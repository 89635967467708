import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

export default function PictureLogin() {
  const { t } = useTranslation('global');
  return (
    <Box>
      <Typography component="h1" variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
        {t('global.welcome')}
      </Typography>
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
        <Avatar
          alt="Remy Sharp"
          sx={{ width: 110, height: 110 }}
        >
          Jlo
        </Avatar>
      </Box>
    </Box>

  );
}

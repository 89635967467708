import LmiColorPalette from './lmi-color-palette';

/// LMI Theme Color
const LmiTheme = {

  /// Light Theme
  light: {

    /// System Background Colors
    systemBackground: {
      base: LmiColorPalette.grayAccentScheme[100],
      elevation: LmiColorPalette.grayAccentScheme[50],
      primary: LmiColorPalette.grayAccentScheme[800],
      primaryIverted: LmiColorPalette.grayAccentScheme[50],
      secondary: LmiColorPalette.grayAccentScheme[500],
      tertiary: LmiColorPalette.grayAccentScheme[400],
      separator: LmiColorPalette.grayAccentScheme[300],
      solid: LmiColorPalette.grayAccentScheme[200],
      dark: LmiColorPalette.grayAccentScheme[900],
    },

    /// Accent Color
    accent: LmiColorPalette.blueScheme[600],
    accentActiveBackground: LmiColorPalette.blueScheme[50],

    /// Brand Colors
    secondaryColor: {
      deepBlue: LmiColorPalette.deepBlueScheme[400],
      purple: LmiColorPalette.purpleScheme[400],
      pink: LmiColorPalette.pinkScheme[400],
      green: LmiColorPalette.greenScheme[500],
      yellow: LmiColorPalette.yellowScheme[400],
      orange: LmiColorPalette.orangeScheme[400],
      red: LmiColorPalette.redScheme[500],
      brown: LmiColorPalette.brownScheme[400],
    },

    /// Info Dialogs Colors
    dialog: {
      success: LmiColorPalette.greenScheme[500],
      info: LmiColorPalette.blueScheme[600],
      warning: LmiColorPalette.orangeScheme[400],
      danger: LmiColorPalette.redScheme[500],
      error: LmiColorPalette.redScheme[500],
      pending: LmiColorPalette.deepBlueScheme[400],
    },

    /// Social Network Colors
    social: {
      facebook: LmiColorPalette.socialScheme.facebook,
      google: LmiColorPalette.socialScheme.google,
    },
  },
};

export default LmiTheme;

import { DialogContent, useMediaQuery, useTheme } from '@mui/material';
import { DialogContentListsModels } from './DialogContentLists.models';

function DialogContentLists(props: DialogContentListsModels) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <DialogContent
        dividers
        sx={{
          px: isMobile ? 1 : undefined,
          p: props.kContentPadding ? 0 : undefined,
        }}
      >
        {props.kContent}
      </DialogContent>
    </>
  );
}

export default DialogContentLists;

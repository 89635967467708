import { Container, useMediaQuery, useTheme } from '@mui/material';
import { ContentLayoutModels } from './ContentLayout.models';

function ContentLayout(props: ContentLayoutModels) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <>
      <Container
        disableGutters={!isDesktop ?? false}
        component="div"
        // sx={{ p: { xs: props.kPaddingXs, md: props.kPaddingMd } }}
        sx={{
          py: {
            xs: `${props.kPaddingXs}px`,
            sm: `${props.kPaddingMd}px`,
          },
          px: {
            xl: '0 !important',
            sm: `${props.kPaddingMd}px`,
            xs: `${props.kPaddingXs}px`,
          },
        }}
      >
        {props.kBodyLayout}
        {props.children}
      </Container>
    </>
  );
}

ContentLayout.defaultProps = {
  kPaddingXs: 16,
  kPaddingMd: 32,
};

export default ContentLayout;

import React, { useContext, useEffect, useState } from 'react';
import {
  Box, Typography, Button, Divider, Dialog, DialogTitle,
  DialogActions,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuid } from 'uuid';
import { DialogCommon, LmiTheme } from '../../../../../../../components/Common';
import AddRequirements from '../AddRequirements/AddRequirements';
import ListRequirements from '../ListRequirements/ListRequirements';
import addEditItemContext from '../../../../../../../context/addEditItem/addEditItemContext';
import { UserTermsModel } from '../../AddItem.models';

export default function ItemRequirements(props: any) {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const addEditContextLog = useContext(addEditItemContext);
  const [flagAdd, setFlagAdd] = useState(false);
  const {
    addEditRequirementInfo,
    userTerms,
    updateRequirementsList,
    updateDraftRequirementList,
    updateRequirementInfo,
    areReqFieldsValidated,
    changedReqFields,
    checkReqValidations,
    deleteRequirement,
    updateReqValidationStatus,
  }: any = addEditContextLog;

  const initialAddEditRequirementInfo: UserTermsModel = {
    idTerm: 0,
    idCatLndUser: 0,
    active: true,
    termDetail: '',
    creationDate: '',
    modifyDate: '',
    relatedToItem: false,
    generatedNewID: uuid(),
  };

  const closeDialogModal = () => {
    updateRequirementInfo(initialAddEditRequirementInfo);
    setOpen(false);
  };

  const handleModalToggle = (value: any) => {
    if (value >= 0) {
      updateRequirementInfo(userTerms[value]);
    } else {
      updateRequirementInfo(initialAddEditRequirementInfo);
    }
    setOpen(!open);
  };

  const submitRequirement = () => {
    setFlagAdd(!flagAdd);
    checkReqValidations(true);
  };

  useEffect(() => {
    if (areReqFieldsValidated) {
      saveUpdateRequirement();
    }
  }, [changedReqFields]);

  const DeleteRequirement = async () => {
    if (await deleteRequirement()) {
      handleModalToggle(-1);
      setOpenDialog(false);
    }
  };

  const saveUpdateRequirement = () => {
    if (addEditRequirementInfo.termDetail != '') {
      if (addEditRequirementInfo.idTerm != 0) {
        const indexInArray = userTerms.findIndex((element: any) => element.idTerm === addEditRequirementInfo.idTerm);
        userTerms.splice(indexInArray, 1, addEditRequirementInfo);
        updateRequirementsList(userTerms);
        updateDraftRequirementList(userTerms);
        setOpen(!open);
        updateReqValidationStatus(false);
      } else {
        const indexInArray = userTerms.findIndex((element: any) => element.generatedNewID === addEditRequirementInfo.generatedNewID);
        if (indexInArray > -1) {
          userTerms.splice(indexInArray, 1, addEditRequirementInfo);
          updateRequirementsList(userTerms);
          updateDraftRequirementList(userTerms);
          setOpen(!open);
          updateReqValidationStatus(false);
        } else {
          userTerms.push(addEditRequirementInfo);
          updateRequirementsList(userTerms);
          updateDraftRequirementList(userTerms);
          setOpen(!open);
          updateReqValidationStatus(false);
        }
      }
    }
  };

  const { t } = useTranslation('global');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (

    <>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('add_item_page.requirement_removed')}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>{t('add_item_page.disagree')}</Button>
          <Button onClick={DeleteRequirement} autoFocus>
            {t('add_item_page.agree')}
          </Button>
        </DialogActions>
      </Dialog>
      <Box>
        <Typography p={2} fontSize="body2.fontSize" color={LmiTheme.light.systemBackground.tertiary}>
          {t('add_item_page.write_instructions')}
        </Typography>
        <Divider />
        <ListRequirements key="0" editRequirement={handleModalToggle} />
        <Box mb={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            disabled={props.isBlocked}
            variant="outlined"
            onClick={() => { handleModalToggle(-1); }}
            startIcon={<AddIcon fontSize="large" />}
            size="large"
            sx={{
              my: 2,
              textTransform: 'none',
              fontWeight: 700,
              borderRadius: '10px',
            }}
          >
            {t('add_item_page.add_special_instructions')}
          </Button>
        </Box>
      </Box>
      <DialogCommon
        kTitle={t('add_item_page.add_requirements')}
        kMaxWidth="sm"
        kMaxHeight={isMobile ? '420px' : '400px'}
        kAlignBtn="center"
        kBorderRadius={isMobile ? 0 : '10px'}
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kKeepBtnSize
        kTopDivider
        kSlideTransition={isMobile}
        kOpenDialogLists={open}
        kOnClose={closeDialogModal}
        kOnPrimary={submitRequirement}
        kOnSecondary={closeDialogModal}
        kContent={
          (
            <AddRequirements />
          )
        }
      />
    </>

  );
}

const BannerPromo = {
  display: 'block',
  '& .lmiwRadiusTop': {
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
  },
  '& .lmiwRadiusBottom': {
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
  },
  '& .lmiwBase': {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
  },
  '& .lmiwLeft': {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    zIndex: 10,
    overflow: 'hidden',
  },
  '& .lmiwGradientRight': {
    // background: 'linear-gradient(to right, rgba(0, 0, 0, 0), rgba(51, 54, 58, 1) 50%)',
    width: { sm: '80%', xs: '100%' },
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    borderRadius: '20px',
  },

};

export default BannerPromo;

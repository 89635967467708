import { LmiTheme } from '..';

export const ZendeskLoadingStyle = {
  padding: '0px',
  bottom: '0px',
  overflow: 'visible',
  border: '0px',
  zIndex: 1,
  right: '0px',
  borderRadius: '999rem',
  background: LmiTheme.light.systemBackground.tertiary,
  p: {
    color: 'white',
  },
  '.MuiCircularProgress-root': {
    width: '14px !important',
    height: '14px !important',
    color: 'white',
    fontWeight: 700,
  },

};

export const BannerCookiesStyle = {
  width: '100%',
  borderRadius: '20px',
  bottom: { xs: 0, lg: 10 },
  zIndex: 99,
  Button: {
    fontWeight: 'bold',
    borderRadius: '10px',
    height: '44px',
    // textTransform: 'lowercase',
    display: 'block',
    fontSize: { md: '16px', xs: '12px' },
    // '&::first-letter': {
    //   textTransform: 'uppercase !important',
    // },

    '&.btn-preferences': {
      width: '180px',
    },
    '&.btn-agree': {
      width: 'auto',
      whiteSpace: 'nowrap',
      minWidth: '180px',
    },

  },
  '& p': {
    color: `${LmiTheme.light.systemBackground.secondary}!important`,
  },

};

export const ModalCookiesStyle = {
  outline: 'none',
  '& :focus-visible': {
    outline: 'none',
  },
  '& p': {
    color: LmiTheme.light.systemBackground.secondary,
    marginBottom: 3,
  },
  '& .closeModal': {
    fill: LmiTheme.light.accent,
    fontSize: { md: 56, xs: 44 },
    cursor: 'pointer',
    right: 0,
    top: 0,
  },
  // '& .MuiCheckbox-root svg': {
  //   fontSize: '2rem',
  //   fill: 'white',
  //   // background: LmiTheme.light.accent,
  // },
  '& .MuiFormControlLabel-root': {
    alignItems: 'flex-start',
    marginLeft: '2px',
  },
  '& .MuiCheckbox-root': {
    padding: 0,
    '& svg': {
      fontSize: '2rem',
      fill: 'white',
      // background: LmiTheme.light.accent,
    },
  },
  '& .MuiFormControlLabel-label': {
    marginLeft: 2,
  },
  '& .Mui-disabled .box-checkbox': {
    opacity: 0.5,
  },
  '& .box-checkbox': {
    width: { md: 32, xs: 24 },
    height: { md: 32, xs: 24 },
    padding: '4px',
    borderRadius: '5px',
    border: `2px solid${LmiTheme.light.accent}`,
    '&.active': {
      background: LmiTheme.light.accent,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },

};

import { Typography } from '@mui/material';
import { LmiTheme } from '../../../../../components/Common';
import { kTitleModels } from './Heading.models';

function Heading(props: kTitleModels) {
  return (
    <>
      <Typography
        component={props.kComponent}
        pb={2}
        sx={{
          fontSize: 'body1.fontSize',
          fontWeight: 'bold',
          color: LmiTheme.light.systemBackground.primary,
        }}
      >
        {props.kTitle}
      </Typography>
    </>
  );
}

Heading.defaultProps = {
  kTitle: 'kTitle',
  kComponent: 'p',
};

export default Heading;

/* eslint-disable dot-notation */
import { useContext, useState } from 'react';
import {
  Stack,
  Button,
  Divider,
  Typography,
  Box,
  TextField,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider, MobileTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import moment from 'moment';
import { toast } from 'react-toastify';
import ItemLocation from '../../../../../components/Common/ItemLocation/ItemLocation';
import LmiTheme from '../../../../../themes/lmi-mui-theme/lmi-theme';
import { DialogCommon } from '../../../../../components/Common';
import bookItemContext from '../../../../../context/bookItem/bookContext';
import ModalMap from '../../../../../components/Common/Map/MapPlaces';
import { MapData } from '../../../../../components/Common/Map/Map.models';
import { formatter } from '../../../../../services/common';
import { ROADIE } from '../../../../../types';
import homeContext from '../../../../../context/home/homeContext';

function DeliveryByRoadie() {
  const {
    bookDetails: {
      startDeliveryTime,
      endDeliveryTime,
      idCatTypeItem,
      currency,
    },
    summary,
    roadieTemp: roadie,
    updateRoadieInfoTemp,
    deliveryType,
    updateRoadieInfo,
    roadie: originRoadie,
  }: any = useContext(bookItemContext);

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const {
    longAddress,
    lat: user_lat,
    long: user_long,
  }: any = useContext(homeContext);

  const {
    address: addressMap,
    lat,
    long,
    // cost,
  } = roadie;

  const { roadie: roadieSummary, pendingAction, feeLMI } = summary ?? { roadie: null };

  const { price } = roadieSummary ?? { price: 0.0 };
  const { stripeFee } = feeLMI ?? { stripeFee: 0.0 };

  let title = 'place';

  switch (idCatTypeItem) {
    case 1:
      title = 'item';
      break;
    case 2:
      title = 'service';
      break;
    default:
      break;
  }

  const dtStart = moment(startDeliveryTime, ['h:mm A']).format('HH:mm');
  const dtEnd = moment(endDeliveryTime, ['h:mm A']).format('HH:mm');
  const startTime = dtStart.split(':');
  const endTime = dtEnd.split(':');
  const _startTime = new Date(0, 0, 0, parseInt(startTime[0], 10), parseInt(startTime[1], 10));
  const _endTime = new Date(0, 0, 0, parseInt(endTime[0], 10), parseInt(endTime[1], 10));
  const _maxTime = new Date(0, 0, 0, parseInt(endTime[0], 10) - 4, parseInt(endTime[1], 10));

  const receptionStartTime = originRoadie?.receptionStartTime ?? _startTime;
  const receptionEndTime = moment(originRoadie?.receptionStartTime ?? _startTime).add(4, 'hours').toDate();
  const returnStartTime = originRoadie?.returnStartTime ?? _startTime;
  const returnEndTime = moment(originRoadie?.returnStartTime ?? _startTime).add(4, 'hours').toDate();

  const [openChangeAddress, setOpenChangeAddress] = useState(false);

  const updateHours = (hours: Date | null, data: string) => {
    const dataObj = { ...roadie };
    const hour = hours! > _maxTime ? _maxTime : hours;

    switch (data) {
      case 'receptionStartTime':
        dataObj['receptionStartTime'] = hour;
        dataObj['receptionEndTime'] = moment(hour).add(4, 'hours').toDate();
        break;
      case 'returnStartTime':
        dataObj['returnStartTime'] = hour;
        dataObj['returnEndTime'] = moment(hour).add(4, 'hours').toDate();
        break;
      default:
        break;
    }

    updateRoadieInfoTemp(dataObj);
  };

  const updateMapa = (mapaData: MapData) => {
    if (isFirstLoad) setIsFirstLoad(false);
    const isNewData = Object.keys(roadie).length == 0 && Object.keys(originRoadie).length == 0;

    if (deliveryType === ROADIE) {
      if (!roadie.receptionStartTime) {
        roadie['receptionStartTime'] = receptionStartTime;
        roadie['receptionEndTime'] = receptionEndTime;
        roadie['returnStartTime'] = returnStartTime;
        roadie['returnEndTime'] = returnEndTime;
      }

      updateRoadieInfoTemp(
        {
          ...roadie,
          address: mapaData.address ?? longAddress,
          countryName: mapaData.countryName,
          extendedAddress: mapaData.extendedAddress,
          locality: mapaData.locality,
          postalCode: mapaData.postalCode,
          region: mapaData.region,
          streetAddress: mapaData.streetAddress,
          lat: mapaData.lat,
          long: mapaData.long,
        },
      );

      if (isNewData) {
        updateRoadieInfo(
          {
            ...roadie,
            address: mapaData.address ?? longAddress,
            countryName: mapaData.countryName,
            extendedAddress: mapaData.extendedAddress,
            locality: mapaData.locality,
            postalCode: mapaData.postalCode,
            region: mapaData.region,
            streetAddress: mapaData.streetAddress,
            lat: mapaData.lat,
            long: mapaData.long,
          },
        );
      }

      if (mapaData.countryName == 'Mexico' || mapaData.countryName == 'Canada' || mapaData.region == 'MX') {
        toast.warning(t('delivery.roadie.not_available'));
      }
    }
  };

  const openMap = () => {
    setOpenChangeAddress(true);
  };

  const cancelEditHours = () => {
    updateRoadieInfoTemp(
      {
        ...originRoadie,
      },
    );
  };

  const acceptEditHours = () => {
    updateRoadieInfo(
      {
        ...roadie,
      },
    );
    updateRoadieInfoTemp(
      {
        ...roadie,
      },
    );
  };

  const saveCloseMap = () => {
    setOpenChangeAddress(!openChangeAddress);

    updateRoadieInfo(
      {
        ...roadie,
      },
    );
  };

  const cancelCloseMap = () => {
    setOpenChangeAddress(!openChangeAddress);

    updateRoadieInfoTemp(
      {
        ...originRoadie,
      },
    );
  };

  const { t } = useTranslation('global');

  function totalDeliveryCollectReturnFee(value: any, stpFee: any) {
    const totalWithStripe = (value / (1 - stpFee!));
    return formatter.format(parseInt(totalWithStripe.toFixed(0), 10));
  }

  return (
    <>
      <DialogCommon
        kTitle={`${t('delivery.by_lender.select_location')} ${title}`}
        kMaxWidth="md"
        kOpenDialogLists={openChangeAddress}
        kOnClose={() => { cancelCloseMap(); }}
        kOnPrimary={() => { saveCloseMap(); }}
        kOnSecondary={() => { cancelCloseMap(); }}
        kContent={
          (
            <ModalMap
              state={{
                address: addressMap,
                lat: (lat && lat.length > 0) ? parseFloat(lat) : user_lat,
                long: (long && long.length > 0) ? parseFloat(long) : user_long,
                typeItem: idCatTypeItem ?? 3,
              }}
              funUpdateInfo={(mapData: MapData) => { updateMapa(mapData); }}
            />
          )
        }
      />
      <Box px={2} pt={2} pb={1}>
        <Typography sx={{ color: LmiTheme.light.systemBackground.tertiary, fontSize: 'subtitle2.fontSize' }}>
          {t('delivery.by_lender.deliver_return')}
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          pt={1}
        >
          <Grid item>
            <ItemLocation
              kTextColor={LmiTheme.light.systemBackground.secondary}
              kText={isFirstLoad ? longAddress : originRoadie.address}
              // kText={originRoadie.address}
            />
          </Grid>
          <Grid item>
            <Button
              variant="text"
              key="share"
              size="small"
              disableElevation
              disableRipple
              disableFocusRipple
              onClick={() => openMap()}
              sx={{
                textTransform: 'none',
                color: LmiTheme.light.accent,
                fontWeight: 'bold',
                fontSize: 'body1.fontSize',
              }}
            >
              {t('delivery.by_lender.change')}
            </Button>
          </Grid>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          pt={2}
          px={1}
        >
          <Typography
            component="span"
            sx={{
              color: LmiTheme.light.systemBackground.secondary,
              fontSize: 'subtitle2.fontSize',
              fontWeight: '500',
            }}
          >
            {t('delivery.roadie.time_lender')}
            <> </>
          </Typography>
          <Typography component="span" sx={{ color: LmiTheme.light.systemBackground.secondary, fontSize: 'subtitle2.fontSize', fontWeight: '700' }}>
            {`${startDeliveryTime} to ${endDeliveryTime}`}
          </Typography>
        </Stack>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            pt={2}
            pb={1}
            px={2}
          >
            <Grid item>
              <Typography sx={{
                color:
                  LmiTheme.light.systemBackground.tertiary,
                fontSize: 'subtitle2.fontSize',
                fontWeight: '500',
              }}
              >
                {t('delivery.roadie.delivery_time')}
              </Typography>
            </Grid>
            <Grid item>
              <MobileTimePicker
                okText={t('delivery.roadie.set')}
                label={t('delivery.roadie.reception_start')}
                toolbarTitle={t('delivery.roadie.reception_start')}
                value={receptionStartTime}
                minTime={_startTime}
                maxTime={_maxTime}
                onClose={() => { cancelEditHours(); }}
                onAccept={() => { acceptEditHours(); }}
                // maxTime={_endTime}
                onChange={(newReceptionStartTime) => {
                  updateHours(newReceptionStartTime, 'receptionStartTime');
                }}
                /* eslint-disable react/jsx-props-no-spreading */
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    type="datetime"
                    variant="standard"
                    placeholder=""
                    size="small"
                    InputLabelProps={{ shrink: false }}
                    InputProps={{
                      disableUnderline: true,
                      readOnly: true,
                      style: {
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: 'subtitle1.fontSize',
                        color: LmiTheme.light.accent,
                        width: '70px',
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Typography sx={{ color: LmiTheme.light.accent, fontSize: 'subtitle1.fontSize', fontWeight: '500' }}>
                —
              </Typography>
            </Grid>
            <Grid>
              <MobileTimePicker
                okText={t('delivery.roadie.set')}
                disabled
                label={t('delivery.roadie.reception_start')}
                toolbarTitle={t('delivery.roadie.reception_start')}
                value={receptionEndTime! <= receptionStartTime! ? receptionStartTime : receptionEndTime}
                minTime={receptionStartTime}
                maxTime={_endTime}
                onChange={() => { }}
                // onChange={(newReceptionEndTime) => {
                //   updateHours(newReceptionEndTime, 'receptionEndTime');
                // }}
                /* eslint-disable react/jsx-props-no-spreading */
                renderInput={(params) => (
                  <TextField
                    {...params}
                    disabled
                    label=""
                    type="datetime"
                    variant="standard"
                    placeholder=""
                    size="small"
                    InputLabelProps={{ shrink: false }}
                    InputProps={{
                      disableUnderline: true,
                      readOnly: true,
                      style: {
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: 'subtitle1.fontSize',
                        color: LmiTheme.light.accent,
                        width: '70px',
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            pt={1}
            pb={2}
            px={2}
          >
            <Grid item>
              <Typography sx={{
                color: LmiTheme.light.systemBackground.tertiary,
                fontSize: 'subtitle2.fontSize',
                fontWeight: '500',
              }}
              >
                {t('delivery.roadie.return_time_between')}
              </Typography>
            </Grid>
            <Grid item>
              <MobileTimePicker
                okText={t('delivery.roadie.set')}
                label={t('delivery.roadie.return_start')}
                toolbarTitle={t('delivery.roadie.return_start')}
                value={returnStartTime}
                minTime={_startTime}
                maxTime={_maxTime}
                onClose={() => { cancelEditHours(); }}
                onAccept={() => { acceptEditHours(); }}
                onChange={(newReturnStartTime) => {
                  updateHours(newReturnStartTime, 'returnStartTime');
                }}
                /* eslint-disable react/jsx-props-no-spreading */
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    type="datetime"
                    variant="standard"
                    placeholder=""
                    size="small"
                    InputLabelProps={{ shrink: false }}
                    InputProps={{
                      disableUnderline: true,
                      readOnly: true,
                      style: {
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: 'subtitle1.fontSize',
                        color: LmiTheme.light.accent,
                        width: '70px',
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Typography sx={{
                color: LmiTheme.light.accent,
                fontSize: 'subtitle1.fontSize',
                fontWeight: '500',
              }}
              >
                —
              </Typography>
            </Grid>
            <Grid item>
              <MobileTimePicker
                okText={t('delivery.roadie.set')}
                disabled
                label={t('delivery.roadie.return_end')}
                toolbarTitle={t('delivery.roadie.return_end')}
                value={returnEndTime! <= returnStartTime! ? returnStartTime : returnEndTime}
                // minTime={returnStartTime}
                // maxTime={_endTime}
                onChange={() => { }}
                // onChange={(newReturnEndTime) => {
                //   updateHours(newReturnEndTime, 'returnEndTime');
                // }}
                /* eslint-disable react/jsx-props-no-spreading */
                renderInput={(params) => (
                  <TextField
                    {...params}
                    disabled
                    label=""
                    type="datetime"
                    variant="standard"
                    placeholder=""
                    size="small"
                    InputLabelProps={{ shrink: false }}
                    InputProps={{
                      disableUnderline: true,
                      readOnly: true,
                      style: {
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: 'subtitle1.fontSize',
                        color: LmiTheme.light.accent,
                        width: '70px',
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </Stack>
        </LocalizationProvider>
        <Divider />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          p={1}
        >
          <Grid item>
            <Typography sx={{ color: LmiTheme.light.systemBackground.secondary, fontSize: 'subtitle1.fontSize', fontWeight: 'bold' }}>
              {t('delivery.roadie.cost')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ color: LmiTheme.light.systemBackground.secondary, fontSize: 'subtitle1.fontSize', fontWeight: 'bold' }}>
              {(price > 0 && !pendingAction) ? `${totalDeliveryCollectReturnFee(price, stripeFee)} ${currency}` : `${pendingAction}`}
            </Typography>
          </Grid>
        </Stack>
      </Box>
      <Divider />
    </>
  );
}

export default DeliveryByRoadie;

import {
  Card, List, ListItem, ListItemButton, ListItemIcon, ListItemText,
} from '@mui/material';
import SpokeRoundedIcon from '@mui/icons-material/SpokeRounded';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import { useTranslation } from 'react-i18next';
import { FiltersSuggestionModels } from './FiltersSuggestion.models';
import { LmiTheme } from '../../../../../../components/Common';

export default function FiltersSuggestion(props: FiltersSuggestionModels) {
  const { t } = useTranslation('global');

  const funcTrigger = props.kOnClick;
  const filterSuggestion = [
    { title: t('activity.list.lend'), icon: <ArrowCircleRightRoundedIcon /> },
    { title: t('activity.list.rent'), icon: <ArrowCircleLeftRoundedIcon /> },
    { title: t('activity.list.purchase'), icon: <AccountBalanceWalletRoundedIcon /> },
    { title: t('activity.list.circles'), icon: <SpokeRoundedIcon /> },
  ];

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          position: 'absolute',
          top: props.kTop,
          width: '100%',
          borderRadius: '10px',
          zIndex: 10,
          svg: {
            color: LmiTheme.light.accent,
          },
          li: {
            height: '40px',
          },
        }}
      >
        <List>
          {filterSuggestion.map((data: any) => (
            <ListItem key={data.title} value={data.title} disablePadding>
              <ListItemButton onClick={() => {
                funcTrigger(data.title);
              }}
              >
                <ListItemIcon sx={{ minWidth: 36 }}>
                  {data.icon}
                </ListItemIcon>
                <ListItemText primary={data.title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Card>
    </>
  );
}

FiltersSuggestion.defaultProps = {
  kTop: 30,
};

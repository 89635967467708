import { Stack, Box, Typography } from '@mui/material';
import { ActivityChatMessageModels } from './ActivityChatMessage.models';
import ActivityChatMessagesRightStyle from './ActivityChatMessage.style';

export default function MessageRight(props: ActivityChatMessageModels) {
  const message = props.kMessage ? props.kMessage : 'no message';
  const timestamp = props.kTimestamp ? props.kTimestamp : '';
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems={props.kAlign === 'right' ? 'flex-end' : 'flex-start'}
      spacing={2}
      sx={ActivityChatMessagesRightStyle}
    >
      <Box className="lmiw-box-message" textAlign={props.kAlign}>
        <Typography className="lmiw-text-message" variant="body2" gutterBottom component="p">
          {message}
        </Typography>
        {timestamp ? (
          <Typography className="lmiw-timestamp-message" gutterBottom component="p">
            {timestamp}
          </Typography>
        ) : <></>}

      </Box>
    </Stack>
  );
}

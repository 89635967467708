import { DefaultApi, Configuration } from '@lendmeit/api_backend_lmi';
// import { Auth } from 'aws-amplify';
import * as AWS from 'aws-sdk/global';

import aws4Interceptor from 'aws4-axios';
import axios from 'axios';
import Cookies from 'js-cookie';

// const AWS = require('aws-sdk');

const urlApi = process.env.REACT_APP_API_BACKEND!;

// User Pool
// const poolData = {
//   UserPoolId: process.env.REACT_APP_USER_POOL_ID,
//   ClientId: process.env.REACT_APP_CLIENDID,
// };
// const userPool = new AWS.CognitoIdentityServiceProvider.CognitoUserPool(poolData);

// const customCredentialsProvider = {
//   getCredentials: async () => Promise.resolve({
//     accessKeyId: 'custom-provider-access-key-id',
//     secretAccessKey: 'custom-provider-secret-access-key',
//   }),
// };

const ApiLMI = (
) => {
  const token = localStorage.getItem('token');
  const language = Cookies.get('i18next');

  const { credentials }: any = AWS.config;
  const accessKeyId = credentials?.accessKeyId!;
  const secretAccessKey = credentials?.secretAccessKey!;
  const sessionToken = credentials?.sessionToken!;

  credentials?.refresh();

  const modelInstance: any = {
    baseURL: urlApi,
    headers: {
      'Accept-Language': language === 'es' ? 'es-MX' : 'en-US',
    },
  };

  if (token) {
    modelInstance.headers.Authtoken = `Bearer ${token}`;
  }

  const axiosInstance = axios.create(modelInstance);

  const interceptor = aws4Interceptor({
    region: process.env.REACT_APP_REGION!,
    service: 'execute-api',
  }, {
    accessKeyId,
    secretAccessKey,
    sessionToken,
  });

  axiosInstance.interceptors.request.use(interceptor);
  axiosInstance.interceptors.response.use((response) => response, (error: any) => {
    const badRequest = { ...error };
    // const { status } = error.response;
    console.log(badRequest);
    debugger;
    // if (process.env.NODE_ENV != 'development') {
    //   window.location.reload();
    // }

    return Promise.reject(error);
  });

  const configApi = new Configuration();

  const api = new DefaultApi(
    configApi,
    urlApi,
    axiosInstance,
  );

  return api;
};

export default ApiLMI;

/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  FormControl, Stack, Typography,
} from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { InputFormModels } from './InputForm.models';
import FormInputStyle from './InputForm.style';
import LmiTheme from '../../../themes/lmi-mui-theme/lmi-theme';

export default function InputForm(props: InputFormModels) {
  // More Information https://mui.com/api/input-base/
  // const defaultValue = props.input_params?.defaultValue;
  return (
    <>
      <FormControl
        variant="standard"
        {...props.form_control_params}
        margin="dense"
        error={props.input_params?.error}
        disabled={props.disabled}
        focused={props.input_params?.autoFocus}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          pb={1}
        >
          {props.label ? (
            <Box>
              <>
                { props.input_params?.required && <Typography component="span" fontSize="caption.fontSize" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>}
                <Typography component="span" fontSize="caption.fontSize" fontWeight={500} color={props.kColorLabel}>
                  {props.label}
                </Typography>
              </>
            </Box>
          ) : <></>}
          {
          props.kInputMaxLength
          && (
            <Typography component="span" fontSize="caption.fontSize" color={LmiTheme.light.systemBackground.tertiary}>
              {/* 0/ */}
              {`${props.kInputCurrentLength} /`}
              {props.kInputMaxLength}
            </Typography>
          )
        }
        </Stack>
        <FormInputStyle
          id={props.id}
          {...props.input_params}
          inputProps={
            {
              maxLength: props.max_lenght,
            }
          }
          sx={{
            border: props.kBorder,
            borderRadius: props.kBorderRadius,
            background: props.kBackground,
            padding: props.kPadding,
            color: props.kColorInput,

            '&:hover:not(.Mui-disabled)': {
              borderColor: LmiTheme.light.systemBackground.tertiary,
            },
            '& input:focus': {
              boxShadow: 'none !important',
              border: '0',
            },
            '&.Mui-focused': {
              boxShadow: 'none !important',
              border: `1px solid ${LmiTheme.light.accent}`,
            },

          }}
        />
        {props.helper_text ? <FormHelperText error={props.input_params?.error} id={props.id}>{props.input_params?.error ? props.helper_text : ''}</FormHelperText> : ''}
      </FormControl>
    </>
  );
}

InputForm.defaultProps = {
  kBorder: `1px solid ${LmiTheme.light.systemBackground.separator}`,
  kBorderRadius: '7px',
  kBackground: LmiTheme.light.systemBackground.base,
  kPadding: '10px 12px',
  kColorLabel: LmiTheme.light.systemBackground.secondary,
  kColorInput: LmiTheme.light.systemBackground.primary,
};

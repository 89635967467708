import React from 'react';

import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import {
  Avatar, Menu, MenuItem, Box, ListItemIcon,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import { useTranslation } from 'react-i18next';
import LmiTheme from '../../../../../themes/lmi-mui-theme/lmi-theme';
import { Marker } from '../../..';

interface BtnUserGuestInterface {
  kChangeLocation: Function,
}

function BtnGuestUser(props: BtnUserGuestInterface) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const history = useHistory();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function login() {
    history.push('/login');
  }

  const { t } = useTranslation('global');

  return (
    <div>
      <Box
        onClick={handleClick}
        style={{
          backgroundColor: 'white',
          cursor: 'pointer',
          height: '42px',
          borderRadius: '100px',
        }}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          width: '80px',
          gap: 1,
          boxShadow: 'none',
          backgroundColor: 'white',
          cursor: 'pointer',
          border: `1px solid ${LmiTheme.light.systemBackground.separator}`,

        }}
      >
        <Avatar sx={{ height: 30, width: 30 }} />
        <MenuRoundedIcon />
      </Box>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
        }}
        sx={{
          '.MuiMenu-paper': {
            border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
            marginTop: '5px',
            borderRadius: 4,
            svg: {
              fill: LmiTheme.light.accent,
              width: 20,
              height: 20,
            },
          },
        }}
      >
        <MenuItem onClick={() => { handleClose(); props.kChangeLocation(); }}>
          <ListItemIcon>
            <Marker />
          </ListItemIcon>
          {t('user_session.change_location')}
        </MenuItem>
        <MenuItem onClick={() => { handleClose(); login(); }}>
          <ListItemIcon>
            <LoginRoundedIcon />
          </ListItemIcon>
          {t('search.log_in')}
        </MenuItem>
      </Menu>
    </div>
  );
}

export default BtnGuestUser;

import LmiTheme from '../../../../../themes/lmi-mui-theme/lmi-theme';

const CircleEarningsStyle = {
  width: '100%',
  //   height: '100%',
  '.lmiw-activity-summary': {
    borderRadius: '10px',

    '.lmiw-summary-concept p,': {
      fontSize: '16px',
      fontWeight: '500',
      color: 'black',
      my: 1,
      '&.red': {
        color: LmiTheme.light.secondaryColor.red,
      },
    },

    '.lmiw-summary-accessories p': {
      fontSize: '16px',
      fontWeight: '400',
      my: 1,
      ml: 2,
      color: LmiTheme.light.systemBackground.secondary,
    },

    '.lmiw-summary-total p,': {
      fontSize: '18px',
      fontWeight: 'bold',
      color: 'black',
      my: 1,
    },

  },
  '.lmiw-info': {
    fontSize: '18px',
    fontWeight: 'bold',
    m: 2,
    textAlign: 'center',
    // color: LmiTheme.light.systemBackground.secondary,
  },
  '.lmiw-more-info': {
    fontSize: '14px',
    fontWeight: '300',
    m: 2,
    textAlign: 'center',
    color: LmiTheme.light.systemBackground.secondary,
  },

};

export default CircleEarningsStyle;

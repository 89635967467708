import { Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LmiTheme, SectionTitle } from '../../../../../components/Common';

export default function IdVerification(props: any) {
  const { t } = useTranslation('global');

  return (
    <>
      <SectionTitle kTitle={t('profile.id_verification')} />
      <Paper
        variant="outlined"
        elevation={0}
        sx={{ p: 1, borderRadius: '10px' }}
        color={LmiTheme.light.systemBackground.tertiary}
      >
        {props.requiresIdVerification ? (
          <Typography sx={{ my: 3 }}>
            {t('listing_detail.requires_id_verification')}
          </Typography>
        ) : (
          <Typography sx={{ my: 3 }}>
            {t('listing_detail.no_requires_id_verification')}
          </Typography>
        )}
      </Paper>
    </>
  );
}

import { Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageNotFoundStyle from './PageNotFound.style';

export default function PageNotFound() {
  useEffect(() => {

  }, []);
  const { t } = useTranslation('global');
  return (
    <>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={PageNotFoundStyle}
      >
        <Typography component="h2" variant="h2">:(</Typography>
        <Typography component="h1" variant="h1">404</Typography>
        <Typography component="h5" variant="h5">{t('404.not_found')}</Typography>
      </Stack>
    </>
  );
}

import {
  Route, Switch, BrowserRouter,
} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import { LicenseInfo } from '@mui/x-date-pickers-pro';
import AuthState from './context/authentication/authState';
import ProtectedRoute from './utils/protectedRoute';
import AddEditItemState from './context/addEditItem/addEditItemState';
import RouterState from './context/router/routerState';
import BookItemState from './context/bookItem/bookState';
import CalendarState from './context/calendar/calendarState';
import ExploreState from './context/explore/exploreState';
import MyListingState from './context/myListing/myListingState';
import PublicProfileState from './context/publicProfile/publicProfileState';
import LoginListener from './services/amplifyListener';

import SideForm from './components/Template/SideForm/SideForm';
import LandingPage from './components/Template/LandingPage/LandingPage';
import PageLogin from './page/BackOffice/LogIn/LogIn';
import PageListing from './page/BackOffice/Listing/Listing';
import PageCircles from './page/BackOffice/Circles/Circles';
import PageNewCircle from './page/BackOffice/Circles/AddCircle/AddCircle';
import PageActivity from './page/BackOffice/Activity/Activity';
import PageAddItem from './page/BackOffice/AddItem/AddItem';
import BookItemPage from './page/FrontOffice/BookItemPage/BookItemPage';
import HomePage from './page/FrontOffice/HomePage/HomePage';
import ExplorePage from './page/FrontOffice/ExplorePage/ExplorePage';
import AncillaryPage from './page/FrontOffice/AncillaryPage/AncillaryPage';
import PublicProfile from './page/FrontOffice/ProfilePublic/ProfilePublic';
import CirclePage from './page/FrontOffice/CirclePage/CirclePage';
import PageNotFound from './page/FrontOffice/PageNotFound/PageNotFound';
import PrivateProfile from './page/BackOffice/ProfilePrivate/PrivateProfile';
import Checkout from './page/FrontOffice/Checkout/Checkout';
import PaymentMethodsState from './context/paymentMethods/paymentMethodsState';
import HomeState from './context/home/homeState';
import ShuftiProState from './context/shuftiPro/shuftiProState';
import { Admin } from './components/Template';
import ScrollToTop from './utils/ScrollToTop';
import PayoutAccountState from './context/payoutAccount/payoutAccountState';
import ActivityState from './context/activity/activityState';
import ActivityActionsState from './context/activity/actions/activityActionsState';
import AddEditCirclesState from './context/circles/addEditCirclesState';
import MyCirclesListingState from './context/myCirclesListing/myCirclesListingState';
import ManageMembersDetail from './page/BackOffice/Circles/ManageMembersDetail/ManageMembersDetail';
import CircleItems from './page/BackOffice/Circles/CircleItems/CircleItems';
import FavoritesState from './context/favorites/favoritesState';
import PickUpDropOffPicturesState from './context/activity/pickUpDropOffPictures/pickUpdropOffPicturesState';
import DeliveryActivityState from './context/deliveryActivity/deliveryActivityState';
import UserCompletion from './page/BackOffice/LogIn/UserCompletion/UserCompletion';
import SocketState from './context/socket/socketState';

function RouterApp() {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY!);
  const { t } = useTranslation('global');
  return (
    <>
      <RouterState>
        <HomeState>
          <AuthState>
            <FavoritesState>
              <SocketState>
                <ShuftiProState>
                  <PublicProfileState>
                    <ActivityState>
                      <ActivityActionsState>
                        <PickUpDropOffPicturesState>
                          <DeliveryActivityState>
                            <BookItemState>
                              <AddEditItemState>
                                <MyListingState>
                                  <AddEditCirclesState>
                                    <MyCirclesListingState>

                                      <PaymentMethodsState>
                                        <PayoutAccountState>
                                          <CalendarState>
                                            <ExploreState>
                                              <LoginListener />
                                              <BrowserRouter>
                                                <ScrollToTop />
                                                <Switch>
                                                  <ProtectedRoute
                                                    exact
                                                    publicPage
                                                    path="/listing"
                                                    title="listing"
                                                    component={() => (<Admin content={<PageListing />} />)}
                                                  />
                                                  <ProtectedRoute
                                                    exact
                                                    publicPage
                                                    path="/listing/edit"
                                                    title={t('item_detail.add_item')}
                                                    component={() => (<Admin content={<PageAddItem />} />)}
                                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                                    render={(props: any) => <PageAddItem {...props} />}
                                                  />
                                                  <ProtectedRoute
                                                    exact
                                                    publicPage={false}
                                                    path="/circles"
                                                    title="circles"
                                                    component={() => (<Admin content={<PageCircles />} />)}
                                                  />
                                                  <ProtectedRoute
                                                    exact
                                                    publicPage={false}
                                                    path="/circles/new"
                                                    title="New Circle"
                                                    component={() => (<Admin content={<PageNewCircle />} />)}
                                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                                    render={(props: any) => <PageNewCircle {...props} />}
                                                  />
                                                  <ProtectedRoute
                                                    exact
                                                    publicPage={false}
                                                    path="/circles/edit"
                                                    title="Edit Circle"
                                                    component={() => (<Admin content={<PageNewCircle />} />)}
                                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                                    render={(props: any) => <PageNewCircle {...props} />}
                                                  />
                                                  <ProtectedRoute
                                                    exact
                                                    publicPage={false}
                                                    path="/circles/members"
                                                    title="New Circle"
                                                    component={() => (<Admin content={<ManageMembersDetail />} />)}
                                                  />
                                                  <ProtectedRoute
                                                    exact
                                                    publicPage={false}
                                                    path="/circles/items"
                                                    title="New Circle"
                                                    component={() => (<Admin content={<CircleItems />} />)}
                                                  />

                                                  <ProtectedRoute
                                                    exact
                                                    publicPage
                                                    path="/public-profile"
                                                    title="Public Profile"
                                                    component={() => (
                                                      <Admin content={<PublicProfile />} />)}
                                                  />
                                                  <ProtectedRoute
                                                    exact
                                                    publicPage={false}
                                                    path="/private-profile"
                                                    title="Private Profile"
                                                    component={() => (<Admin content={<PrivateProfile />} />)}
                                                  />
                                                  <ProtectedRoute
                                                    exact
                                                    publicPage={false}
                                                    path={['/activity', '/activity/detail']}
                                                    title="activity"
                                                    component={() => (<Admin content={<PageActivity />} />)}
                                                  />
                                                  <Route
                                                    exact
                                                    path="/login"
                                                    render={() => (<SideForm content={<PageLogin />} />)}
                                                  />
                                                  <Route
                                                    exact
                                                    path="/user-completion"
                                                    render={() => (<SideForm content={<UserCompletion />} />)}
                                                  />
                                                  <ProtectedRoute
                                                    exact
                                                    path="/bookitem"
                                                    title="bookitem"
                                                    publicPage
                                                    component={() => (<Admin content={<BookItemPage />} />)}
                                                  />
                                                  <ProtectedRoute
                                                    exact
                                                    publicPage={false}
                                                    path="/checkout"
                                                    title="checkout"
                                                    component={() => (<Admin content={<Checkout />} />)}
                                                  />
                                                  <Route
                                                    exact
                                                    path={['/page*/*', '/page*', '/page']}
                                                    render={(props) => {
                                                      const pageTo = props.location.pathname.split('=')[1].split('/')[0];
                                                      const identity = props.location.pathname.split('=')[2];

                                                      switch (pageTo) {
                                                        case 'itemDetail': {
                                                          props.history.push('/bookitem', { bookItem: { idCatLndItem: identity } });
                                                          break;
                                                        }
                                                        case 'circleDetail': {
                                                          toast.info(t('global.only_available_in_app'));
                                                          props.history.push('/');
                                                          break;
                                                        }
                                                        case 'promotions': {
                                                          toast.info(t('global.only_available_in_app'));
                                                          props.history.push('/');
                                                          break;
                                                        }
                                                        default:
                                                          props.history.push('/');
                                                          break;
                                                      }

                                                      return null;
                                                    }}
                                                  />
                                                  <ProtectedRoute
                                                    publicPage
                                                    exact
                                                    path="/"
                                                    title="Home"
                                                    component={() => (<LandingPage content={<HomePage />} />)}
                                                  />
                                                  <ProtectedRoute
                                                    publicPage
                                                    exact
                                                    path={['/search/:search', '/search']}
                                                    title="search"
                                                    component={() => (<LandingPage content={<ExplorePage />} />)}
                                                  />
                                                  <ProtectedRoute
                                                    publicPage
                                                    exact
                                                    path="/circle"
                                                    component={() => <LandingPage content={<CirclePage />} />}
                                                  />
                                                  <ProtectedRoute
                                                    publicPage
                                                    exact
                                                    path={['/terms', '/privacy-policy', '/faq', '/about', '/guarantee', '/eula']}
                                                    component={() => <LandingPage content={<AncillaryPage />} />}
                                                  />
                                                  {/* <Route
                                exact
                                path={['/terms', '/privacy-policy', '/faq', '/about', '/guarantee']}
                                render={() => <LandingPage content={<AncillaryPage />} />}
                              /> */}
                                                  <Route path="*" render={() => (<LandingPage content={<PageNotFound />} />)} />
                                                </Switch>
                                              </BrowserRouter>
                                            </ExploreState>
                                          </CalendarState>
                                        </PayoutAccountState>
                                      </PaymentMethodsState>
                                    </MyCirclesListingState>
                                  </AddEditCirclesState>
                                </MyListingState>
                              </AddEditItemState>
                            </BookItemState>
                          </DeliveryActivityState>
                        </PickUpDropOffPicturesState>
                      </ActivityActionsState>
                    </ActivityState>
                  </PublicProfileState>
                </ShuftiProState>
              </SocketState>
            </FavoritesState>

          </AuthState>
        </HomeState>
      </RouterState>
      <ToastContainer />
    </>
  );
}

export default RouterApp;

import {
  Grid, Typography, Button, Stack, Card, Box,
} from '@mui/material';
// import { useHistory } from 'react-router-dom';
import BannerCirclesStyle from './BannerCircles.style';
import { BannerCirlcesModels } from './BannerCircles.models';

export default function BannerCircles(props: BannerCirlcesModels) {
  // const classes = useStyles();
  return (
    <Box sx={{ ...BannerCirclesStyle }}>
      <Card
        component="section"
        elevation={0}
        sx={{
          backgroundImage: `url(${props.kBackground})`, backgroundColor: props.kColorBg, overflow: 'visible',
        }}
        className="lmiwBase lmiwRadiusBottom lmiwRadiusTop"
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          maxWidth="xl"
        >
          <Grid xs={12} md={7} lg={7} item sx={{ position: 'relative' }}>
            <Box sx={{ height: { xs: '240px', sm: '300px', md: '320px' } }} />
            <Stack
              className="lmiwLeft lmiwRadiusBottom lmiwRadiusTop"
              direction="row"
              justifyContent={{
                xs: 'center', sm: 'center', md: 'flex-end', lg: 'center',
              }}
              alignItems="flex-end"
              sx={{ height: { xs: '240px', sm: '350px', md: '380px' } }}
            >
              <img
                alt=""
                src={props.kImage}
                srcSet={props.kImage}
                loading="lazy"
                height="100%"
              />
            </Stack>

          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            lg={5}
            className="lmiwRadiusBottom"
            // className={classes.lmiwRadiusBottom}
            sx={{ zIndex: '1', background: { xs: props.kColorBg, md: 'none' } }}
          >
            <Stack
              direction={{ xs: 'column', sm: 'row', md: 'column' }}
              justifyContent="space-between"
              alignItems={{ sm: 'center', md: 'flex-start' }}
              spacing={1}
              sx={{ m: { xs: 3, md: 0 } }}
            >
              <Box>
                <Typography
                  sx={{
                    color: props.kColorText,
                    fontWeight: 'bold',
                    lineHeight: 1,
                    marginBottom: { xs: 2, sm: 0, md: 2 },
                    fontSize: { xs: '24px', md: '35px' },
                  }}
                  component="h3"
                >
                  {props.kTitle}
                </Typography>
                {props.kText ? (
                  <Typography
                    sx={{
                      color: props.kColorText,
                      fontWeight: '300',
                      lineHeight: 1.4,
                      marginBottom: { xs: 2, sm: 0, md: 2 },
                      fontSize: { xs: '18px', md: '20px' },
                    }}
                    component="h5"
                  >
                    {props.kText}
                  </Typography>
                ) : ''}
              </Box>

              <Button
                onClick={() => props.kClick()}
                variant="contained"
                fullWidth={false}
                className="btn-textTransform-none"
                sx={{
                  minWidth: '160px', maxWidth: '200px', borderRadius: '50px', height: '44px', backgroundColor: props.kBtnColor,
                }}
              >
                {props.kBtnText}
              </Button>
            </Stack>
          </Grid>
          <Box
            className="lmiwGradientRight"
            sx={{
              background: `linear-gradient(to right, rgba(0, 0, 0, 0), ${props.kColorBg} 50%)`,
              display: { xs: 'none', md: 'block' },
            }}
          />
        </Grid>
      </Card>
    </Box>
  );
}

BannerCircles.defaultProps = {
  kColorText: 'white',

};

import { Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import MyCirclesListingContext from '../../../../../../context/myCirclesListing/myCirclesListingContext';

export default function LeaveCircle() {
  const {
    circle,
  }: any = useContext(MyCirclesListingContext);

  const { t } = useTranslation('global');

  return (
    <>
      {
          circle?.isOwner ? (
            <Typography>
              {t('circles.detail.msg_leave')}
            </Typography>
          ) : (
            <Typography>
              {t('circles.detail.msg_leave2')}
            </Typography>
          )
      }
    </>
  );
}

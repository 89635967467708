import { LmiTheme } from '../../../components/Common';

const PageNotFoundStyle = {
  backgroundColor: 'white',
  height: { md: 'calc(100vh - 120px)', xs: 'calc(100vh - 125px)' },
  padding: 1,
  h2: {
    fontWeight: 400,
    fontSize: 100,
    color: LmiTheme.light.systemBackground.primary,
  },
  h1: {
    color: LmiTheme.light.accent,
    fontWeight: 400,
  },
  h5: {
    textAlign: 'center',
    maxWidth: '80%',
    fontSize: { md: '18px', xs: '16px' },
    color: LmiTheme.light.systemBackground.primary,
  },
};

export default PageNotFoundStyle;

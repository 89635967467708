/* eslint-disable max-lines */
/* eslint import/no-unresolved: [2, { commonjs: true, amd: true }] */
import { useReducer } from 'react';
import * as AWS from 'aws-sdk/global';
import {
  ClsModAddRemoveItemCircle,
  ClsModCreateCircleDynamicLink,
  ClsModCreateUpdateCircle,
  ClsModCreateUpdateCircleUserMapAdress,
  ClsModEditCircleMember,
  ClsModEnableDisableCircle,
  ClsModGetCircleItemsEdit,
  ClsModGetUserItemInCircles,
  ClsModGetCircleMembersEdit,
  ClsModGetUserCircleEdit,
  ClsModLeaveCircle,
  ClsModUploadCirclePicture,
} from '@lendmeit/api_backend_lmi/dist/models';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  SET_INITIAL_CIRCLE,
  SET,
  INFO_IS_LOADING,
  CONDITION_ADD_EDIT,
  CHECK_CONDITIONS_VALIDATIONS,
  UPDATE_CONDITION_VALIDATION_STATUS,
  SET_CONDITION_EDIT_INDEX,
  CONDITIONS_UPDATE_LIST,
  REMOVE_CONDITION,
  CIRCLE_INFO_CHANGED,
  FILTER_CIRCLE_ITEMS,
  UPDATE_CIRCLES_LIST,
  UPDATE_REASON_DISABLE_ITEM,
  SET_ITEM_ACTIVE_TO_UPDATE,
  UPDATE_PAGE_ITEM_STATUS_UPDATE,
  MEMBER_DETAILS,
  UPDATED_MEMBER_PERMIT,
  CIRCLE_UPDATED_SUCCESSFULLY,
  UNSET_MEMBERS_EDIT,
  CHECK_VALIDATIONS,
  CIRCLE_UPDATE_ERROR,
  ACTIVE_CIRCLE_UPDATED,
  RE_SET_MEMBERS,
  SUCCESSFULLY_LEFT_CIRCLE,
  FILTER_CIRCLE_MEMBERS,
  SET_CIRCLE_ITEMS,
  VALID_ERRORS,
  CLEAN_ERRORS,
  CIRCLE_UPDATED_CLEAN,
} from '../../types';
import AddEditCirclesContext from './addEditCirclesContext';
import addEditCirclesReducer from './addEditCirclesReducer';
import ApiLMI from '../../services/apilmi';
import {
  AddEditCircleModel, circleItemElementModel, circleMemberElementModel, emptyAddEditCircleModel,
} from '../../page/BackOffice/Circles/AddCircle/AddCircle.models';

const AddEditCirclesState = (props: any) => {
  const { t } = useTranslation('global');
  const initialState: AddEditCircleModel = emptyAddEditCircleModel();
  const [state, dispatch] = useReducer(addEditCirclesReducer, initialState);
  AWS.config.region = process.env.REACT_APP_REGION;

  const updateDraftCircleInfo = async (values: any) => {
    if (state.circleInfoEdit?.idCatLndCircle == 0) {
      localStorage.setItem('draftCircleInfo', JSON.stringify(values));
    }
  };

  const updateDraftCircleTags = async (values: any) => {
    if (state.circleInfoEdit?.idCatLndCircle == 0) {
      localStorage.setItem('draftCircleTags', JSON.stringify(values));
    }
  };

  const updateDraftCircleConditionsList = async (values: any) => {
    if (state.circleInfoEdit?.idCatLndCircle == 0) {
      localStorage.setItem('draftCircleConditions', JSON.stringify(values));
    }
  };

  const setCircleDetailEdit = async (values: any) => {
    dispatch({
      type: SET_INITIAL_CIRCLE,
      circleInfoEdit: values,
    });
  };

  const validErrors = async (values: any) => {
    dispatch({
      type: VALID_ERRORS,
      errors: values,
    });
  };

  const updateCircleInfo = async (values: any) => {
    dispatch({
      type: CIRCLE_INFO_CHANGED,
      circleInfo: values,
    });
  };

  const setInitialCircleEdit = async () => {
    dispatch({
      type: SET_INITIAL_CIRCLE,
      circleInfoEdit: initialState,
      conditionsList: [],
    });
  };

  const setCircleInfo = async (circleModel: AddEditCircleModel) => {
    dispatch({
      type: SET,
      circleInfoEdit: circleModel,
      areFieldsValidated: false,
      deletedImages: [],
    });
  };

  const setCircleLocation = async (circleModel: AddEditCircleModel) => {
    dispatch({
      type: CIRCLE_INFO_CHANGED,
      circleInfo: {
        ...state.circleInfoEdit,
        lat: circleModel.lat,
        lon: circleModel.lon,
        address: circleModel.address,
        city: circleModel.city,
        zipCode: circleModel.zipCode,
        country: circleModel.country,
      },
      // conditionsList: state.conditionsList,
    });
  };

  const setCircleStatus = async (isPublic: boolean) => {
    dispatch({
      type: SET,
      circleInfoEdit: {
        ...state.circleInfoEdit,
        isPublic,
      },
    });
  };

  const setReasonToDisableItem = async (value: string) => {
    dispatch({
      type: UPDATE_REASON_DISABLE_ITEM,
      reasonToDisableItem: value,
    });
  };

  const setItemPrepareUpdate = async (selectedItemId: any, itemActive: any) => {
    dispatch({
      type: SET_ITEM_ACTIVE_TO_UPDATE,
      selectedItemId,
      itemActive,
    });
  };

  const setModalItemEditPage = async (page: number) => {
    dispatch({
      type: UPDATE_PAGE_ITEM_STATUS_UPDATE,
      pageEditItem: page,
    });
  };

  const isLoading = async () => {
    dispatch({
      type: INFO_IS_LOADING,
    });
  };

  const removeTag = async (index: number) => {
    const tags = state.circleInfoEdit?.tags.split(',');
    tags.splice(index, 1);
    const newTags = tags.join(',');
    updateDraftCircleInfo({
      ...state.circleInfoEdit,
      tags: newTags,
    });

    dispatch({
      type: CIRCLE_INFO_CHANGED,
      circleInfo: {
        ...state.circleInfoEdit,
        tags: newTags,
      },
    });
  };

  const addNewTag = async (newTag: string) => {
    const newTags = state.circleInfoEdit?.tags.length > 0 ? state.circleInfoEdit?.tags.concat(',', newTag) : newTag;
    updateDraftCircleInfo({
      ...state.circleInfoEdit,
      tags: newTags,
    });

    dispatch({
      type: CIRCLE_INFO_CHANGED,
      circleInfo: {
        ...state.circleInfoEdit,
        tags: newTags,
      },
    });
  };

  const getCircleEditInfo = async (idCircle: number) => {
    const _api = ApiLMI();
    let dataGetCircle: any = emptyAddEditCircleModel();
    let conditions: any = [];
    let circleItems: any = [];
    let circleMembers: any = [];

    isLoading();

    if (idCircle > 0) {
      const modGetCircleDetail: ClsModGetUserCircleEdit = {
        toc: 'qwerty',
        idCatLndCircle: idCircle,
      };
      dataGetCircle = await _api.circleGetUserCircleEdit(modGetCircleDetail);

      conditions = JSON.parse(dataGetCircle.data).Response[0]?.conditions?.length > 0
        ? JSON.parse(dataGetCircle.data).Response[0].conditions.split(',')
        : [];

      const modGetCircleItems: ClsModGetCircleItemsEdit = {
        toc: 'qwerty',
        idCatLndCircle: idCircle,
      };

      circleItems = await _api.circleGetCircleItemsEdit(modGetCircleItems);

      const modGetCircleMembersEdit: ClsModGetCircleMembersEdit = {
        toc: 'qwerty',
        idCatLndCircle: idCircle,
      };

      circleMembers = await _api.circleGetCircleMembersEdit(modGetCircleMembersEdit);

      dispatch({
        type: SET,
        circleInfoEdit: dataGetCircle?.data ? JSON.parse(dataGetCircle?.data)?.Response[0] : dataGetCircle,
        originalCircleInfoEdit: dataGetCircle?.data ? JSON.parse(dataGetCircle?.data)?.Response[0] : [],
        conditionsList: conditions || '',
        circleAddress: dataGetCircle?.data ? JSON.parse(dataGetCircle?.data)?.Response[0] : [],
        circleItemsEdit: circleItems?.data ? JSON.parse(circleItems?.data)?.Response : [],
        circleMembersEdit: circleMembers?.data ? JSON.parse(circleMembers?.data)?.Response : [],
        circleItemsEditFilter: circleItems?.data ? JSON.parse(circleItems?.data)?.Response : [],
        circleMembersEditFilter: circleMembers?.data ? JSON.parse(circleMembers?.data)?.Response : [],
        reasonToDisableItem: '',
        pageEditItem: 1,
      });
    } else {
      const draftCircleInfo = localStorage.getItem('draftCircleInfo');
      const draftCircleConditions = localStorage.getItem('draftCircleConditions');
      dispatch({
        type: SET,
        circleInfoEdit: draftCircleInfo ? JSON.parse(draftCircleInfo) : dataGetCircle,
        originalCircleInfoEdit: dataGetCircle?.data ? JSON.parse(dataGetCircle?.data)?.Response[0] : [],
        conditionsList: draftCircleConditions ? JSON.parse(draftCircleConditions) : conditions,
        circleAddress: dataGetCircle?.data ? JSON.parse(dataGetCircle?.data)?.Response[0] : [],
        circleItemsEdit: circleItems?.data ? JSON.parse(circleItems?.data)?.Response : [],
        circleMembersEdit: circleMembers?.data ? JSON.parse(circleMembers?.data)?.Response : [],
        circleItemsEditFilter: circleItems?.data ? JSON.parse(circleItems?.data)?.Response : [],
        circleMembersEditFilter: circleMembers?.data ? JSON.parse(circleMembers?.data)?.Response : [],
        reasonToDisableItem: '',
        pageEditItem: 1,
      });
    }
  };

  const updateConditionInfo = async (values: any) => {
    dispatch({
      type: CONDITION_ADD_EDIT,
      addEditConditionInfo: values,
      checkConditionValidate: false,
    });
  };

  const checkConditionValidations = async (values: any) => {
    dispatch({
      type: CHECK_CONDITIONS_VALIDATIONS,
      checkConditionValidate: values,
    });
  };

  const setConditionIndex = async (index: number) => {
    dispatch({
      type: SET_CONDITION_EDIT_INDEX,
      conditionIndex: index,
    });
  };

  const updateConditionValidationStatus = async (values: any) => {
    dispatch({
      type: UPDATE_CONDITION_VALIDATION_STATUS,
      areConditionFieldsValidated: values,
      checkConditionValidate: false,
    });
  };

  const updateConditionsList = async (values: any) => {
    dispatch({
      type: CONDITIONS_UPDATE_LIST,
      conditionsList: values,
      checkConditionValidate: false,
    });
  };

  const deleteCondition = async () => {
    state.conditionsList.splice(state.conditionIndex, 1);
    dispatch({
      type: REMOVE_CONDITION,
      payload: state.conditionsList,
    });
    updateDraftCircleConditionsList(state.conditionsList);

    return true;
  };

  const filterItemManageElements = async (searchValue: any, idCircle: number) => {
    const _api = ApiLMI();
    const modGetCircleItems: ClsModGetCircleItemsEdit = {
      toc: 'qwerty',
      idCatLndCircle: idCircle,
    };
    const circleItems: any = await _api.circleGetCircleItemsEdit(modGetCircleItems);
    const items = circleItems?.data ? JSON.parse(circleItems?.data)?.Response : [];
    const filteredArr = items.filter((element: any) => element.name?.toLowerCase().includes(searchValue));
    dispatch({
      type: FILTER_CIRCLE_ITEMS,
      circleItemsEditFilter: filteredArr,
    });
  };

  const filterMemberManageElements = async (searchValue: any) => {
    const filteredArr = state.circleMembersEdit.filter((element: any) => element?.memberName?.toLowerCase().includes(searchValue));
    dispatch({
      type: FILTER_CIRCLE_MEMBERS,
      circleMembersEditFilter: filteredArr,
    });
  };

  const addRemoveItemCircle = async () => {

  };

  const circleManageUpdateItem = async (
    idCatLndItem: number,
    active: boolean,
    setAvailableForCircles: boolean = false,
    blockComments: string,
    idCatLndCircle: number = 0,
  ) => {
    const _api = ApiLMI();

    const currentItems = state.circleItemsEdit;
    const firstIndex = currentItems.findIndex((element: circleItemElementModel) => element.idCatLndItem === idCatLndItem);

    if (firstIndex !== -1) {
      currentItems[firstIndex].active = active;
      currentItems[firstIndex].circlesAvailable = setAvailableForCircles == true ? true : currentItems[firstIndex].circlesAvailable;

      const bodyAddRemoveItemCircle: ClsModAddRemoveItemCircle = {
        toc: 'qwery',
        idCatLndCircle: idCatLndCircle > 0 ? idCatLndCircle : state.circleInfoEdit?.idCatLndCircle,
        idCatLndItem,
        blockComments,
        setAvailableForCircles,
        addRemove: active,
      };
      const dataAddRemoveItemCircle: any = await _api.circleAddRemoveItemCircle(bodyAddRemoveItemCircle);
      if (JSON.parse(dataAddRemoveItemCircle.data).Error == '') {
        dispatch({
          type: UPDATE_CIRCLES_LIST,
          circleItemsEdit: currentItems,
          pageEditItem: 1,
          reasonToDisableItem: '',
        });
      }
    } else {
      console.error('Element not found in the currentItems array.');
    }
  };

  const itemManageUpdateCircles = async (
    idCatLndItem: number,
    active: boolean,
    setAvailableForCircles: boolean = false,
    blockComments: string,
    idCatLndCircle: number = 0,
  ) => {
    const _api = ApiLMI();

    const modGetCircleItems: ClsModGetCircleItemsEdit = {
      toc: 'qwerty',
      idCatLndCircle,
    };

    const circleItems: any = await _api.circleGetCircleItemsEdit(modGetCircleItems);

    const currentItems = JSON.parse(circleItems?.data)?.Response;

    const bodyAddRemoveItemCircle: ClsModAddRemoveItemCircle = {
      toc: 'qwery',
      idCatLndCircle,
      idCatLndItem,
      blockComments,
      setAvailableForCircles,
      addRemove: active,
    };
    const dataAddRemoveItemCircle: any = await _api.circleAddRemoveItemCircle(bodyAddRemoveItemCircle);

    if (JSON.parse(dataAddRemoveItemCircle.data).Error == '') {
      toast.success(t('circle_detail.item_updated'));
      dispatch({
        type: UPDATE_CIRCLES_LIST,
        circleItemsEdit: currentItems,
        pageEditItem: 1,
        reasonToDisableItem: '',
      });
    }
  };

  const memberDetails = async (member: circleMemberElementModel) => {
    try {
      if (member) {
        // setItemImages(images);
        dispatch({
          type: MEMBER_DETAILS,
          payload: member,
        });
      }
    } catch (error) {
      toast.error(t('global.server_error_details'));
    }
  };

  const updateMemberCirclePermission = async (
    idCatLndCircleMember: number,
    isAdmin: boolean,
    isOwner: boolean,
    isBlocked: boolean,
    rentOnly: boolean,
    idCatLndCircle: number = 0,
  ) => {
    try {
      const _api = ApiLMI();
      const bodyEditCircleMember: ClsModEditCircleMember = {
        blocked: isBlocked,
        isAdmin,
        isOwner,
        toc: 'qwer',
        idCatLndCircleMember,
        idCatLndCircle,
        rentOnly,
        // rentOnly
      };
      const respEditcircleMember: any = await _api.circleEditCircleMember(bodyEditCircleMember);
      if (JSON.parse(respEditcircleMember.data).Error == '') {
        toast.success(t('circle_detail.member_updated'));
        const index = state.circleMembersEdit.findIndex((m: any) => m.idCatLndCircleMember === idCatLndCircleMember);
        state.circleMembersEdit[index].rentOnly = rentOnly;
        state.memberSelected.rentOnly = rentOnly;
        dispatch({
          type: UPDATED_MEMBER_PERMIT,
          memberUpdated: true,
        });
      }
    } catch (error) {
      toast.error(t('global.server_error_details'));
    }
  };

  const submitUpdateCircleEdit = async () => {
    const _api = ApiLMI();
    try {
      const idtoast = toast.loading(t('listing_data.saving_info'));
      document.body.classList.add('not-scroll');
      let newIdCatLndCircleAddress = 0;
      if (state?.circleInfoEdit?.idCatLndCircle == 0
        || state?.circleAddress.address != state.circleInfoEdit.address) {
        const createAddressBody: ClsModCreateUpdateCircleUserMapAdress = {
          active: true,
          adress: state?.circleInfoEdit?.address,
          lat: state?.circleInfoEdit?.lat,
          lng: state?.circleInfoEdit?.lon,
          zipCode: state?.circleInfoEdit?.zipCode,
          city: state?.circleInfoEdit?.city,
          country: state?.circleInfoEdit?.country,
          idCatLndCircleAddress: state?.circleInfoEdit?.idCatLndCircleAddress,
          name: state?.circleInfoEdit?.name,
          state: state?.circleInfoEdit?.state,
          street: state?.circleInfoEdit?.street,
          toc: 'qwerty',
        };
        const responseUpdateAddress: any = await _api.circleCreateUpdateCircleUserMapAdress(createAddressBody);
        const parsedResponseUpdateAddress = JSON.parse(responseUpdateAddress.data);

        if (parsedResponseUpdateAddress.Error == '') {
          newIdCatLndCircleAddress = parsedResponseUpdateAddress.Response[0].idCatLndCircleAddress;
        }
      }
      let picturePath: any;
      if (state?.circleInfoEdit?.idCatLndCircle != 0) {
        if (state?.originalCircleInfoEdit?.circlePicture != state?.circleInfoEdit.circlePicture) {
          const bodyUploadPicture: ClsModUploadCirclePicture = {
            circlePictureBase: state?.circleInfoEdit.circlePicture.split(',')[1],
            toc: 'qwer',
          };
          picturePath = await _api.circleUploadCirclePicture(bodyUploadPicture);
        } else {
          console.log('test');
        }
      } else {
        const bodyUploadPicture: ClsModUploadCirclePicture = {
          circlePictureBase: state?.circleInfoEdit.circlePicture.split(',')[1],
          toc: 'qwer',
        };
        picturePath = await _api.circleUploadCirclePicture(bodyUploadPicture);
      }

      const bodyCreateUpdateCircle: ClsModCreateUpdateCircle = {
        ...state?.circleInfoEdit,
        circlePicture: picturePath != undefined ? JSON.parse(picturePath.data).Response[0].fileName : state?.circleInfoEdit.circlePictureFile,
        idCatLndCircleAddress: newIdCatLndCircleAddress > 0 ? newIdCatLndCircleAddress : state?.circleInfoEdit?.idCatLndCircleAddress,
        conditions: state?.conditionsList.join(','),
      };
      const idSavedCircle: any = await _api.circleCreateUpdateCircle(bodyCreateUpdateCircle);
      const parsedSavedCircle: any = JSON.parse(idSavedCircle.data);
      if (parsedSavedCircle.Error == '') {
        const idForDynamic = parsedSavedCircle.Response[0].idCatLndCircle;
        const bodyCreateDynamicLink: ClsModCreateCircleDynamicLink = {
          idCatLndCircle: idForDynamic,
          toc: 'qwer',
        };

        const createdDeepLink: any = await _api.circleCreateCircleDynamicLink(bodyCreateDynamicLink);
        const parsedCreatedDeepLink = JSON.parse(createdDeepLink.data);

        if (parsedCreatedDeepLink.Error == '') {
          toast.update(idtoast, {
            render: t('listing_data.saved'), type: 'success', icon: '🟢', isLoading: false, autoClose: 1500,
          });
          dispatch({
            type: CIRCLE_UPDATED_SUCCESSFULLY,
          });
          dispatch({
            type: CLEAN_ERRORS,
          });
          document.body.classList.remove('not-scroll');
        }
      } else {
        dispatch({
          type: CIRCLE_UPDATE_ERROR,
          circleUpdateError: !state.circleUpdateError,
          // circleUpdated: false,
        });
      }

      // const
    } catch (error) {
      document.body.classList.remove('not-scroll');
      dispatch({
        type: CIRCLE_UPDATE_ERROR,
        // circleUpdateError: !state.circleUpdateError,
      });
      console.log(error);
    }
  };

  const unsetMembersEdit = async () => {
    dispatch({
      type: UNSET_MEMBERS_EDIT,
    });
  };

  const checkValidations = async () => {
    const circleInfo = state.circleInfoEdit;
    if (
      circleInfo.circlePicture.length == 0
      || circleInfo.name.length == 0
      || circleInfo.dscription.length == 0
      || circleInfo.tags.length == 0
      || circleInfo.address.length == 0
    ) {
      dispatch({
        type: VALID_ERRORS,
        errors: {
          circlePicture: circleInfo.circlePicture.length == 0,
          name: circleInfo.name.length == 0,
          dscription: circleInfo.dscription.length == 0,
          tags: circleInfo.tags.length == 0,
          addressCircle: circleInfo.address.length == 0,
        },
      });
    } else {
      submitUpdateCircleEdit();
    }
    dispatch({
      type: CHECK_VALIDATIONS,
      checkValidate: !state.checkValidate,
    });
  };

  const enableDisableCircle = async () => {
    const _api = ApiLMI();
    const idtoast = toast.loading(t('global.saving_info'));
    try {
      const bodyChangeActive: ClsModEnableDisableCircle = {
        active: !state.circleInfoEdit?.active,
        idCatLndCircle: state.circleInfoEdit?.idCatLndCircle,
        toc: 'toc',
      };
      const changedCircleActive: any = await _api.circleEnableDisableCircle(bodyChangeActive);

      const parsedChangedCircleActive = JSON.parse(changedCircleActive.data);

      if (parsedChangedCircleActive.Error == '') {
        dispatch({
          type: ACTIVE_CIRCLE_UPDATED,
          circleInfo: {
            ...state.circleInfoEdit,
            active: !state.circleInfoEdit?.active,
          },
        });
        toast.update(idtoast, {
          render: t('listing_data.saved'), type: 'success', icon: '🟢', isLoading: false, autoClose: 1500,
        });
        document.body.classList.remove('not-scroll');
      } else {
        toast.dismiss();
      }
    } catch (error) {
      toast.dismiss();
    }
  };

  const confirmLeaveCircle = async (idCatLndCircle: number, isOwnerLeaving: boolean, newOwnerId: number) => {
    const _api = ApiLMI();
    const members = state.circleMembersEdit;
    try {
      const leaveBody: ClsModLeaveCircle = {
        toc: 'toc',
        idCatLndCircle,
        isOwnerLeaving,
        newOwnerId,
      };
      console.log(leaveBody, _api);

      const leaveCircleResp: any = await _api.circleLeaveCircle(leaveBody);

      const parsedLeaveCircleResp = JSON.parse(leaveCircleResp.data);
      if (parsedLeaveCircleResp.Error == '') {
        dispatch({
          type: RE_SET_MEMBERS,
          payload: members,
        });
        toast.success('Successfully left circle');
        dispatch({
          type: SUCCESSFULLY_LEFT_CIRCLE,
        });
      } else {
        dispatch({
          type: RE_SET_MEMBERS,
          payload: members,
        });
      }
    } catch (error) {
      dispatch({
        type: RE_SET_MEMBERS,
        payload: members,
      });
      console.log(error);
    }
  };

  const getCircleItems = async (idCircle: number) => {
    isLoading();
    const _api = ApiLMI();
    const modGetCircleItems: ClsModGetCircleItemsEdit = {
      toc: 'qwerty',
      idCatLndCircle: idCircle,
    };

    const circleItems: any = await _api.circleGetCircleItemsEdit(modGetCircleItems);
    dispatch({
      type: CIRCLE_UPDATED_CLEAN,
    });

    dispatch({
      type: SET_CIRCLE_ITEMS,
      circleItemsEdit: circleItems?.data ? JSON.parse(circleItems?.data)?.Response : [],
      circleItemsEditFilter: circleItems?.data ? JSON.parse(circleItems?.data)?.Response : [],
    });

    return circleItems?.data ? JSON.parse(circleItems?.data)?.Response : [];
  };

  const existsItemsInCircles = async (idItems: number) => {
    const _api = ApiLMI();
    // const modGetCircleItems : ClsModGetCircleItemsEdit = {
    const modGetCircleItems: ClsModGetUserItemInCircles = {
      toc: 'qwerty',
      idCatLndItem: idItems,
    };

    // const circleItems: any = await _api.circleGetCircleItemsEdit(modGetCircleItems);
    const circleItems: any = await _api.circleGetUserItemInCircles(modGetCircleItems);
    return circleItems?.data ? JSON.parse(circleItems?.data)?.Response : [];
  };

  const cleanErrorsCircles = async () => {
    dispatch({
      type: CLEAN_ERRORS,
    });
  };

  return (
    <AddEditCirclesContext.Provider
      value={
        {
          ...state,
          circleInfoEdit: state.circleInfoEdit,
          addEditConditionInfo: state.addEditConditionInfo,
          checkConditionValidate: state.checkConditionValidate,
          areConditionFieldsValidated: state.areConditionFieldsValidated,
          changedConditionFields: state.changedConditionFields,
          conditionsList: state.conditionsList,
          conditionIndex: state.conditionIndex,
          circleItemsEdit: state.circleItemsEdit,
          circleMembersEdit: state.circleMembersEdit,
          reasonToDisableItem: state.reasonToDisableItem,
          selectedItemId: state.selectedItemId,
          itemActive: state.itemActive,
          pageEditItem: state.pageEditItem,
          memberSelected: state.memberSelected,
          memberUpdated: state.memberUpdated,
          circleAddress: state.circleAddress,
          originalCircleInfoEdit: state.originalCircleInfoEdit,
          circleUpdated: state.circleUpdated,
          checkValidate: state.checkValidate,
          circleUpdateError: state.circleUpdateError,
          loading: state.loading,
          errors: state.errors,
          setConditionIndex,
          setCircleInfo,
          setCircleDetailEdit,
          setCircleLocation,
          setInitialCircleEdit,
          setReasonToDisableItem,
          setCircleStatus,
          getCircleEditInfo,
          checkConditionValidations,
          updateCircleInfo,
          updateConditionValidationStatus,
          updateConditionsList,
          updateConditionInfo,
          deleteCondition,
          setItemPrepareUpdate,
          circleManageUpdateItem,
          itemManageUpdateCircles,
          addRemoveItemCircle,
          setModalItemEditPage,
          filterItemManageElements,
          removeTag,
          addNewTag,
          isLoading,
          memberDetails,
          updateMemberCirclePermission,
          submitUpdateCircleEdit,
          unsetMembersEdit,
          checkValidations,
          enableDisableCircle,
          confirmLeaveCircle,
          filterMemberManageElements,
          getCircleItems,
          cleanErrorsCircles,
          validErrors,
          existsItemsInCircles,
          updateDraftCircleInfo,
          updateDraftCircleTags,
          updateDraftCircleConditionsList,
        }
      }
    >
      {props.children}
    </AddEditCirclesContext.Provider>
  );
};

export default AddEditCirclesState;

import { LmiColorPalette, LmiTheme } from '../../../../../../components/Common';

const ActivityStatusStyle = {
  width: '100%',
  '.MuiPaper-rounded': {
    borderRadius: 2,
    overflow: 'hidden',
  },
  // '.lmiw-status-text': {
  //   minHeight: '22px',
  //   fontSize: '18px',
  //   fontWeight: 'bold',
  //   lineHeight: '2.72rem',
  // },
  '.lmiw-status-title': {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  '.lmiw-status-orange': {
    backgroundColor: LmiColorPalette.orangeScheme[50],
    color: LmiColorPalette.orangeScheme[900],
  },
  '.lmiw-status-red': {
    backgroundColor: LmiColorPalette.redScheme[50],
    color: LmiColorPalette.redScheme[900],
  },
  '.lmiw-status-green': {
    backgroundColor: LmiColorPalette.greenScheme[50],
    color: LmiColorPalette.greenScheme[900],
  },
  '.lmiw-status-gray': {
    backgroundColor: LmiColorPalette.grayAccentScheme[100],
    color: LmiColorPalette.grayAccentScheme[800],
  },
  '.lmiw-status-pink': {
    backgroundColor: LmiColorPalette.pinkScheme[50],
    color: LmiColorPalette.pinkScheme[900],
  },
  '.lmiw-status-purple': {
    backgroundColor: LmiColorPalette.purpleScheme[50],
    color: LmiColorPalette.purpleScheme[900],
  },
  '.lmiw-status-blue': {
    backgroundColor: LmiColorPalette.deepBlueScheme[50],
    color: LmiColorPalette.deepBlueScheme[900],
  },
  '.lmiw-status-brandblue': {
    backgroundColor: LmiColorPalette.blueScheme[50],
    color: LmiColorPalette.blueScheme[900],
  },

  '.lmiw-status-header': {
    '.lmiw-status-btn-progress': {
      color: LmiTheme.light.accent,
      fontSize: '14px',
      textDecoration: 'underline',
      cursor: 'pointer',
      fontWeight: 'bold',
    },
  },

  button: {
    borderRadius: '10px',
    textTransform: 'capitalize',
    px: 4,
  },
};

export default ActivityStatusStyle;

import { useEffect, useState, useContext } from 'react';
import { ContentCopyRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  CardContent,
  CardMedia,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  MyPromoCodeImage,
  LmiTheme,
  MainInformationTabPanel,
} from '..';
import { TabPanelMyPromoCodeModels } from './TabPanelMyPromoCode.models';
import ShareButtons from '../../../Listing/Components/AddItem/Components/ShareButtons/ShareButtons';
import publicProfileContext from '../../../../../context/publicProfile/publicProfileContext';

function TabPanelMyPromoCode(props: TabPanelMyPromoCodeModels) {
  const [cupon, setCupon] = useState('');
  const [link, setLink] = useState('');
  const pProfileContext = useContext(publicProfileContext);

  const {
    privateProfileInfo,
    loading,
  }: any = pProfileContext;

  useEffect(() => {
    const { userCode = '', referralLink = '' } = privateProfileInfo;
    setLink(referralLink);
    setCupon(userCode);
  }, [loading]);

  const handleCopy = () => {
    navigator.clipboard.writeText(cupon);
    toast.success(t('global.coupon_copied'));
  };

  const { t } = useTranslation('global');

  return (
    <>
      <MainInformationTabPanel
        kTabValue={props.kTabValue}
        kTabIndex={props.kTabIndex}
        kContent={
          (
            <>
              <CardContent sx={{ p: 3 }}>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  py={3}
                >
                  <CardMedia
                    component="img"
                    alt="My Promo Code"
                    image={MyPromoCodeImage}
                    sx={{ width: '40%' }}
                  />
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    // spacing={5}
                    px={3}
                    py={1}
                    my={1}
                    sx={{ border: `1px solid ${LmiTheme.light.systemBackground.separator}`, borderRadius: '7px' }}
                  >
                    {cupon}
                    <ContentCopyRounded
                      onClick={handleCopy}
                      fontSize="large"
                      color="primary"
                      sx={{ pl: 1 }}
                      style={{ cursor: 'pointer' }}
                    />
                  </Stack>
                  <Box
                    sx={{
                      fontSize: 'h6.fontSize',
                      fontWeight: 700,
                      pt: 1,
                    }}
                  >
                    {t('global.invite_friends')}
                  </Box>
                  <Box
                    sx={{
                      fontSize: 'subtitle2.fontSize',
                      fontWeight: 500,
                      color: LmiTheme.light.systemBackground.tertiary,
                      maxWidth: '90%',
                      textAlign: 'center',
                      pb: 2,
                    }}
                  >
                    {t('global.invite_friends_benefits')}
                  </Box>
                  <Button
                    onClick={undefined}
                    disableElevation
                    variant="outlined"
                    size="large"
                    sx={{
                      px: 3,
                      fontSize: 'subtitle1.fontSize',
                      textTransform: 'none',
                      fontWeight: 'bold',
                    }}
                  >
                    <ShareButtons
                      deepLink={link}
                    />
                  </Button>
                </Stack>
              </CardContent>
            </>
          )
        }
      />
    </>
  );
}

export default TabPanelMyPromoCode;

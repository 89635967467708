/* eslint-disable no-unused-expressions */
import * as React from 'react';

import { ExpandLessRounded } from '@mui/icons-material';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Divider,
  List,
  Box,
} from '@mui/material';
import LmiTheme from '../../../themes/lmi-mui-theme/lmi-theme';
import { AccessoriesOptionsModels } from './AccessoriesOptions.models';
import Accessory from './Accessory';
import {
  PRECING_HOURLY,
  PRECING_DAILY,
  PRECING_WEEKLY,
  PRECING_MONTHLY,
  RENT,
} from '../../../types/index';
import { ItemAccessoriesModel } from '../../../page/BackOffice/Listing/Components/AddItem/Components/ItemAccessories/ItemAccessories.models';

function AccessoriesOptions(props: AccessoriesOptionsModels) {
  const {
    accesories: accesoriesList,
    precingType,
    editAccesory,
    bookType,
    currency,
  }: any = props;

  const [expanded, setExpanded] = React.useState<string | false>('expanded');
  const handleChangeExpanded = (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  const storageAccesories: any = localStorage.getItem('storageAccesories');

  const selectChange = editAccesory ? (e: React.ChangeEvent<HTMLInputElement>,
    { idRelItemAccesorie }: any) => {
    const updateAccessory: any = storageAccesories == null ? [] : storageAccesories.split(',');
    if (e.target.checked) {
      updateAccessory.push(idRelItemAccesorie);
      localStorage.setItem('storageAccesories', updateAccessory);
    } else {
      const deleteAccessory = updateAccessory.filter((id: any) => parseInt(id) !== idRelItemAccesorie);
      deleteAccessory[0] != null ? localStorage.setItem('storageAccesories', deleteAccessory)
        : localStorage.removeItem('storageAccesories');
    }
    editAccesory({ checked: e.target.checked, idRelItemAccesorie });
  } : null;

  return (
    <>
      <Box my={3}>
        <Accordion
          expanded={expanded === 'expanded'}
          onChange={handleChangeExpanded('expanded')}
          elevation={0}
          sx={{ border: `1px solid ${LmiTheme.light.systemBackground.separator}`, borderRadius: '4px' }}
        >
          <AccordionSummary
            expandIcon={<ExpandLessRounded sx={{ fontSize: '2em', color: LmiTheme.light.accent }} />}
          >
            <Typography sx={{ fontSize: 'h6.fontSize', fontWeight: 'bold' }}>
              {props.kTitle}
            </Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails sx={{ padding: '0' }}>
            <List sx={{ p: 0 }}>
              {
              accesoriesList && accesoriesList.length > 0
                /* eslint-disable react/jsx-props-no-spreading */
                ? accesoriesList.map((a: ItemAccessoriesModel, i: any) => {
                  let price:number;
                  if (bookType == RENT) {
                    switch (precingType) {
                      case PRECING_HOURLY:
                        price = a.hourlyRentalPrice ?? 0;
                        break;
                      case PRECING_DAILY:
                        price = a.dailyRentalPrice ?? 0;
                        break;
                      case PRECING_WEEKLY:
                        price = a.weeklyRentalPrice ?? 0;
                        break;
                      case PRECING_MONTHLY:
                        price = a.monthlyRentalPrice ?? 0;
                        break;
                      default:
                        price = 0;
                        break;
                    }
                  } else {
                    price = a.replacementCost ?? 0;
                  }

                  return (
                    <Accessory
                      key={a.idRelItemAccesorie}
                      kTitle={a.name}
                      kDescription={a.dscription}
                      kPrice={price}
                      kPriceType={bookType == RENT ? precingType : ''}
                      kValue={i}
                      kDivider={i < accesoriesList.length - 1 ?? false}
                      kChecked={a.isAdded ?? false}
                      kIsReadOnly={props.kIsReadOnly}
                      kCurrency={currency}
                      // eslint-disable-next-line func-names
                      funUpdate={selectChange ?? function () { }}
                      {...a}
                    />
                  );
                })
                : null
              }
            </List>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
}

AccessoriesOptions.defaultProps = {
  kTitle: 'Accessories',
};

export default AccessoriesOptions;

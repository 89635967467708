import { LmiTheme } from '../..';

const AccordionLinkStyles = {
  '&:last-child': {
    borderBottom: { xs: `1px solid ${LmiTheme.light.systemBackground.separator}`, sm: 'inherit' },
  },
  '.lmiw-accordion-container': {
    backgroundColor: { xs: LmiTheme.light.systemBackground.solid, sm: 'inherit' },
    borderTop: { xs: `1px solid ${LmiTheme.light.systemBackground.separator}`, sm: 'inherit' },
    px: { xs: 2, sm: 'inherit' },
    pb: { xs: 0, sm: 'inherit' },
    borderRadius: '0px !important',
    '.lmiw-summary': {
      cursor: { xs: 'pointer', sm: 'auto' },
      minHeight: '0',
      mb: '15px',
      pt: { xs: '15px', md: '0' },
      '.MuiAccordionSummary-content': {
        margin: 0,
        cursor: { xs: 'pointer', sm: 'auto' },
      },
    },
    '.lmiw-summary-title': {
      fontSize: '14px',
      color: 'black',
      fontWeight: 'bold',
    },
    '.lmiw-summary-link': {
      cursor: 'pointer',
      fontSize: '14px',
      // lineHeight: '1.5rem',

      mb: '10px',
      color: LmiTheme.light.systemBackground.secondary,
      '&:hover': {
        color: LmiTheme.light.systemBackground.primary,
      },
    },
  },

};

export default AccordionLinkStyles;

/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import SwiperCore, { EffectCoverflow, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import IPData from 'ipdata';
import {
  Stack, Box, Typography, Button,
} from '@mui/material';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { SectionTitle } from '../../../../../components/Common';
import newFeaturesStyle from './NewFeatures.style';

import { NewFeaturesSwiperModels } from './NewFeatures.models';

import delivery from '../../../../../assets/new_features/delivery.webp';
import motocross from '../../../../../assets/new_features/motocross.webp';
import walk from '../../../../../assets/new_features/walk.webp';
import bike from '../../../../../assets/new_features/bike.webp';
import getNoticed from '../../../../../assets/new_features/get_noticed_full.webp';
import expanding from '../../../../../assets/new_features/expanding.webp';

SwiperCore.use([EffectCoverflow, Pagination]);

const images: any = [
  {
    image: delivery,
    name: 'new_features.delivery_available',
    description: 'new_features.delivery_available_txt',
    description2: 'new_features.delivery_available_txt2',

  },
  {
    image: motocross,
    name: 'new_features.earn_money',
    description: 'new_features.earn_money_txt',
    description2: 'new_features.earn_money_txt2',

  },
  {
    image: walk,
    name: 'new_features.schedule_organized',
    description: 'new_features.schedule_organized_txt',
    description2: 'new_features.schedule_organized_txt2',

  },
  {
    image: bike,
    name: 'new_features.list_accessories',
    description: 'new_features.list_accessories_txt',
    description2: 'new_features.list_accessories_txt2',

  },
  {
    image: getNoticed,
    name: 'new_features.get_noticed',
    description: 'new_features.get_noticed_txt',
    description2: 'new_features.get_noticed_txt2',
  },
  {
    image: expanding,
    name: 'new_features.we_expanding',
    description: 'new_features.we_expanding_txt',
    description2: 'new_features.we_expanding_txt2',
  },
];

const imagesWithoutRoadie: any = [
  {
    image: walk,
    name: 'new_features.schedule_organized',
    description: 'new_features.schedule_organized_txt',
    description2: 'new_features.schedule_organized_txt2',

  },
  {
    image: motocross,
    name: 'new_features.earn_money',
    description: 'new_features.earn_money_txt',
    description2: 'new_features.earn_money_txt2',

  },
  {
    image: bike,
    name: 'new_features.list_accessories',
    description: 'new_features.list_accessories_txt',
    description2: 'new_features.list_accessories_txt2',

  },
  {
    image: getNoticed,
    name: 'new_features.get_noticed',
    description: 'new_features.get_noticed_txt',
    description2: 'new_features.get_noticed_txt2',
  },
  {
    image: expanding,
    name: 'new_features.we_expanding',
    description: 'new_features.we_expanding_txt',
    description2: 'new_features.we_expanding_txt2',
  },
];

function getIpData() {
  const [countryCode, setCountryCode] = useState('');

  useEffect(() => {
    const countryCodeSession = sessionStorage.getItem('country_code');
    setCountryCode(countryCodeSession || '');
    if (!countryCodeSession || countryCodeSession == '') {
      const ipdata = new IPData(`${process.env.REACT_APP_IPDATA}`);
      ipdata.lookup().then((data) => {
        setCountryCode(data.country_code);
        sessionStorage.setItem('country_code', data.country_code);
      });
    }
  }, []);

  return countryCode;
}

function SwiperContent(props: NewFeaturesSwiperModels) {
  const countryCode = getIpData();
  return (
    <Swiper
      effect="coverflow"
      initialSlide={countryCode === 'US' ? 4 : 3}
      preventClicks
      slidesPerView={2}
      centeredSlides
      speed={1000}
      loop
      grabCursor
      onSlideChange={() => { props.slideMove(); }}
      pagination={{
        clickable: true,
        dynamicBullets: true,
      }}
      coverflowEffect={
        {
          rotate: 0,
          stretch: 0,
          depth: 600,
          modifier: 1,
          slideShadows: true,
        }
      }
      breakpoints={{

        1200: {
          slidesPerView: 2,
          coverflowEffect: {
            stretch: 0,
            depth: 600,
          },
        },

        992: {
          slidesPerView: 1.7,
          coverflowEffect: {
            stretch: 125,
            depth: 600,
          },
        },

        768: {
          slidesPerView: 1.5,
          coverflowEffect: {
            stretch: 145,
            depth: 700,
          },
        },

        600: {
          slidesPerView: 1.2,
          coverflowEffect: {
            stretch: 265,
            depth: 600,
          },
        },

        1: {
          slidesPerView: 1,
          coverflowEffect: {
            stretch: 0,
            depth: 700,
          },
        },

      }}
      navigation
    // navigation={{
    //   prevEl: '.prev',
    //   nextEl: '.next',
    // }}
    >
      {props.children}
    </Swiper>
  );
}

export default function NewFeatures() {
  const [checked, setChecked] = useState(false);
  const [, setCheckedTimeOut] = useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
    if (checked) {
      setTimeout(() => {
        setCheckedTimeOut((prev) => !prev);
      }, 500);
    } else {
      setCheckedTimeOut((prev) => !prev);
    }
  };

  const countryCode = getIpData();

  const featureImages = countryCode === 'US' ? images : imagesWithoutRoadie;

  const slideMove = () => {
    setChecked(false);
    setCheckedTimeOut(false);
  };

  const { t } = useTranslation('global');

  return (
    <Box sx={{ ...newFeaturesStyle }}>
      <SwiperContent slideMove={() => slideMove()}>
        {featureImages.map((data: any) => (
          <SwiperSlide
            key={data.image}
            className="lmi-swiper-slide"
            style={{
              backgroundImage: `url(${data.image})`,
            }}
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-end"
              spacing={0}
              className="lmi-swiper-stack"
            >
              <Box
                px={{ xs: 2, sm: 6, md: 15 }}
                py={8}
                className="caption"
                component="div"
              >
                <SectionTitle kColor="white" kMarginTop={0} kMarginBottom={0} kComponent="h2" kVariant="h4" kTitle={t(data.name)} />
                {/* <Collapse
                  in={checked}
                  collapsedSize={41}
                  className="lmi-swiper-collapse"
                >
                  <Typography
                    color="white"
                    component="p"
                    variant="body2"
                    sx={{
                      WebkitBoxOrient: checked ? 'horizontal' : 'vertical',
                    }}
                  >
                    {data.description}
                  </Typography>
                </Collapse> */}
                <Box
                  height={checked ? '120px' : '50px'}
                  className="lmi-swiper-collapse"
                >
                  <Typography
                    color="white"
                    component="p"
                  >
                    {t(data.description)}
                    {checked ? '' : '...'}
                    {!checked ? '' : t(data.description2)}
                  </Typography>
                </Box>
                <Button
                  className="lmi-btn-accordion"
                  onClick={handleChange}
                  variant="outlined"
                  endIcon={<KeyboardArrowUpRoundedIcon sx={{ transform: checked ? 'rotate(180deg)' : '0' }} />}
                  size="medium"
                >
                  {checked ? `${t('new_features.view_less')} ` : `${t('new_features.view_more')} `}
                  {' '}
                </Button>
              </Box>
              <Box
                className={checked ? 'bg more' : 'bg'}
                sx={{
                  position: 'absolute',
                }}
                component="div"
              />
            </Stack>
          </SwiperSlide>
        ))}
      </SwiperContent>

    </Box>
  );
}

/* eslint-disable react/jsx-props-no-spreading */
import { useContext, useEffect, useState } from 'react';
import cookies from 'js-cookie';
import { enUS, es } from 'date-fns/locale';
import {
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import { format } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LocalizationProvider, StaticDateRangePicker, DateRangePickerDay } from '@mui/x-date-pickers-pro';
import CalendarContext from '../../../../../../context/calendar/calendarContext';
import { currentDate, currentDateYear } from '../../../../../../services/common';

export default function CalendarRentals({
  componentKey,
  setDateRange,
  unavailableDates,
  dateRange: dateRangeProp,
}: any) {
  const {
    setRange,
  }: any = useContext(CalendarContext);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const [hereDateRange, setHereDateRange]: any = useState(dateRangeProp);

  useEffect(() => {
    setHereDateRange(dateRangeProp);
  }, [dateRangeProp]);

  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  const unavDates = unavailableDates ?? [];

  const getDaysInBetween = (startDateBet: Date, endDateBet: Date) => {
    const daysArray = [];
    const diffDays = moment(endDateBet).diff(moment(startDateBet), 'days');
    // eslint-disable-next-line no-plusplus
    for (let x = 0; x <= diffDays; x++) {
      const myFutureDate = new Date(startDateBet);
      daysArray.push(myFutureDate.setDate(myFutureDate.getDate() + x));
    }
    return daysArray;
  };

  return (
    <LocalizationProvider key={`${componentKey}_LP`} dateAdapter={AdapterDateFns} locale={(cookies.get('i18next') === 'en') ? enUS : es}>
      <Stack
        key={`${componentKey}_stack`}
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ textTransform: 'capitalize' }}
      >
        <StaticDateRangePicker
          key={componentKey}
          displayStaticWrapperAs={isDesktop ? 'desktop' : 'mobile'}
          value={hereDateRange}
          disablePast
          // inputRanges={[]}
          // actionBar hides the ok and cancel button from below the calendar
          // toolbar is like a date range input that's visible on mobile
          slotProps={{ actionBar: { actions: [] }, toolbar: { hidden: true } }}
          slots={{
            day: (props: any) => {
              const dayFormattedB = moment(props.day).format('YYYY-MM-DD').toString();
              const dayFormatted = moment(props.day, 'YYYY-MM-DD');
              // const isBlocked = datearray.indexOf(dayFormattedB) > -1;
              const isBlocked = unavDates?.indexOf(dayFormattedB) > -1;
              const date = new Date();
              const isPast = dayFormatted.isBefore(date.setDate(date.getDate() - 1));
              return (
                <DateRangePickerDay
                  sx={{
                    '.MuiDateRangePickerDay-rangeIntervalPreview': isBlocked ? {
                      position: 'relative',
                      'button.MuiButtonBase-root::after': {
                        content: '"•"',
                        position: 'absolute',
                        bottom: '-13px',
                        color: 'orange',
                        fontSize: '25px',
                      },
                    } : {},
                  }}
                  {...props}
                  disabled={isBlocked || isPast}
                />
              );
            },
          }}
          onChange={(newValue: any) => {
            console.log('change');
            const secondDaySelected = moment(newValue[1]);
            let hasUnavailableBetween = false;
            let daysList;
            // setRange(newValue[0], datearray);
            const {
              starLimitDate: startLimit,
              endLimitDate: endLimit,
            } = setRange(newValue[0]!, unavDates);
            localStorage.setItem('storageDateRange', newValue);

            if (!newValue[1] || (newValue[1] && (
              (startLimit && endLimit
                && secondDaySelected.isBetween(startLimit, endLimit))
              || (
                (!startLimit
                  && endLimit.isAfter(newValue[0])
                  && endLimit.isAfter(newValue[1]))
                || (!endLimit
                  && startLimit.isBefore(newValue[0])
                  && startLimit.isBefore(newValue[1]))
              ) || (moment(newValue[0])!.isBefore(hereDateRange[0]))
            ))
            ) {
              if (newValue[0] != null && newValue[1] != null) {
                daysList = getDaysInBetween(newValue[0], newValue[1]);

                daysList.forEach((element) => {
                  const fD = format(element, 'yyyy-MM-dd');
                  let indexElement = -1;
                  indexElement = unavDates.indexOf(fD);
                  if (indexElement > -1) {
                    hasUnavailableBetween = true;
                  }
                });

                if (!hasUnavailableBetween) {
                  setHereDateRange(newValue);
                  setDateRange(newValue);
                } else if (moment(newValue[0])!.isBefore(hereDateRange[0])) {
                  setDateRange([newValue[0], null]);
                  setHereDateRange([newValue[0], null]);
                } else if (hasUnavailableBetween) {
                  setDateRange([newValue[1], null]);
                  setHereDateRange([newValue[1], null]);
                }
              } else {
                setDateRange(newValue);
                setHereDateRange(newValue);
              }

              return true;
            } if (!secondDaySelected.isBetween(startLimit, endLimit)) {
              if (newValue[0] && newValue[1]) {
                daysList = getDaysInBetween(newValue[0], newValue[1]);

                daysList.forEach((element) => {
                  const fD = format(element, 'yyyy-MM-dd');
                  let indexElement = -1;
                  indexElement = unavDates.indexOf(fD);
                  if (indexElement > -1) {
                    hasUnavailableBetween = true;
                  }
                });

                if (!hasUnavailableBetween) {
                  setDateRange(newValue);
                  setHereDateRange(newValue);
                } else {
                  setDateRange([newValue[1], null]);
                  setHereDateRange([newValue[1], null]);
                }
              } else {
                setDateRange([newValue[0], null]);
                setHereDateRange([newValue[0], null]);
              }
              // setDateRange([newValue[0], null]);
              return true;
            }

            // setDateRange([newValue[0], newValue[0]]);

            return true;
          }}
          minDate={new Date(currentDate)}
          maxDate={new Date(currentDateYear)}
        />
      </Stack>
    </LocalizationProvider>
  );
}

/* eslint-disable react/jsx-props-no-spreading */
import { Route, Switch, useLocation } from 'react-router-dom';
import { Collapse, Container } from '@mui/material';
import useScrollTrigger from '@mui/material/useScrollTrigger';
// import { useState } from 'react';
import BackOfficeAppBar from '../../Layout/BackOfficeAppBar/BackOfficeAppBar';

import { BannerCookies, Copyright, Footer } from '../../Common';

import { LandingPageModels } from './LandingPage.models';

export default function LandingPage(props: LandingPageModels) {
  // const { window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 700,
  });

  const location = useLocation();

  return (
    <>
      <Collapse in={(location.pathname == '/'
      || location.pathname == '/circle') ? trigger : true}
      >
        <BackOfficeAppBar />
      </Collapse>
      <Container disableGutters component="main" sx={{ maxWidth: '100% !important' }}>
        {props.content}
        <Switch>
          <Route
            path={['/', '/circle', '/search', '/search/*']}
            exact
            render={() => <Footer />}
          />
        </Switch>
        <Copyright />
      </Container>
      <BannerCookies kShowBanner />
    </>
  );
}

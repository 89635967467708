import LmiTheme from '../../../../../themes/lmi-mui-theme/lmi-theme';

const CircleSwiperStyle = {
  backgroundColor: 'white',
  overflow: 'hidden',

  '.lmi-stack-btn': {
    '.prev, .next': {
      display: { xs: 'none', sm: 'flex' },
      width: 56,
      height: 56,
    },
    button: {
      fontWeight: 400,
      boxShadow: 'none',
      backgroundColor: 'white',
      textTransform: 'capitalize',
      color: LmiTheme.light.accent,
      border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
      '&.MuiButton-outlined': {
        borderRadius: 2,
        px: { xs: 2, sm: 4 },
        height: { xs: 44, sm: 56 },
      },
    },
  },
  '& .swiper-slide': {
    padding: '0px',
  },
  '.swiper-container': {
    maxWidth: '1136px',
    overflow: 'visible',
    marginLeft: { xs: '16px', sm: '24px', lg: 'auto' },
    marginRight: { xs: '16px', sm: '24px', lg: 'auto' },
  },

};

export default CircleSwiperStyle;

import { createTheme } from '@mui/material/styles';
import Theme from './lmi-theme';

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
  }
}

const LmiMuiTheme = createTheme({
  // breakpoints: {
  //   values: {
  //     xs: 600,
  //     sm: 900,
  //     md: 1200,
  //     lg: 1536,
  //     xl: 1920,
  //   },
  // },
  palette: {
    primary: {
      main: Theme.light.accent,
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
  components: {
    // Name of the component
    MuiTextField: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        // Name of the slot
        root: {
          '&.btn-textTransform-none': {
            textTransform: 'none',
          },
          sizeMedium: {
            height: 56,
          },

        },
      },
    },
  },
});

export default LmiMuiTheme;

/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { SecondTopBarModels } from './SecondTopBar.models';

export default function SecondTopBar(props: SecondTopBarModels) {
  const { t } = useTranslation('global');
  return (
    <>
      {
        props.kOpenSearchCircle ? (
          <>
            <Typography
              component="span"
              fontSize={14}
              sx={{
                textTransform: 'none',
                color: '#999da2',
              }}
            >
              {props.kSecondTitle}
            </Typography>
          </>
        ) : (
          props.kCirclesUserBelong && (
            <>
              <Typography
                component="span"
                fontSize={14}
                sx={{
                  textTransform: 'none',
                  color: '#999da2',
                }}
              >
                {t('circles.main.i_belong')}
              </Typography>
            </>
          )
        )
      }
    </>
  );
}

import {
  ADD,
  ADD_RANGE,
  DELETE,
  DELETE_ALL,
  GET_ALL,
  SET_LOCATION,
  SET_CURRENCY,
} from '../../types';

export default (state: any, action: any) => {
  switch (action.type) {
    case GET_ALL:
      return {
        ...state,
        categories: action.payload,
        loading: false,
      };
    case ADD:
      const category = action.payload;
      let newChecked = [...state.categoriesSelected ?? []];

      const exist = newChecked.filter((f) => f.id === category.id).length > 0;

      if (exist) {
        newChecked = newChecked.filter((f) => f.id !== category.id);
      } else {
        newChecked.push(category);
      }

      localStorage.setItem('filtersCategory', JSON.stringify(newChecked));
      return {
        ...state,
        categoriesSelected: newChecked,
        loading: false,
      };
    case DELETE:
      const categorytoRemove = action.payload;
      let checked = [...state.categoriesSelected ?? []];

      const existToRemove = checked
        .filter((f) => f.id === categorytoRemove.id).length > 0;

      if (existToRemove) {
        checked = checked.filter((f) => f.id !== categorytoRemove.id);
      }

      localStorage.setItem('filtersCategory', JSON.stringify(checked));
      return {
        ...state,
        categoriesSelected: checked,
        loading: false,
      };
    case ADD_RANGE:
      localStorage.setItem('filtersCategory', JSON.stringify(action.payload));
      return {
        ...state,
        categoriesSelected: action.payload,
        loading: false,
      };
    case SET_CURRENCY:
      localStorage.setItem('searchCurrency', action.payload);
      return {
        ...state,
        searchCurrency: action.payload,
      };
    case DELETE_ALL:
      localStorage.removeItem('filtersCategory');
      localStorage.removeItem('filtersApplied');
      localStorage.setItem('searchCurrency', '');
      return {
        ...state,
        categoriesSelected: null,
      };
    case SET_LOCATION:
      const {
        lat,
        long,
        address,
        longAddress,
        countryName,
        countryCode,
        postalCode,
        extendedAddress,
      }: any = action.payload;
      return {
        ...state,
        lat,
        long,
        address,
        longAddress,
        countryName,
        countryCode,
        postalCode,
        extendedAddress,
      };

    default:
      return state;
  }
};

import { useMediaQuery, useTheme } from '@mui/material';
import { useContext } from 'react';
import EllipsisText from '../../../../../../components/Common/EllipsisText/EllipsisText';
import MyCirclesListingContext from '../../../../../../context/myCirclesListing/myCirclesListingContext';

export default function AboutCircle() {
  const {
    circle,
  }: any = useContext(MyCirclesListingContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <EllipsisText
        kText={circle?.dscription}
        kMaxLength={isMobile ? 150 : false}
        kIsVisibleBtnMore={isMobile}
      />
    </>
  );
}

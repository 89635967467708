import { ReactElement } from 'react';

export interface AddItemModel {
  edit?: boolean;
  content?: ReactElement,
}

export interface AddItemInfoModel {
  idCatLndItem: number,
  idCatTypeItem: number,
  name: string,
  dscription: string,
  dscription2: string,
  brand: string,
  model: string,
  year: string,
  idCatLndCategory: number,
  idCatLndCondition: number,
  finish: string,
  color: string,
  replacementCost: number,
  acceptOffers: boolean,
  zip_code2: string,
  address2: string,
  pickupOnly: boolean,
  blocked?: boolean,
  sold?: boolean,
  delivery: boolean,
  idCatLndItemStatus: number,
  weight: number,
  idCatLndUomWeight: number,
  height: number,
  width: number,
  length: number,
  idCatLndUomDimension: number,
  lat: string,
  lon: string,
  itemPicture: string,
  creationDate: string,
  active: boolean,
  lenderName: string,
  renterName: string,
  lenderPhoneNumber: string,
  renterPhoneNumber: string,
  usCost: number,
  internationalCost: number,
  idCatLndItemAdress: number,
  addressName: string,
  itemLocationName: string,
  zipCode: string,
  address: string,
  street: string,
  location: string,
  country: string,
  city: string,
  state: string,
  distance: string,
  rateReview1: string,
  rateReview2: string,
  rateReview3: string,
  rateReview4: string,
  rateReview5: string,
  coditionDesc: string,
  lenderProfilePicture: string,
  idLenderUser: number,
  minRentDays: number,
  conditions: string,
  lenderEmail: string,
  requiresDeposit: boolean,
  depositPercentage: number,
  depositDueDays: number,
  fromPickUpTime: string,
  toPickUpTime: string,
  fromDropOffTime: string,
  toDropOfftime: string,
  timeCanBearranged: true,
  lenderNickName: string,
  category: string,
  hasStripeAcct: number,
  isFavorite: string,
  isItemLuxury: number,
  exactAddress: boolean,
  requiresIdVerify: boolean,
  hasPayOutAccount: boolean,
  lenderStripeAccount: string,
  lenderStripePerson: string,
  lenderStripeAcctType: number,
  lenderVerified: boolean,
  renterVerified: boolean,
  isOwnItem: boolean,
  dailyRentalPrice: number,
  hourlyRentalPrice: number,
  weeklyRentalPrice: number,
  monthlyRentalPrice: number,
  isDailyRental: boolean,
  isHourlyRental: boolean,
  isWeeklyRental: boolean,
  isMonthlyRental: boolean,
  salePrice: number,
  fiveMilesPrice: number,
  tenMilesPrice: number,
  tFiveMilesPrice: number,
  twentyFiveMilesPrice: number,
  hasDelivery: boolean,
  lmiLenderFee: number,
  lmiRenterFee: number,
  stripeFixedFee: number,
  stripeFee: number,
  itemDeepLink: string,
  sumRatesreceived: Number,
  canBuy: boolean,
  fiftyMilesPrice: number,
  roadie: boolean,
  startDeliveryTime: string,
  endDeliveryTime: string,
  totalViews: number,
  totalLikes: number,
  circleFee: number,
  toc: string,
  circlesOnly: boolean,
  circlesAndExplore: boolean,
  exploreOnly: boolean,
  isLenderDelivery: boolean,
  itemStatus: number,
  accept_offers: boolean,
  streetAddress: string,
  extendedAddress: string,
  locality: string,
  region: string,
  postalCode: string,
  countryName: string,
  availabilityFromTime: string,
  availabilityToTime: string,
  currency: string,
  currencySource: string,
  canBeArranged: boolean,
  securityDeposit: number,
  physicalLat: string,
  physicalLon: string,
  physicalAddress: string
  publicIP: string,
  itemVideos: any,
  hasServiceMaxRate: boolean,
  serviceMaxRate: number
}

export interface UserTermsModel {

  idTerm: number,
  idCatLndUser: number,
  active: boolean,
  termDetail: string,
  creationDate: string,
  modifyDate: string,
  relatedToItem: boolean,
  generatedNewID: string,
}

export interface ItemImageModel {

  idDetLndItemImage: number,
  idCatLndItem: number,
  fileName: string,
  imgPath: string,
  assetType: string,
  videoPath: string,
  base64: string,
}

export const emptyItemModel = (): AddItemInfoModel => ({
  // const emptyItemModel = (): AddItemInfoModel => ({
  accept_offers: false,
  idCatLndItem: 0,
  idCatTypeItem: 1,
  name: '',
  dscription: '',
  dscription2: '',
  brand: '',
  model: '',
  year: '2022',
  idCatLndCategory: 12,
  idCatLndCondition: 1,
  finish: '',
  color: '',
  replacementCost: 0,
  acceptOffers: false,
  zip_code2: '',
  address2: '',
  pickupOnly: false,
  delivery: false,
  idCatLndItemStatus: 1,
  weight: 0,
  idCatLndUomWeight: 1,
  height: 0,
  width: 0,
  length: 0,
  idCatLndUomDimension: 1,
  lat: '',
  lon: '',
  itemPicture: '',
  creationDate: '',
  active: true,
  lenderName: '',
  renterName: '',
  lenderPhoneNumber: '',
  renterPhoneNumber: '',
  usCost: 0,
  internationalCost: 0,
  idCatLndItemAdress: 0,
  addressName: '',
  itemLocationName: '',
  zipCode: '',
  address: '',
  street: '',
  location: '',
  country: '',
  city: '',
  state: '',
  distance: '',
  rateReview1: '',
  rateReview2: '',
  rateReview3: '',
  rateReview4: '',
  rateReview5: '',
  coditionDesc: '',
  lenderProfilePicture: '',
  idLenderUser: 0,
  minRentDays: 0,
  conditions: '',
  lenderEmail: '',
  requiresDeposit: true,
  depositPercentage: 0,
  depositDueDays: 0,
  fromPickUpTime: '',
  toPickUpTime: '',
  fromDropOffTime: '',
  toDropOfftime: '',
  timeCanBearranged: true,
  lenderNickName: '',
  category: '',
  hasStripeAcct: 0,
  isFavorite: '',
  isItemLuxury: 0,
  exactAddress: false,
  requiresIdVerify: true,
  hasPayOutAccount: false,
  lenderStripeAccount: '',
  lenderStripePerson: '',
  lenderStripeAcctType: 0,
  lenderVerified: false,
  renterVerified: false,
  isOwnItem: false,
  dailyRentalPrice: 0,
  hourlyRentalPrice: 0,
  weeklyRentalPrice: 0,
  monthlyRentalPrice: 0,
  isDailyRental: true,
  isHourlyRental: true,
  isWeeklyRental: true,
  isMonthlyRental: true,
  salePrice: 0,
  fiveMilesPrice: 0,
  tenMilesPrice: 0,
  twentyFiveMilesPrice: 0,
  tFiveMilesPrice: 0,
  hasDelivery: true,
  lmiLenderFee: 0,
  lmiRenterFee: 0,
  stripeFixedFee: 0,
  stripeFee: 0,
  itemDeepLink: '',
  sumRatesreceived: 0,
  canBuy: false,
  fiftyMilesPrice: 0,
  roadie: false,
  startDeliveryTime: '2022-02-02T12:00',
  endDeliveryTime: '2022-02-02T19:00',
  totalViews: 0,
  totalLikes: 0,
  circleFee: 0,
  toc: 'jsjsjs',
  circlesOnly: false,
  circlesAndExplore: true,
  exploreOnly: false,
  isLenderDelivery: false,
  itemStatus: 1,
  streetAddress: '',
  extendedAddress: '',
  locality: '',
  region: '',
  postalCode: '',
  countryName: '',
  availabilityFromTime: '2022-02-02T09:00',
  availabilityToTime: '2022-02-02T17:00',
  currency: '',
  currencySource: '',
  canBeArranged: true,
  securityDeposit: 0,
  physicalLat: '',
  physicalLon: '',
  physicalAddress: '',
  publicIP: '',
  itemVideos: [],
  hasServiceMaxRate: false,
  serviceMaxRate: 0,
});

export const createItemEmpty = <T extends Partial<AddItemInfoModel>>(initialValues: T): AddItemInfoModel & T => Object.assign(emptyItemModel(), initialValues);

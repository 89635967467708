import {
  Avatar, Box, Button, Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import VerifiedIcon from '@mui/icons-material/Verified';
import { useHistory } from 'react-router-dom';
import { DialogCommon, LmiColorPalette, LmiTheme } from '../../../../../../../components/Common';
import publicProfileContext from '../../../../../../../context/publicProfile/publicProfileContext';
import MyCirclesListingContext from '../../../../../../../context/myCirclesListing/myCirclesListingContext';
import AddEditCirclesContext from '../../../../../../../context/circles/addEditCirclesContext';

export default function MemberDetailHeader() {
  const pProfileContext = useContext(publicProfileContext);
  const {
    publicProfileInfo,
  }: any = pProfileContext;

  const {
    circle,
  }: any = useContext(MyCirclesListingContext);

  const circlesContextLog = useContext(AddEditCirclesContext);
  const {
    confirmLeaveCircle,
    leftCircle,
    memberSelected,
  }: any = circlesContextLog;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const history = useHistory();
  const state = history.location.state as {
    isChoosingNewOwner: boolean,
  };

  const [open, setOpen] = useState(false);
  const isChoosingNewOwner = (state && state.isChoosingNewOwner) && state.isChoosingNewOwner && !memberSelected?.isOwner;

  const isVerified = true;

  useEffect(() => {
    if (leftCircle) {
      window.scrollTo(0, 0);
      history.push('/circles');
    }
  }, [leftCircle]);

  const { t } = useTranslation('global');

  return (
    <Box sx={{ mb: '30px' }}>
      <Stack flexDirection="row" sx={{ mt: 3 }}>
        <Avatar
          alt={publicProfileInfo?.completeName}
          src={publicProfileInfo?.profilePicture}
          sx={{
            ml: isMobile ? '15px' : '30px',
            width: isMobile ? '150px' : '170px',
            height: isMobile ? '150px' : '170px',
            border: `5px solid ${LmiTheme.light.systemBackground.elevation}`,
            color: LmiColorPalette.greenScheme[900],
            fontSize: '56px',
            fontWeight: 'bold',
            bgcolor: publicProfileInfo?.profilePicture === '--' ? LmiColorPalette.greenScheme[100] : LmiTheme.light.systemBackground.base,
          }}
        />
        <Stack flexDirection="column" sx={{ ml: isMobile ? '15px' : '28px', mt: isMobile ? '15px' : '28px' }}>
          <Typography sx={{
            fontSize: isMobile ? '20px' : '24px', color: '#121212', fontWeight: '900', mb: '10px',
          }}
          >
            {publicProfileInfo?.completeName}
          </Typography>
          <Stack flexDirection="row">
            <CalendarTodayRoundedIcon sx={{ fontSize: isMobile ? '16px' : '20px' }} />
            <Typography sx={{
              fontSize: isMobile ? '12px' : '16px',
              color: '#999da2',
              ml: '13px',
            }}
            >
              {publicProfileInfo?.memberSince}
            </Typography>
          </Stack>
          {
            isVerified ? (
              <Stack flexDirection="row" alignItems="center">
                <VerifiedIcon sx={{ color: '#038de2', fontSize: isMobile ? '16px' : '20px' }} />
                <Typography sx={{ fontSize: isMobile ? '12px' : '16px', color: '#999da2', ml: '13px' }}>
                  {t('circles.members.id_verified')}
                </Typography>
              </Stack>
            ) : (<></>)
          }
        </Stack>
        {isChoosingNewOwner
          && (

            <Stack
              justifyContent="center"
              alignItems="center"
              my={3}
              flexDirection="column"
              sx={{ ml: isMobile ? '15px' : '28px', mt: isMobile ? '15px' : '28px' }}
            >
              <Button
                onClick={() => { setOpen(true); }}
                fullWidth
                variant="contained"
                size="large"
                disableElevation
                sx={{ textTransform: 'none', fontSize: 'subtitle1.fontSize', fontWeight: 'bold' }}
              >
                {t('circles.detail.makeNewOwner3')}
              </Button>
            </Stack>
          )}
      </Stack>

      <DialogCommon
        kTitle={t('circles.detail.newOwner')}
        kMaxWidth="sm"
        kMaxHeight="280px"
        kAlignBtn="center"
        kBorderRadius={isMobile ? 0 : '10px'}
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kKeepBtnSize
        kOpenDialogLists={open}
        kOnClose={() => { setOpen(false); }}
        kOnSecondary={() => { setOpen(false); }}
        kOnThird={() => {
          confirmLeaveCircle(circle?.idCatLndCircle, true, publicProfileInfo?.idCatLndUser);
        }}
        kContent={
          (
            <Typography>
              {t('circles.detail.makeNewOwner')}
              {publicProfileInfo?.completeName}
              {t('circles.detail.makeNewOwner2')}
            </Typography>
          )
        }
      />
    </Box>
  );
}

import {
  Card, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { LmiTheme } from '..';
import nouns from '../../../utils/nouns';
import { SearchSuggestionModels } from './SearchSuggestion.models';

export default function SearchSuggestion(props: SearchSuggestionModels) {
  const funcTrigger = props.kOnClick;
  const [textSuggestion, setTextSuggestion] = useState([] as any);
  const [searchHistory, setSearchHistory] = useState([] as any);
  useEffect(() => {
    const result : any[] = [];
    nouns.filter((noun) => noun.startsWith(props.kInputText.toLowerCase())).map((data) => result.push(data));
    setTextSuggestion(result.slice(0, 5));
  }, [props.kInputText]);

  useEffect(() => {
    const History = localStorage.getItem('searchHistory');
    const searchHistoryJson = History ? JSON.parse(History) : [];
    setSearchHistory(searchHistoryJson?.reverse());
  }, []);

  const deleteHistory = (text: string) => {
    const newHistory = searchHistory.filter((s:any) => s !== text);
    localStorage.setItem('searchHistory', JSON.stringify(newHistory?.reverse()));
    setSearchHistory(newHistory?.reverse());
  };

  const { t } = useTranslation('global');

  return (
    <>
      {textSuggestion.length > 0 || searchHistory.length
        ? (
          <Card
            variant="outlined"
            sx={{
              position: 'absolute',
              top: props.kTop,
              width: '90%',
              borderRadius: '10px',
              zIndex: 2,
            }}
          >
            { searchHistory.length ? (
              <Typography
                variant="subtitle2"
                fontSize={14}
                fontWeight="600"
                p={2}
              >
                {t('other.search_recents')}
              </Typography>
            ) : null}
            <List
              sx={{
                zIndex: 1550,
                li: {
                  borderBottom: `1px solid ${LmiTheme.light.systemBackground.separator}`,
                  '&:last-child': {
                    border: 'none',
                  },
                },
              }}
            >
              {searchHistory.map((text: string) => (
                <ListItem value={text} disablePadding>
                  <div style={{ width: '15%', marginLeft: 6 }}>
                    <ListItemButton onClick={() => deleteHistory(text)} sx={{ width: '100%' }}>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <CloseIcon />
                      </ListItemIcon>
                    </ListItemButton>
                  </div>
                  <div style={{ width: '100%' }}>
                    <ListItemButton onClick={() => funcTrigger(text)} sx={{ width: '100%', zIndex: 2000 }}>
                      <ListItemText primary={text} />
                    </ListItemButton>
                  </div>
                  <Divider />
                </ListItem>
              ))}
              { textSuggestion.length ? (
                <Typography
                  variant="subtitle2"
                  fontSize={14}
                  fontWeight="600"
                  p={2}
                >
                  {t('other.search_suggestions')}
                </Typography>
              ) : null }
              {textSuggestion.map((text: string) => (
                <ListItem value={text} disablePadding>
                  <ListItemButton onClick={() => funcTrigger(text)}>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <SearchIcon />
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                  <Divider />
                </ListItem>
              ))}
            </List>
          </Card>
        )

        : ''}
    </>
  );
}

SearchSuggestion.defaultProps = {
  kTop: 30,
};

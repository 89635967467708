import {
  useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import InputForm from '../../../../../../../components/Common/InputForm/InputForm';
import AddEditCirclesContext from '../../../../../../../context/circles/addEditCirclesContext';

export default function AddCircleConditions() {
  const addEditCircleContextLog = useContext(AddEditCirclesContext);

  const {
    addEditConditionInfo,
    updateConditionInfo,
    checkConditionValidate,
    updateConditionValidationStatus,
  }: any = addEditCircleContextLog;

  const [, setIsConditionValid] = useState(true);

  useEffect(() => {
    let isEverythingOk = true;
    if (checkConditionValidate) {
      if (addEditConditionInfo.length == 0) {
        isEverythingOk = false;
        setIsConditionValid(false);
      }
      updateConditionValidationStatus(isEverythingOk);
    }
  }, [checkConditionValidate]);

  const onChange = (e: any) => {
    if (e.target.name === 'condition') {
      if (e.target.value.length == 0) {
        setIsConditionValid(false);
      } else {
        setIsConditionValid(true);
      }
      e.target.value = e.target.value.substring(0, 200);
    }

    updateConditionInfo(e.target.value.substring(0, 200));
  };

  const { t } = useTranslation('global');

  return (
    <>
      <InputForm
        id="description"
        label={t('circles.add_circle_page.enter_special_condition')}
        helper_text={t('circles.add_circle_page.enter_a_condition')}
        max_lenght="420"
        input_params={{
          autoFocus: true,
          multiline: true,
          required: true,
          // error: !isTermDetailValid,
          rows: '2',
          placeholder: t('circles.add_circle_page.enter_a_condition'),
          onChange,
          name: 'condition',
          value: addEditConditionInfo != undefined ? addEditConditionInfo : '',
        }}
        form_control_params={{
          fullWidth: true,
        }}
      />
    </>
  );
}

import { useEffect, useRef, useState } from 'react';
import {
  Grid, Stack, Box, useMediaQuery, useTheme, Container,

} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import googlePlay from '../../../assets/header_swiper/google-play-badge-en.png';
import googlePlay_es from '../../../assets/header_swiper/google-play-badge-es.png';
import appStore from '../../../assets/header_swiper/app-store-badge.svg';
import appStore_es from '../../../assets/header_swiper/app-store.png';
import qr from '../../../assets/footer/qr_code.svg';
import LmiTheme from '../../../themes/lmi-mui-theme/lmi-theme';
import { BannerCookies } from '..';
import AccordionLinksFooter from './AccordionLink/AccordionLink';

declare const window: any;

export default function Footer() {
  const history = useHistory();
  const childRef:any = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expandedCompany, setExpandedCompany] = useState<boolean>(true);
  const [expandedSupport, setExpandedSupport] = useState<boolean>(true);
  const [expandedOther, setExpandedOther] = useState<boolean>(true);

  useEffect(() => {
    if (isMobile) {
      setExpandedCompany(false);
      setExpandedSupport(false);
      setExpandedOther(false);
    } else {
      setExpandedCompany(true);
      setExpandedSupport(true);
      setExpandedOther(true);
    }
  }, [isMobile]);

  const cookieStorage = {
    getItem: (item: any) => {
      const cookies = document.cookie
        .split(';')
        .map((cookie) => cookie.split('='))
        .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
      return cookies[item];
    },
    setItem: (item: any, value: any) => {
      const d = new Date();
      const exdays = 7;
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      const expires = `expires=${d.toUTCString()}`;
      document.cookie = `${item}=${value};${expires}`;
    },
  };

  const openChat = () => {
    if (cookieStorage.getItem('consentNecessaryCookies') || cookieStorage.getItem('consentNecessaryCookies') == false) {
      window.zE('webWidget', 'toggle');
    } else {
      childRef.current.refToggleMessagesModal();
    }
  };

  const handleChange = (panel: string) => {
    if (isMobile) {
      if (panel === 'company') {
        setExpandedCompany(!expandedCompany);
      }
      if (panel === 'support') {
        setExpandedSupport(!expandedSupport);
      }
      if (panel === 'other') {
        setExpandedOther(!expandedOther);
      }
    }
  };
  const goTo = async (ancillaryPage: string) => {
    history.push(`/${ancillaryPage}`);
  };

  const { t } = useTranslation('global');

  return (
    <Box pt={{ xs: 0, sm: 5 }} pb={5} component="article" sx={{ bgcolor: '#fff', borderTop: `1px solid ${LmiTheme.light.systemBackground.separator}` }}>
      <Container disableGutters={!!isMobile} component="div">
        <Grid
          container
          direction={{ lg: 'row', xs: 'column' }}
          justifyContent={{ lg: 'space-between', sm: 'center', xs: 'space-between' }}
          alignItems={{ lg: 'flex-start', sm: 'center' }}
          rowSpacing={{ lg: 8, xs: 4 }}
        >

          <Grid xs={12} sm={5} item>
            <Stack
              direction={{ sm: 'row', xs: 'column' }}
              justifyContent={{ lg: 'space-around', sm: 'space-between' }}
              alignItems="flex-start"
              spacing={{ sm: 2 }}
            >

              <AccordionLinksFooter
                kExpanded={expandedCompany}
                kAccordionClik={() => handleChange('company')}
                kTitle={t('footer.company')}
                kLinkItems={[
                  { kLinkText: `${t('footer.about')}`, kLinkClik: () => goTo('about') },
                  { kLinkText: `${t('footer.jobs')}`, kLinkClik: () => { window.location.href = 'mailto:jobs@lendmeit.com'; } },
                  { kLinkText: `${t('footer.our_guarantee')}`, kLinkClik: () => goTo('guarantee') },
                ]}
              />
              <AccordionLinksFooter
                kExpanded={expandedSupport}
                kAccordionClik={() => handleChange('support')}
                kTitle={t('footer.support')}
                kLinkItems={[
                  { kLinkText: 'FAQ', kLinkClik: () => goTo('faq') },
                  { kLinkText: `${t('footer.customer_support')}`, kLinkClik: () => openChat() },
                ]}
              />
              <AccordionLinksFooter
                kExpanded={expandedOther}
                kAccordionClik={() => handleChange('other')}
                kTitle={t('footer.other')}
                kLinkItems={[
                  { kLinkText: `${t('footer.privacy')}`, kLinkClik: () => goTo('privacy-policy') },
                  { kLinkText: `${t('footer.terms')}`, kLinkClik: () => goTo('terms') },
                  { kLinkText: `${t('footer.cookies')}`, kLinkClik: () => childRef.current.refTogglePreferencesCookies() },
                ]}
              />
            </Stack>
          </Grid>

          <Grid item>
            <Stack
              direction={{ lg: 'row', xs: 'column' }}
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Box sx={{ height: { xs: '40px', lg: '44px' } }}>
                  <a href="https://play.google.com/store/apps/details?id=com.lendmeit.app" rel="noopener noreferrer" target="_blank">
                    {
                  Cookies.get('i18next') === 'en' ? (
                    <img src={googlePlay} alt="Google Play" height="100%" />
                  ) : (<img src={googlePlay_es} alt="Google Play" height="100%" />)
                  }
                  </a>
                </Box>
                <Box sx={{ height: { xs: '40px', lg: '44px' } }}>
                  <a href="https://itunes.apple.com/us/app/lend-me-it-lend-rent-easily/id1444352676" rel="noopener noreferrer" target="_blank">
                    {
                    Cookies.get('i18next') === 'en' ? (
                      <img src={appStore} alt="AppStore" height="100%" />
                    ) : (<img src={appStore_es} alt="AppStore" height="100%" />)
                    }
                  </a>
                </Box>
              </Stack>
              <Box sx={{ textAlign: 'right' }}>
                <img width="80px" alt="qr-code" src={qr} />
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <BannerCookies
        ref={childRef}
        kShowBanner={false}
      />
    </Box>
  );
}

import { FiberManualRecordRounded } from '@mui/icons-material';
import {
  ListItem,
  ListItemText,
} from '@mui/material';
import LmiTheme from '../../../themes/lmi-mui-theme/lmi-theme';
import { InstructionModels } from './Instruction.models';

function Instruction(props: InstructionModels) {
  return (
    <>
      <ListItem
        key={props.kkey}
        divider={props.kDivider}
      >
        <FiberManualRecordRounded sx={{ color: LmiTheme.light.accent, fontSize: 'caption.fontSize', paddingRight: '10px' }} />
        <ListItemText
          primaryTypographyProps={{ color: LmiTheme.light.systemBackground.tertiary, fontSize: 'subtitle2.fontSize' }}
          primary={props.termDetail}
        />
      </ListItem>
    </>
  );
}

export default Instruction;

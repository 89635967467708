import { useContext, useEffect, useState } from 'react';
import {
  Divider,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
import {
  DateRangePickerDay, DateRangePickerDayProps, StaticDateRangePicker,
} from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enUS, es } from 'date-fns/locale';
import { DateRange } from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import moment from 'moment';
import LmiTheme from '../../../../../themes/lmi-mui-theme/lmi-theme';
import CalendarContext from '../../../../../context/calendar/calendarContext';
// import { formatYYYYMMDD } from '../../../../../services/common';
import { ItemDetailBlockedDaysModels } from './ItemDetaiModels';

export default function ItemDetailCalendar(props: ItemDetailBlockedDaysModels) {
  const {
    starLimitDate,
    endLimitDate,
    setRange,
  }: any = useContext(CalendarContext);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const [dateRange, setDateRange] = useState<DateRange<Date>>([null, null]);

  const newDate = new Date();
  const date = newDate.getDate();
  const month = newDate.getMonth();
  const year = newDate.getFullYear();

  const currentDate = new Date(year, month, date);
  const currentDateYear = new Date((year + 1), month, date);

  const datearray = props.kBlockedDays;

  useEffect(() => {
  }, [datearray]);

  const renderWeekPickerDay = (
    // eslint-disable-next-line no-shadow
    day: any,
    dateRangePickerDayProps: DateRangePickerDayProps<Date>,
  ) => {
    const dayFormatted = moment(day).format('YYYY-MM-DD').toString();
    if (datearray.indexOf(dayFormatted) > -1) {
      dateRangePickerDayProps.disabled = true;
      dateRangePickerDayProps.disableMargin = false;
      dateRangePickerDayProps.isHighlighting = false;
      // dateRangePickerDayProps.showDaysOutsideCurrentMonth = true;
      // dateRangePickerDayProps.outsideCurrentMonth = true;
      if (!dateRangePickerDayProps.outsideCurrentMonth) {
        dateRangePickerDayProps.sx = {
          backgroundColor: LmiTheme.light.accent,
          border: 0,
          color: 'white !important',
          lineHeight: 0,
          margin: '0 2px',
        };
      }
    } else {
      dateRangePickerDayProps.sx = {
        pointerEvents: 'none',
        margin: '0 2px',
      };
    }

    if (dateRangePickerDayProps.isPreviewing || dateRangePickerDayProps.selected) {
      const compareDate = moment(date, 'YYYY/MM/DD');
      if ((!endLimitDate && compareDate.isBefore(starLimitDate)) || !(compareDate.isBetween(starLimitDate, endLimitDate))) {
        dateRangePickerDayProps.isEndOfPreviewing = false;
        dateRangePickerDayProps.isPreviewing = false;
        dateRangePickerDayProps.selected = false;
        dateRangePickerDayProps.disableMargin = false;
        dateRangePickerDayProps.isHighlighting = false;
        dateRangePickerDayProps.showDaysOutsideCurrentMonth = true;
      }
    }

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <DateRangePickerDay {...dateRangePickerDayProps} />;
  };

  const { t } = useTranslation('global');

  return (
    <>
      <Box>
        <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
          <Box p={2} sx={{ textAlign: 'center' }}>
            <Typography
              component="div"
              sx={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: { lg: 'body1.fontSize', xs: 'body2.fontSize' },
              }}
            >
              {t('item_detail.upcoming_blocked_days_txt')}
              {' '}
              {datearray.length}
              {' '}
              {t('item_detail.upcoming_blocked_days_txt2')}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                color: LmiTheme.light.systemBackground.tertiary,
              }}
            >
              {t('item_detail.select_range_txt')}
            </Typography>
          </Box>
          <Divider />
          <Box className="lmi--date-picker" sx={{ textTransform: 'capitalize' }}>
            <>
              <LocalizationProvider locale={(cookies.get('i18next') === 'en') ? enUS : es} dateAdapter={AdapterDateFns}>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <StaticDateRangePicker
                    showToolbar={false}
                    startText="Check-in"
                    endText="Check-out"
                    displayStaticWrapperAs={isDesktop ? 'desktop' : 'mobile'}
                    value={dateRange}
                    renderDay={renderWeekPickerDay}
                    showDaysOutsideCurrentMonth={false}
                    onChange={(newValue) => {
                      // const fisrtDaySelected = moment(newValue[0]);
                      const secondDaySelected = moment(newValue[1]);

                      const {
                        starLimitDate: startLimit,
                        endLimitDate: endLimit,
                      } = setRange(newValue[0]!, datearray);

                      if (!newValue[1] || (newValue[1] && (
                        (startLimit && endLimit
                          && secondDaySelected.isBetween(startLimit, endLimit))
                        || (
                          (!startLimit
                            && endLimit.isAfter(newValue[0])
                            && endLimit.isAfter(newValue[1]))
                          || (!endLimit
                            && startLimit.isBefore(newValue[0])
                            && startLimit.isBefore(newValue[1]))
                        )
                      ))
                      ) {
                        setDateRange(newValue);
                      }
                      // return false;
                    }}
                    minDate={new Date(currentDate)}
                    maxDate={new Date(currentDateYear)}
                    /* eslint-disable react/jsx-props-no-spreading */
                    renderInput={(startProps, endProps) => (
                      <>
                        <TextField
                          {...startProps}
                          type="datetime"
                          variant="standard"
                          placeholder=""
                          size="small"
                          InputProps={{
                            disableUnderline: true,
                            readOnly: true,
                            style: { textAlign: 'center', fontWeight: 'bold' },
                          }}
                        />
                        <Box sx={{ mx: 2 }}> to </Box>
                        <TextField
                          {...endProps}
                          type="datetime"
                          variant="standard"
                          placeholder=""
                          size="small"
                          InputProps={{
                            disableUnderline: true,
                            readOnly: true,
                            style: { textAlign: 'center', fontWeight: 'bold' },
                          }}
                        />
                      </>
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </>
          </Box>
        </Paper>
      </Box>
    </>
  );
}

ItemDetailCalendar.defaultProps = {
  kStartDate: 'Select Start Date',
  kEndDate: 'Select End Date',
};

import {
  ITEM_BASIC_INFO_CHANGED,
  ITEM_INFO_LOAD,
  ACCESORY_ADD_EDIT,
  REQUIREMENT_ADD_EDIT,
  ACCESORY_UPDATE_LIST,
  IMAGES_UPDATE_LIST,
  VIDEOS_UPDATE_LIST,
  CHECK_VALIDATIONS,
  UPDATE_VALIDATION_STATUS,
  CHECK_ACC_VALIDATIONS,
  UPDATE_ACC_VALIDATION_STATUS,
  CHECK_REQ_VALIDATIONS,
  UPDATE_REQ_VALIDATION_STATUS,
  DELETED_IMAGES_UPDATE_LIST,
  DELETED_VIDEOS_UPDATE_LIST,
  BLOCKED_IND_DATES_UPDATE_LIST,
  SET,
  UPDATE_UPDATED_ITEM_STATUS,
  RESET_ITEM_INFO,
  INFO_IS_LOADING,
  CLEAN_FIELDS,
  ITEM_INFO_LOAD_REFRESH_PAGE,
  REMOVE_REQUIREMENT,
  PRECING_CHANGE,
  CHECK_ROADIE_FIELDS,
  SET_ADD_ITEM_FLAG,
  VALID_ERRORS,
  CLEAN_ERRORS,
  REQUIREMENT_UPDATE_LIST,
} from '../../types';

export default (state: any, action: any) => {
  switch (action.type) {
    case ITEM_BASIC_INFO_CHANGED: {
      return {
        ...state,
        itemInfo: action.itemInfo,
        addEditAccessoryInfo: state.addEditAccessoryInfo,
        canModify: true,
        checkValidate: false,
        checkRoadie: false,
      };
    }
    case SET: {
      return {
        ...state,
        itemInfo: action.payload,
        itemImages: action.itemImages,
        itemVideos: action.itemVideos,
        canModify: false,
        deletedImages: [],
        deletedVideos: [],
        areAccFieldsValidated: false,
      };
    }
    case ACCESORY_ADD_EDIT: {
      return {
        ...state,
        addEditAccessoryInfo: action.addEditAccessoryInfo,
        checkAccValidate: action.checkAccValidate,
        canModify: true,
        areAccFieldsValidated: false,
      };
    }
    case ACCESORY_UPDATE_LIST: {
      return {
        ...state,
        itemAccessories: action.itemAccessories,
        checkAccValidate: action.checkAccValidate,
      };
    }
    case REQUIREMENT_ADD_EDIT: {
      return {
        ...state,
        addEditRequirementInfo: action.addEditRequirementInfo,
        checkReqValidate: action.checkReqValidate,
      };
    }
    case REQUIREMENT_UPDATE_LIST: {
      return {
        ...state,
        userTermns: action.addEditRequirementInfo,
        checkReqValidate: action.checkReqValidate,
      };
    }
    case IMAGES_UPDATE_LIST: {
      return {
        ...state,
        itemImages: action.itemImages,
      };
    }
    case DELETED_IMAGES_UPDATE_LIST: {
      return {
        ...state,
        deletedImages: action.deletedImages,
      };
    }
    case VIDEOS_UPDATE_LIST: {
      return {
        ...state,
        itemVideos: action.itemVideos,
      };
    }
    case 'VIDEOS_FILES': {
      return {
        ...state,
        files: action.files,
      };
    }
    case DELETED_VIDEOS_UPDATE_LIST: {
      return {
        ...state,
        deletedVideos: action.deletedVideos,
      };
    }
    case BLOCKED_IND_DATES_UPDATE_LIST: {
      const uniqueArray = action.blockedIndDates
        .filter((item: string, pos: string) => action.blockedIndDates.indexOf(item) == pos);

      return {
        ...state,
        blockedIndDates: uniqueArray,
      };
    }
    case CHECK_VALIDATIONS: {
      return {
        ...state,
        checkValidate: action.checkValidate,
        checkRoadie: false,
      };
    }
    case UPDATE_VALIDATION_STATUS: {
      return {
        ...state,
        areFieldsValidated: action.areFieldsValidated,
        checkValidate: action.checkValidate,
        checkRoadie: false,
      };
    }
    case UPDATE_UPDATED_ITEM_STATUS: {
      return {
        ...state,
        isItemSavedUpdated: action.isItemSavedUpdated,
        areFieldsValidated: false,
        checkValidate: false,
      };
    }
    case CHECK_ACC_VALIDATIONS: {
      return {
        ...state,
        checkAccValidate: action.checkAccValidate,
      };
    }
    case CHECK_REQ_VALIDATIONS: {
      return {
        ...state,
        checkReqValidate: action.checkReqValidate,
      };
    }
    case VALID_ERRORS: {
      return {
        ...state,
        errors: action.errors,
      };
    }
    case CLEAN_ERRORS: {
      return {
        ...state,
        errors: {},
      };
    }
    case CHECK_ROADIE_FIELDS: {
      return {
        ...state,
        checkRoadie: true,
        checkValidate: false,
      };
    }
    case UPDATE_ACC_VALIDATION_STATUS: {
      return {
        ...state,
        areAccFieldsValidated: action.areAccFieldsValidated,
        changedAccFields: !state.changedAccFields,
      };
    }
    case UPDATE_REQ_VALIDATION_STATUS: {
      return {
        ...state,
        areReqFieldsValidated: action.areReqFieldsValidated,
        changedReqFields: !state.changedReqFields,
        // checkReqValidate: action.checkReqValidate,
      };
    }
    case ITEM_INFO_LOAD: {
      return {
        ...state,
        itemInfo: action.itemInfo,
        itemAccessories: action.itemAccessories,
        userTerms: action.userTerms,
        itemImages: action.itemImages,
        itemVideos: action.itemVideos,
        addEditAccessoryInfo: action.addEditAccessoryInfo,
        deletedImages: action.deletedImages,
        deletedVideos: action.deletedVideos,
        blockedIndDates: action.blockedIndDates,
        unavRentedDates: action.unavRentedDates,
        canModify: true,
        isItemSavedUpdated: state.isItemSavedUpdated,
        isLoading: false,
      };
    }
    case ITEM_INFO_LOAD_REFRESH_PAGE: {
      return {
        ...state,
        itemInfo: action.itemInfo,
        itemAccessories: action.itemAccessories,
        userTerms: action.userTerms,
        itemImages: action.itemImages,
        itemVideos: action.itemVideos,
        addEditAccessoryInfo: action.addEditAccessoryInfo,
        deletedImages: action.deletedImages,
        deletedVideos: action.deletedVideos,
        blockedIndDates: action.blockedIndDates,
        unavRentedDates: action.unavRentedDates,
        canModify: true,
        isItemSavedUpdated: state.isItemSavedUpdated,
        isLoading: false,
        areAccFieldsValidated: action.areAccFieldsValidated,
      };
    }
    case INFO_IS_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case CLEAN_FIELDS: {
      return {
        ...state,
        itemInfo: action.itemInfo,
      };
    }
    case RESET_ITEM_INFO: {
      return {
        ...state,
        // itemInfo: action.itemInfo,
        itemImages: action.itemImages,
        itemVideos: action.itemVideos,
        areFieldsValidated: false,
        checkValidate: false,
      };
    }
    case REMOVE_REQUIREMENT: {
      return {
        ...state,
        userTerms: action.payload,
      };
    }
    case PRECING_CHANGE: {
      const {
        isHourlyRental,
        isDailyRental,
        isWeeklyRental,
        isMonthlyRental,
      } = action.payload;
      return {
        ...state,
        isHourlyRental,
        isDailyRental,
        isWeeklyRental,
        isMonthlyRental,
      };
    }
    case SET_ADD_ITEM_FLAG: {
      return {
        ...state,
        addItemFlag: !state.addItemFlag,
      };
    }
    default:
      return state;
  }
};

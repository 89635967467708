/* eslint-disable no-nested-ternary */
import {
  Box, Button, IconButton, Paper, useMediaQuery, useTheme,
} from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Heading from '../Heading/Heading';
import ManageMembers from './ManageMembers/ManageMembers';
import { ManageMembersSectionModels } from './ManageMembersSection.models';

function ManageMembersSection(props: ManageMembersSectionModels) {
  const history = useHistory();

  const state = history.location.state as {
    kOpensFromCircleDetail: boolean;
  };

  const openCircleMembersPage = () => {
    window.scrollTo(0, 0);
    history.push({
      pathname: '/circles/members',
      state: {
        ...state,
        kOpensFromCircleDetail: false,
      },
    });
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation('global');
  return (
    <>
      <Box
        mt={3}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: '10px',
        }}
      >
        <Heading kTitle={t('circles.add_circle_page.members')} kPaddingBottom="0px" />
        {
         !props.kCircleIsNew && (
           isMobile ? (
             <IconButton onClick={() => openCircleMembersPage()}>
               <ArrowForwardIosRoundedIcon color="primary" />
             </IconButton>
           ) : (
             <Button
               variant="text"
               sx={{ textTransform: 'none', fontSize: '18px' }}
               onClick={() => openCircleMembersPage()}
             >
               {t('circles.add_circle_page.manage')}
             </Button>
           )
         )
        }
      </Box>
      <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
        <ManageMembers />
      </Paper>
    </>
  );
}

export default ManageMembersSection;

import {
  Box, Typography, Stack, AvatarGroup, Avatar, Button, useTheme, useMediaQuery, Divider,
} from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { DialogCommon, LmiTheme } from '../../../../../ProfilePrivate/Components';
import MyCirclesListingContext from '../../../../../../../context/myCirclesListing/myCirclesListingContext';
import UserCircles from '../../../../UserCircles/UserCircles';

export default function MemberCircles() {
  const {
    circles,
  }: any = useContext(MyCirclesListingContext);

  const [openUserCircles, setOpenUserCircles] = useState(false);

  const { t } = useTranslation('global');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const totalCircles = circles ? Object.values(circles).length : 0;
  const totalMemberCircles = circles ? Object.values(circles) : [];

  const hasCircles = totalCircles >= 0;

  return (
    <>
      {
        hasCircles ? (
          <Box sx={{ padding: '0px 0' }}>
            <Stack height="53px" p={0} pl={3} direction="row" justifyContent="space-between" alignItems="center" spacing={0}>
              <Stack flexDirection="row" alignItems="center">

                <AvatarGroup
                  total={totalCircles}
                  sx={{
                    '& .MuiAvatar-root': {
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#f3f3f3',
                      color: 'black',
                      fontSize: '16px',
                    },
                  }}
                >
                  {
                    totalMemberCircles.map((circle: any, index: any) => (
                      isMobile ? (
                        index <= 1 && <Avatar alt={circle?.name} src={circle?.circlePicture} key={circle?.idCatLndCircle} />
                      ) : (
                        index <= 4 && <Avatar alt={circle?.name} src={circle?.circlePicture} key={circle?.idCatLndCircle} />
                      )
                    ))
                  }
                </AvatarGroup>
                <Typography
                  fontSize="17px"
                  color={LmiTheme.light.systemBackground.tertiary}
                  sx={{ ml: '12px' }}
                >
                  {`${totalCircles} ${t('circles.members.circles')}`}
                </Typography>
              </Stack>
              <Stack flexDirection="row">
                <Button
                  onClick={() => setOpenUserCircles(true)}
                  sx={{ height: '30px', mr: '8px' }}
                >
                  <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    mr: '4px',
                    textTransform: 'none',
                  }}
                  >
                    {t('circles.members.see_all')}
                  </Typography>
                  <ArrowForwardIosRoundedIcon />
                </Button>
              </Stack>
            </Stack>
            <DialogCommon
              kTitle={t('circles.members.user_circles')}
              kMaxWidth="sm"
              kEnableBorderRadius
              kBorderRadius={isMobile ? 0 : '10px'}
              kFixedDialog={0}
              kFixedDialogPosition="fixed"
              kDisableBottomDivider
              kDisableContentPadding
              kKeepBtnSize
              kSlideTransition={isMobile}
              kOpenDialogLists={openUserCircles}
              kOnClose={() => setOpenUserCircles(false)}
              kFullScreenMobile
              kDisableActions
              kContent={
                (
                  <Stack>
                    <Divider />
                    <Typography
                      component="span"
                      fontSize={14}
                      sx={{
                        textTransform: 'none',
                        color: '#999da2',
                        pl: '20px',
                        py: '8px',
                      }}
                    >
                      {t('circles.members.user_circles')}
                    </Typography>
                    <Divider />
                    <UserCircles
                      kReadOnly
                      kUserCircles
                    />
                    <Typography
                      component="span"
                      fontSize={14}
                      sx={{
                        textTransform: 'none',
                        color: '#999da2',
                        pl: '20px',
                        py: '8px',
                      }}
                    >
                      {t('circles.members.circles_user_belongs')}
                    </Typography>
                    <Divider />
                    <UserCircles
                      kReadOnly
                    />
                  </Stack>
                )
              }
            />
          </Box>
        ) : (
          <>
            <Typography
              fontSize={14}
              sx={{
                textAlign: 'center',
                color: '#999da2',
                p: 3,
                display: !hasCircles ? 'inherit' : 'none',
              }}
            >
              {t('circles.main.no_near_circles')}
            </Typography>
          </>
        )
      }

    </>

  );
}

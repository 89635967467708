/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
/* eslint import/no-unresolved: [2, { commonjs: true, amd: true }] */
import React, {
  useContext, useEffect, useReducer,
} from 'react';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import {
  ClsModAddNewCoupon,
  ClsModCheckoutSubmit,
  ClsModCreateItemBooking,
  // ClsModCreateRoadieDeliveryHeader,
  ClsModCreateUserFavorite,
  // ClsModGetItemDetailInfo,
  ClsModGetItemImages,
  ClsModGetItemRelatedAccessories,
  ClsModGetUserCoupons,
  ClsModGetItemUnavailableDates,
  ClsModGetUserTermsAndConditions,
  ClsModCreateUserViewed,
} from '@lendmeit/api_backend_lmi/dist/models';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import ApiLMI from '../../services/apilmi';
import ApiLambdaLMI from '../../services/apiLambdalmi';
import authContext from '../authentication/authContext';
import BookContext from './bookContext';
import BookReducer from './bookReducer';
import {
  GET_BY_ID,
  PRECING_TYPE,
  PRECING_CHANGE,
  // PRECING_HOURLY,
  RENT,
  BOOK_TYPE_CHANGE,
  UPDATE_LENDER_INFO,
  UPDATE_ROADIE_INFO,
  UPDATE_ACCESORY,
  UPDATE_FAVORITE,
  LOADING,
  INITIAL,
  PRECING_DAILY,
  SET_SUMMARY,
  AVAILABILITY_SET_RANGES,
  AVAILABILITY_SET_HOURS,
  AVAILABILITY_SET_ERROR,
  PRECING_HOURLY,
  PRECING_WEEKLY,
  PRECING_MONTHLY,
  DELIVERY_TYPE,
  DELIVERY_CHANGE,
  NONE,
  ROADIE,
  PICKUPBYLENDER,
  PICKUP_CHANGE,
  SET_STATE,
  NEED_COMPLETE_PROFILE,
  UPDATE_PENDING_INFO,
  COUPON_LIST,
  MODIFY_ACCESORY,
  UPDATE_LENDER_INFO_TEMP,
  SET_SUMMARY_TEMP,
  UPDATE_ROADIE_INFO_TEMP,
  DELIVERY_CHANGE_TEMP,
  COUPON_NEW,
  PROMO_CODE_CHANGE_TEMP,
  PROMO_CODE_CHANGE,
  NEED_ACCEPT_LONG_DISTANCE,
  BUY,
  RENTAL_CREATED,
  RENTAL_CREATION_ERROR,
  SET_WARNING_ROADIE,
  NEED_VERIFY_ACCOUNT,
  UPDATE_FROM_HOUR,
  UPDATE_TO_HOUR,
  GET_LENDER_ITEMS,
  CLEAR_BOOK_ERROR,
  SET_STATE_ALL,
} from '../../types';
import { AddItemInfoModel, createItemEmpty } from '../../page/BackOffice/Listing/Components/AddItem/AddItem.models';
import { MapData, createMapEmpty } from '../../components/Common/Map/Map.models';
import { ItemAccessoriesModel } from '../../page/BackOffice/Listing/Components/AddItem/Components/ItemAccessories/ItemAccessories.models';
import { RoadieFail, SummaryModel } from '../authentication/models/summary-Model';
import { ListAccesoriesModel } from '../../page/BackOffice/Listing/Components/AddItem/Components/ListAccessories/ListAccessories.models';
import homeContext from '../home/homeContext';
import publicProfileContext from '../publicProfile/publicProfileContext';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    gapi: any;
    FB: any;
  }
}

const BookItemState = (props: any) => {
  // const {
  //   address,
  //   lat,
  //   long,
  // }: any = useContext(homeContext);
  const _api = ApiLMI();
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const { token }: any = useContext(authContext);
  const bookInitial: AddItemInfoModel = createItemEmpty({});
  const mapInitial: MapData = createMapEmpty({});

  const {
    address,
    lat: user_lat,
    long: user_long,
  }: any = useContext(homeContext);

  const pProfileContext = useContext(publicProfileContext);

  const {
    privateProfileInfo,
  }: any = pProfileContext;
  // item_away;
  const initialState = {
    loading: true,
    bookItem:
    {
      bookDetails: bookInitial,
      images: null,
      unavailableDates: [],
      condictions: null,
      accesories: null,
    },
    bookType: localStorage.getItem('bookType') ?? RENT,
    deliveryType: NONE,
    precingType: localStorage.getItem('precingType') ?? PRECING_DAILY,
    roadie: (localStorage.getItem('checkout') != null && localStorage.getItem('checkout') != '') ? JSON.parse(localStorage.getItem('checkout')!).roadie : mapInitial,
    includeAccesories: localStorage.getItem('includeAccesories') ?? [],
    availability: { dateRange: [null, null], hours: 1 },
    accesoriesUpdated: false,
    roadieChange: false,
    completeProfile: {
      openModal: false,
      firstName: '',
      lastName: '',
      profilePicture: '',
    },
    showAcceptDistance: {
      openModal: false,
    },
    showAcceptDistanceModal: false,
    showNeedsVerifyModal: false,
    couponList: [],
    couponSelected: null,
    pickupChange: false,
    pickupLender: mapInitial,
    summary: (localStorage.getItem('checkout') != null && localStorage.getItem('checkout') != '') ? JSON.parse(localStorage.getItem('checkout')!).summary : null,
    pickupChangeTemp: false,
    pickupLenderTemp: mapInitial,
    summaryTemp: null,
    roadieTemp: mapInitial,
    roadieChangeTemp: false,
    deliveryTypeTemp: NONE,
    promoSelected: null,
    promoSelectedTemp: null,
    roadieWarning: '',
    fromHour: (localStorage.getItem('checkout') != null && localStorage.getItem('checkout') != '') ? JSON.parse(localStorage.getItem('checkout')!).fromHour : null,
    toHour: (localStorage.getItem('checkout') != null && localStorage.getItem('checkout') != '') ? JSON.parse(localStorage.getItem('checkout')!).toHour : null,
  };

  const [state, dispatch] = useReducer(BookReducer, initialState);

  const {
    loading,
    bookItem: {
      bookDetails,
      images,
      unavailableDates,
      condictions,
      accesories,
      idCatLndCircle: idCircle,
    },
    precingType,
    bookType,
    deliveryType,
    pickupLender,
    roadie,
    includeAccesories,
    summary,
    availability,
    accesoriesUpdated,
    pickupChange,
    roadieChange,
    completeProfile,
    couponList,
    couponSelected,
    pickupChangeTemp,
    pickupLenderTemp,
    summaryTemp,
    roadieTemp,
    roadieChangeTemp,
    deliveryTypeTemp,
    showAcceptDistance,
    rentalError,
    rentalCreated,
    roadieWarning,
  } = state;

  const distance = (
    lat1: number, lon1: number, lat2: number, lon2: number, unit: string,
  ) => {
    const theta: number = lon1 - lon2;
    let dist: number = Math.sin(deg2rad(lat1)) * Math.sin(deg2rad(lat2))
      + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.cos(deg2rad(theta));
    dist = Math.acos(dist);
    dist = rad2deg(dist);
    dist = dist * 60 * 1.1515;
    if (unit == 'K') {
      dist *= 1.609344;
    } else if (unit == 'N') {
      dist *= 0.8684;
    }
    return dist.toFixed(2);
  };

  const deg2rad = (deg: number) => ((deg * Math.PI) / 180.0);

  const rad2deg = (rad: number) => ((rad * 180.0) / Math.PI);

  const setDateRange = async (ranges: any) => {
    dispatch({
      type: AVAILABILITY_SET_RANGES,
      payload: ranges,
    });
  };

  const setHours = async (hour: number, hourlyDateRange: any = [null, null]) => {
    dispatch({
      type: AVAILABILITY_SET_HOURS,
      payload: hour,
      dateRange: hourlyDateRange,
    });
  };

  const setAvailabilityError = async (message: string) => {
    dispatch({
      type: AVAILABILITY_SET_ERROR,
      payload: message,
    });
  };

  useEffect(() => {
    const {
      dateRange,
      errorAvailability,
    } = availability;
    let hasErrorRange = false;
    if (bookType == 'RENT') {
      hasErrorRange = bookType != RENT && (((precingType !== PRECING_HOURLY) && errorAvailability && errorAvailability != '') || !dateRange[1]);
    } else {
      hasErrorRange = (errorAvailability && errorAvailability != '');
    }

    if (bookDetails.idCatLndItem > 0) {
      if (!hasErrorRange) {
        (async function fetchSummary() {
          const payload = await getSummary(deliveryType, pickupLender, roadie);
          if (payload) {
            dispatch({
              type: SET_SUMMARY,
              payload,
            });
          }
        }());
      }
    }
  }, [
    bookDetails,
    bookType,
    accesories,
    availability,
    roadieChange,
    pickupChange,
    accesoriesUpdated,
    deliveryType,
    couponSelected,
  ]);

  useEffect(() => {
    const {
      dateRange,
      errorAvailability,
    } = availability;

    const hasErrorRange = bookType != RENT && (((precingType !== PRECING_HOURLY) && errorAvailability && errorAvailability != '') || !dateRange[1]);

    if (bookDetails.idCatLndItem > 0) {
      if (!hasErrorRange) {
        (async function fetchSummary() {
          const payload = await getSummary(deliveryTypeTemp, pickupLenderTemp, roadieTemp);
          if (payload) {
            dispatch({
              type: SET_SUMMARY_TEMP,
              payload,
            });
          }
        }());
      }
    }
  }, [
    pickupChangeTemp,
    roadieChangeTemp,
  ]);

  const getUserId = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const { attributes } = user;
      return attributes['custom:lmi-id'];
    } catch (e) {
      return 0;
    }
  };

  const dispatchRoadiemessage = (message: string) => {
    dispatch({
      type: SET_WARNING_ROADIE,
      message,
    });
  };

  const getSummary = async (deliveryTypeParameter: string, pickupByLender: any, roadieParameter: any) => {
    try {
      const userId = await getUserId();
      const { body, hasRoadie }: { body: any; hasRoadie: any; } = getBodyModel(deliveryTypeParameter, pickupByLender, roadieParameter);
      body.userId = userId;
      const _lambdaApi = ApiLambdaLMI();
      const response: any = await _lambdaApi.summaryPost(body);

      if (response?.status == 200 && response?.data.statusCode == 200) {
        const payload = response.data.data as SummaryModel;
        payload.pendingAction = '';
        let errorCode = '';
        if (hasRoadie) {
          // eslint-disable-next-line dot-notation
          if (payload.roadie['statusCode'] === 400) {
            const rodieErrors = payload.roadie as unknown as RoadieFail;
            for (let index = 0; index < rodieErrors.errors.length; index += 1) {
              if (rodieErrors.errors[index].message.includes('linesSorry')) {
                const splitMessage = rodieErrors.errors[index].message.split('Sorry');
                const trimMessage = splitMessage[0].includes('Route Restricted state lines') ? splitMessage[0].split('Route Restricted state lines')[1].trim() : splitMessage[0];
                const firstLetterCap = trimMessage.charAt(0).toUpperCase();
                const remainingMsg = trimMessage.slice(1);
                const trimCapitalizedMessage = `${firstLetterCap}${remainingMsg}`;
                payload.pendingAction = `${trimCapitalizedMessage}.`;
                toast.warning(`${trimCapitalizedMessage}.`, {
                  toastId: 'roadie_error_1',
                });
                toast.warning(`Sorry${splitMessage[1]}`, {
                  toastId: 'roadie_error_2',
                });
                dispatchRoadiemessage(splitMessage[0]);
              } else if (rodieErrors.errors[index].code != errorCode) {
                if ((roadieParameter.countryName == 'Mexico' || roadieParameter.countryName == 'Canada')) {
                  toast.warning(t('global.country_not_available'), {
                    toastId: 'country_not_available',
                  });
                  payload.pendingAction = t('global.country_not_available');
                  errorCode = rodieErrors.errors[index].code;
                  dispatchRoadiemessage(t('global.country_not_available'));
                } else {
                  // Delivery contact phone number must be between 10 and 11 digits.
                  toast.warning(rodieErrors.errors[index].message,
                    {
                      toastId: 'roadie_error',
                    });
                  payload.pendingAction = rodieErrors.errors[index].message;
                  errorCode = rodieErrors.errors[index].code;
                  dispatchRoadiemessage(rodieErrors.errors[index].message);
                }
              }
            }
          } else if (!payload.roadie.price && !(roadieParameter.countryName == 'Mexico' || roadieParameter.countryName == 'Canada')) {
            toast.warning(t('global.country_not_available'), {
              toastId: 'country_not_available',
            });
            dispatchRoadiemessage(t('global.country_not_available'));
            payload.pendingAction = t('global.country_not_available');
          } else if (roadieParameter.countryName == 'Mexico' || roadieParameter.countryName == 'Canada') {
            toast.warning(t('global.country_not_available'), {
              toastId: 'country_not_available',
            });
            dispatchRoadiemessage(t('global.country_not_available'));
            payload.pendingAction = t('global.country_not_available');
          } else {
            dispatchRoadiemessage('');
          }
        } else {
          dispatchRoadiemessage('');
        }

        if (deliveryTypeParameter === PICKUPBYLENDER) {
          if (payload?.self?.miles > 50 || payload?.self?.message != '') {
            toast.warning(payload.self.message == 'Error'
              ? t('book_state.item_away')
              : `${t('book_state.item_more_than')} ${parseInt(payload.self.miles.toString(), 10)}${t('book_state.item_more_than2')}`,
              {
                toastId: 'self_error',
              });
          }
        }

        return payload;
      }
    } catch (_err: any) {
      // eslint-disable-next-line no-unused-vars
      console.log(_err);
    }

    return null;
  };

  function getBodyModel(deliveryTypeParameter: string, pickupByLender: any, roadieParameter: any) {
    const
      {
        idCatLndItem,
        length, width, height, weight,
        value, lat: item_lat,
        lon: item_long,
        street,
        state: stateLocation,
        zipCode, city,
        dailyRentalPrice,
        hourlyRentalPrice, weeklyRentalPrice,
        monthlyRentalPrice,
        salePrice,
        minRentDays,
        // bookItem,
      } = bookDetails;

    const items = [];

    const hasRoadie = precingType != 'HOURLY' && deliveryTypeParameter == ROADIE && roadieParameter && Object.keys(roadieParameter).length > 0;

    let rentalAmmount = 0;
    let accessoriesAmmount = 0;
    let startDate = '';
    let endDate = '';
    let totalHours = 0;
    // let minRentDays = 0;

    if (bookType == RENT) {
      switch (precingType) {
        case PRECING_HOURLY:
          rentalAmmount = hourlyRentalPrice;
          break;
        case PRECING_DAILY:
          rentalAmmount = dailyRentalPrice;
          break;
        case PRECING_WEEKLY:
          rentalAmmount = weeklyRentalPrice;
          break;
        case PRECING_MONTHLY:
          rentalAmmount = monthlyRentalPrice;
          break;

        default:
          break;
      }
      // rentalAmmount = state.summary.grandTotal;
    } else {
      rentalAmmount = salePrice;
    }

    if (bookType == RENT && availability && availability.dateRange) {
      startDate = moment(availability.dateRange[0])
        .format('YYYY-MM-DD').toString();
      if (availability.dateRange.length > 1) {
        endDate = moment(availability.dateRange[1])
          .format('YYYY-MM-DD').toString();
      }

      if (bookType == RENT && precingType == PRECING_HOURLY) {
        totalHours = availability.hours ?? 0;
      }
    } else if (bookType == BUY) {
      startDate = moment(Date.now())
        .format('YYYY-MM-DD').toString();

      endDate = moment(Date.now())
        .format('YYYY-MM-DD').toString();
    }

    if (accesories && accesories.length > 0) {
      const list_accesories: ListAccesoriesModel = accesories.filter((s: any) => s.isAdded == true);

      for (let index = 0; index < list_accesories.length; index += 1) {
        if (bookType == RENT) {
          switch (precingType) {
            case PRECING_HOURLY:
              accessoriesAmmount += +list_accesories[index].hourlyRentalPrice;
              break;
            case PRECING_DAILY:
              accessoriesAmmount += +list_accesories[index].dailyRentalPrice;
              break;
            case PRECING_WEEKLY:
              accessoriesAmmount += +list_accesories[index].weeklyRentalPrice;
              break;
            case PRECING_MONTHLY:
              accessoriesAmmount += +list_accesories[index].monthlyRentalPrice;
              break;

            default:
              break;
          }
        } else {
          accessoriesAmmount += +list_accesories[index].replacementCost;
        }

        items.push({
          dscription: list_accesories[index].dscription != '' ? list_accesories[index].dscription : list_accesories[index].name,
          length: list_accesories[index].length,
          width: list_accesories[index].width,
          height: list_accesories[index].height,
          weight: list_accesories[index].weight,
          quantity: 1,
          value: list_accesories[index].replacementCost ?? 0,
        });
      }
    }

    const body: any = {
      itemId: idCatLndItem,
      startDate,
      endDate,
      rentalType: precingType,
      isRent: bookType == RENT,
      rentalAmmount,
      accessoriesAmmount,
      totalHours,
      minRentDays,
      userId: localStorage.getItem('userId'),
      couponcode: '',
      idCoupon: 0,
      idCode: 0,
    };

    if (couponSelected) {
      body.couponcode = couponSelected.codeCoupon;
      body.idCoupon = couponSelected.idCoupon;
      body.idCode = couponSelected.idCode;
    }

    if (bookType == RENT) {
      if (hasRoadie) {
        const receptionStartTime = state.roadie.receptionStartTime
          ? `${state.roadie?.receptionStartTime.getHours()}:${state.roadie?.receptionStartTime?.getMinutes()}`
          : '00:00';
        const receptionEndTime = state.roadie.receptionEndTime
          ? `${state.roadie?.receptionEndTime.getHours()}:${state.roadie?.receptionEndTime?.getMinutes()}`
          : '00:00';

        const returnStartTime = state.roadie.returnStartTime
          ? `${state.roadie?.returnStartTime.getHours()}:${state.roadie?.returnStartTime?.getMinutes()}`
          : '00:00';
        const returnEndTime = state.roadie.returnEndTime
          ? `${state.roadie?.returnEndTime.getHours()}:${state.roadie?.returnEndTime?.getMinutes()}`
          : '00:00';

        items.push({
          length,
          width,
          height,
          weight,
          quantity: 1,
          value,
          dscription: bookDetails.name,
        });

        const delivery_start = new Date(`${startDate} ${receptionStartTime}`).toISOString();
        const delivery_end = new Date(`${startDate} ${receptionEndTime}`).toISOString();
        const collect_start = new Date(`${endDate} ${returnStartTime}`).toISOString();
        const collect_end = new Date(`${endDate} ${returnEndTime}`).toISOString();

        body.roadie = {
          items,
          deliveryCity: roadieParameter.locality,
          deliveryLatitude: roadieParameter.lat,
          deliveryLongitude: roadieParameter.long,
          deliveryName: bookDetails?.renterName ?? '',
          deliveryStreet: roadieParameter.streetAddress,
          // deliveryPhoneNumber: roadieParameter.
          deliveryState: roadieParameter.region,
          deliveryZip: roadieParameter.postalCode,
          pickUpCity: city,
          pickupLatitude: item_lat,
          pickupLongitude: item_long,
          pickupName: bookDetails?.lenderName ?? '',
          pickupState: stateLocation,
          pickupStreet: street,
          pickupZip: zipCode,
          signatureRequired: false,
          deliveryType: 0,
          rodieItems: items,
          description: '',
          // deliveryLatitude
          pickup_location: {
            address: {
              street1: street,
              city,
              state: stateLocation,
              zip: zipCode,
            },
          },
          delivery_location: {
            address: {
              street1: roadieParameter.streetAddress,
              city: roadieParameter.locality,
              state: roadieParameter.region,
              zip: roadieParameter.postalCode,
            },
          },
          pickup_after: delivery_start,
          deliveryBetweenStart: delivery_start,
          deliveryBetweenEnd: delivery_end,
          pickUpAfter: delivery_start,
          pickupBetweenStart: collect_start,
          pickupBetweenEnd: collect_end,
          totalAmmount: state?.roadie?.price,
          deliver_between: {
            start: delivery_start,
            end: delivery_end,
          },
          collect_between: {
            start: collect_start,
            end: collect_end,
          },

          pickupPhoneNumber: bookDetails?.lenderPhoneNumber ?? '8568884058',
          // pickupPhoneNumber: '2222222222',
          pickupNotes: '',
          deliveryPhoneNumber: bookDetails?.renterPhoneNumber ?? '8568884058',
          // deliveryPhoneNumber: '1111111111',
          deliveryNotes: '',

        };
      }

      if (deliveryTypeParameter == PICKUPBYLENDER && pickupByLender && Object.keys(pickupByLender).length > 0) {
        const { lat, long, streetAddress } = pickupByLender;
        body.selfDelivery = {
          lat,
          long,
          item_lat,
          item_long,
          street: streetAddress,
        };
      }
    }
    return { body, hasRoadie };
  }

  const getDaysInBetween = (startDateBet: Date, endDateBet: Date) => {
    const daysArray = [];
    // eslint-disable-next-line no-plusplus
    for (let x = 0; x <= moment(endDateBet).diff(moment(startDateBet), 'days'); x++) {
      const myFutureDate = new Date(startDateBet);
      daysArray.push(myFutureDate.setDate(myFutureDate.getDate() + x));
    }
    return daysArray.length;
  };

  const bookNow = async (paymentMethod: any) => {
    try {
      const {
        body: {
          itemId,
          startDate,
          endDate,
          rentalType,
          isRent,
          rentalAmmount,
          accessoriesAmmount,
          totalHours,
          roadie: roadieHeader,
          selfDelivery: self,
          couponcode,
          idCode,
          idCoupon,
        }, hasRoadie,
      }: {
        body: any; hasRoadie: any;
      } = getBodyModel(deliveryTypeTemp, pickupLenderTemp, roadieTemp);

      let totalDays = 0;
      // let totalHours = 0;
      let totalWeeks = 0;
      let totalMonths = 0;
      const accessoriesInRent: any = [];

      if (state.bookItem.accesories && state.bookItem.accesories.length > 0) {
        const list_accesories: ListAccesoriesModel = state.bookItem.accesories.filter((s: any) => s.isAdded == true);
        for (let index = 0; index < list_accesories.length; index += 1) {
          let basePrice = 0;
          let accessory_ammount = 0;
          let total_units = 0;
          if (bookType == RENT) {
            switch (precingType) {
              case PRECING_HOURLY:
                basePrice = parseFloat(list_accesories[index].hourlyRentalPrice);
                accessory_ammount = totalHours * list_accesories[index].hourlyRentalPrice;
                total_units = totalHours - 1;
                // accessory_ammount += +list_accesories[index].hourlyRentalPrice;
                break;
              case PRECING_DAILY:
                basePrice = parseFloat(list_accesories[index].dailyRentalPrice);
                totalDays = getDaysInBetween(startDate, endDate) - 1;
                accessory_ammount = totalDays * list_accesories[index].dailyRentalPrice;
                total_units = totalDays;
                // accessory_ammount += +list_accesories[index].dailyRentalPrice;
                break;
              case PRECING_WEEKLY:
                basePrice = parseFloat(list_accesories[index].weeklyRentalPrice);
                totalDays = getDaysInBetween(startDate, endDate) - 1;
                totalWeeks = totalDays / 7;
                accessory_ammount = totalWeeks * list_accesories[index].weeklyRentalPrice;
                total_units = totalWeeks;
                break;
              case PRECING_MONTHLY:
                basePrice = parseFloat(list_accesories[index].monthlyRentalPrice);
                totalDays = getDaysInBetween(startDate, endDate) - 1;
                totalMonths = totalDays / 31;
                accessory_ammount = totalMonths * list_accesories[index].monthlyRentalPrice;
                total_units = Math.round(totalMonths);
                accessory_ammount = totalMonths * list_accesories[index].monthlyRentalPrice;
                break;

              default:
                break;
            }
          } else {
            accessory_ammount = list_accesories[index].replacementCost;
          }
          accessoriesInRent.push({
            toc: 'string',
            idCatLndRntItem: 0,
            idCatLndItemAccessory: list_accesories[index].idRelItemAccesorie, // 4869, //idRelItemAccesorie
            basePrice,
            totalAmmount: accessory_ammount,
            totalunits: total_units,
          });
        }
      }
      // pending totaldeliverypickup
      const totalDeliveryPickup = 0;
      const roadiePrice = (summary?.roadie?.price / (1 - summary?.feeLMI.stripeFee!));
      const totalWithStripe = parseInt(roadiePrice.toFixed(0), 10);
      let paymentDescription = '';
      let lmiLenderFee = 0;
      if (isRent) {
        lmiLenderFee = hasRoadie ? (((state.summary?.totalRental + state.summary?.totalAccesories + totalDeliveryPickup) * state.summary?.feeLMI.lmiLenderFee) + totalWithStripe)
        : ((state.summary?.grandTotal + totalDeliveryPickup) * state.summary?.feeLMI.lmiLenderFee);
        switch (bookDetails?.idCatItemType) {
          case 1:
            paymentDescription = `Reservation of item ${bookDetails?.name.substring(0, 10)} on Lend Me It.`;
            break;
          case 2:
            paymentDescription = `Reservation of service ${bookDetails?.name.substring(0, 10)} on Lend Me It.`;
            break;
          case 3:
            paymentDescription = `Reservation of place ${bookDetails?.name.substring(0, 10)} on Lend Me It.`;
            break;
          default:
            break;
        }
      } else {
        lmiLenderFee = ((state.summary?.grandTotal + totalDeliveryPickup) * state.summary?.feeLMI.lmiLenderFeeBuy);
        paymentDescription = `Purchase of item ${bookDetails?.name.substring(0, 10)} on Lend Me It.`;
      }

      const serviceFee = (state.summary?.grandTotal * state.summary?.feeLMI.stripeFee) + state.summary?.feeLMI.stripeFixedFee;

      const totalLmiComission = (isRent ? 0 : serviceFee) + lmiLenderFee;
      let deliveryAddress = '';
      let deliveryLat = '';
      let deliveryLon = '';

      if (deliveryTypeTemp == PICKUPBYLENDER) {
        deliveryAddress = self.street ?? '';
        deliveryLat = self.lat ?? '';
        deliveryLon = self.long ?? '';
      } else if (deliveryTypeTemp == ROADIE) {
        deliveryAddress = roadieHeader?.deliveryStreet ?? '';
        deliveryLat = roadieHeader?.deliveryLatitude ?? '';
        deliveryLon = roadieHeader?.deliveryLongitude ?? '';
      }

      if (precingType == PRECING_DAILY) {
        totalDays = getDaysInBetween(startDate, endDate) - 1;
      } else if (precingType == PRECING_WEEKLY) {
        const totalDaysPrev = getDaysInBetween(startDate, endDate) - 1;
        totalWeeks = totalDaysPrev / 7;
      } else if (precingType == PRECING_MONTHLY) {
        const totalDaysPrev = getDaysInBetween(startDate, endDate) - 1;
        totalMonths = totalDaysPrev / 31;
      }

      const selfShippingCost = summary?.self?.fee ?? 0;

      let { fromHour, toHour } = state;
      if (moment(state.fromHour).format('YYYY-MM-DD') != startDate) {
        fromHour = `${startDate}${moment(state.fromHour).format('THH:mm')}`;
        toHour = `${startDate}${moment(state.toHour).format('THH:mm')}`;
      }

      const itemBooking: ClsModCreateItemBooking = {
        idCatLndItem: itemId,
        dailyRentalPrice: rentalAmmount,
        shippingCost: summary?.roadie?.price > 0 ? totalWithStripe : selfShippingCost,
        tax: 0,
        subtotalRentalAmmount: state.summary?.totalRental,
        totalRentalAmmount: state.summary?.grandTotal,
        fromDate: startDate,
        toDate: endDate,
        idCatItemStatus: 0,
        idPaymentMethod: paymentMethod ? paymentMethod.idCatLndUserPaymentMethod : 0,
        dateToCancel: startDate,
        depositRentalAmmount: 0,
        acceptConditions: true,
        isApplepay: false,
        rentalDate: startDate,
        requireIdVerifyLender: false,
        requireIdVerifyRenter: bookDetails.requiresRenterIdVerification,
        totalHours,
        totalDays: PRECING_DAILY == 'DAILY' ? totalDays : 0,
        totalWeeks: PRECING_WEEKLY == 'WEEKLY' ? totalWeeks : 0,
        totalMonths: PRECING_MONTHLY == 'MONTHLY' ? totalMonths : 0,
        isRent,
        isBuy: !isRent,
        lmiComission: totalLmiComission,
        idCatLndCircle: idCircle ?? 0,
        totalAccessoriesAmmount: state?.summary?.totalAccesories ?? 0,
        isLenderDelivery: deliveryTypeTemp == PICKUPBYLENDER,
        renterDeliveryAddress: deliveryAddress,
        renterDeliveryLat: deliveryLat,
        renterDeliveryLng: deliveryLon,
        isRoadieDelivery: hasRoadie,
        hasCircleFee: bookDetails?.circleFee > 0 ?? false,
        physicalLat: user_lat,
        physicalLon: user_long,
        physicalAddress: address,
        publicIP: '',
        idCoupon: idCoupon.toString(),
        idCode: idCode.toString(),
        codeCoupon: couponcode,
        couponDescription: '',
        discount: state?.summary?.discountAmount ? Math.abs(parseFloat(state?.summary?.discountAmount.toFixed(2))) : 0,
        idCatLndUserCouponCodes: idCode,
        totalToTransferLender: state?.summary?.totalToTransferLender,
        totalWithOutDiscount: state?.summary?.totalRentalBeforeDiscounts,
        circleFee: bookDetails?.circleFee ?? 0,
        fromHour,
        toHour,
      };
      // let rodieHed:ClsModCreateRoadieDeliveryHeader  = {};

      // if ( hasRoadie ) {
      //   rodieHed = {

      //   }
      // }

      const body: ClsModCheckoutSubmit = {
        rodieHeader: roadieHeader,
        itemId,
        rentalType,
        isRent,
        hasRodie: hasRoadie,
        startDateUTC: startDate,
        endDateUTC: endDate,
        totalHours,
        rentalAmmount,
        accessoriesAmmount,
        couponCode: couponcode,
        hasCircleFee: bookDetails?.circleFee > 0 ?? false,
        hasPayOutAccount: bookDetails.hasPayOutAccount,
        idCatLndPaymentMethod: paymentMethod ? paymentMethod.idCatLndUserPaymentMethod : 0,
        lat: '',
        lon: '',
        ip: '',
        itemBooking,
        rentalAccessories: accessoriesInRent,
        feeCouponCode: {
          total: 10,
          paymentDescription,
        },
      };

      let rentalHasWarning = false;

      if (itemBooking?.idPaymentMethod == 0) {
        // toast add payment method
        rentalHasWarning = true;
        toast.error(t('payment_method.add_payment_method'));
      }
        const now = new Date();
        if (paymentMethod?.exp_month <= now.getMonth()) {
          if (paymentMethod?.exp_year < now.getFullYear()) {
            rentalHasWarning = true;
            toast.error(t('listing_detail.payment_method_expired'));
          }
        } else if (paymentMethod?.exp_month >= now.getMonth()) {
          if (paymentMethod?.exp_year < now.getFullYear()) {
            rentalHasWarning = true;
            toast.error(t('listing_detail.payment_method_expired'));
          }
        }

      // else if (itemBooking?.totalDays < itemBooking.min)
      if (!rentalHasWarning) {
        const checkoutSubmitResponse: any = await _api.apiItemCheckoutSubmitPost(body);
        const obj = JSON.parse(checkoutSubmitResponse.data);
        if (obj.Response && obj.Error == '') {
          // dispatch rental created
          dispatch({
            type: RENTAL_CREATED,
            payload: {
              rentalCreated: true,
            },
          });
        } else {
          dispatch({
            type: RENTAL_CREATION_ERROR,
            payload: {
              rentalError: true,
              errorBookMessage: obj.Error,
            },
          });
        }
      } else {
        dispatch({
          type: RENTAL_CREATION_ERROR,
          payload: {
            rentalError: true,
          },
        });
      }

      // eslint-disable-next-line no-empty
    } catch (err: any) {
      dispatch({
        type: RENTAL_CREATION_ERROR,
        payload: {
          rentalError: true,
        },
      });
      console.log(err);
    }
  };

  const getLenderItems = async (idLender: any) => {
    try {
      // Get items from a specific lender, in order to show them in a carousel
      // in the book Item page
      const { data } = await _api.userGetUserLndItemsNewFlowPublicProfile({ idCatLndLender: idLender, idCatLndItem: 0, toc: 'qwerty' });
      if (typeof data === 'string') {
        const obj = JSON.parse(data.toString());
        const lenderItemList = obj.Response;

        dispatch({
          type: GET_LENDER_ITEMS,
          payload: {
            lenderItemList,
          },
        });
    }
  } catch (e: any) {
      _loading(false);
      toast.error(e);
    }
  };

  const setViewedItem = async (idCatLndItem: any) => {
    const bodyCreateview: ClsModCreateUserViewed = {
      toc: 'toc',
      idCatLndItem,
      create: 1,
    };
      await _api.userCreateUserViewed(bodyCreateview);
  };

  const getBookItemDetails = async ({ idCatLndItem, idCatLndCircle }:
    any) => {
    try {
      const { data } = await _api.itemGetItemDetailInfo(
        { idCatLndItem, idCatLndCircle, toc: 'qwerty' },
      );
      const imagesData = await getBookImages(idCatLndItem!);
      const unavailableDatesData = await getBookUnavailableDates(idCatLndItem!);
      const condictionsData = await userGetUserTermsAndConditions(idCatLndItem!);
      const AccesoriesData = await getBookItemRelatedAccessories(idCatLndItem!);

      if (typeof data === 'string') {
        const obj = JSON.parse(data.toString());
        const bookDetailsData = obj.Response[0];
        // const { response, error } = data;
        if (bookDetailsData) {
          const savedPrecingType = localStorage.getItem('precingType');
          const defaultRentalType = bookDetailsData.isDailyRental
            ? 'DAILY'
            : bookDetailsData.isHourlyRental ? 'HOURLY'
              : bookDetailsData.isWeeklyRental ? 'WEEKLY'
                : 'MONTHLY';

          localStorage.setItem('bookType', RENT);
          dispatch({
            type: GET_BY_ID,
            payload: {
              bookItem: {
                bookDetails: bookDetailsData,
                images: imagesData,
                unavailableDates: unavailableDatesData,
                accesories: AccesoriesData,
                condictions: condictionsData,
                idCatLndCircle: idCatLndCircle ?? 0,
              },
              bookType: RENT,
              precingType: (savedPrecingType != null && savedPrecingType != '') ? savedPrecingType : defaultRentalType,
            },
          });
        } else {
          toast.error(t('book_state.not_found'));

          _loading(false);
        }
      }
    } catch (error: any) {
      _loading(false);
      toast.error(error);
    }
  };

  const changePrcingType = async (type: PRECING_TYPE) => {
    try {
      dispatch({
        type: PRECING_CHANGE,
        payload: type,
      });
    } catch (e: any) {
      toast.error(e ?? t('book_state.change_price'));
    }
  };

  const changeDeliveryType = async (type: DELIVERY_TYPE) => {
    try {
      dispatch({
        type: DELIVERY_CHANGE,
        payload: type,
      });
    } catch (e: any) {
      toast.error(e ?? t('book_state.change_delivery'));
    }
  };

  const setPromoSelectedTemp = async (codeId: number) => dispatch({
    type: PROMO_CODE_CHANGE_TEMP,
    payload: codeId,
  });

  const setPromoSelected = async () => dispatch({
    type: PROMO_CODE_CHANGE,
    payload: null,
  });

  const changeDeliveryTypeTemp = async (type: DELIVERY_TYPE) => dispatch({
    type: DELIVERY_CHANGE_TEMP,
    payload: type,
  });

  const changePickup = async () => dispatch({
    type: PICKUP_CHANGE,
  });

  const getBookImages = async (idCatLndItem: number) => {
    const body: ClsModGetItemImages = {
      idCatLndItem,
      toc: '123456',
    };
    try {
      const result = await _api.itemGetItemImages(body);
      const data = JSON.parse(result.data.toString());
      return data.Response;
    } catch (e: any) {
      toast.error(e ?? t('book_state.load_images'));
      return null;
    }
  };

  const getBookUnavailableDates = async (idCatLndItem: number) => {
    const body: ClsModGetItemUnavailableDates = {
      idCatLndItem,
      toc: '123456',
    };
    try {
      const result = await _api.itemGetItemUnavailableDates(body);
      const data = JSON.parse(result.data.toString());
      const arrayDates: any = [];

      data.Response.forEach((e: any) => {
        // arrayDates.push(e.blockedDate);
        const days = getDates(e.from.replaceAll('/', '-'), e.to.replaceAll('/', '-'));
        days.forEach((date: any) => {
          if (date != undefined && date != null) {
            arrayDates.push(date);
          }
        });
      });
      return arrayDates;
    } catch (e: any) {
      toast.error(e ?? t('book_state.unavailable_dates'));
      return null;
    }
  };

  const getDates = (startDate: Date, stopDate: Date) => {
    const dateArray = [];
    let currentDate = moment(startDate);
    // .format('YYYY-MM-DD').toString()
    while (currentDate <= moment(stopDate) && currentDate >= moment(Date.now())) {
      dateArray.push(currentDate.format('YYYY-MM-DD').toString());
      currentDate = currentDate.add(1, 'days');
    }
    return dateArray;
  };

  const getItemAccesories = async (idCatLndItem: number) => {
    const body: ClsModGetItemRelatedAccessories = {
      idCatLndItem,
      toc: 'jsjdjajd',
    };
    try {
      const result = await _api.itemGetItemRelatedAccessories(body);
      const data = JSON.parse(result.data.toString());
      return data.Response;
    } catch (e: any) {
      toast.error(e ?? t('book_state.load_accessories'));
      return null;
    }
  };

  const userGetUserTermsAndConditions = async (idCatLndItem: number) => {
    const body: ClsModGetUserTermsAndConditions = {
      idCatLndItem,
      toc: 'jsjdjajd',
      ownTerms: 0,
    };
    let response = null;

    try {
      const result = await _api
        .userGetUserTermsAndConditions(body);

      const data = JSON.parse(result.data.toString());
      response = data.Response;
    } catch (e: any) {
      toast.error(e ?? t('book_state.user_terms'));
    }

    return response;
  };

  const getBookItemRelatedAccessories = async (idCatLndItem: number) => {
    const body: ClsModGetUserTermsAndConditions = {
      idCatLndItem,
      toc: 'jsjdjajd',
    };
    let response = null;

    try {
      const result = await _api.itemGetItemRelatedAccessories(body);
      const data = JSON.parse(result.data.toString());
      response = data?.Response as ItemAccessoriesModel[];
    } catch (e: any) {
      toast.error(e ?? t('book_state.accessories'));
    }

    return response;
  };

  const funBookType = (
    _event: React.MouseEvent<HTMLElement>,
    tabSelected: string,
  ) => {
    if (tabSelected !== null) {
      dispatch({
        type: BOOK_TYPE_CHANGE,
        payload: tabSelected,
      });
    }
  };

  const updateLenderPikupInfo = async (values: any) => dispatch({
    type: UPDATE_LENDER_INFO,
    payload: values,
  });

  const updateFromHourRental = async (values: any) => dispatch({
    type: UPDATE_FROM_HOUR,
    payload: values,
  });

  const updateToHourRental = async (values: any) => dispatch({
    type: UPDATE_TO_HOUR,
    payload: values,
  });

  const updateLenderPikupInfo_Temp = async (values: any) => dispatch({
    type: UPDATE_LENDER_INFO_TEMP,
    payload: values,
  });

  const updateRoadieInfo = async (values: any) => dispatch({
    type: UPDATE_ROADIE_INFO,
    payload: values,
  });

  const updateRoadieInfoTemp = async (values: any) => dispatch({
    type: UPDATE_ROADIE_INFO_TEMP,
    payload: values,
  });

  const editAccesory = async (values: any) => {
    dispatch({
      type: UPDATE_ACCESORY,
      payload: values,
    });
  };

  const modifyAccesories = async (acessories: []) => dispatch({
    type: MODIFY_ACCESORY,
    payload: acessories,
  });

  const createItemFavorite = async (item: number) => {
    _loading();

    try {
      if (token) {
        const { isFavorite } = bookDetails;
        const create = isFavorite === 'active' ? 0 : 1;
        const favorite = create === 1 ? 'active' : '';
        const body: ClsModCreateUserFavorite = {
          idCatLndItem: item,
          toc: 'qwerty',
          create,
        };

        const { data } = await _api.userCreateUserFavorite(body);

        if (typeof data === 'string') {
          const obj = JSON.parse(data);
          const bookDetailsData = obj.Response[0];

          if (bookDetailsData) {
            dispatch({
              type: UPDATE_FAVORITE,
              payload: favorite,
            });
          }
        }
      } else {
        window.location.replace('/login');
      }
    } catch (_) {
      toast.error(t('book_state.favorite'));
    }

    _loading(false);
  };

  const _loading = (load: boolean = true) => {
    dispatch({
      type: LOADING,
      payload: load,
    });
  };

  const clearData = async () => {
    localStorage.removeItem('precingType');
    localStorage.removeItem('endTime');
    localStorage.removeItem('startTime');
    initialState.precingType = PRECING_DAILY;
    dispatch({
      type: INITIAL,
      payload: initialState,
    });
  };

  const setBookItemDetails = async (useStatee: any) => {
    const hasRoadie = useStatee.deliveryType == ROADIE && useStatee.roadie && Object.keys(useStatee.roadie).length > 0;

    if (hasRoadie) {
      useStatee.roadie.receptionStartTime = new Date(useStatee.roadie.receptionStartTime);
      useStatee.roadie.receptionEndTime = new Date(useStatee.roadie.receptionEndTime);
      useStatee.roadie.returnStartTime = new Date(useStatee.roadie.returnStartTime);
      useStatee.roadie.returnEndTime = new Date(useStatee.roadie.returnEndTime);
    }
    useStatee.loading = true;
    useStatee.deliveryTypeTemp = useStatee.deliveryType;
    useStatee.pickupChangeTemp = { ...useStatee.pickupChange };
    useStatee.pickupLenderTemp = { ...useStatee.pickupLender };
    useStatee.summaryTemp = { ...useStatee.summary };
    useStatee.roadieTemp = { ...useStatee.roadie };
    useStatee.roadieChangeTemp = { ...useStatee.roadieChange };

    if (!useStatee?.deliveryType) {
      dispatch({
        type: SET_STATE,
        payload: useStatee,
      });
    } else {
      dispatch({
        type: SET_STATE_ALL,
        payload: useStatee,
      });
    }

    setTimeout(() => {
      _loading(false);
    }, 1300);
  };

  const clickPayNowbtn = async () => {
    const bookItem = state.bookItem.bookDetails;
    if (bookItem.isRent) {
      // if (bookItem.startDate)
    }
  };

  const validateBookNow = async (acceptBigDistance = false, skipVerify = false) => {
    let redirect;
    let toState;

    try {
      await Auth.currentAuthenticatedUser();

      const {
        dateRange,
        errorAvailability,
        hours,
      } = availability;

      const hasErrorRange = bookType == RENT && (((precingType != PRECING_HOURLY) && errorAvailability && errorAvailability != '')
      || (!dateRange[1] && (precingType != PRECING_HOURLY)) || (!dateRange[1] && (precingType == PRECING_HOURLY)));

      const hasErrorHoursRange = bookType == RENT && ((hours == null && (precingType == PRECING_HOURLY)) || (hours == 0 && (precingType == PRECING_HOURLY)));

      if (hasErrorRange) {
        toast.warning(t('book_state.need_dates'));
      } else if (hasErrorHoursRange) {
        toast.warning(t('book_state.need_hours'));
      } else {
        const { data } = await _api.userValidatesUserPendingInfo({ toc: '' });

        if (typeof data === 'string') {
          const obj = JSON.parse(data);
          const userPendingInfo = obj.Response[0];
          const distanceToItem = distance(user_lat, user_long, parseFloat(state.bookItem.bookDetails.lat), parseFloat(state.bookItem.bookDetails.lon), 'M');

          if (userPendingInfo.firstName.length == 0
            || userPendingInfo.lastName.length == 0
            || userPendingInfo.profilePicture.length == 0) {
            userPendingInfo.openModal = true;
            dispatch({
              type: NEED_COMPLETE_PROFILE,
              payload: userPendingInfo,
            });
          } else if (parseFloat(distanceToItem) > 50 && !acceptBigDistance) {
            dispatch({
              type: NEED_ACCEPT_LONG_DISTANCE,
              payload: { ...showAcceptDistance, openModal: true },
            });
          } else if (state.bookItem.bookDetails.requiresRenterIdVerification
              && !state.bookItem.bookDetails.renterVerified && !skipVerify
              && !privateProfileInfo?.isShuftiVerified) {
            dispatch({
              type: NEED_VERIFY_ACCOUNT,
              payload: true,
            });
          } else if (precingType == 'HOURLY' && moment(dateRange[0]).isSame(moment(), 'day') && moment(state.fromHour).hour() < moment().hour()) {
            toast.error(t('book_state.hour_range_past'));
          } else {
            // _loading(true);
            const hasRoadie = deliveryType == ROADIE && roadie && Object.keys(roadie).length > 0;

            if (hasRoadie) {
              // state.roadie.receptionStartTime = state.roadie.receptionStartTime.toUTCString();
              // state.roadie.receptionEndTime = state.roadie.receptionEndTime.toUTCString();
              // state.roadie.receptionStartTime = state.roadie.receptionStartTime.toUTCString();
              // state.roadie.receptionEndTime = state.roadie.receptionEndTime.toUTCString();
              // state.roadie.receptionEndTime = state.roadie.receptionEndTime.toDate().toUTCString();
              // state.roadie.returnStartTime = state.roadie.returnStartTime.toUTCString();
              // state.roadie.returnEndTime = state.roadie.returnEndTime.toUTCString();
              // state.roadie.returnEndTime = state.roadie.returnEndTime.toDate().toUTCString();
            }

            if (roadieWarning.length > 0) {
              toast.warning(roadieWarning);
            } else {
              const toSave = JSON.stringify(state);
              localStorage.setItem('checkout', toSave);

              redirect = '/checkout';
            }

            // toState = state;
          }
        } else {
          window.location.replace('/login');
          history.push('/login', state);
        }
      }
    } catch (err) {
      window.location.replace('/login');
      history.push('/login', state);
    }

    return { redirect, toState };
  };

  const validateCodeCoupon = async (code: string) => {
    let exist = false;
    try {
      const body: ClsModAddNewCoupon = {
        code,
      };

      const { data } = await _api.apiCouponsAddNewCouponPost(body);

      if (typeof data === 'string') {
        const obj = JSON.parse(data);

        if (obj.Error === '') {
          dispatch({
            type: COUPON_NEW,
            payload: obj.Response,
          });

          exist = true;
        } else {
          toast.warning(obj.Error);
        }
      }
      // eslint-disable-next-line no-empty
    } catch (err) {
    }

    return exist;
  };

  const getCouponCodes = async (type = 0) => {
    try {
      const body: ClsModGetUserCoupons = {
        option: type,
      };

      const { data } = await _api.couponsGetUserCoupons(body);

      if (typeof data === 'string') {
        const obj = JSON.parse(data);
        const coupons = obj.Response;

        dispatch({
          type: COUPON_LIST,
          payload: coupons ?? [],
        });
      }
      // eslint-disable-next-line no-empty
    } catch (err) {
    }
  };

  const closePendingInfo = () => dispatch({
    type: NEED_COMPLETE_PROFILE,
    payload: { ...completeProfile, openModal: false },
  });

  const closeShowAcceptDistance = () => dispatch({
    type: NEED_ACCEPT_LONG_DISTANCE,
    payload: { ...showAcceptDistance, openModal: false },
  });

  const closeNeedsVerifyModal = () => dispatch({
    type: NEED_VERIFY_ACCOUNT,
    payload: false,
  });

  const updateProfile = (info: any) => dispatch({
    type: UPDATE_PENDING_INFO,
    payload: info,
  });

  const clearErrorMessage = () => dispatch({
    type: CLEAR_BOOK_ERROR,
  });

  const { t } = useTranslation('global');

  return (
    <BookContext.Provider
      value={{
        ...state,
        availability,
        loading,
        bookDetails,
        images,
        unavailableDates,
        condictions,
        accesories,
        precingType,
        bookType,
        pickupLender,
        roadie,
        includeAccesories,
        summary,
        deliveryType,
        pickupChange,
        roadieChange,
        completeProfile,
        couponList,
        couponSelected,
        pickupChangeTemp,
        pickupLenderTemp,
        summaryTemp,
        roadieTemp,
        roadieChangeTemp,
        deliveryTypeTemp,
        rentalError,
        rentalCreated,
        funBookType,
        getBookItemDetails,
        getBookImages,
        getBookUnavailableDates,
        getItemAccesories,
        userGetUserTermsAndConditions,
        getBookItemRelatedAccessories,
        changePrcingType,
        updateLenderPikupInfo,
        updateRoadieInfo,
        editAccesory,
        createItemFavorite,
        clearData,
        getRoadieEstimation: getSummary,
        setDateRange,
        setHours,
        setAvailabilityError,
        changeDeliveryType,
        changePickup,
        validateBookNow,
        setBookItemDetails,
        closePendingInfo,
        updateProfile,
        modifyAccesories,
        validateCodeCoupon,
        getCouponCodes,
        updateLenderPikupInfo_Temp,
        updateRoadieInfoTemp,
        changeDeliveryTypeTemp,
        setPromoSelected,
        setPromoSelectedTemp,
        bookNow,
        closeShowAcceptDistance,
        closeNeedsVerifyModal,
        clickPayNowbtn,
        updateFromHourRental,
        updateToHourRental,
        getLenderItems,
        clearErrorMessage,
        setViewedItem,
      }}
    >
      {props.children}

    </BookContext.Provider>
  );
};

export default BookItemState;

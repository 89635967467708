/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable react/jsx-props-no-spreading */
import SwiperCore, { EffectFade, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import {
  Stack, Box, Typography, Fab, useTheme, useMediaQuery,
} from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import cookies from 'js-cookie';
import SearchIcon from '@mui/icons-material/Search';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import {
  ContentLayout, SearchModal, Logo, UserMenu,
} from '../../../../../components/Common';
import HeaderSwiperStyle from './HeaderSwiper.style';

import googlePlay from '../../../../../assets/header_swiper/google-play-badge-en.png';
import googlePlay_es from '../../../../../assets/header_swiper/google-play-badge-es.png';
import appStore from '../../../../../assets/header_swiper/app-store-badge.svg';
import appStore_es from '../../../../../assets/header_swiper/app-store.png';

SwiperCore.use([EffectFade, Autoplay]);

export default function HeaderSwiper() {
  const history = useHistory();
  const goToHome = () => {
    history.push('/');
  };

  const [openSearch, setOpenSearch] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.up('sm') && theme.breakpoints.down('md'));
  const toggleModal = () => {
    setOpenSearch(!openSearch);
  };

  const { t } = useTranslation('global');

  const images: any = [
    {
      kImg: 'bike',
      kAlign: 'center',
      kTitle: <Trans components={{ br: <br /> }}>{t('header_swiper.rent_everything')}</Trans>,
      kDesc: `${t('header_swiper.rent_everything_txt')}`,
    },
    {
      kImg: 'outdoor',
      kTitle: <Trans components={{ br: <br /> }}>{t('header_swiper.outdoors')}</Trans>,
      kAlign: 'center',
      kDesc: `${t('header_swiper.outdoors_txt')}`,
    },
    {
      kImg: 'audio',
      kTitle: <Trans components={{ br: <br /> }}>{t('header_swiper.audio')}</Trans>,
      kAlign: 'center',
      kDesc: `${t('header_swiper.audio_txt')}`,
    },
    {
      kImg: 'instruments',
      kTitle: <Trans components={{ br: <br /> }}>{t('header_swiper.instruments')}</Trans>,
      kAlign: 'center',
      kDesc: `${t('header_swiper.instruments_txt')}`,
    },
    {
      kImg: 'photo_video',
      kTitle: <Trans components={{ br: <br /> }}>{t('header_swiper.photo')}</Trans>,
      kAlign: 'center',
      kDesc: `${t('header_swiper.photo_txt')}`,
    },
    {
      kImg: 'sporting',
      kTitle: <Trans components={{ br: <br /> }}>{t('header_swiper.sporting')}</Trans>,
      kAlign: 'center',
      kDesc: `${t('header_swiper.sporting_txt')}`,
    },
    {
      kImg: 'tools',
      kTitle: <Trans components={{ br: <br /> }}>{t('header_swiper.tools')}</Trans>,
      kAlign: 'center',
      kDesc: `${t('header_swiper.tools_txt')}`,
    },

  ];

  const MainParams = {
    modules: [EffectFade, Autoplay],
    speed: 3000,
    parallax: false,
    loop: false,
    autoplay: {
      delay: 12000,
      disableOnInteraction: false,
    },
  };

  // eslint-disable-next-line no-nested-ternary
  const sizeLogo = isMobile ? 50 : isTablet ? 180 : 210;

  return (
    <>
      <Box sx={{ ...HeaderSwiperStyle, position: 'relative' }} component="section">
        <Box className="navbar">
          <ContentLayout>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Box sx={{ display: 'flex' }} onClick={() => goToHome()}>
                <Logo width={sizeLogo} icon={isMobile} iconColor="white" textColor="white" />
              </Box>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Fab
                  onClick={() => { toggleModal(); }}
                  size="medium"
                  className="fabIconNavbarSecondary"
                >
                  <SearchIcon />
                </Fab>

                <UserMenu kType="showFullButton" />
              </Stack>
            </Stack>
          </ContentLayout>
        </Box>
        <SearchModal
          kSearchModalOpen={openSearch}
          kSearchModalToggle={() => toggleModal()}
        />
        <Swiper
          {...MainParams}
          effect="fade"
        >
          {images.map((data: any) => (
            <SwiperSlide key={data.kImg.toString()}>
              <Box
                sx={{
                  backgroundImage: `url(${require(`../../../../../assets/header_swiper/${data.kImg}.webp`).default})`,
                  height: { xs: '400px', sm: '700px' },
                  backgroundSize: 'cover',
                  backgroundPosition: data.kAlign,
                }}
              >
                <ContentLayout>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-end"
                    spacing={2}
                    sx={{ height: { xs: '400px', md: '700px' } }}
                  >
                    <Box className="captions">
                      <Typography component="h1">
                        {data.kTitle}
                      </Typography>
                      <Typography component="p">
                        {data.kDesc}
                      </Typography>
                    </Box>
                  </Stack>
                </ContentLayout>
                <Box component="div" className="bg" />
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
        <Box className="badges">
          <ContentLayout>
            <Stack
              direction="row"
              justifyContent={{ xs: 'space-start', md: 'flex-end' }}
              alignItems="flex-end"
              spacing={{ xs: 1, md: 2 }}
            >
              <Box sx={{ height: { xs: '40px', sm: '50px', md: '60px' } }}>
                <a href="https://play.google.com/store/apps/details?id=com.lendmeit.app" rel="noopener noreferrer" target="_blank">
                  {
                    cookies.get('i18next') === 'en' ? (
                      <img src={googlePlay} alt="Google Play" height="100%" />
                    ) : (<img src={googlePlay_es} alt="Google Play" height="100%" />)
                  }
                </a>
              </Box>
              <Box sx={{ height: { xs: '40px', sm: '50px', md: '60px' } }}>
                <a href="https://itunes.apple.com/us/app/lend-me-it-lend-rent-easily/id1444352676" rel="noopener noreferrer" target="_blank">
                  {
                    cookies.get('i18next') === 'en' ? (
                      <img src={appStore} alt="AppStore" height="100%" />
                    ) : (<img src={appStore_es} alt="AppStore" height="100%" />)
                  }
                </a>
              </Box>
            </Stack>
          </ContentLayout>
        </Box>
      </Box>

    </>
  );
}

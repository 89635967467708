import {
  INIT_WS_CONNECTION,
  DELIVERY_ACTIVITY_SUCCESS_ROADIE,
  CHAT_ALL_MESSAGES,
  CHAT_NEW_MESSAGE,
  CHAT_NOTIFICATIONS,
  CHAT_START_TASK,
} from '../../types';

export default (state: any, action: any) => {
  switch (action.type) {
    case INIT_WS_CONNECTION:
      return {
        ...state,
        ws: action.payload,
      };
    case DELIVERY_ACTIVITY_SUCCESS_ROADIE:
      // const collectShipment = action.payload.collectShipment;
      return {
        ...state,
        // returnShipment: action.payload.returnShipment,
        collectLatLng: action.payload.collectLatLng,
        returnLatLng: action.payload.returnLatLng,
        deliveryId: action.payload.deliveryId,
      };
    case CHAT_ALL_MESSAGES:
      return {
        ...state,
        allMessages: action.payload,
      };
    case CHAT_NEW_MESSAGE:
      return {
        ...state,
        message_received: action.payload.message,
        newNotification: action.payload.rentId,
        newMessage: true,
      };
    case CHAT_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case CHAT_START_TASK:
      return {
        ...state,
        newMessage: false,
      };
    default:
      return state;
  }
};

import {
  CHANGE, RECOMMENDED_MONTHLY, RECOMMENDED_WEEKLY,
} from '../../types';

export default (state: any, action: any) => {
  switch (action.type) {
    case CHANGE:
      const { starLimitDate, endLimitDate } = action.payload;

      return {
        ...state,
        starLimitDate,
        endLimitDate,
      };
    case RECOMMENDED_WEEKLY:
      const {
        starSelectDate: starWeekSelectDate,
        endSelectDate: endWeekSelectDate,
        daysOfYear: daysOfWeek,
      } = action.payload;

      return {
        ...state,
        starWeekSelectDate,
        endWeekSelectDate,
        daysOfWeek,
      };
    case RECOMMENDED_MONTHLY:
      const {
        starSelectDate: starMonthSelectDate,
        endSelectDate: endMonthSelectDate,
        daysOfYear: daysOfMonth,
      } = action.payload;

      return {
        ...state,
        starMonthSelectDate,
        endMonthSelectDate,
        daysOfMonth,
      };

    default:
      return state;
  }
};

import {
  Button, Box,
  Stack, FormControl, OutlinedInput, InputAdornment, useMediaQuery, useTheme, AppBar,
} from '@mui/material/';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import Logo from '../../Common/Logo/Logo';
import { UserMenu, SearchSuggestion } from '../../Common';
import BackOfficeAppBarStyle from './BackOfficeAppBar.style';

import ExploreContext from '../../../context/explore/exploreContext';
import publicProfileContext from '../../../context/publicProfile/publicProfileContext';
import authContext from '../../../context/authentication/authContext';

export default function BackOfficeAppBar() {
  const {
    // loading,
    privateProfileInfo,
    getPrivateProfileInfo,
  }: any = useContext(publicProfileContext);

  const {
    token,
  }: any = useContext(authContext);

  const {
    searched,
    clearData,
  }: any = useContext(ExploreContext);
  const [isVisibleSuggestion, setVisibleSuggestion] = useState(false);
  const [search, setSearch] = useState(searched);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const history = useHistory();

  useEffect(() => {
    if (searched && searched != '') { setSearch(searched); }
  }, [searched]);

  useEffect(() => {
    if ((token && Object.keys(privateProfileInfo).length == 0) || (token && Object.keys(privateProfileInfo).length <= 3)) {
      getPrivateProfileInfo({ idCatLender: 0 });
      // console.log(privateProfileInfo.completeName);
    }
  }, [token]);

  const gotoSearch = (value?: string) => {
    if (history.location.pathname == '/') {
      clearData();
    }
    if (value) {
      history.push(`/search/${value}`);
    } else {
      history.push(`/search/${search}`);
    }
  };

  const { t } = useTranslation('global');

  return (
    <>
      <AppBar
        position="fixed"
        color="inherit"
        elevation={0}
        sx={BackOfficeAppBarStyle}
      >
        <Stack
          direction={{ md: 'row', xs: 'column' }}
          justifyContent={{ lg: 'space-between', xs: 'center' }}
          alignItems="center"
          spacing={{ lg: 2, xs: 1 }}
          className="lmi-stack"
        >

          <Box
            className="lmi-box-logo lmi-hide-down-lg"
            onClick={() => history.push('/')}
          >
            <Logo icon={isMobile} width={isMobile ? 50 : 150} iconColor="black" textColor="black" />
          </Box>
          {isTablet ? (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={{ lg: 2, xs: 1 }}
              className="lmi-stack-logo-usermenu"
            >
              <Box
                className="lmi-box-logo"
                onClick={() => history.push('/')}
              >
                <Logo icon={isMobile} width={isMobile ? 50 : 150} iconColor="black" textColor="black" />
              </Box>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={{ lg: 2, xs: 1 }}
              >
                <UserMenu kType="showFullButton" />
              </Stack>
            </Stack>
          ) : <></>}

          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={{ lg: 2, xs: 1 }}
            className="lmi-stack-formcontrol"
          >
            <FormControl
              className="lmi-formcontrol"
              onChange={(e: any) => setSearch(e.target.value)}
              onKeyDown={(e: any) => (e.keyCode == 13
                ? gotoSearch()
                : '')}
            >
              <OutlinedInput
                inputProps={{
                  maxLength: 50,
                }}
                value={search}
                fullWidth
                className="lmi-input-search"
                onFocus={() => {
                  setVisibleSuggestion(true);
                }}
                onBlur={() => {
                  setTimeout(() => {
                    setVisibleSuggestion(false);
                  }, 150);
                }}
                startAdornment={(
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )}
                placeholder={t('search.what_looking')}
              />
              {(search || search == '') && isVisibleSuggestion
                ? <SearchSuggestion kTop={50} kInputText={search} kOnClick={(text: string) => gotoSearch(text)} />
                : ''}

            </FormControl>
            <Button
              onClick={() => gotoSearch()}
              className="lmi-btn-search"
              variant="outlined"
              disableElevation
            >
              {t('search.search')}
            </Button>
          </Stack>
          {!isTablet ? (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={{ lg: 2, xs: 1 }}
            >
              <UserMenu kType="showFullButton" />
            </Stack>
          ) : <></>}

        </Stack>
      </AppBar>
    </>
  );
}

import Paper from '@mui/material/Paper';
// import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import { SideFormModels } from './SideForm.models';
import img1 from '../../../assets/login/splash-screen-1.jpg';
import img2 from '../../../assets/login/splash-screen-2.jpg';
import img3 from '../../../assets/login/splash-screen-3.jpg';
import img4 from '../../../assets/login/splash-screen-4.jpg';
import img5 from '../../../assets/login/splash-screen-5.jpg';
import img6 from '../../../assets/login/splash-screen-6.jpg';

export default function SideForm(props: SideFormModels) {
  const coverImage = [img1, img2, img3, img4, img5, img6];

  const randomCoverImage = coverImage[Math.floor(Math.random() * coverImage.length)];

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={false}
        md={6}
        lg={6}
        sx={{
          backgroundImage: `url("${randomCoverImage}")`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={12} md={6} lg={6} component={Paper} elevation={0} square style={{ position: 'relative' }}>
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
          px={3}
          sx={{ width: '100%', height: '100%' }}
        >
          {props.content}
        </Grid>
      </Grid>
    </Grid>
  );
}

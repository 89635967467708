import { useReducer } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { ClsModGetDiscoveryFavoriteItems, ClsModCreateUserFavorite } from '@lendmeit/api_backend_lmi/dist/models';
import FavoritesContext from './favoritesContext';
import FavoritesReducer from './favoritesReducer';
import {
  ITEMS_LOADED,
} from '../../types';
import ApiLMI from '../../services/apilmi';

const FavoritesState = ({ children }: any) => {
  const initialState = {
    favItems: [],
  };

  const [state, dispatch] = useReducer(FavoritesReducer, initialState);
  let favoriteItems = [];

  const loadItems = async () => {
    const _api = ApiLMI();

    try {
      const body: ClsModGetDiscoveryFavoriteItems = {
        toc: 'qwer',
      };
      // const { data } = await _api
      //   .itemMainItemSearchNewFlowCategories(body);

      const { data } = await _api.itemGetDiscoveryFavoriteItems(body);

      if (typeof data === 'string') {
        const obj = JSON.parse(data.toString());
        // filter by currency
        favoriteItems = obj.Response;
      } else {
        favoriteItems = [];
        toast.error(t('global.explore_error'));
      }
    } catch (err: any) {
      toast.error(err);
      favoriteItems = [];
    }

    dispatch({
      type: ITEMS_LOADED,
      payload: {
        favItems: favoriteItems,
      },
    });
  };

  const updateFavorite = async (item: any, isFavorite: boolean) => {
    const _api = ApiLMI();
    let favoriteItmes;
    try {
      const body: ClsModCreateUserFavorite = {
        toc: 'qwer',
        idCatLndItem: item.idCatLndItem,
        create: isFavorite ? 1 : 0,
      };
      const { data } = await _api.userCreateUserFavorite(body);

      if (typeof data === 'string') {
        const obj = JSON.parse(data.toString());
        // filter by currency
        favoriteItems = obj.Response;
        if (isFavorite) {
          favoriteItmes = state.favItems;
          favoriteItmes.push(item);
        } else {
          favoriteItems = state.favItems;
          favoriteItmes = state.favItems
            .filter((r: any) => r.idCatLndItem != item.idCatLndItem);
        }
        //   if (event.isFavorite)
        //   items.add(event.item);
        // else
        //   items.removeWhere(
        //       (element) => element.idCatLndItem == event.item.idCatLndItem);
      } else {
        favoriteItems = [];
        toast.error(t('global.explore_error'));
      }
    } catch (err: any) {
      toast.error(err);
      favoriteItems = [];
    }
    dispatch({
      type: ITEMS_LOADED,
      payload: {
        favItems: favoriteItmes,
      },
    });
  };

  const { t } = useTranslation('global');

  return (
    <FavoritesContext.Provider
      value={
     {
       ...state,
       favItems: state.favItems,
       loadItems,
       updateFavorite,
     }
      }
    >
      {children}
    </FavoritesContext.Provider>
  );
};

export default FavoritesState;

import {
  Box, Link, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function FoterLogin() {
  const { t } = useTranslation('global');
  return (
    <Box
      // position="absolute"
      my={4}
      color="transparent"
      sx={{ bottom: '40px', width: '100%' }}
    >
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        sx={{ maxWidth: '260px', marginLeft: 'auto', marginRight: 'auto' }}
      >
        {t('sign_up.agree_txt')}
        {' '}
        <Link color="primary" href="/terms">
          {t('sign_up.agree_txt2')}
          {' '}
        </Link>
        {t('sign_up.agree_txt3')}
        {' '}
        <Link color="primary" href="/eula">
          {t('sign_up.agree_txt4')}
        </Link>
      </Typography>
    </Box>
  );
}

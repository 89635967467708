import { FormControlLabel, Checkbox } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputForm from '../../../../../../../components/Common/InputForm/InputForm';
import addEditItemContext from '../../../../../../../context/addEditItem/addEditItemContext';
import { getKeyValue } from '../../../../../../../services/common';

export default function AddRequirements() {
  const { t } = useTranslation('global');
  const addEditContextLog = useContext(addEditItemContext);

  const {
    addEditRequirementInfo,
    updateRequirementInfo,
    updateDraftRequirementList,
    checkReqValidate,
    updateReqValidationStatus,
  }: any = addEditContextLog;

  const [, setIsTermDetailValid] = useState(true);

  useEffect(() => {
    let isEverythingOk = true;
    if (checkReqValidate) {
      if (addEditRequirementInfo.termDetail.length == 0) {
        isEverythingOk = false;
        setIsTermDetailValid(false);
      }
      updateReqValidationStatus(isEverythingOk);
    }
  }, [checkReqValidate]);

  const onChange = (e: any) => {
    const dataType = getKeyValue(addEditRequirementInfo, e.target.name);
    if (e.target.name === 'termDetail') {
      if (e.target.value.length == 0) {
        setIsTermDetailValid(false);
      } else {
        setIsTermDetailValid(true);
      }
      e.target.value = e.target.value.substring(0, 200);
    }
    let value;
    switch (typeof (dataType)) {
      case 'number':
        value = parseFloat(e.target.value != '' ? e.target.value : 0);
        break;
      case 'boolean':
        value = e.target.checked;
        break;
      default:
        value = e.target.value;
        break;
    }

    updateRequirementInfo({
      ...addEditRequirementInfo,
      [e.target.name]: value,
    });
    updateDraftRequirementList({
      ...addEditRequirementInfo,
      [e.target.name]: value,
    });
  };

  const updateReqActive = () => {
    updateRequirementInfo({
      ...addEditRequirementInfo,
      relatedToItem: !addEditRequirementInfo.relatedToItem,
    });
    updateDraftRequirementList({
      ...addEditRequirementInfo,
      relatedToItem: !addEditRequirementInfo.relatedToItem,
    });
  };

  return (
    <>
      <InputForm
        id="description"
        label={t('add_item_page.description')}
        helper_text={t('add_item_page.required_field')}
        max_lenght="420"
        input_params={{
          multiline: true,
          required: true,
          // error: !isTermDetailValid,
          rows: '4',
          placeholder: `${t('add_item_page.enter_description_requirements')}`,
          onChange,
          name: 'termDetail',
          value: addEditRequirementInfo?.termDetail != undefined ? addEditRequirementInfo?.termDetail : '',
        }}
        form_control_params={{
          fullWidth: true,
        }}
      />
      <FormControlLabel control={<Checkbox checked={addEditRequirementInfo?.relatedToItem} onChange={() => { updateReqActive(); }} />} label={t('global.active')} />

    </>
  );
}

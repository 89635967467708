/* eslint-disable react/jsx-props-no-spreading */
import {
  Typography,
  Grid,
  InputAdornment,
  Box,
} from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import IPData from 'ipdata';
import { useTranslation } from 'react-i18next';
import { LmiTheme } from '../../../../../../../components/Common';
// import { SectionTitle } from '../../../../../../../components/Common';
import addEditItemContext from '../../../../../../../context/addEditItem/addEditItemContext';
import { getKeyValue } from '../../../../../../../services/common';
import InputNumerictextField from '../../../../../AddItem/Components/InputNumericTextField/InputNumericTextField';
// import InputTextField from '../../../../../AddItem/Components/InputTextField/InputTextField';
// import { PickUpModel, InputAdornmentInputModel } from './PickUp.models';
// import { AddItemInfoModel } from '../../AddItem.models';

// function InputAdornmentInput(props: InputAdornmentInputModel) {
//   return (
//     <InputAdornment position="start">
//       <Typography component="label" variant="body2" sx={{ fontWeight: '600' }}>
//         {props.kText}
//         : $
//       </Typography>
//     </InputAdornment>
//   );
// }

function getIpData() {
  const [countryCode, setCountryCode] = useState('');

  useEffect(() => {
    const countryCodeSession = sessionStorage.getItem('country_code');
    setCountryCode(countryCodeSession || '');
    if (!countryCodeSession || countryCodeSession == '') {
      const ipdata = new IPData(`${process.env.REACT_APP_IPDATA}`);
      ipdata.lookup().then((data) => {
        setCountryCode(data.country_code);
        sessionStorage.setItem('country_code', data.country_code);
      });
    }
  }, []);

  return countryCode;
}

export default function PickUp(props: any) {
  const addEditContextLog = useContext(addEditItemContext);
  // const [itemInfo, setItemInfo] = useState<AddItemInfoModel>(values.itemInfo);

  const {
    itemInfo,
    updateItemInfo,
    updateDraftItemInfo,
  }: any = addEditContextLog;

  const updateItemForDraft = (obj: {}) => {
    if (itemInfo.idCatLndItem == 0) {
      updateDraftItemInfo({
        ...obj,
      });
    }
  };

  const onChange = (e: any) => {
    const dataType = getKeyValue(itemInfo, e.target.name);

    let value;
    if (e.target.name === 'title') {
      e.target.value = e.target.value.substring(0, 200);
    }
    if (e.target.name === 'lastName') {
      e.target.value = e.target.value.substring(0, 200);
    }
    if (e.target.name === 'email') {
      e.target.value = e.target.value.substring(0, 200);
    }

    switch (typeof (dataType)) {
      case 'number':
        value = parseFloat(e.target.value != '' ? e.target.value : 0);
        break;
      case 'boolean':
        value = e.target.checked;
        break;
      default:
        value = e.target.value;
        break;
    }

    updateItemInfo({
      ...itemInfo,
      [e.target.name]: value,
    });
    updateItemForDraft({
      ...itemInfo,
      [e.target.name]: value,
    });
  };

  const { t } = useTranslation('global');

  const countryCode = getIpData();

  return (
    <>
      <Box pt={1} pb={2}>
        <Grid item xs={12}>
          <Typography py={1} fontSize="body2.fontSize" fontWeight={500} color={LmiTheme.light.systemBackground.primary}>
            {t('add_item_page.delivery_txt')}
          </Typography>
          <Typography fontSize="body2.fontSize" color={LmiTheme.light.systemBackground.tertiary}>
            {t('add_item_page.delivery_txt2')}
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <InputNumerictextField
              kInputId="5miles"
              kKmNumber={countryCode === 'US' ? '5' : '8'}
              kLabel={countryCode === 'US' ? `${t('pick_up.miles')}` : `${t('pick_up.kilometers')}`}
              kDisabled={itemInfo?.isLenderDelivery != undefined ? !itemInfo.isLenderDelivery : true}
              kInputValue={itemInfo?.fiveMilesPrice != undefined ? itemInfo.fiveMilesPrice : 0}
              kInputOnChange={onChange}
              kInputRequired={false}
              kInputName="fiveMilesPrice"
              kPrecision={0}
              kLabelColor={LmiTheme.light.systemBackground.secondary}
              kInputPlaceholder="0"
              kInputTextAlign="center"
              kLabelCentered
              kCountryCode={countryCode}
              kLabelRequiredHide
              kInputMaxLength={7}
              kInputMaxLengthHide
              kInputType="text"
              kInputMultiline={false}
              kInputRows={1}
              kInputMaxRows={1}
              kStartAdornment={
                (
                  <InputAdornment position="start">
                    <Typography color={LmiTheme.light.systemBackground.tertiary} fontSize="body2.fontSize">$</Typography>
                  </InputAdornment>
                )
              }
            />
            <Typography
              color={LmiTheme.light.systemBackground.secondary}
              fontSize="body2.fontSize"
              sx={{ textAlign: 'center' }}
            >
              {props.userCurrency}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <InputNumerictextField
              kInputId="10miles"
              kKmNumber={countryCode === 'US' ? '10' : '24'}
              kLabel={countryCode === 'US' ? `${t('pick_up.miles')}` : `${t('pick_up.kilometers')}`}
              kDisabled={itemInfo?.isLenderDelivery != undefined ? !itemInfo.isLenderDelivery : true}
              kInputValue={itemInfo?.tenMilesPrice != undefined ? itemInfo.tenMilesPrice : 0}
              kInputOnChange={onChange}
              kInputRequired={false}
              kInputName="tenMilesPrice"
              kLabelColor={LmiTheme.light.systemBackground.secondary}
              kInputPlaceholder="0"
              kPrecision={0}
              kInputTextAlign="center"
              kLabelCentered
              kLabelRequiredHide
              kInputMaxLength={7}
              kInputMaxLengthHide
              kInputType="text"
              kInputMultiline={false}
              kInputRows={1}
              kInputMaxRows={1}
              kStartAdornment={
                (
                  <InputAdornment position="start">
                    <Typography color={LmiTheme.light.systemBackground.tertiary} fontSize="body2.fontSize">$</Typography>
                  </InputAdornment>
                )
              }
            />
            <Typography
              color={LmiTheme.light.systemBackground.secondary}
              fontSize="body2.fontSize"
              sx={{ textAlign: 'center' }}
            >
              {props.userCurrency}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <InputNumerictextField
              kInputId="30miles"
              kKmNumber={countryCode === 'US' ? '30' : '48'}
              kLabel={countryCode === 'US' ? `${t('pick_up.miles')}` : `${t('pick_up.kilometers')}`}
              kDisabled={itemInfo?.isLenderDelivery != undefined ? !itemInfo.isLenderDelivery : true}
              kInputValue={itemInfo.tFiveMilesPrice != undefined ? itemInfo.tFiveMilesPrice : 0}
              kInputOnChange={onChange}
              kInputRequired={false}
              kInputName="tFiveMilesPrice"
              kPrecision={0}
              kLabelColor={LmiTheme.light.systemBackground.secondary}
              kInputPlaceholder="0"
              kInputTextAlign="center"
              kLabelCentered
              kLabelRequiredHide
              kInputMaxLength={7}
              kInputMaxLengthHide
              kInputType="text"
              kInputMultiline={false}
              kInputRows={1}
              kInputMaxRows={1}
              kStartAdornment={
                (
                  <InputAdornment position="start">
                    <Typography color={LmiTheme.light.systemBackground.tertiary} fontSize="body2.fontSize">$</Typography>
                  </InputAdornment>
                )
              }
            />
            <Typography
              color={LmiTheme.light.systemBackground.secondary}
              fontSize="body2.fontSize"
              sx={{ textAlign: 'center' }}
            >
              {props.userCurrency}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <InputNumerictextField
              kInputId="50miles"
              kKmNumber={countryCode === 'US' ? '50' : '80'}
              kLabel={countryCode === 'US' ? `${t('pick_up.miles')}` : `${t('pick_up.kilometers')}`}
              kDisabled={itemInfo?.isLenderDelivery != undefined ? !itemInfo.isLenderDelivery : true}
              kInputValue={itemInfo?.fiftyMilesPrice != undefined ? itemInfo.fiftyMilesPrice : 0}
              kInputOnChange={onChange}
              kInputRequired={false}
              kInputName="fiftyMilesPrice"
              kLabelColor={LmiTheme.light.systemBackground.secondary}
              kInputPlaceholder="0"
              kPrecision={0}
              kInputTextAlign="center"
              kLabelCentered
              kLabelRequiredHide
              kInputMaxLength={7}
              kInputMaxLengthHide
              kInputType="text"
              kInputMultiline={false}
              kInputRows={1}
              kInputMaxRows={1}
              kStartAdornment={
                (
                  <InputAdornment position="start">
                    <Typography color={LmiTheme.light.systemBackground.tertiary} fontSize="body2.fontSize">$</Typography>
                  </InputAdornment>
                )
              }
            />
            <Typography
              color={LmiTheme.light.systemBackground.secondary}
              fontSize="body2.fontSize"
              sx={{ textAlign: 'center' }}
            >
              {props.userCurrency}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {/* <Grid container spacing={2}>
        {props.kIsReadOnly ? '' : (
          <Grid item xs={12}>
            <Typography py={1} fontSize="body2.fontSize" fontWeight={500} color={LmiTheme.light.systemBackground.primary}>
              You can offer the delivery service and get a fee for it.
            </Typography>
            <Typography fontSize="body2.fontSize" color={LmiTheme.light.systemBackground.tertiary}>
              Amount you’ll charge per radius.
            </Typography>
          </Grid>
        )}
        <Grid item xs={6}>
          <InputForm
            id="5miles"
            disabled={itemInfo?.delivery != undefined ? !itemInfo.delivery : true}
            input_params={{
              placeholder: '0',
              startAdornment: <InputAdornmentInput kText="5 Miles" />,
              value: itemInfo?.fiveMilesPrice != undefined ? itemInfo.fiveMilesPrice : '',
              onChange,
              name: 'fiveMilesPrice',
              readOnly: !!props.kIsReadOnly,
            }}
            form_control_params={{
              fullWidth: true,
            }}
            {...props.kIsReadOnly ? { kBorder: 'none', kPadding: '0' } : ''}
          />
        </Grid>
        <Grid item xs={6}>
          <InputForm
            id="10miles"
            disabled={itemInfo?.delivery != undefined ? !itemInfo.delivery : true}
            input_params={{
              placeholder: '0',
              startAdornment: <InputAdornmentInput kText="10 Miles" />,
              value: itemInfo?.tenMilesPrice != undefined ? itemInfo.tenMilesPrice : '',
              onChange,
              name: 'tenMilesPrice',
              readOnly: !!props.kIsReadOnly,
            }}
            form_control_params={{
              fullWidth: true,
            }}
            {...props.kIsReadOnly ? { kBorder: 'none', kPadding: '0' } : ''}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputForm
            id="30miles"
            disabled={itemInfo?.delivery != undefined ? !itemInfo.delivery : true}
            input_params={{
              placeholder: '0',
              startAdornment: <InputAdornmentInput kText="30 Miles" />,
              value: itemInfo?.tFiveMilesPrice != undefined ? itemInfo.tFiveMilesPrice : '',
              onChange,
              name: 'tFiveMilesPrice',
              readOnly: !!props.kIsReadOnly,
            }}
            form_control_params={{
              fullWidth: true,
            }}
            {...props.kIsReadOnly ? { kBorder: 'none', kPadding: '0' } : ''}
          />
        </Grid>
        <Grid item xs={6}>
          <InputForm
            id="50miles"
            disabled={itemInfo?.delivery != undefined ? !itemInfo.delivery : true}
            input_params={{
              placeholder: '0',
              startAdornment: <InputAdornmentInput kText="50 Miles" />,
              value: itemInfo?.fiftyMilesPrice != undefined ? itemInfo.fiftyMilesPrice : '',
              onChange,
              name: 'fiftyMilesPrice',
              readOnly: !!props.kIsReadOnly,
            }}
            form_control_params={{
              fullWidth: true,
            }}
            {...props.kIsReadOnly ? { kBorder: 'none', kPadding: '0' } : ''}
          />
        </Grid>
      </Grid> */}
    </>
  );
}

PickUp.defaultProps = {
  kIsReadOnly: false,
};

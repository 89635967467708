import {
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  Grid, Divider, Card, CardContent, Stack, IconButton, Box, Paper, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ArrowBackIosRounded } from '@mui/icons-material';
import AddEditCirclesContext from '../../../../context/circles/addEditCirclesContext';
import { ContentLayout, DialogCommon, SectionTitle } from '../../../../components/Common';
import HeaderTitle from '../../../../components/Common/HeaderTitle/HeaderTitle';
import Heading from './Components/Heading/Heading';
import LoadingLayout from '../../../../components/Common/LoadingLayout/LoadingLayout';
import AppBarSecondary from '../../../../components/Layout/AppBarSecondary/AppBarSecondary';
import CirclesBasicInformation from './Components/CirclesBasicInformation/CirclesBasicInformation';
import CircleTags from './Components/CircleTags/CircleTags';
import ConditionsToJoin from './Components/ConditionsToJoin/ConditionsToJoin';
import SetSpecialFee from './Components/SetSpecialFee/SetSpecialFee';
import PermisionToUploadItems from './Components/PermissionToUploadItems/PermissionToUploadItems';
import CircleLocation from './Components/CircleLocation/CircleLocation';
import CircleAvailable from './Components/CircleAvailable/CircleAvailable';
import { DialogLists } from '../../../FrontOffice/Checkout/Components';
import DialogCircleCreated from './Components/CircleCreated/CircleCreated';
import { CircleDetailInfoModel } from './AddCircle.models';
import SubmitButton from './Components/SubmitButton/SubmitButton';
import ManageItemsSection from './Components/ManageItemsSection/ManageItemsSection';
import ManageMembersSection from './Components/ManageMembersSection/ManageMembersSection';
import MyCirclesListingContext from '../../../../context/myCirclesListing/myCirclesListingContext';
import getPosition from '../../../../utils/getElementPosition';

export default function AddCircle() {
  const [isBlocked, setIsBlocked] = useState(false);
  const [buttonBlocked, setButtonBlocked] = useState(false);
  const [open, setOpen] = useState(false);
  const [getPositionState, setGetPositionState] = useState(false);
  const [updateItemStatus, setUpdateItemStatus] = useState(false);

  const mountedRef = useRef(false);

  const history = useHistory();
  const state = history.location.state as {
    circleElement: CircleDetailInfoModel,
    kCircleIsNew: boolean
  };

  if (!state) {
    mountedRef.current = false;
    window.location.replace('/circles');
    return null;
  }
  const circlesContextLog = useContext(AddEditCirclesContext);
  const circleAddInfo = (state && state.circleElement) && state.circleElement;
  const {
    getCircleEditInfo,
    checkValidate,
    checkValidations,
    circleUpdated,
    reasonToDisableItem,
    circleManageUpdateItem,
    selectedItemId,
    itemActive,
    circleUpdateError,
    loading,
    errors,
  }: any = circlesContextLog;

  useEffect(() => {
    async function fetchCircleEditInfo() {
      await getCircleEditInfo(Number(circleAddInfo?.idCatLndCircle) ? Number(circleAddInfo?.idCatLndCircle) : 0);
    }

    fetchCircleEditInfo();
  }, []);

  const saveNewItemStatus = () => {
    circleManageUpdateItem(selectedItemId, itemActive, true, reasonToDisableItem, circleAddInfo?.idCatLndCircle);
  };

  const {
    circle,
    circles,
    details,
  }: any = useContext(MyCirclesListingContext);

  const goToCircles = () => {
    window.scrollTo(0, 0);
    if (circles?.length < 0) history.push('/circles');
    else history.goBack();
  };
  useEffect(() => {
    setIsBlocked(false);
  }, [checkValidate]);

  useEffect(() => {
    if (errors) {
      const { position, type } = getPosition(errors);
      if (Object.keys(errors).length) {
        const { x, y } = position;
        if (type !== '') {
          window.scrollTo(x, y);
        }
      }
    }
  }, [getPositionState]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsBlocked(false);
  }, [circleUpdateError]);

  useEffect(() => {
    if (circleUpdated) {
      setIsBlocked(false);
      closeCongratulationDialog();
      document.body.classList.remove('not-scroll');
      if (state.kCircleIsNew) {
        setOpenCongratulationsDialog(true);
      } else {
        details(circle.idCatLndCircle);
        if (updateItemStatus) saveNewItemStatus();
      }
      discardLocalStorage();
    }
  }, [circleUpdated]);

  const closeCongratulationDialog = () => {
    if (!state.kCircleIsNew) {
      setTimeout(clickCloseCongratulationsDialog, 1500);
    }
  };

  const [openCongratulationsDialog, setOpenCongratulationsDialog] = useState(false);
  const clickCloseCongratulationsDialog = () => {
    setOpenCongratulationsDialog(false);
    history.goBack();
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation('global');

  const titlePage = state.kCircleIsNew ? t('circles.add_circle_page.new_circle') : t('circles.add_circle_page.edit_circle');

  const submitCircle = () => {
    setIsBlocked(true);
    checkValidations();
    setGetPositionState(!getPositionState);
    if (!state.kCircleIsNew) setButtonBlocked(true);
  };

  const discardLocalStorage = () => {
    localStorage.removeItem('draftCircleIdImage');
    localStorage.removeItem('draftCircleInfo');
    localStorage.removeItem('draftCircleTags');
    localStorage.removeItem('draftCircleConditions');
    localStorage.removeItem('draftBlockedIndDates');
  };

  const closeDialogModal = () => {
    discardLocalStorage();
    setOpen(false);
    goToCircles();
  };

  const closeDialogWithoutSaveDraft = () => {
    setOpen(false);
    goToCircles();
  };

  const handleDraftDialog = () => {
    if (state.kCircleIsNew) {
      setOpen(true);
    } else {
      goToCircles();
    }
  };

  return (
    <>
      <AppBarSecondary
        kBack
        kTitle={
          (
            <SectionTitle
              kMarginTop={0}
              kMarginBottom={0}
              kTitle={titlePage}
            />
          )
        }
      />
      <ContentLayout kPaddingXs={0}>
        <Grid container spacing={{ md: 3 }} sx={{ justifyContent: 'center' }}>

          {/* Main Content */}
          <Grid item mb={3} xs={12} sm={12} md={8}>
            <Card elevation={0}>
              <Stack
                px={1}
                py={1}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={3}
                sx={{
                  display: { xs: 'none', md: 'flex' },
                }}
              >
                <IconButton color="primary" onClick={() => handleDraftDialog()} sx={{ py: 1.4 }}>
                  <ArrowBackIosRounded />
                </IconButton>
                <HeaderTitle
                  kTitle={titlePage}
                />
              </Stack>
              <Divider sx={{
                display: { xs: 'none', md: 'flex' },
              }}
              />
              <CardContent sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
              }}
              >
                {(loading) ? (
                  <Paper elevation={0} sx={{ height: { xs: 'calc(100vh - 40px)', lg: 'auto' } }}>
                    <LoadingLayout />
                  </Paper>
                )
                  : (
                    <>
                      <Box
                        sx={{
                          position: 'fixed',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          zIndex: 9999,
                          backgroundColor: 'rgba(255, 255, 255, 0.7)',
                          display: isBlocked ? 'block' : 'none',
                        }}
                      >
                        <LoadingLayout />
                      </Box>

                      <Box mt={0}>
                        <Heading kTitle={t('circles.add_circle_page.basic_information')} />
                      </Box>
                      <Paper
                        variant="outlined"
                        sx={{
                          borderRadius: '10px',
                          padding: {
                            xs: '16px', sm: '16px', md: '16px', lg: 'none',
                          },
                        }}
                      >
                        <CirclesBasicInformation />
                      </Paper>

                      <Box mt={3}>
                        <Heading kTitle={t('circles.add_circle_page.circle_location')} />
                      </Box>
                      <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
                        <CircleLocation />
                      </Paper>

                      <Box mt={3}>
                        <Heading kTitle={t('circles.add_circle_page.tags_circle')} />
                      </Box>
                      <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
                        <CircleTags />
                      </Paper>

                      <Box mt={3}>
                        <Heading kTitle={t('circles.add_circle_page.conditions_to_join')} />
                      </Box>
                      <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
                        <ConditionsToJoin />
                      </Paper>

                      <ManageItemsSection kSaveNewItemStatus={() => setUpdateItemStatus(true)} kCircleIsNew={state.kCircleIsNew} kIdCatLndCircle={state.circleElement?.idCatLndCircle} />

                      <ManageMembersSection kCircleIsNew={state.kCircleIsNew} />

                      {(circleAddInfo?.isOwner || state.kCircleIsNew) && (
                        <>
                          <Box mt={3}>
                            <Heading kTitle={t('circle_detail.permission_upload_title')} />
                          </Box>
                          <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
                            <PermisionToUploadItems />
                          </Paper>
                          <Box mt={3}>
                            <Heading kTitle={t('circles.add_circle_page.set_special_fee')} />
                          </Box>
                          <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
                            <SetSpecialFee />
                          </Paper>
                        </>
                      )}

                      {
                        !state.kCircleIsNew && (
                          <>
                            <Box mt={3}>
                              <Heading kTitle={t('circles.add_circle_page.circle_available')} />
                            </Box>
                            <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
                              <CircleAvailable />
                            </Paper>
                          </>
                        )
                      }

                      <SubmitButton kSubmitCircle={() => submitCircle()} disabled={buttonBlocked} />

                      <DialogLists
                        kTitle={t('checkout.congratulations')}
                        kMaxWidth="sm"
                        kFullScreen={false}
                        kDisableContentPadding
                        kDisableTitle
                        kDisableActions
                        kOpenDialogLists={openCongratulationsDialog}
                        kOnClose={clickCloseCongratulationsDialog}
                        kOnCancel={clickCloseCongratulationsDialog}
                        kOnSave={clickCloseCongratulationsDialog}
                        kContent={<DialogCircleCreated kOnContinue={clickCloseCongratulationsDialog} />}
                      />

                    </>
                  )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </ContentLayout>
      <DialogCommon
        kTitle={t('listing.save_circle')}
        kMaxWidth="sm"
        kMaxHeight={isMobile ? '280px' : '260px'}
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kBorderRadius={isMobile ? 0 : '10px'}
        kAlignBtn="center"
        kKeepBtnSize
        kTopDivider
        kSlideTransition={isMobile}
        kOpenDialogLists={open}
        kOnClose={closeDialogModal}
        kOnSecondary={closeDialogModal}
        kSecondaryBtnText={t('listing.discard_item_draft')}
        kOnPrimary={() => closeDialogWithoutSaveDraft()}
        kContent={
          (
            <Typography>{t('circles.main.save_circle_draft')}</Typography>
          )
        }
      />
    </>
  );
}

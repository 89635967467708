/* eslint-disable max-lines */
/* eslint-disable dot-notation */
import {
  useContext, useEffect, useState,
} from 'react';
import {
  ArrowBackIos, CheckCircle, LocationOnRounded, Edit, Add,
} from '@mui/icons-material';
import {
  useTheme,
  useMediaQuery,
  Grid,
  Card,
  Divider,
  CardContent,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// import { toast } from 'react-toastify';
import { toast } from 'react-toastify';
import {
  LmiTheme,
  TopNav,
  IconLinkButton,
  SectionTitle,
  Summary,
  BottomBarSummary,
  ItemToLend,
  PanelSection,
  AccesoriesPanelList,
  DialogAccessories,
  DialogLists,
  PaymentMethodSelect,
  PaymentMethodAddDialog,
  DialogPromoCodes,
  DialogPickupLocation,
  DialogDeliveryOptions,
  DialogAvailability,
  DialogCongratulations,
  // MapComponent,
} from './Components';
import ContentLayout from '../../../components/Common/ContentLayout/ContentLayout';
import bookItemContext from '../../../context/bookItem/bookContext';
import paymentMethodsContext from '../../../context/paymentMethods/paymentMethodsContext';

import {
  NONE,
  PICKUPBYLENDER,
  PRECING_DAILY, PRECING_HOURLY, PRECING_MONTHLY, PRECING_WEEKLY, RENT, ROADIE,
} from '../../../types';
import { DialogCommon, LoadingLayout } from '../../../components/Common';
// import { DeliveryOptions } from '../BookItemPage/Components';

function Checkout() {
  const history = useHistory();

  const [isBlocked, setIsBlocked] = useState(false);
  const [roadieVisibleFromPhoneCode, setRoadieVisibleFromPhoneCode] = useState(true);
  const { state }: any = history.location;
  if (!state) {
    history.push('/');
  }

  const {
    loading,
    accesories,
    bookDetails,
    bookType,
    precingType,
    getCouponCodes,
    modifyAccesories,
    changeDeliveryTypeTemp,
    deliveryTypeTemp,
    updateRoadieInfo,
    roadieTemp,
    updateLenderPikupInfo,
    pickupLenderTemp,
    changeDeliveryType,
    deliveryType,
    setPromoSelected,
    couponSelected,
    bookNow,
    rentalCreated,
    rentalError,
    clearErrorMessage,
    errorBookMessage,
    setBookItemDetails,
    setDateRange,
  }: any = useContext(bookItemContext);

  const {
    newPaymentMethodInfo,
    totalValidCard,
    getUserPaymentInfo,
    paymentMethodsList,
    // updateDefaultCard,
    // deleteCard,
    createPaymentMethod,
    arePaymentFieldsValidated,
    changedPaymentFields,
    checkNewPaymentValidations,
    // defaultPaymentMethod,
    bookingCard,
    updateBookingCard,
  }: any = useContext(paymentMethodsContext);

  const { roadie, isLenderDelivery } = bookDetails;

  useEffect(() => {
    const stateCheckout = localStorage.getItem('checkout');
    if (stateCheckout) {
      const parsedState = JSON.parse(stateCheckout);
      setBookItemDetails(parsedState);
      const dateRange = [
        new Date(parsedState?.availability?.dateRange[0]),
        new Date(parsedState?.availability?.dateRange[1]),
      ];
      setDateRange(dateRange);
    }
    getUserPaymentInfo();

    return () => {
      localStorage.removeItem('checkout');
    };
  }, []);

  useEffect(() => {
    // Phone Code validation
    const renterPhoneNumb = bookDetails?.renterPhoneNumber;
    if (renterPhoneNumb.length > 0) {
      const phoneCode = renterPhoneNumb.slice(0, -10);
      if (phoneCode != '+1') {
        setRoadieVisibleFromPhoneCode(false);
      }
    }
  }, [bookDetails?.renterPhoneNumber]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.down('lg'));

  const [defaulPaymenteTmp, setDefaulPaymenteTmp] = useState(0);
  const [deletePaymenteTmp, setDeletePaymenteTmp] = useState<number[]>([]);
  const [openAccesoriesDialog, setOpenAccesoriesDialog] = useState(false);
  const clickOpenAccesoriesDialog = () => setOpenAccesoriesDialog(true);

  const [openPaymentDetailsDialog, setOpenPaymentDetailsDialog] = useState(false);
  const clickOpenPaymentDetailsDialog = () => setOpenPaymentDetailsDialog(true);

  const [openAddPaymentDetailsDialog, setOpenAddPaymentDetailsDialog] = useState(false);
  const clickOpenAddPaymentDetailsDialog = () => setOpenAddPaymentDetailsDialog(true);
  const clickCloseAddPaymentDetailsDialog = () => setOpenAddPaymentDetailsDialog(false);

  const [openPromoCodesDialog, setOpenPromoCodesDialog] = useState(false);
  const clickOpenPromoCodesDialog = () => {
    getCouponCodes();
    setOpenPromoCodesDialog(true);
  };
  const clickClosePromoCodesDialog = () => setOpenPromoCodesDialog(false);

  const [openPickupLocationDialog, setOpenPickupLocationDialog] = useState(false);
  const clickOpenPickupLocationDialog = () => setOpenPickupLocationDialog(true);
  const clickClosePickupLocationDialog = () => setOpenPickupLocationDialog(false);

  const [openDeliveryOptionsDialog, setOpenDeliveryOptionsDialog] = useState(false);
  const clickOpenDeliveryOptionsDialog = () => setOpenDeliveryOptionsDialog(true);
  const clickCloseDeliveryOptionsDialog = () => setOpenDeliveryOptionsDialog(false);

  const [openAvailabilityDialog, setOpenAvailabilityDialog] = useState(false);
  const clickOpenAvailabilityDialog = () => setOpenAvailabilityDialog(true);
  const clickCloseAvailabilityDialog = () => setOpenAvailabilityDialog(false);

  const [openCongratulationsDialog, setOpenCongratulationsDialog] = useState(false);
  const clickOpenCongratulationsDialog = () => {
    setIsBlocked(true);
    bookNow(bookingCard ?? 0);
    setOpenCongratulationsDialog(false);
  };

  const updateDisplayBookingCard = async (idPayment: any) => {
    setDefaulPaymenteTmp(idPayment);
    updateBookingCard(idPayment);
  };

  const goToActivity = () => {
    setOpenCongratulationsDialog(false);
    history.push('/activity');
  };

  const clickCloseAccesoriesDialog = () => setOpenAccesoriesDialog(false);
  const [flagAdd, setFlagAdd] = useState(false);

  const [accList, edit] = useState([]);
  const clickSaveAccesoriesDialog = () => {
    modifyAccesories(accList);

    setOpenAccesoriesDialog(false);
    return false;
  };

  const clickClosePaymentDetailsDialog = async () => {
    setOpenPaymentDetailsDialog(false);
    if (defaulPaymenteTmp > 0) {
      await updateBookingCard(defaulPaymenteTmp);
    }
    setTimeout(() => {
      setDeletePaymenteTmp([]);
    }, 300);
  };

  const clickSavePayment = async () => {
    setFlagAdd(!flagAdd);
    checkNewPaymentValidations(true);
  };

  useEffect(() => {
    if (arePaymentFieldsValidated) {
      if (newPaymentMethodInfo.expiryMonth.length >= 4) {
        const splitExpire = newPaymentMethodInfo.expiryMonth.split('/');
        const month = splitExpire[0];
        const year = splitExpire[1];
        const arrToSend = {
          ...newPaymentMethodInfo,
          expiryMonth: month,
          expiryYear: year,
        };

        (async function processPaymentMethod() {
          if (await createPaymentMethod(arrToSend)) {
            clickCloseAddPaymentDetailsDialog();
          }
        }());
      }
    }
  }, [changedPaymentFields]);

  useEffect(() => {
    if (rentalCreated) {
      setIsBlocked(false);
      setOpenCongratulationsDialog(true);
    }
  }, [rentalCreated]);

  useEffect(() => {
    setIsBlocked(false);
    if (errorBookMessage != '') {
      toast.error(errorBookMessage);
      clearErrorMessage();
    }
  }, [rentalError]);

  let rentalAmmount = 0;

  const {
    dailyRentalPrice,
    hourlyRentalPrice,
    weeklyRentalPrice,
    monthlyRentalPrice,
    salePrice,
  } = bookDetails;

  if (bookType == RENT) {
    switch (precingType) {
      case PRECING_HOURLY:
        rentalAmmount = hourlyRentalPrice;
        break;
      case PRECING_DAILY:
        rentalAmmount = dailyRentalPrice;
        break;
      case PRECING_WEEKLY:
        rentalAmmount = weeklyRentalPrice;
        break;
      case PRECING_MONTHLY:
        rentalAmmount = monthlyRentalPrice;
        break;
      default:
        break;
    }
  } else {
    rentalAmmount = salePrice;
  }

  const { t } = useTranslation('global');

  const tempEditAccesory = (edition: any) => {
    for (let index = 0; index < accList.length; index += 1) {
      const accesory: any[] = accList[index];

      if (accesory['idRelItemAccesorie'] == edition.idRelItemAccesorie) {
        accesory['tempAdded'] = edition.checked;
        break;
      }
    }
    edit([...accList]);
  };

  useEffect(() => {
    if (accesories && accesories.length > 0) {
      const copyModal: any = [...accesories];

      for (let index = 0; index < copyModal.length; index += 1) {
        const accesory: any[] = copyModal[index];

        if (accesory['isAdded']) {
          accesory['tempAdded'] = true;
        } else {
          accesory['tempAdded'] = false;
        }
      }

      // edit((previousState: any) => ([...previousState]));
      edit(copyModal);
    }
  }, [accesories]);
  const clickSavePromoCodesDialog = () => {
    setPromoSelected();
    setOpenPromoCodesDialog(false);
  };
  const clickSaveDeliveryOptionsDialog = async () => {
    switch (deliveryTypeTemp) {
      case ROADIE:
        updateRoadieInfo(roadieTemp);
        break;
      case
        PICKUPBYLENDER:
        updateLenderPikupInfo(pickupLenderTemp);
        break;
      case NONE:

        break;
      default:
        break;
    }
    changeDeliveryType(deliveryTypeTemp);
    setOpenDeliveryOptionsDialog(false);
  };

  const addressNoPickup = bookDetails.exactAddress ? bookDetails.address : bookDetails.itemLocationName;

  return (
    <>
      <ContentLayout>
        <Grid container spacing={!isDesktop ? 3 : 1}>
          <Grid item mb={isTablet ? 3 : 17} xs={12} sm={12} md={8}>
            <TopNav
              kContentLeft={
                (
                  <IconLinkButton
                    kStartIcon={<ArrowBackIos />}
                    kOnClick={() => history.goBack()}
                    kText={t('checkout.back')}
                    kFontSize={isMobile ? 'body2.fontSize' : 'body1.fontSize'}
                    kFontWeight={isMobile ? '400' : 'bold'}
                  />
                )
              }
            />
            <Card elevation={0}>
              <SectionTitle
                kMarginLeft={3}
                kMarginRight={3}
                kMarginTop={2}
                kMarginBottom={2}
                kTitle={t('checkout.checkout')}
              />
              <Divider />
              <CardContent sx={{ px: 1, pb: 0 }}>

                <Box
                  sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 9999,
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    display: isBlocked ? 'block' : 'none',
                  }}
                >
                  <LoadingLayout />
                </Box>
                <ItemToLend
                  kItemName={bookDetails.name}
                  kItemPhoto={bookDetails.itemPicture}
                  kItemPrice={rentalAmmount}
                  kItemPriceType={bookType === RENT ? precingType : bookType}
                  kItemDescription={bookDetails.dscription}
                  kLenderPhoto={bookDetails.lenderProfilePicture}
                  kLenderName={bookDetails.lenderName}
                  kCurrency={bookDetails.currency}
                />
                <Divider sx={{ mx: 2, mt: 1 }} />

                {accList && accList.length > 0 ? (
                  <>
                    <AccesoriesPanelList
                      kTitle={t('checkout.accessories')}
                      kOnClick={clickOpenAccesoriesDialog}
                      kDivider={false}
                      kCurrency={bookDetails.currency}
                    />
                    <DialogLists
                      kTitle={t('checkout.accessories')}
                      kMaxWidth="md"
                      kOpenDialogLists={openAccesoriesDialog}
                      kOnClose={clickCloseAccesoriesDialog}
                      kOnCancel={clickCloseAccesoriesDialog}
                      kOnSave={clickSaveAccesoriesDialog}
                      kContent={(
                        <DialogAccessories
                          accList={accList}
                          bookType={bookType}
                          precingType={precingType}
                          tempEditAccesory={tempEditAccesory}
                          currency={bookDetails.currency}
                        />
                      )}
                    />
                  </>
                ) : null}

              </CardContent>
            </Card>
            <PanelSection
              kTitle={t('checkout.payment_details')}
              kOnClick={clickOpenPaymentDetailsDialog}
              kListItemIcon={bookingCard ? <CheckCircle /> : null}
              kListItemIconColor={LmiTheme.light.secondaryColor.green}
              kSecondaryActionText={bookingCard ? t('global.change') : t('checkout.setup_payment')}
              KIcon={bookingCard ? <Edit /> : <Add />}
              kCardImageSrc={bookingCard ? bookingCard?.brand : ''}
              kPrimaryText={bookingCard ? bookingCard?.last4 : ''}
              kSecondaryText={bookingCard && bookingCard?.valid ? t('checkout.valid') : ''}
            />
            <DialogCommon
              kTitle={t('checkout.select_method')}
              kMaxWidth="sm"
              kAlignBtn="center"
              kBorderRadius={isMobile ? 0 : '10px'}
              kDisableBottomDivider
              kKeepBtnSize
              kTopDivider
              kSlideTransition={isMobile}
              kOpenDialogLists={openPaymentDetailsDialog}
              kOnClose={clickClosePaymentDetailsDialog}
              // kOnSecondary={clickClosePaymentDetailsDialog}
              // kOnPrimary={clickSavePaymentDetailsDialog}
              kContent={
                (
                  <PaymentMethodSelect
                    defaulPaymenteTmp={bookingCard ?? defaulPaymenteTmp}
                    deletePaymenteTmp={deletePaymenteTmp}
                    kPaymentMethods={paymentMethodsList}
                    kUpdateDefault={updateDisplayBookingCard}
                    kIsCheckout
                    kDeletePayment={(r: number) => {
                      deletePaymenteTmp.push(r);
                      setDeletePaymenteTmp(deletePaymenteTmp);
                    }}
                    kTotalValidCards={totalValidCard}
                    kOnClick={() => {
                      clickClosePaymentDetailsDialog();
                      clickOpenAddPaymentDetailsDialog();
                    }}
                  />
                )
              }
            />
            <DialogCommon
              kTitle={t('payment_method.add_payment_method')}
              kMaxWidth="sm"
              kAlignBtn="center"
              kBorderRadius={isMobile ? 0 : '10px'}
              kDisableBottomDivider
              kKeepBtnSize
              kTopDivider
              kSlideTransition={isMobile}
              kOpenDialogLists={openAddPaymentDetailsDialog}
              kOnClose={clickCloseAddPaymentDetailsDialog}
              kOnSecondary={clickCloseAddPaymentDetailsDialog}
              kOnPrimary={clickSavePayment}
              kContent={
                (
                  <PaymentMethodAddDialog />
                )
              }
            />
            <PanelSection
              kTitle={t('checkout.promo_code')}
              kOnClick={clickOpenPromoCodesDialog}
              KIcon={couponSelected ? <Edit /> : <Add />}
              kPrimaryText={couponSelected ? couponSelected.codeCoupon : ''}
              kSecondaryActionText={couponSelected ? t('global.change') : t('global.add')}
            />
            <DialogCommon
              kTitle={t('checkout.promo_code')}
              kMaxWidth="sm"
              kAlignBtn="center"
              kBorderRadius={isMobile ? 0 : '10px'}
              kDisableBottomDivider
              kKeepBtnSize
              kTopDivider
              kSlideTransition={isMobile}
              kOpenDialogLists={openPromoCodesDialog}
              kOnClose={clickClosePromoCodesDialog}
              kOnSecondary={clickClosePromoCodesDialog}
              kOnPrimary={clickSavePromoCodesDialog}
              kContent={
                (
                  <DialogPromoCodes />
                )
              }
            />
            {(deliveryType == PICKUPBYLENDER || deliveryType == ROADIE) ? null : (
              <PanelSection
                kTitle={t('checkout.pickup_location')}
                kOnClick={clickOpenPickupLocationDialog}
                kSecondaryAction={false}
                kListItemIcon={<LocationOnRounded />}
                kListItemIconColor={LmiTheme.light.accent}
                kPrimaryText={deliveryType == PICKUPBYLENDER ? pickupLenderTemp.address : addressNoPickup}
                kPrimaryTextColor={LmiTheme.light.accent}
              />
            )}
            {bookDetails
              ? (
                <DialogCommon
                  kTitle={t('checkout.pickup_location')}
                  kMaxWidth="md"
                  kOpenDialogLists={openPickupLocationDialog}
                  kBorderRadius={isMobile ? 0 : '10px'}
                  kSlideTransition={isMobile}
                  kOnClose={clickClosePickupLocationDialog}
                  kDisableActions
                  kContent={(
                    <DialogPickupLocation
                      kDraggable
                      lat={!loading ? bookDetails?.lat : 34.0536909}
                      long={!loading ? bookDetails?.lon : -118.242766}
                      exactAddress={bookDetails?.exactAddress}
                    />
                  )}
                />

              ) : null}
            {bookType != RENT || (!roadie && !isLenderDelivery) || precingType == 'HOURLY' ? null
              : (
                <>
                  <PanelSection
                    kTitle={t('checkout.delivery_option')}
                    kOnClick={clickOpenDeliveryOptionsDialog}
                    // eslint-disable-next-line no-nested-ternary
                    kPrimaryText={(deliveryType === 'ROADIE') ? t('delivery.options.roadie') : (deliveryType === 'PICKUPBYLENDER') ? t('checkout.takeout') : t('delivery.options.none_description')}
                  />
                  <DialogLists
                    kTitle={t('checkout.delivery_options')}
                    kMaxWidth="md"
                    kDisableContentPadding
                    kOpenDialogLists={openDeliveryOptionsDialog}
                    kOnClose={clickCloseDeliveryOptionsDialog}
                    kOnCancel={clickCloseDeliveryOptionsDialog}
                    kOnSave={clickSaveDeliveryOptionsDialog}
                    kContent={(
                      <DialogDeliveryOptions
                        kTitle={t('listing_detail.delivery')}
                        roadie={roadie}
                        lenderDelivery={isLenderDelivery}
                        changeDeliveryType={changeDeliveryTypeTemp}
                        deliveryType={deliveryTypeTemp}
                        visiblePhoneCode={roadieVisibleFromPhoneCode}
                      />
                    )}
                  />
                </>
              )}
          </Grid>
          {
            isTablet
              ? (
                <Grid item xs={12} sm={12} md={4}>
                  <TopNav />
                  <Card elevation={0} sx={{ position: 'sticky', top: '0' }}>
                    <SectionTitle
                      kMarginLeft={2}
                      kMarginRight={2}
                      kMarginTop={2}
                      kMarginBottom={2}
                      kTitle={t('checkout.summary')}
                    />
                    <Divider />
                    <CardContent>
                      <Summary
                        kOnClickOpenDialogAvailability={() => {}}
                        kOnClickOpenDialogCongratulations={clickOpenCongratulationsDialog}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              )
              : undefined
          }
        </Grid>
      </ContentLayout>
      {!isTablet ? (
        <BottomBarSummary
          kOnClickOpenDialogAvailability={clickOpenAvailabilityDialog}
          kOnClickOpenDialogCongratulations={clickOpenCongratulationsDialog}
        />
      ) : undefined}
      <DialogLists
        kTitle={t('checkout.availability')}
        kMaxWidth="md"
        kDisableContentPadding
        kOpenDialogLists={openAvailabilityDialog}
        kOnClose={clickCloseAvailabilityDialog}
        kOnCancel={clickCloseAvailabilityDialog}
        kOnSave={clickCloseAvailabilityDialog}
        kContent={<DialogAvailability />}
      />
      <DialogLists
        kTitle={t('checkout.congratulations')}
        kMaxWidth="sm"
        kFullScreen={false}
        kDisableContentPadding
        kDisableTitle
        kDisableActions
        kOpenDialogLists={openCongratulationsDialog}
        kOnClose={goToActivity}
        kOnCancel={goToActivity}
        kOnSave={goToActivity}
        kContent={<DialogCongratulations kIsRent={bookType === RENT} kOnContinue={goToActivity} />}
      />
    </>
  );
}

export default Checkout;

// import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
// import { Route, Switch } from 'react-router-dom';
import BackOfficeAppBar from '../../Layout/BackOfficeAppBar/BackOfficeAppBar';

import { AdminModels } from './Admin.models';

export default function Admin(props: AdminModels) {
  return (
    <>
      <Box component="main" sx={{ display: 'flex' }}>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <BackOfficeAppBar />
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1, p: 0, width: '100%', background: '#f7f7f7',
          }}
        >
          <Toolbar />
          {props.content}
        </Box>
      </Box>
    </>
  );
}

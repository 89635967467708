import {
  Menu, MenuItem, ListItemIcon, Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import cookies from 'js-cookie';
import i18next from 'i18next';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';

import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import {
  Explore, Wallet, Marker, Profile, Share,
} from '../../..';
import authContext from '../../../../../context/authentication/authContext';
import { ReactComponent as GlobeIcon } from '../../../../../assets/icons/globe.svg';
import { ListUserMenuModels } from './ListUserMenu.models';
import ListUserMenuStyle from './ListUserMenu.style';

export default function ListUserMenu(props: ListUserMenuModels) {
  const history = useHistory();
  const { signOff }: any = useContext(authContext);
  const logOff = (e: any) => {
    e.preventDefault();
    signOff();
    localStorage.removeItem('profilePicture');
    localStorage.removeItem('item');
  };

  function changeLanguageMenu() {
    if (cookies.get('i18next') === 'en') {
      i18next.changeLanguage('es');
    } else { i18next.changeLanguage('en'); }
  }

  const { t } = useTranslation('global');

  return (
    <>
      <Menu
        anchorEl={props.kEven}
        id="user-menu"
        open={props.kOpen}
        onClose={props.kClose}
        onClick={props.kClose}
        PaperProps={{
          elevation: 0,
        }}
        sx={ListUserMenuStyle}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => props.kChangeLocation()}>
          <ListItemIcon>
            <Marker />
          </ListItemIcon>
          {t('user_session.change_location')}
        </MenuItem>
        <MenuItem onClick={() => changeLanguageMenu()}>
          <ListItemIcon sx={{ svg: { transform: 'rotate(90deg)' } }}>
            <GlobeIcon />
          </ListItemIcon>
          {
            cookies.get('i18next') === 'en' ? (
              'Versión en Español'
            ) : 'English Version'
          }
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => history.push('/')} disabled={history.location.pathname === '/'}>
          <ListItemIcon>
            <Explore />
          </ListItemIcon>
          {t('user_session.explore')}
        </MenuItem>
        <MenuItem onClick={() => history.push('/listing')} disabled={history.location.pathname === '/listing'}>
          <ListItemIcon>
            <Wallet />
          </ListItemIcon>
          {t('user_session.lend')}
        </MenuItem>
        <MenuItem onClick={() => history.push('/circles')} disabled={history.location.pathname === '/circles'}>
          <ListItemIcon>
            <GroupsOutlinedIcon />
          </ListItemIcon>
          {t('user_session.circles')}
        </MenuItem>
        <MenuItem onClick={() => history.push('/private-profile')} disabled={history.location.pathname === '/private-profile'}>
          <ListItemIcon>
            <Profile />
          </ListItemIcon>
          {t('user_session.profile')}
        </MenuItem>
        <MenuItem onClick={() => history.push('/activity')} disabled={history.location.pathname === '/activity'}>
          <ListItemIcon>
            <ChatOutlinedIcon />
          </ListItemIcon>
          {t('user_session.activity')}
        </MenuItem>
        <Divider />
        <MenuItem onClick={(e) => logOff(e)}>
          <ListItemIcon sx={{ svg: { transform: 'rotate(90deg)' } }}>
            <Share />
          </ListItemIcon>
          {t('user_session.logout')}
        </MenuItem>
      </Menu>
    </>
  );
}

import {
  Box, Typography,
} from '@mui/material';
import { useContext } from 'react';
import publicProfileContext from '../../../../../../../context/publicProfile/publicProfileContext';
import { LmiTheme } from '../../../../../ProfilePrivate/Components';

export default function AboutMember() {
  const pProfileContext = useContext(publicProfileContext);
  const {
    publicProfileInfo,
  }: any = pProfileContext;

  return (
    <Box sx={{ padding: '16px 30px', lineBreak: 'anywhere' }}>
      <Typography fontSize="16px" color={LmiTheme.light.systemBackground.tertiary}>
        {publicProfileInfo?.dscription}
      </Typography>
    </Box>
  );
}

import { LmiTheme } from '../../../../../../components/Common';

const ActivityReviewStyle = {
  width: '100%',
  '.lmiw-activity-review': {
    borderRadius: '10px',
    color: LmiTheme.light.systemBackground.secondary,
    '.lmiw-review-stack': {
      p: 2,
      svg: {
        width: '32px',
        height: '32px',
        color: LmiTheme.light.accent,
      },

    },
  },

};

export default ActivityReviewStyle;

import { Box, Typography, Stack } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { LmiTheme } from '../../../../../../components/Common';
import ActivityTrackingModalStyle from './ActivityTrackingModal.style';
// import ActivityContext from '../../../../../../context/activity/activityContext';
import MapRoadie from '../../../../../../components/Common/Map/MapRoadie';
import deliveryActivityContext from '../../../../../../context/deliveryActivity/deliveryActivityContext';
// import { ActivityReviewModels } from './ActivityReview.models';

// you could use this library:
/// https://github.com/tejassavaliya/react-google-map-vehicle-tracking

export default function ActivityTrackingModal() {
  const { t } = useTranslation('global');
  const deliveryActivityContextLog = useContext(deliveryActivityContext);
  const { collectShipment }: any = deliveryActivityContextLog;

  // const activityContext = useContext(ActivityContext);
  // const {
  //   openedActivityItem,
  // }: any = activityContext;
  return (
    <Box sx={{ ...ActivityTrackingModalStyle, position: 'relative' }}>
      {/* <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        p={1}
        spacing={1}
        sx={{ color: LmiTheme.light.systemBackground.secondary }}
      >
        <Typography fontSize="14" fontWeight="400">
          Delivery between:
        </Typography>
        <Typography fontSize="14" fontWeight="bold">
          12:30 PM  - 7:00 PM
        </Typography>
      </Stack> */}
      <Box sx={{ height: '400px' }}>
        <MapRoadie />
      </Box>
      <Box sx={{
        position: 'absolute', bottom: { md: 5, xs: 0 }, width: '100%',
      }}
      >
        <Box sx={{
          backgroundColor: { md: 'rgba(255, 255, 255, 0.8)', xs: 'white' },
          maxWidth: '500px',
          marginLeft: 'auto',
          marginRight: 'auto',
          borderRadius: { md: '10px', xs: 0 },
          // borderBottomLeftRadius: '10px',
          // borderBottomRightRadius: '10px',
          border: { md: `1px solid ${LmiTheme.light.systemBackground.separator}`, xs: 'none' },
          textAlign: 'center',
        }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              p={1}
            >
              <Typography fontSize={12} fontWeight="bold" sx={{ color: LmiTheme.light.systemBackground.secondary }}>
                {t('activity.tracking.from')}
              </Typography>
              <Typography fontSize={14} px={1} fontWeight="500" lineHeight={1.2}>
                {collectShipment?.pickUp?.address.street1}
                {/* {openedActivityItem?.addressComplete} */}
              </Typography>
            </Stack>

            <Box>
              <ArrowForwardIcon sx={{ width: '30px', height: '30px' }} />
            </Box>
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              p={1}
            >
              <Typography fontSize={12} fontWeight="bold" sx={{ color: LmiTheme.light.systemBackground.secondary }}>
                {t('activity.tracking.to')}
              </Typography>
              <Typography fontSize={14} px={1} fontWeight="500" lineHeight={1.2}>
                {collectShipment?.dropOff?.address.street1}
                {/* {openedActivityItem?.renterDeliveryAddress} */}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Box>

    </Box>
  );
}

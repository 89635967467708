import {
  DialogActions,
  Button,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DialogCommonActionsModels } from './DialogActions.models';

function DialogCommonActions(props: DialogCommonActionsModels) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation('global');
  return (
    <>
      <DialogActions
        sx={{ p: 3 }}
      >
        <Stack
          direction="row"
          justifyContent={isMobile ? props.kAlignBtnAction : 'inherit'}
          alignItems="center"
          spacing={2}
          // pl={1}
          width="100%"
          sx={{
            Button: {
              height: { xs: props.kKeepBtnSize ? 56 : 44, sm: 56 },
              fontSize: { xs: props.kKeepBtnSize ? '1rem' : '0.875rem', sm: '1rem' },
              fontWeight: { xs: props.kKeepBtnSize ? '700' : '500', sm: '700' },
              textTransform: 'capitalize',
              minWidth: { xs: props.kKeepBtnSize ? '150px' : '120px', sm: '150px' },
              borderRadius: '10px',
            },
          }}
        >

          {props.kElementAction ? props.kElementAction : <></>}
          {props.kSecondaryDialogAction
           && (
           <Button
             className="btn-textTransform-none"
             disableElevation
             variant="outlined"
             onClick={props.kSecondaryDialogAction}
           >
             {t(`${props.kSecondaryBtnTextAction}`)}
           </Button>
           )}
          {props.kPrimaryDialogAction
            && (
              <Button
                disabled={props.kDisableBtnSave}
                className="btn-textTransform-none"
                disableElevation
                variant="contained"
                onClick={props.kPrimaryDialogAction}
              >
                {t(`${props.kPrimaryBtnTextAction}`)}
              </Button>
            )}
          {props.kThirdDialogAction
            && (
              <Button
                disabled={props.kDisableBtnSave}
                className="btn-textTransform-none"
                disableElevation
                variant="contained"
                onClick={props.kThirdDialogAction}
              >
                {t(`${props.kThirdBtnTextAction}`)}
              </Button>
            )}
        </Stack>
      </DialogActions>
    </>
  );
}

DialogCommonActions.defaultProps = {
  kPrimaryBtnTextAction: 'global.save',
  kSecondaryBtnTextAction: 'global.cancel',
  kThirdBtnTextAction: 'global.continue',
  kSizeButtons: 'sm',
  kAlignBtnAction: 'flex-end',
};

export default DialogCommonActions;

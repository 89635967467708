import { LmiTheme } from '../../../../../../components/Common';

const ActivityProgressModalStyle = {
  width: '100%',
  '.Mui-completed svg': {
    color: LmiTheme.light.secondaryColor.green,
  },
};

export default ActivityProgressModalStyle;

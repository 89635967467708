/* eslint-disable no-tabs */
import { Box } from '@mui/material';
import { LogoModel } from './Logo.models';
import LogoStyle from './Logo.style';

export default function Logo(props: LogoModel) {
//   const classProps = `lmiw-c-logo lmiw-c-logo--${props.color} ${props.icon ? 'lmiw-c-logo--icon' : ''}`;
  const viewBoxProps = `${props.icon ? '0 0 61 61' : '0 0 193 61'}`;
  return (
    <Box sx={LogoStyle} width={props.width}>
      <svg version="1.1" id="logo" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox={viewBoxProps}>
        <g>
          <path
            className={`lmiw-color-${props.iconColor}`}
            d="M0.1,33.9c0-13.2,9.6-24.2,22.2-26.6V0.2L36,12.7L22.3,25.1v-7.4c-8.1,2.5-13.1,10.4-11.9,18.7
		s8.3,14.5,16.8,14.5c8.4,0.1,15.6-6,17-14.3c1.3-8.3-3.6-16.3-11.6-18.9l5.6-5.1l-5.8-5.3C45,9.7,54.5,20.7,54.5,33.9
		c0,14.9-12.2,27-27.2,27C12.3,61,0.1,48.9,0.1,33.9L0.1,33.9z"
          />
        </g>
        <g>
          <path
            className={`lmiw-color-${props.textColor}`}
            d="M76.2,24.7v15h9v3.4H72.1V24.7H76.2z M90.4,39.9c0.6,0.5,1.3,0.8,2.4,0.8c0.7,0,1.4-0.2,1.9-0.6
		c0.5-0.3,0.8-0.7,1-1.2H99c-0.5,1.6-1.3,2.7-2.4,3.4c-1.2,0.7-2.5,1.1-3.9,1c-1,0-1.9-0.2-2.9-0.5c-0.8-0.3-1.6-0.8-2.2-1.4
		c-0.6-0.6-1.1-1.4-1.4-2.2c-0.3-0.9-0.5-1.9-0.5-2.8c0-1,0.2-1.9,0.5-2.8c0.6-1.7,1.9-3,3.6-3.7c0.9-0.4,1.8-0.6,2.8-0.5
		c1,0,2.1,0.2,3,0.7c0.8,0.4,1.5,1,2.1,1.8s1,1.6,1.2,2.5c0.2,1,0.3,2,0.3,3h-9.7C89.6,38.5,89.9,39.4,90.4,39.9z M94.6,32.9
		c-0.4-0.5-1.1-0.7-2-0.7c-0.5,0-1,0.1-1.5,0.3c-0.4,0.2-0.7,0.4-0.9,0.7c-0.2,0.3-0.4,0.6-0.5,0.9c-0.1,0.3-0.1,0.6-0.2,0.9h6
		C95.4,34.1,95.1,33.4,94.6,32.9z M104.3,29.8v1.9h0.1c0.4-0.7,1.1-1.3,1.8-1.7c0.7-0.3,1.5-0.5,2.3-0.5s1.7,0.1,2.4,0.4
		c0.6,0.2,1.1,0.6,1.5,1.1s0.6,1.1,0.8,1.7c0.2,0.7,0.2,1.5,0.2,2.3v8.2h-3.7v-7.5c0-1.1-0.2-1.9-0.5-2.5c-0.3-0.5-1-0.8-1.8-0.8
		c-1,0-1.7,0.3-2.2,0.9c-0.4,0.6-0.7,1.6-0.7,2.9v7h-3.7V29.8H104.3L104.3,29.8z M125,41.4c-0.4,0.7-1,1.2-1.7,1.6
		c-0.7,0.3-1.6,0.5-2.4,0.5c-0.9,0-1.8-0.2-2.6-0.6c-0.7-0.4-1.4-0.9-1.9-1.6c-0.5-0.7-0.9-1.5-1.1-2.3c-0.3-0.9-0.4-1.8-0.4-2.7
		s0.1-1.8,0.4-2.6c0.2-0.8,0.6-1.5,1.1-2.2c0.5-0.6,1.1-1.2,1.9-1.5c0.8-0.4,1.7-0.6,2.6-0.6c0.8,0,1.6,0.2,2.3,0.5
		c0.7,0.3,1.3,0.8,1.7,1.5h0.1v-6.7h3.7v18.4h-3.5L125,41.4C125.1,41.4,125,41.4,125,41.4z M124.9,34.8c-0.1-0.5-0.3-0.9-0.5-1.3
		c-0.2-0.4-0.6-0.7-1-0.9c-0.5-0.3-1-0.4-1.5-0.4s-1.1,0.1-1.5,0.4c-0.4,0.2-0.7,0.6-1,1s-0.4,0.9-0.5,1.4s-0.2,1.1-0.2,1.6
		s0.1,1,0.2,1.5s0.3,1,0.6,1.4c0.3,0.4,0.6,0.7,1,1s1,0.4,1.5,0.4s1.1-0.1,1.5-0.4c0.4-0.2,0.7-0.6,1-1c0.2-0.4,0.4-0.9,0.5-1.4
		s0.2-1.1,0.2-1.6C125,35.9,125,35.3,124.9,34.8z M142.1,24.7l4.3,12.7h0.1l4.1-12.7h5.7v18.4h-3.8v-13h-0.1l-4.5,13.1h-3.1
		l-4.5-12.9h-0.1v12.9h-3.8V24.7H142.1z M162.8,39.9c0.6,0.5,1.4,0.8,2.4,0.8c0.7,0,1.4-0.2,1.9-0.6c0.5-0.3,0.8-0.7,1-1.2h3.2
		c-0.5,1.6-1.3,2.7-2.4,3.4c-1.2,0.7-2.5,1.1-3.9,1c-1,0-1.9-0.2-2.9-0.5c-0.8-0.3-1.6-0.8-2.2-1.4c-0.6-0.6-1.1-1.4-1.4-2.2
		c-0.3-0.9-0.5-1.9-0.5-2.8c0-1,0.2-1.9,0.5-2.8c0.6-1.7,1.9-3,3.6-3.7c0.9-0.4,1.8-0.6,2.8-0.5c1,0,2.1,0.2,3,0.7
		c0.8,0.4,1.5,1,2.1,1.8s1,1.6,1.2,2.5c0.2,1,0.3,2,0.3,3h-9.7C162,38.5,162.3,39.4,162.8,39.9L162.8,39.9z M167,32.9
		c-0.4-0.5-1.1-0.7-2-0.7c-0.5,0-1,0.1-1.5,0.3c-0.4,0.2-0.7,0.4-0.9,0.7s-0.4,0.6-0.5,0.9c-0.1,0.3-0.1,0.6-0.2,0.9h6
		C167.8,34.1,167.5,33.4,167,32.9L167,32.9z M181.6,24.7v18.4h-4.1V24.7H181.6z M192.2,29.8v2.5h-2.7v6.6c0,0.6,0.1,1,0.3,1.2
		s0.6,0.3,1.2,0.3c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.5-0.1v2.8c-0.3,0.1-0.7,0.1-1,0.1c-0.4,0-0.8,0-1.1,0c-0.5,0-1.1,0-1.6-0.1
		s-0.9-0.2-1.4-0.5c-0.4-0.2-0.7-0.6-0.9-1c-0.2-0.5-0.4-1.1-0.3-1.6v-7.9h-2.2v-2.5h2.2v-4h3.7v4h2.7V29.8z"
          />
        </g>
      </svg>
    </Box>

  );
}

Logo.defaultProps = {
  iconColor: 'accent',
  textColor: 'primary',
  width: 150,
  icon: false,
};

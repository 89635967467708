/* eslint-disable no-nested-ternary */
import {
  Card,
  CardContent,
  Divider,
  Stack,
  Box,
} from '@mui/material';
import LmiTheme from '../../../../../themes/lmi-mui-theme/lmi-theme';
import { SectionTitle } from '../../../../../components/Common';
import { CardLendModels } from './CardLend.models';

export default function CardLend(props: CardLendModels) {
  return (
    <Card elevation={0} sx={{ width: '100%' }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        sx={{ minHeight: 40, display: { xs: 'none', md: 'flex' } }}
        m={1}
      >
        <Stack
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}
        >
          {props.kBackAction}
          {
          !props.kHiddenTitle ? (
            <SectionTitle
              kMarginLeft={1}
              kMarginRight={2}
              kMarginTop={1}
              kMarginBottom={1}
              kTitle={props.kTitle}
            />
          ) : null
          }
        </Stack>

        {props.kActions}
      </Stack>
      <Divider sx={{ display: { xs: 'none', md: 'block' } }} />
      <CardContent
        sx={{
          p: 0,
          height: {
            xs: '100vh',
            md: props.kViewMap ? 'calc(100vh - 90px)' : 'calc(100vh - 190px)',
            lg: props.kViewMap ? 'calc(100vh - 120px)' : 'calc(100vh - 220px)',
          },
          mb: 0,
          minHeight: { xs: 'calc(100vh - 65px)', md: '500px' },
          overflow: 'auto',
          '&:hover::-webkit-scrollbar-thumb': { backgroundColor: LmiTheme.light.systemBackground.tertiary, borderRadius: 5 },
          '&::-webkit-scrollbar': { backgroundColor: 'white', width: 4 },
        }}
      >
        {props.kTopBar && (
          <Box sx={{ display: { xs: 'none', md: 'inherit' } }}>
            <Box mx={2} my={1}>
              {props.kTopBar}
            </Box>
            {
              !props.kHideDividers && (
                <Divider />
              )
            }
          </Box>
        )}
        {props.kContent}
        {props.kSecondTopBar && (
          <Box sx={{ display: { xs: 'none', md: 'inherit' } }}>
            <Box mx={2} my={1}>
              {props.kSecondTopBar}
            </Box>
            {
              !props.kHideDividers && (
                <Divider />
              )
            }
          </Box>
        )}
        {props.kSecondContent}
      </CardContent>
    </Card>
  );
}

// import {
//   styled, alpha,
// } from '@mui/material/styles';
// import MuiAppBar from '@mui/material/AppBar';
// import InputBase from '@mui/material/InputBase';
// import { makeStyles } from '@mui/styles';

import LmiTheme from '../../../themes/lmi-mui-theme/lmi-theme';

const BackOfficeAppBarStyle = {
  borderBottom: `1px solid${LmiTheme.light.systemBackground.separator}`,
  '.lmi-stack': {
    height: { md: '64px', xs: '128px' },
    padding: '0 20px',
    '.lmi-box-logo': {
      width: { xs: '48px', sm: '150px', md: '200px' },
      cursor: 'pointer',
      svg: {
        width: '100%',
      },
    },
    '.lmi-formcontrol': {
      width: { md: '400px', xs: '100%' },

    },
    '.lmi-stack-formcontrol': {
      width: '100%',

    },
    '.lmi-input-search': {
      height: { xs: '44px', md: '46px' },
      borderRadius: 2,
      px: 1,
      py: 2,
      border: '0',
      boxShadow: 'none !important',
      backgroundColor: LmiTheme.light.systemBackground.base,
      '& fieldset': {
        border: '0',
      },
      '&.Mui-focused fieldset': {
        border: `1px solid${LmiTheme.light.accent} !important`,
      },

    },
    '.lmi-btn-search': {
      minWidth: '120px',
      height: { xs: '44px', md: '46px' },
      borderRadius: 2,
      textTransform: 'capitalize',
      fontWeight: 'bold',
      display: {
        xs: 'none', sm: 'flex', md: 'none', lg: 'flex',
      },
      borderColor: LmiTheme.light.systemBackground.separator,
      '&:hover': {
        color: 'white',
        backgroundColor: LmiTheme.light.accent,
      },
    },
    '.lmi-hide-down-lg': {
      display: { xs: 'none', md: 'flex' },
    },
    '.lmi-stack-logo-usermenu': {
      width: '100%',
    },
  },
};

export default BackOfficeAppBarStyle;

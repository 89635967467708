import { Stack } from '@mui/material';
import {
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';
import { useTranslation } from 'react-i18next';

function ShareListingsSocialMedia(props: any) {
  const { t } = useTranslation('global');

  return (
    <>
      <Stack flexDirection="row" justifyContent="center" alignItems="center" gap={2}>
        <FacebookShareButton url={props.dynamicLink} quote={t('share.listing')}>
          <FacebookIcon size={50} round />
        </FacebookShareButton>
        <FacebookMessengerShareButton url={props.dynamicLink} appId="">
          <FacebookMessengerIcon size={50} round />
        </FacebookMessengerShareButton>
        <WhatsappShareButton url={props.dynamicLink} title={t('share.listing')}>
          <WhatsappIcon size={50} round />
        </WhatsappShareButton>
        <TwitterShareButton url={props.dynamicLink} title={t('share.listing')}>
          <TwitterIcon size={50} round />
        </TwitterShareButton>
      </Stack>
    </>
  );
}

export default ShareListingsSocialMedia;

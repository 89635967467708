import { ArrowForwardIosRounded } from '@mui/icons-material';
import {
  Avatar,
  AvatarGroup,
  Box,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconLinkButton, LmiTheme, SectionTitle } from '../../../page/BackOffice/ProfilePrivate/Components';
import { CirclesCardModels } from './Circles.models';

function CirclesCard(props: CirclesCardModels) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const circlesList = [
    { kName: 'Circle 0', kimage: 'https://source.unsplash.com/random/0' },
    { kName: 'Circle 1', kimage: 'https://source.unsplash.com/random/1' },
    { kName: 'Circle 2', kimage: 'https://source.unsplash.com/random/2' },
    { kName: 'Circle 3', kimage: 'https://source.unsplash.com/random/3' },
    { kName: 'Circle 4', kimage: 'https://source.unsplash.com/random/4' },
    { kName: 'Circle 5', kimage: 'https://source.unsplash.com/random/5' },
    { kName: 'Circle 6', kimage: 'https://source.unsplash.com/random/6' },
    { kName: 'Circle 7', kimage: 'https://source.unsplash.com/random/7' },
    { kName: 'Circle 8', kimage: 'https://source.unsplash.com/random/8' },
    { kName: 'Circle 9', kimage: 'https://source.unsplash.com/random/9' },
    { kName: 'Circle 10', kimage: 'https://source.unsplash.com/random/10' },
    { kName: 'Circle 11', kimage: 'https://source.unsplash.com/random/11' },
    { kName: 'Circle 12', kimage: 'https://source.unsplash.com/random/12' },
  ];
  const { t } = useTranslation('global');
  return (
    <>
      <SectionTitle kTitle={props.kTitle} kMarginTop={3} kMarginBottom={1} />
      <Paper variant="outlined">
        <Box my={2} ml={2} mr={1}>
          {
            circlesList.length !== 0
              ? (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                >
                  <Stack
                    pt={0}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    <AvatarGroup
                      total={circlesList.length}
                      max={isMobile ? 3 : 6}
                      sx={{
                        '.MuiAvatarGroup-avatar': {
                          backgroundColor: LmiTheme.light.accent,
                          fontSize: { xs: 10, sm: 12, md: 14 },
                          width: { xs: 26, sm: 32, md: 42 },
                          height: { xs: 26, sm: 32, md: 42 },
                        },
                      }}
                    >
                      {circlesList.map((item: any) => (
                        <Avatar
                          key={`${item.kname}`}
                          alt={item.kname}
                          sx={{
                            width: { xs: 26, sm: 32, md: 42 },
                            height: { xs: 26, sm: 32, md: 42 },
                          }}
                          src={item.kimage}
                        />
                      ))}
                    </AvatarGroup>
                    <Typography sx={{ fontSize: 'body2.fontSize', color: LmiTheme.light.systemBackground.tertiary }}>
                      {circlesList.length}
                      <> </>
                      {t('circles_card.circles')}
                    </Typography>
                  </Stack>
                  <IconLinkButton
                    kOnClick={undefined}
                    kEndIcon={<ArrowForwardIosRounded />}
                    kText={t('circles_card.see_all')}
                    kFontSize="body1.fontSize"
                    kFontWeight="bold"
                    kHideText={false}
                  />
                </Stack>
              )
              : (
                <Typography sx={{ fontSize: 'body2.fontSize', color: LmiTheme.light.systemBackground.tertiary }}>
                  {t('circles_card.no_circles')}
                </Typography>
              )
          }
        </Box>
      </Paper>
    </>
  );
}

CirclesCard.defaultProps = {
  kTitle: 'Circles',
};

export default CirclesCard;

const BtnStyle = {
  minHeight: '44px',
  fontWeight: '600',
  borderRadius: '8px',
  minWidth: { sm: '130px', xs: '90px' },
  '&.lmiw-btn-login': {
    textTransform: 'capitalize',
  },
};

export default BtnStyle;

import {
  Box, Typography, Stack, Divider, FormControlLabel, useTheme, useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import React, {
  useContext,
  useState,
  useEffect,
} from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { InputForm, LmiTheme, DialogCommon } from '../../../../../../components/Common';
import AddEditCirclesContext from '../../../../../../context/circles/addEditCirclesContext';
import payoutAccountContext from '../../../../../../context/payoutAccount/payoutAccountContext';
import useSpecialFeeValidations from './hooks/useSpecialFeeValidations';
import NewIndOrBusPayout from '../../../../ProfilePrivate/Components/TabPanelPayoutAccount/Forms/NewIndOrBusPayout';

// const payoutTag = () => <b />;

export default function SetSpecialFee() {
  const [openAddPayOutDialog, setOpenAddPayOutDialog] = useState<boolean>(false);
  const circlesContextLog = useContext(AddEditCirclesContext);
  const {
    circleInfoEdit,
    updateCircleInfo,
    updateDraftCircleInfo,
  }: any = circlesContextLog;
  const payoutContext = useContext(payoutAccountContext);
  const {
    checkNewPayoutValidations,
    arePayoutFieldsValidated,
    payoutAccountDetails,
    updatePayoutAccount,
    createPayoutAccount,
    changedPayoutFields,
    payoutUpdated,
    getUserPayoutAccount,
  }: any = payoutContext;

  const { onlyNumberWithDecimals } = useSpecialFeeValidations();

  const Theme = useTheme();

  const isMobile = useMediaQuery(Theme.breakpoints.down('md'));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name == 'isCircleFeeActive') {
      updateCircleInfo({
        ...circleInfoEdit,
        isCircleFeeActive: !circleInfoEdit.isCircleFeeActive,
      });
      updateDraftCircleInfo({
        ...circleInfoEdit,
        isCircleFeeActive: !circleInfoEdit.isCircleFeeActive,
      });
    }
  };

  const IOSSwitch = styled((props: SwitchProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#038DE2' : '#038DE2',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  const { t } = useTranslation('global');

  useEffect(() => {
    document.getElementById('payout_button')?.addEventListener('click', () => setOpenAddPayOutDialog(true));
  }, []);

  const clickSavePayout = () => {
    checkNewPayoutValidations(true);
  };

  useEffect(() => {
    if (arePayoutFieldsValidated) {
      if (payoutAccountDetails?.isDebitCar && payoutAccountDetails?.expiration?.length >= 4) {
        const splitExpire = payoutAccountDetails.expiration.split('/');
        const month = splitExpire[0];
        const year = splitExpire[1];
        const arrToSend = {
          ...payoutAccountDetails,
          expirationMonth: month,
          expirationYear: year,
        };

        if (payoutAccountDetails.accId != '') {
          updatePayoutAccount(arrToSend);
        } else {
          createPayoutAccount(arrToSend);
        }
      } else {
        const arrToSend = {
          ...payoutAccountDetails,
          expirationMonth: '',
          expirationYear: '',
        };
        if (payoutAccountDetails.accId != '') {
          updatePayoutAccount(arrToSend);
        } else {
          createPayoutAccount(arrToSend);
        }
      }
    }
  }, [changedPayoutFields]);

  const getUserPayout = async () => {
    await getUserPayoutAccount();
  };

  useEffect(() => {
    if (payoutUpdated) {
      getUserPayout();
      setOpenAddPayOutDialog(false);
    }
  }, [payoutUpdated]);

  useEffect(() => {
    getUserPayout();
  }, []);

  return (
    <Box>
      <Stack
        minHeight="53px"
        pr={2}
        pl={3}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={0}
      >
        <Stack spacing={1} py={2}>
          <Typography fontSize="17px" color={LmiTheme.light.systemBackground.tertiary} sx={{ mr: isMobile ? '20px' : '' }}>
            {t('circles.add_circle_page.want_special_fee')}
          </Typography>
          {!payoutAccountDetails?.accId ? (
            <Stack maxWidth="90%">
              <Typography fontSize="14px" color={LmiTheme.light.systemBackground.tertiary}>
                <Trans components={{ tag: <b id="payout_button" style={{ cursor: 'pointer', color: LmiTheme.light.accent }} /> }}>
                  {t('circles.add_circle_page.fee_range_activate_message')}
                </Trans>
              </Typography>
            </Stack>
          ) : null}
        </Stack>

        <FormControlLabel
          label=""
          control={(
            <IOSSwitch
              checked={circleInfoEdit?.isCircleFeeActive}
              name="isCircleFeeActive"
              onChange={handleChange}
              sx={{ mr: -1 }}
              disabled={!payoutAccountDetails?.accId}
            />
          )}
        />
      </Stack>
      <Divider />
      <Stack
        minHeight="53px"
        p={0}
        pl={3}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={0}
      >
        <Stack spacing={1} py={2}>
          <Typography fontSize="17px" color={LmiTheme.light.systemBackground.tertiary} sx={{ mr: isMobile ? '30px' : '' }}>
            <Trans components={{ tag: <b /> }}>
              {t('circles.add_circle_page.fee_range')}
            </Trans>
          </Typography>
          <Stack maxWidth="90%">
            <Typography fontSize="14px" color={LmiTheme.light.systemBackground.tertiary}>
              {t('circles.add_circle_page.fee_range_message')}
            </Typography>
          </Stack>
        </Stack>
        <Box sx={{ mr: '10px', mb: '10px' }}>
          <InputForm
            id="circleFee"
            label=""
            helper_text=""
            kHeight="48px"
            disabled={!circleInfoEdit?.isCircleFeeActive || !payoutAccountDetails?.accId}
            input_params={{
              placeholder: '0',
              name: 'circleFee',
              value: circleInfoEdit?.circleFee,
              onChange: onlyNumberWithDecimals,
            }}
            form_control_params={{
              fullWidth: false,
              sx: {
                width: '109px',
                textAlign: 'center',
                pt: '0px',
                pb: '0',
                fontSize: '14px',
              },
            }}
          />
        </Box>

      </Stack>
      <DialogCommon
        kTitle={t('activity_detail.add_payout')}
        kMaxWidth="sm"
        kOpenDialogLists={openAddPayOutDialog}
        kOnClose={() => setOpenAddPayOutDialog(false)}
        kOnSecondary={() => setOpenAddPayOutDialog(false)}
        kOnPrimary={() => clickSavePayout()}
        kContent={<NewIndOrBusPayout />}
      />
    </Box>
  );
}

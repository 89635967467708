import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ListItem,
  ListItemText,
  Radio,
} from '@mui/material';
import LmiTheme from '../../../../../themes/lmi-mui-theme/lmi-theme';
import { PICKUPBYLENDER, ROADIE } from '../../../../../types';
import { DeliveryModels } from './Deivery.models';
import DeliveryByLender from './DeliveryByLender';
import DeliveryByRoadie from './DeliveryByRoadie';

function Delivery(props: DeliveryModels) {
  return (
    <>
      <Box p={0} m={0} sx={{ background: props.kShowContent !== true ? LmiTheme.light.systemBackground.base : 'transparent' }}>
        <ListItem
          dense
          key={props.kId}
          disablePadding
          divider={props.kDivider}
          sx={{
            py: 1,
            pr: 1,
            pl: 2,
          }}
        >
          <ListItemText
            primaryTypographyProps={{ fontWeight: '500', fontSize: 'subtitle1.fontSize' }}
            secondaryTypographyProps={{ color: LmiTheme.light.systemBackground.tertiary, fontSize: 'subtitle2.fontSize' }}
            primary={props.kTitle}
            secondary={props.kDescription}
          />
          <Radio
            checked={props.kChecked}
            onChange={props.kOnChange}
            disabled={!props.kShowContent}
            value={props.kValue}
            name="delivery-options"
          />
        </ListItem>
        <Box my={0}>

          <Accordion disableGutters elevation={0} expanded={props.kSelectedValue === PICKUPBYLENDER ?? false}>
            <AccordionSummary sx={{ display: 'none', p: 0 }} />
            <AccordionDetails sx={{ p: 0 }}>
              {
                props.kShowContent === true && props.kSelectedValue === props.kDeliveryType && props.kDeliveryType === PICKUPBYLENDER
                  ? <DeliveryByLender /> : <Box />

              }
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box my={0}>
          <Accordion disableGutters elevation={0} expanded={props.kSelectedValue === ROADIE ?? false}>
            <AccordionSummary sx={{ display: 'none', p: 0 }} />
            <AccordionDetails sx={{ p: 0 }}>
              {
                props.kShowContent === true && props.kSelectedValue === props.kDeliveryType && props.kDeliveryType === ROADIE ? <DeliveryByRoadie /> : <Box />
              }
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </>
  );
}

export default Delivery;

const style = {
  width: 'calc(100% - 50px)',
  height: '200px',
  zIndex: '9999',
  bottom: '0px',
  '.lmi-swiper-slide': {
    height: '100%',
    display: 'flex',
    backgroundSize: 'cover',
    maxWidth: '250px',
    borderRadius: '10px',
    '.lmi-swiper-stack': {
      overflow: 'hidden',
      height: {
        xs: { xs: '450px', sm: '600px' },
      },
      position: 'relative',
      '.caption': {
        zIndex: 2,
        textAlign: 'center',
      },
      '.lmi-swiper-collapse': {
        my: { xs: 2, sm: 4 },
        overflow: 'hidden',
        transition: 'all 1s ease',
        p: {
          fontSize: '18px',
          lineHeight: ' 1.2',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 2,
        },
      },
    },
  },
  '& .swiper-wrapper': {
    height: { xs: '151px', sm: '151px' },
    mb: 5,
  },

  '& .swiper-slide': {
    borderRadius: '10px',
    '& .caption': {
      opacity: '0',
      h2: {
        fontSize: { xs: '1.8rem', md: '2.125rem' },
      },
    },
  },

  '& .bg': {
    left: 0,
    bottom: 0,
    width: '100%',
    height: '150%',
    zIndex: 1,
    borderRadius: '10px',
    transition: 'all 1s ease',
    background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);',
  },

  '& .swiper-button-next, .swiper-button-prev': {
    color: '#038de2',
    borderRadius: '50px',
    border: '2px solid white',
    height: '44px',
    width: '44px',
    padding: '0px',
    backgroundColor: 'white',
    '&:after': {
      fontSize: '18px',
      fontWeight: 'bold',
    },
    display: { xs: 'none', sm: 'flex' },
    top: '40%',
  },
  '& .swiper-button-next': {
    right: {
      xs: '20px', sm: '40px', md: '30px', lg: '30px',
    },
  },
  '& .swiper-button-prev': {
    left: {
      xs: '20px', sm: '40px', md: '30px', lg: '30px',
    },
  },

  '& .swiper-slide-shadow-left': {
    borderRadius: '10px',
  },

  '& .swiper-slide-shadow-right': {
    borderRadius: '10px',
  },
};

export default style;

/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable react/jsx-props-no-spreading */
import { useContext } from 'react';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'swiper/swiper-bundle.min.css';
import {
  Typography, Avatar, Stack, Box, Button, Fab,
} from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { ContentLayout, SectionTitle } from '../../../../../components/Common';
import homeContext from '../../../../../context/home/homeContext';
import exploreContext from '../../../../../context/explore/exploreContext';
import CategoriesSwiperStyle from './CategoriesSwiper.style';

SwiperCore.use([Pagination, Navigation]);
export default function CategoriesSwiper() {
  const history = useHistory();
  const {
    categories,
    addCategories,
    removeAllCategoriesSelected,
  }: any = useContext(homeContext);

  const {
    addCategoriesApplied,
    clearData,
  }: any = useContext(exploreContext);

  const MainParams = {
    modules: [Pagination, Navigation],
    spaceBetween: 3,
    slidesPerGroup: 1,
    initialSlide: 0,
    navigation: {
      prevEl: '.prev',
      nextEl: '.next',
    },
    breakpoints: {
      1900: {
        slidesPerView: 8,
        spaceBetween: 50,
        initialSlide: 3,
        slidesPerGroup: 3,
      },

      1200: {
        slidesPerView: 8,
        spaceBetween: 40,
      },

      860: {
        slidesPerView: 7,
        spaceBetween: 20,
      },

      768: {
        slidesPerView: 6,
        spaceBetween: 12,
      },

      600: {
        slidesPerView: 5,
        spaceBetween: 8,
      },

      300: {
        slidesPerView: 4,
        spaceBetween: 10,
      },
      1: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
    },
  };

  const { t } = useTranslation('global');

  const getImageCategories = (data: { idCategory: any; }) => {
    let image_path = '';
    try {
      image_path = require(`../../../../../assets/categories_swiper/${data.idCategory}.webp`).default;
    } catch (err) {
      image_path = require('../../../../../assets/categories_swiper/cat_default.webp').default;
    }
    return image_path;
  };

  return (
    <>
      <Box pt={5} pb={5} component="section" sx={CategoriesSwiperStyle}>
        <ContentLayout>
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              mb={5}
            >
              <SectionTitle
                kMarginTop={0}
                kMarginBottom={0}
                kComponent="h2"
                kVariant="h5"
                kTitle={t('categories.categories').toUpperCase()}
              />
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
                className="lmi-stack-btn"
              >
                <Fab
                  size="medium"
                  aria-label="left"
                  className="prev"
                  style={{ zIndex: 0 }}
                >
                  <ArrowLeftIcon />
                </Fab>
                <Fab
                  size="medium"
                  aria-label="right"
                  className="next"
                  style={{ zIndex: 0 }}
                >
                  <ArrowRightIcon />
                </Fab>
                <Button
                  style={{ textTransform: 'none' }}
                  color="primary"
                  size="large"
                  variant="outlined"
                  onClick={() => {
                    clearData();
                    removeAllCategoriesSelected();
                    history.push('/search');
                  }}
                >
                  {t('categories.see_all')}
                </Button>

              </Stack>
            </Stack>
            <Swiper {...MainParams}>
              {categories && categories.length > 0
                ? categories.map((data: any) => (
                  <SwiperSlide
                    onClick={async () => {
                      const category = { id: data.idCategory, title: data.category };
                      clearData();
                      await addCategories([category]);
                      await addCategoriesApplied([category]);
                      history.push('/search',
                        { state: { categories: [category] } });
                    }}
                    key={data.idCategory}
                  >
                    <Stack
                      direction="column"
                      justifyContent="flex-center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Avatar
                        alt={data.category}
                        className="lmi-avatar-category"
                        src={getImageCategories(data)}
                      />
                      <Typography variant="body2" textAlign="center">
                        {t(`categories.${data.category.trim()}`)}
                      </Typography>
                    </Stack>
                  </SwiperSlide>

                )) : null}
            </Swiper>
          </>
        </ContentLayout>
      </Box>

    </>
  );
}

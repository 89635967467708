import {
  Box,
  Paper,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import LmiTheme from '../../../../../themes/lmi-mui-theme/lmi-theme';
import {
  SectionTitle, MapComponent, DialogCommon,
} from '../../../../../components/Common';
import { PickupLocationModels } from './PickupLocation.models';
import bookItemContext from '../../../../../context/bookItem/bookContext';
import StaticMap from '../../../../../components/Common/StaticMap/StaticMap';

function PickupLocation(props: PickupLocationModels) {
  const {
    bookDetails: { lat, lon, exactAddress },
  }: any = useContext(bookItemContext);

  const [open, setOpen] = React.useState(false);
  const handleModalToggle = () => {
    setOpen(!open);
  };
  // const updateMapa = (mapaData: any) => {
  //   console.log(mapaData);
  // };

  const { t } = useTranslation('global');
  return (
    <>
      <SectionTitle kTitle={props.kTitle} />
      <Paper
        variant="outlined"
        sx={{ borderRadius: '10px' }}
      >
        <Box
          m={2}
          sx={{
            backgroundColor: LmiTheme.light.systemBackground.base,
            minHeight: 200,
            borderRadius: 2,
          }}
          onClick={() => { handleModalToggle(); }}
        >
          <StaticMap
            kExactLocation={exactAddress}
            kLat={parseFloat(lat)}
            kLng={parseFloat(lon)}
          />
        </Box>
      </Paper>

      <DialogCommon
        kTitle={t('item_detail.item_location')}
        kMaxWidth="lg"
        kOpenDialogLists={open}
        kOnClose={handleModalToggle}
        kDisableActions
        kContent={(
          <MapComponent
            state={{
              height: 500,
              lat,
              long: lon,
            }}
            exactAddress={exactAddress}
            kDraggable={false}
          // funUpdateInfo={updateMapa}
          />
        )}
      />
    </>
  );
}

PickupLocation.defaultProps = {
  kTitle: 'kTitle',
};

export default PickupLocation;

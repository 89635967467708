// import Box from '@mui/material/Box';
import {
  Stack, Box, useTheme, useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';
import {
  ContentLayout, UserMenu, SectionTitle,
} from '../../Common';
import AppBarSecondary from '../../Layout/AppBarSecondary/AppBarSecondary';
import { ListingDetailModels } from './ListingDetail.models';
import ListingDetailStyle from './ListingDetail.style';

export default function ListingDetail(props: ListingDetailModels) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation('global');
  return (
    <>
      <Switch>
        <Route
          path={props.kListingPath}
          exact
          render={() => (
            <AppBarSecondary
              kTitle={(
                <SectionTitle
                  kMarginTop={0}
                  kMarginBottom={0}
                  kTitle={t('activity_detail.activity')}
                />
                )}
              kActions={<UserMenu />}
            />
          )}
        />
        <Route
          path={props.kDetailPath}
          exact
          render={() => (
            <AppBarSecondary
              kBack
              kTitle={(
                <SectionTitle
                  kMarginTop={0}
                  kMarginBottom={0}
                  kTitle={t('activity_detail.activity_detail')}
                />
                )}
            />
          )}
        />
      </Switch>
      <ContentLayout kPaddingXs={0}>
        <Stack
          direction={props.kListingDirection === 'left' ? 'row' : 'row-reverse'}
          justifyContent="center"
          alignItems="flex-start"
          spacing={props.kSpacing}
          sx={ListingDetailStyle}
        >
          <Switch>
            <Route
              path={isMobile ? props.kListingPath : [props.kListingPath, props.kDetailPath]}
              exact
              render={() => (
                <Box className="lmiw-listing">
                  {!isMobile || !props.kHideListingHeaderInMobile ? (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent={props.kListingHeaderJustify}
                      spacing={2}
                      className="lmiw-listing-header"
                    >
                      {props.kListingHeader}
                    </Stack>
                  ) : <></> }
                  <Box className="lmiw-listing-body">
                    {props.kListingBody}
                  </Box>
                </Box>
              )}
            />
          </Switch>
          <Switch>
            <Route
              path={isMobile ? props.kDetailPath : [props.kListingPath, props.kDetailPath]}
              exact
              render={() => (
                <Box className="lmiw-detail">
                  {!isMobile || !props.kHideDetailHeaderInMobile ? (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent={props.kDetailHeaderJustify}
                      spacing={2}
                      className="lmiw-detail-header"
                    >
                      {props.kDetailHeader}
                    </Stack>
                  ) : <></> }

                  <Box className="lmiw-detail-body">
                    {props.kDetailBody}
                  </Box>
                </Box>
              )}
            />
          </Switch>
        </Stack>
      </ContentLayout>
    </>
  );
}

ListingDetail.defaultProps = {
  kListingDirection: 'left',
  kSpacing: 0,
  kListingHeaderJustify: 'space-between',
  kDetailHeaderJustify: 'space-between',
  kHideListingHeaderInMobile: false,
  kHideDetailHeaderInMobile: false,
};

import { useReducer } from 'react';
import moment from 'moment';
import CalendarContext from './calendarContext';
import CalendarReducer from './calendarReducer';
import {
  CHANGE, RECOMMENDED_MONTHLY, RECOMMENDED_WEEKLY,
} from '../../types';

const CalendarState = ({ children }: any) => {
  const [state, dispatch] = useReducer(CalendarReducer, {
    starLimitDate: null,
    endLimitDate: null,
    starWeekSelectDate: null,
    endWeekSelectDate: null,
    daysOfWeek: null,
    starMonthSelectDate: null,
    endMonthSelectDate: null,
    daysOfMonth: null,
  });

  const nextDaysRecommended = (
    numberDays: number,
    inactiveDays:string[] = [],
    type: 'WEEKLY' | 'MONTHLY',
  ) => {
    let starSelectDate: any;
    let endSelectDate: any;

    const now = new Date();
    now.setFullYear(now.getFullYear() + 1, now.getMonth(), now.getDate());

    let daysOfYear = [];
    for (let d = new Date(); d <= now; d.setDate(d.getDate() + 1)) {
      const compareDate = moment(d);

      const dayFormatted = compareDate.format('YYYY-MM-DD').toString();

      if (inactiveDays && inactiveDays.indexOf(dayFormatted) > -1) {
        starSelectDate = null;
        daysOfYear = [];
      } else if (!starSelectDate) {
        // starSelectDate = compareDate;
        starSelectDate = new Date(d);
      }

      daysOfYear.push(compareDate);

      endSelectDate = d;

      // const numberOfDays = compareDate.diff(starSelectDate, 'days');
      const numberOfDays = compareDate.diff(moment(starSelectDate), 'days');

      if (numberOfDays === numberDays) {
        break;
      }
    }

    const result = { starSelectDate, endSelectDate, daysOfYear };

    if (inactiveDays != null) {
      setRange(starSelectDate, inactiveDays);
    }

    dispatch({
      type: type == 'WEEKLY' ? RECOMMENDED_WEEKLY : RECOMMENDED_MONTHLY,
      payload: result,
    });
    return result;
  };

  const setRange = (date:Date, inactiveDays:string[]) => {
    let startDate: any;
    let endDate: any;

    const yesterday = new Date();
    yesterday.setFullYear(yesterday.getFullYear() - 1, yesterday.getMonth(), yesterday.getDate());

    if (inactiveDays == null || inactiveDays.length == 0) {
      inactiveDays = inactiveDays ?? [];

      inactiveDays.push(moment(yesterday).format('YYYY-MM-DD').toString());
    }

    for (let index = 0; index < inactiveDays.length; index += 1) {
      const nextIndexInactiveDate = index + 1;
      const compareDate = moment(date, 'YYYY/MM/DD');

      startDate = moment(inactiveDays[index], 'YYYY/MM/DD');

      if (compareDate.isBefore(startDate)) {
        endDate = moment(inactiveDays[index], 'YYYY/MM/DD');
        startDate = null;
        break;
      }

      if (nextIndexInactiveDate >= inactiveDays.length) {
        break;
      }

      endDate = moment(inactiveDays[nextIndexInactiveDate], 'YYYY/MM/DD');

      if (compareDate.isBetween(startDate, endDate)) {
        break;
      }

      endDate = undefined;
    }

    const result = { starLimitDate: startDate, endLimitDate: endDate };
    dispatch({
      type: CHANGE,
      payload: result,
    });

    return result;
  };

  return (
    <CalendarContext.Provider
      value={
     {
       ...state,
       starLimitDate: state.starLimitDate,
       endLimitDate: state.endLimitDate,
       setRange,
       nextDaysRecommended,
     }
      }
    >
      {children}
    </CalendarContext.Provider>
  );
};

export default CalendarState;

/* eslint-disable max-lines */
import {
  Box, Button, List, FormControl, OutlinedInput, InputAdornment, IconButton, Skeleton, Stack,
} from '@mui/material';
import { useState, useContext, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CancelIcon from '@mui/icons-material/Cancel';
import { toast } from 'react-toastify';
import ActivityListStyle from './ActivityList.style';
import ItemList from './Components/ActivityItem/ActivityItem';
import {
  EmptyState, LmiTheme, LoadingLayout, SearchSuggestion,
} from '../../../../components/Common';
import FiltersSuggestion from './Components/FiltersSuggestion/FiltersSuggestion';
import empty_lend from '../../../../assets/empty_state/empty_lend.png';
import ActivityContext from '../../../../context/activity/activityContext';
import { ActivityListModel } from './ActivityList.models';
import activityActionsContext from '../../../../context/activity/actions/activityActionsContext';
import SocketContext from '../../../../context/socket/socketContext';

const SequentialSkeletons = () => (
  <Box sx={{
    px: '30px',
  }}
  >
    {[...Array(5)].map(() => (
      <Stack flexDirection="row" justifyContent="start" alignItems="center" width="100%" py={2} px={1} gap={2} mb="5px">
        <Skeleton
          variant="rectangular"
          width="74px"
          height={74}
          animation="wave"
          sx={{ borderRadius: '10px', bgcolor: '#f7f7f7' }}
        />
        <Stack flexDirection="column" justifyContent="space-between" alignItems="space-between">
          <Stack mb="20px">
            <Skeleton
              variant="rectangular"
              width="220px"
              height={16}
              animation="wave"
              sx={{ borderRadius: '10px', bgcolor: '#f7f7f7', mb: '5px' }}
            />
            <Skeleton
              variant="rectangular"
              width="80px"
              height={16}
              animation="wave"
              sx={{ borderRadius: '10px', bgcolor: '#f7f7f7' }}
            />
          </Stack>
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
            <Skeleton
              variant="rectangular"
              width="60px"
              height={16}
              animation="wave"
              sx={{ borderRadius: '10px', bgcolor: '#f7f7f7' }}
            />
            <Skeleton
              variant="rectangular"
              width="40px"
              height={16}
              animation="wave"
              sx={{ borderRadius: '10px', bgcolor: '#f7f7f7' }}
            />
          </Stack>
        </Stack>
      </Stack>

    ))}
  </Box>
);

export default function ActivityList(props: ActivityListModel) {
  const [search, setSearch] = useState('');
  const [visibleSuggestion, setVisibleSuggestion] = useState(false);
  const [readNotificationIds, setReadNotificationIds] = useState<number[]>([]);

  const storageItem: any = localStorage.getItem('item');
  const activityContext = useContext(ActivityContext);

  const {
    getActivityItems,
    getHistoryActivityItems,
    activityList,
    activityHistoryList,
    filterHistoryNotifs,
    filterNotifs,
    setDetailActivityItem, // this will set the selected item for the detail page
    setActionNotifOpened, // sets notif as read// ActivityActionssetNotifOpened
    openedActivityItem,
    unsetOpenActivityItem,
    getActivityJoinCircleExtraInfo,
    getActivityCircleInvitation,
    loading,
  }: any = activityContext;

  const activityActionContext = useContext(activityActionsContext);
  const {
    taskEnded,
    taskError,
    setLoadingFalse,
    setLoadingTrue,
  }: any = activityActionContext;
  const history = useHistory();

  const socketContext = useContext(SocketContext);
  const { ws }: any = socketContext;

  useEffect(() => {
    (async function fetchActivityData() {
      try {
        await Promise.all([
          getActivityItems(),
          getHistoryActivityItems(),
        ]);
      } catch (err) {
        console.log(err);
      }
    }());
  }, []);

  useEffect(() => {
    if (window.location.pathname == '/activity/detail') {
      if (openedActivityItem == null && storageItem != null) {
        setDetailActivityItem(JSON.parse(storageItem));
      }
    }
  }, []);

  useEffect(() => {
    const reloadActivityItems = async () => {
      setLoadingTrue();
      const newItems = await getActivityItems();
      const historyItems = await getHistoryActivityItems();

      const findItemIndex = (items: any) => items.findIndex((x: any) => x.idCatLndRntItem === openedActivityItem.idCatLndRntItem);

      let indexItem = findItemIndex(newItems);
      let reopenItem = indexItem >= 0 ? newItems[indexItem] : null;

      if (!reopenItem) {
        indexItem = findItemIndex(historyItems);
        reopenItem = historyItems[indexItem];
      }

      if (taskError?.length > 0 && taskError == 'Join request processed') {
        toast.success(taskError);
      }
      setLoadingFalse();
      if (!reopenItem?.notifActionType?.includes('circle')) {
        setDetailActivityItem(reopenItem);
      } else {
        unsetOpenActivityItem();
      }
    };

    if (taskEnded) {
      reloadActivityItems();
    }
  }, [taskEnded]);

  useEffect(() => {
    if (ws) {
      ws.send(JSON.stringify({
        action: 'notifications',
      }));
    }
  }, [ws]);

  const goToSearch = (value?: string) => {
    let newVal = '';

    switch (value?.toLowerCase()) {
      case 'purchase':
      case 'compras':
        newVal = 'buy';
        break;
      case 'circles':
      case 'circulos':
        newVal = 'circles';
        break;
      case 'lend':
      case 'rentas recibidas':
        newVal = 'lend';
        break;
      case 'rent':
      case 'rentas hechas':
        newVal = 'rent';
        break;
      default:
        newVal = value || '';
        break;
    }

    const newValue = value?.toLowerCase() == newVal ? newVal : value;
    if (newValue) {
      filterNotifs(newVal);
      filterHistoryNotifs(newVal);
      setSearch(newVal);
    } else {
      filterNotifs('');
      filterHistoryNotifs('');
      setSearch('');
    }
    setVisibleSuggestion(false);
  };

  const _openItemActivityDetail = (item: any) => {
    setReadNotificationIds((prevIds) => [...prevIds, item.idDetLndUserNotification]);

    history.push('/activity/detail');

    switch (item.notifActionType) {
      case 'new_rental':
      case 'new_buy':
      case 'approve':
      case 'approved':
      case 'rejected':
      case 'pickup':
      case 'dropoff':
      case 'payment_processed':
      case 'review':
      case 'cancelled':
      case 'support':
      case 'approve_buy':
      case 'message':
      case 'verified_failed':
      case 'verified':
      case 'verifypending':
      case 'verified_ok':
      case 'driver_assigned':
      case 'en_route_to_pickup':
      case 'pickup_confirmed':
      case 'en_route_to_delivery':
      case 'at_pickup':
      case 'at_delivery':
      case 'delivery_confirmed':
      case 'delivery_details_added':
      case 'delivery_attempted':
      case 'returned':
      case 'canceled':
      case 'driver_approaching_pickup':
      case 'driver_approaching_delivery':
      case 'scheduled':
      case 'assigned':
      case 'en_route':
      case 'delivered':
      case 'attempted':
      case 'reminder':
      case 'expired':
      case 'extend_reservation_pending':
      case 'extend_reservation_approved':
      case 'extend_reservation_denied':
      case 'extend_reservation_canceled':
        if (item.notifType == 'IDV') {
          if (item.notifActionType == 'verified_failed'
            || item.notifActionType == 'verified'
            || item.notifActionType == 'verifypending'
            || item.notifActionType == 'verified_ok') {
            if (item.readType == 'unread') {
              setActionNotifOpened(item, true);
            }
          }
        } else {
          localStorage.setItem('item', JSON.stringify(item));
          setDetailActivityItem(item);
        }
        break;
      case 'joincirclerequest':
        if (item.readType == 'unread') {
          getActivityJoinCircleExtraInfo(item);
        }
        break;
      case 'circleInvitation':
        if (item.readType == 'unread') {
          getActivityCircleInvitation(item);
        }
        break;
      case 'joincirclerequest_approved':
        if (item.readType == 'unread') {
          setActionNotifOpened(item, true);
        }
        break;
      case 'joincirclerequest_denied':
        if (item.readType == 'unread') {
          setActionNotifOpened(item, true);
        }
        setDetailActivityItem(item);
        break;
      case 'circle_promoted_owner':
        if (item.readType == 'unread') {
          setActionNotifOpened(item, true);
        }
        break;
      case 'circle_promoted_admin':
        if (item.readType == 'unread') {
          setActionNotifOpened(item, true);
        }
        break;
      case 'circle_member_blcked':
        if (item.readType == 'unread') {
          setActionNotifOpened(item, true);
        }
        break;
      case 'circle_item_blocked':
        if (item.readType == 'unread') {
          setActionNotifOpened(item);
        }
        break;
      case 'circle_item_unblocked':
        if (item.readType == 'unread') {
          setActionNotifOpened(item);
        }
        localStorage.setItem('circleIdActivity', item.idCatLndCircle.toString());
        history.push('/circles');
        break;
      case 'circle_promoted_user_admin':
        if (item.readType == 'unread') {
          setActionNotifOpened(item);
        }
        break;
      case 'circle_member_admin_blcked':
        if (item.readType == 'unread') {
          setActionNotifOpened(item, true);
        }
        break;
      case 'circle_downgrade_admin':
        if (item.readType == 'unread') {
          setActionNotifOpened(item, true);
        }
        break;
      case 'circle_downgrade_owner':
        if (item.readType == 'unread') {
          setActionNotifOpened(item, true);
        }
        break;
      case 'circle_owner_earning':
        setDetailActivityItem(item);
        getActivityJoinCircleExtraInfo(item);
        if (item.readType == 'unread') {
          setActionNotifOpened(item);
        }
        break;
      default:
        break;
    }
  };

  function ActiveChat(data: any) {
    let isActive = true;
    if (data.notifActionType == 'approve' || data.notifActionType == 'new_rental') {
      isActive = false;
    }
    return isActive;
  }

  const { t } = useTranslation('global');

  const [visibleNotifications, setVisibleNotifications] = useState(10);
  const [visibleHistoryNotifications, setVisibleHistoryNotifications] = useState(10);

  const handleShowMoreNotifications = () => {
    setVisibleNotifications(visibleNotifications + 10);
  };

  const handleShowMoreHistoryNotifications = () => {
    setVisibleHistoryNotifications(visibleHistoryNotifications + 10);
  };

  const renderedNotifications = activityList?.slice(0, visibleNotifications);
  const renderedHistoryNotifications = activityHistoryList?.slice(0, visibleHistoryNotifications);

  return (
    <Box sx={ActivityListStyle}>
      <Box p={2}>
        <FormControl
          onChange={(e: any) => setSearch(e.target.value)}
          onKeyDown={(e: any) => (e.keyCode == 13
            ? goToSearch(e.target.value)
            : '')}
          className="lmiw-activity-search"
        >
          <OutlinedInput
            inputProps={{
              maxLength: 50,
            }}
            value={search}
            fullWidth
            onFocus={() => {
              setVisibleSuggestion(true);
            }}
            onBlur={() => {
              setTimeout(() => {
                setVisibleSuggestion(false);
              }, 200);
            }}
            startAdornment={(
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )}
            endAdornment={(
              search.length > 0 && (
                <InputAdornment position="start">

                  <IconButton
                    color="primary"
                    onClick={() => {
                      goToSearch('');
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                </InputAdornment>
              )
            )}
            placeholder={t('activity_list.search')}
          />
          {search && visibleSuggestion ? (
            <SearchSuggestion
              kTop={55}
              kInputText={search}
              kOnClick={(text: string) => goToSearch(text)}
            />
          )
            : ''}
          {!search && visibleSuggestion
            ? (
              <FiltersSuggestion
                kTop={55}
                kOnClick={goToSearch}
              />
            )
            : ''}

        </FormControl>
      </Box>

      <Box className="lmi-tabpanel" sx={{ display: props.kValueTab === '1' ? 'block' : 'none' }}>
        {loading && activityList?.length == 0 ? (
          <SequentialSkeletons />
        ) : (
          <>
            {(activityList?.length == 0) ? (
              <Box sx={{
                height: { xs: 'auto', md: 'calc(100vh - 240px)' },
                minHeight: { xs: 'calc(100vh - 65px)', md: '500px' },
              }}
              >
                <EmptyState
                  kImage={empty_lend}
                  kTitle={t('activity_list.all_set')}
                  kDescription={t('activity_list.no_activity')}
                  kHeightAuto
                  kAction={(
                    <Button
                      onClick={() => history.push('/')}
                      disableElevation
                      variant="contained"
                      size="large"
                      sx={{
                        fontSize: 'subtitle1.fontSize',
                        textTransform: 'none',
                        fontWeight: 'bold',
                        height: 56,
                        borderRadius: 3,
                      }}
                    >
                      {t('activity_list.start_exploring')}
                    </Button>
                  )}
                />
              </Box>
            )
              : (
                <>
                  <List
                    disablePadding
                    sx={{
                      width: '100%',
                      bgcolor: 'background.paper',
                      li: {
                        borderTop: `1px solid ${LmiTheme.light.systemBackground.solid}`,
                        'li:lastChild': {
                          borderBottom: `1px solid ${LmiTheme.light.systemBackground.solid}`,
                        },
                      },
                    }}
                  >
                    {
                      renderedNotifications && renderedNotifications.length > 0
                        ? renderedNotifications.map((item: any) => (
                          <ItemList
                            key={item.idDetLndUserNotification}
                            kIsActive
                            kOpenDetail={() => { _openItemActivityDetail(item); }}
                            kIsItemActive={item.idDetLndUserNotification == openedActivityItem?.idDetLndUserNotification}
                            kOpenChat={() => props.kOpenChat()}
                            kItemStatus={item.notifActionType}
                            kItemType={item.notifType}
                            kTitle="Driver headed to pickup location"
                            kDesc={item.name}
                            kUserName="Jorge Lopez"
                            kTime="1 Hour Ago"
                            kIsUnread={!readNotificationIds.includes(item.idDetLndUserNotification) && item.readType != 'read'}
                            kIsActiveChat={ActiveChat(item)}
                            kActivityItem={item}
                            kImage={item?.notifAttachment}
                          />
                        )) : null
                    }
                  </List>
                  {visibleNotifications < activityList.length && activityList.length >= 10 && (
                    <Button
                      onClick={handleShowMoreNotifications}
                      sx={{
                        display: 'block',
                        margin: '0 auto',
                        borderRadius: '10px',
                        textTransform: 'none',
                      }}
                    >
                      {t('activity_list.view_more')}
                    </Button>
                  )}
                </>
              )}
          </>
        )}
      </Box>
      <Box className="lmi-tabpanel" sx={{ display: props.kValueTab === '2' ? 'block' : 'none' }}>
        {loading && activityHistoryList?.length == 0 ? (
          <Box sx={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '500px',
          }}
          >
            <LoadingLayout />
          </Box>
        ) : (
          <>
            {(!activityHistoryList || activityHistoryList?.length == 0) ? (
              <Box sx={{
                height: { xs: 'auto', md: 'calc(100vh - 240px)' },
                minHeight: { xs: 'calc(100vh - 65px)', md: '500px' },
              }}
              >
                <EmptyState
                  kImage={empty_lend}
                  kTitle={t('activity_list.all_set')}
                  kDescription={t('activity_list.no_activity')}
                  kAction={(
                    <Button
                      onClick={() => history.push('/')}
                      disableElevation
                      variant="contained"
                      size="large"
                      sx={{
                        fontSize: 'subtitle1.fontSize',
                        textTransform: 'none',
                        fontWeight: 'bold',
                        height: 56,
                        borderRadius: 3,
                      }}
                    >
                      {t('activity_list.start_exploring')}
                    </Button>
                  )}
                />
              </Box>
            )
              : (
                <>
                  <List
                    disablePadding
                    sx={{
                      width: '100%',
                      bgcolor: 'background.paper',
                      li: {
                        borderTop: `1px solid ${LmiTheme.light.systemBackground.solid}`,
                        'li:lastChild': {
                          borderBottom: `1px solid ${LmiTheme.light.systemBackground.solid}`,
                        },
                      },
                    }}
                  >
                    {
                      renderedHistoryNotifications && renderedHistoryNotifications.length > 0
                        ? renderedHistoryNotifications.map((item: any) => (
                          <ItemList
                            key={item.idDetLndUserNotification}
                            kIsActive
                            kOpenDetail={() => _openItemActivityDetail(item)}
                            kIsItemActive={item.idDetLndUserNotification == openedActivityItem?.idDetLndUserNotification}
                            kOpenChat={() => props.kOpenChat()}
                            kItemStatus={item.notifActionType}
                            kItemType={item.notifType}
                            kTitle=""
                            kDesc={item.name}
                            kUserName=""
                            kTime=""
                            kIsUnread={false}
                            kIsActiveChat={ActiveChat(item)}
                            kActivityItem={item}
                            kImage={item?.notifAttachment}
                          />
                        )) : null
                    }
                  </List>
                  {visibleHistoryNotifications < activityHistoryList.length && activityHistoryList.length >= 10 && (
                    <Button
                      onClick={handleShowMoreHistoryNotifications}
                      sx={{
                        display: 'block',
                        margin: '0 auto',
                        borderRadius: '10px',
                        textTransform: 'none',
                      }}
                    >
                      {t('activity_list.view_more')}
                    </Button>
                  )}
                </>
              )}

          </>
        )}
      </Box>

    </Box>

  );
}

import {
  Box, Typography, Paper, Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CardMastercard, CardVisa, SectionTitle } from '../../../../../../components/Common';
import ActivityPaymentStyle from './ActivityPayment.style';

export default function ActivityPayment({ paymentMethod }: any) {
  const { t } = useTranslation('global');
  return (
    <Box sx={ActivityPaymentStyle}>
      <SectionTitle
        kMarginTop={2}
        kTitle={t('activity.payment.method')}
        kFontSize={18}
      />
      <Paper
        className="lmiw-activity-payment"
        variant="outlined"
        elevation={0}
        sx={{ p: 1, textAlign: 'center' }}
      >

        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="lmiw-payment-concept"
          spacing={2}
          sx={{ px: 2 }}
        >
          { (paymentMethod.brand == 'Visa') && <CardVisa />}
          { (paymentMethod.brand == 'mastercard') && <CardMastercard />}
          <Typography>
            { paymentMethod?.brand }
            {' '}
          </Typography>
          <Typography>•••• •••• ••••</Typography>
          <Typography>{ paymentMethod?.last4 }</Typography>
        </Stack>

      </Paper>

    </Box>

  );
}

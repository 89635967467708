/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-lines */
import React, { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { enUS, es } from 'date-fns/locale';
import cookies from 'js-cookie';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';
import Compressor from 'compressorjs';
import { FileUploader } from 'react-drag-drop-files';
import { AddCircleRounded, CancelOutlined } from '@mui/icons-material';
import { CardStripe, LmiTheme } from '../..';
import payoutAccountContext from '../../../../../../context/payoutAccount/payoutAccountContext';
import {
  TextCardNumber, TextCVCMask, TextExpireDate, TextMaskPhone,
} from '../../../../../../utils/inputsFormats';
import mexicanStates from '../../../../../../data/mx_states.json';
import LmiColorPalette from '../../../../../../themes/lmi-mui-theme/lmi-color-palette';

function NewIndOrBusPayout(props: any) {
  const pPayoutContext = useContext(payoutAccountContext);
  const {
    payoutAccountDetails,
    updateExistingPayoutAccountDetails,
    checkPayoutValidate,
    updatePayoutValidationStatus,
  }: any = pPayoutContext;

  const [inputDate, setInputDate] = useState(payoutAccountDetails?.dateOfBirth ? new Date(payoutAccountDetails.dateOfBirth) : null);

  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [isBirthDateValid, setIsBirthDateValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isSsnValid, setIsSsnValid] = useState(true);
  const [isCVVValid, setIsCVVValid] = useState(true);
  const [isRfcValid, setIsRfcValid] = useState(true);
  const [isCityValid, setIsCityValid] = useState(true);
  const [isAddressValid, setIsAddressValid] = useState(true);
  const [isExtNumValid, setIsExtNumValid] = useState(true);
  const [isZipCodeValid, setIsZipCodeValid] = useState(true);
  const [isCardNumberValid, setIsCardNumberValid] = useState(true);
  const [isExpDateValid, setIsExpDateValid] = useState(true);
  const [isCompanyNameValid, setIsCompanyNameValid] = useState(true);
  const [isTaxIdValid, setIsTaxIdValid] = useState(true);
  const [isBankAccountMxValid, setIsBankAccountMxValid] = useState(true);
  const [isAccountNumberValid, setIsAccountNumberValid] = useState(true);
  const [isRoutingNumberValid, setIsRoutingNumberValid] = useState(true);
  const [validationIne, setValidationIne] = useState(true);
  const [selectedMxState, setSelectedMxState] = useState('AGS');
  const [isCompanyEmailValid, setIsCompanyEmailValid] = useState(true);
  const [isCompanyRfcValid, setIsCompanyRfcValid] = useState(true);
  const [isOwnerFirstNameValid, setIsOwnerFirstNameValid] = useState(true);
  const [isOwnerLastNameValid, setIsOwnerLastNameValid] = useState(true);
  const [isCompanyOwnerRfcValid, setIsCompanyOwnerRfcValid] = useState(true);
  const [isOwnerPhoneNumberValid, setIsOwnerPhoneNumberValid] = useState(true);
  const [isCompanyStreetValid, setIsCompanyStreetValid] = useState(true);
  const [isCompanyCityValid, setIsCompanyCityValid] = useState(true);
  const [isCompanyExtNumValid, setIsCompanyExtNumValid] = useState(true);
  const [isCompanyZipCodeValid, setIsCompanyZipCodeValid] = useState(true);
  const [isAccountHolderFirstNameValid, setIsAccountHolderFirstNameValid] = useState(true);
  const [isAccountHolderLastNameValid, setIsAccountHolderLastNameValid] = useState(true);

  const fileTypes = [
    'JPG', 'JPEG',
    'PNG', 'WEBP',
    'BMP', 'TIFF',
  ];

  const onImageDropped = (e: any, ineSide: String) => {
    Array.from(e).forEach((file: any) => {
      // eslint-disable-next-line no-new
      new Compressor(file, {
        convertTypes: [
          'image/png',
          'image/webp',
          'image/bmp',
          'image/tiff',
        ],
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        convertSize: 1000,
        maxWidth: 4096,
        maxHeight: 4096,
        minWidth: 1080,
        minHeight: 1920,
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          const reader = new FileReader();
          reader.onloadend = () => {
            updateExistingPayoutAccountDetails({
              ...payoutAccountDetails,
              [`${ineSide}`]: reader.result as string,
            });
          };
          reader.readAsDataURL(compressedResult);
        },
      });
    });
  };

  const firstNameRef = React.useRef(null);
  const lastNameRef = React.useRef(null);
  const emailRef = React.useRef(null);
  const dateOfBirthRef = React.useRef(null);
  const ssnRef = React.useRef(null);
  const companyNameRef = React.useRef(null);
  const taxIdRef = React.useRef(null);
  const rfcRef = React.useRef(null);
  const cityRef = React.useRef(null);
  const addressRef = React.useRef(null);
  const extNumRef = React.useRef(null);
  const zipCodeRef = React.useRef(null);
  const bankAccountRef = React.useRef(null);
  const cvvRef = React.useRef(null);
  const cardNumberRef = React.useRef(null);
  const intNumRef = React.useRef(null);
  const ineRef = React.useRef(null);
  const expDateRef = React.useRef(null);
  const accountNumberRef = React.useRef(null);
  const routingNumberRef = React.useRef(null);
  const companyRfcRef = React.useRef(null);
  const companyEmailRef = React.useRef(null);
  const ownerFirstNameRef = React.useRef(null);
  const ownerLastNameRef = React.useRef(null);
  const companyOwnerRfcRef = React.useRef(null);
  const ownerPhoneNumberRef = React.useRef(null);
  const companyAddressRef = React.useRef(null);
  const companyCityRef = React.useRef(null);
  const companyExtNumRef = React.useRef(null);
  const companyIntNumRef = React.useRef(null);
  const companyZipCodeRef = React.useRef(null);
  const accountHolderLastNameRef = React.useRef(null);
  const accountHolderFirstNameRef = React.useRef(null);

  function emailValidation(emailV: any) {
    // eslint-disable-next-line
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!emailV || regex.test(emailV) === false) {
      return false;
    }
    return true;
  }
  function removeBackgroundImageFront() {
    updateExistingPayoutAccountDetails({
      ...payoutAccountDetails,
      frontIne: '',

    });
  }
  function removeBackgroundImageBack() {
    updateExistingPayoutAccountDetails({
      ...payoutAccountDetails,
      backIne: '',

    });
  }
  useEffect(() => {
    let isEverythingOk = true;
    if (checkPayoutValidate) {
      const invalidFields: any[] = [];
      const validateFieldLength = (field: any, length: any, setter: any, ref: any) => {
        const isValid = field?.length >= length;
        setter(isValid);
        if (!isValid && ref?.current != null) {
          isEverythingOk = false;
          invalidFields.push(ref);
        }
        return isValid;
      };
      validateFieldLength(payoutAccountDetails.firstName, 1, setIsFirstNameValid, firstNameRef);
      validateFieldLength(payoutAccountDetails.lastName, 1, setIsLastNameValid, lastNameRef);

      if (payoutAccountDetails.isIndividual) {
        validateFieldLength(payoutAccountDetails.dateOfBirth, 1, setIsBirthDateValid, dateOfBirthRef);
      } else {
        validateFieldLength(payoutAccountDetails.companyName, 1, setIsCompanyNameValid, companyNameRef);
        validateFieldLength(payoutAccountDetails.taxId, 1, setIsTaxIdValid, taxIdRef);
      }
      // isEverythingOk = !(isFirstNameValid && isLastNameValid && isEmailValid);
      // isEverythingOk = !(!isFirstNameValid || !isLastNameValid || !isEmailValid);
      if (payoutAccountDetails.accId == '') {
        if (payoutAccountDetails.country === 'us') {
          const usValidations = [
            () => {
              const isValid = !(payoutAccountDetails.isIndividual && payoutAccountDetails.ssn.length === 0);
              setIsSsnValid(isValid);
              if (!isValid) {
                invalidFields.push(ssnRef);
              }
              return isValid;
            },
            () => {
              const isValidAccountNumber = !(valueDebitcardOrBankaccount !== 'debitcard' && payoutAccountDetails.accountNumber.length < 5);
              setIsAccountNumberValid(isValidAccountNumber);
              if (!isValidAccountNumber) {
                invalidFields.push(accountNumberRef);
              }

              const isValidRoutingNumber = !(valueDebitcardOrBankaccount !== 'debitcard' && payoutAccountDetails.routingNumber.length < 5);
              setIsRoutingNumberValid(isValidRoutingNumber);
              if (!isValidRoutingNumber) {
                invalidFields.push(routingNumberRef);
              }

              return isValidAccountNumber && isValidRoutingNumber;
            },
          ];
          isEverythingOk = usValidations.every((validation) => validation());
        }

        if (payoutAccountDetails.country === 'mx' && payoutAccountDetails.isIndividual) {
          const validateField = (field: any, minLength: number, setter: Function, ref: React.RefObject<HTMLElement>) => {
            const isValid = field.length >= minLength;
            setter(isValid);
            if (!isValid) {
              isEverythingOk = false;
              invalidFields.push(ref);
            }
            return isValid;
          };

          const fieldValidations = [
            {
              field: payoutAccountDetails.rfc, minLength: 13, setter: setIsRfcValid, ref: rfcRef,
            },
            {
              field: payoutAccountDetails.city, minLength: 4, setter: setIsCityValid, ref: cityRef,
            },
            {
              field: payoutAccountDetails.address, minLength: 4, setter: setIsAddressValid, ref: addressRef,
            },
            {
              field: payoutAccountDetails.extNumber, minLength: 1, setter: setIsExtNumValid, ref: extNumRef,
            },
            {
              field: payoutAccountDetails.zipCode, minLength: 1, setter: setIsZipCodeValid, ref: zipCodeRef,
            },
            {
              field: payoutAccountDetails.bankAccountMx, minLength: 6, setter: setIsBankAccountMxValid, ref: bankAccountRef,
            },
          ];

          fieldValidations.forEach((validation) => validateField(validation.field, validation.minLength, validation.setter, validation.ref));

          const isValidIne = payoutAccountDetails?.frontIne && payoutAccountDetails?.backIne;
          setValidationIne(isValidIne);
          if (!isValidIne) {
            invalidFields.push(ineRef);
            toast.warning(t('payout_info.ine_required'));
          }
        }

        if (payoutAccountDetails.country === 'mx' && !payoutAccountDetails.isIndividual) {
          const validateField = (field: any, minLength: number, setter: Function, ref: React.RefObject<HTMLElement>) => {
            let isValid = field.length >= minLength;
            if (ref == companyEmailRef) {
              isValid = emailValidation(field);
            }
            setter(isValid);
            if (!isValid) {
              isEverythingOk = false;
              invalidFields.push(ref);
            }
            return isValid;
          };
          const fieldValidationsZea = [
            {
              field: payoutAccountDetails.companyRfc, minLength: 13, setter: setIsCompanyRfcValid, ref: companyRfcRef,
            },
            {
              field: payoutAccountDetails.companyEmail, minLength: 5, setter: setIsCompanyEmailValid, ref: companyEmailRef,
            },
            {
              field: payoutAccountDetails.ownerPhoneNumber, minLength: 6, setter: setIsCompanyEmailValid, ref: ownerPhoneNumberRef,
            },
            {
              field: payoutAccountDetails.companyOwnerRfc, minLength: 13, setter: setIsCompanyOwnerRfcValid, ref: companyOwnerRfcRef,
            },
            {
              field: payoutAccountDetails.companyOwnerRfc, minLength: 10, setter: setIsOwnerPhoneNumberValid, ref: companyOwnerRfcRef,
            },
            {
              field: payoutAccountDetails.companyCity, minLength: 4, setter: setIsCompanyCityValid, ref: companyCityRef,
            },
            {
              field: payoutAccountDetails.companyAddress, minLength: 4, setter: setIsCompanyStreetValid, ref: companyAddressRef,
            },
            {
              field: payoutAccountDetails.ownerFirstName, minLength: 3, setter: setIsOwnerFirstNameValid, ref: ownerFirstNameRef,
            },
            {
              field: payoutAccountDetails.ownerLastName, minLength: 3, setter: setIsOwnerLastNameValid, ref: ownerLastNameRef,
            },
            {
              field: payoutAccountDetails.companyExtNumber, minLength: 1, setter: setIsCompanyExtNumValid, ref: companyExtNumRef,
            },
            {
              field: payoutAccountDetails.companyZipCode, minLength: 1, setter: setIsCompanyZipCodeValid, ref: companyZipCodeRef,
            },
            {
              field: payoutAccountDetails.accountHolderLastNameMx, minLength: 3, setter: setIsAccountHolderLastNameValid, ref: accountHolderLastNameRef,
            },
            {
              field: payoutAccountDetails.accountHolderNameMx, minLength: 6, setter: setIsAccountHolderFirstNameValid, ref: accountHolderFirstNameRef,
            },
            {
              field: payoutAccountDetails.bankAccountMx, minLength: 6, setter: setIsBankAccountMxValid, ref: bankAccountRef,
            },
          ];

          fieldValidationsZea.forEach((validation) => validateField(validation.field, validation.minLength, validation.setter, validation.ref));

          const isValidIne = payoutAccountDetails?.frontIne && payoutAccountDetails?.backIne;
          setValidationIne(isValidIne);
          if (!isValidIne) {
            invalidFields.push(ineRef);
            toast.warning(t('payout_info.ine_required'));
          }
        }

        const validateField = (field: any, length: any) => (!(field.length < length));
        if (valueDebitcardOrBankaccount === 'debitcard' && payoutAccountDetails.country !== 'mx') {
          isEverythingOk = validateField(payoutAccountDetails.cardNumber, 19);
          setIsCardNumberValid(isEverythingOk);
          isEverythingOk = validateField(payoutAccountDetails.expiration, 5);
          setIsExpDateValid(isEverythingOk);

          isEverythingOk = validateField(payoutAccountDetails.cvv, 3);
          setIsCVVValid(isEverythingOk);
        } else {
          setIsCardNumberValid(true);
          setIsExpDateValid(true);
          setIsCVVValid(true);
        }
      }
      validateFieldLength(payoutAccountDetails.email, 1, setIsEmailValid, emailRef);
      updatePayoutValidationStatus(isEverythingOk);
      if (invalidFields.length > 0) {
        invalidFields[0].current?.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [checkPayoutValidate]);

  useEffect(() => {
    changeIndividualOrBussines('', 'individual');
  }, [payoutAccountDetails.country]);

  const [selectedIndividualOrBussines, setSelectedIndividualOrBussines] = useState('individual');
  const changeIndividualOrBussines = (
    _event: any,
    newSelectedIndividualOrBussines: string,
  ) => {
    if (newSelectedIndividualOrBussines !== null) {
      setSelectedIndividualOrBussines(newSelectedIndividualOrBussines);
      if (newSelectedIndividualOrBussines == 'individual') {
        updateExistingPayoutAccountDetails({
          ...payoutAccountDetails,
          isIndividual: true,
          isBusiness: false,
        });
      } else {
        updateExistingPayoutAccountDetails({
          ...payoutAccountDetails,
          isBusiness: true,
          isIndividual: false,
        });
      }
    }
  };

  const validateInput = (target: any) => {
    const { name, value } = target;
    let newValue = value;

    if (name === 'rfc' || name === 'companyRfc' || name === 'companyOwnerRfc') {
      newValue = value?.toUpperCase();
    }
    if (name === 'zipCode' || name === 'companyzipCode') {
      newValue = value.replace(/\D/g, '').substring(0, 5);
    }

    if (name === 'bankAccountMx') {
      const val = value.replace(/\s/g, '');
      newValue = val;
    }

    if (name === 'extNumber' || name === 'intNumber' || name === 'companyExtNumber' || name === 'companyIntNumber') {
      const onlyNumber = value.replace(/\D/g, '');
      newValue = onlyNumber;
    }
    updateExistingPayoutAccountDetails({
      ...payoutAccountDetails,
      [name]: newValue,
    });
  };

  const handleInputchange = ({ target }: any) => {
    validateInput(target);
  };

  const limitFullName = 45;

  const [valueDebitcardOrBankaccount, setValueDebitcardOrBankaccount] = React.useState('debitcard');
  const changeDebitcardOrBankaccount = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueDebitcardOrBankaccount((event.target as HTMLInputElement).value);
    if ((event.target as HTMLInputElement).value == 'debitcard') {
      updateExistingPayoutAccountDetails({
        ...payoutAccountDetails,
        isBankAccount: false,
      });
    } else {
      updateExistingPayoutAccountDetails({
        ...payoutAccountDetails,
        isBankAccount: true,
      });
    }
  };
  const updateCountry = (e: any) => {
    updateExistingPayoutAccountDetails({
      ...payoutAccountDetails,
      country: e.target.value,
    });
  };

  const updateMxState = (e: any) => {
    setSelectedMxState(e.target.value);
    updateExistingPayoutAccountDetails({
      ...payoutAccountDetails,
      state: e.target.value,
    });
  };

  const { t } = useTranslation('global');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const expiration = payoutAccountDetails?.expiration;
  const formattedExpiration = expiration.length === 4 && !expiration.startsWith('1') ? `0${expiration}` : expiration;

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Typography py={2} component="span" textAlign="center" color={LmiTheme.light.systemBackground.tertiary}>{t('payout_info.payment_secure')}</Typography>
        <CardStripe width={56} height={56} style={{ marginRight: 30 }} />
      </Stack>
      <Box sx={{ p: 1, fontWeight: 'bold' }}>
        {t('payout_info.payout_information')}
      </Box>
      <FormControl sx={{ mb: 2 }} fullWidth>
        <Select
          disabled={false}
          value={payoutAccountDetails.country}
          onChange={updateCountry}
          sx={{ bgcolor: LmiTheme.light.systemBackground.base }}
        >
          <MenuItem value="us">USA</MenuItem>
          <MenuItem value="mx">México</MenuItem>
        </Select>
      </FormControl>
      <Paper variant="outlined" sx={{ p: 2, borderRadius: '10px' }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={1}
          component="form"
          sx={{
            '& > :not(style)': { p: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        >
          {(payoutAccountDetails.accId == '') ? (
            <Grid item xs={12}>
              <ToggleButtonGroup
                onChange={changeIndividualOrBussines}
                value={selectedIndividualOrBussines}
                color="primary"
                fullWidth
                exclusive
              >
                <ToggleButton
                  disableFocusRipple
                  disableRipple
                  value="individual"
                >
                  {t('payout_info.individual')}
                </ToggleButton>
                <ToggleButton
                  disableFocusRipple
                  disableRipple
                  value="business"
                >
                  {t('payout_info.business')}
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          ) : (
            <Box>
              {payoutAccountDetails.isIndividual ? <Typography component="span" fontWeight={500}>{t('payout_info.individual_account')}</Typography>
                : <Typography component="span" fontWeight={500}>{t('payout_info.business_account')}</Typography>}
            </Box>
          )}
          { payoutAccountDetails.isIndividual ? (
            <Grid item xs={6}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
                pb={1}
              >
                <Box>
                  <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                  <Typography component="span" fontWeight={500}>{t('payout_info.first_name')}</Typography>
                </Box>
                <Typography component="span" color={LmiTheme.light.systemBackground.tertiary}>
                  {`${payoutAccountDetails.firstName.length}/${limitFullName}`}
                </Typography>
              </Stack>
              <TextField
                fullWidth
                ref={firstNameRef}
                disabled={props.kReadOnly}
                variant="outlined"
                type="text"
                placeholder={t('payout_info.first_name')}
                name="firstName"
                value={payoutAccountDetails?.firstName}
                error={!isFirstNameValid}
                helperText={!isFirstNameValid && `${t('payout_info.invalid_first_name')}`}
                onChange={handleInputchange}
                sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                inputProps={{
                  maxLength: limitFullName,
                }}
                FormHelperTextProps={{
                  style: {
                    backgroundColor: LmiTheme.light.systemBackground.elevation,
                    margin: 0,
                    paddingTop: '3px',
                    paddingRight: '14px',
                    paddingBottom: '0',
                    paddingLeft: '14px',
                  },
                }}
              />
            </Grid>
          ) : <Box />}

          { payoutAccountDetails.isIndividual ? (
            <Grid item xs={6}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
                pb={1}
              >
                <Box>
                  <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                  <Typography component="span" fontWeight={500}>{t('payout_info.last_name')}</Typography>
                </Box>
                <Typography component="span" color={LmiTheme.light.systemBackground.tertiary}>
                  {`${payoutAccountDetails.lastName.length}/${limitFullName}`}
                </Typography>
              </Stack>
              <TextField
                fullWidth
                ref={lastNameRef}
                disabled={props.kReadOnly}
                variant="outlined"
                type="text"
                placeholder={t('payout_info.last_name')}
                name="lastName"
                error={!isLastNameValid}
                helperText={!isLastNameValid && `${t('payout_info.invalid_last_name')}`}
                value={payoutAccountDetails?.lastName}
                onChange={handleInputchange}
                sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                inputProps={{
                  maxLength: limitFullName,
                }}
                FormHelperTextProps={{
                  style: {
                    backgroundColor: LmiTheme.light.systemBackground.elevation,
                    margin: 0,
                    paddingTop: '3px',
                    paddingRight: '14px',
                    paddingBottom: '0',
                    paddingLeft: '14px',
                  },
                }}
              />
            </Grid>
          ) : <Box />}
          <Grid item p={0} m={0} xs={12}>
            <Box my={0}>
              <Accordion disableGutters elevation={0} expanded={selectedIndividualOrBussines !== 'individual' ?? false}>
                <AccordionSummary sx={{ display: 'none', padding: 0 }}>
                  <></>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <Grid
                    spacing={2}
                    container
                    rowSpacing={2}
                    columnSpacing={0}
                    component="form"
                    sx={{
                      '& > :not(style)': { width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    {(!payoutAccountDetails.isIndividual) && (
                      <Grid item xs={12}>
                        <Box pb={1}>
                          <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                          <Typography component="span" fontWeight={500}>{t('payout_info.company_name')}</Typography>
                        </Box>
                        <TextField
                          fullWidth
                          ref={companyNameRef}
                          variant="outlined"
                          type="text"
                          error={!isCompanyNameValid}
                          helperText={!isCompanyNameValid && `${t('payout_info.invalid_company_name')}`}
                          placeholder={t('payout_info.your_company_name')}
                          name="companyName"
                          onChange={handleInputchange}
                          value={payoutAccountDetails?.companyName}
                          // value={valuesFullName.name}
                          sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                          FormHelperTextProps={{
                            style: {
                              backgroundColor: LmiTheme.light.systemBackground.elevation,
                              margin: 0,
                              paddingTop: '3px',
                              paddingRight: '14px',
                              paddingBottom: '0',
                              paddingLeft: '14px',
                            },
                          }}
                        />
                      </Grid>
                    )}
                    {(!payoutAccountDetails.isIndividual && payoutAccountDetails.country == 'mx') && (
                      <Grid item xs={12}>
                        <Box pb={1}>
                          <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                          <Typography component="span" fontWeight={500}>{t('payout_info.company_email')}</Typography>
                        </Box>
                        <TextField
                          fullWidth
                          ref={companyEmailRef}
                          variant="outlined"
                          type="text"
                          error={!isCompanyEmailValid}
                          helperText={!isCompanyEmailValid && `${t('payout_info.invalid_email')}`}
                          placeholder={t('payout_info.your_company_email')}
                          name="companyEmail"
                          onChange={handleInputchange}
                          value={payoutAccountDetails?.companyEmail}
                          // value={valuesFullName.name}
                          sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                          FormHelperTextProps={{
                            style: {
                              backgroundColor: LmiTheme.light.systemBackground.elevation,
                              margin: 0,
                              paddingTop: '3px',
                              paddingRight: '14px',
                              paddingBottom: '0',
                              paddingLeft: '14px',
                            },
                          }}
                        />
                      </Grid>
                    )}

                    {(!payoutAccountDetails.isIndividual && payoutAccountDetails.country == 'mx') && (
                    <Grid item xs={12}>
                      <Box pb={1}>
                        <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                        <Typography component="span" fontWeight={500}>{t('payout_info.company_rfc')}</Typography>
                      </Box>
                      <TextField
                        fullWidth
                        ref={companyRfcRef}
                        variant="outlined"
                        type="text"
                        error={!isCompanyRfcValid}
                        helperText={!isCompanyRfcValid && `${t('payout_info.invalid_rfc')}`}
                        placeholder={t('payout_info.your_company_rfc')}
                        name="companyRfc"
                        onChange={handleInputchange}
                        value={payoutAccountDetails?.companyRfc}
                        inputProps={{
                          inputMode: 'text',
                          maxLength: 13,
                          style: { textAlign: 'center' },
                        }}
                          // value={valuesFullName.name}
                        sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                        FormHelperTextProps={{
                          style: {
                            backgroundColor: LmiTheme.light.systemBackground.elevation,
                            margin: 0,
                            paddingTop: '3px',
                            paddingRight: '14px',
                            paddingBottom: '0',
                            paddingLeft: '14px',
                          },
                        }}
                      />
                    </Grid>
                    )}
                    { (!payoutAccountDetails.isIndividual && payoutAccountDetails.country == 'mx') ? (
                      <Grid item xs={6}>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={1}
                          pb={1}
                        >
                          <Box>
                            <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                            <Typography component="span" fontWeight={500}>{t('payout_info.owner_name')}</Typography>
                          </Box>
                          <Typography component="span" color={LmiTheme.light.systemBackground.tertiary}>
                            {`${payoutAccountDetails.ownerFirstName.length}/13`}
                          </Typography>
                        </Stack>
                        <TextField
                          fullWidth
                          ref={ownerFirstNameRef}
                          disabled={props.kReadOnly}
                          variant="outlined"
                          type="text"
                          placeholder={t('payout_info.owner_name_hint')}
                          name="ownerFirstName"
                          value={payoutAccountDetails?.ownerFirstName}
                          error={!isOwnerFirstNameValid}
                          helperText={!isOwnerFirstNameValid && `${t('payout_info.invalid_first_name')}`}
                          onChange={handleInputchange}
                          sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                          inputProps={{
                            maxLength: 13,
                          }}
                          FormHelperTextProps={{
                            style: {
                              backgroundColor: LmiTheme.light.systemBackground.elevation,
                              margin: 0,
                              paddingTop: '3px',
                              paddingRight: '14px',
                              paddingBottom: '0',
                              paddingLeft: '14px',
                            },
                          }}
                        />
                      </Grid>
                    ) : <Box />}
                    { (!payoutAccountDetails.isIndividual && payoutAccountDetails.country == 'mx') ? (
                      <Grid item xs={6}>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={1}
                          pb={1}
                        >
                          <Box>
                            <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                            <Typography component="span" fontWeight={500}>{t('payout_info.owner_last_name')}</Typography>
                          </Box>
                          <Typography component="span" color={LmiTheme.light.systemBackground.tertiary}>
                            {`${payoutAccountDetails.ownerLastName.length}/13`}
                          </Typography>
                        </Stack>
                        <TextField
                          fullWidth
                          ref={ownerLastNameRef}
                          disabled={props.kReadOnly}
                          variant="outlined"
                          type="text"
                          placeholder={t('payout_info.owner_last_name_hint')}
                          name="ownerLastName"
                          value={payoutAccountDetails?.ownerLastName}
                          error={!isOwnerLastNameValid}
                          helperText={!isOwnerLastNameValid && `${t('payout_info.invalid_last_name')}`}
                          onChange={handleInputchange}
                          sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                          inputProps={{
                            maxLength: limitFullName,
                          }}
                          FormHelperTextProps={{
                            style: {
                              backgroundColor: LmiTheme.light.systemBackground.elevation,
                              margin: 0,
                              paddingTop: '3px',
                              paddingRight: '14px',
                              paddingBottom: '0',
                              paddingLeft: '14px',
                            },
                          }}
                        />
                      </Grid>
                    ) : <Box />}
                    {(!payoutAccountDetails.isIndividual && payoutAccountDetails.country == 'mx') && (
                    <Grid item xs={6}>
                      <Box pb={1}>
                        <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                        <Typography component="span" fontWeight={500}>{t('payout_info.company_owner_rfc')}</Typography>
                      </Box>
                      <TextField
                        fullWidth
                        ref={companyOwnerRfcRef}
                        variant="outlined"
                        type="text"
                        error={!isCompanyOwnerRfcValid}
                        helperText={!isCompanyOwnerRfcValid && `${t('payout_info.invalid_rfc')}`}
                        placeholder={t('payout_info.company_owner_rfc_hint')}
                        name="companyOwnerRfc"
                        onChange={handleInputchange}
                        value={payoutAccountDetails?.companyOwnerRfc}
                        inputProps={{
                          inputMode: 'text',
                          maxLength: 13,
                          style: { textAlign: 'center' },
                        }}
                          // value={valuesFullName.name}
                        sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                        FormHelperTextProps={{
                          style: {
                            backgroundColor: LmiTheme.light.systemBackground.elevation,
                            margin: 0,
                            paddingTop: '3px',
                            paddingRight: '14px',
                            paddingBottom: '0',
                            paddingLeft: '14px',
                          },
                        }}
                      />
                    </Grid>
                    )}
                    { (!payoutAccountDetails.isIndividual && payoutAccountDetails.country == 'mx') ? (
                      <Grid item xs={6}>
                        <Box pb={1}>
                          <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                          <Typography component="span" fontWeight={500}>{t('payout_info.date_birth')}</Typography>
                        </Box>
                        <LocalizationProvider locale={(cookies.get('i18next') === 'en') ? enUS : es} dateAdapter={AdapterDateFns}>

                          <DesktopDatePicker
                            disabled={props.kReadOnly}
                            value={inputDate}
                            onChange={(newValue) => {
                              setInputDate(newValue);
                              if (newValue != null) {
                                updateExistingPayoutAccountDetails({
                                  ...payoutAccountDetails,
                                  dateOfBirth: moment(newValue).format('YYYY/MM/DD').toString(),
                                });
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                ref={dateOfBirthRef}
                                error={!isBirthDateValid}
                                helperText={!isBirthDateValid && `${t('payout_info.invalid_date_birth')}`}
                                FormHelperTextProps={{
                                  style: {
                                    backgroundColor: LmiTheme.light.systemBackground.elevation,
                                    margin: 0,
                                    paddingTop: '3px',
                                    paddingRight: '14px',
                                    paddingBottom: '0',
                                    paddingLeft: '14px',
                                  },
                                }}
                                {...params}
                                sx={{ backgroundColor: LmiTheme.light.systemBackground.base, color: 'black', fontSize: '14px' }}
                                onBlur={() => {
                                  updateExistingPayoutAccountDetails({
                                    ...payoutAccountDetails,
                                    dateOfBirth: moment(inputDate).format('YYYY/MM/DD').toString(),
                                  });
                                }}
                              />

                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    ) : <Box />}

                    { (!payoutAccountDetails.isIndividual && payoutAccountDetails.country == 'mx') ? (
                      <Grid item xs={6}>
                        <Box pb={1}>
                          <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                          <Typography component="span" fontWeight={500}>{t('payout_info.owner_phone_number')}</Typography>
                        </Box>
                        <TextField
                          fullWidth
                          ref={ownerPhoneNumberRef}
                          variant="outlined"
                          type="text"
                          name="ownerPhoneNumber"
                          onChange={handleInputchange}
                          value={payoutAccountDetails?.ownerPhoneNumber}
                          error={!isOwnerPhoneNumberValid}
                          helperText={!isOwnerPhoneNumberValid && `${t('payout_info.invalid_phone_humber')}`}
                          placeholder={t('payout_info.owner_phone_number_hint')}
                          sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                          InputProps={{
                            inputComponent: TextMaskPhone as any,
                          }}
                          FormHelperTextProps={{
                            style: {
                              backgroundColor: LmiTheme.light.systemBackground.elevation,
                              margin: 0,
                              paddingTop: '3px',
                              paddingRight: '14px',
                              paddingBottom: '0',
                              paddingLeft: '14px',
                            },
                          }}
                        />
                      </Grid>
                    ) : <Box />}
                    {/* { (!payoutAccountDetails.isIndividual && payoutAccountDetails.country == 'mx') ? (
                      <Grid item xs={6}>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={1}
                          pb={1}
                        >
                          <Box>
                            <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                            <Typography component="span" fontWeight={500}>Company Owner RFC</Typography>
                          </Box>
                          <Typography component="span" color={LmiTheme.light.systemBackground.tertiary}>
                            {`${payoutAccountDetails.lastName.length}/${limitFullName}`}
                          </Typography>
                        </Stack>
                        <TextField
                          fullWidth
                          ref={lastNameRef}
                          disabled={props.kReadOnly}
                          variant="outlined"
                          type="text"
                          placeholder={t('payout_info.last_name')}
                          name="lastName"
                          error={!isLastNameValid}
                          helperText={!isLastNameValid && `${t('payout_info.invalid_last_name')}`}
                          value={payoutAccountDetails?.lastName}
                          onChange={handleInputchange}
                          sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                          inputProps={{
                            maxLength: limitFullName,
                          }}
                          FormHelperTextProps={{
                            style: {
                              backgroundColor: LmiTheme.light.systemBackground.elevation,
                              margin: 0,
                              paddingTop: '3px',
                              paddingRight: '14px',
                              paddingBottom: '0',
                              paddingLeft: '14px',
                            },
                          }}
                        />
                      </Grid>
                    ) : <Box />} */}
                    {(payoutAccountDetails.accId == '' && payoutAccountDetails.country == 'mx') && (
                      <Grid item xs={6}>
                        <Box pb={1}>
                          <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                          <Typography component="span" fontWeight={500}>{t('payout_info.state_territory')}</Typography>
                        </Box>

                        <FormControl fullWidth>
                          <Select
                            disabled={false}
                            value={selectedMxState}
                            onChange={updateMxState}
                            sx={{ bgcolor: LmiTheme.light.systemBackground.base }}
                          >
                            {mexicanStates.map((e) => (<MenuItem value={e.abbreviation}>{e.name}</MenuItem>))}
                            {/* <MenuItem value="MX">México</MenuItem> */}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}

                    { (payoutAccountDetails.accId == '' && payoutAccountDetails.country == 'mx') && (
                    <Grid item xs={6}>
                      <Box pb={1}>
                        <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                        <Typography component="span" fontWeight={500}>{t('payout_info.company_city')}</Typography>
                      </Box>
                      <TextField
                        fullWidth
                        ref={companyCityRef}
                        variant="outlined"
                        type="text"
                        placeholder={t('payout_info.company_city_hint')}
                        sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                        error={!isCompanyCityValid}
                        helperText={!isCompanyCityValid && `${t('payout_info.invalid_city')}`}
                        name="companyCity"
                        value={payoutAccountDetails?.companyCity}
                        onChange={handleInputchange}
                        inputProps={{
                          inputMode: 'text',
                          // maxLength: 4,
                          style: { textAlign: 'center' },
                        }}
                        FormHelperTextProps={{
                          style: {
                            backgroundColor: LmiTheme.light.systemBackground.elevation,
                            margin: 0,
                            paddingTop: '3px',
                            paddingRight: '14px',
                            paddingBottom: '0',
                            paddingLeft: '14px',
                          },
                        }}
                      />
                    </Grid>
                    )}

                    { (payoutAccountDetails.accId == '' && payoutAccountDetails.country == 'mx')
                      && (
                      <Grid item xs={6}>
                        <Box pb={1}>
                          <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                          <Typography component="span" fontWeight={500}>{t('payout_info.company_street')}</Typography>
                        </Box>
                        <TextField
                          fullWidth
                          ref={companyAddressRef}
                          variant="outlined"
                          type="text"
                          placeholder={t('payout_info.company_street_hint')}
                          sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                          error={!isCompanyStreetValid}
                          helperText={!isCompanyStreetValid && `${t('payout_info.invalid_street')}`}
                          name="companyAddress"
                          value={payoutAccountDetails?.companyAddress}
                          onChange={handleInputchange}
                          inputProps={{
                            inputMode: 'text',
                            // maxLength: 4,
                            style: { textAlign: 'center' },
                          }}
                          FormHelperTextProps={{
                            style: {
                              backgroundColor: LmiTheme.light.systemBackground.elevation,
                              margin: 0,
                              paddingTop: '3px',
                              paddingRight: '14px',
                              paddingBottom: '0',
                              paddingLeft: '14px',
                            },
                          }}
                        />
                      </Grid>
                      )}

                    {(payoutAccountDetails.accId == '' && payoutAccountDetails.country == 'mx') && (
                    <Grid item xs={6}>
                      <Box pb={1}>
                        <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                        <Typography component="span" fontWeight={500}>{t('payout_info.company_ext_number')}</Typography>
                      </Box>
                      <TextField
                        fullWidth
                        ref={companyExtNumRef}
                        variant="outlined"
                        type="number"
                        placeholder={t('payout_info.company_ext_number_hint')}
                        sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                        error={!isCompanyExtNumValid}
                        helperText={!isCompanyExtNumValid && `${t('payout_info.invalid_ext_number')}`}
                        name="companyExtNumber"
                        value={payoutAccountDetails?.companyExtNumber}
                        onChange={handleInputchange}
                        inputProps={{
                          inputMode: 'text',
                          maxLength: 10,
                          style: { textAlign: 'center' },
                        }}
                        FormHelperTextProps={{
                          style: {
                            backgroundColor: LmiTheme.light.systemBackground.elevation,
                            margin: 0,
                            paddingTop: '3px',
                            paddingRight: '14px',
                            paddingBottom: '0',
                            paddingLeft: '14px',
                          },
                        }}
                      />
                    </Grid>
                    )}
                    {(payoutAccountDetails.accId == '' && payoutAccountDetails.country == 'mx') && (
                    <Grid item xs={6}>
                      <Box pb={1}>
                        <Typography component="span" fontWeight={500}>{t('payout_info.company_int_number')}</Typography>
                      </Box>
                      <TextField
                        fullWidth
                        ref={companyIntNumRef}
                        variant="outlined"
                        type="text"
                        placeholder={t('payout_info.company_int_number_hint')}
                        sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                        name="companyIntNumber"
                        value={payoutAccountDetails?.companyIntNumber}
                        onChange={handleInputchange}
                        inputProps={{
                          inputMode: 'text',
                          // maxLength: 4,
                          style: { textAlign: 'center' },
                        }}
                        FormHelperTextProps={{
                          style: {
                            backgroundColor: LmiTheme.light.systemBackground.elevation,
                            margin: 0,
                            paddingTop: '3px',
                            paddingRight: '14px',
                            paddingBottom: '0',
                            paddingLeft: '14px',
                          },
                        }}
                      />
                    </Grid>
                    )}
                    {(payoutAccountDetails.accId == '' && payoutAccountDetails.country == 'mx') && (
                    <Grid item xs={6}>
                      <Box pb={1}>
                        <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                        <Typography component="span" fontWeight={500}>{t('payout_info.company_zip_code')}</Typography>
                      </Box>
                      <TextField
                        fullWidth
                        ref={companyZipCodeRef}
                        variant="outlined"
                        type="text"
                        placeholder={t('payout_info.zip_code_hint')}
                        sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                        error={!isCompanyZipCodeValid}
                        helperText={!isCompanyZipCodeValid && `${t('payout_info.company_zip_code_hint')}`}
                        name="companyZipCode"
                        value={payoutAccountDetails?.companyZipCode}
                        onChange={handleInputchange}
                        inputProps={{
                          inputMode: 'text',
                          // maxLength: 4,
                          style: { textAlign: 'center' },
                        }}
                        FormHelperTextProps={{
                          style: {
                            backgroundColor: LmiTheme.light.systemBackground.elevation,
                            margin: 0,
                            paddingTop: '3px',
                            paddingRight: '14px',
                            paddingBottom: '0',
                            paddingLeft: '14px',
                          },
                        }}
                      />
                    </Grid>
                    )}
                    {/* { !payoutAccountDetails.isIndividual ? (
                      <Grid item xs={6}>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={1}
                          pb={1}
                        >
                          <Box>
                            <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                            <Typography component="span" fontWeight={500}>Company RFC</Typography>
                          </Box>
                          <Typography component="span" color={LmiTheme.light.systemBackground.tertiary}>
                            {`${payoutAccountDetails.lastName.length}/${limitFullName}`}
                          </Typography>
                        </Stack>
                        <TextField
                          fullWidth
                          ref={lastNameRef}
                          disabled={props.kReadOnly}
                          variant="outlined"
                          type="text"
                          placeholder={t('payout_info.last_name')}
                          name="lastName"
                          error={!isLastNameValid}
                          helperText={!isLastNameValid && `${t('payout_info.invalid_last_name')}`}
                          value={payoutAccountDetails?.lastName}
                          onChange={handleInputchange}
                          sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                          inputProps={{
                            maxLength: limitFullName,
                          }}
                          FormHelperTextProps={{
                            style: {
                              backgroundColor: LmiTheme.light.systemBackground.elevation,
                              margin: 0,
                              paddingTop: '3px',
                              paddingRight: '14px',
                              paddingBottom: '0',
                              paddingLeft: '14px',
                            },
                          }}
                        />
                      </Grid>
                    ) : <Box />} */}
                    {(payoutAccountDetails.accId == '' && payoutAccountDetails.country == 'us') ? (
                      <Grid item xs={6}>
                        <Box pb={1}>
                          <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                          <Typography component="span" fontWeight={500}>{t('payout_info.tax_id')}</Typography>
                        </Box>
                        <TextField
                          fullWidth
                          ref={taxIdRef}
                          variant="outlined"
                          type="text"
                          error={!isTaxIdValid}
                          helperText={!isTaxIdValid && `${t('payout_info.invalid_tax_id')}`}
                          value={payoutAccountDetails?.taxId}
                          onChange={handleInputchange}
                          name="taxId"
                          placeholder={t('payout_info.your_tax_id')}
                          sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                          FormHelperTextProps={{
                            style: {
                              backgroundColor: LmiTheme.light.systemBackground.elevation,
                              margin: 0,
                              paddingTop: '3px',
                              paddingRight: '14px',
                              paddingBottom: '0',
                              paddingLeft: '14px',
                            },
                          }}
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion disableGutters elevation={0} expanded={selectedIndividualOrBussines === 'individual' ?? false}>
                <AccordionSummary sx={{ display: 'none', padding: 0 }}>
                  <></>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <Grid
                    container
                    item
                    rowSpacing={0}
                    columnSpacing={3}
                  >
                    <Grid item xs={7}>
                      <Box pb={1}>
                        <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                        <Typography component="span" fontWeight={500}>{t('payout_info.date_birth')}</Typography>
                      </Box>
                      <LocalizationProvider locale={(cookies.get('i18next') === 'en') ? enUS : es} dateAdapter={AdapterDateFns}>

                        <DesktopDatePicker
                          disabled={props.kReadOnly}
                          value={inputDate}
                          onChange={(newValue) => {
                            setInputDate(newValue);
                            if (newValue != null) {
                              updateExistingPayoutAccountDetails({
                                ...payoutAccountDetails,
                                dateOfBirth: moment(newValue).format('YYYY/MM/DD').toString(),
                              });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              ref={dateOfBirthRef}
                              error={!isBirthDateValid}
                              helperText={!isBirthDateValid && `${t('payout_info.invalid_date_birth')}`}
                              FormHelperTextProps={{
                                style: {
                                  backgroundColor: LmiTheme.light.systemBackground.elevation,
                                  margin: 0,
                                  paddingTop: '3px',
                                  paddingRight: '14px',
                                  paddingBottom: '0',
                                  paddingLeft: '14px',
                                },
                              }}
                              {...params}
                              sx={{ backgroundColor: LmiTheme.light.systemBackground.base, color: 'black', fontSize: '14px' }}
                              onBlur={() => {
                                updateExistingPayoutAccountDetails({
                                  ...payoutAccountDetails,
                                  dateOfBirth: moment(inputDate).format('YYYY/MM/DD').toString(),
                                });
                              }}
                            />

                          )}
                        />
                      </LocalizationProvider>
                    </Grid>

                    {(payoutAccountDetails.accId == '' && payoutAccountDetails.country == 'us') && (
                      <Grid item xs={5}>
                        <Box pb={1}>
                          <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                          <Typography component="span" fontWeight={500}>{t('payout_info.ssn_last_four')}</Typography>
                        </Box>
                        <TextField
                          fullWidth
                          ref={ssnRef}
                          variant="outlined"
                          type="number"
                          placeholder="0000"
                          sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                          error={!isSsnValid}
                          helperText={!isSsnValid && `${t('payout_info.invalid_ssn')}`}
                          name="ssn"
                          onInput={(e: any) => {
                            // eslint-disable-next-line
                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4)
                          }}
                          value={payoutAccountDetails?.ssn}
                          onChange={handleInputchange}
                          inputProps={{
                            // inputMode: 'numeric',
                            maxLength: 4,
                            style: { textAlign: 'center' },
                          }}
                          FormHelperTextProps={{
                            style: {
                              backgroundColor: LmiTheme.light.systemBackground.elevation,
                              margin: 0,
                              paddingTop: '3px',
                              paddingRight: '14px',
                              paddingBottom: '0',
                              paddingLeft: '14px',
                            },
                          }}
                        />
                      </Grid>
                    )}
                    {(payoutAccountDetails.accId == '' && payoutAccountDetails.country == 'mx') && (
                      <Grid item xs={6}>
                        <Box pb={1}>
                          <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                          <Typography component="span" fontWeight={500}>{t('payout_info.rfc')}</Typography>
                        </Box>
                        <TextField
                          fullWidth
                          ref={rfcRef}
                          variant="outlined"
                          type="text"
                          placeholder={t('payout_info.rfc_hint')}
                          sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                          error={!isRfcValid}
                          helperText={!isRfcValid && `${t('payout_info.invalid_rfc')}`}
                          name="rfc"
                          value={payoutAccountDetails?.rfc}
                          onChange={handleInputchange}
                          inputProps={{
                            inputMode: 'text',
                            maxLength: 13,
                            style: { textAlign: 'center' },
                          }}
                          FormHelperTextProps={{
                            style: {
                              backgroundColor: LmiTheme.light.systemBackground.elevation,
                              margin: 0,
                              paddingTop: '3px',
                              paddingRight: '14px',
                              paddingBottom: '0',
                              paddingLeft: '14px',
                            },
                          }}
                        />
                      </Grid>
                    )}
                    {(payoutAccountDetails.accId == '' && payoutAccountDetails.country == 'mx') && (
                      <Grid item xs={6}>
                        <Box pb={1}>
                          <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                          <Typography component="span" fontWeight={500}>{t('payout_info.state_territory')}</Typography>
                        </Box>

                        <FormControl fullWidth>
                          <Select
                            disabled={false}
                            value={selectedMxState}
                            onChange={updateMxState}
                            sx={{ bgcolor: LmiTheme.light.systemBackground.base }}
                          >
                            {mexicanStates.map((e) => (<MenuItem value={e.abbreviation}>{e.name}</MenuItem>))}
                            {/* <MenuItem value="MX">México</MenuItem> */}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                  </Grid>

                  {(payoutAccountDetails.accId == '' && payoutAccountDetails.country == 'mx') && (
                    <Grid
                      container
                      item
                      rowSpacing={0}
                      columnSpacing={3}
                      spacing={1}
                      pb={1}
                    >

                      <Grid item xs={12}>
                        <Box pb={1}>
                          <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                          <Typography component="span" fontWeight={500}>{t('payout_info.city')}</Typography>
                        </Box>
                        <TextField
                          fullWidth
                          ref={cityRef}
                          variant="outlined"
                          type="text"
                          placeholder={t('payout_info.city_hint')}
                          sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                          error={!isCityValid}
                          helperText={!isCityValid && `${t('payout_info.invalid_city')}`}
                          name="city"
                          value={payoutAccountDetails?.city}
                          onChange={handleInputchange}
                          inputProps={{
                            inputMode: 'text',
                            // maxLength: 4,
                            style: { textAlign: 'center' },
                          }}
                          FormHelperTextProps={{
                            style: {
                              backgroundColor: LmiTheme.light.systemBackground.elevation,
                              margin: 0,
                              paddingTop: '3px',
                              paddingRight: '14px',
                              paddingBottom: '0',
                              paddingLeft: '14px',
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Box pb={1}>
                          <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                          <Typography component="span" fontWeight={500}>{t('payout_info.street')}</Typography>
                        </Box>
                        <TextField
                          fullWidth
                          ref={addressRef}
                          variant="outlined"
                          type="text"
                          placeholder={t('payout_info.street_hint')}
                          sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                          error={!isAddressValid}
                          helperText={!isAddressValid && `${t('payout_info.invalid_street')}`}
                          name="address"
                          value={payoutAccountDetails?.address}
                          onChange={handleInputchange}
                          inputProps={{
                            inputMode: 'text',
                            // maxLength: 4,
                            style: { textAlign: 'center' },
                          }}
                          FormHelperTextProps={{
                            style: {
                              backgroundColor: LmiTheme.light.systemBackground.elevation,
                              margin: 0,
                              paddingTop: '3px',
                              paddingRight: '14px',
                              paddingBottom: '0',
                              paddingLeft: '14px',
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Box pb={1}>
                          <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                          <Typography component="span" fontWeight={500}>{t('payout_info.ext_number')}</Typography>
                        </Box>
                        <TextField
                          fullWidth
                          ref={extNumRef}
                          variant="outlined"
                          type="number"
                          placeholder={t('payout_info.ext_number_hint')}
                          sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                          error={!isExtNumValid}
                          helperText={!isExtNumValid && `${t('payout_info.invalid_ext_number')}`}
                          name="extNumber"
                          value={payoutAccountDetails?.extNumber}
                          onChange={handleInputchange}
                          inputProps={{
                            inputMode: 'text',
                            maxLength: 10,
                            style: { textAlign: 'center' },
                          }}
                          FormHelperTextProps={{
                            style: {
                              backgroundColor: LmiTheme.light.systemBackground.elevation,
                              margin: 0,
                              paddingTop: '3px',
                              paddingRight: '14px',
                              paddingBottom: '0',
                              paddingLeft: '14px',
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {(payoutAccountDetails.accId == '' && payoutAccountDetails.country == 'mx') && (
                    <Grid
                      container
                      item
                      rowSpacing={0}
                      columnSpacing={3}
                      spacing={1}
                      pb={1}
                    >

                      <Grid item xs={6}>
                        <Box pb={1}>
                          <Typography component="span" fontWeight={500}>{t('payout_info.int_number')}</Typography>
                        </Box>
                        <TextField
                          fullWidth
                          ref={intNumRef}
                          variant="outlined"
                          type="text"
                          placeholder={t('payout_info.int_number_hint')}
                          sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                          name="intNumber"
                          value={payoutAccountDetails?.intNumber}
                          onChange={handleInputchange}
                          inputProps={{
                            inputMode: 'text',
                            // maxLength: 4,
                            style: { textAlign: 'center' },
                          }}
                          FormHelperTextProps={{
                            style: {
                              backgroundColor: LmiTheme.light.systemBackground.elevation,
                              margin: 0,
                              paddingTop: '3px',
                              paddingRight: '14px',
                              paddingBottom: '0',
                              paddingLeft: '14px',
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Box pb={1}>
                          <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                          <Typography component="span" fontWeight={500}>{t('payout_info.zip_code')}</Typography>
                        </Box>
                        <TextField
                          fullWidth
                          ref={zipCodeRef}
                          variant="outlined"
                          type="text"
                          placeholder={t('payout_info.zip_code_hint')}
                          sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                          error={!isZipCodeValid}
                          helperText={!isZipCodeValid && `${t('payout_info.invalid_zip_code')}`}
                          name="zipCode"
                          value={payoutAccountDetails?.zipCode}
                          onChange={handleInputchange}
                          inputProps={{
                            inputMode: 'text',
                            // maxLength: 4,
                            style: { textAlign: 'center' },
                          }}
                          FormHelperTextProps={{
                            style: {
                              backgroundColor: LmiTheme.light.systemBackground.elevation,
                              margin: 0,
                              paddingTop: '3px',
                              paddingRight: '14px',
                              paddingBottom: '0',
                              paddingLeft: '14px',
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
          {(payoutAccountDetails.isIndividual || payoutAccountDetails.country == 'us') && (
          <Grid item xs={12}>
            <Box pb={1}>
              <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
              <Typography component="span" fontWeight={500}>{t('payout_info.email')}</Typography>
            </Box>
            <TextField
              fullWidth
              ref={emailRef}
              variant="outlined"
              // type="email"
              name="email"
              value={payoutAccountDetails?.email}
              error={!isEmailValid}
              helperText={!isEmailValid && `${t('payout_info.invalid_email')}`}
              onChange={handleInputchange}
              disabled={props.kReadOnly}
              placeholder={t('payout_info.enter_email')}
              sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
              FormHelperTextProps={{
                style: {
                  backgroundColor: LmiTheme.light.systemBackground.elevation,
                  margin: 0,
                  paddingTop: '3px',
                  paddingRight: '14px',
                  paddingBottom: '0',
                  paddingLeft: '14px',
                },
              }}
            />
          </Grid>
          )}
          {payoutAccountDetails.country == 'mx' && (
            <Grid item xs={12}>

              <Box sx={{ p: 1, color: LmiTheme.light.systemBackground.tertiary }}>
                {t('payout_info.ine_verify_message')}
              </Box>
            </Grid>
          )}
          {payoutAccountDetails.country == 'mx' && (
            <Grid item xs={6}>

              <Box>
                <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                <Typography component="span" fontWeight={500}>{t('payout_info.front_ine')}</Typography>
              </Box>
              {payoutAccountDetails?.frontIne?.length == 0 ? (

                <FileUploader
                  maxSize={256}
                  multiple
                  handleChange={(e: any) => onImageDropped(e, 'frontIne')}
                  name="file"
                  types={fileTypes}
                  style={{ width: '300px' }}
                >

                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    className="__add"
                    sx={{
                      borderRadius: '7px',
                      bgcolor: validationIne ? LmiColorPalette.blueScheme[50] : LmiColorPalette.redScheme[50],
                      aspectRatio: '5 / 4',
                      backgroundSize: 'cover',
                      width: '100%',
                      height: '100%',
                      border: `1px dashed ${validationIne ? LmiTheme.light.accent : LmiTheme.light.dialog.danger}`,
                    }}
                  >
                    <AddCircleRounded fontSize="large" sx={{ color: LmiTheme.light.accent }} />
                    <Typography sx={{ fontSize: 'body1.fontSize', fontWeight: 500, color: LmiTheme.light.accent }}>
                      {t('add_item_page.add_photo')}
                    </Typography>
                  </Stack>
                </FileUploader>
              )
                : (

                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    spacing={3}
                    className="__image"
                    sx={{
                      borderRadius: '7px',
                      backgroundImage: `url(${payoutAccountDetails?.frontIne?.imgPath != undefined ? payoutAccountDetails?.frontIne?.imgPath : payoutAccountDetails?.frontIne})`,
                      aspectRatio: '5 / 4',
                      width: '100%',
                      height: '100%',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
                    }}
                  >
                    <Box
                      onClick={() => { }}
                      sx={{
                        background: 'linear-gradient(45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.7) 100%)',
                        p: 1,
                        width: '100%',
                        height: '40%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-start',
                        borderBottomLeftRadius: '50%',
                        borderTopRightRadius: '7px',
                        zIndex: 10000,
                        cursor: 'pointer',
                      }}
                      aria-label="add"
                    >
                      <CancelOutlined
                        fontSize="medium"
                        onMouseDown={() => { removeBackgroundImageFront(); }}
                        sx={{ color: LmiTheme.light.systemBackground.primaryIverted, zIndex: 100, cursor: 'pointer' }}
                      />
                    </Box>
                  </Stack>
                )}
            </Grid>
          )}
          {/* Back ine */}
          {payoutAccountDetails.country == 'mx' && (
            <Grid item xs={6}>
              <Box>
                <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                <Typography component="span" fontWeight={500}>{t('payout_info.back_ine')}</Typography>
              </Box>
              {payoutAccountDetails?.backIne?.length == 0 ? (

                <FileUploader
                  ref={ineRef}
                  maxSize={256}
                  multiple
                  handleChange={(e: any) => onImageDropped(e, 'backIne')}
                  name="file"
                  types={fileTypes}
                >

                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    className="__add"
                    sx={{
                      borderRadius: '7px',
                      bgcolor: validationIne ? LmiColorPalette.blueScheme[50] : LmiColorPalette.redScheme[50],
                      aspectRatio: '5 / 4',
                      backgroundSize: 'cover',
                      width: '100%',
                      height: '100%',
                      border: `1px dashed ${validationIne ? LmiTheme.light.accent : LmiTheme.light.dialog.danger}`,
                    }}
                  >
                    <AddCircleRounded fontSize="large" sx={{ color: LmiTheme.light.accent }} />
                    <Typography sx={{ fontSize: 'body1.fontSize', fontWeight: 500, color: LmiTheme.light.accent }}>
                      {t('add_item_page.add_photo')}
                    </Typography>
                  </Stack>
                </FileUploader>
              )
                : (

                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    spacing={3}
                    className="__image"
                    sx={{
                      borderRadius: '7px',
                      backgroundImage: `url(${payoutAccountDetails?.backIne?.imgPath != undefined ? payoutAccountDetails?.backIne?.imgPath : payoutAccountDetails?.backIne})`,
                      aspectRatio: '5 / 4',
                      width: '100%',
                      height: '100%',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
                    }}
                  >
                    <Box
                      onClick={() => { }}
                      sx={{
                        background: 'linear-gradient(45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.7) 100%)',
                        p: 1,
                        width: '40%',
                        height: '40%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-start',
                        borderBottomLeftRadius: '50%',
                        borderTopRightRadius: '7px',
                      }}
                      aria-label="add"
                    >
                      <CancelOutlined
                        fontSize="medium"
                        onMouseDown={() => { removeBackgroundImageBack(); }}
                        sx={{ color: LmiTheme.light.systemBackground.primaryIverted, zIndex: 100, cursor: 'pointer' }}
                      />
                    </Box>
                  </Stack>
                )}
            </Grid>
          )}
        </Grid>
        {payoutAccountDetails?.externalAcc != '' && (
          <Grid item xs={12}>
            <Box>
              <Typography component="span" fontSize={14} fontWeight={500}>
                {t('payout_info.card_ending')}
                {payoutAccountDetails?.externalAcc.data[0].last4}

              </Typography>
            </Box>
          </Grid>
        )}
      </Paper>
      {(payoutAccountDetails.accId == '') && (
        <Box sx={{
          px: 1,
          pt: 3,
          pb: 2,
          fontWeight: 'bold',
        }}
        >
          {t('payout_info.where_send_money')}
        </Box>
      )}
      {(!payoutAccountDetails.isIndividual && payoutAccountDetails.country == 'mx') && (
        <Grid sx={{ mt: 5 }} item xs={12}>
          <Box pb={1}>
            <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
            <Typography component="span" fontWeight={500}>{t('payout_info.account_name')}</Typography>
          </Box>
          <TextField
            fullWidth
            ref={accountHolderFirstNameRef}
            variant="outlined"
            // type="email"
            name="accountHolderNameMx"
            value={payoutAccountDetails?.accountHolderNameMx}
            error={!isAccountHolderFirstNameValid}
            helperText={!isAccountHolderFirstNameValid && `${t('payout_info.invalid_first_name')}`}
            onChange={handleInputchange}
            disabled={props.kReadOnly}
            placeholder={t('payout_info.first_name')}
            sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
            FormHelperTextProps={{
              style: {
                backgroundColor: LmiTheme.light.systemBackground.elevation,
                margin: 0,
                paddingTop: '3px',
                paddingRight: '14px',
                paddingBottom: '0',
                paddingLeft: '14px',
              },
            }}
          />
        </Grid>
      )}
      {(!payoutAccountDetails.isIndividual && payoutAccountDetails.country == 'mx') && (
        <Grid sx={{ mt: 5 }} item xs={12}>
          <Box pb={1}>
            <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
            <Typography component="span" fontWeight={500}>{t('payout_info.account_last_name')}</Typography>
          </Box>
          <TextField
            fullWidth
            ref={accountHolderLastNameRef}
            variant="outlined"
            // type="email"
            name="accountHolderLastNameMx"
            value={payoutAccountDetails?.accountHolderLastNameMx}
            error={!isAccountHolderLastNameValid}
            helperText={!isAccountHolderLastNameValid && `${t('payout_info.invalid_last_name')}`}
            onChange={handleInputchange}
            disabled={props.kReadOnly}
            placeholder={t('payout_info.last_name')}
            sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
            FormHelperTextProps={{
              style: {
                backgroundColor: LmiTheme.light.systemBackground.elevation,
                margin: 0,
                paddingTop: '3px',
                paddingRight: '14px',
                paddingBottom: '0',
                paddingLeft: '14px',
              },
            }}
          />
        </Grid>
      )}
      {payoutAccountDetails.country == 'mx' && (
        <Grid sx={{ mt: 5 }} item xs={12}>
          <Box pb={1}>
            <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
            <Typography component="span" fontWeight={500}>{t('payout_info.bank_account_label')}</Typography>
          </Box>
          <TextField
            fullWidth
            ref={bankAccountRef}
            variant="outlined"
            // type="email"
            name="bankAccountMx"
            value={payoutAccountDetails?.bankAccountMx}
            error={!isBankAccountMxValid}
            helperText={!isBankAccountMxValid && `${t('payout_info.bank_account_hint')}`}
            onChange={handleInputchange}
            disabled={props.kReadOnly}
            placeholder={t('payout_info.bank_account_hint')}
            sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
            FormHelperTextProps={{
              style: {
                backgroundColor: LmiTheme.light.systemBackground.elevation,
                margin: 0,
                paddingTop: '3px',
                paddingRight: '14px',
                paddingBottom: '0',
                paddingLeft: '14px',
              },
            }}
          />
        </Grid>
      )}
      {(payoutAccountDetails.accId == '' && payoutAccountDetails.country == 'us') && (

        <Paper variant="outlined" sx={{ p: 2 }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={1}
            component="form"
            sx={{
              '& > :not(style)': { p: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid item xs={12}>
              <RadioGroup
                row
                name="debit-card-or-bank-acount"
                value={valueDebitcardOrBankaccount}
                onChange={changeDebitcardOrBankaccount}
              >
                <FormControlLabel value="debitcard" control={<Radio />} label={t('payout_info.debit_card')} />
                <FormControlLabel value="bankaccount" control={<Radio />} label={t('payout_info.bank_account')} />
              </RadioGroup>
            </Grid>
            <Grid item p={0} m={0} xs={12}>
              <Box my={0}>

                <Accordion disableGutters elevation={0} expanded={valueDebitcardOrBankaccount !== 'debitcard' ?? false}>
                  <AccordionSummary sx={{ display: 'none', padding: 0 }}>
                    <></>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: 0 }}>
                    <Grid
                      container
                      rowSpacing={2}
                      columnSpacing={0}
                      component="form"
                      sx={{
                        '& > :not(style)': { width: '25ch' },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <Grid item xs={12}>
                        <Box pb={1}>
                          <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                          <Typography component="span" fontWeight={500}>{t('payout_info.bank_account_number')}</Typography>
                        </Box>
                        <TextField
                          fullWidth
                          ref={accountNumberRef}
                          variant="outlined"
                          type="text"
                          placeholder={t('payout_info.your_bank_account_number')}
                          name="accountNumber"
                          onChange={handleInputchange}
                          value={payoutAccountDetails?.accountNumber}
                          error={!isAccountNumberValid}
                          helperText={!isAccountNumberValid && `${t('payout_info.invalid_bank_account_number')}`}
                          // value={valuesFullName.name}
                          sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Box pb={1}>
                          <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                          <Typography component="span" fontWeight={500}>{t('payout_info.routing_number')}</Typography>
                        </Box>
                        <TextField
                          fullWidth
                          ref={routingNumberRef}
                          variant="outlined"
                          type="text"
                          placeholder={t('payout_info.your_routing_number')}
                          onChange={handleInputchange}
                          name="routingNumber"
                          value={payoutAccountDetails?.routingNumber}
                          error={!isRoutingNumberValid}
                          helperText={!isRoutingNumberValid && `${t('payout_info.invalid_routing_number')}`}
                          // value={valuesFullName.name}
                          sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion disableGutters elevation={0} expanded={valueDebitcardOrBankaccount === 'debitcard' ?? false}>
                  <AccordionSummary sx={{ display: 'none', padding: 0 }}>
                    <></>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: 0 }}>
                    <Grid
                      container
                      rowSpacing={2}
                      columnSpacing={3}
                      component="form"
                      sx={{
                        '& > :not(style)': { width: '25ch' },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <Grid item xs={12}>
                        <Box pb={1}>
                          <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                          <Typography component="span" fontWeight={500}>{t('payout_info.card_number')}</Typography>
                        </Box>
                        <TextField
                          fullWidth
                          ref={cardNumberRef}
                          variant="outlined"
                          type="text"
                          name="cardNumber"
                          onChange={handleInputchange}
                          value={payoutAccountDetails?.cardNumber}
                          error={!isCardNumberValid}
                          helperText={!isCardNumberValid && `${t('payout_info.invalid_card_number')}`}
                          placeholder="•••• •••• •••• ••••"
                          sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                          InputProps={{
                            inputComponent: TextCardNumber as any,
                          }}
                          FormHelperTextProps={{
                            style: {
                              backgroundColor: LmiTheme.light.systemBackground.elevation,
                              margin: 0,
                              paddingTop: '3px',
                              paddingRight: '14px',
                              paddingBottom: '0',
                              paddingLeft: '14px',
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={isMobile ? 6 : 3}>
                        <Box pb={1}>
                          <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                          <Typography component="span" fontWeight={500}>{t('payout_info.expiration')}</Typography>
                        </Box>
                        <TextField
                          fullWidth
                          ref={expDateRef}
                          variant="outlined"
                          type="text"
                          name="expiration"
                          placeholder="MM/YY"
                          value={formattedExpiration}
                          error={!isExpDateValid}
                          helperText={!isExpDateValid && `${t('payout_info.invalid_exp_date')}`}
                          onChange={handleInputchange}
                          sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                          InputProps={{
                            inputComponent: TextExpireDate as any,
                          }}
                          FormHelperTextProps={{
                            style: {
                              backgroundColor: LmiTheme.light.systemBackground.elevation,
                              margin: 0,
                              paddingTop: '3px',
                              paddingRight: '14px',
                              paddingBottom: '0',
                              paddingLeft: '14px',
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Box pb={1}>
                          <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                          <Typography component="span" fontWeight={500}>CVV</Typography>
                        </Box>
                        <TextField
                          fullWidth
                          ref={cvvRef}
                          variant="outlined"
                          type="text"
                          name="cvv"
                          placeholder="••••"
                          helperText={!isCVVValid && `${t('payout_info.invalid_cvv')}`}
                          error={!isCVVValid}
                          sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                          value={payoutAccountDetails?.cvv}
                          onChange={handleInputchange}
                          // inputProps={{
                          //   inputMode: 'numeric',
                          //   maxLength: 4,
                          //   style: { textAlign: 'center' },
                          // }}
                          InputProps={{
                            inputComponent: TextCVCMask as any,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
}

export default NewIndOrBusPayout;

/* eslint-disable no-nested-ternary */
import {
  Box, Typography, Paper, Stack, Divider, useMediaQuery, useTheme, IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useContext, useEffect } from 'react';
// import moment from 'moment';
import ActivityDeliveryStyle from './ActivityDelivery.style';
import { ReactComponent as RoadieIcon } from '../../../../../../assets/icons/lmi_roadie_icon.svg';
import { ReactComponent as DeliveryIcon } from '../../../../../../assets/icons/lmi_delivery_icon.svg';
import { SectionTitle } from '../../../../../../components/Common';
import { ActivityDeliveryModels } from './ActivityDelivery.models';
import deliveryActivityContext from '../../../../../../context/deliveryActivity/deliveryActivityContext';

export default function ActivityDelivery(props: ActivityDeliveryModels) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (props.kType == 'Roadie') {
      deliveryActivityLoad(props?.notifDetail);
    }
  }, []);

  const deliveryActivityContextLog = useContext(deliveryActivityContext);
  const {
    deliveryActivityLoad,
    collectShipment,
    returnShipment,
  }: any = deliveryActivityContextLog;

  const openInNewTab = () => {
    const url = `https://www.google.com/maps/search/?api=1&query=${props?.notifDetail?.renterDeliveryAddress}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  const { t } = useTranslation('global');
  return (
    <Box sx={ActivityDeliveryStyle}>

      <SectionTitle
        kMarginTop={2}
        kTitle={t('activity.delivery.type_delivery')}
        kFontSize={18}
      />
      <Paper className="lmiw-activity-delivery" variant="outlined" elevation={0}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" p={2}>
          <Stack direction="row" justifyContent="flex-start" alignItems="center">
            {props.kType == 'Roadie'
              ? <RoadieIcon width={24} height={24} style={{ marginRight: 16 }} />
              : <DeliveryIcon width={24} height={24} style={{ marginRight: 16 }} />}

            <Typography fontSize={16} fontWeight={700}>
              {props.kType == 'Roadie' ? `${t('activity.delivery.roadie')}` : `${t('activity.delivery.pickup_dropoff')}`}
            </Typography>
          </Stack>
          {(props.kType == 'Roadie' && props?.notifDetail?.isLenderApproved)
            ? (isMobile ? <ArrowForwardIosOutlinedIcon onClick={() => props.kOpenTracking()} />
              : <Typography className="lmiw-btn-tracking" onClick={() => props.kOpenTracking()}>{t('activity.delivery.view_tracking')}</Typography>) : null}
        </Stack>
        {props.kType == 'Roadie' ? (
          <>
            <Divider />
            <Stack
              direction={{ sm: 'row', xs: 'column' }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Box p={2}>
                <Typography className="lmiw-delivery-title">{t('activity.delivery.collect')}</Typography>
                <Stack
                  direction="row"
                  spacing={2}
                >
                  <Typography className="lmiw-delivery-label">{t('activity.delivery.delivery_time')}</Typography>
                  <Typography className="lmiw-delivery-date">

                    {collectShipment?.deliverBetween?.start}
                    {' '}
                    -
                    {' '}
                    {collectShipment?.deliverBetween?.end}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                >
                  <Typography className="lmiw-delivery-label">{t('activity.delivery.status')}</Typography>
                  <Box>
                    <Typography className="lmiw-delivery-date">{t(collectShipment?.state?.value)}</Typography>
                    <Typography className="lmiw-delivery-desc">{t(collectShipment?.state?.description)}</Typography>
                  </Box>
                </Stack>
              </Box>
              <Divider orientation="vertical" flexItem />
              <Divider flexItem />
              <Box p={2}>
                <Typography className="lmiw-delivery-title">{t('activity.delivery.return')}</Typography>
                <Stack
                  direction="row"
                  spacing={2}
                >
                  <Typography className="lmiw-delivery-label">{t('activity.delivery.delivery_time')}</Typography>
                  <Typography className="lmiw-delivery-date">
                    {returnShipment?.deliverBetween?.start}
                    {' '}
                    -
                    {' '}
                    {returnShipment?.deliverBetween?.end}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                >
                  <Typography className="lmiw-delivery-label">{t('activity.delivery.status')}</Typography>
                  <Box>
                    <Typography className="lmiw-delivery-date">{t(returnShipment?.state?.value)}</Typography>
                    <Typography className="lmiw-delivery-desc">{t(returnShipment?.state?.description)}</Typography>
                  </Box>
                </Stack>
              </Box>
            </Stack>

          </>
        ) : (
          <>

            <Divider />
            <Stack
              direction={{ sm: 'row', xs: 'column' }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack
                direction="row"
                spacing={2}
              >
                <Box p={2}>

                  <Typography className="lmiw-delivery-label">{t('activity.delivery.contact_lender')}</Typography>
                </Box>
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className="lmiw-student-concept"
                spacing={2}
                sx={{ px: 2 }}
              >

                <Typography onClick={() => openInNewTab()} className="lmiw-delivery-label">
                  {props?.notifDetail?.renterDeliveryAddress}
                </Typography>

                <IconButton onClick={() => openInNewTab()} color="primary" aria-label="close modal">
                  <ArrowForwardIosIcon />
                </IconButton>
              </Stack>
            </Stack>
          </>
        )}

      </Paper>
    </Box>
  );
}

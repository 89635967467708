// import { useState } from 'react';
import {
  Avatar,
  Grid,
  Stack,
  Typography,

} from '@mui/material';
import { useTranslation } from 'react-i18next';
import LmiTheme from '../../../../../themes/lmi-mui-theme/lmi-theme';
import step1 from '../../../../../assets/how_its_works/step1.jpg';
import step2 from '../../../../../assets/how_its_works/step2.jpg';
import step3 from '../../../../../assets/how_its_works/step3.jpg';

export default function HowItsWorks() {
  const { t } = useTranslation('global');
  const circleSteps:any = [
    {
      kStep: '1',
      kImg: step1,
      kTitle: `${t('circles_page.create')}`,
      kDesc: `${t('circles_page.create_txt')}`,
    },
    {
      kStep: '2',
      kImg: step2,
      kTitle: `${t('circles_page.list')}`,
      kDesc: `${t('circles_page.list_txt')}`,
    },
    {
      kStep: '3',
      kImg: step3,
      kTitle: `${t('circles_page.invite')}`,
      kDesc: `${t('circles_page.invite_txt')}`,
    },

  ];

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="start"
        spacing={4}
      >

        {circleSteps.map((data: any) => (
          <Grid item xs={12} md={6} lg={4}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Avatar
                alt="Remy Sharp"
                src={data.kImg}
                sx={{ width: 300, height: 300 }}
              />
              <Typography variant="h5" fontWeight="bold" my={2} sx={{ display: 'flex', my: 5 }}>
                <Avatar sx={{
                  width: 30, height: 30, bgcolor: LmiTheme.light.accent, mr: 2,
                }}
                >
                  {data.kStep}
                </Avatar>
                {data.kTitle}
              </Typography>
              <Typography
                variant="body1"
                align="center"
                component="p"
                fontWeight="300"
                fontSize="22px"
                color={LmiTheme.light.systemBackground.primary}
              >
                {data.kDesc}
              </Typography>
            </Stack>

          </Grid>
        ))}

      </Grid>
    </>
  );
}

/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import { useState } from 'react';
import {
  Stack, Box, Typography, Container, Button, CardMedia,
} from '@mui/material';

import { TabsLandingModels } from './TwoTabs.model';
import TwoTabsStyle from './TwoTabs.style';

function Title(props: any) {
  return (
    <Box className="lmi-title" mb={4}>
      <Typography variant="h4" component="h4">
        {props.text1}
      </Typography>
      {' '}
      <Typography variant="h4" color="primary" component="h4">
        {props.text2}
      </Typography>
    </Box>
  );
}

export default function EarnSaveMoney(props: TabsLandingModels) {
  const [tabSelected, setTab] = useState('1');
  return (
    <Box sx={TwoTabsStyle}>
      {/* <TabContext value={tabSelected}> */}
      <Stack
        direction="row"
        justifyContent={{ md: 'flex-start', xs: 'center' }}
        alignItems="center"
        spacing={2}
        className="lmi-tablist"
      >
        <Button className={tabSelected === '1' ? 'lmi-tabactive' : ''} onClick={() => setTab('1')} variant="text">{props.kTab1}</Button>
        <Button className={tabSelected === '2' ? 'lmi-tabactive' : ''} onClick={() => setTab('2')} variant="text">{props.kTab2}</Button>

      </Stack>
      <Box className="lmi-tabpanel" sx={{ display: tabSelected === '1' ? 'block' : 'none' }}>
        <Stack
          direction={{ md: 'row', xs: 'column-reverse' }}
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Container disableGutters className="lmi-cont-text">
            <Title text1={props.kTitle1} text2={props.kTitleBlue1} />
            {props.kDesc1}
          </Container>
          <Stack
            justifyContent="center"
            alignItems="center"
            className="lmi-stack-img"
          >
            <CardMedia
              component="img"
              height="auto"
              image={props.kImg1}
              alt={props.kTab1}
            />
          </Stack>

        </Stack>
      </Box>
      <Box className="lmi-tabpanel" sx={{ display: tabSelected === '2' ? 'block' : 'none' }}>
        <Stack
          direction={{ md: 'row', xs: 'column-reverse' }}
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Container disableGutters className="lmi-cont-text">
            <Title text1={props.kTitle2} text2={props.kTitleBlue2} />
            {props.kDesc2}
          </Container>
          <Stack
            justifyContent="center"
            alignItems="center"
            className="lmi-stack-img"
          >
            <CardMedia
              component="img"
              height="auto"
              image={props.kImg2}
              alt={props.kTab2}
            />

          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}

/* eslint-disable react/jsx-indent */
import { useState } from 'react';
import {
  Box,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StaticDateRangePicker } from '@mui/lab';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import cookies from 'js-cookie';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enUS, es } from 'date-fns/locale';
import { DateRange } from '@mui/lab/DateRangePicker';
import { LmiTheme } from '..';

function DialogAvailability() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const [dateRange, setDateRange] = useState<DateRange<Date>>([null, null]);

  const newDate = new Date();
  const date = newDate.getDate();
  const month = newDate.getMonth();
  const year = newDate.getFullYear();

  const currentDate = new Date(year, month, date);
  const currentDateMonth = new Date(year, month, (date + 1));
  const currentDateYear = new Date((year + 1), month, date);

  const formatDate = (dateString: any) => {
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    };
    if (cookies.get('i18next') === 'es') {
      return new Date(dateString).toLocaleDateString('es-ES', options);
    } return new Date(dateString).toLocaleDateString([], options);
  };

  const { t } = useTranslation('global');

  return (
    <>
      <>
        <Grid
          spacing={3}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            pl={1}
            xs={5}
            sm="auto"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Typography
              variant="caption"
              sx={{
                color: LmiTheme.light.systemBackground.tertiary,
              }}
            >
              {t('listing_detail.start_date')}
            </Typography>
            <Typography
              component="div"
              sx={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: isDesktop ? 'body1.fontSize' : 'body2.fontSize',
              }}
            >
              {formatDate(dateRange[0] ?? currentDate)}
            </Typography>
          </Grid>
          <>
          </>
          <Grid
            item
            xs="auto"
            sm={2}
            textAlign="center"
          >
            <Typography
              variant="caption"
              sx={{
                color: LmiTheme.light.systemBackground.tertiary,
                fontWeight: 'bold',
              }}
            >
              {t('listing_detail.to')}
            </Typography>
          </Grid>
          <Grid
            item
            pr={1}
            xs={5}
            sm="auto"
            textAlign="center"
          >
            <Typography
              variant="caption"
              sx={{
                color: LmiTheme.light.systemBackground.tertiary,
              }}
            >
              {t('listing_detail.end_date')}
            </Typography>
            <Typography
              component="div"
              sx={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: isDesktop ? 'body1.fontSize' : 'body2.fontSize',
              }}
            >
              {formatDate(dateRange[1] ?? currentDateMonth)}
            </Typography>
          </Grid>
        </Grid>
      </>
      <Divider />
      <>
        <LocalizationProvider locale={(cookies.get('i18next') === 'en') ? enUS : es} dateAdapter={AdapterDateFns}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <StaticDateRangePicker
              showToolbar={false}
              startText="Check-in"
              endText="Check-out"
              displayStaticWrapperAs={isDesktop ? 'desktop' : 'mobile'}
              value={dateRange}
              onChange={(newValue) => {
                setDateRange(newValue);
              }}
              minDate={new Date(currentDate)}
              maxDate={new Date(currentDateYear)}
              /* eslint-disable react/jsx-props-no-spreading */
              renderInput={(startProps, endProps) => (
                <>
                  <TextField
                    {...startProps}
                    type="datetime"
                    variant="standard"
                    placeholder=""
                    size="small"
                    InputProps={{
                      disableUnderline: true,
                      readOnly: true,
                      style: { textAlign: 'center', fontWeight: 'bold' },
                    }}
                  />
                  <Box sx={{ mx: 2 }}>
                  {t('listing_detail.to')}
                  </Box>
                  <TextField
                    {...endProps}
                    type="datetime"
                    variant="standard"
                    placeholder=""
                    size="small"
                    InputProps={{
                      disableUnderline: true,
                      readOnly: true,
                      style: { textAlign: 'center', fontWeight: 'bold' },
                    }}
                  />
                </>
              )}
            />
          </Stack>
        </LocalizationProvider>
      </>
    </>
  );
}

DialogAvailability.defaultProps = {
  kStartDate: 'Select Start Date',
  kEndDate: 'Select End Date',
};

export default DialogAvailability;

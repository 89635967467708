import React, { useContext, useState } from 'react';
import {
  Card, Divider, CardContent, List,
  ListItem, ListItemButton, ListItemIcon,
  Checkbox, ListItemText,
  Button, Box, Drawer,
  useMediaQuery, useTheme, Typography, Stack, Skeleton, Fade, ToggleButtonGroup, ToggleButton,

} from '@mui/material';
import { useTranslation } from 'react-i18next';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import homeContext from '../../../../../context/home/homeContext';
import exploreContext from '../../../../../context/explore/exploreContext';
import FilterStyle from './Filters.style';
import { SectionTitle } from '../../../../../components/Common';

const Items = (props: any) => {
  const { t } = useTranslation('global');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    categories,
    addCategory,
    categoriesSelected,
    removeAllCategoriesSelected,
    setSearchCurreny,
    searchCurrency,
  }: any = useContext(homeContext);

  const {
    categoriesApplied,
    removeAllCategoriesApplied,
    applyCategories,
  }: any = useContext(exploreContext);

  const skeletonItems: any = [36, 55, 80, 60, 77, 73, 70, 71, 50, 74, 40, 53, 37, 43, 54];

  const handleChangeCurrency = (
    _event: React.MouseEvent<HTMLElement>,
    newCurrency: string,
  ) => {
    if (newCurrency != null) {
      setSearchCurreny(newCurrency);
    }
  };

  return (
    <>
      <Card
        elevation={0}
        sx={{ ...FilterStyle, position: 'sticky' }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ minHeight: '32px' }}
          m={2}
          spacing={2}
        >
          <SectionTitle kMarginTop={0} kMarginBottom={0} kComponent="h2" kVariant="h5" kTitle={t('search_page.filters')} />
          <Box>
            <Button
              disabled={!(searchCurrency.length > 0 || (categoriesApplied && categoriesApplied.length > 0))}
              onClick={() => {
                removeAllCategoriesApplied();
                removeAllCategoriesSelected();
                setSearchCurreny('');
                if (props.showDrawer) { props.showDrawer(); }
              }}
              size="small"
              variant="text"
            >
              {t('search_page.clear')}

            </Button>
          </Box>
        </Stack>
        <Divider />
        <CardContent className="lmiw-content">
          <Typography
            className="lmiw-subtitle"
            mb={2}
            variant="subtitle1"
          >
            {t('search_page.categories')}
          </Typography>
          <List
            dense
            disablePadding
            className="lmiw-list"
          >
            {categories ? categories.map((value: any, index: number) => {
              const labelId = `checkbox-list-label-${value.idCategory}`;
              return (
                <Fade key={value.idCategory} in={!!categories} timeout={index * 100}>
                  <ListItem
                    dense
                    disablePadding
                  >
                    <ListItemButton
                      role={undefined}
                      onClick={() => addCategory({
                        id: value.idCategory,
                        title: value.category,
                      })}
                      dense
                    >
                      <ListItemIcon sx={{ minWidth: '25px' }}>
                        <Checkbox
                          edge="start"
                          checked={categoriesSelected ? (categoriesSelected.filter((f: any) => f.id === value.idCategory).length > 0) : false}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                          checkedIcon={(
                            <Box className="lmiw-box-checkbox active">
                              <CheckOutlinedIcon sx={{ fontSize: 18 }} />
                            </Box>
                          )}
                          icon={<Box className="lmiw-box-checkbox" />}
                          sx={{ padding: '0' }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={`${t(`categories.${value.category.trim()}`)}`} />
                    </ListItemButton>
                  </ListItem>
                </Fade>
              );
            })
              : (
                <>
                  {skeletonItems.map((value: number) => (
                    <Stack
                      key={value}
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={2}
                      sx={{ px: 0.7, py: 0.38 }}
                    >
                      <Skeleton height="30px" width={18} />
                      <Skeleton height="30px" width={value} />
                    </Stack>
                  ))}
                </>
              )}
            <ToggleButtonGroup
              color="primary"
              value={searchCurrency}
              exclusive
              onChange={handleChangeCurrency}
              aria-label="Platform"
            >
              <ToggleButton value="usd">USD</ToggleButton>
              <ToggleButton value="mxn">MXN</ToggleButton>
            </ToggleButtonGroup>
            {!isMobile && (
            <Box mt={2}>
              <Button
                // disabled={!(categoriesSelected && categoriesSelected.length > 0)}
                variant="contained"
                onClick={() => {
                  window.scroll({ top: 0 });
                  applyCategories();
                  if (props.showDrawer) { props.showDrawer(); }
                }}
                className="lmiw-btn-apply"
              >
                {t('search_page.apply')}
              </Button>
            </Box>
            )}

            <Box height={140} />
          </List>
        </CardContent>
      </Card>
    </>
  );
};
export default function Filters() {
  const { t } = useTranslation('global');
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    applyCategories,
  }: any = useContext(exploreContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <>
      {isMobile ? (
        <>
          <Button size="small" variant="outlined" startIcon={<FilterAltOutlinedIcon fontSize="small" />} onClick={handleDrawerToggle}>
            {t('search_page.filters')}
          </Button>
          <Drawer
            open={mobileOpen}
            onClose={handleDrawerToggle}
            sx={{ display: { xs: 'block', sm: 'none' } }}
          >
            <Items showDrawer={handleDrawerToggle} />
            <Box mt="8px" mb="8px" mr="8px">

              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
                sx={{ px: 0.7, py: 0.38 }}
              >
                <Button
                // disabled={!(categoriesSelected && categoriesSelected.length > 0)}
                  variant="contained"
                  onClick={() => {
                    window.scroll({ top: 0 });
                    applyCategories();
                    if (handleDrawerToggle) { handleDrawerToggle(); }
                  }}
                  className="lmiw-btn-apply"
                >
                  {t('search_page.apply')}
                </Button>
              </Stack>
            </Box>
          </Drawer>
        </>
      ) : <Items />}

    </>
  );
}

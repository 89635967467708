import {
  Avatar,
  Box, Divider, FormControl, FormControlLabel, IconButton, InputAdornment, OutlinedInput, Stack, styled, Switch, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { SwitchProps } from '@mui/material/Switch';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import AddEditCirclesContext from '../../../../../../../context/circles/addEditCirclesContext';
import { circleItemElementModel } from '../../../AddCircle.models';
import ActivityCancelStyle from '../../../../../Activity/ActivityDetail/Components/ActivityCancel/ActivityCancel.style';
import { InputForm } from '../../../../../../../components/Common';
import MyCirclesListingContext from '../../../../../../../context/myCirclesListing/myCirclesListingContext';

export default function ManageItemsModal() {
  const circlesContextLog = useContext(AddEditCirclesContext);
  const {
    circleItemsEdit,
    filterItemManageElements,
    circleItemsEditFilter,
    circleManageUpdateItem,
    setItemPrepareUpdate,
    setModalItemEditPage,
    setReasonToDisableItem,
    pageEditItem,
    circleInfoEdit,
  }: any = circlesContextLog;

  const {
    circle,
  }: any = useContext(MyCirclesListingContext);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const newTimer = setTimeout(() => {
      if (circleItemsEdit) {
        filterItemManageElements(search, circle?.idCatLndCircle);
      }
    }, 500);
    return () => clearTimeout(newTimer);
  }, [search]);

  const IOSSwitch = styled((props: SwitchProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#038DE2' : '#038DE2',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  const onChangeSwitch = (item: circleItemElementModel) => {
    const switchVal = !item.active;
    const idCatLndCircle = circle.idCatLndCircle ? circle?.idCatLndCircle : circleInfoEdit?.idCatLndCircle;

    const updateItem = (page: number) => {
      setItemPrepareUpdate(item.idCatLndItem, switchVal);
      setModalItemEditPage(page);
    };

    const manageUpdateItem = () => {
      circleManageUpdateItem(item.idCatLndItem, switchVal, true, '', idCatLndCircle);
    };

    if (!item.circlesAvailable) {
      updateItem(3);
    } else if (item.memberItem && !switchVal) {
      updateItem(2);
    } else {
      manageUpdateItem();
    }
  };

  const goToSearch = (value: string) => {
    setSearch(value);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation('global');

  switch (pageEditItem) {
    case 1:
      return (
        <>
          <Box>
            <Box sx={{ mb: '16px', mx: isMobile ? '16px' : '30px' }}>
              <FormControl
                sx={{ width: '100%' }}
                onChange={(e: any) => setSearch(e.target.value)}
                onKeyDown={(e: any) => (e.keyCode == 13
                  ? goToSearch(e.target.value)
                  : '')}
              >
                <OutlinedInput
                  sx={{ borderRadius: '10px' }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  value={search}
                  fullWidth
                  onFocus={() => {
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                    }, 100);
                  }}
                  startAdornment={(
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )}
                  endAdornment={(
                    search.length > 0 && (
                      <InputAdornment position="start">

                        <IconButton color="primary" onClick={() => { goToSearch(''); }}>
                          <CancelIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  )}
                  placeholder={t('circles.add_circle_page.item_name')}
                />
              </FormControl>
            </Box>
            <Typography sx={{
              mb: '16px', mx: isMobile ? '16px' : '30px', fontSize: '16px', color: '#999da2',
            }}
            >
              {t('circles.add_circle_page.my_items')}
            </Typography>
            <Divider sx={{ width: '100%', mb: '16px' }} />
            <Stack flexDirection="column">
              {
                circleItemsEditFilter?.filter((element: any) => !element.memberItem).map((item: any) => (
                  <Stack key={uuid()}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mx: isMobile ? '16px' : '30px',
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Avatar
                          variant="rounded"
                          alt={item?.name}
                          src={item?.itemPicture}
                          sx={{
                            width: '80px', height: '80px', mr: '16px', borderRadius: '7px',
                          }}
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Typography sx={{
                            fontSize: '16px',
                            fontWeight: '600',
                            mb: '4px',
                            overflowWrap: 'anywhere',
                          }}
                          >
                            {item?.name}
                          </Typography>
                          <Typography sx={{
                            fontSize: '14px',
                            fontWeight: '500',
                            mb: '4px',
                          }}
                          >
                            $
                            {item?.price}
                            {' '}
                            {item?.currency}
                            {' '}
                            {t('circles.add_circle_page.daily')}
                          </Typography>
                          <Typography sx={{
                            fontSize: '14px',
                            fontWeight: '400',
                            color: '#707479',
                            overflowWrap: 'anywhere',
                          }}
                          >
                            {item?.dscription}
                          </Typography>
                        </Box>
                      </Box>

                      <Box>
                        <FormControlLabel
                          label=""
                          control={(
                            <IOSSwitch
                              sx={{ mr: -2 }}
                              checked={item?.active}
                              onChange={() => { onChangeSwitch(item); }}
                            />
                          )}
                        />
                      </Box>
                    </Box>
                    <Divider sx={{ mb: '16px', mt: '16px' }} />
                  </Stack>
                ))
              }
            </Stack>
            {
              (circle?.isOwner || circle?.isAdmin) && (
                <>
                  <Typography sx={{
                    mb: '16px', mx: isMobile ? '16px' : '30px', fontSize: '16px', color: '#999da2',
                  }}
                  >
                    {t('circles.add_circle_page.member_items')}
                  </Typography>
                  <Divider sx={{ width: '100%', mb: '16px' }} />

                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {
                    circleItemsEditFilter
                      ?.filter((element: any) => element.memberItem)
                      .map((item: any) => (
                        <Stack key={uuid()} flexDirection="column">
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              mx: isMobile ? '16px' : '30px',
                            }}
                          >
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <Avatar
                                variant="rounded"
                                alt={item?.name}
                                src={item?.itemPicture}
                                sx={{
                                  width: '80px', height: '80px', mr: '16px', borderRadius: '7px',
                                }}
                              />
                              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography sx={{
                                  fontSize: '16px',
                                  fontWeight: '600',
                                  mb: '4px',
                                }}
                                >
                                  {item?.name}
                                </Typography>
                                <Typography sx={{
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  mb: '4px',
                                }}
                                >
                                  $
                                  {item?.price}
                                  {' '}
                                  {item?.currency}
                                  {' '}
                                  {t('circles.add_circle_page.daily')}
                                </Typography>
                                <Typography sx={{
                                  fontSize: '14px',
                                  fontWeight: '400',
                                  color: '#707479',
                                }}
                                >
                                  {item?.dscription}
                                </Typography>
                              </Box>
                            </Box>

                            <Box>
                              <FormControlLabel
                                label=""
                                control={(
                                  <IOSSwitch
                                    sx={{ mr: -2 }}
                                    checked={circleInfoEdit?.canUploadItem === 2 ? false : item?.active}
                                    onChange={() => { onChangeSwitch(item); }}
                                  />
                                )}
                              />
                            </Box>
                          </Box>
                          <Divider sx={{ mb: '16px', mt: '16px' }} />
                        </Stack>
                      ))
                  }
                  </Box>
                </>
              )
            }
          </Box>
        </>
      );
    case 2:
      return (
        (
          <>
            <Box sx={ActivityCancelStyle}>
              <Box
                px={3}
                pb={1}
                pt={0}
              >
                <InputForm
                  id="other"
                  label={t('circles.add_circle_page.reason_disable_item')}
                  helper_text={t('activity.cancel.required_field')}
                  max_lenght="420"
                  input_params={{
                    multiline: false,
                    required: true,
                    placeholder: t('circles.add_circle_page.reason_disabling'),
                    name: 'Reason',
                    onChange: (e: any) => {
                      setReasonToDisableItem(e.target.value);
                    },
                  }}
                  form_control_params={{
                    fullWidth: true,
                  }}
                />
              </Box>

            </Box>
          </>
        )
      );
    case 3:
      return (
        (
          <>
            <Box sx={ActivityCancelStyle}>
              <Box
                px={3}
                pb={1}
                pt={0}
              >
                <Typography component="span" className="lmiw-status-type">
                  {t('circles.add_circle_page.item_unavailable')}
                </Typography>
              </Box>

            </Box>
          </>
        )
      );
    default:
      return (<></>);
  }
}

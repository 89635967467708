// import { makeStyles } from '@mui/styles';
// import { Theme } from '@mui/system';
import LmiTheme from '../../../../../themes/lmi-mui-theme/lmi-theme';

const WhyLendMeItStyle = {
  h6: {
    fontSize: 22,
    fontWeight: 'bold',
    color: LmiTheme.light.systemBackground.primary,
  },
  'p.lmi-whylendmeit': {
    fontWeight: '300',
    fontSize: 18,
    color: LmiTheme.light.systemBackground.primary,
  },

  'p.lmi-box': {
    fontSize: 16,
    fontWeight: '100',
    lineHeight: 1.2,
    color: LmiTheme.light.systemBackground.primary,
  },

  '.MuiPaper-root': {
    boxShadow: '0 13px 18px 0 rgba(104, 129, 146, 0.09) !important',
    borderRadius: 4,
    // border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
  },
  '.MuiCardContent-root': {
    p: 3,
    height: { lg: 220, sm: 200, xs: 'auto' },
    borderRadius: 2,
  },
};

export default WhyLendMeItStyle;

import { LmiTheme } from '../../..';

const ListUserMenuStyle = {
  '.MuiMenu-paper': {
    // top: { md: '100px !important', xs: '60px !important' },
    border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
    marginTop: '5px',
    borderRadius: 4,
    svg: {
      fill: LmiTheme.light.accent,
      width: 20,
      height: 20,
    },
  },
};

export default ListUserMenuStyle;

import { LmiTheme, LmiColorPalette } from '../../../../../../components/Common';

const ActivityReviewModalStyle = {
  width: '100%',
  '.lmiw-Activity-cancel-title': {
    color: LmiTheme.light.systemBackground.tertiary,
    fontSize: 18,
  },
  '.lmiw-review-btn': {

    '.lmiw-btn-dislike': {
      borderRadius: '100%',
      padding: 0.5,
      border: `2px solid ${LmiColorPalette.redScheme[200]}`,
      button: {
        padding: 2,
        backgroundColor: LmiColorPalette.redScheme[200],
      },
      '&.lmi-btn-active': {
        borderColor: LmiColorPalette.redScheme[600],
        button: {
          backgroundColor: LmiColorPalette.redScheme[600],
        },
      },
    },
    '.lmiw-btn-like': {
      borderRadius: '100%',
      padding: 0.5,
      border: `2px solid ${LmiColorPalette.blueScheme[200]}`,
      button: {
        padding: 2,
        backgroundColor: LmiColorPalette.blueScheme[200],
      },
      '&.lmi-btn-active': {
        borderColor: LmiColorPalette.blueScheme[600],
        button: {
          backgroundColor: LmiColorPalette.blueScheme[600],
        },
      },
    },
  },

  p: {
    fontWeight: 500,
    fontSize: 16,
  },
};

export default ActivityReviewModalStyle;

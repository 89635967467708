import { LmiTheme } from '../../../components/Common';

const ActivityListStyle = {
  width: '100%',
  '.lmiw-tab': {
    color: LmiTheme.light.systemBackground.secondary,
    height: '56px',
    px: 3,
    fontSize: { xs: '12px', sm: '14px' },
    textTransform: 'capitalize',
    borderRadius: 0,
    m: 0,
    '&.active': {
      color: LmiTheme.light.accent,
      borderBottom: `2px solid ${LmiTheme.light.accent}`,
    },
  },

};

export default ActivityListStyle;

import { DefaultApi, Configuration } from '@lendmeit/api_lambda_lmi';
// import { Auth } from 'aws-amplify';
import * as AWS from 'aws-sdk/global';

import aws4Interceptor from 'aws4-axios';
import axios from 'axios';

const urlApi = process.env.REACT_APP_API_LAMBDA!;

const ApiLambdaLMI = (
) => {
  // const token = localStorage.getItem('token');

  const { credentials }: any = AWS.config;
  const accessKeyId = credentials?.accessKeyId!;
  const secretAccessKey = credentials?.secretAccessKey!;
  const sessionToken = credentials?.sessionToken!;

  credentials?.refresh();
  const modelIntance: any = {
    baseURL: urlApi,
  };

  // if (token) {
  //   modelIntance.headers = {
  //     Authtoken: `Bearer ${token}`,
  //     'Content-Type': 'application/json',
  //   };
  // }

  const axiosInstance = axios.create(modelIntance);

  const interceptor = aws4Interceptor({
    region: process.env.REACT_APP_REGION!,
    service: 'execute-api',
  },
  {
    accessKeyId,
    secretAccessKey,
    sessionToken,
  });

  axiosInstance.interceptors.request.use(interceptor);
  axiosInstance.interceptors.response.use((response) => response, (error: any) => Promise.reject(error));

  const configApi = new Configuration();

  const api = new DefaultApi(
    configApi,
    urlApi,
    axiosInstance,
  );

  return api;
};

export default ApiLambdaLMI;

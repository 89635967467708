/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  CardMedia,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import React from 'react';
import { DialogCommon, LmiTheme } from '../ProfilePrivate/Components';
import createdCircleImage from '../../../assets/create_listing/listingCreated.png';
import confetti from '../../../assets/create_listing/confeti.png';
import confettiMobile from '../../../assets/create_listing/confetimobile.png';
import MyListingContext from '../../../context/myListing/myListingContext';
import { emptyItemModel } from '../Listing/Components/AddItem/AddItem.models';
import addEditItemContext from '../../../context/addEditItem/addEditItemContext';
import ShareItem from './Components/ShareItem';

interface DialogListingCreatedT {
  handleDialog: (addNewItem: boolean) => void,
}

function DialogListingCreated(props: DialogListingCreatedT) {
  const { handleDialog } = props;
  const { details }: any = React.useContext(MyListingContext);
  const addEditContextLog = React.useContext(addEditItemContext);
  const { updateAccessoriesList }: any = addEditContextLog;

  const { t } = useTranslation('global');

  const [open, setOpen] = React.useState(false);

  const handleModalToggle = () => {
    setOpen(true);
  };

  const closeDialogModal = () => {
    setOpen(false);
  };

  const discardLocalStorage = () => {
    localStorage.removeItem('draftItemIdImage');
    localStorage.removeItem('draftItemIdVideo');
    localStorage.removeItem('draftItemInfo');
    localStorage.removeItem('draftAccessoriesInfo');
    localStorage.removeItem('draftRequirementList');
    localStorage.removeItem('draftBlockedIndDates');
  };

  const addNewItem = () => {
    discardLocalStorage();
    updateAccessoriesList([]);
    openAddItemPage();
  };

  const closeAddNewItem = () => {
    history.push('/listing');
    handleDialog(false);
  };

  const history = useHistory();

  const state = history.location.state as {
    kItemId: string,
    kItemIndex: number,
    updateSwiper: false,
    kShowDetailsMobile: false
  };
  const openAddItemPage = () => {
    window.scrollTo(0, 0);
    details(emptyItemModel(), []);
    history.push({
      pathname: '/listing/edit',
      state: {
        ...state,
        kItemId: 0,
        kItemIsNew: true,
        kItemIndex: state?.kItemIndex ? state.kItemIndex : 0,
        updateSwiper: false,
        kShowDetailsMobile: false,
      },
    });
    handleDialog(true);
  };

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        maxWidth="686px"
        spacing={0}
        py={3}
      >
        {
          isMobile ? (
            <>
              <Box
                width="100%"
                height="54%"
                position="absolute"
                sx={{
                  top: 0,
                  zIndex: 0,
                  backgroundImage: `url(${confettiMobile})`,
                  backgroundPosition: 'center',
                  height: '196px',
                }}
              />
              <Typography sx={{
                mb: -1,
                fontSize: 'h5.fontSize',
                fontWeight: 600,
                maxWidth: '235px',
                textAlign: 'center',
              }}
              >
                {t('checkout.congratulations')}
              </Typography>

            </>
          ) : (
            <>
              <Box
                width="100%"
                height="54%"
                position="absolute"
                sx={{
                  top: 0,
                  zIndex: 0,
                  backgroundImage: `url(${confetti})`,
                  backgroundPosition: 'center',
                  height: '281.3px',
                }}
              />
              <Typography sx={{
                pt: '35px',
                fontSize: 'h5.fontSize',
                fontWeight: 600,
                maxWidth: '285px',
                textAlign: 'center',
              }}
              >
                {t('checkout.congratulations')}
              </Typography>

            </>
          )
        }

        <CardMedia
          component="img"
          alt="confetti"
          image={createdCircleImage}
          sx={{ width: '70%', maxWidth: '325px', zIndex: 2 }}
        />
        <Box
          sx={{
            fontSize: 'subtitle1.fontSize',
            fontWeight: 500,
            color: LmiTheme.light.systemBackground.tertiary,
            maxWidth: '70%',
            textAlign: 'center',
            mb: '30px',
            zIndex: 1,
          }}
        >
          {t('listing.listing_created')}
        </Box>
        <Button
          onClick={() => {
            addNewItem();
          }}
          disableElevation
          variant="contained"
          size="large"
          sx={{
            px: 3,
            fontSize: 'subtitle1.fontSize',
            textTransform: 'none',
            fontWeight: 'bold',
            mb: '30px',
          }}
        >
          {t('listing.add_new_item')}
        </Button>
        <Button
          onClick={() => {
            handleModalToggle();
          }}
          disableElevation
          variant="contained"
          size="large"
          sx={{
            px: 3,
            fontSize: 'subtitle1.fontSize',
            textTransform: 'none',
            fontWeight: 'bold',
            mb: '30px',
          }}
        >
          {t('circles.add_circle_page.share_with_friends')}
        </Button>
        <Button
          variant="text"
          onClick={closeAddNewItem}
          sx={{
            textTransform: 'none',
            fontSize: '17px',
          }}
        >
          {t('circles.add_circle_page.close')}
        </Button>

        <DialogCommon
          kTitle={t('listing.share_item')}
          kMaxWidth="sm"
          kEnableBorderRadius
          kMaxHeight={isMobile ? '450px' : 'auto'}
          kBorderRadius={isMobile ? 0 : '10px'}
          kFixedDialog={0}
          kFixedDialogPosition="fixed"
          kDisableBottomDivider
          kDisableContentPadding
          kKeepBtnSize
          kCenterTitle
          kSlideTransition={isMobile}
          kTopDivider
          kOpenDialogLists={open}
          kOnClose={closeDialogModal}
          kFullScreenMobile={false}
          kDisableActions
          kContent={
            (
              <ShareItem />
            )
          }
        />
      </Stack>
    </>
  );
}

export default DialogListingCreated;

// import { CheckCircle } from '@mui/icons-material';
import {
  Avatar,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material';
import LmiTheme from '../../../../../themes/lmi-mui-theme/lmi-theme';
import { AccesoriesPanelListItemModels } from './AccesoriesPanelListItem.models';

function AccesoriesPanelListItem(props: AccesoriesPanelListItemModels) {
  return (
    <>
      <ListItem
        key={0}
        divider={props.kDivider}
        sx={{ px: 2 }}
        secondaryAction={
          props.kPrice !== undefined
          && (
            <>
              $
              {props.kPrice}
              <> </>
              {' '}
              {props.kCurrency}
              {' '}
              {props.kPriceType}
            </>
          )
        }
      >
        {
          props.kListItemIcon !== undefined
          && <ListItemIcon>{props.kListItemIcon}</ListItemIcon>
        }
        {
          props.kAvatarImageSrc !== undefined
          && <Avatar variant={props.kAvatarVariant} alt={props.kAvatarAlt} src={props.kAvatarImageSrc} sx={{ width: props.kAvatarWidth, height: props.kAvatarHeight }} />
        }
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <ListItemText
            primaryTypographyProps={{ color: LmiTheme.light.systemBackground.primary, fontSize: 'subtitle1.fontSize' }}
            primary={props.kPrimaryText}
            secondary={props.kSecondaryText}
          />
        </Stack>
      </ListItem>
    </>
  );
}

AccesoriesPanelListItem.defaultProps = {
  kDivider: true,
  kListItemIcon: undefined,
  kAvatarVariant: undefined,
  kAvatarAlt: 'Avatar',
  kAvatarImageSrc: undefined,
  kAvatarWidth: 44,
  kAvatarHeight: 44,
  kPrimaryText: '',
  kSecondaryText: '',
  kPrice: undefined,
  kPriceType: undefined,
};

export default AccesoriesPanelListItem;

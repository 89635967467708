import { LmiTheme } from '../../../../../../components/Common';

const ActivityLocationStyle = {
  width: '100%',
  height: '100%',
  '.lmiw-activity-location': {

    'p.lmiw-location-address': {
      maxWidth: { xs: '100px', sm: '250px' },
      fontSize: '12px !important',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    // svg: {
    //   color: LmiTheme.light.accent,
    // },
  },
  '.lmiw-activity-map': {
    borderRadius: '10px',
  },
  '.lmiw-location-info': {
    fontSize: '14px',
    m: 2,
    textAlign: 'center',
    color: LmiTheme.light.systemBackground.secondary,
  },

};

export default ActivityLocationStyle;

/* eslint-disable no-nested-ternary */
import {
  ListItemButton, Avatar, ListItemAvatar, ListItem, List, Typography, Stack, Chip, Button, Box, useMediaQuery, useTheme,
  // useTheme, useMediaQuery,
} from '@mui/material';
import { v4 as uuid } from 'uuid';
// import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { LmiTheme } from '../../ProfilePrivate/Components';
import { LoadingLayout } from '../../../../components/Common';
import { MyCircleListingModels } from '../MyCircleListing/MyCircleListing.models';
import MyCirclesListingContext from '../../../../context/myCirclesListing/myCirclesListingContext';
import { AddEditCircleModel, CircleDetailInfoModel } from '../AddCircle/AddCircle.models';

export default function MyCircles(props: MyCircleListingModels) {
  const { t } = useTranslation('global');
  const {
    circle,
    circles,
    loading,
    suggestedCircles,
    circlesTypeToShow,
    searchedCircles,
    requestJoinCircle,
  }: any = useContext(MyCirclesListingContext);
  const [arrayCircles, setArrayCircles] = useState([]);
  const uiTheme = useTheme();
  const isMobile = useMediaQuery(uiTheme.breakpoints.down('md'));

  useEffect(() => {
    if (circlesTypeToShow == 'suggested') {
      setArrayCircles(props.kUserCircles
        ? circles
        : suggestedCircles);
    } else if (circlesTypeToShow == 'searched') {
      setArrayCircles(searchedCircles);
    } else {
      setArrayCircles(props.kUserCircles
        ? circles?.filter((circleElement: CircleDetailInfoModel) => circleElement?.isOwner)
        : circles?.filter((circleElement: CircleDetailInfoModel) => !circleElement?.isOwner).sort((a: any) => {
          if (a.isAdmin) {
            return -1;
          }
          if (!a.isAdmin) {
            return 1;
          }
          return 0;
        }));
    }
  }, [circlesTypeToShow, searchedCircles, suggestedCircles, circles]);

  const isOwnerOfCircles = circles?.some((circleElement: any) => circleElement.isOwner == true);
  props.kIsOwnerOfCircles?.(isOwnerOfCircles);

  const areCirclesIBelong = circles?.some((circleElement: any) => circleElement.isAdmin == true || circleElement.isMember == true);
  props.kAreCirclesIBelong?.(areCirclesIBelong);
  const areSuggestedCircles = suggestedCircles?.length > 0;
  props.kAreSuggestedCircles?.(areSuggestedCircles);

  props.kShowSearchedCircles?.(searchedCircles);

  return (
    <>
      <List
        disablePadding
        sx={{
          width: '100%',
          bgcolor: 'background.paper',
        }}
      >
        {
          arrayCircles
            ? arrayCircles.map((listCircle: AddEditCircleModel, index: number) => (
              <ListItem
                key={uuid()}
                disabled={loading}
                alignItems="flex-start"
                disablePadding
                onClick={() => (!props.kReadOnly && !loading && props.onChange ? props.onChange(listCircle, index) : null)}
              >
                <ListItemButton
                  divider
                  onClick={() => props.kOpenCircleDetail?.(true)}
                  sx={{
                    maxHeight: '150px',
                    cursor: props.kReadOnly ? 'auto' : 'pointer',
                    pt: '20px',
                    pb: '20px',
                    backgroundColor: (!props.kReadOnly && circle && circle.idCatLndCircle === listCircle.idCatLndCircle) ? LmiTheme.light.accentActiveBackground : '',
                  }}
                >
                  <ListItemAvatar sx={{ marginRight: '2px' }}>
                    <Avatar
                      sx={{
                        width: 80,
                        height: 80,
                        marginRight: 1,
                      }}
                      variant="circular"
                      alt={listCircle.name}
                      src={listCircle.circlePicture}
                    />
                  </ListItemAvatar>
                  <Stack
                    direction="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    sx={{ width: 'calc(100% - 50px)' }}
                  >
                    <Stack flexDirection="row" justifyContent="space-between" sx={{ width: '100%' }}>
                      <Box sx={{ width: isMobile ? '150px' : '100%' }}>
                        <Typography
                          component="label"
                          noWrap
                          fontSize={16}
                          fontWeight="500"
                          sx={{
                            textTransform: 'capitalize',
                            width: { md: '150px', lg: '210px', sm: '150px' },
                            display: 'block',
                            color: '#1d2024',
                            mb: '5px',
                          }}
                        >
                          {listCircle.name ? listCircle.name : t('circles.members.no_title')}
                        </Typography>
                      </Box>
                      {
                        // eslint-disable-next-line no-nested-ternary
                        (listCircle?.isAdmin || listCircle?.isOwner) ? (
                          <Chip
                            label={listCircle?.isAdmin ? t('circles.members.admin').toUpperCase() : t('circles.members.owner').toUpperCase()}
                            variant="outlined"
                            sx={
                              listCircle?.isOwner
                                ? ({
                                  height: '20px', fontSize: '10px', borderColor: '#2daa24', color: '#2daa24',
                                })
                                : ({
                                  height: '20px', fontSize: '10px', borderColor: '#922bfa', color: '#922bfa',
                                })
                            }
                          />
                        ) : (
                          (listCircle?.isMember)
                            ? null
                            : (
                              <Button
                                onClick={() => {
                                  requestJoinCircle(listCircle?.idCatLndCircle);
                                }}
                                disabled={listCircle?.pendingApproval}
                                variant="text"
                                sx={{ textTransform: 'none' }}
                              >
                                {listCircle?.pendingApproval ? t('circles.main.pending') : t('circles.main.join')}
                              </Button>
                            )
                        )
                      }
                    </Stack>

                    <Typography
                      component="p"
                      fontSize={14}
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        color: '#1d2024',
                        mb: '5px',
                      }}
                    >
                      {
                        listCircle.totalMembers
                          ? `${listCircle.totalMembers} ${t('circles.members.members')}`
                          : t('circles.members.no_members')
                      }
                    </Typography>
                    <Typography
                      component="p"
                      fontSize={14}
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        color: '#707479',
                        maxWidth: '250px',
                      }}
                    >
                      {listCircle.dscription ? listCircle.dscription : `${t('profile.no_description')}`}
                    </Typography>
                  </Stack>
                </ListItemButton>
              </ListItem>
            ))
            : <LoadingLayout />
        }
      </List>
    </>
  );
}

// import { useState } from 'react';
import {
  Stack, Box, Fab, Container, useMediaQuery, useTheme, Button,

} from '@mui/material';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
import i18next from 'i18next';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import CopyrightStyle from './Copyright.style';

export default function Copyright() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const date = new Date().getFullYear();

  function changeLanguageMenu() {
    if (cookies.get('i18next') === 'en') {
      i18next.changeLanguage('es');
    } else { i18next.changeLanguage('en'); }
  }

  const { t } = useTranslation('global');
  return (
    <Box sx={CopyrightStyle}>
      <Container
        disableGutters={!isDesktop ?? false}
      >
        <Stack
          direction={{ md: 'row', xs: 'column' }}
          justifyContent={{ md: 'space-between', xs: 'center' }}
          alignItems="center"
          className="lmiw-stackcontent"
          spacing={1}
        >
          <Stack
            direction="row"
            justifyContent={{ xs: 'center', sm: 'flex-end' }}
            alignItems="center"
            spacing={1}
          >
            <Button
              variant="text"
              size="small"
              sx={{ textTransform: 'capitalize' }}
              onClick={() => changeLanguageMenu()}
            >
              {
                cookies.get('i18next') === 'en' ? (
                  'Español'
                ) : 'English'
              }
            </Button>
            <Fab
              target="_blank"
              size="small"
              className="lmiw-fabicon-social-footer"
              aria-label="facebook"
              href="https://www.facebook.com/lendmeitapp/"
            >
              <FacebookOutlinedIcon />
            </Fab>
            <Fab
              target="_blank"
              size="small"
              className="lmiw-fabicon-social-footer"
              aria-label="instagram"
              href="https://www.instagram.com/lendmeit/"
            >
              <InstagramIcon />
            </Fab>
            <Fab
              target="_blank"
              size="small"
              className="lmiw-fabicon-social-footer"
              aria-label="twitter"
              href="https://twitter.com/lendmeitapp/"
            >
              <TwitterIcon />
            </Fab>
            <Fab
              target="_blank"
              size="small"
              className="lmiw-fabicon-social-footer"
              aria-label="youtube"
              href="https://www.youtube.com/channel/UCsWJj7fzvJM786uTAstKTnQ/"
            >
              <YouTubeIcon />
            </Fab>
          </Stack>
          <Box className="lmiw-rights-reserved">
            Lend Me It, Inc.
            {t('footer.copyright')}
            Copyright
            {' '}
            {date}
          </Box>
        </Stack>
      </Container>

    </Box>
  );
}

import React, { useContext, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box, Divider, Grid, IconButton, InputAdornment, TextField, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import LmiTheme from '../../../../../themes/lmi-mui-theme/lmi-theme';
import publicProfileContext from '../../../../../context/publicProfile/publicProfileContext';

function DialogChangePassword() {
  const pPublicProfileContext = useContext(publicProfileContext);

  const {
    updateChangePassword,
    changePasswordInfo,
  }: any = pPublicProfileContext;
  interface StateShowPassword {
    kPassword: string;
    kShowPassword: boolean;
  }
  const [valuesShowPassword, setValuesShowPassword] = useState<StateShowPassword>({
    kPassword: '',
    kShowPassword: false,
  });
  const [valuesShowNewPassword, setValuesShowNewPassword] = useState<StateShowPassword>({
    kPassword: '',
    kShowPassword: false,
  });
  const [valuesShowConfirmPassword, setValuesShowConfirmPassword] = useState<StateShowPassword>({
    kPassword: '',
    kShowPassword: false,
  });

  // const changeShowPassword = (prop: keyof StateShowPassword) => (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setValuesShowPassword({ ...valuesShowPassword, [prop]: event.target.value });
  // };

  const handleInputchange = ({ target }: any) => {
    updateChangePassword({
      ...changePasswordInfo,
      [target.name]: target.value,
    });
  };

  const handleClickShowPassword = () => {
    setValuesShowPassword({
      ...valuesShowPassword,
      kShowPassword: !valuesShowPassword.kShowPassword,
    });
  };
  const handleClickShowNewPassword = () => {
    setValuesShowNewPassword({
      ...valuesShowNewPassword,
      kShowPassword: !valuesShowNewPassword.kShowPassword,
    });
  };
  const handleClickShowConfirmPassword = () => {
    setValuesShowConfirmPassword({
      ...valuesShowConfirmPassword,
      kShowPassword: !valuesShowConfirmPassword.kShowPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const { t } = useTranslation('global');

  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid item xs={12}>
          <Box pb={1}>
            <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
            <Typography component="span" fontWeight={500}>{t('profile.current_password')}</Typography>
          </Box>
          <TextField
            fullWidth
            id="outlined-basic"
            variant="outlined"
            placeholder={t('profile.enter_current_password')}
            type={valuesShowPassword.kShowPassword ? 'text' : 'password'}
            onChange={handleInputchange}
            name="oldPassword"
            value={changePasswordInfo?.oldPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {valuesShowPassword.kShowPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
          />
          {/* <Box pt={1} pb={3}>
            <Link href="https://localhost:3000/privateprofile" underline="hover"> Forgot your password? </Link>
          </Box> */}
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box pb={1}>
            <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
            <Typography component="span" fontWeight={500}>{t('profile.new_password')}</Typography>
          </Box>
          <TextField
            fullWidth
            id="outlined-basic"
            variant="outlined"
            placeholder={t('profile.enter_new_password')}
            type={valuesShowNewPassword.kShowPassword ? 'text' : 'password'}
            onChange={handleInputchange}
            value={changePasswordInfo?.newPass}
            name="newPass"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {valuesShowNewPassword.kShowPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box pb={1}>
            <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
            <Typography component="span" fontWeight={500}>{t('profile.confirm_password')}</Typography>
          </Box>
          <TextField
            fullWidth
            id="outlined-basic"
            variant="outlined"
            placeholder={t('profile.confirm_your_password')}
            type={valuesShowConfirmPassword.kShowPassword ? 'text' : 'password'}
            onChange={handleInputchange}
            name="newPassConfirm"
            value={changePasswordInfo?.newPassConfirm}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {valuesShowConfirmPassword.kShowPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default DialogChangePassword;

import { LmiTheme } from '../../../../../components/Common';

const ResultListingStyle = {
  width: 'auto',
  '.lmiw-header': {
    top: { xs: 124, md: 64 },
    zIndex: 1,
    position: 'sticky',
    '.lmiw-stack': {
      minHeight: '32px',
    },
  },
  '.lmiw-content': {
    flexGrow: 1,
    pt: 0,
    '.MuiGrid-item': {
      overflow: 'hidden',
    },
    '.lmiw-item': {
      cursor: 'pointer',
    },
    '.lmiw-img': {
      aspectRatio: '5 / 4',
      backgroundSize: 'cover',
      borderRadius: '7px',
      '.lmiw-item__price': {
        backgroundColor: 'white',
        m: 1,
        fontWeight: 'bold',
      },
    },
    '.lmiw-item__name': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
      lineHeight: 1.2,

    },
  },
  '.lmiw-btn-more': {
    minWidth: '120px',
    height: { xs: '44px', md: '46px' },
    borderRadius: 2,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    borderColor: LmiTheme.light.systemBackground.separator,
    '&:hover': {
      color: 'white',
      backgroundColor: LmiTheme.light.accent,
    },
  },
};

export default ResultListingStyle;

// import InputForm from '../../../../../../../components/Common/InputForm/InputForm';

import {
  Box,
  FormControl, IconButton, InputAdornment, OutlinedInput, Stack, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CancelIcon from '@mui/icons-material/Cancel';
import { LmiTheme, SearchSuggestion } from '../../../../../../../components/Common';
// import AddEditCirclesContext from '../../../../../../../context/circles/addEditCirclesContext';
import { AddCircleTagsModels } from './AddCircleTags.models';

export default function AddCircleTags(props: AddCircleTagsModels) {
  const setSearch = props.setTagSearch;
  const search = props.tagSearch;

  const [visibleSuggestion, setVisibleSuggestion] = useState(false);
  const [count, setCount] = React.useState(0);

  useEffect(() => {
    props.visibleSuggestion(visibleSuggestion);
    // setSearch(search);
    props.setTagSearch(search);
  }, [visibleSuggestion, search]);

  const { t } = useTranslation('global');

  return (
    <>
      <FormControl
        sx={{ width: '100%' }}
        onChange={(e: any) => setSearch(e.target.value)}
        onKeyDown={(e: any) => (e.keyCode == 13
          ? setSearch(e.target.value)
          : '')}
        className="lmiw-activity-search"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          pb={1}
        >
          <Box>
            <Typography
              component="span"
              fontSize="caption.fontSize"
              fontWeight="bold"
              color={LmiTheme.light.accent}
            >
              *
            </Typography>
            <Typography
              component="span"
              fontSize="caption.fontSize"
              fontWeight={500}
            >
              {t('circles.add_circle_page.tags_for_circle')}
            </Typography>
          </Box>
          <Typography
            component="span"
            fontSize="caption.fontSize"
            color={LmiTheme.light.systemBackground.tertiary}
            sx={{ fontSize: '12px', color: count > 25 ? 'red' : '#707479', fontWeight: 900 }}
          >
            {`${count}/`}
            25
          </Typography>
        </Stack>
        <OutlinedInput
          sx={{
            maxWidth: '626px',
            height: '48px',
            borderRadius: '10px',
            backgroundColor: '#f7f7f7',
            color: LmiTheme.light.systemBackground.tertiary,
            borderColor: '#ececec',
          }}
          inputProps={{
            maxLength: 25,
          }}
          value={search}
          onChange={(e) => {
            setCount(e.target.value.length);
            // eslint-disable-next-line no-unused-expressions
            count > 0 ? setVisibleSuggestion(true) : setVisibleSuggestion(false);
          }}
          endAdornment={(
                search.length > 0 && (
                <InputAdornment position="start">
                  <IconButton color="primary" onClick={() => { setSearch(''); setCount(0); }}>
                    <CancelIcon />
                  </IconButton>
                </InputAdornment>
                )
            )}
          placeholder={t('circles.add_circle_page.enter_tag_circle')}
        />
      </FormControl>
      {search && visibleSuggestion && (
        <SearchSuggestion
          kTop={110}
          kInputText={search}
          kOnClick={(text:string) => {
            setSearch(text);
            setVisibleSuggestion(false);
          }}
        />
      )}

    </>
  );
}

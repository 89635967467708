// import { LmiTheme } from '../../../../components/Common';

const CircleAdminsChatStyle = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '.lmiw-activity-chat-paper': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
  },

};

export default CircleAdminsChatStyle;

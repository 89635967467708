import {
  Menu, MenuItem, Box, useMediaQuery, useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { useState } from 'react';
import { toast } from 'react-toastify';
import IosShareIcon from '@mui/icons-material/IosShare';
import { IconLinkButton } from '../../../../../../../components/Common';

export default function ShareButtons({ deepLink }: any) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [shareModal, setShareModal] = useState(null);
  const open = Boolean(shareModal);
  const handleClickShare = (event: any) => {
    setShareModal(event.currentTarget);
  };
  const handleCloseShare = () => {
    setShareModal(null);
  };

  const copyShareToClipboard = () => {
    handleCloseShare();
    navigator.clipboard.writeText(deepLink);
    toast.success(t('listing_detail.url_copied'));
  };

  const { t } = useTranslation('global');

  return (
    <>
      <IconLinkButton
        kStartIcon={<IosShareIcon />}
        kText={t('listing_detail.share')}
        kHideText={isMobile}
        kFontSize={isMobile ? 'body2.fontSize' : 'body1.fontSize'}
        kFontWeight={isMobile ? '400' : 'bold'}
        kOnClick={(e: any) => handleClickShare(e)}
      />

      <Menu
        id="basic-menu"
        anchorEl={shareModal}
        open={open}
        onClose={handleCloseShare}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleCloseShare}>
          <Box
            sx={{
              // p: 0.5,
              m: 0.5,
            }}
          >

            <FacebookShareButton
              url={deepLink}
              quote="share this item"
              className="Demo__some-network__share-button"
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </Box>
          <Box
            sx={{
              // p: 0.5,
              m: 0.5,
            }}
          >
            <FacebookMessengerShareButton
              url={deepLink}
              appId="521270401588372"
              className="Demo__some-network__share-button"
            >
              <FacebookMessengerIcon size={32} round />
            </FacebookMessengerShareButton>
          </Box>
          <Box
            sx={{
              // p: 0.5,
              m: 0.5,
            }}
          >
            <TwitterShareButton
              url={deepLink}
              title=""
              className="Demo__some-network__share-button"
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
          </Box>

        </MenuItem>
        <MenuItem onClick={handleCloseShare}>
          <Box
            sx={{
              // p: 0.5,
              m: 0.5,
            }}
          >

            <WhatsappShareButton
              url={deepLink}
              title=""
              separator=":: "
              className="Demo__some-network__share-button"
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </Box>
          <Box
            sx={{
              // p: 0.5,
              m: 0.5,
            }}
          >
            <EmailShareButton
              url={deepLink}
              subject=""
              body="body"
              className="Demo__some-network__share-button"
            >
              <EmailIcon size={32} round />
            </EmailShareButton>
          </Box>
          <Box
            sx={{
              // p: 0.5,
              m: 0.5,
            }}
          >
            <TelegramShareButton
              url={deepLink}
              title=""
              className="Demo__some-network__share-button"
            >
              <TelegramIcon size={32} round />
            </TelegramShareButton>
          </Box>

        </MenuItem>
        <MenuItem onClick={copyShareToClipboard}>
          {t('listing_detail.copy_url')}
        </MenuItem>
      </Menu>
    </>
  );
}

import { LmiTheme } from '../../../../components/Common';

const ActivityListStyle = {
  width: '100%',
  height: '100%',
  overflow: 'auto',
  '.lmiw-activity-search': {
    width: '100%',
    '.MuiInputBase-root': {
      borderRadius: 2,
      input: {
        py: '13px',
      },
    },

  },
  '.lmi-tablist': {
    borderBottom: 1,
    borderColor: 'divider',
    minHeight: '56px',
    position: 'sticky',
    top: 0,
    zIndex: 2,
    backgroundColor: 'white',
    button: {
      color: LmiTheme.light.systemBackground.secondary,
      height: '56px',
      px: 3,
      fontSize: { xs: '12px', sm: '14px' },
      textTransform: 'capitalize',
      borderRadius: 0,
      m: 0,
      '&.lmi-tabactive': {
        color: LmiTheme.light.accent,
        borderBottom: `2px solid ${LmiTheme.light.accent}`,
      },
    },

  },
  '.lmi-tabpanel': {
    p: 0,
    '.lmi-title': {
      textAlign: { md: 'left', xs: 'center' },
      mt: 2,
      h4: {
        fontWeight: 'bold',
        display: 'inline',
        fontSize: {
          xs: '24px', sm: '30px', md: '28px', lg: '36px',
        },
      },

    },
    '.MuiContainer-root': {
      marginLeft: { xs: 'auto', md: 0 },
    },
    '.lmi-cont-text': {
      maxWidth: { lg: '444px', md: '370px', xs: '444px' },
    },
    '.lmi-stack-img': {
      position: 'relative',
      height: {
        md: 450, sm: 500, xs: 300,
      },
      overflow: 'hidden',
      borderRadius: 7,
      img: {
        width: '100%',
        height: 'auto',
        maxWidth: { xs: '400px', sm: '500px', lg: '640px' },
        maxHeight: '500px',
        borderRadius: 7,
      },
    },
  },
};

export default ActivityListStyle;

import { InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';

const FormInputStyle = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 7,
    position: 'relative',
    // backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    // border: '1px solid #ced4da',
    fontSize: 16,
    minwidth: 'auto',
    // padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
  },
}));

export default FormInputStyle;

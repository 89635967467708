import {
  Box, Card, Divider, Paper, Stack, Typography,
} from '@mui/material';
import { useContext } from 'react';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { useTranslation } from 'react-i18next';
import ActivityContext from '../../../../../context/activity/activityContext';
import ActivityDetailStyle from '../ActivityDetail.style';
import ActivityCircleCard from '../Components/ActivityCircleCard/ActivityCircleCard';
import { ActivityTitleStatusModel } from '../Components/ActivityStatus/ActivityStatus.models';
import SectionTitle from '../../../../../components/Common/SectionTitle/SectionTitle';
import ActivityStatusStyle from '../Components/ActivityStatus/ActivityStatus.style';
import CircleEarningsStyle from './CircleEarningsDetail.style';

export default function CircleEarningsDetail() {
  const { t } = useTranslation('global');
  const activityContext = useContext(ActivityContext);
  const {
    openedActivityItem,
    circleInfo,
  }: any = activityContext;

  const textStatus = `Congratulations you just earned $${openedActivityItem?.circleOwnerEarnings} ${openedActivityItem?.circleOwnerEarningsCurrency.toUpperCase()}`;
  return (
    <>
      <Card elevation={0} sx={ActivityDetailStyle}>
        <Box className="lmiw-detail-content">
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="end"
            spacing={2}
            px={2}
            pb={2}
          >
            <Box sx={ActivityStatusStyle}>
              <SectionTitle
                kMarginTop={3}
                kTitle={t('activity.status.status')}
                kFontSize={18}
              />
              <Paper variant="outlined" elevation={0}>
                <CardTitle
                  kColorStatus="green"
                  kTextStatus={textStatus}
                  kActionProgress={() => { }}
                />
              </Paper>
            </Box>
            <ActivityCircleCard
              circleInfo={circleInfo}
            />
            <Box sx={CircleEarningsStyle}>
              <SectionTitle
                kMarginTop={2}
                kTitle={t('activity.summary.earnings')}
                kFontSize={18}
              />
              <Paper
                variant="outlined"
                elevation={0}
                sx={{ borderRadius: '10px' }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className="lmiw-summary-total"
                >
                  <Typography className="lmiw-info">{t('activity.summary.total_earnings')}</Typography>
                  <Typography className="lmiw-info">{`$${parseFloat(openedActivityItem.circleOwnerEarnings).toFixed(2)} ${openedActivityItem?.circleOwnerEarningsCurrency.toUpperCase()}`}</Typography>
                </Stack>
                <Divider />
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className="lmiw-summary-total"
                >
                  <Typography className="lmiw-more-info">{t('activity.summary.info')}</Typography>
                </Stack>
              </Paper>
            </Box>
          </Stack>
        </Box>
      </Card>

    </>
  );
}

function CardTitle(props: ActivityTitleStatusModel) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={`lmiw-status-header lmiw-status-${props.kColorStatus}`}
      p={1}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <CheckCircleOutlineRoundedIcon />
        <Typography className="lmiw-status-title">
          {props.kTextStatus}
        </Typography>
      </Stack>
    </Stack>
  );
}

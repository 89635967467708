import {
  Box,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import NumericInput from 'material-ui-numeric-input';
import { LmiTheme } from '../../../../../components/Common';
import { InputNumericTextFieldModels } from './InputNumericTextField.models';

function InputNumerictextField(props: InputNumericTextFieldModels) {
  // eslint-disable-next-line react/jsx-indent

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      sx={{
        '.price-center': {
          '.MuiInputBase-root': {
            paddingLeft: '6px',
          },
          '.MuiInputAdornment-positionStart': {
            marginRight: '0px',
          },
          input: {
            paddingRight: '6px',
          },
        },
        'div.Mui-disabled': {
          backgroundColor: LmiTheme.light.systemBackground.separator,
          fieldset: {
            borderColor: `${LmiTheme.light.systemBackground.separator} !important`,
          },
        },
      }}

    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        pb={1}
      >
        <Box sx={{ m: props.kLabelCentered ? '0 auto' : 0 }}>

          <Stack
            flexDirection={isMobile ? 'column' : 'row'}
            justifyContent="center"
            alignItems="center"
            gap={isMobile ? 0 : 1}
            sx={{ textAlign: 'center', paddingLeft: !props.kLabelRequiredHide ? '8px' : '0', position: 'relative' }}
          >
            {
                !props.kLabelRequiredHide
                && <Typography sx={{ top: '2px', left: '0', position: 'absolute' }} component="span" fontSize="caption.fontSize" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
              }
            <Typography component="span" fontWeight="bold" fontSize={14}>
              {props.kKmNumber}
            </Typography>

            <Typography component="span" fontWeight="bold" fontSize={14}>

              {props.kLabel}
            </Typography>
          </Stack>
        </Box>
        {
          !props.kInputMaxLengthHide
          && (
            <Typography component="span" fontSize="caption.fontSize" color={LmiTheme.light.systemBackground.tertiary}>
              {/* {`${valuesDescription.description.length}/${limitDescription}`} */}
              {/* 0/ */}
              {`${props.kInputCurrentLength} /`}
              {props.kInputMaxLength}
            </Typography>
          )
        }
      </Stack>

      <NumericInput
        key={props.kInputId}
        fullWidth
        id={props.kInputId}
        placeholder={props.kInputPlaceholder}
        name={props.kInputName}
        value={props.kInputValue}
        precision={props.kPrecision}
        decimalChar="."
        thousandChar=","
        disabled={props.kDisabled}
        ref={props.kRef}
        // label="Example"
        onClick={props.kOnClick}
        onChange={props.kInputOnChange}
        // onChange={(event: any) => console.log(event.target.value)}
        variant="outlined"
        multiline={props.kInputMultiline}
        rows={props.kInputRows}
        maxRows={props.kInputMaxRows}
        required={props.kInputRequired}
        error={props.kInputError}
        helperText={props.kInputHelperText}
        sx={{ bgcolor: LmiTheme.light.systemBackground.base }}
        className={props.kInputClassName}
        inputProps={{
          style: {
            cursor: props.kOnClick === undefined ? 'auto' : 'pointer',
            textAlign: props.kInputTextAlign,
          },
          inputMode: 'numeric',
          // pattern: '[0-9]*',
          maxLength: props.kInputMaxLength,
          min: 0,
          // error: props.kInputError,
          // onChange: props.kInputOnChange,
          // value: props.kInputValue,
          // helper_text: props.kInputHelperText,
        }}
        /* eslint-disable */
        InputProps={{
          disabled: props.kDisabled,
          startAdornment: props.kStartAdornment && props.kStartAdornment,
          endAdornment: props.kEndAdornment && props.kEndAdornment,
          inputMode: 'numeric',
        }}
        FormHelperTextProps={{
          style: {
            backgroundColor: LmiTheme.light.systemBackground.elevation,
            margin: 0,
            paddingTop: '3px',
            paddingRight: '14px',
            paddingBottom: '0',
            paddingLeft: '14px',
          },
        }}
      />

    </Box>
  );
}

InputNumerictextField.defaultProps = {
  kOnClick: undefined,
  kLabel: 'kLabels',
  kLabelCentered: false,
  kLabelColor: LmiTheme.light.systemBackground.secondary,
  kLabelRequiredHide: false,
  // kInputMaxLength: 45,
  kInputMaxLengthHide: false,
  // kInputId: 'kIdInput',
  kInputType: 'text',
  kInputPlaceholder: 'kInputPlaceholder',
  // kInputValue: 'kInputValue',
  kInputMultiline: false,
  kInputRows: 1,
  kInputMaxRows: 1,
  // kInputOnChange: undefined,
  kInputRequired: false,
  // kInputError: undefined,
  // kInputName: 'kInputName',
  // kInputHelperText: 'This field is required',
};

export default InputNumerictextField;

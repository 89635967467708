import { CloseRounded } from '@mui/icons-material';
import {
  DialogTitle,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DialogTitleListsModels } from './DialogListsTitle.models';

function DialogTitleLists(props: DialogTitleListsModels) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <DialogTitle sx={{ px: isMobile ? 1 : undefined }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          pl={1}
          sx={{ fontSize: 'h6.fontSize', fontWeight: 'bold' }}
        >
          {props.kTitle}
          <IconButton color="primary" onClick={props.kOnClose}>
            <CloseRounded fontSize="large" />
          </IconButton>
        </Stack>
      </DialogTitle>
    </>
  );
}

export default DialogTitleLists;

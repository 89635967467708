import { Box, Stack } from '@mui/material';
import { isIOS } from 'react-device-detect';
// https://bestofreactjs.com/repo/duskload-react-device-detect-react-utilites
import { ContentLayout } from '..';

import { ParallaxImageModels } from './ParallaxImage.models';
import ParallaxImageStyle from './ParallaxImage.style';

export default function ParallaxImage(props: ParallaxImageModels) {
  return (
    <Box
      sx={{
        ...ParallaxImageStyle,
        minHeight: `${props.kHeight}px`,
        position: 'relative',
      }}
    >
      <Box
        className="lmi-background"
        sx={{
          backgroundImage: `url("${props.kImageBg}")`,
          backgroundAttachment: isIOS ? 'unset' : 'fixed',
        }}
      >
        <ContentLayout kPaddingXs={0} kPaddingMd={0}>
          <Stack
            direction="row"
            justifyContent={{ xs: 'center', lg: 'space-between' }}
            alignItems="center"
            spacing={2}
            sx={{ minHeight: `${props.kHeight}px` }}
          >
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              className="lmi-left-element"
            >
              {props.kLeftElement}

            </Stack>
            <Box className="lmi-right-element">
              {props.kRightElement}
            </Box>
          </Stack>
        </ContentLayout>
      </Box>
    </Box>

  );
}
ParallaxImage.defaultProps = {
  kHeight: 500,
};

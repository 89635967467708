import { Button } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import LmiTheme from '../../../../../themes/lmi-mui-theme/lmi-theme';
import { ItemViewsModels } from './ItemViews.models';

function ItemViews(props: ItemViewsModels) {
  return (
    <>
      <Button
        onClick={props.kOnClick}
        variant={props.kVariant}
        key={props.kKey}
        size={props.kButtonSize}
        startIcon={(
          <Visibility
            style={{
              color: props.kIconColor,
              fontSize: props.kIconSIze,
            }}
          />
        )}
        disableElevation
        disableRipple
        disableFocusRipple
        sx={{
          color: props.kTextColor,
          fontWeight: props.kFontWeight,
          fontSize: props.kFontSize,
          textAlign: props.KTextAlign,
          textTransform: props.kTextTransform,
        }}
      >
        {props.kViews}
      </Button>
    </>
  );
}

ItemViews.defaultProps = {
  kKey: 'views',
  kOnClick: () => undefined,
  kViews: 0,
  kTextColor: LmiTheme.light.systemBackground.tertiary,
  kFontWeight: 'bold',
  kFontSize: 'subtitle2.fontSize',
  KTextAlign: 'left',
  kTextTransform: 'none',
  kIconColor: LmiTheme.light.systemBackground.primary,
  kIconSize: 22,
  kButtonSize: 'large',
  kVariant: 'text',
};

export default ItemViews;

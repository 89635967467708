import {
  Stack, CircularProgress,
} from '@mui/material';

export default function LoadingLayout() {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        height: { xs: 'auto', md: 'calc(100vh - 260px)', lg: 'calc(100vh - 240px)' },
        minHeight: '500px',
      }}
      spacing={2}
    >
      <CircularProgress />
    </Stack>
  );
}

import LmiTheme from '../../../../../themes/lmi-mui-theme/lmi-theme';

const HeaderSwiperStyle = {
  '.captions': {
    width: '100%',
    zIndex: 2,
    mb: { xs: 18, sm: 0, md: 28 },
    '& h1': {
      color: '#FFFFFF',
      fontWeight: '900',
      textTransform: 'uppercase',
      lineHeight: '1.2',
      fontSize: { xs: 'h4.fontSize', sm: '46px', md: 'h2.fontSize' },
      // maxWidth: { xs: '75%', sm: '52%', md: '455px' },
      width: '100%',
      mb: { xs: 2, md: 2 },
    },
    '& p': {
      color: '#FFFFFF',
      fontWeight: '400',
      // textTransform: 'lowercase',
      lineHeight: '1.2',
      '&:first-letter': {
        textTransform: 'uppercase',
      },
      fontSize: {
        xs: '16px', sm: '20px', md: '24px', lg: '28px',
      },
      maxWidth: {
        xs: '100%', sm: '90%', md: '50%', lg: '55%',
      },
    },
  },

  '.badges': {
    position: 'absolute',
    right: 0,
    zIndex: 2,
    width: '100%',
    bottom: { xs: 28, sm: 150 },
  },

  '.navbar': {
    position: 'absolute',
    // top: 100,
    right: 0,
    zIndex: 2,
    width: '100%',
    top: { xs: 0, md: 8 },
  },

  '.fabIconNavbarPrimary': {
    boxShadow: 'none',
    color: 'white',
    height: { xs: '44px', md: '48px' },
    width: { xs: '44px', md: '48px' },
  },

  '.fabIconNavbarSecondary': {
    boxShadow: 'none',
    color: LmiTheme.light.accent,
    backgroundColor: LmiTheme.light.systemBackground.solid,
    border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
  },

  '.bg': {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    background: 'linear-gradient(to right, rgba(0,0,0,0.9), rgba(0,0,0,0))',
  },
  '.StyledChipUser': {
    backgroundColor: 'white',
    height: '48px',
    borderRadius: '25px',
    paddingLeft: '8px',
    border: `1px solid${LmiTheme.light.systemBackground.separator}`,
  },
  '.StyledFabSearch': {
    boxShadow: 'none',
    background: 'white',
    border: `1px solid${LmiTheme.light.systemBackground.separator}`,
  },
};

export default HeaderSwiperStyle;

/* eslint-disable no-nested-ternary */
import {
  Box, Typography, Paper, Divider, Stack, Avatar,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SectionTitle } from '../../../../../../components/Common';
import ActivityReservationStyle from './ActivityReservaton.style';

export default function ActivityReservaton({ itemInfo, accessories, activitySummary }: any) {
  const { t } = useTranslation('global');

  return (
    <Box sx={ActivityReservationStyle}>
      <SectionTitle
        kMarginTop={2}
        kTitle={cardTitelExpressionText(itemInfo, t)}
        kFontSize={18}
      />
      <Paper variant="outlined" elevation={0}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems={{ md: 'flex-start', xs: 'center' }}
          spacing={2}
          p={2}
        >
          <Avatar
            src={itemInfo?.notifAttachment}
            className="lmiw-reservation-image"
            variant="rounded"
          />
          <Box width="100%">
            <Stack
              direction={{ md: 'row', xs: 'column' }}
              justifyContent="space-between"
              alignItems={{ md: 'center', xs: 'flex-start' }}
              width="100%"
              mb={1}
            >
              <Typography className="lmiw-reservation-item">
                {itemInfo?.name}
              </Typography>
              <Typography className="lmiw-reservation-price">
                $
                {
                   itemInfo?.isRent
                     ? activitySummary?.totalMonths >= 1
                       ? activitySummary?.totalRentalAmmount / activitySummary?.totalMonths
                       : activitySummary?.totalWeeks >= 1
                         ? activitySummary?.totalRentalAmmount / activitySummary?.totalWeeks
                         : activitySummary?.totalDays >= 1
                           ? itemInfo?.dailyPrice

                           : activitySummary?.totalHours >= 1
                             ? itemInfo?.hourlyPrice
                             : '' : activitySummary?.totalRentalAmmount
                    }
                {' '}
                {activitySummary?.currency}
                {' '}
                {
                itemInfo?.isRent
                  ? activitySummary?.totalMonths >= 1
                    ? t('item_detail.monthly')
                    : activitySummary?.totalWeeks >= 1
                      ? t('item_detail.weekly')
                      : activitySummary?.totalDays >= 1
                        ? t('item_detail.daily')
                        : activitySummary?.totalHours >= 1
                          ? t('item_detail.hourly')

                          : '' : t('activity_item.is_buy')
}
              </Typography>
            </Stack>
            <Typography className="lmiw-reservation-desc" sx={{ display: { xs: 'none', sm: 'block' } }}>
              {itemInfo?.name}
            </Typography>
          </Box>
        </Stack>
        <Box sx={{ display: { xs: 'block', sm: 'none' } }} p={2} pt={0}>
          <Typography className="lmiw-reservation-desc">
            {itemInfo?.name}
          </Typography>
        </Box>
        {accessories?.length > 0 && <Divider />}
        {/* <Typography className="lmw-activitydetail-title">
          {cardCancelReasonText(itemInfo)}
        </Typography>
        <Divider /> */}
        {accessories?.length > 0 && (
          <Box p={2}>
            <Typography className="lmiw-reservation-price">
              {t('activity.reservation.accessories')}
            </Typography>
            {accessories.map((acc: any) => (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  className="lmiw-reservation-accessories"
                  style={{ wordWrap: 'break-word', overflowWrap: 'anywhere' }}
                >
                  {acc?.name}
                </Typography>
                <Typography
                  className="lmiw-reservation-accessories"
                  style={{ wordWrap: 'break-word', overflowWrap: 'anywhere' }}
                >
                  {acc?.dscription}
                </Typography>
                <Typography className="lmiw-reservation-accessories">
                  $
                  {acc?.totalAmmount}
                  {' '}
                  {activitySummary?.currency}
                </Typography>
              </Stack>
            ))}

          </Box>
        )}
      </Paper>
    </Box>
  );
}

function cardTitelExpressionText(notifDetail: any, t: any) {
  let _cardTitle;

  const notifActionType = notifDetail?.notifActionType;
  const notifType = notifDetail?.notifType;
  const isRent = notifDetail?.isRent;

  switch (notifActionType) {
    case 'approve':
      _cardTitle = isRent
        ? `${t('activity.reservation.your_reservation_for')}`
        : `${t('activity.reservation.someone_bought_item')}`;
      break;
    case 'new_rental':
      _cardTitle = `${t('activity.reservation.your_reservation_for')}`;
      break;
    case 'new_buy':
      _cardTitle = `${t('activity.reservation.you_bought_item')}`;
      break;
    case 'approved':
    case 'rejected':
    case 'pickup':
    case 'dropoff':
    case 'payment_processed':
    case 'review':
    case 'cancelled':
    case 'verified_failed':
    case 'verified':
    case 'verifypending':
    case 'verified_ok':
      if (notifType === 'LEND') {
        _cardTitle = isRent
          ? `${t('activity.reservation.your_reservation_for')}`
          : `${t('activity.reservation.someone_bought_item')}`;
      } else {
        _cardTitle = isRent
          ? `${t('activity.reservation.your_reservation_for')}`
          : `${t('activity.reservation.you_bought_item')}`;
      }
      break;
    case 'approve_buy':
      _cardTitle = `${t('activity.reservation.someone_bought_item')}`;
      break;
    default:
      _cardTitle = isRent
        ? `${t('activity.reservation.your_reservation_for')}`
        : `${t('activity.reservation.you_bought_item')}`;
  }

  return _cardTitle;
}

// function cardCancelReasonText(itemInfo: any) {
//   const { t } = useTranslation('global');
//   let _cancelReasonRow;
//   let _reason;
//   if (itemInfo?.notifActionType == 'rejected') {
//     if (itemInfo?.cancelReason != '') {
//       _reason = `${t('activity.reservation.rejection_reason')} ${itemInfo.cancelReason}`;
//     } else {
//       _reason = `${t('activity.reservation.rejection_reason_no')}`;
//     }
//   } else if (itemInfo?.notifActionType == 'cancelled') {
//     if (itemInfo?.cancelledLenderCommnets != ''
//     || itemInfo?.cancelledRenterComments != ''
//     || itemInfo?.cancelReason != '') {
//       itemInfo?.cancelledLenderComments != ''
//         ? _reason = `${t('activity.reservation.cancelation_reason')} ${itemInfo.cancelledLenderCommnets} `
//         : itemInfo?.cancelledRenterComments != ''
//           ? _reason = `${t('activity.reservation.cancelation_reason')} ${itemInfo.cancelledRenterComments} `
//           : _reason = `${t('activity.reservation.cancelation_reason')} ${itemInfo.cancelReason} `;
//     } else {
//       _reason = `${t('activity.reservation.cancelation_reason_no')}`;
//     }
//   }

//   if (itemInfo?.notifActionType == 'rejected'
//   || itemInfo?.notifActionType == 'cancelled') {
//     _cancelReasonRow = (
//       <Typography className="lmiw-reservation-item">
//         {_reason}
//       </Typography>
//     );
//   }

//   return _cancelReasonRow;
// }

import {
  Card,
  CardContent,
  Container,
  Divider,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowBackIosRounded } from '@mui/icons-material';
import ReviewsDialog from '../../../components/Common/Reviews/ReviewsDialog';
import publicProfileContext from '../../../context/publicProfile/publicProfileContext';
import {
  AboutMe,
  HeaderTitle,
  MyListings,
  ProfileInfo,
  ReviewsCard,
} from './Components';
import { TabPanelReviews } from '../../BackOffice/ProfilePrivate/Components';
import AppBarSecondary from '../../../components/Layout/AppBarSecondary/AppBarSecondary';
import { DialogCommon, SectionTitle } from '../../../components/Common';

function PublicProfile() {
  const history = useHistory();
  const { state }: any = history.location;
  const [showReviews, setShowReviews] = useState(0);

  if (!state) {
    history.push('/');
  }
  /// Breakpoints Media Query

  const { fromPage, publicProfileId } = state ?? { bookItem: { idCatLndItem: 0 }, fromPage: '', publicProfileId: 0 };
  const { t } = useTranslation('global');
  const titleProfile = fromPage == 'private-profile' ? `${t('profile.public_profile')}` : `${t('profile.lender_profile')}`;

  const pProfileContext = useContext(publicProfileContext);

  const {
    publicProfileInfo,
    getPublicProfileInfo,
    publicUserItems,
    publicUserReviews,

    privateProfileInfo,
    publicGivenReviewList,
  }: any = pProfileContext;

  useEffect(() => {
    (async function fetchPublicProfileInfo() {
      await getPublicProfileInfo({ idCatLender: publicProfileId });
    }());
  }, []);

  const handleBack = (e: any) => {
    e.preventDefault();
    history.goBack();
  };

  const [openAccesoriesDialog, setOpenAccesoriesDialog] = useState(false);
  const clickCloseAccesoriesDialog = () => setOpenAccesoriesDialog(false);
  const clickShowReviews = () => setShowReviews(showReviews == 1 ? 0 : 1);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <AppBarSecondary
        kBack
        kTitle={(
          <SectionTitle
            kMarginTop={0}
            kMarginBottom={0}
            kTitle={titleProfile}
          />
        )}
      />
      <Container sx={{ margin: { md: '24px auto', xs: '0' }, padding: { xs: 0 } }} maxWidth="md">
        <Card elevation={0}>
          <Stack
            px={1}
            py={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={3}
            sx={{
              display: { xs: 'none', md: 'flex' },
            }}
          >
            <IconButton
              aria-label="back"
              color="primary"
              onClick={(e) => handleBack(e)}
              sx={{ py: 1.4 }}
            >
              <ArrowBackIosRounded />
            </IconButton>
            <HeaderTitle
              kTitle={titleProfile}
            />

          </Stack>
          <Divider sx={{
            display: { xs: 'none', md: 'flex' },
          }}
          />
          <CardContent sx={{ px: 3 }}>
            <ProfileInfo
              kPhoto={publicProfileInfo?.profilePicture}
              kName={publicProfileInfo?.completeName}
              kMemberSince={publicProfileInfo?.memberSince}
              kIdVerify={(publicProfileInfo?.isShuftiVerified || publicProfileInfo?.isStripeVerified)}
            />
            <AboutMe
              kTitle={t('profile.about_me')}
              kDescription={publicProfileInfo?.dscription}
            />
            <ReviewsCard
              kTitle={t('profile.reviews')}
              kRecommended={publicProfileInfo?.sumRatesReceived?.toFixed(2) ?? 100}
              kReviews={publicProfileInfo?.totalReceivedReviews}
              kOnClickOpenReviewsDialog={clickShowReviews}
            />

            <DialogCommon
              kTitle={t('profile.reviews')}
              kMaxWidth="sm"
              kDisableContentPadding
              kDisableContentPaddinginY
              kOpenDialogLists={showReviews}
              kBorderRadius={isMobile ? 0 : '10px'}
              kSlideTransition={isMobile}
              kOnClose={clickShowReviews}
              kOnSecondary={clickShowReviews}
              kSecondaryBtnText={t('global.close')}
              kContent={
                (
                  <TabPanelReviews
                    hideReviewsSummary
                    kTabValue={showReviews}
                    kProfileInfo={privateProfileInfo}
                    kRecommendedNumber={privateProfileInfo?.sumRatesReceived?.toFixed(2) ?? 100}
                    kUserReviews={publicUserReviews}
                    kGivenReviews={publicGivenReviewList}
                    kTabIndex={1}
                  />
                )
              }
            />

            {publicUserReviews?.length > 0 && (
              <ReviewsDialog
                kReviewsList={publicUserReviews}
                kOpenDialog={openAccesoriesDialog}
                kOnClose={clickCloseAccesoriesDialog}
                kOnCancel={clickCloseAccesoriesDialog}
                kOnSave={clickCloseAccesoriesDialog}
              />
            )}
          </CardContent>
        </Card>
        {publicUserItems?.length > 0 && (
          <Card elevation={0} sx={{ mt: 3 }}>
            <HeaderTitle
              kTitle={t('profile.listings')}
            />
            <Divider />
            <MyListings kItemsListing={publicUserItems} />
          </Card>
        )}
      </Container>
    </>
  );
}

export default PublicProfile;

import {
  Box, Stack, FormControl, OutlinedInput, useTheme, useMediaQuery,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from 'react-i18next';
import CircleChatStyle from './CircleAdminsChat.style';
import ChatMessage from './AdminsChatMessage/AdminsChatMessage';

export default function CircleAdminsChat() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation('global');

  return (
    <>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={CircleChatStyle}
      >
        <Box width="100%">
          <ChatMessage
            kMessage="Hi Can you meet at coffee shop in the parking slot at noon? I’m driving a minivan and have a huge mustache"
            kTimestamp="6:33 pm"
            kAlign="right"
          />
          <ChatMessage
            kMessage="Hi, Sure. I will see you there."
            kTimestamp="6:34 pm"
            kAlign="left"
          />
          <ChatMessage
            kMessage="Cool, Thanks for renting out my product."
            kAlign="right"
          />
          <ChatMessage
            kMessage="See you there! :)"
            kTimestamp="6:38 pm"
            kAlign="right"
          />
        </Box>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={{ lg: 2, xs: 1 }}
          sx={{
            width: '100%',
            position: isMobile ? 'fixed' : 'inherit',
            bottom: isMobile ? 0 : 'inherit',
          }}
        >
          <FormControl
            className="lmi-formcontrol"
            onChange={() => {}}
            onKeyDown={() => {}}
            sx={{
              width: '100%',
              ml: '16px',
              mb: '16px',
            }}
          >
            <OutlinedInput
              sx={{
                width: '100%',
                borderRadius: '10px',
              }}
              fullWidth
              placeholder={t('circles.members.type_message')}
              onFocus={() => {}}
              onBlur={() => { }}
            />

          </FormControl>
          <Box>
            <SendIcon sx={{
              mb: '16px',
              ml: '10px',
              mr: '16px',
              width: '30px',
              height: '30px',
            }}
            />
          </Box>
        </Stack>
      </Stack>

    </>

  );
}

import { generateVideoThumbnails } from '@rajesh896/video-thumbnails-generator';

// const videoThumbnailGet = async (videoFile: any) => {
//   let videoThumbnail = '';
//   await generateVideoThumbnails(videoFile, 1, 'video').then((thumbnailArray) => {
//     if (thumbnailArray.length > 0) {
//       const [vidThumb] = thumbnailArray;
//       videoThumbnail = vidThumb;
//     }
//   }).catch((err) => {
//     console.error(err);
//   });

//   return videoThumbnail;
// };

// export default videoThumbnailGet;
async function videoThumbnailGet(videoFile: any) {
  let videoThumbnail = '';
  await generateVideoThumbnails(videoFile, 1, 'video').then((thumbnailArray) => {
    if (thumbnailArray.length > 0) {
      const [vidThumb] = thumbnailArray;
      videoThumbnail = vidThumb;
    }
  }).catch((err) => {
    console.error(err);
  });

  return videoThumbnail;
}

export default videoThumbnailGet;

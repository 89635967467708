/* eslint-disable max-lines */
/* eslint import/no-unresolved: [2, { commonjs: true, amd: true }] */
import { useReducer } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { ClsModCreatePaymentMethodFromCamera } from '@lendmeit/api_backend_lmi/dist/models/cls-mod-create-payment-method-from-camera';
import ApiLMI from '../../services/apilmi';
import PaymentMethodsContext from './paymentMethodsContext';
import PaymentMethodsReducer from './paymentMethodsReducer';
import {
  GET_BY_ID, UPDATE_DEFAULT_CARD,
  PAYMENT_ADD_EDIT,
  CHECK_NEW_PAYMENT_VALIDATIONS,
  UPDATE_PAYMENT_VALIDATION_STATUS,
  // PAYMENT_METHOD_ADDED_SUCCESS,
  UPDATE_BOOKING_CARD,
  DELETED_CARD_SUCCESS,
} from '../../types';

const PaymentMethodsState = (props: any) => {
  const initialState = {
    loading: true,
    paymentMethodsList: [],
    totalValidCard: 0,
    defaulPaymentMethod: undefined,
  };

  const [state, dispatch] = useReducer(PaymentMethodsReducer, initialState);

  const {
    loading,
    paymentMethodsList,
    newPaymentMethodInfo,
    // totalValidCard,
  } = state;

  const initialNewPaymentInfo: ClsModCreatePaymentMethodFromCamera = {
    cardNumber: '',
    cardNumberFormated: '0',
    cvc: '',
    expiryMonth: '',
    expiryYear: '',
    toc: 'qwerty',
    cardName: '',
  };

  const deleteCard = async (value: number) => {
    const _api = ApiLMI();

    const paymentDelete = await _api.paymentDeletePaymentMethod({ idPaymentMethod: value, toc: 'qwerty' });
    const paymentDeleteParse = JSON.parse(paymentDelete.data.toString());

    if (paymentDeleteParse.Error != '') {
      toast.error(paymentDeleteParse.Error);
    } else {
      toast.success(t('payment_method.state.payment_deleted'));
      dispatch({
        type: DELETED_CARD_SUCCESS,
        payload: {
          paymentMethodsList: state.paymentMethodsList.filter((element: any) => element.idCatLndUserPaymentMethod !== value),
          totalValidCard: state.totalValidCard > 0 ? (state.totalValidCard - 1) : 0,
          // checkPaymentValidate: false,
        },
      });
    }
  };

  const updateDefaultCard = async (value: number) => {
    // const updatePaymentList = [];
    const _api = ApiLMI();
    // The index of the card is to know if this card is expired(valid) if it is, it can be set as default
    const indexCard = state.paymentMethodsList.findIndex((e: any) => e.idCatLndUserPaymentMethod === value);
    console.log(indexCard);

    if (state.paymentMethodsList[indexCard].valid) {
      state.paymentMethodsList.forEach((element: any, index: number) => {
        if (element.idCatLndUserPaymentMethod === value) {
          state.paymentMethodsList[index].defaultCard = true;
        } else {
          state.paymentMethodsList[index].defaultCard = false;
        }
      });
      const paymentMethods = await _api.paymentSetUserCustomerDefaultPaymenyMethod({ idPaymentMethod: value, toc: 'qwerty' });
      const newDefaultId = JSON.parse(paymentMethods.data.toString()).Response;
      console.log(newDefaultId[0]);
      if (newDefaultId[0].idCatLndUserPaymentMethod) {
        dispatch({
          type: UPDATE_DEFAULT_CARD,
          payload: {
            paymentMethodsList: state.paymentMethodsList,
          },
        });
      } else {
        toast.error(t('payment_method.state.card_not_updated'));
      }
    } else {
      toast.info(t('payment_method.state.expired_card'));
    }
  };

  const updateBookingCard = async (value: number) => {
    // The index of the card is to know if this card is expired(valid) if it is, it can be set as default
    const indexCard = state.paymentMethodsList.findIndex((e: any) => e.idCatLndUserPaymentMethod === value);
    console.log(indexCard);
    let newBookingCard: any;

    if (state.paymentMethodsList[indexCard].valid) {
      state.paymentMethodsList.forEach((element: any, index: number) => {
        if (element.idCatLndUserPaymentMethod === value) {
          newBookingCard = state.paymentMethodsList[index];
        }
      });
      if (newBookingCard) {
        dispatch({
          type: UPDATE_BOOKING_CARD,
          payload: {
            bookingCard: newBookingCard,
          },
        });
      } else {
        toast.error(t('payment_method.state.card_not_updated'));
      }
    } else {
      toast.info(t('payment_method.state.expired_card'));
    }
  };

  const updateNewPaymentInfo = async (values:any) => {
    dispatch({
      type: PAYMENT_ADD_EDIT,
      payload: {
        newPaymentMethodInfo: values,
        checkPaymentValidate: false,
      },
    });
  };

  const getUserPaymentInfo = async () => {
    const _api = ApiLMI();
    // const idtoast = toast.loading('Loading information, Please wait...');
    try {
      const paymentMethods = await _api.paymentGetUserPaymentMethods({ idPaymentMethod: 0, toc: 'qwerty' });
      const paymentMethodsParsed = JSON.parse(paymentMethods.data.toString()).Response;
      let totalValidCards = 0;

      if (paymentMethodsParsed.length > 0) {
        const today = new Date();
        const actualYear = today.getFullYear();

        // eslint-disable-next-line no-restricted-syntax
        // eslint-disable-next-line no-plusplus
        for (let x = 0; x < paymentMethodsParsed.length; x++) {
          // let isValid = true;
          if (paymentMethodsParsed[x].exp_year > actualYear) {
            paymentMethodsParsed[x].valid = true;
          } else if (paymentMethodsParsed[x].exp_year == actualYear) {
            paymentMethodsParsed[x].valid = paymentMethodsParsed[x].exp_month >= today.getMonth();
            // isValid = false;
          } else if (paymentMethodsParsed[x].exp_year < actualYear) {
            paymentMethodsParsed[x].valid = false;
            // isValid = false;
          }
          // if (isValid) {
          totalValidCards += 1;
          // }
        }
      }
      if (paymentMethodsParsed) {
        // toast.update(idtoast, {
        //   render: 'Loaded', type: 'success', icon: '🟢', isLoading: false, autoClose: 1500,
        // });

        dispatch({
          type: GET_BY_ID,
          payload: {
            paymentMethodsList: paymentMethodsParsed,
            newPaymentMethodInfo: initialNewPaymentInfo,
            totalValidCard: totalValidCards,
          },
        });
      } else {
        // toast.update(idtoast, {
        //   render: 'Error', type: 'error', isLoading: false, autoClose: 5000,
        // });
      }
    //   }
    } catch (error: any) {
      // toast.update(idtoast, {
      //   render: error, type: 'error', isLoading: false, autoClose: 5000,
      // });
    }
  };

  const createPaymentMethod = async (details: ClsModCreatePaymentMethodFromCamera) => {
    const api = ApiLMI();

    let isSuccess = false;
    const createPayment = await api.paymentCreatePaymentMethodFromCamera(details);
    const createPaymentParsed = JSON.parse(createPayment.data.toString());

    if (createPaymentParsed.Error != '') {
      toast.error(createPaymentParsed.Error);
    } else {
      getUserPaymentInfo();
      toast.success(t('payment_method.state.payment_added'));
      isSuccess = true;
    }

    return isSuccess;
    // dispatch({
    //   type: PAYMENT_METHOD_ADDED_SUCCESS,
    //   newPaymentMethodInfo: initialNewPaymentInfo,
    // });
    // console.log(createPaymentParsed);
  };

  const checkNewPaymentValidations = async (values:any) => {
    dispatch({
      type: CHECK_NEW_PAYMENT_VALIDATIONS,
      checkPaymentValidate: values,
    });
  };

  const updatePaymentValidationStatus = async (values:any) => {
    dispatch({
      type: UPDATE_PAYMENT_VALIDATION_STATUS,
      arePaymentFieldsValidated: values,
      checkPaymentValidate: false,
    });
  };

  const { t } = useTranslation('global');

  return (
    <PaymentMethodsContext.Provider
      value={{
        ...state,
        loading,
        defaulPaymentMethod: state.defaulPaymentMethod,
        paymentMethodsList,
        newPaymentMethodInfo,
        totalValidCard: state.totalValidCard,
        checkPaymentValidate: state.checkPaymentValidate,
        changedPaymentFields: state.changedPaymentFields,
        arePaymentFieldsValidated: state.arePaymentFieldsValidated,
        bookingCard: state.bookingCard,
        getUserPaymentInfo,
        updateDefaultCard,
        updateNewPaymentInfo,
        createPaymentMethod,
        checkNewPaymentValidations,
        updatePaymentValidationStatus,
        deleteCard,
        updateBookingCard,
      }}
    >
      {props.children}

    </PaymentMethodsContext.Provider>
  );
};

export default PaymentMethodsState;

import { useMediaQuery, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitleLists from './DialogListsTitle';
import DialogActionsLists from './DialogActionsLists';
import DialogContentLists from './DialogContentLists';
import { DialogListsModels } from './DialogLists.models';

function DialogLists(props: DialogListsModels) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Dialog
        keepMounted
        fullWidth
        fullScreen={props.kFullScreen ?? isMobile}
        maxWidth={props.kMaxWidth}
        open={props.kOpenDialogLists}
        onClose={props.kOnClose}
        PaperProps={{
          style: { borderRadius: '10px' },
        }}
      >
        {
          !props.kDisableTitle
          && (
            <DialogTitleLists
              kTitle={props.kTitle}
              kOnClose={props.kOnClose}
            />
          )
        }
        <DialogContentLists
          kContent={props.kContent}
          kContentPadding={props.kDisableContentPadding}
        />
        {
          !props.kDisableActions
          && (
            <DialogActionsLists
              kCancelDialogAction={props.kOnCancel}
              kSaveDialogAction={props.kOnSave}
            />
          )
        }
      </Dialog>
    </>
  );
}

export default DialogLists;

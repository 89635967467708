import { LmiTheme } from '..';

const TwoTabsStyle = {
  width: '100%',
  '.lmi-tablist': {
    borderBottom: 1,
    borderColor: 'divider',
    mb: 2,
    button: {
      color: LmiTheme.light.systemBackground.secondary,
      fontSize: { xs: '16px', sm: '20px' },
      borderRadius: 0,
      '&.lmi-tabactive': {

        color: LmiTheme.light.accent,
        borderBottom: `2px solid ${LmiTheme.light.accent}`,
      },
    },

    // '.MuiTabs-flexContainer': {
    //   justifyContent: { md: 'flex-start', xs: 'center' },
    // },
  },
  '.lmi-tabpanel': {
    px: 0,
    '.lmi-title': {
      textAlign: { md: 'left', xs: 'center' },
      mt: 2,
      h4: {
        fontWeight: 'bold',
        display: 'inline',
        fontSize: {
          xs: '24px', sm: '30px', md: '28px', lg: '36px',
        },
      },

    },
    '.MuiContainer-root': {
      marginLeft: { xs: 'auto', md: 0 },
    },
    '.lmi-cont-text': {
      maxWidth: { lg: '444px', md: '370px', xs: '444px' },
    },
    '.lmi-stack-img': {
      position: 'relative',
      height: {
        md: 450, sm: 500, xs: 300,
      },
      overflow: 'hidden',
      borderRadius: 7,
      img: {
        width: '100%',
        height: 'auto',
        maxWidth: { xs: '400px', sm: '500px', lg: '640px' },
        maxHeight: '500px',
        borderRadius: 7,
      },
    },
  },
};

export default TwoTabsStyle;

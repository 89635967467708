/* eslint-disable max-lines */
/* eslint import/no-unresolved: [2, { commonjs: true, amd: true }] */
import { useContext, useReducer } from 'react';
import { toast } from 'react-toastify';
import {
  ClsModGetUserPublicProfileInfo, ClsModUpdateUserNameAndDescription,
} from '@lendmeit/api_backend_lmi/dist/models';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import ApiLMI from '../../services/apilmi';
import PublicProfileContext from './publicProfileContext';
import PublicProfileReducer from './publicProfileReducer';
import {
  GET_BY_ID,
  PROFILE_INFO_CHANGED,
  UPDATE_ABOUT_ME,
  UPDATE_FULL_NAME,
  CHANGE_PASS_UPDATE,
  CHANGE_PASS_SUCCESS,
  CHANGE_PASS_ERROR,
  GET_PRIVATE,
  CLEAR,
  UPDATE_PENDING_PICTURE,
} from '../../types';
import shuftiProContext from '../shuftiPro/shuftiProContext';

const PublicProfileState = (props: any) => {
  const pShuftiProContext = useContext(shuftiProContext);
  const {
    initializeShufti,
    // shuftiUrl,
  }: any = pShuftiProContext;
  const initialState = {
    loading: true,
    publicProfileInfo: {},
    privateProfileInfo: {},
    newAboutMe: '',
  };

  const initialChangePassWord = {
    oldPassword: '',
    newPass: '',
    newPassConfirm: '',
  };

  const [state, dispatch] = useReducer(PublicProfileReducer, initialState);

  const {
    loading,
    publicProfileInfo,
    privateProfileInfo,
    userItems,
    publicUserItems,
    newAboutMe,
    changePasswordInfo,
    closeModal,
  } = state;

  const getPublicProfileInfo = async ({ idCatLender }: ClsModGetUserPublicProfileInfo) => {
    const _api = ApiLMI();
    const idtoast = toast.loading(t('global.loading_txt'));
    try {
      const { data } = await _api.userGetUserPublicProfileInfo({ idCatLender, toc: 'qwerty' });
      const dataItems = await _api.userGetUserLndItemsNewFlowPublicProfile({ idCatLndLender: idCatLender, idCatLndItem: 0, toc: 'qwerty' });
      const userReviews = await _api.userGetReceivedReviewsFixed({ idCatLender, toc: 'qwerty' });
      const userGivenReviews = await _api.userGetGivenReviewsFixed({ idCatLender, toc: 'qwerty' });
      const itemList = JSON.parse(dataItems.data.toString());
      const reviewList = JSON.parse(userReviews.data.toString());
      const givenReviewList = JSON.parse(userGivenReviews.data.toString());
      if (typeof data === 'string') {
        const obj = JSON.parse(data.toString());
        const publicProfileData = obj.Response[0];
        // const { response, error } = data; totalGivenReviews totalReceivedReviews
        if (publicProfileData) {
          const nameToShow = publicProfileData?.completeName != '' ? publicProfileData?.completeName : publicProfileInfo.userName;
          toast.update(idtoast, {
            render: `${t('profile.loaded')}`, type: 'success', icon: '🟢', isLoading: false, autoClose: 1500,
          });
          dispatch({
            type: GET_BY_ID,
            payload: {
              publicProfileInfo: publicProfileData,
              publicUserItems: itemList.Response,
              publicUserReviews: reviewList.Response,
              newFullName: nameToShow,
              publicGivenReviewList: givenReviewList.Response,
              paymentMethods: [],
              changePasswordInfo: initialChangePassWord,
            },
          });
        } else {
          toast.update(idtoast, {
            render: 'Error', type: 'error', isLoading: false, autoClose: 5000,
          });
        }
      }
    } catch (error: any) {
      toast.update(idtoast, {
        render: error, type: 'error', isLoading: false, autoClose: 5000,
      });
    }
  };

  const getPrivateProfileInfo = async ({ idCatLender }: ClsModGetUserPublicProfileInfo) => {
    const _api = ApiLMI();
    try {
      const { data } = await _api.userGetUserPublicProfileInfo({ idCatLender, toc: 'qwerty' });
      const dataItems = await _api.userGetUserLndItemsNewFlowPublicProfile({ idCatLndLender: idCatLender, idCatLndItem: 0, toc: 'qwerty' });
      const userReviews = await _api.userGetReceivedReviewsFixed({ idCatLender, toc: 'qwerty' });
      const userGivenReviews = await _api.userGetGivenReviewsFixed({ idCatLender, toc: 'qwerty' });

      const pendingResponse = await _api.userValidatesUserPendingInfo({ toc: '' });
      // const paymentMethods = await _api.paymentGetUserPaymentMethods({ idPaymentMethod: 0, toc: 'qwerty' });
      const itemList = JSON.parse(dataItems.data.toString());
      const reviewList = JSON.parse(userReviews.data.toString());
      const givenReviewList = JSON.parse(userGivenReviews.data.toString());
      const pendingInfo = JSON.parse(pendingResponse.data.toString());

      if (typeof data === 'string') {
        const obj = JSON.parse(data.toString());

        if (obj.Error == '' && obj.Response.length > 0) {
          const privateProfileData = obj.Response[0];
          if (privateProfileData) {
            const nameToShow = privateProfileData?.completeName != '' ? privateProfileData?.completeName : privateProfileData.userName;

            if (privateProfileData?.isShuftiVerified == false) {
              await initializeShufti(privateProfileData?.email, privateProfileData?.userName, privateProfileData?.idCatLndUser);
            }
            dispatch({
              type: GET_PRIVATE,
              payload: {
                privateProfileInfo: privateProfileData,
                userItems: itemList.Response,
                userReviews: reviewList.Response,
                newFullName: nameToShow,
                givenReviewList: givenReviewList.Response,
                paymentMethods: [],
                changePasswordInfo: initialChangePassWord,
                newAboutMe: privateProfileData.description,
                pendingInfo: pendingInfo.Response[0],
              },
            });
          }
        }
      }
    } catch (error: any) {
      const err = { ...error };
      toast.error(err);
    }
  };

  const resetShuftiReferenceId = async () => {
    await initializeShufti(state.privateProfileInfo?.email, state.privateProfileInfo?.userName, state.privateProfileInfo?.idCatLndUser, true);
  };

  const updateUserNameAndDescription = async ({ userName, description }: ClsModUpdateUserNameAndDescription) => {
    const api = ApiLMI();

    //  Delete previous salePrice value
    const bodyUserNameDescription = {
      userName,
      description,
    };
    await api.userUpdateUserNameAndDescription(bodyUserNameDescription);
  };

  const uploadUpdateProfilePicture = async (profilePicturebase: any) => {
    const api = ApiLMI();
    //  Delete previous salePrice value
    const splitBase = profilePicturebase.split(',');
    const bodyUploadPicture = {
      toc: 'qwerty',
      profilePicturebase: splitBase[1],
    };

    const picturePath = await api.userUploadProfilePicture(bodyUploadPicture);
    const pictureUrlPath = JSON.parse(picturePath.data.toString()).Response[0].fileName;
    await api.userUpdateUserProfilePicture({ toc: 'qwerty', pictureUrl: pictureUrlPath });

    toast.success(t('profile.picture_updated'));
    dispatch({
      type: UPDATE_PENDING_PICTURE,
    });
  };

  const updateProfileAboutMe = async (values: any) => {
    dispatch({
      type: UPDATE_ABOUT_ME,
      newAboutMe: values,
    });
  };

  const updateCompleteName = async (values: any) => {
    dispatch({
      type: UPDATE_FULL_NAME,
      newFullName: values,
    });
  };

  const updateProfileInfo = async (values: any) => {
    dispatch({
      type: PROFILE_INFO_CHANGED,
      privateProfileInfo: values,
    });
  };

  const clearPublicProfile = async () => {
    dispatch({
      type: CLEAR,
      payload: null,
    });
  };

  const updateChangePassword = async (values: any) => {
    dispatch({
      type: CHANGE_PASS_UPDATE,
      changePasswordInfo: values,
      closeModal: false,
    });
  };

  const resetChangePasswordInfo = async () => {
    dispatch({
      type: PROFILE_INFO_CHANGED,
      changePasswordInfo: initialChangePassWord,
      privateProfileInfo: state.privateProfileInfo,
      closeModal: false,
    });
  };

  const changePassword = async ({ oldPassword, newPassConfirm }: any) => {
    const user = await Auth.currentAuthenticatedUser();

    try {
      await Auth.changePassword(user, oldPassword, newPassConfirm);
      dispatch({
        type: CHANGE_PASS_SUCCESS,
        changePasswordInfo: initialChangePassWord,
        closeModal: true,
      });
      return true;
    } catch (error: any) {
      const alerta = {
        msg: (error) || `${t('auth_state.server_error')}`,
        category: 'alerta-error',
      };
      toast.error(error.message);
      dispatch({
        type: CHANGE_PASS_ERROR,
        payload: alerta,
      });
    }

    return false;
  };

  const { t } = useTranslation('global');

  return (
    <PublicProfileContext.Provider
      value={{
        ...state,
        loading,
        publicProfileInfo,
        privateProfileInfo,
        publicUserItems,
        userItems,
        newAboutMe,
        changePasswordInfo,
        closeModal,
        getPublicProfileInfo,
        getPrivateProfileInfo,
        updateProfileInfo,
        updateProfileAboutMe,
        updateUserNameAndDescription,
        updateCompleteName,
        uploadUpdateProfilePicture,
        updateChangePassword,
        resetChangePasswordInfo,
        changePassword,
        resetShuftiReferenceId,
        clearPublicProfile,
      }}
    >
      {props.children}

    </PublicProfileContext.Provider>
  );
};

export default PublicProfileState;

// Authentication
export const TOKEN = 'TOKEN';
export const REGISTRATION_SUCCESSFUL = 'REGISTRATION_SUCCESSFUL';
export const REGISTRATION_ERROR = 'REGISTRATION_ERROR';
export const GET_USER = 'GET_USER';
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const CLOSE_SESION = 'CLOSE_SESION';
export const CONFIRM_ACCOUNT = 'CONFIRM_ACCOUNT';
export const CONFIRM_ERROR = 'CONFIRM_ERROR';
export const CHANGE_PASS_ERROR = 'CHANGE_PASS_ERROR';
export const FORGOT_VERIFICATION = 'FORGOT_VERIFICATION';
export const CONFIRM_ACCOUNT_PROVIDER = 'CONFIRM_ACCOUNT_PROVIDER';
export const NOTAUTHORIZED = 'NOTAUTHORIZED';
export const REQUEST_ID = 'REQUEST_ID';
export const USER_LMI = 'USER_LMI';
export const BLOCKED = 'BLOCKED';
export const EMAIL_PASS = 'EMAIL_PASS';
export const CLEAN_ALERT = 'CLEAN_ALERT';
export const SET_NEW_USER = 'SET_NEW_USER';

// COMMONS
export const INITIAL = 'INITIAL';
export const ADD = 'ADD';
export const ADD_RANGE = 'ADD_RANGE';
export const SAVE = 'SAVE';
export const DETAILS = 'DETAILS';
export const GET_ALL = 'GET_ALL';
export const SEARCH = 'SEARCH';
export const GET_BY_ID = 'GET_BY_ID';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const DELETE_ALL = 'DELETE_ALL';
export const SET = 'SET';
export const CLEAR = 'CLEAR';
export const LOADING = 'LOADING';
export const LOADINGITEMS = 'LOADINGITEMS';
export const MORE = 'MORE';
export const SET_STATE = 'SET_STATE';
export const GET_DYNAMIC_LINK = 'UPDATE_DYNAMIC_LINK';
export const SET_ADD_ITEM_FLAG = 'SET_ADD_ITEM_FLAG';
export const SET_ADD_CIRCLE_FLAG = 'SET_ADD_CIRCLE_FLAG';
export const SET_STATE_ALL = 'SET_STATE_ALL';
export const SEARCH_SIMILAR = 'SEARCH_SIMILAR';

// Currency
export const GET_CURRENCY = 'GET_CURRENCY';
export const SET_CURRENCY = 'SET_CURRENCY';

// Coupon Codes
export const GET_COUPON_CODES = 'GET_COUPON_CODES';

// Item Detail
export const ITEM_BASIC_INFO_CHANGED = 'ITEM_BASIC_INFO_CHANGED';
export const ITEM_ACCESORIES_CHANGED = 'ITEM_ACCESORIES_CHANGED';
export const ITEM_REQUIREMENTS_CHANGED = 'ITEM_REQUIREMENTS_CHANGED';
export const ITEM_PICTURES_CHANGED = 'ITEM_PICTURES_CHANGED';
export const ITEM_INFO_LOAD = 'ITEM_INFO_LOAD';
export const ACCESORY_ADD_EDIT = 'ACCESORY_ADD_EDIT';
export const ACCESORY_UPDATE_LIST = 'ACCESORY_UPDATE_LIST';
export const REQUIREMENT_ADD_EDIT = 'REQUIREMENT_ADD_EDIT';
export const REQUIREMENT_UPDATE_LIST = 'REQUIREMENT_UPDATE_LIST';
export const IMAGES_UPDATE_LIST = 'IMAGES_UPDATE_LIST';
export const VIDEOS_UPDATE_LIST = 'VIDEOS_UPDATE_LIST';
export const CHECK_VALIDATIONS = 'CHECK_VALIDATIONS';
export const UPDATE_VALIDATION_STATUS = 'UPDATE_VALIDATION_STATUS';
export const CHECK_ACC_VALIDATIONS = 'CHECK_ACC_VALIDATIONS';
export const UPDATE_ACC_VALIDATION_STATUS = 'UPDATE_ACC_VALIDATION_STATUS';
export const CHECK_REQ_VALIDATIONS = 'CHECK_REQ_VALIDATIONS';
export const UPDATE_REQ_VALIDATION_STATUS = 'UPDATE_REQ_VALIDATION_STATUS';
export const DELETED_IMAGES_UPDATE_LIST = 'DELETED_IMAGES_UPDATE_LIST';
export const DELETED_VIDEOS_UPDATE_LIST = 'DELETED_VIDEOS_UPDATE_LIST';
export const BLOCKED_IND_DATES_UPDATE_LIST = 'BLOCKED_IND_DATES_UPDATE_LIST';
export const UPDATE_UPDATED_ITEM_STATUS = 'UPDATE_UPDATED_ITEM_STATUS';
export const RESET_ITEM_INFO = 'RESET_ITEM_INFO';
export const INFO_IS_LOADING = 'INFO_IS_LOADING';
export const CLEAN_FIELDS = 'CLEAN_FIELDS';
export const ITEM_INFO_LOAD_REFRESH_PAGE = 'ITEM_INFO_LOAD_REFRESH_PAGE';
export const REMOVE_REQUIREMENT = 'REMOVE_REQUIREMENT';
export const ITEM_FILE_VIDEOS_UPDATE = 'ITEM_FILE_VIDEOS_UPDATE';

// Router
export const CHANGE = 'CHANGE';
export const RECOMMENDED_WEEKLY = 'RECOMMENDED_WEEKLY';
export const RECOMMENDED_MONTHLY = 'RECOMMENDED_MONTHLY';

// Book Item
export type PRECING_TYPE = 'HOURLY' | 'DAILY' | 'WEEKLY' | 'MONTHLY';
export type DELIVERY_TYPE = 'PICKUPBYLENDER' | 'ROADIE' | 'NONE';
export const NONE = 'NONE';
export const PICKUPBYLENDER = 'PICKUPBYLENDER';
export const ROADIE = 'ROADIE';
export const PRECING_HOURLY = 'HOURLY';
export const PRECING_DAILY = 'DAILY';
export const PRECING_WEEKLY = 'WEEKLY';
export const PRECING_MONTHLY = 'MONTHLY';
export const PRECING_CHANGE = 'PRECING_CHANGE';
export const PROMO_CODE_CHANGE_TEMP = 'PROMO_CODE_CHANGE_TEMP';
export const PROMO_CODE_CHANGE = 'PROMO_CODE_CHANGE';
export const DELIVERY_CHANGE = 'DELIVERY_CHANGE';
export const DELIVERY_CHANGE_TEMP = 'DELIVERY_CHANGE_TEMP';
export const PICKUP_CHANGE = 'PICKUP_CHANGE';
export const BOOK_TYPE_CHANGE = 'BOOK_TYPE_CHANGE';
export const RENT = 'RENT';
export const BUY = 'BUY';
export const UPDATE_LENDER_INFO = 'UPDATE_LENDER_INFO';
export const UPDATE_LENDER_INFO_TEMP = 'UPDATE_LENDER_INFO_TEMP';
export const UPDATE_ROADIE_INFO = 'UPDATE_ROADIE_INFO';
export const UPDATE_ROADIE_INFO_TEMP = 'UPDATE_ROADIE_INFO_TEMP';
export const UPDATE_ACCESORY = 'UPDATE_ACCESORY';
export const MODIFY_ACCESORY = 'MODIFY_ACCESORY';
export const AVAILABILITY_SET_RANGES = 'AVAILABILITY_SET_RANGES';
export const AVAILABILITY_SET_HOURS = 'AVAILABILITY_SET_HOURS';
export const AVAILABILITY_SET_ERROR = 'AVAILABILITY_SET_ERROR';
export const NEED_COMPLETE_PROFILE = 'NEED_COMPLETE_PROFILE';
export const UPDATE_PENDING_INFO = 'UPDATE_PENDING_INFO';
export const COUPON_LIST = 'COUPON_LIST';
export const COUPON_NEW = 'COUPON_NEW';
export const NEED_ACCEPT_LONG_DISTANCE = 'NEED_ACCEPT_LONG_DISTANCE';
export const RENTAL_CREATED = 'RENTAL_CREATED';
export const RENTAL_CREATION_ERROR = 'RENTAL_CREATION_ERROR';
export const SET_WARNING_ROADIE = 'SET_WARNING_ROADIE';
export const NEED_VERIFY_ACCOUNT = 'NEED_VERIFY_ACCOUNT';
export const UPDATE_FROM_HOUR = 'UPDATE_FROM_HOUR';
export const UPDATE_TO_HOUR = 'UPDATE_TO_HOUR';
export const GET_LENDER_ITEMS = 'GET_LENDER_ITEMS';
export const CLEAR_BOOK_ERROR = 'CLEAR_BOOK_ERROR';
export const CLEAR_NEW_DELIVERY_FLAG = 'CLEAR_NEW_DELIVERY_FLAG';

// Profile
export const PROFILE_INFO_CHANGED = 'PROFILE_INFO_CHANGED';
export const UPDATE_ABOUT_ME = 'UPDATE_ABOUT_ME';
export const UPDATE_FULL_NAME = 'UPDATE_FULL_NAME';
export const UPDATE_FAVORITE = 'UPDATE_FAVORITE';
export const CHANGE_PASS_UPDATE = 'CHANGE_PASS_UPDATE';
export const CHANGE_PASS_SUCCESS = 'CHANGE_PASS_SUCCESS';
export const GET_PRIVATE = 'GET_PRIVATE';
export const UPDATE_PENDING_PICTURE = 'UPDATE_PENDING_PICTURE';

// Payment Methods
export const UPDATE_DEFAULT_CARD = 'UPDATE_DEFAULT_CARD';
export const PAYMENT_ADD_EDIT = 'PAYMENT_ADD_EDIT';
export const CHECK_NEW_PAYMENT_VALIDATIONS = 'CHECK_NEW_PAYMENT_VALIDATIONS';
export const UPDATE_PAYMENT_VALIDATION_STATUS = 'UPDATE_PAYMENT_VALIDATION_STATUS';
export const PAYMENT_METHOD_ADDED_SUCCESS = 'PAYMENT_METHOD_ADDED_SUCCESS';
export const DELETED_CARD_SUCCESS = 'DELETED_CARD_SUCCESS';
export const UPDATE_BOOKING_CARD = 'UPDATE_BOOKING_CARD';

// shufti
export const INITIALIZE_SHUFTI_PRO = 'INITIALIZE_SHUFTI_PRO';
// Home
export const SET_LOCATION = 'SET_LOCATION';

// Explore
export const APPLY_CATEGORIES = 'APPLY_CATEGORIES';
export const LOCATION = 'LOCATION';

// Payout Account
export const PAYOUT_EDIT_INFO = 'PAYOUT_EDIT_INFO';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const CHECK_NEW_PAYOUT_VALIDATIONS = 'CHECK_NEW_PAYOUT_VALIDATIONS';
export const UPDATE_PAYOUT_VALIDATION_STATUS = 'UPDATE_PAYOUT_VALIDATION_STATUS';
export const PAYOUT_UPDATED_CREATED = 'PAYOUT_UPDATED_CREATED';

// Roadie
export const CHECK_ROADIE_FIELDS = 'CHECK_ROADIE_FIELDS';
export const SET_SUMMARY = 'SET_SUMMARY';
export const SET_SUMMARY_TEMP = 'SET_SUMMARY_TEMP';
export const DELIVERY_ACTIVITY_SUCCES = 'DELIVERY_ACTIVITY_SUCCES';
export const DELIVERY_ACTIVITY_SUCCESS_ROADIE = 'DELIVERY_ACTIVITY_SUCCESS_ROADIE';

// Chat
export const CHAT_NOTIFICATIONS = 'CHAT_NOTIFICATIONS';
export const CHAT_ALL_MESSAGES = 'CHAT_ALL_MESSAGES';
export const CHAT_NEW_MESSAGE = 'CHAT_NEW_MESSAGE';
export const CHAT_START_TASK = 'CHAT_START_TASK';

// Activity
export const ACTIVITY_INFO_LOADED = 'ACTIVITY_INFO_LOADED';
export const ACTIVITY_HISTORY_INFO_LOADED = 'ACTIVITY_HISTORY_INFO_LOADED';
export const ACTIVITY_HISTORY_INFO_FILTERED = 'ACTIVITY_HISTORY_INFO_FILTERED';
export const ACTIVITY_INFO_FILTERED = 'ACTIVITY_INFO_FILTERED';
export const ACTIVITY_DETAIL_ITEM_SET = 'ACTIVITY_DETAIL_ITEM_SET';
export const ACTIVITY_EXTRA_INFO_LOADED = 'ACTIVITY_EXTRA_INFO_LOADED';
export const RENTAL_PICKUP_IMAGES_LOADED = 'RENTAL_PICKUP_IMAGES_LOADED';
export const RENTAL_DROPOFF_IMAGES_LOADED = 'RENTAL_DROPOFF_IMAGES_LOADED';
export const SET_NEW_ACTIVITY_LOCATION = 'SET_NEW_ACTIVITY_LOCATION';
export const ACTIVITY_CIRCLE_JOIN_EXTRA_INFO = 'ACTIVITY_CIRCLE_JOIN_EXTRA_INFO';
export const ACTIVITY_CIRCLE_INVITATION_INFO = 'ACTIVITY_CIRCLE_INVITATION_INFO';
export const UNSET_ACTIVITY_ITEM = 'UNSET_ACTIVITY_ITEM';
export const LOAD_PICKUP_PICTURES = 'LOAD_PICKUP_PICTURES';
export const LOAD_DROPOFF_PICTURES = 'LOAD_DROPOFF_PICTURES';
export const LOAD_PICKUP_VIDEOS = 'LOAD_PICKUP_VIDEOS';
export const STOP_SWIPER_VIDEOS = 'STOP_SWIPER_VIDEOS';

// Activity Actions
export const ACTIVITY_VALIDATES_APPROVED_STATE = 'ACTIVITY_VALIDATES_APPROVED_STATE';
export const ACTIVITY_START_VALIDATES_APPROVED_STATE = 'ACTIVITY_START_VALIDATES_APPROVED_STATE';
export const ACTIVITY_START_APPROVAL_PROCESS = 'ACTIVITY_START_APPROVAL_PROCESS';
export const UPDATE_REJECT_REASON = 'UPDATE_REJECT_REASON';
export const ACTIVITY_START_TASK = 'ACTIVITY_START_TASK';
export const REFRESH_ACTIVITY_TASK_DONE = 'REFRESH_ACTIVITY_TASK_DONE';
export const ROADIE_IN_PAST = 'ROADIE_IN_PAST';
export const APPROVE_REJECT_FAILURE = 'APPROVE_REJECT_FAILURE';
export const UPDATE_REVIEW_RATE = 'UPDATE_REVIEW_RATE';
export const UPDATE_REVIEW_COMMENT = 'UPDATE_REVIEW_COMMENT';
export const UPDATE_REVIEW_THUMBS = 'UPDATE_REVIEW_THUMBS';
export const SET_LOADING_TRUE = 'SET_LOADING_TRUE';
export const SET_LOADING_FALSE = 'SET_LOADING_FALSE';
export const SET_DISABLE_BUTTON = 'SET_DISABLE_BUTTON';
export const ACCEPTED_CIRCLE_JOIN_REQUEST = 'ACCEPTED_CIRCLE_JOIN_REQUEST';
export const UPLOAD_PICTURE_FAILURE = 'UPLOAD_PICTURE_FAILURE';
export const NEW_VIDEO_CONVERTED = 'NEW_VIDEO_CONVERTED';

// socket
export const INIT_WS_CONNECTION = 'INIT_WS_CONNECTION';

// CIRCLES
export const SET_INITIAL_CIRCLE = 'SET_INITIAL_CIRCLE';
export const CONDITION_ADD_EDIT = 'CONDITION_ADD_EDIT';
export const CHECK_CONDITIONS_VALIDATIONS = 'CHECK_CONDITIONS_VALIDATIONS';
export const UPDATE_CONDITION_VALIDATION_STATUS = 'UPDATE_CONDITION_VALIDATION_STATUS';
export const SET_CONDITION_EDIT_INDEX = 'SET_CONDITION_EDIT_INDEX';
export const CONDITIONS_UPDATE_LIST = 'CONDITIONS_UPDATE_LIST';
export const REMOVE_CONDITION = 'REMOVE_CONDITION';
export const CIRCLE_INFO_CHANGED = 'CIRCLE_INFO_CHANGED';
export const FILTER_CIRCLE_ITEMS = 'FILTER_CIRCLE_ITEMS';
export const UPDATE_CIRCLES_LIST = 'UPDATE_CIRCLES_LIST';
export const UPDATE_REASON_DISABLE_ITEM = 'UPDATE_REASON_DISABLE_ITEM';
export const SET_ITEM_ACTIVE_TO_UPDATE = 'SET_ITEM_ACTIVE_TO_UPDATE';
export const UPDATE_PAGE_ITEM_STATUS_UPDATE = 'UPDATE_PAGE_ITEM_STATUS_UPDATE';
export const MEMBER_DETAILS = 'MEMBER_DETAILS';
export const UPDATED_MEMBER_PERMIT = 'UPDATED_MEMBER_PERMIT';
export const CIRCLE_UPDATED_SUCCESSFULLY = 'CIRCLE_UPDATED_SUCCESSFULLY';
export const CIRCLES_TYPE = 'CIRCLES_TYPE';
export const LOAD_SEARCH_CIRCLES = 'LOAD_SEARCH_CIRCLES';
export const GET_SEARCHED_CIRCLES = 'GET_SEARCHED_CIRCLES';
export const UPDATE_SUGGESTED_CIRCLES = 'UPDATE_SUGGESTED_CIRCLES';
export const UPDATE_SEARCHED_CIRCLES = 'UPDATE_SEARCHED_CIRCLES';
export const UNSET_MEMBERS_EDIT = 'UNSET_MEMBERS_EDIT';
export const CIRCLE_UPDATE_ERROR = 'CIRCLE_UPDATE_ERROR';
export const ACTIVE_CIRCLE_UPDATED = 'ACTIVE_CIRCLE_UPDATED';
export const SET_IS_CHOOSING_NEW_ONWER = 'SET_IS_CHOOSING_NEW_ONWER';
export const DISABLE_MEMBERS = 'DISABLE_MEMBERS';
export const SUCCESSFULLY_LEFT_CIRCLE = 'SUCCESSFULLY_LEFT_CIRCLE';
export const RE_SET_MEMBERS = 'RE_SET_MEMBERS';
export const FILTER_CIRCLE_MEMBERS = 'FILTER_CIRCLE_MEMBERS';
export const SET_CIRCLE_ITEMS = 'SET_CIRCLE_ITEMS';
export const VALID_ERRORS = 'VALID_ERRORS';
export const CLEAN_ERRORS = 'CLEAN_ERRORS';
export const LOAD_CIRCLES = 'LOAD_CIRCLES';
export const LOAD_CIRCLES_DETAILS = 'LOAD_CIRCLES_DETAILS';
export const CIRCLE_UPDATED_CLEAN = 'CIRCLE_UPDATED_CLEAN';

// Favorites
export const ITEMS_LOADED = 'ITEMS_LOADED';

import { ThumbUpRounded } from '@mui/icons-material';
import {
  Avatar,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LmiTheme } from '..';
import { ReviewsSummaryModels } from './ReviewsSummary.models';

function ReviewsSummary(props: ReviewsSummaryModels) {
  const { t } = useTranslation('global');
  const dataList = [
    {
      kLabel: `${t('profile.x_recommended')}`,
      kValue: props.kRecommendedReview,
    },
    // {
    //   kLabel: 'Positive',
    //   kValue: props.kPositiveReview,
    // },
    // {
    //   kLabel: 'Negative',
    //   kValue: props.kNegativeReview,
    // },
    {
      kLabel: 'Total',
      kValue: props.kTotalReview,
    },
  ];
  return (
    <>
      <Paper variant="outlined">
        <List disablePadding>
          {
            /* eslint-disable react/jsx-props-no-spreading */
            dataList.map((d, i) => (
              <ListItem
                disablePadding
                divider={i < dataList.length - 1 ?? false}
                sx={{ px: 2, py: 1 }}
              >
                {
                  i === 0
                  && (
                    <ListItemText
                      primaryTypographyProps={{ fontWeight: 'bold', color: LmiTheme.light.systemBackground.primary, fontSize: 'subtitle2.fontSize' }}
                      primary={
                        (
                          <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={0}
                            my={1}
                            p={0}
                          >
                            <Avatar
                              onClick={undefined}
                              sx={{
                                bgcolor: LmiTheme.light.secondaryColor.green,
                                width: 24,
                                height: 24,
                                mr: 1,
                              }}
                            >
                              <ThumbUpRounded sx={{ color: LmiTheme.light.systemBackground.primaryIverted, fontSize: 14 }} />
                            </Avatar>
                            {d.kValue}
                            <>% </>
                            {d.kLabel}
                          </Stack>
                        )
                      }
                      {...d}
                    />
                  )
                }
                <ListItemText
                  primaryTypographyProps={{ color: LmiTheme.light.systemBackground.primary, fontSize: 'subtitle2.fontSize', fontWeight: i < dataList.length - 1 ? 'normal' : 'bold' }}
                  primary={
                    i > 0
                    && d.kLabel
                  }
                  {...d}
                />
                <ListItemSecondaryAction>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ fontWeight: i < dataList.length - 1 ? 'normal' : 'bold' }}
                  >
                    {
                      i > 0
                      && d.kValue
                    }
                  </Stack>
                </ListItemSecondaryAction>
              </ListItem>
            ))
          }
        </List>
      </Paper>
    </>
  );
}

ReviewsSummary.defaultProps = {
  kRecommendedReview: 100,
  kPositiveReview: 0,
  kNegativeReview: 0,
  kTotalReview: 0,
};

export default ReviewsSummary;

import React, { useContext, useState } from 'react';
import {
  AddCircleRounded,
} from '@mui/icons-material';
import {
  List,
  Box,
  Stack,
  TextField,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import LmiTheme from '../../../../../themes/lmi-mui-theme/lmi-theme';
import DialogPromoCodesItem from './DialogPromoCodesItem';
import bookItemContext from '../../../../../context/bookItem/bookContext';
import { formatter } from '../../../../../services/common';

function DialogPromoCodes() {
  const {
    validateCodeCoupon,
    couponList,
    setPromoSelectedTemp,
    promoSelectedTemp,

  }: any = useContext(bookItemContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const limitPromoCode = 45;
  const [valuesPromoCode, setValuesPromoCode] = useState({
    code: '',
  });
  const changePromoCode = (code: string) => (event: { target: { value: any; }; }) => {
    setValuesPromoCode({ ...valuesPromoCode, [code]: event.target.value.toUpperCase() });
  };

  const handlePaymentSelected = (
    _event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setPromoSelectedTemp(promoSelectedTemp != index ? index : 0);
  };

  const checkCode = async () => {
    if (await validateCodeCoupon(valuesPromoCode.code)) {
      setValuesPromoCode({ code: '' });
    }
  };

  // const couponList = [
  //   {
  //     kId: 0,
  //     kCodeName: 'GEAR20',
  //     kCodePromo: '20% Off',
  //     kCodeDescription: '20% of discount on every listing related to camera equipment.',
  //     kCodeValidity: true,
  //     kCodeValidDate: 'Valid only in August 2021.',
  //   }];

  const { t } = useTranslation('global');

  return (
    <>
      <Box
        sx={{
          mx: isMobile ? 1 : 3,
          mt: 2,
          px: isMobile ? 1 : 1,
          py: 1,
          color: LmiTheme.light.systemBackground.tertiary,
          width: 'auto',
        }}
      >
        {t('dialog.write_promo_code')}
      </Box>
      <Box sx={{ mx: isMobile ? 1 : 3, px: isMobile ? 1 : 1, py: 2 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          pb={1}
        >
          <Box>
            <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
            <Typography component="span" fontWeight={500}>{t('dialog.code')}</Typography>
          </Box>
          <Typography pr={7} component="span" color={LmiTheme.light.systemBackground.tertiary}>
            {`${valuesPromoCode.code.length}/${limitPromoCode}`}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
          pb={1}
        >
          <TextField
            fullWidth
            id="outlined-basic"
            variant="outlined"
            type="text"
            placeholder={t('dialog.enter_code')}
            value={valuesPromoCode.code}
            onChange={changePromoCode('code')}
            inputProps={{
              maxLength: limitPromoCode,
            }}
            sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
          />
          <IconButton
            aria-label="toggle password visibility"
            onClick={checkCode}
          >
            <AddCircleRounded
              // onClick={checkCode}
              fontSize="large"
              color="primary"
            />
          </IconButton>
        </Stack>
      </Box>
      <Box
        sx={{
          mx: isMobile ? 1 : 3, px: isMobile ? 1 : 1, pb: 2, fontWeight: 'bold',
        }}
      >
        {t('dialog.available')}
      </Box>
      <List
        sx={{
          mx: 0,
          px: isMobile ? 1 : 3,
          backgroundColor: LmiTheme.light.systemBackground.base,
          height: '100%',
        }}
      >

        { couponList && couponList.length > 0
          /* eslint-disable react/jsx-props-no-spreading */
          ? couponList.map((l: any) => (
            <DialogPromoCodesItem
              key={l.idCatLndUserCouponCodes}
              kPromoSelectedIndex={promoSelectedTemp === l.idCatLndUserCouponCodes}
              kHandlePaymentSelected={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => handlePaymentSelected(event, l.idCatLndUserCouponCodes)}
              kDivider={false}
              kCodeName={l.codeCoupon}
              kCodeDescription={l.description}
              kCodePromo={l.discount_percent > 0 ? `${l.discount_percent}% Off` : formatter.format(l.discount_dollars)}
              kCodeValidity={!l.code_expired}
              kDiscountPercent={l.discount_percent}
                // code_expiration_date={l.code_expiration_date}
              kCodeValidDate={`${t('dialog.coupon_valid')} ${moment(l.code_expiration_date, 'MM/DD/YYYY').format('ll')}`}
              {...l}
            />
          ))
          : null}
      </List>
    </>
  );
}

export default DialogPromoCodes;

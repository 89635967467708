import { Typography } from '@mui/material';
import { LmiTheme } from '../../../../../../components/Common';
import { kTitleModels } from './Heading.models';

function Heading(props: kTitleModels) {
  const paddingBottom = props.kPaddingBottom;

  return (
    <>
      <Typography
        component={props.kComponent}
        pb={paddingBottom || 2}
        sx={{
          fontSize: '18px',
          fontWeight: 'bold',
          color: LmiTheme.light.systemBackground.primary,
        }}
      >
        {props.kTitle}
      </Typography>
    </>
  );
}

Heading.defaultProps = {
  kTitle: 'kTitle',
  kComponent: 'p',
};

export default Heading;

import {
  Box, Typography, Paper, Stack, Avatar, ListItemAvatar,
} from '@mui/material';
// import { useTranslation } from 'react-i18next';
//   import ActivityUserStyle from './ActivityUser.style';
import { LmiColorPalette, SectionTitle } from '../../../../../../components/Common';
// import { CircleDetailInfoModel } from '../../../../Circles/AddCircle/AddCircle.models';
import ActivityUserStyle from '../ActivityUser/ActivityUser.style';
//   import { ActivityUserModels } from './ActivityUser.models';

// export default function ActivityCircleCard(props : CircleDetailInfoModel) {
export default function ActivityCircleCard(props: any) {
  //   const { t } = useTranslation('global');

  // let defaultImg =
  //     'https://lendmt-images.s3.us-east-2.amazonaws.com/lnd_own_images/placeholder_profile.png';
  return (
    <Box sx={ActivityUserStyle}>
      <SectionTitle
        kMarginTop={2}
        kTitle="Circle"
        kFontSize={18}
      />
      <Paper className="lmiw-activity-user" variant="outlined" elevation={0}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <ListItemAvatar>
              <Avatar
                src={props?.circleInfo?.circlePicture}
                alt={props?.circleInfo?.circlePicture}
                sx={{ width: { md: 100, xs: 65 }, height: { md: 100, xs: 65 } }}
              />
            </ListItemAvatar>
            <Box>
              <Typography className="lmiw-user-name" mb={0.5} color={LmiColorPalette.blueScheme[600]}>
                {props?.circleInfo?.name != null ? props?.circleInfo?.name : props?.circleInfo?.circleName}
              </Typography>
              <Stack
                direction="row"
                className="lmiw-user-like"
              >
                <Typography fontSize={14}>
                  {props?.circleInfo?.dscription != null ? props?.circleInfo?.dscription : props?.circleInfo?.circleDescription}
                </Typography>

              </Stack>
              {/* <Stack
                  direction="row"
                  className={`lmiw-user-${props.kType}`}
                >
                  {console.log(props.kType)}
                  {props.kType === 'verified' ? <Verified /> : <Bank />}
                  <Typography fontSize={14}>
                    {props.kType === 'verified' ? 'ID Verified' : 'Special Organization'}
                  </Typography>
                </Stack> */}
            </Box>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
}

import {
  Box,
  Button,
  CardMedia,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import React from 'react';
import { LmiTheme } from '../ProfilePrivate/Components';
// import createdCircleImage from '../../../assets/create_listing/listingCreated.png';
import setupPayoutImage from '../../../assets/create_listing/no_payment@3x.png';

import MyListingContext from '../../../context/myListing/myListingContext';
import { emptyItemModel } from '../Listing/Components/AddItem/AddItem.models';

  interface DialogSetupPayoutT {
    handleDialog: () => void,
  }

function DialogSetupPayout(props: DialogSetupPayoutT) {
  const { handleDialog } = props;
  const { details }: any = React.useContext(MyListingContext);

  const { t } = useTranslation('global');

  const history = useHistory();
  const state = history.location.state as {
      kItemId: string,
      kItemIndex: number,
      updateSwiper: false,
      kShowDetailsMobile: false
    };

  const goToProfilePayout = () => {
    window.scrollTo(0, 0);
    details(emptyItemModel(), []);
    history.push({
      pathname: '/private-profile',
      state: {
        ...state,
        kGoToPayout: 1,
      },
    });
  };

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        maxWidth="686px"
        spacing={0}
        py={3}
      >
        <CardMedia
          component="img"
          image={setupPayoutImage}
          sx={{ width: '70%', maxWidth: '325px', zIndex: 2 }}
        />
        {
            isMobile ? (
              <>
                <Box
                  width="100%"
                  height="54%"
                  position="absolute"
                  sx={{
                    top: 0,
                    zIndex: 0,
                    backgroundPosition: 'center',
                    height: '196px',
                  }}
                />
                <Typography sx={{
                  mb: -1,
                  fontSize: 'h5.fontSize',
                  fontWeight: 600,
                  maxWidth: '235px',
                  textAlign: 'center',
                }}
                >
                  Set up your payout!
                </Typography>

              </>
            ) : (
              <>
                <Box
                  width="100%"
                  height="54%"
                  position="absolute"
                  sx={{
                    top: 0,
                    // pb: 50,
                    zIndex: 0,
                    backgroundPosition: 'center',
                    height: '281.3px',
                  }}
                />
                <Typography sx={{
                  pt: '35px',
                  fontSize: 'h5.fontSize',
                  fontWeight: 600,
                  maxWidth: '285px',
                  textAlign: 'center',
                }}
                >
                  {t('listing.setup_payout')}
                </Typography>

              </>
            )
          }
        <Box height={20} />
        <Box
          sx={{
            fontSize: 'subtitle1.fontSize',
            fontWeight: 500,
            color: LmiTheme.light.systemBackground.tertiary,
            maxWidth: '70%',
            textAlign: 'center',
            mb: '10px',
            zIndex: 1,
          }}
        >
          {t('listing.setup_payout_description')}
        </Box>
        <Box
          sx={{
            fontSize: 'subtitle2.fontSize',
            fontWeight: 300,
            color: LmiTheme.light.systemBackground.tertiary,
            maxWidth: '70%',
            textAlign: 'center',
            mb: '30px',
            zIndex: 1,
          }}
        >
          {t('listing.setup_payout_text')}
        </Box>
        <Button
          onClick={() => {
            goToProfilePayout();
          }}
          disableElevation
          variant="contained"
          size="large"
          sx={{
            px: 3,
            fontSize: 'subtitle1.fontSize',
            textTransform: 'none',
            fontWeight: 'bold',
            mb: '30px',
          }}
        >
          {t('listing.setup_payout_confirm')}
        </Button>
        <Button
          variant="text"
          onClick={handleDialog}
          sx={{
            color: LmiTheme.light.systemBackground.secondary,
            textTransform: 'none',
            fontSize: '12px',
          }}
        >
          {t('listing.setup_payout_cancel')}
        </Button>
      </Stack>
    </>
  );
}

export default DialogSetupPayout;

import {
  Box,
  Tabs,
  Tab,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LmiTheme } from '..';
import { MainInformationModels } from './MainInformation.models';

function MainInformation(props: MainInformationModels) {
  const { t } = useTranslation('global');
  /// Breakpoints Media Query
  const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));
  const mainInformationList = [
    `${t('profile.main_info')}`,
    // 'Visible Only to You',
    `${t('profile.payment_method')} & ${t('profile.payout_account')}`,
    // 'My Promo Code',
    `${t('profile.password')}`,
    `${t('profile.reviews')}`,
    // `${t('profile.payout_account')}`,
    // 'Special Organization',
  ];
  return (
    <>
      <Box p={0} m={0} sx={{ width: '100%', maxWidth: '100vw', bgcolor: 'background.paper' }}>
        <Tabs
          orientation={!isTablet ? 'horizontal' : 'vertical'}
          variant="scrollable"
          centered
          scrollButtons="auto"
          allowScrollButtonsMobile
          value={props.kValue}
          onChange={props.kHandleChange}
          TabIndicatorProps={{
            style: {
              background: !isTablet ? LmiTheme.light.accent : 'transparent',
            },
          }}
          sx={{ m: 0, p: 0 }}
        >
          {
            mainInformationList.map((k, i) => (
              <Tab
                label={k}
                id={`profile-tab-${i}`}
                disabled={props.kValue === i ?? false}
                disableRipple
                sx={{
                  py: 2.7,
                  alignItems: 'flex-start',
                  textTransform: 'none',
                  color: LmiTheme.light.accent,
                  borderBottom: i < mainInformationList.length - 1 ? `1px solid ${LmiTheme.light.systemBackground.separator}` : '1px solid transparent',
                }}
              />
            ))
          }
        </Tabs>
      </Box>
    </>
  );
}

export default MainInformation;

import {
  Box,
  Button,
  CardMedia,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LmiTheme } from '..';
import CongratsRental from '../../../../../assets/book_item/png/congrats_rental@3x.png';
import CongratsRentalConfetti from '../../../../../assets/book_item/svg/confetti.svg';
import { DialogCongratulationsModels } from './DialogCongratulations.models';

function DialogCongratulations(props: DialogCongratulationsModels) {
  const { t } = useTranslation('global');
  return (
    <>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        py={3}
      >
        <Box
          width="100%"
          height="54%"
          position="absolute"
          sx={{
            top: 0,
            zIndex: 0,
            backgroundImage: `url(${CongratsRentalConfetti})`,
            backgroundPosition: 'center',
          }}
        />
        <Box
          sx={{
            fontSize: 'h5.fontSize',
            fontWeight: 700,
          }}
        >
          {t('dialog.congratulations')}
        </Box>
        <CardMedia
          component="img"
          alt="confetti"
          image={CongratsRental}
          sx={{ width: '70%' }}
        />
        <Box
          sx={{
            fontSize: 'subtitle1.fontSize',
            fontWeight: 500,
            color: LmiTheme.light.systemBackground.tertiary,
            maxWidth: '70%',
            textAlign: 'center',
          }}
        >
          {props?.kIsRent ? t('dialog.reservation_sent') : t('dialog.buy_success')}
        </Box>
        <Button
          onClick={props.kOnContinue}
          disableElevation
          variant="text"
          size="large"
          sx={{
            px: 3,
            fontSize: 'subtitle1.fontSize',
            textTransform: 'none',
            fontWeight: 'bold',
          }}
        >
          {t('dialog.continue')}
        </Button>
      </Stack>
    </>
  );
}

export default DialogCongratulations;

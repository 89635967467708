import { useContext } from 'react';
import {
  Box, Grid, Stack, TextField, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import LmiTheme from '../../../themes/lmi-mui-theme/lmi-theme';
import publicProfileContext from '../../../context/publicProfile/publicProfileContext';

function DialogAboutMeEdit() {
  const pProfileContext = useContext(publicProfileContext);
  const limitDescription = 250;

  const {
    newAboutMe,
    updateProfileAboutMe,
  }: any = pProfileContext;

  const changeDescription = (e: any) => {
    updateProfileAboutMe(e.target.value);
  };
  const { t } = useTranslation('global');

  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            pb={1}
          >
            <Box>
              <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
              <Typography component="span" fontWeight={500}>{t('profile.description')}</Typography>
            </Box>
            <Typography component="span" color={LmiTheme.light.systemBackground.tertiary}>
              {`${(newAboutMe?.length)}/${limitDescription}`}
            </Typography>
          </Stack>
          <TextField
            fullWidth
            id="outlined-basic"
            variant="outlined"
            type="text"
            placeholder={t('profile.enter_description')}
            value={newAboutMe}
            multiline
            rows={4}
            maxRows={4}
            onChange={changeDescription}
            sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
            inputProps={{
              maxLength: limitDescription,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default DialogAboutMeEdit;

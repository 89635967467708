import { LmiTheme } from '../../../../../../components/Common';

const ActivityItemStyle = {
  width: '100%',
  height: '100%',
  '&.listing-item-active': {
    backgroundColor: LmiTheme.light.accentActiveBackground,
  },
  '.lmiw-listing-item': {
    width: '100%',
    '.lmiw-item-image': {
      cursor: 'pointer',
      width: 74,
      height: 74,
      // marginRight: 1,
      backgroundColor: LmiTheme.light.accent,
      // borderRadius: 2,
      border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
      '&.MuiAvatar-rounded': {
        borderRadius: 2,
      },
    },
    '.lmiw-item-content': {
      cursor: 'pointer',
      width: 'calc(100% - 50px)',
      minHeight: '76px',
      lineHeight: 1,
      paddingRight: 1,
      '.lmiw-content-info': {
        span: {
          fontSize: 14,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
          width: '100%',
          // maxWidth: { md: '150px', lg: '210px' },
          lineHeight: 1.2,
        },
        '.lmiw-content-title': {
          color: LmiTheme.light.systemBackground.primary,
          fontWeight: '500',
        },
        '.lmiw-content-desc': {
          color: LmiTheme.light.systemBackground.secondary,
        },
      },
      '.lmiw-content-status': {
        display: 'flex',
        span: {
          fontSize: 12,
          color: LmiTheme.light.systemBackground.secondary,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
          textTransform: 'capitalize',
        },
        '.lmiw-status-type': {
          fontWeight: '500',
        },
        '.lmiw-status-username': {
          fontWeight: '400',
        },
        '.lmiw-status-bullet': {
          mx: 0.3,
          fontWeight: '500',
        },
      },
      '.lmiw-content-time': {
        fontSize: 12,
        color: LmiTheme.light.systemBackground.secondary,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        textTransform: 'capitalize',
      },

    },
    '.lmiw-item-actions': {
      '.MuiBadge-anchorOriginTopRightRectangular, .MuiBadge-anchorOriginTopRightCircular': {
        backgroundColor: LmiTheme.light.secondaryColor.orange,
        width: '6px',
        height: '6px',
        minWidth: '6px',
        border: '1px solid white',
      },
    },
  },

};

export default ActivityItemStyle;

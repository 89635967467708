// import { Favorite, FavoriteBorder } from '@mui/icons-material';
import {
  // Avatar,
  Box,
  // Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { MyListingsModels } from './MyListings.models';
import BookContext from '../../../../../context/bookItem/bookContext';

function MyListings(props: MyListingsModels) {
  const {
    clearData,
  }: any = useContext(BookContext);

  const history = useHistory();

  function handleClick(bookItem: any): void {
    clearData();

    const state = history.location.state as { bookItem: any };
    clearStorageBookItem();
    localStorage.setItem('storageIdItem', bookItem.idCatLndItem);
    localStorage.setItem('checkout', '');
    localStorage.removeItem('precingType');
    history.push('/bookitem', {
      ...state,
      bookItem,
      fromPage: 'public-profile',
    });
  }

  function clearStorageBookItem() {
    localStorage.removeItem('storageDateRange');
    localStorage.removeItem('storageDeliveryType');
    localStorage.removeItem('storageAccesories');
  }

  function formatListingsPrice(value: any) {
    const newValue = `${value}`;
    const priceArray: any = newValue.split('.');
    if (parseInt(priceArray[1]) == 0) {
      return parseInt(priceArray[0]);
    }
    return value;
  }

  return (
    <>
      <Box p={3}>
        <Grid container rowSpacing={0} columnSpacing={3}>
          {
            /* eslint-disable react/jsx-props-no-spreading */
            props.kItemsListing.map((d: any, i: number) => (
              i <= 9 && (

                <Grid item xs={6} sm={4} md={3}>
                  <Card elevation={0} onClick={() => handleClick(d)} sx={{ cursor: 'pointer' }}>
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="flex-starts"
                      sx={{
                        aspectRatio: '5 / 4',
                        backgroundImage: `url(${d.itemPicture})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        borderRadius: '10px',
                      }}
                    />
                    <CardContent sx={{ p: 0 }}>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        px={0}
                        pt={1}
                        pb={0.5}
                      >
                        <Typography
                          variant="body2"
                          color="text.primary"
                          fontWeight={500}
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                          }}
                        >
                          {d.name}
                        </Typography>

                        {(() => {
                          let price = 0;
                          if (d.dailyRentalPrice != 0) {
                            price = d.dailyRentalPrice;
                          } else if (d.hourlyRentalPrice != 0) {
                            price = d.hourlyRentalPrice;
                          } else if (d.weeklyRentalPrice != 0) {
                            price = d.weeklyRentalPrice;
                          } else if (d.monthlyRentalPrice != 0) {
                            price = d.monthlyRentalPrice;
                          }
                          return (
                            <Chip
                              label={`$${formatListingsPrice(price)} ${d.currency}`}
                              variant="outlined"
                              sx={{ backgroundColor: 'white', fontWeight: 'bold' }}
                            />
                          );
                        })()}
                      </Stack>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        fontSize="12px"
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                        }}
                      >
                        {d.dscription}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )
            ))
          }
        </Grid>

      </Box>
    </>
  );
}

export default MyListings;

import { LmiTheme } from '../../..';

const UserBtnChip = {
  height: { xs: '44px', sm: '46px' },
  borderRadius: '25px',
  paddingLeft: { xs: '0px', sm: '8px' },
  backgroundColor: '#fff',
  cursor: 'pointer',
  border: `1px solid ${LmiTheme.light.systemBackground.separator}`,

  '&.MuiChip-root:hover': {
    backgroundColor: '#fff',
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#fff',
  },
  '.lmiw-list-item': {
    span: {
      fontWeight: 400,
      fontSize: 'body2.fontSize',
      height: '15px',
    },
    'p,span': {
      lineHeight: '1',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '110px',
      whiteSpace: 'nowrap',
      minWidth: '80px',
    },
    'p.MuiTypography-root': {
      fontSize: '12px',
    },
  },
  'svg.lmiw-list-icon': {
    fill: LmiTheme.light.accent,
  },
};

export default UserBtnChip;

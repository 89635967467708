// import { useTranslation } from 'react-i18next';

export interface RoadieShipmentState {

  value: string;
  description: string;
}

export default function useGetRoadieShipmentState(state: any) {
  switch (state) {
    case null:
      return RoadieShipmentWaiting;
    case 'scheduled':
      return RoadieShipmentScheduled;
    case 'assigned':
      return RoadieShipmentAssigned;
    case 'en_route':
      return RoadieShipmentEnRoute;
    case 'delivered':
      return RoadieShipmentDelivered;
    case 'attempted':
      return RoadieShipmentAttempted;
    case 'returned':
      return RoadieShipmentReturned;
    case 'canceled':
      return RoadieShipmentCanceled;
    case 'expired':
      return RoadieShipmentExpired;
    default:
      return RoadieShipmentUnkown;
  }
}

const RoadieShipmentWaiting: RoadieShipmentState = {
  value: 'activity.delivery.roadie_waiting_title',
  description: 'activity.delivery.roadie_waiting',
};

const RoadieShipmentScheduled: RoadieShipmentState = {
  value: 'activity.delivery.roadie_scheduled_title',
  description: 'activity.delivery.roadie_scheduled',
};

const RoadieShipmentAssigned: RoadieShipmentState = {
  value: 'activity.delivery.roadie_assigned_title',
  description: 'activity.delivery.roadie_assigned',
};

const RoadieShipmentEnRoute: RoadieShipmentState = {
  value: 'activity.delivery.roadie_en_route_title',
  description: 'activity.delivery.roadie_en_route',
};

const RoadieShipmentDelivered: RoadieShipmentState = {
  value: 'activity.delivery.roadie_delivered_title',
  description: 'activity.delivery.roadie_delivered',
};

const RoadieShipmentAttempted: RoadieShipmentState = {
  value: 'activity.delivery.roadie_attempted_title',
  description: 'activity.delivery.roadie_attempted',
};

const RoadieShipmentReturned: RoadieShipmentState = {
  value: 'activity.delivery.roadie_returned_title',
  description: 'activity.delivery.roadie_returned',
};

const RoadieShipmentCanceled: RoadieShipmentState = {
  value: 'activity.delivery.roadie_canceled_title',
  description: 'activity.delivery.roadie_canceled',
};

const RoadieShipmentExpired: RoadieShipmentState = {
  value: 'activity.delivery.roadie_expired_title',
  description: 'activity.delivery.roadie_expired',
};

const RoadieShipmentUnkown: RoadieShipmentState = {
  value: 'unkown',
  description: 'state unkown',
};

import {
  Stack, Typography, Box, IconButton, Avatar,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import { useContext, useEffect, useState } from 'react';
import ActivityReviewStyle from './ActivityReviewModal.style';
import { InputForm, LmiTheme } from '../../../../../../components/Common';
import activityActionsContext from '../../../../../../context/activity/actions/activityActionsContext';
// import { ActivityReviewModels } from './ActivityReview.models';

export default function ActivityReviewModal({ notifDetail, onButtonStatusChange }: any) {
  const [isLike, setIslike] = useState(0);
  const activityActionContext = useContext(activityActionsContext);

  useEffect(() => {
    setIslike(0);
    setReviewComment('');
    setThumbsReview(false);
    onButtonStatusChange(true);
  }, [notifDetail]);

  const setThumbs = (value: number) => {
    setIslike(value);
    if (value == 5) {
      setThumbsReview(true);
      if (reviewComment == undefined || reviewComment.length == 0) {
        onButtonStatusChange(true);
      } else {
        onButtonStatusChange(false);
      }
    } else {
      setThumbsReview(false);
      if (reviewComment == undefined || reviewComment.length == 0) {
        onButtonStatusChange(true);
      } else {
        onButtonStatusChange(false);
      }
    }
  };

  const {
    setReviewComment,
    setThumbsReview,
    reviewComment,
  }: any = activityActionContext;

  const onChange = (e: any) => {
    setReviewComment(e.target.value);
    if (e.target.value.length > 0 && isLike > 0) {
      onButtonStatusChange(false);
    } else if (e.target.value.length < 0) onButtonStatusChange(true);
  };

  const { t } = useTranslation('global');

  return (
    <Box sx={ActivityReviewStyle}>

      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        spacing={0}
        px={3}
        pt={2}
      >
        <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>{t('activity.review_modal.how_did_it_go')}</Typography>
        <Typography sx={{ fontSize: 16, fontWeight: 'normal' }}>{t('activity.review_modal.comments')}</Typography>
      </Stack>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        spacing={0}
        px={3}
        py={1}
      >
        <Avatar
          src={notifDetail?.notifAttachment}
          sx={{
            mt: 2,
            width: { md: 100, xs: 60 },
            height: { md: 100, xs: 60 },
            bgcolor: LmiTheme.light.systemBackground.base,
            border: '3px solid  white',
          }}
        />
        <Typography component="label" sx={{ fontSize: 20, fontWeight: 'bold' }}>{notifDetail?.notifType == 'RENT' ? notifDetail?.lenderName : notifDetail?.renterName}</Typography>
        <Typography component="span" sx={{ fontSize: 16, fontWeight: '300' }}>{notifDetail?.name}</Typography>

      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        px={3}
        pt={1}
        className="lmiw-review-btn"
      >
        <Box className={`lmiw-btn-dislike ${isLike === 1 ? 'lmi-btn-active' : ''} `}>
          <IconButton aria-label="dont like" onClick={() => setThumbs(1)}>
            <ThumbDownAltIcon sx={{ color: 'white' }} />
          </IconButton>
        </Box>
        <Box className={`lmiw-btn-like ${isLike === 5 ? 'lmi-btn-active' : ''}`}>
          <IconButton aria-label="like" onClick={() => setThumbs(5)}>
            <ThumbUpIcon sx={{ color: 'white' }} />
          </IconButton>
        </Box>
      </Stack>
      <Box
        px={3}
        pb={1}
        pt={0}
      >
        <InputForm
          id="other"
          label={t('activity.review_modal.review_comments')}
          helper_text={t('activity.review_modal.required_field')}
          max_lenght="420"
          input_params={{
            multiline: true,
            required: true,
            placeholder: `${t('activity.review_modal.review_comments')}`,
            name: 'Other',
            rows: 4,
            onChange,
            value: reviewComment,
          }}
          form_control_params={{
            fullWidth: true,
          }}
        />
      </Box>

    </Box>
  );
}

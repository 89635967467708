/* eslint-disable */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import FlagMx from '../../../../../assets/icons/flag_mx.png';
import FlagUsa from '../../../../../assets/icons/flag_usa.png';
// import FlagCanada from '../../../../../assets/icons/flag-canada.png';
import authContext from '../../../../../context/authentication/authContext';
import { LmiTheme } from '../../../../../components/Common';
import { TextMaskPhone } from '../../../../../utils/inputsFormats';
import { isMobile } from 'react-device-detect';

function Email(props: any) {
  const { t } = useTranslation('global');
  const triggerFunction = props.parentMethod;
  const initialEmail = props.email;
  return (
    <Box>
      <Box>
        <Typography component="span" fontSize="caption.fontSize" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
        <Typography component="span" fontSize="caption.fontSize" fontWeight={500}>
          {t('sign_up.enter_email')}
        </Typography>
      </Box>
      <TextField
        hiddenLabel
        required
        fullWidth
        onChange={(e) => triggerFunction(e.target.value?.trim().toLowerCase())}
        value={initialEmail}
        autoCapitalize='none'
        id="email"
        placeholder={t('sign_up.email_address')}
        name="email"
        autoComplete="email"
        sx={{ my: 1 }}
      />
    </Box>

  );
}

function Password(props: any) {
  const isExistingUser = props.userExists;
  const goToForgetPassWord = props.parentMethod;
  // const initialPass = props.password;
  interface State {
    password: string;
    showPassword: boolean;
  }
  const [values, setValues] = React.useState<State>(
    {
      password: '',
      showPassword: false,
    },
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => props.updatePassword(event.target.value);

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const { t } = useTranslation('global');

  return (
    <Box my={5}>
      <Box>
        <Typography component="span" fontSize="caption.fontSize" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
        <Typography component="span" fontSize="caption.fontSize" fontWeight={500}>
          {isExistingUser ? `${t('welcome.enter_password')}` : `${t('welcome.create_password')}`}
        </Typography>
      </Box>
      <FormControl variant="standard" fullWidth>
        <OutlinedInput
          id="outlined-adornment-password"
          name="password"
          // minlength={8}
          // maxlength={30}
          type={values.showPassword ? 'text' : 'password'}
          value={props.password ?? ''}
          onChange={handleChange}
          fullWidth
          inputProps={{ minLength: 8, maxLength: 128 }}
          // defaultValue={initialPass}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )}
          placeholder={t('welcome.password')}
          sx={{ my: 1 }}
        />

        {isExistingUser
          ? (
            <FormHelperText sx={{ textAlign: 'center' }}>
              <Link onClick={goToForgetPassWord}
                to='#'
                style={{
                  color: LmiTheme.light.accent,
                  textDecoration: 'none',
                  fontSize: '14px',
                  fontWeight: 700,
                  margin: '18px 0px',
                  display: 'inline-block'
                }}
              >
                {t('welcome.forgot_password')}
              </Link>
            </FormHelperText>
          ) : null}

      </FormControl>
      {
        !isExistingUser
        && (
          <Typography fontSize="body2.fontSize" fontWeight={400} color={LmiTheme.light.systemBackground.tertiary} my={2}>
            {t('welcome.password_recommend')}
          </Typography>
        )
      }
    </Box>
  );
}

function ForgotPassword(props: any) {
  interface State {
    password: string;
    showPassword: boolean;
  }

  const [values, setValues] = React.useState<State>(
    {
      password: '',
      showPassword: false,
    },
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => props.updatePassword(event.target.value);

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const { t } = useTranslation('global');

  return (
    <Box sx={{ my: 8 }}>
      <Box mb={1}>
        <Typography component="span" fontSize="caption.fontSize" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
        <Typography component="span" fontSize="caption.fontSize" fontWeight={500}>
          {t('welcome.create_password')}
        </Typography>
      </Box>
      <FormControl variant="standard" fullWidth>
        <OutlinedInput
          id="outlined-adornment-password"
          name="newPassword"
          type={values.showPassword ? 'text' : 'password'}
          value={props.password ?? ''}
          onChange={handleChange}
          inputProps={{ minLength: 8, maxLength: 128 }}
          fullWidth
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )}
          placeholder={t('welcome.new_password')}
        />

      </FormControl>
    </Box>

  );
}

export function PhoneNumber({
  phone: initialPhone,
  setPhone,
}: any) {

  const [inputCountry, setInputCountry] = React.useState("1");

  const handleChange = (event: { target: { value: string } }) => {

    switch (event.target.value) {
      case '1':
        setInputCountry("1")
        break;
      case '2':
        setInputCountry("2")
        break;
      case '3':
        setInputCountry("3")
        break;
      default:
        break;
    }
  };

  const changePhone = (event: { target: { value: string } }) => {
    setPhone(event.target.value);
  };

  const { t } = useTranslation('global');

  return (
    <Box>
      <Box>
        <Typography component="span" fontSize="caption.fontSize" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
        <Typography component="span" fontSize="caption.fontSize" fontWeight={500}>
          {t('welcome.enter_phone_number')}
        </Typography>
      </Box>
      <Grid container spacing={2} my={0}>
        <Grid item xs={4}>
          <FormControl variant="standard" fullWidth>
            <Select
              name="code"
              value={inputCountry}
              defaultValue={inputCountry}
              onChange={handleChange}
              input={<OutlinedInput />}
            >
              <MenuItem value='1'>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start'
                }}>
                  <img src={FlagUsa} width="20px" alt="usa" style={{ marginRight: '15px' }} />
                  + 1
                </div>
              </MenuItem>
              <MenuItem value='3'>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start'
                }}>
                  <img src={FlagMx} width="20px" alt="mx" style={{ marginRight: '15px' }} />
                  + 52
                </div>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={8}>
          <FormControl variant="standard" fullWidth>
            <TextField
              hiddenLabel
              required
              fullWidth
              value={initialPhone}
              onChange={changePhone}
              placeholder={t('welcome.phone_number')}
              name="phone"
              id="phone"
              InputProps={{
                inputComponent: TextMaskPhone as any,
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Typography textAlign="center" fontSize="body2.fontSize" fontWeight={400} color={LmiTheme.light.systemBackground.tertiary} my={3}>
        {t('welcome.user_information_txt')}
      </Typography>
    </Box>
  );
}

const nextField = (key: string) => {
  const [, setValue] = React.useState({
    code1: '',
    code2: '',
    code3: '',
    code4: '',
    code5: '',
    code6: '',
  });

  return {
    handleChange: (e: any) => {
      const { maxLength, value, name } = e.target;

      const [, fieldIndex] = name.split(key);

      if (!isNaN(+value)) {
        if (value.length >= maxLength) {
          if (parseInt(fieldIndex, 10) < 6) {
            const nextSibling: any = document.querySelector(
              `input[name=${key}${parseInt(fieldIndex, 10) + 1}]`,
            );
            // If found, focus the next field
            if (nextSibling !== null) {
              nextSibling.focus();
            }
          }
        }

        setValue({
          ...value,
          [`code${fieldIndex}`]: value,
        });
      } else {
        e.target.value = "";
      }
    },
  };
};

export function Code() {
  const {
    ResendCode,
  }: any = React.useContext(authContext);

  const { t } = useTranslation('global');

  const { handleChange } = nextField('code');

  return (
    <Box>
      <Box pb={2}>
        <Typography component="span" fontSize="caption.fontSize" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
        <Typography component="span" fontSize="caption.fontSize" fontWeight={500}>
          {t('welcome.verification_code')}
        </Typography>
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <TextField
            hiddenLabel
            required
            inputProps={{
              maxLength: 1,
              min: 1,
              max: 1,
              style: { textAlign: 'center' },
              autoFocus: true,
              inputMode: `${isMobile ? 'numeric' : 'text'}`
            }}
            fullWidth
            id="code1"
            name="code1"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            hiddenLabel
            required
            fullWidth
            id="code2"
            name="code2"
            inputProps={{
              maxLength: 1,
              style: { textAlign: 'center' },
              inputMode: `${isMobile ? 'numeric' : 'text'}`
            }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            hiddenLabel
            required
            fullWidth
            id="code3"
            name="code3"
            inputProps={{
              maxLength: 1,
              style: { textAlign: 'center' },
              inputMode: `${isMobile ? 'numeric' : 'text'}`
            }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            hiddenLabel
            required
            fullWidth
            id="code4"
            name="code4"
            inputProps={{
              maxLength: 1,
              style: { textAlign: 'center' },
              inputMode: `${isMobile ? 'numeric' : 'text'}`
            }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            hiddenLabel
            required
            fullWidth
            id="code5"
            name="code5"
            inputProps={{
              maxLength: 1,
              style: { textAlign: 'center' },
              inputMode: `${isMobile ? 'numeric' : 'text'}`
              // autoFocus: { inp5 },
            }}
            onChange={handleChange}
          // onChange={(e) => {
          //   if (e.target.value && e.target.value?.trim() != '') { setFocus({ ...initialState, inp6: true }); }
          // }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            hiddenLabel
            required
            fullWidth
            id="code6"
            name="code6"
            inputProps={{
              maxLength: 1,
              style: { textAlign: 'center' },
              inputMode: `${isMobile ? 'numeric' : 'text'}`
            }}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <FormControl sx={{ width: '100%', my: 3 }}>
        <FormHelperText sx={{ textAlign: 'center' }}>
          <Link
            to="#"
            onClick={() => { ResendCode(); }}
            style={{
              color: LmiTheme.light.accent,
              textDecoration: 'none',
              fontSize: '14px',
              fontWeight: 700,
              margin: '0 16px',
            }}
          >
            {t('welcome.resend_code')}
          </Link>
        </FormHelperText>
      </FormControl>
    </Box>
  );
}

function ForgotPCode() {
  const {
    ForgotPassword,
    email,
  }: any = React.useContext(authContext);
  const { handleChange } = nextField('newCode');
  const { t } = useTranslation('global');
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <TextField
            hiddenLabel
            required
            fullWidth
            id="newCode1"
            name="newCode1"
            inputProps={{
              maxLength: 1,
              style: { textAlign: 'center' },
              autoFocus: true,
            }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            hiddenLabel
            required
            fullWidth
            id="newCode2"
            name="newCode2"
            inputProps={{
              maxLength: 1,
              style: { textAlign: 'center' },
            }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            hiddenLabel
            required
            fullWidth
            id="newCode3"
            name="newCode3"
            inputProps={{
              maxLength: 1,
              style: { textAlign: 'center' },
            }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            hiddenLabel
            required
            fullWidth
            id="newCode4"
            name="newCode4"
            inputProps={{
              maxLength: 1,
              style: { textAlign: 'center' },
            }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            hiddenLabel
            required
            fullWidth
            id="newCode5"
            name="newCode5"
            inputProps={{
              maxLength: 1,
              style: { textAlign: 'center' },
            }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            hiddenLabel
            required
            fullWidth
            id="newCode6"
            name="newCode6"
            inputProps={{
              maxLength: 1,
              style: { textAlign: 'center' },
            }}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <FormControl sx={{ width: '100%', my: 3 }}>
        <FormHelperText sx={{ textAlign: 'center' }}>
          <Link
            to="#"
            onClick={() => { ForgotPassword(email); }}
            style={{
              color: LmiTheme.light.accent,
              textDecoration: 'none',
              fontSize: '14px',
              fontWeight: 700,
              margin: '0 16px',
            }}
          >
            {t('welcome.resend_code')}
          </Link>
        </FormHelperText>
      </FormControl>
    </Box>
  );
}

function Profile() {
  const { t } = useTranslation('global');
  return (
    <Box>
      <FormControl fullWidth>
        <Typography component="label" variant="subtitle2" sx={{ fontWeight: '300' }}>
          {t('welcome.full_name')}
        </Typography>
        <TextField
          hiddenLabel
          fullWidth
          id="full-name"
          placeholder={t('welcome.enter_full_name')}
          name="full-name"
        />
      </FormControl>

      <FormControl sx={{ mt: 2 }} fullWidth>
        <Typography component="label" variant="subtitle2" sx={{ fontWeight: '300' }}>
          {t('welcome.describe_yourself')}
        </Typography>
        <TextField
          hiddenLabel
          fullWidth
          id="description"
          placeholder={t('welcome.describe_yourself')}
          name="description"
          multiline
          rows={3}
        />
      </FormControl>

    </Box>

  );
}

export default function FormLogin(value: any) {
  const triggerFunction = value.updateEmail;
  const updateEmailChild = async (e: string) => {
    triggerFunction(e);
  };

  const triggerFunctionForgot = value.forgotPassword;
  const goToForgotPassword = async (e: string) => {
    triggerFunctionForgot(e);
  };

  switch (value.step) {
    case 0:
      return (
        <Email email={value.email} parentMethod={updateEmailChild} />
      );
    case 1:
      return (
        <Password
          password={value.password}
          parentMethod={goToForgotPassword}
          userExists={value.userExists}
          updatePassword={value.updatePassword}
        />
      );
    case 2:
      return (
        <PhoneNumber
          phone={value.phone}
          setPhone={value.setPhone}
          country={value.country}
          setCountry={value.setCountry}
        />
      );
    case 3:
      return <Code />;
    case 4:
      return <ForgotPassword
        password={value.password}
        updatePassword={value.updatePassword}
      />;
    case 5:
      return <ForgotPCode />;
    case 6:
      return <Profile />;
    default:
      return (
        <h1>
          NOT INFO
          {' '}
          {value.step}
        </h1>
      );
  }
}

import { useTranslation } from 'react-i18next';
import { ReviewsItem } from '..';
import DialogCommon from '../DialogCommon/DialogCommon';
import { ReviewsDialogModels } from './ReviewsDialog.models';

function ReviewsDialog(props: ReviewsDialogModels) {
  const { t } = useTranslation('global');
  return (
    <>
      <DialogCommon
        kTitle={t('profile.reviews')}
        kMaxWidth="sm"
        kOpenDialogLists={props.kOpenDialog}
        kOnClose={props.kOnClose}
        kOnSecondary={props.kOnCancel}
        kOnPrimary={props.kOnSave}
        kContent={
          /* eslint-disable react/jsx-props-no-spreading */
          props.kReviewsList.map((d: any, i: number) => (
            i <= 3 && (
              <ReviewsItem
                kDivider={i < props.kReviewsList?.length - 1 ?? false}
                kLenderName={d.userNameWhoReviewedYou}
                kLenderPhoto={d.profilePicture}
                kReviewDate={d.creationDate}
                kReviewDescription={d.message}
                kReviewLike={d.idRateUserWhoReviewedYou}
              />
            )
          ))
        }
      />
    </>
  );
}

export default ReviewsDialog;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useJsApiLoader } from '@react-google-maps/api';
import { useMediaQuery, useTheme } from '@mui/material';
import { DialogCommon } from '..';
import { ChangeLocationModels } from './ChangeLocation.models';
import { MapData } from '../Map/Map.models';
import SuggestedMap from '../Map/MapPlaces';
import { LocationModel } from '../../../context/home/models/locationModel';

export default function ChangeLocation(props: ChangeLocationModels) {
  const [libraries]: any = useState(['places']);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
  });
  const [usrLat, setUserLat] = useState(props.kLat ? props.kLat : 0);
  const [usrLng, setUsrLng] = useState(props.kLon ? props.kLon : 0);
  const [shortAddress, setShortAddress] = useState(props.kAddress ? props.kAddress : '');
  const [longAddress, setLongAddress] = useState('');
  const [countryName, setCountryName] = useState('');

  const toggleMap = () => {
    props.kClose();
  };

  const saveGpsInLocalStorage = async () => {
    localStorage.setItem('lat', JSON.parse(usrLat.toString()));
    localStorage.setItem('lng', JSON.parse(usrLng.toString()));
    localStorage.setItem('address', shortAddress);
    localStorage.setItem('longAddress', longAddress);
    localStorage.setItem('countryName', countryName);

    const locationModel: LocationModel = {
      lat: parseFloat(usrLat.toString()),
      long: parseFloat(usrLng.toString()),
    };

    if (props.kChangeLocation) {
      await props.kChangeLocation(locationModel);
    }
  };

  const updateMarker = (mapData: MapData) => {
    setShortAddress(mapData.locality ? `${mapData.locality}, ${mapData.region}` : 'Los Ángeles, California');
    setCountryName(mapData.countryName ? `${mapData.countryName}` : 'United States');
    setLongAddress(mapData.address ? mapData.address : '');
    setUserLat(mapData.lat ? parseFloat(mapData.lat) : 34.0536909);
    setUsrLng(mapData.long ? parseFloat(mapData.long) : -118.242766);
  };

  useEffect(() => {
    const localLat = localStorage.getItem('lat');
    const localLng = localStorage.getItem('lng');
    if (props.open) {
      if (localLat === null && localLng === null) {
        if (usrLat === 0 && usrLng === 0) {
          navigator.geolocation.getCurrentPosition((position) => {
            setUserLat(position.coords.latitude);
            setUsrLng(position.coords.longitude);
          }, () => {
            setUserLat(34.0536909);
            setUsrLng(-118.242766);
          });
        }
      } else {
        setUserLat(localLat ? parseFloat(localLat) : 34.0536909);
        setUsrLng(localLng ? parseFloat(localLng) : -118.242766);
      }
    }
  }, [props.open]);

  const { t } = useTranslation('global');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>

      {isLoaded
        && (
          <DialogCommon
            kTitle={t('user_session.change_location')}
            kMaxWidth="md"
            kSlideTransition={isMobile}
            kDisableContentPadding={isMobile}
            kDisableContentPaddinginY={isMobile}
            kDisableBottomDivider
            kKeepBtnSize
            kAlignBtn="center"
            kBorderRadius={isMobile ? 0 : '10px'}
            kDisablePaddingInY={isMobile}
            kTopDivider
            kOpenDialogLists={props.open}
            kOnClose={() => { toggleMap(); }}
            kOnSecondary={() => { toggleMap(); }}
            kOnPrimary={async () => { saveGpsInLocalStorage().then(() => toggleMap()); }}
            kContent={
              (
                <SuggestedMap
                  state={{
                    address: longAddress,
                  }}
                  usrLat={usrLat}
                  usrLng={usrLng}
                  userLatLng={{ lat: usrLat, lng: usrLng }}
                  funUpdateInfo={updateMarker}
                  kDisableInsideInput
                />
              )
            }
          />
        )}
    </>
  );
}

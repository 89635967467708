// import { useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Box,

} from '@mui/material';
import { useTranslation } from 'react-i18next';
import circle from '../../../../../assets/why_lend_me_it/check_circle.svg';
import economical from '../../../../../assets/why_lend_me_it/economical.svg';
import safe from '../../../../../assets/why_lend_me_it/safe.svg';
import secure from '../../../../../assets/why_lend_me_it/secure.svg';

import WhyLendMeItStyle from './WhyLendMeIt.style';

export default function WhyLendMeIt() {
  const { t } = useTranslation('global');
  return (
    <>
      <Box sx={WhyLendMeItStyle}>
        <Typography
          py={1}
          component="p"
          className="lmi-whylendmeit"
        >
          {t('why_lmi.text')}
        </Typography>
        <Grid
          mt={3}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={4}
        >

          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardContent>
                <img src={economical} alt="Icon Economical" height="40" />
                <Typography component="h6" my={2}>
                  {t('why_lmi.economical')}
                </Typography>
                <Typography
                  className="lmi-box"
                  component="p"
                >
                  {t('why_lmi.economical_txt')}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardContent>
                <img src={safe} alt="Icon Economical" height="40" />
                <Typography component="h6" my={2}>
                  {t('why_lmi.safe')}
                </Typography>
                <Typography
                  className="lmi-box"
                  component="p"
                >
                  {t('why_lmi.safe_txt')}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardContent>
                <img src={secure} alt="Icon Economical" height="40" />
                <Typography component="h6" my={2}>
                  {t('why_lmi.secure')}
                </Typography>
                <Typography
                  className="lmi-box"
                  component="p"
                >
                  {t('why_lmi.secure_txt')}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardContent>
                <img src={circle} alt="Icon Economical" height="40" />
                <Typography component="h6" my={2}>
                  {t('why_lmi.guaranteed')}
                </Typography>
                <Typography
                  className="lmi-box"
                  component="p"
                >
                  {t('why_lmi.guaranteed_txt')}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

import { LmiTheme } from '..';

const ParallaxImageStyle = {
  overflow: 'hidden',
  width: '100%',
  outline: 'none',
  '.lmi-background': {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: '0',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    '.lmi-left-element': {
      backgroundColor: LmiTheme.light.accent,
      borderRadius: '20px',
      p: '40px',
      maxWidth: '450px',
      width: 'calc(100% - 150px)',
      minHeight: { xs: '180px', sm: '250px' },
    },
    '.lmi-right-element': {
      display: { xs: 'none', lg: 'block' },
    },
  },
};

export default ParallaxImageStyle;

import { useContext, useState } from 'react';
import { Edit } from '@mui/icons-material';
import {
  Box,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  DialogCommon,
  IconLinkButton,
  LmiTheme,
  SectionTitle,
} from '../../../page/BackOffice/ProfilePrivate/Components';
import { AboutMeModels } from './AboutMe.models';
import DialogAboutMeEdit from './DialogAboutMeEdit';
import publicProfileContext from '../../../context/publicProfile/publicProfileContext';

function AboutMe(props: AboutMeModels) {
  const pProfileContext = useContext(publicProfileContext);
  const {
    newAboutMe,
    newFullName,
    privateProfileInfo,
    updateProfileAboutMe,
    updateProfileInfo,
    updateUserNameAndDescription,
  }: any = pProfileContext;
  const [openAboutMeEdit, setOpenAboutMeEdit] = useState(false);
  const clickOpenAboutMeEdit = () => {
    updateProfileAboutMe(props.kDescription);
    setOpenAboutMeEdit(true);
  };
  const clickCloseAboutMeEdit = () => setOpenAboutMeEdit(false);
  const clickSaveAboutMeEdit = () => {
    if (newAboutMe.length > 0) {
      updateUserNameAndDescription({
        userName: newFullName != '' ? newFullName : privateProfileInfo.userName,
        description: newAboutMe,
      });
      updateProfileInfo({
        ...privateProfileInfo,
        dscription: newAboutMe,
      });
      setOpenAboutMeEdit(false);
    } else {
      setOpenAboutMeEdit(false);
    }
  };
  const { t } = useTranslation('global');

  const uiTheme = useTheme();
  const isMobile = useMediaQuery(uiTheme.breakpoints.down('md'));

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <SectionTitle kTitle={props.kTitle} kMarginTop={0} kMarginBottom={1} />
        {
          props.kEditing
          && (
            <IconLinkButton
              kOnClick={clickOpenAboutMeEdit}
              kStartIcon={<Edit />}
              kText={t('profile.edit')}
              kFontSize="body1.fontSize"
              kFontWeight="bold"
              kHideText={false}
            />
          )
        }
      </Stack>
      <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
        <Box m={2}>
          <Typography component="p" sx={{ overflowWrap: 'anywhere', fontSize: 'body2.fontSize', color: LmiTheme.light.systemBackground.tertiary }}>
            {
              props.kDescription ? props.kDescription : `${t('profile.no_description')}`
            }
          </Typography>
        </Box>
      </Paper>
      {
        props.kEditing
        && (
          <DialogCommon
            kTitle={t('profile.about_me')}
            kMaxWidth="sm"
            kMaxHeight="400px"
            kAlignBtn="center"
            kBorderRadius={isMobile ? 0 : '10px'}
            kFixedDialog={0}
            kFixedDialogPosition="fixed"
            kDisableBottomDivider
            kKeepBtnSize
            kTopDivider
            kSlideTransition={isMobile}
            kOpenDialogLists={openAboutMeEdit}
            kOnClose={clickCloseAboutMeEdit}
            kOnSecondary={clickCloseAboutMeEdit}
            kOnPrimary={clickSaveAboutMeEdit}
            kContent={
              (
                <DialogAboutMeEdit />
              )
            }
          />
        )
      }
    </>
  );
}

export default AboutMe;

// import { ItemAccessoriesModel } from '../../page/BackOffice/Listing/Components/AddItem/Components/ItemAccessories/ItemAccessories.models';
import {
  CLEAR,
  GET_BY_ID,
  PROFILE_INFO_CHANGED,
  UPDATE_ABOUT_ME,
  UPDATE_FULL_NAME,
  CHANGE_PASS_UPDATE,
  CHANGE_PASS_ERROR,
  CHANGE_PASS_SUCCESS,
  GET_PRIVATE,
  UPDATE_PENDING_PICTURE,
} from '../../types';

export default (state: any, action: any) => {
  switch (action.type) {
    case UPDATE_FULL_NAME: {
      return {
        ...state,
        newFullName: action.newFullName,
      };
    }
    case CHANGE_PASS_UPDATE: {
      return {
        ...state,
        changePasswordInfo: action.changePasswordInfo,
        closeModal: action.closeModal,
      };
    }
    case CHANGE_PASS_ERROR:
      return {
        ...state,
        message: action.payload,
        isBlocked: false,
      };
    case CHANGE_PASS_SUCCESS:
      return {
        ...state,
        closeModal: action.closeModal,
        changePasswordInfo: action.changePasswordInfo,
      };
    case UPDATE_ABOUT_ME: {
      return {
        ...state,
        newAboutMe: action.newAboutMe,
      };
    }
    case PROFILE_INFO_CHANGED:
    {
      return {
        ...state,
        privateProfileInfo: action.privateProfileInfo,
        newAboutMe: '',
      };
    }
    case CLEAR:
    {
      return {
        ...state,
        publicProfileInfo: {},
        newAboutMe: '',
        loading: true,
        publicUserItems: [],
        publicUserReviews: [],
        newFullName: '',
        publicGivenReviewList: null,
        paymentMethods: null,
      };
    }
    case GET_BY_ID:
    //   localStorage.setItem('publicProfileInfo', JSON.stringify(action.payload.publicProfileInfo));
    {
      return {
        ...state,
        loading: false,
        publicProfileInfo: action.payload.publicProfileInfo,
        // privateProfileInfo: action.payload.privateProfileInfo,
        publicUserItems: action.payload.publicUserItems,
        publicUserReviews: action.payload.publicUserReviews,
        newAboutMe: '',
        newFullName: action.payload.newFullName,
        publicGivenReviewList: action.payload.publicGivenReviewList,
        paymentMethods: action.payload.paymentMethods,
        changePasswordInfo: action.payload.changePasswordInfo,
      // precingType: action.payload.precingType,
      }; }
    case GET_PRIVATE:
    {
      return {
        ...state,
        loading: false,
        privateProfileInfo: action.payload.privateProfileInfo,
        userItems: action.payload.userItems,
        userReviews: action.payload.userReviews,
        newAboutMe: action.payload.newAboutMe,
        newFullName: action.payload.newFullName,
        givenReviewList: action.payload.givenReviewList,
        paymentMethods: action.payload.paymentMethods,
        changePasswordInfo: action.payload.changePasswordInfo,
        pendingInfo: action.payload.pendingInfo,
      };
    }
    // Upadtes the pending picture field in order to activate the verify id section in the profile page
    case UPDATE_PENDING_PICTURE:
      return {
        ...state,
        pendingInfo: {
          ...state.pendingInfo,
          profilePicture: 'not Pending',
        },
      };
    default:
      return state;
  }
};

import { useContext } from 'react';
import Compressor from 'compressorjs';
import AddEditCirclesContext from '../../../../../../../context/circles/addEditCirclesContext';

const useImageCircleBasicInformation = () => {
  const addEditCirclesContextLog = useContext(AddEditCirclesContext);

  const {
    circleInfoEdit,
    updateCircleInfo,
    updateDraftCircleInfo,
    validErrors,
    errors,
  }: any = addEditCirclesContextLog;

  const CONSTANTS = {
    QUALITY: 0.8, // 0.6 can also be used, but its not recommended to go below.
    CONVERT_SIZE: 1000,
    MAX_WIDTH: 4096,
    MAX_HEIGHT: 4096,
    MIN_WIDTH: 1080,
    MIN_HEIGHT: 1920,
  };

  const onImageDropped = (file: any) => {
    // eslint-disable-next-line no-new
    new Compressor(file, {
      convertTypes: [
        'image/png',
        'image/webp',
        'image/bmp',
        'image/tiff',
      ],
      quality: CONSTANTS.QUALITY,
      convertSize: CONSTANTS.CONVERT_SIZE,
      maxWidth: CONSTANTS.MAX_WIDTH,
      maxHeight: CONSTANTS.MAX_HEIGHT,
      minWidth: CONSTANTS.MIN_WIDTH,
      minHeight: CONSTANTS.MIN_HEIGHT,
      success: (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        const reader = new FileReader();
        reader.onloadend = () => {
          updateCircleInfo({
            ...circleInfoEdit,
            circlePicture: reader.result as string,
          });
          updateDraftCircleInfo({
            ...circleInfoEdit,
            circlePicture: reader.result as string,
          });
          validErrors({
            ...errors,
            circlePicture: false,
          });
        };
        reader.readAsDataURL(compressedResult);
      },
    });
  };

  return {
    onImageDropped,
  };
};

export default useImageCircleBasicInformation;

import {
  Box,
  Card,
  Divider,
  CardContent,
  ListItem,
  Stack,
  ListItemText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Edit } from '@mui/icons-material';
import {
  SectionTitle,
  IconLinkButton,
  CardAmex,
  CardCirrus,
  CardDiscover,
  CardJcb,
  CardMaestro,
  CardMastercard,
  CardPaypal,
  CardVisa,
} from '..';
import LmiTheme from '../../../../../themes/lmi-mui-theme/lmi-theme';
import { PanelSectionModels } from './PanelSection.models';

function PanelSection(props: PanelSectionModels) {
  const { t } = useTranslation('global');
  return (
    <>
      <Box my={3}>
        <Card elevation={0}>
          <>
            <SectionTitle
              kMarginLeft={3}
              kMarginRight={3}
              kMarginTop={3}
              kMarginBottom={3}
              kTitle={props.kTitle}
            />
            <Divider />
          </>
          <CardContent sx={{ pl: 3, pr: props.kSecondaryAction === true ? 0 : 1 }}>
            <ListItem
              disablePadding
              divider={props.kDivider}
              key={props.kId}
              secondaryAction={
                props.kSecondaryAction === true
                && (
                  <IconLinkButton
                    kOnClick={props.kOnClick}
                    kStartIcon={props.KIcon}
                    kText={t(`${props.kSecondaryActionText}`)}
                    kFontSize="body1.fontSize"
                    kFontWeight="bold"
                    kHideText={false}
                  />
                )
              }
            >
              {
                props.kListItemIcon !== undefined
                && (
                  <Box pr={1.6} sx={{ color: props.kListItemIconColor }}>{props.kListItemIcon}</Box>
                )
              }
              {
                props.kCardImageSrc !== undefined
                && (
                  (props.kCardImageSrc.toLowerCase() === 'amex' && <CardAmex width={props.kCardWidth} height={props.kCardHeight} style={{ marginRight: 16 }} />)
                  || (props.kCardImageSrc.toLowerCase() === 'cirrus' && <CardCirrus width={props.kCardWidth} height={props.kCardHeight} style={{ marginRight: 16 }} />)
                  || (props.kCardImageSrc.toLowerCase() === 'discover' && <CardDiscover width={props.kCardWidth} height={props.kCardHeight} style={{ marginRight: 16 }} />)
                  || (props.kCardImageSrc.toLowerCase() === 'jcb' && <CardJcb width={props.kCardWidth} height={props.kCardHeight} style={{ marginRight: 16 }} />)
                  || (props.kCardImageSrc.toLowerCase() === 'maestro' && <CardMaestro width={props.kCardWidth} height={props.kCardHeight} style={{ marginRight: 16 }} />)
                  || (props.kCardImageSrc.toLowerCase() === 'mastercard' && <CardMastercard width={props.kCardWidth} height={props.kCardHeight} style={{ marginRight: 16 }} />)
                  || (props.kCardImageSrc.toLowerCase() === 'paypal' && <CardPaypal width={props.kCardWidth} height={props.kCardHeight} style={{ marginRight: 16 }} />)
                  || (props.kCardImageSrc.toLowerCase() === 'visa' && <CardVisa width={props.kCardWidth} height={props.kCardHeight} style={{ marginRight: 16 }} />)
                )
              }
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
              >
                <ListItemText
                  onClick={props.kOnClick}
                  primaryTypographyProps={{ color: props.kPrimaryTextColor, fontSize: 'subtitle1.fontSize', fontWeight: 500 }}
                  secondaryTypographyProps={{
                    color: props.kSecondaryText === 'Expired' ? LmiTheme.light.dialog.danger : LmiTheme.light.systemBackground.tertiary,
                    fontSize: 'subtitle2.fontSize',
                  }}
                  primary={`${props.kCardImageSrc ? '···· ···· ···· ' : ''} ${props.kPrimaryText}`}
                  secondary={props.kSecondaryText}
                  sx={{ pr: props.kSecondaryAction === true ? 15 : 1, cursor: 'pointer' }}
                />
              </Stack>
            </ListItem>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

PanelSection.defaultProps = {
  kDivider: false,
  kId: 0,
  kTitle: 'kTitle',
  kSecondaryAction: true,
  kSecondaryActionText: 'global.change',
  kOnClick: undefined,
  kCardWidth: 56,
  kCardHeight: 56,
  KIcon: <Edit />,
};

export default PanelSection;

import {
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  CardContent, Stack, Button, Box, Paper, useMediaQuery, useTheme,
} from '@mui/material';
import { AddRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { DialogCommon, MainInformationTabPanel } from '..';
import { TabPanelPaymentMethodModels } from './TabPanelPaymentMethod.models';
import PaymentMethodSelect from '../../../../../components/Common/PaymentMethodSelect/PaymentMethodSelect';
import PaymentMethodAddDialog from '../../../../../components/Common/PaymentMethodSelect/PaymentMethodAddDialog';
import paymentMethodsContext from '../../../../../context/paymentMethods/paymentMethodsContext';
import payoutAccountContext from '../../../../../context/payoutAccount/payoutAccountContext';
import Heading from '../../../AddItem/Components/Heading/Heading';

import TabPanelPayoutAccount from '../TabPanelPayoutAccount/TabPanelPayoutAccount';

function TabPanelPaymentMethod(props: TabPanelPaymentMethodModels) {
  const pPaymentMethodsContext = useContext(paymentMethodsContext);
  const pPyaoutContext = useContext(payoutAccountContext);
  const [openDialogAddPaymentMethod, setOpenDialogAddPaymentMethod] = useState(false);
  const clickOpenDialogAddPaymentMethod = () => setOpenDialogAddPaymentMethod(true);
  const clickCloseDialogAddPaymentMethod = () => setOpenDialogAddPaymentMethod(false);
  const [areValuesValid, setValuesInvalid] = useState(true);
  const [flagAdd, setFlagAdd] = useState(false);
  const [showPayoutForm, setShowPayoutForm] = useState(props.kOpenPayoutModal ?? false);
  const clickCloseDialogAddPayoutMethod = () => setShowPayoutForm(!showPayoutForm);

  const {
    newPaymentMethodInfo,
    totalValidCard,
    getUserPaymentInfo,
    paymentMethodsList,
    updateDefaultCard,
    deleteCard,
    createPaymentMethod,
    arePaymentFieldsValidated,
    changedPaymentFields,
    checkNewPaymentValidations,
  }: any = pPaymentMethodsContext;

  const {
    getUserPayoutAccount,
    payoutAccountDetails,
  }: any = pPyaoutContext;
  const { accId } = payoutAccountDetails;

  const clickSavePayment = () => {
    setFlagAdd(!flagAdd);
    checkNewPaymentValidations(true);
  };

  useEffect(() => {
    if (arePaymentFieldsValidated) {
      if (newPaymentMethodInfo.expiryMonth.length >= 4) {
        const splitExpire = newPaymentMethodInfo.expiryMonth.split('/');
        const month = splitExpire[0];
        const year = splitExpire[1];
        const arrToSend = {
          ...newPaymentMethodInfo,
          expiryMonth: month,
          expiryYear: year,
        };
        createPaymentMethod(arrToSend);
        setOpenDialogAddPaymentMethod(false);
      }
    }
  }, [changedPaymentFields]);

  useEffect(() => {
    (async function fetchUserPaymentInfo() {
      await getUserPaymentInfo();
    }());
  }, []);

  useEffect(() => {
    (async function fetchUserPayoutAccount() {
      await getUserPayoutAccount();
    }());
  }, []);

  const { t } = useTranslation('global');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const modalScrollContainerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <MainInformationTabPanel
        kTabValue={props.kTabValue}
        kTabIndex={props.kTabIndex}
        kContent={
          (
            <>
              <CardContent sx={{ p: 3 }}>
                <Box mt={3}>
                  <Heading kTitle={t('profile.payment_method')} />
                </Box>
                <PaymentMethodSelect
                  kPaymentMethods={paymentMethodsList}
                  kUpdateDefault={updateDefaultCard}
                  kDeletePayment={deleteCard}
                  kTotalValidCards={totalValidCard}
                  kOnClick={clickOpenDialogAddPaymentMethod}
                />

                <Box mt={3}>
                  <Heading kTitle={t('profile.payout_account')} />
                </Box>
                {accId === '' ? (
                  <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
                    <Stack mb={1} my={3}>
                      <Button
                        disableElevation
                        size="large"
                        variant="outlined"
                        onClick={clickCloseDialogAddPayoutMethod}
                        sx={{
                          mx: 'auto',
                          textTransform: 'none',
                          borderRadius: '10px',
                        }}
                      >
                        <AddRounded />
                        {t('payout_info.add_payout_method')}
                      </Button>
                    </Stack>
                  </Paper>

                ) : <TabPanelPayoutAccount clickCloseDialogAddPayoutMethod={clickCloseDialogAddPayoutMethod} />}

              </CardContent>
              <DialogCommon
                kTitle={t('payment_method.add_payment_method')}
                kMaxWidth="sm"
                kMaxHeight="630px"
                kAlignBtn="center"
                kDisableContentPadding
                kBorderRadius={isMobile ? 0 : '10px'}
                kFixedDialog={0}
                kFixedDialogPosition="fixed"
                kDisableBottomDivider
                kKeepBtnSize
                kTopDivider
                kSlideTransition={isMobile}
                kOpenDialogLists={openDialogAddPaymentMethod}
                kOnClose={clickCloseDialogAddPaymentMethod}
                kOnSecondary={clickCloseDialogAddPaymentMethod}
                kOnPrimary={clickSavePayment}
                kDisableActions={!areValuesValid}
                kContent={
                  (
                    <PaymentMethodAddDialog KSetValid={setValuesInvalid} />
                  )
                }
              />
              {accId === '' && (
                <DialogCommon
                  kTitle={t('payout_info.add_payout_method')}
                  kMaxWidth="sm"
                  kBorderRadius={isMobile ? 0 : '10px'}
                  kSlideTransition={isMobile}
                  kDisableContentPadding
                  kOpenDialogLists={showPayoutForm}
                  kOnClose={clickCloseDialogAddPayoutMethod}
                  kContent={
                    (
                      <div ref={modalScrollContainerRef} style={{ overflowY: 'auto' }}>
                        <TabPanelPayoutAccount clickCloseDialogAddPayoutMethod={clickCloseDialogAddPayoutMethod} />
                      </div>

                    )
                  }
                />
              )}
            </>
          )
        }
      />
    </>
  );
}

export default TabPanelPaymentMethod;

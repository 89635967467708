/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable react/jsx-props-no-spreading */
import { useContext } from 'react';
import {
  Grid, ImageListItem, Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import homeContext from '../../../../../context/home/homeContext';
import exploreContext from '../../../../../context/explore/exploreContext';

import hobbies from '../../../../../assets/find_or_list_anything/hobbies.webp';
// import passion from '../../../../../assets/find_or_list_anything/passion.webp';
import explore from '../../../../../assets/find_or_list_anything/explore.webp';
import workHome from '../../../../../assets/find_or_list_anything/work-home.webp';
// import camp from '../../../../../assets/find_or_list_anything/camp.webp';
import yourself from '../../../../../assets/find_or_list_anything/yourself.webp';

import FindOrListAnythingStyle from './FindOrListAnything.style';

export default function FindOrListAnything() {
  const history = useHistory();
  const {
    addCategories,
  }: any = useContext(homeContext);
  const {
    addCategoriesApplied,
    clearData,
  }: any = useContext(exploreContext);

  const goToSearch = ({ id }: any) => {
    const categories = [];

    switch (id) {
      case 3:
        categories.push(
          { id: 31, title: 'Tools' },
        );

        break;
      case 4:
        categories.push(
          { id: 32, title: 'Sporting' },
          { id: 34, title: 'Outdoor' },
          { id: 40, title: 'Automotive' },
          { id: 33, title: 'Photo/Video' },
        );
        break;
      case 5:
        categories.push(
          { id: 31, title: 'Tools' },
          { id: 33, title: 'Photo/Video' },
          { id: 37, title: 'Electronics' },
          { id: 38, title: 'Appliances' },
        );
        break;
      case 6:
        categories.push(
          { id: 32, title: 'Sporting' },
          { id: 33, title: 'Photo/Video' },
          { id: 34, title: 'Outdoor' },
          { id: 35, title: 'Instruments' },
          { id: 43, title: 'Audio' },
        );
        break;

      default:
        break;
    }

    clearData();
    addCategories(categories);
    addCategoriesApplied(categories);
    history.push('/search',
      { state: { categories } });
  };

  const { t } = useTranslation('global');

  return (
    <Grid container spacing={2} sx={FindOrListAnythingStyle}>
      {itemData.map((item) => (
        <Grid item key={item.title} xs={6}>
          <ImageListItem onClick={() => goToSearch(item)}>
            <img
              src={item.img}
              srcSet={item.img}
              alt={item.title}
              loading="lazy"
            />
            <Typography mt={1} component="h6">
              {t(`find_list.${item.title}`)}
            </Typography>
          </ImageListItem>
        </Grid>
      ))}
    </Grid>
  );
}

const itemData = [
  {
    id: 6,
    order: 1,
    img: hobbies,
    title: 'discover',
  },
  {
    id: 4,
    order: 1,
    img: explore,
    title: 'explore_world',
  },
  {
    id: 5,
    order: 3,
    img: workHome,
    title: 'work_home',
  },
  {
    id: 3,
    order: 5,
    img: yourself,
    title: 'do_it',
  },
];

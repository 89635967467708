import { Box, useMediaQuery, useTheme } from '@mui/material';
import { MapComponent } from '..';
import LmiTheme from '../../../../../themes/lmi-mui-theme/lmi-theme';

function DialogPickupLocation({ lat, long, exactAddress }: any) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Box sx={{
        m: isMobile ? 0 : 1,
        backgroundColor: LmiTheme.light.systemBackground.base,
        height: isMobile ? '100%' : '50vh',
        minHeight: '260px',
        borderRadius: isMobile ? 0 : 3,
        overflow: 'hidden',
      }}
      >
        <MapComponent
          state={{
            height: 500,
            lat,
            long,
          }}
          exactAddress={exactAddress}
          kDraggable={false}
        />
      </Box>
    </>
  );
}

export default DialogPickupLocation;

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Fab, Box } from '@mui/material';
import { ReactComponent as IconFacebook } from '../../../../../assets/icons/facebook_icon.svg';
import { ReactComponent as IconGoogle } from '../../../../../assets/icons/google_icon.svg';
import { ReactComponent as IconApple } from '../../../../../assets/icons/apple_icon.svg';
import useStyles from './SocialButton.style';
import { LmiTheme } from '../../../../../components/Common';

export default function SocialLogin() {
  const { t } = useTranslation('global');
  const classes = useStyles();

  return (
    <Box m="0 auto">
      <Typography component="p" fontSize="body2.fontSize" fontWeight={400} color={LmiTheme.light.systemBackground.tertiary} sx={{ textAlign: 'center', my: 5 }}>
        {t('sign_up.or')}
      </Typography>
      <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
        <Grid item>
          <Fab
            className={`${classes.base} ${classes.facebook}`}
            id="facebook-button"
            onClick={(e) => {
              e.preventDefault();
              Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook });
            }}
          >
            <IconFacebook style={{ height: '30px' }} />
          </Fab>

        </Grid>
        <Grid item>
          <Fab
            className={`${classes.base} ${classes.google}`}
            id="google-button"
            onClick={(e) => {
              e.preventDefault();
              Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
            }}
          >
            <IconGoogle style={{ height: '30px' }} />
          </Fab>
        </Grid>
        <Grid item>
          <Fab
            className={`${classes.base} ${classes.apple}`}
            id="apple-button"
            onClick={(e) => {
              e.preventDefault();
              Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Apple });
            }}
          >
            <IconApple style={{ height: '30px' }} />
          </Fab>
        </Grid>
      </Grid>
    </Box>
  );
}

import { ThumbUpRounded, VerifiedRounded } from '@mui/icons-material';
import {
  Paper,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Box,
  Typography,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import LmiTheme from '../../../../../themes/lmi-mui-theme/lmi-theme';
import SectionTitle from '../../../../../components/Common/SectionTitle/SectionTitle';
import { AboutLenderModels } from './AboutLender.models';
import publicProfileContext from '../../../../../context/publicProfile/publicProfileContext';

function AboutLender(props: AboutLenderModels) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const history = useHistory();

  const {
    clearPublicProfile,
  }: any = useContext(publicProfileContext);

  const lenderProfile = () => {
    (async function navigateToProfile() {
      await clearPublicProfile();
      history.push('/public-profile', {
        bookItem: props.kBookItem,
        publicProfileId: props.kIdLender,
      });
    }());
  };

  const { t } = useTranslation('global');

  return (
    <>
      <SectionTitle kTitle={props.kTitle} />
      <Paper
        variant="outlined"
        onClick={() => lenderProfile()}
        sx={{ cursor: 'pointer', borderRadius: '10px' }}
      >
        <ListItem alignItems="flex-start">
          <Stack flexDirection="row" justifyContent="center" alignItems="center" mb="8px">
            <ListItemAvatar>
              <Avatar alt={props.kName} src={props.kPhoto} sx={{ width: isDesktop ? 100 : 74, height: isDesktop ? 100 : 74 }} />
            </ListItemAvatar>
            <ListItemText sx={{ marginLeft: '16px' }}>
              <>
                <Box>
                  <Typography sx={{ fontSize: 'subtititle1.fontSize', fontWeight: '700' }}>
                    {props.kName}
                  </Typography>
                </Box>
                <Stack
                  pt={0}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <ThumbUpRounded sx={{ color: LmiTheme.light.dialog.success, fontSize: 20 }} />
                  <> </>
                  <Typography sx={{ fontSize: 'body2.fontSize', color: LmiTheme.light.systemBackground.tertiary }}>
                    {/* {props.kRecommended}  */}
                    {/* {props?.kRecommended?.toFixed(2) ?? 100} */}
                    {parseFloat(props?.kRecommended ?? '0')?.toFixed(2) ?? 100}
                    {t('profile.recommended')}
                  </Typography>
                </Stack>
                {(props.kBookItem.lenderVerified || props.kBookItem.lenderVerifiedShufti || props.kBookItem.lenderVerifiedStripe) && (
                  <Stack
                    pt={0}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    <VerifiedRounded sx={{ color: LmiTheme.light.dialog.success, fontSize: 20 }} />
                    <Typography sx={{ fontSize: 'body2.fontSize', color: LmiTheme.light.systemBackground.tertiary }}>
                      {props.kIdVerified}
                    </Typography>
                  </Stack>
                )}
                {
                  isDesktop
                    ? (
                      <Box pt={1}>
                        <Typography sx={{ fontSize: 'body2.fontSize', color: LmiTheme.light.systemBackground.primary }}>
                          {props.kDescription}
                        </Typography>
                      </Box>
                    )
                    : undefined
                }
              </>
            </ListItemText>
          </Stack>

        </ListItem>
      </Paper>
    </>
  );
}

AboutLender.defaultProps = {
  kTitle: 'kTitle',
  kPhoto: 'kPhoto',
  kName: 'kName',
  kRecommended: 'kRecommended',
  kIdVerified: 'kIdVerified',
  kDescription: 'kDescription',
};

export default AboutLender;

/* eslint-disable no-nested-ternary */
import {
  Stack, Typography, Chip, Avatar,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { LmiTheme } from '../../../../../components/Common';
import { ItemDetailNameModels } from './ItemDetaiModels';
import LmiColorPalette from '../../../../../themes/lmi-mui-theme/lmi-color-palette';

export default function ItemDetailName(props: ItemDetailNameModels) {
  const { t } = useTranslation('global');
  return (
    <>
      <Typography
        fontWeight="bold"
        fontSize={32}
        mt={props.kMarginTop}
        ml={0}
        mb={props.kMarginBottom}
        sx={{
          textTransform: 'capitalize',
        }}
      >
        {props.kNameItem}
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Chip
            label={props.kListingStatus}
            variant="filled"
            sx={{
              fontWeight: 'bold',
              height: '25px',
              borderRadius: '7px',
              color:
                (props.kListingStatus === 'Blocked' || props.kListingStatus === 'Bloqueado')
                  ? LmiColorPalette.redScheme[900]
                  : props.kListingStatus !== 'Unavailable'
                    ? LmiColorPalette.greenScheme[900]
                    : LmiTheme.light.systemBackground.primary,
              background:
                (props.kListingStatus === 'Blocked' || props.kListingStatus === 'Bloqueado')
                  ? LmiColorPalette.redScheme[50]
                  : props.kListingStatus !== 'Unavailable'
                    ? LmiColorPalette.greenScheme[50]
                    : LmiTheme.light.systemBackground.solid,
            }}
          />

          <Chip
            icon={(
              <Avatar sx={{
                width: 25,
                height: 25,
                bgcolor: (props.kForSale)
                  ? LmiTheme.light.dialog.success
                  : LmiTheme.light.systemBackground.tertiary,
              }}
              >
                <AttachMoneyIcon sx={{ fontSize: 15, color: 'white' }} />
              </Avatar>
            )}
            label={(props.kForSale) ? `${t('item_detail.for_sale')}` : `${t('item_detail.not_for_sale')}`}
            sx={{
              fontWeight: 'bold',
              background: 'white',
              display: !(props.kListingStatus === 'Blocked' || props.kListingStatus === 'Bloqueado') ? 'inherit' : 'none',
            }}
          />
        </Stack>
        <Chip
          icon={(
            <VisibilityIcon />
          )}
          label={(props.kTotalViews) ? `${props.kTotalViews} ${t('item_detail.views')}` : `0 ${t('item_detail.views')}`}
          sx={{
            fontWeight: 'bold',
            background: 'white',
          }}
        />
      </Stack>
    </>
  );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Box,
  Link,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LmiTheme from '../../../themes/lmi-mui-theme/lmi-theme';
import { EllipsisTextModels } from './EllipsisText.models';

export default function EllipsisText(props: EllipsisTextModels) {
  const [fullText, setFullText] = React.useState(false);
  const [maxText, setMaxText] = React.useState(0);

  const { t } = useTranslation('global');

  useEffect(() => {
    if (props.kMaxLength) {
      setMaxText(props.kMaxLength);
      setFullText(false);
    } else {
      setFullText(true);
    }
  }, [props.kMaxLength]);

  return (
    <Box sx={{ position: 'relative', padding: '16px' }}>
      <Typography
        fontSize="16px"
        color={LmiTheme.light.systemBackground.tertiary}
        sx={{ overflow: 'hidden' }}
      >
        <>
          {fullText ? props.kText : props.kText?.substring(0, maxText)}
          {props.kText?.length > maxText && props.kIsVisibleBtnMore
            ? (
              <>
                {fullText ? '' : '...'}
                <Link
                  component="button"
                  variant="body2"
                  underline="none"
                  sx={{ pl: 1 }}
                  onClick={() => setFullText((prev) => !prev)}
                >
                  {fullText ? t('ellipsis_text.less') : t('ellipsis_text.more')}
                </Link>
              </>
            )
            : <></>}
        </>
      </Typography>
    </Box>
  );
}

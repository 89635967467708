import {
  Box, Paper, List, ListItem, ListItemIcon, ListItemText,
} from '@mui/material'; import CircleIcon from '@mui/icons-material/Circle';
import { useTranslation } from 'react-i18next';
import ActivityInstructionsStyle from './ActivityInstructions.style';
import { SectionTitle } from '../../../../../../components/Common';

export default function ActivityInstructions({ reqs }: any) {
  const { t } = useTranslation('global');
  return (
    <Box sx={ActivityInstructionsStyle}>
      <SectionTitle
        kMarginTop={2}
        kTitle={t('add_item_page.requirements')}
        kFontSize={18}
      />
      <Paper className="lmiw-activity-instructions" variant="outlined" elevation={0}>
        <List>
          { reqs.map((req: any) => (

            <ListItem className="lmiw-activity-instructions-item">
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>
              <ListItemText primary={req?.termDetail} />
            </ListItem>
          ))}
          {/* <ListItem className="lmiw-activity-instructions-item">
            <ListItemIcon>
              <CircleIcon />
            </ListItemIcon>
            <ListItemText primary="Please take care of the charger cord since is delicate." />
          </ListItem>
          <ListItem className="lmiw-activity-instructions-item">
            <ListItemIcon>
              <CircleIcon />
            </ListItemIcon>
            <ListItemText primary="When the drill is fully charge, please unplug charger, don’t leave charging overnight." />
          </ListItem> */}
        </List>
      </Paper>
    </Box>
  );
}

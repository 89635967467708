import { useContext, useEffect, useReducer } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { ClsModGetUserLndItems } from '@lendmeit/api_backend_lmi/dist/models';
import MyListingContext from './myListingContext';
import authContext from '../authentication/authContext';

import MyListingReducer from './myListingReducer';
import {
  GET_ALL,
  GET_BY_ID,
  DETAILS,
  PRECING_TYPE,
  PRECING_CHANGE,
  PRECING_HOURLY,
  CLEAR,
  GET_DYNAMIC_LINK,
} from '../../types';
import ApiLMI from '../../services/apilmi';
import BookItemContext from '../bookItem/bookContext';
import AddItemContext from '../addEditItem/addEditItemContext';
import { AddItemInfoModel, ItemImageModel } from '../../page/BackOffice/Listing/Components/AddItem/AddItem.models';
import { getItemAccesories, getItemBlockedIndDates } from '../../data/listing';

const initialStte = {
  items: null,
  images: null,
  condictions: null,
  accesories: null,
  loading: false,
  item: null,
  itemBlockedIndDates: null,
  totalItems: null,
  precingType: PRECING_HOURLY,
  dynamicLink: null,
};

const MyListingState = ({ children }: any) => {
  const { token, user }: any = useContext(authContext);
  const [state, dispatch] = useReducer(MyListingReducer, initialStte);

  const {
    getBookImages,
    userGetUserTermsAndConditions,
  }: any = useContext(BookItemContext);

  const { setItemInfo }: any = useContext(AddItemContext);

  const getExtraInformation = async () => {
    if (!token || !user) {
      return;
    }
    try {
      if (state.item) {
        const { idCatLndItem } = state.item;
        if (idCatLndItem !== 0) {
          const images = await getBookImages(idCatLndItem);
          const condictions = await userGetUserTermsAndConditions(idCatLndItem);
          const accesories = await getItemAccesories(idCatLndItem);
          const itemBlockedIndDates = await getItemBlockedIndDates(idCatLndItem);

          dispatch({
            type: GET_BY_ID,
            payload: {
              itemBlockedIndDates,
              images,
              condictions,
              accesories,
            },
          });
        }
      }
    } catch (err: any) {
      toast.error(t('global.server_error'));
    }
  };

  const getMyItems = async () => {
    if (!token || !user) {
      return;
    }

    const _api = ApiLMI();
    try {
      const modelRequest: ClsModGetUserLndItems = {
        idCatLndItem: 0,
        idCatLndLender: 0,
        toc: 'qwerty',
      };
      const { data } = await _api.itemGetUserLndItems(modelRequest);

      // Get array item with status sold
      const itemsStatusSold = await _api.userGetUserLndItemsNewFlow(modelRequest);
      const objItemsStatusSold = JSON.parse(itemsStatusSold.data.toString());
      const itemsSold = objItemsStatusSold.Response;

      if (typeof data === 'string') {
        const obj = JSON.parse(data.toString());
        const items = obj.Response;
        // Add status sold first array
        const myItems = items.map((arrayItems: any) => {
          const newItemsSold = itemsSold.find((arrayItemsSold: any) => arrayItems.idCatLndItem === arrayItemsSold.idCatLndItem);
          return { ...arrayItems, ...newItemsSold };
        });

        if (myItems) {
          if (obj.Error == '') {
            dispatch({
              type: GET_ALL,
              payload: myItems,
            });
          } else {
            dispatch({
              type: GET_ALL,
              payload: [],
            });
          }
        }
      } else {
        toast.error(t('global.server_error'));
      }
    } catch (err: any) {
      toast.error(t('global.server_error'));
    }
  };

  const getDynamicLink = async () => {
    const _api = ApiLMI();
    try {
      const modelRequest: ClsModGetUserLndItems = {
        idCatLndItem: 0,
        idCatLndLender: 0,
        toc: 'qwerty',
      };
      const { data } = await _api.userGetUserLendPageDynamicLink(modelRequest);

      if (typeof data === 'string') {
        const obj = JSON.parse(data.toString());
        const deepLink = obj.Response;

        if (deepLink[0]?.lendPageDynamicLink != null) {
          if (obj.Error == '') {
            dispatch({
              type: GET_DYNAMIC_LINK,
              payload: deepLink[0].lendPageDynamicLink,
            });
          } else {
            dispatch({
              type: GET_DYNAMIC_LINK,
              payload: '',
            });
          }
        } else {
          dispatch({
            type: GET_DYNAMIC_LINK,
            payload: '',
          });
        }
      }
    } catch (err: any) {
      throw new Error(t('global.server_error'));
    }
  };

  const details = async (item: AddItemInfoModel, images: Array<ItemImageModel>) => {
    if (!token || !user) {
      return;
    }
    try {
      if (item) {
        if (item.circlesOnly && item.active) {
          item.itemStatus = 2;
        } if (item.exploreOnly && item.active) {
          item.itemStatus = 3;
        } if (item.circlesAndExplore && item.active) {
          item.itemStatus = 1;
        } if (!item.active) {
          item.itemStatus = 0;
        }

        setItemInfo(item, images);
        dispatch({
          type: DETAILS,
          payload: item,
          itemImages: images,
          totalItems: state.items?.length ?? 0,
        });
      }
    } catch (error) {
      toast.error(t('global.server_error_details'));
    }
  };

  const changePrcingType = async (type: PRECING_TYPE) => {
    try {
      dispatch({
        type: PRECING_CHANGE,
        payload: type,
      });
    } catch (e: any) {
      toast.error(e ?? t('global.server_error_price'));
    }
  };

  const initialState = async () => {
    try {
      dispatch({
        type: CLEAR,
        payload: initialStte,
      });
      // eslint-disable-next-line no-empty
    } catch (_) {
    }
  };

  useEffect(() => {
    if (!token || !user) {
      initialState();
    }
  }, [token, user]);

  const { t } = useTranslation('global');

  return (
    <MyListingContext.Provider
      key={token}
      value={
        {
          ...state,
          item: state.item,
          items: state.items,
          images: state.images,
          condictions: state.condictions,
          itemBlockedIndDates: state.itemBlockedIndDates,
          accesories: state.accesories,
          precingType: state.precingType,
          totalItems: state.totalItems,
          dynamicLink: state.dynamicLink,
          loading: state.loading,
          initialState,
          getMyItems,
          details,
          getExtraInformation,
          changePrcingType,
          getDynamicLink,
        }
      }
    >
      {children}
    </MyListingContext.Provider>
  );
};

export default MyListingState;

import { CloseRounded } from '@mui/icons-material';
import {
  Box,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DialogCommonHeaderModels } from './DialogHeader.models';

function DialogCommonHeader(props: DialogCommonHeaderModels) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <DialogTitle sx={{ px: isMobile ? 1 : 3, py: 0.5 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          // pl={1}
        >
          {
          props.kCenterTitle ? (
            <Box sx={{ height: '51px', width: '51px' }} />
          ) : null
          }
          {props.kHeaderComponent
            ? props.kHeaderComponent
            : (
              <Stack direction="column">
                <Typography
                  sx={{ textTransform: 'none' }}
                  fontWeight="bold"
                  fontSize="h6.fontSize"
                >
                  {props.kTitle}
                </Typography>
                <Typography
                  sx={{ textTransform: 'none' }}
                  fontWeight="300"
                  fontSize="14px"
                >
                  {props.kSubTitle}
                </Typography>
              </Stack>
            )}

          <IconButton color="primary" onClick={props.kOnClose}>
            <CloseRounded fontSize="large" />
          </IconButton>
        </Stack>
      </DialogTitle>
    </>
  );
}

export default DialogCommonHeader;

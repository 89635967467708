// import { ItemAccessoriesModel } from '../../page/BackOffice/Listing/Components/AddItem/Components/ItemAccessories/ItemAccessories.models';
import {
  GET_BY_ID,
  INITIALIZE_SHUFTI_PRO,
  CHANGE_PASS_UPDATE,
} from '../../types';

export default (state: any, action: any) => {
  switch (action.type) {
    case INITIALIZE_SHUFTI_PRO: {
      return {
        ...state,
        shuftiUrl: action.verification_url,
        referenceId: action.reference_id,
      };
    }
    case CHANGE_PASS_UPDATE: {
      return {
        ...state,
        changePasswordInfo: action.changePasswordInfo,
        closeModal: action.closeModal,
      };
    }
    case GET_BY_ID:
    //   localStorage.setItem('publicProfileInfo', JSON.stringify(action.payload.publicProfileInfo));
    { return {
      ...state,
      loading: false,
      publicProfileInfo: action.payload.publicProfileInfo,
      userItems: action.payload.userItems,
      userReviews: action.payload.userReviews,
      newAboutMe: '',
      newFullName: action.payload.newFullName,
      givenReviewList: action.payload.givenReviewList,
      paymentMethods: action.payload.paymentMethods,
      changePasswordInfo: action.payload.changePasswordInfo,
      // precingType: action.payload.precingType,
    }; }
    default:
      return state;
  }
};

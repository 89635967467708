import { LmiTheme } from '../../../../../../components/Common';

const ActivityCancelStyle = {
  width: '100%',
  '.lmiw-Activity-cancel-title': {
    color: LmiTheme.light.systemBackground.tertiary,
    fontSize: 18,
  },
  p: {
    fontWeight: 500,
    fontSize: 16,
  },
};

export default ActivityCancelStyle;

import {
  Box,
  Button,
  CardContent,
  CardMedia,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  SpecialOrganizationsImage,
  LmiTheme,
  MainInformationTabPanel,
} from '..';
import { TabPanelSpecialOrganizationsModels } from './TabPanelSpecialOrganizations.models';

function TabPanelSpecialOrganizations(props: TabPanelSpecialOrganizationsModels) {
  const { t } = useTranslation('global');
  return (
    <>
      <MainInformationTabPanel
        kTabValue={props.kTabValue}
        kTabIndex={props.kTabIndex}
        kContent={
          (
            <>
              <CardContent sx={{ p: 3 }}>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  py={3}
                >
                  <CardMedia
                    component="img"
                    alt="My Promo Code"
                    image={SpecialOrganizationsImage}
                    sx={{ width: '40%' }}
                  />
                  <Box
                    sx={{
                      fontSize: 'subtitle2.fontSize',
                      fontWeight: 500,
                      color: LmiTheme.light.systemBackground.tertiary,
                      maxWidth: '90%',
                      textAlign: 'center',
                      pb: 2,
                    }}
                  >
                    {t('special_org.txt')}
                  </Box>
                  <Box
                    sx={{
                      fontSize: 'subtitle2.fontSize',
                      fontWeight: 500,
                      color: LmiTheme.light.systemBackground.tertiary,
                      maxWidth: '90%',
                      textAlign: 'center',
                      pb: 2,
                    }}
                  >
                    {t('special_org.txt2')}
                  </Box>
                  <Button
                    onClick={undefined}
                    disableElevation
                    variant="outlined"
                    size="large"
                    sx={{
                      px: 3,
                      fontSize: 'subtitle1.fontSize',
                      textTransform: 'none',
                      fontWeight: 'bold',
                    }}
                  >
                    {t('special_org.send_request')}
                  </Button>
                </Stack>
              </CardContent>
            </>
          )
        }
      />
    </>
  );
}

export default TabPanelSpecialOrganizations;

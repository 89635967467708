import { useState } from 'react';
import {
  Box, Button, Collapse, Container, Fab, Stack, Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { useHistory } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NavbarAncillaryStyle from './NavBarAncillary.style';

export default function NavbarAncillary() {
  const history = useHistory();
  const [menuMobile, setShowMenuMobile] = useState(false);
  const handleClick = () => {
    setShowMenuMobile(!menuMobile);
  };
  const { t } = useTranslation('global');

  return (
    <>
      <Box sx={NavbarAncillaryStyle}>
        <Container maxWidth="lg" disableGutters component="div">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Button className="lmi-btn-back" variant="text" onClick={() => { history.goBack(); }}>
              <ArrowLeftIcon />
              {t('about_page.back_homepage')}
            </Button>
            <Stack
              className="NavigationMenu"
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
              sx={{ display: { md: 'flex', xs: 'none' } }}
            >
              <Button variant="text" href="/about">{t('about_page.about')}</Button>
              <Button variant="text" href="/guarantee">{t('about_page.our_guarantee')}</Button>
              <Button variant="text" href="/faq">FAQ</Button>
              <Button variant="text" href="/privacy-policy">{t('about_page.privacy_policy')}</Button>
              <Button variant="text" href="/terms">{t('about_page.terms')}</Button>
            </Stack>
            <Fab onClick={handleClick} sx={{ display: { md: 'none', xs: 'flex' } }}>
              <MoreVertIcon />
            </Fab>
          </Stack>
          <Collapse in={menuMobile} className="NavigationMenu">
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={0}
              sx={{ display: { md: 'none', xs: 'flex' } }}
            >
              <Button variant="text" href="/about">{t('about_page.about')}</Button>
              <Button variant="text" href="/guarantee">{t('about_page.our_guarantee')}</Button>
              <Button variant="text" href="/faq">FAQ</Button>
              <Button variant="text" href="/privacy-policy">{t('about_page.privacy_policy')}</Button>
              <Button variant="text" href="/terms">{t('about_page.terms')}</Button>
            </Stack>
          </Collapse>
        </Container>
      </Box>
      {menuMobile ? <Divider sx={{ display: { md: 'none', xs: 'block' } }} /> : ''}
    </>
  );
}

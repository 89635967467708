import { useContext, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { Divider } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import LmiTheme from '../../../../../../../themes/lmi-mui-theme/lmi-theme';
import AddEditCirclesContext from '../../../../../../../context/circles/addEditCirclesContext';

export default function ListSpecialConditions(props: any) {
  const triggerFunction = props.editCondition;
  const addEditCirclesContextLog = useContext(AddEditCirclesContext);
  const {
    conditionsList,
  }: any = addEditCirclesContextLog;
  const [checked, setChecked] = useState([0]);

  const editCondition = (value: any) => () => {
    triggerFunction(value);
  };
  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <List sx={{ width: '100%' }}>
      {conditionsList?.map((value: any, index: any) => (

        <ListItem
          // eslint-disable-next-line react/no-array-index-key
          divider
          key={`${value}-key`}
          secondaryAction={(
            <IconButton onClick={editCondition(index)}>
              <EditIcon fontSize="medium" sx={{ color: LmiTheme.light.accent }} />
            </IconButton>
          )}
          disablePadding
        >
          <ListItemButton
            role={undefined}
            onClick={handleToggle(value)}
            sx={{ mb: '8px', mt: '8px' }}
            dense
          >
            <FiberManualRecordIcon color="primary" style={{ fontSize: '8px' }} />
            <ListItemText
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                maxWidth: { xs: 'calc(100% - 130px)', sm: '100%' },
                ml: '12px',
              }}
              primaryTypographyProps={{
                fontSize: 'body2.fontSize',
              }}
              primary={value}
            />
          </ListItemButton>
          <Divider />
        </ListItem>
      ))}
    </List>
  );
}

import { LmiTheme } from '../../../../../components/Common';

const ActivityChatMessagesRightStyle = {
  width: '100%',
  '.lmiw-box-message': {
    position: 'relative',
    marginBottom: '10px',
    padding: '10px',
    maxWidth: '45%',
    border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
    borderRadius: '10px',
    wordWrap: 'break-word',
    '.lmiw-timestamp-message': {
      mt: 0.5,
      color: LmiTheme.light.systemBackground.secondary,
      fontSize: '11px',
    },
  },
};

export default ActivityChatMessagesRightStyle;

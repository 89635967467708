import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import LmiTheme from '../../../themes/lmi-mui-theme/lmi-theme';
import { ItemPricingModels } from './ItemPricing.models';
import {
  PRECING_HOURLY,
  PRECING_DAILY,
  PRECING_WEEKLY,
  PRECING_MONTHLY,
} from '../../../types/index';
import bookItemContext from '../../../context/bookItem/bookContext';

function ItemPricing(props: ItemPricingModels) {
  const {
    precingType,
  }: any = useContext(bookItemContext);
  const { t } = useTranslation('global');

  return (
    <>
      <ToggleButtonGroup
        disabled={props.kAllDisabled}
        onChange={props.kOnChange}
        value={precingType}
        color="primary"
        fullWidth
        exclusive
        sx={{
          'button.Mui-selected': {
            backgroundColor: `${LmiTheme.light.accent}`,
            color: 'white',
            fontWeight: 'bold',
            span: { color: 'white' },
            '&:hover': {
              backgroundColor: LmiTheme.light.accent,
            },
          },
        }}
      >
        <ToggleButton
          disableFocusRipple
          disableRipple
          value={PRECING_HOURLY}
          disabled={props.kHourlyDisabled}
          sx={{
            flexDirection: 'column',
          }}
        >
          <>
            <Typography
              variant="caption"
            >
              {t('item_pricing.hourly')}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: 'bold',
              }}
            >
              {props.kHourlyPrice}
            </Typography>
          </>
        </ToggleButton>
        <ToggleButton
          disableFocusRipple
          disableRipple
          value={PRECING_DAILY}
          disabled={props.kDailyDisabled}
          sx={{
            flexDirection: 'column',

          }}
        >
          <>
            <Typography
              variant="caption"
              sx={{
                color: LmiTheme.light.systemBackground.tertiary,
              }}
            >
              {t('item_pricing.daily')}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: 'bold',
              }}
            >
              {props.kDailyPrice}
            </Typography>
          </>
        </ToggleButton>
        <ToggleButton
          disableFocusRipple
          disableRipple
          value={PRECING_WEEKLY}
          disabled={props.kWeeklyDisabled}
          sx={{
            flexDirection: 'column',
          }}
        >
          <>
            <Typography
              variant="caption"
              sx={{
                color: LmiTheme.light.systemBackground.tertiary,
              }}
            >
              {t('item_pricing.weekly')}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: 'bold',
              }}
            >
              {props.kWeeklyPrice}
            </Typography>
          </>
        </ToggleButton>
        <ToggleButton
          disableFocusRipple
          disableRipple
          value={PRECING_MONTHLY}
          disabled={props.kMonthlyDisabled}
          sx={{
            flexDirection: 'column',
          }}
        >
          <>
            <Typography
              variant="caption"
              sx={{
                color: LmiTheme.light.systemBackground.tertiary,
              }}
            >
              {t('item_pricing.monthly')}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: 'bold',
              }}
            >
              {props.kMonthlyPrice}
            </Typography>
          </>
        </ToggleButton>
      </ToggleButtonGroup>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        p={2}
      >
        <Typography
          sx={{
            fontSize: 'body2.fontSize',
            color: LmiTheme.light.systemBackground.secondary,
          }}
        >
          {t('item_detail.prices_expressed')}
          {props?.kCurrency?.toLowerCase() == 'usd'
            ? `${t('item_detail.usd_dollars')}`
            : `${t('item_detail.mxn_pesos')}`}
        </Typography>
      </Stack>
    </>
  );
}

ItemPricing.defaultProps = {
  kHourlyPrice: '0.00',
  kDailyPrice: '0.00',
  kWeeklyPrice: '0.00',
  kMonthlyPrice: '0.00',
};

export default ItemPricing;

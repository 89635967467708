import { useContext } from 'react';
// import { Typography } from '@mui/material';
import { ActivityDetailModel } from './ActivityDetail.models';
import ActivityReservationDetail from './ActivityReservationDetail';
import ActivityContext from '../../../../context/activity/activityContext';
import JoinRequestDetail from './Circle/JoinRequestDetail';
import CircleEarningsDetail from './Circle/CircleEarningsDetail';
import RejectedFromCircleDetail from './Circle/RejectedFromCircleDetail';
import CircleInvitationDetail from './Circle/CircleInvitationDetail';

export default function ActivityDetail(props: ActivityDetailModel) {
  const activityContext = useContext(ActivityContext);
  const {
    openedActivityItem,
  }: any = activityContext;
  let returnWidget;
  if (openedActivityItem?.notifActionType == 'joincirclerequest') {
    returnWidget = <JoinRequestDetail />;
  } else if (openedActivityItem?.notifActionType == 'circleInvitation') {
    returnWidget = <CircleInvitationDetail />;
  } else if (openedActivityItem?.notifActionType == 'circle_owner_earning') {
    returnWidget = <CircleEarningsDetail />;
  } else if (openedActivityItem?.notifActionType == 'joincirclerequest_denied') {
    returnWidget = <RejectedFromCircleDetail />;
  } else {
    returnWidget = <ActivityReservationDetail kOpenChat={props.kOpenChat} />;
  }
  return (
    <>
      {returnWidget}
    </>
  );
}

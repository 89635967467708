import React, { useContext } from 'react';
import AddEditCirclesContext from '../../../../../../../context/circles/addEditCirclesContext';

const useCircleBasicInformationValidations = () => {
  const addEditCirclesContextLog = useContext(AddEditCirclesContext);

  const {
    circleInfoEdit,
    updateCircleInfo,
    updateDraftCircleInfo,
    validErrors,
    errors,
  }: any = addEditCirclesContextLog;

  const handleChange = (name: string, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    const nameLength = 46;
    const descriptionLength = 421;
    const numLength = name === 'name' ? nameLength : descriptionLength;

    if (value.length === numLength) return;

    updateCircleInfo({
      ...circleInfoEdit,
      [name]: value,
    });
    updateDraftCircleInfo({
      ...circleInfoEdit,
      [name]: value,
    });
    validErrors({
      ...errors,
      [name]: false,
    });
  };

  return {
    handleChange,
  };
};

export default useCircleBasicInformationValidations;

import {
  Box, Typography, Stack, Button,
} from '@mui/material';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { DialogCommon, LmiTheme, TabPanelReviews } from '../../../../../ProfilePrivate/Components';
import publicProfileContext from '../../../../../../../context/publicProfile/publicProfileContext';

export default function MemberReviews() {
  const { t } = useTranslation('global');

  // const numberUserReviews = 8;
  const pProfileContext = useContext(publicProfileContext);
  const {
    publicProfileInfo,
    privateProfileInfo,
    publicUserReviews,
    publicGivenReviewList,
  }: any = pProfileContext;

  const [showReviews, setShowReviews] = useState(0);

  return (
    <Box>
      <Stack
        height="53px"
        p={0}
        pl={3}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={0}
      >
        {
          publicProfileInfo?.totalReceivedReviews !== 0 ? (
            <>
              <Stack flexDirection="row">
                <ThumbUpRoundedIcon sx={{ color: '#2daa23', mr: '12px' }} />
                <Typography fontSize="17px" color={LmiTheme.light.systemBackground.tertiary}>
                  {' '}
                  {publicProfileInfo?.sumRatesReceived?.toFixed(2) ?? 100}
                  %
                </Typography>
              </Stack>
              <Stack flexDirection="row">
                <Button
                  onClick={() => setShowReviews(1)}
                  sx={{ height: '30px', mr: '8px' }}
                >
                  <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    mr: '4px',
                    textTransform: 'none',
                  }}
                  >
                    {publicProfileInfo?.totalReceivedReviews}
                    {' '}
                    {t('circles.members.reviews')}
                  </Typography>
                  <ArrowForwardIosRoundedIcon />
                </Button>
              </Stack>
            </>

          ) : (
            <Typography sx={{ fontSize: 'body2.fontSize', color: LmiTheme.light.systemBackground.tertiary }}>
              {t('profile.no_reviews')}
            </Typography>
          )
        }
      </Stack>

      <DialogCommon
        kTitle={t('profile.reviews')}
        kMaxWidth="sm"
        kOpenDialogLists={showReviews}
        kBorderRadius={isMobile ? 0 : '10px'}
        kDisableContentPaddinginY
        kDisableContentPadding
        kSlideTransition={isMobile}
        kOnClose={() => setShowReviews(0)}
        kOnSecondary={() => setShowReviews(0)}
        kSecondaryBtnText={t('global.close')}
        kContent={
          (
            <TabPanelReviews
              hideReviewsSummary
              kTabValue={showReviews}
              kProfileInfo={privateProfileInfo}
              kRecommendedNumber={privateProfileInfo?.sumRatesReceived?.toFixed(2) ?? 100}
              kUserReviews={publicUserReviews}
              kGivenReviews={publicGivenReviewList}
              kTabIndex={1}
            />
          )
        }
      />
    </Box>
  );
}

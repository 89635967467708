import { useReducer } from 'react';
import {
  ClsModGetItemCategoriesListNew,
} from '@lendmeit/api_backend_lmi/dist/models';
// import { useHistory } from 'react-router-dom';
import { String } from 'aws-sdk/clients/acm';
import HomeContext from './homeContext';
import HomeReducer from './homeReducer';
import {
  ADD,
  ADD_RANGE,
  DELETE,
  DELETE_ALL,
  GET_ALL,
  SET_LOCATION,
  SET_CURRENCY,
} from '../../types';
import ApiLMI from '../../services/apilmi';
import { LocationInfoModel, LocationModel } from './models/locationModel';

const HomeState = ({ children }: any) => {
  const [state, dispatch] = useReducer(HomeReducer, {
    categories: null,
    loading: true,
    lat: localStorage.getItem('lat') ?? 34.0536909,
    long: localStorage.getItem('lng') ?? -118.242766,
    address: '',
    longAddress: '',
    countryName: localStorage.getItem('countryName') ?? 'United States',
    postalCode: '',
    extendedAddress: '',
    categoriesSelected: localStorage.getItem('filtersCategory') ? JSON.parse(localStorage.getItem('filtersCategory')!) : null,
    categoriesApplied: null,
    searchCurrency: '',
  });

  const {
    lat,
    long,
    address,
    searchCurrency,
    longAddress,
  }: any = state;

  const getCategories = async () => {
    try {
      const _api = ApiLMI();
      const body: ClsModGetItemCategoriesListNew = {};
      const { data } = await _api.itemGetItemCategoriesListNew(body);

      if (typeof data === 'string') {
        const obj = JSON.parse(data.toString());

        if (obj.Error === '') {
          const categories = obj.Response;
          if (categories && categories.length > 0) {
            dispatch({
              type: GET_ALL,
              payload: categories,
            });
          }
        }
      }
    // eslint-disable-next-line no-empty
    } catch (_) {

    }
  };

  const addCategory = async (category: any) => {
    try {
      dispatch({
        type: ADD,
        payload: category,
      });
    // eslint-disable-next-line no-empty
    } catch (_) {

    }
  };

  const addCategories = async (categories: []) => {
    try {
      dispatch({
        type: ADD_RANGE,
        payload: categories,
      });
    // eslint-disable-next-line no-empty
    } catch (_) {

    }
  };

  const removeAllCategoriesSelected = async () => {
    try {
      dispatch({
        type: DELETE_ALL,
        payload: null,
      });
    // eslint-disable-next-line no-empty
    } catch (_) {

    }
  };

  const removeCategorySelected = async (category: any) => {
    try {
      addCategory(category);
      dispatch({
        type: DELETE,
        payload: category,
      });
    // eslint-disable-next-line no-empty
    } catch (_) {

    }
  };

  const setLocation = async (location: LocationModel) => {
    await new Promise((resolve) => {
      fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.lat},${location.long}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}`)
        .then((response) => response.json())
        .then((data) => {
          let streetAddress = '';
          let extendedAddress = '';
          let locality = '';
          let region = '';
          let postalCode = '';
          let countryName = '';
          let countryCode = '';

          data.results[0].address_components.forEach((element: any) => {
            const shortName = element.short_name;
            const longName = element.long_name;

            const { types } = element;

            if (types.includes('colloquial_area')) {
              streetAddress = longName;
            }
            if (types.includes('street_number')) {
              streetAddress = `${streetAddress} ${longName}`.trim();
            }
            if (types.includes('route')) {
              streetAddress = `${streetAddress} ${longName}`.trim();
            } else if (types.includes('sublocality')) {
              extendedAddress = longName;
            } else if (types.includes('locality')) {
              locality = longName;
            } else if (types.includes('administrative_area_level_1')) {
              region = shortName;
            } else if (types.includes('postal_code')) {
              postalCode = longName;
            } else if (types.includes('country')) {
              countryName = longName;
              countryCode = shortName;
            }
          });

          const locationInfoModel: LocationInfoModel = {
            lat: location.lat,
            long: location.long,
            address: `${locality}, ${region}`,
            longAddress: streetAddress,
            countryName,
            countryCode,
            postalCode,
            extendedAddress,
          };

          dispatch({
            type: SET_LOCATION,
            payload: locationInfoModel,
          });

          setTimeout(() => {
            resolve({});
          }, 170);
        }).catch((e) => {
          resolve(e);
        });
    });
  };

  const setSearchCurreny = async (currency: String) => {
    try {
      // addCategory(category);
      dispatch({
        type: SET_CURRENCY,
        payload: currency,
      });
    // eslint-disable-next-line no-empty
    } catch (_) {

    }
  };

  return (
    <HomeContext.Provider
      value={
     {
       ...state,
       categories: state.categories,
       categoriesSelected: state.categoriesSelected,
       loading: state.loading,
       lat,
       long,
       address,
       countryCode: state.countryCode,
       longAddress,
       searchCurrency,
       getCategories,
       addCategory,
       addCategories,
       removeAllCategoriesSelected,
       removeCategorySelected,
       setLocation,
       setSearchCurreny,
     }
      }
    >
      {children}
    </HomeContext.Provider>
  );
};

export default HomeState;

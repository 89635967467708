import {
  GET_BY_ID, UPDATE_DEFAULT_CARD,
  PAYOUT_EDIT_INFO,
  CHECK_NEW_PAYOUT_VALIDATIONS,
  UPDATE_PAYOUT_VALIDATION_STATUS,
  PAYMENT_METHOD_ADDED_SUCCESS,
  DELETED_CARD_SUCCESS,
  PAYOUT_UPDATED_CREATED,
} from '../../types';

export default (state: any, action: any) => {
  switch (action.type) {
    case GET_BY_ID:
    {
      return {
        ...state,
        loading: false,
        payoutAccountDetails: action.payload.payoutAccountDetails,
        payoutAccDetEdit: action.payload.payoutAccDetEdit,
      };
    }
    case UPDATE_DEFAULT_CARD:
    {
      return {
        ...state,
        loading: false,
        paymentMethodsList: action.payload.paymentMethodsList,
      };
    }
    case PAYOUT_EDIT_INFO:
    {
      return {
        ...state,
        loading: false,
        payoutAccountDetails: action.payload.payoutAccountDetails,
        checkPayoutValidate: action.payload.checkPayoutValidate,
      };
    }
    case CHECK_NEW_PAYOUT_VALIDATIONS: {
      return {
        ...state,
        checkPayoutValidate: action.checkPayoutValidate,
        triggerCheckValidate: action.triggerCheckValidate,
      };
    }
    case UPDATE_PAYOUT_VALIDATION_STATUS: {
      return {
        ...state,
        arePayoutFieldsValidated: action.arePayoutFieldsValidated,
        changedPayoutFields: action.changedPayoutFields,
        checkPayoutValidate: false,
      };
    }
    case PAYMENT_METHOD_ADDED_SUCCESS: {
      return {
        ...state,
        newPaymentMethodInfo: action.newPaymentMethodInfo,
      };
    }
    case DELETED_CARD_SUCCESS: {
      return {
        ...state,
        paymentMethodsList: action.payload.paymentMethodsList,
      };
    }
    case PAYOUT_UPDATED_CREATED: {
      return {
        ...state,
        payoutUpdated: true,
        arePayoutFieldsValidated: false,
        checkPayoutValidate: false,
      };
    }
    default:
      return state;
  }
};

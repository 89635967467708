import { Box, Typography } from '@mui/material';

import LmiTheme from '../../../themes/lmi-mui-theme/lmi-theme';
import SectionTitle from '../SectionTitle/SectionTitle';

import { SectionDescriptionModels } from './SectionDescription.models';

function SectionDescription(props: SectionDescriptionModels) {
  return (
    <>
      <Box>
        <SectionTitle
          kTitle={props.kTitle}
          kFontSize={props.kTitleSize}
          kMarginLeft={props.kMarginLeft}
          kMarginRight={props.kMarginRight}
          kMarginTop={props.kMarginTop}
          kMarginBottom={props.kMarginBottom}
        />
        <Typography sx={{ fontSize: props.kFontSize, color: props.kColor }}>
          {props.kDescription}
        </Typography>
      </Box>
    </>
  );
}

SectionDescription.defaultProps = {
  kTitle: 'kTitle',
  kDescription: 'kDescription',
  kColor: LmiTheme.light.systemBackground.secondary,
  kFontSize: 'subtititle1.fontSize',
  kFontWeight: 'normal',
  kMarginLeft: 0,
  kMarginRight: 0,
  kMarginTop: 2,
  kMarginBottom: 2,
};

export default SectionDescription;

import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import MyCirclesListingContext from '../../../../../../context/myCirclesListing/myCirclesListingContext';
import CardLend from '../../CardLend/CardLend';
import SecondTopBar from '../../../../Circles/Components/SecondTopBar/SecondTopBar';
import ManageCirclesSectionModal from './ManageCirclesSectionModal';
import NoCirclesFound from '../../../../Circles/Components/NoCirclesFound.tsx/NoCirclesFound';

export default function ManageCirclesModal() {
  const circlesContextLog = useContext(MyCirclesListingContext);

  const {
    itemsFiltered,
    filterCircles,
    circles,
    getMyCircles,
  }: any = circlesContextLog;

  const [search, setSearch] = useState('');
  const [circlesInfo, setCirclesInfo] = useState([]);
  const [openSearchCircle, setOpenSearchCircle] = useState(false);
  const [searched, setSearched] = useState(false);

  useEffect(() => {
    (async function fetchCircles() {
      await getMyCircles();
    }());
  }, []);

  useEffect(() => {
    if (circles) setCirclesInfo(circles);
  }, [circles]);

  useEffect(() => {
    setCirclesInfo(itemsFiltered);
  }, [itemsFiltered]);

  const timer = setTimeout(() => {
    setSearched(false);
  }, 1500);

  const goToSearch = (value: string) => {
    setSearch(value);
    filterCircles(value);
    setOpenSearchCircle(true);
    setSearched(true);
    clearTimeout(timer);
  };

  const { t } = useTranslation('global');

  return (
    <>
      <CardLend
        kHiddenTitle
        kHideDividers
        kHideSecondTopBarDivider
        kOpenSearchCircle={openSearchCircle}
        // Button to open of input search
        kActions={(
          <FormControl
            sx={{ width: '100%' }}
            onChange={(e: any) => goToSearch(e.target.value)}
            onKeyDown={(e: any) => (e.keyCode == 13
              ? goToSearch(e.target.value)
              : '')}
          >
            <OutlinedInput
              sx={{ borderRadius: '10px' }}
              inputProps={{
                maxLength: 50,
              }}
              value={search}
              fullWidth
              onFocus={() => {
              }}
              onBlur={() => {
                setTimeout(() => {
                }, 100);
              }}
              startAdornment={(
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )}
              endAdornment={(
                search.length > 0 && (
                  <InputAdornment position="start">

                    <IconButton color="primary" onClick={() => { goToSearch(''); }}>
                      <CancelIcon />
                    </IconButton>
                  </InputAdornment>
                )
              )}
              placeholder={t('circles.main.search_circle')}
            />
          </FormControl>
        )}
        kTopBar={(
          <SecondTopBar
            kCirclesUserBelong
            kSecondTitle={searched ? 'searched' : t('circles.main.my_circles')}
            kOpenSearchCircle
          />
        )}
        // Show the content in the list circles
        kContent={(
          <>
            {circlesInfo && circlesInfo.length > 0 ? (
              <Box sx={{ display: { md: 'inherit' } }}>
                <ManageCirclesSectionModal circlesInfo={circlesInfo} />
              </Box>
            ) : <NoCirclesFound hiddenButton />}
          </>
        )}
        kSecondTopBar={(
          <>
            {(circlesInfo && circlesInfo.length > 0) && search.length == 0 ? (
              <SecondTopBar
                kCirclesUserBelong
                kSecondTitle=""
                kOpenSearchCircle={false}
              />
            ) : null}
          </>
        )}
        kSecondContent={(
          <>
            {circlesInfo && circlesInfo.length > 0 ? (
              <Box sx={{ display: { md: 'inherit' } }}>
                <ManageCirclesSectionModal circlesInfo={circlesInfo} isMember />
              </Box>
            ) : null}
          </>
        )}
      />
    </>
  );
}

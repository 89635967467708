import {
  ClsModCreateUpdateItemMaxRate,
  ClsModGetItemBlockedIndDates,
  ClsModGetItemDetailInfo, ClsModGetItemImages, ClsModGetItemRelatedAccessories, ClsModGetItemRentedUnavailableDates, ClsModGetUserTermsAndConditions, CurrencyEnum, CurrencySourceEnum,
} from '@lendmeit/api_backend_lmi/dist/models';
import moment from 'moment';
import { useContext } from 'react';
// import { useContext } from 'react';
import { toast } from 'react-toastify';
import addEditItemContext from '../context/addEditItem/addEditItemContext';
// import addEditItemContext from '../context/addEditItem/addEditItemContext';
import { AddItemInfoModel } from '../page/BackOffice/Listing/Components/AddItem/AddItem.models';
import ApiLMI from '../services/apilmi';
import ApiVideoLMI from '../services/apilmiVideo';

// eslint-disable-next-line import/prefer-default-export

export async function getItemInfoById(idCatLndItem: number) {
  const api = ApiLMI();
  const body: ClsModGetItemDetailInfo = {
    idCatLndItem,
    // idTerm: 0,
    toc: 'jsjdjajd',
  };
  try {
    const result = await api.itemGetUserLndItems(body);
    const data = JSON.parse(result.data.toString());
    data.Response[0].requireIdVerify = data.Response[0].requiresIdVerify;
    data.Response[0].acceptOffers = data.Response[0].accept_offers != null
      ? data.Response[0].accept_offers
      : false;

    return data.Response[0];
  } catch (e) {
    return null;
  }
}

export async function getItemAccesories(idCatLndItem: number) {
  const api = ApiLMI();
  const body: ClsModGetItemRelatedAccessories = {
    idCatLndItem,
    toc: 'jsjdjajd',
  };
  try {
    const result = await api.itemGetItemRelatedAccessoriesEdit(body);
    const data = JSON.parse(result.data.toString());
    return data.Response;
  } catch (e) {
    return 0;
  }
}

export async function getItemBlockedIndDates(idCatLndItem: number) {
  const api = ApiLMI();
  const body: ClsModGetItemBlockedIndDates = {
    idCatLndItem,
    toc: 'jsjdjajd',
  };
  try {
    const result = await api.itemGetItemBlockedIndDates(body);
    const data = JSON.parse(result.data.toString());
    const arrayDates: any = [];
    data.Response.forEach((e: any) => {
      arrayDates.push(e.blockedDate);
    });
    // return data.Response;
    return arrayDates;
  } catch (e) {
    return 0;
  }
}

export async function getItemGetRentedUnavailableDates(idCatLndItem: number) {
  const api = ApiLMI();
  const body: ClsModGetItemRentedUnavailableDates = {
    idCatLndItem,
    toc: 'jsjdjajd',
  };
  try {
    const result = await api.itemGetItemRentedUnavailableDates(body);
    const data = JSON.parse(result.data.toString());
    const arrayDates: any = [];
    data.Response.forEach((e: any) => {
      arrayDates.push(e.no_date);
    });
    // return data.Response;
    return arrayDates;
  } catch (e) {
    return 0;
  }
}

export async function userGetUserTermsAndConditions(idCatLndItem: number) {
  const api = ApiLMI();
  const body: ClsModGetUserTermsAndConditions = {
    idCatLndItem,
    toc: 'jsjdjajd',
    ownTerms: 0,
  };
  try {
    const result = await api.userGetUserTermsAndConditions(body);
    const data = JSON.parse(result.data.toString());
    if (data.Error == '') {
      return data.Response;
    }
    return [];
  } catch (e) {
    console.log(e);
    return 0;
  }
}

export async function getItemImages(idCatLndItem: number) {
  const api = ApiLMI();
  const body: ClsModGetItemImages = {
    idCatLndItem,
    toc: 'jsjdjajd',
  };
  try {
    const result = await api.itemGetItemImages(body);
    const data = JSON.parse(result.data.toString());
    return data.Response;
  } catch (e) {
    return 0;
  }
}

export async function createUpdateItemInfo(itemInfo: AddItemInfoModel,
  itemImages: any,
  userTerms: any,
  itemAccessories: any,
  deletedImages: any,
  blockedIndDates: any,
  {
    isHourlyRental,
    isDailyRental,
    isWeeklyRental,
    isMonthlyRental,
  }: any,
  currency: string,
  currencySource: string,
  t: any) {
  const addEditContextLog = useContext(addEditItemContext);
  const {
    setFlag,
  }: any = addEditContextLog;
  try {
    const idtoast = toast.loading(t('listing_data.saving_info'));
    itemInfo.toc = 'testToken';
    const format1 = 'YYYY-MM-DD HH:mm:ss';
    itemInfo.startDeliveryTime = moment(itemInfo.startDeliveryTime).format(format1);
    itemInfo.endDeliveryTime = moment(itemInfo.endDeliveryTime).format(format1);
    itemInfo.availabilityFromTime = moment(itemInfo.availabilityFromTime).format(format1);
    itemInfo.availabilityToTime = moment(itemInfo.availabilityToTime).format(format1);
    // itemInfo.startDeliveryTime = new Date(itemInfo.startDeliveryTime).toISOString();
    // itemInfo.endDeliveryTime = new Date(itemInfo.endDeliveryTime).toISOString();

    if (itemInfo.idCatTypeItem == 1 && itemInfo.replacementCost < 5 && currency == 'USD') {
      toast.update(idtoast, {
        render: t('listing_data.item_value_info'),
        type: 'error',
        isLoading: false,
        autoClose: 1500,
      });

      return 0;
    }

    if (itemInfo.idCatTypeItem == 1 && itemInfo.replacementCost < 50 && currency == 'MXN') {
      toast.update(idtoast, {
        render: t('listing_data.item_value_info_mxn'),
        type: 'error',
        isLoading: false,
        autoClose: 1500,
      });

      return 0;
    }

    if (!itemImages || (itemImages && itemImages.length == 0)) {
      toast.update(idtoast, {
        render: t('listing_data.photo_required'),
        type: 'error',
        isLoading: false,
        autoClose: 1500,
      });

      return 0;
    }

    if (
      (isHourlyRental && itemInfo.hourlyRentalPrice >= 5)
      || (isDailyRental && itemInfo.dailyRentalPrice >= 5)
      || (isWeeklyRental && itemInfo.weeklyRentalPrice >= 5)
      || (isMonthlyRental && itemInfo.monthlyRentalPrice >= 5)
    ) {
      itemInfo.isHourlyRental = isHourlyRental;
      itemInfo.isDailyRental = isDailyRental;
      itemInfo.isWeeklyRental = isWeeklyRental;
      itemInfo.isMonthlyRental = isMonthlyRental;
      itemInfo.acceptOffers = itemInfo.accept_offers;
      itemInfo.currency = currency;
      // const api = ApiLMI();
      // const resultItem = await api.apiItemCreateUpdateItemPost(itemInfo);
      const resultItem = await createUpdateItemBasicInfo(itemInfo, currencySource);

      const resultIdItem = JSON.parse(resultItem.data.toString()).Response[0].IdItemLnd;
      itemInfo.idCatLndItem = resultIdItem;
      if (resultItem != null) {
        await createUpdateItemDynamicLinks(resultItem);

        await UpdateLndItemStatuses(itemInfo);

        await saveItemAddress(itemInfo);

        await UpdateItemImages(itemImages, resultIdItem, t);

        await deleteItemImages(deletedImages, resultIdItem);

        await updateUserReqs(userTerms, resultIdItem);

        await deleteCreateAcceptOffers(itemInfo);

        await deleteCreateDeliveryPrices(itemInfo);

        await createUpdateaccessories(itemAccessories, resultIdItem);

        await deleteBlockedIndDates(resultIdItem);

        await createUpdateItemBlockedIndDates(blockedIndDates, resultIdItem);

        await setFlag();
      }
    } else {
      toast.update(idtoast, {
        render: t('listing_data.minimum_rent'),
        type: 'error',
        isLoading: false,
        autoClose: 1000,
      });
      return 0;
    }

    toast.dismiss();
    return 1;
  } catch (e: any) {
    const err = { ...e };
    console.error(err);
    return 0;
  }
}

async function saveItemAddress(itemInfo: AddItemInfoModel) {
  const api = ApiLMI();
  const bodyCreateUpdateAddress = {
    adress: itemInfo.address,
    toc: 'testToken',
    idCatLndItemAddress: itemInfo.idCatLndItemAdress,
    lat: itemInfo.lat,
    lng: itemInfo.lon,
    active: true,
    name: itemInfo.extendedAddress,
    country: itemInfo.country,
    state: itemInfo.state,
    city: itemInfo.city,
    zipCode: itemInfo.zipCode,
    street: itemInfo.street,
  };
  const resultAddress = await api.itemCreateUpdateItemUserMapAdress(bodyCreateUpdateAddress);
  if (resultAddress != null) {
    const idAddress = JSON.parse(resultAddress.data.toString()).Response[0].IdItemAdress;
    itemInfo.idCatLndItemAdress = idAddress;
    const bodyUpdateAddress = {
      toc: 'testToken',
      idCatLndItem: itemInfo.idCatLndItem,
      idCatLndItemAddress: idAddress,
    };

    await api.itemUpdateItemAddress(bodyUpdateAddress);
  }
}

// subfunctions for creating or updating an item

// Subsection for creating or updating item for basic information
async function createUpdateItemBasicInfo(itemInfo: AddItemInfoModel, currencySource: any) {
  const api = ApiLMI();
  const currSource = currencySource == 'PhoneNumber' ? CurrencySourceEnum.PhoneNumber : CurrencySourceEnum.Payout;
  const body: ClsModCreateUpdateItemMaxRate = {

    idCatLndItem: itemInfo.idCatLndItem,
    toc: 'token',
    name: itemInfo.name,
    dscription: itemInfo.dscription,
    dscription2: '',
    brand: itemInfo.brand,
    model: itemInfo.model,
    year: parseInt(itemInfo.year),
    idCatLndCategory: itemInfo.idCatLndCategory,
    idCatLndCondition: itemInfo.idCatLndCondition,
    finish: itemInfo.finish,
    color: itemInfo.color,
    replacementCost: itemInfo.replacementCost,
    dailyRentalPrice: itemInfo.isDailyRental ? itemInfo.dailyRentalPrice : 0,
    acceptOffers: itemInfo.acceptOffers,
    zipCode: itemInfo.zipCode,
    address: itemInfo.address,
    pickUpOnly: itemInfo.delivery,
    idCatLndItemStatus: 1,
    roadie: itemInfo.roadie,
    startDeliveryTime: itemInfo.startDeliveryTime,
    endDeliveryTime: itemInfo.endDeliveryTime,
    weight: itemInfo.weight,
    idCatLndUomWeight: 1,
    height: itemInfo.height,
    width: itemInfo.width,
    length: itemInfo.length,
    idCatLndUomDimension: 1,
    lat: '',
    lon: '',
    location: '',
    active: itemInfo.active,
    usShippingCost: itemInfo.usCost,
    internationalShippingCost: itemInfo.internationalCost,
    conditions: itemInfo.conditions,
    minRentDays: itemInfo.minRentDays,
    requiresDeposit: itemInfo.requiresDeposit,
    depositPercentage: itemInfo.depositPercentage,
    depositDueDays: 0,
    fromPickUpTime: itemInfo.fromPickUpTime,
    toPickUpTime: itemInfo.toPickUpTime,
    fromDropOffTime: itemInfo.fromDropOffTime,
    toDropOffTime: itemInfo.toDropOfftime,
    securityDeposit: itemInfo.securityDeposit,
    canBeArranged: itemInfo.canBeArranged,
    isAvailable: itemInfo.active,
    exactAddress: itemInfo.exactAddress,
    requireIdVerify: itemInfo.requiresIdVerify,
    hourlyRentalPrice: itemInfo.isHourlyRental ? itemInfo.hourlyRentalPrice : 0,
    weeklyRentalPrice: itemInfo.isWeeklyRental ? itemInfo.weeklyRentalPrice : 0,
    monthlyRentalPrice:
      itemInfo.isMonthlyRental ? itemInfo.monthlyRentalPrice : 0,
    idCatTypeItem:
      itemInfo.idCatTypeItem != null ? itemInfo.idCatTypeItem : 1,
    isLenderDelivery: itemInfo.delivery != null ? itemInfo.delivery : false,
    physicalLat: itemInfo.physicalLat,
    physicalLon: itemInfo.physicalLon,
    physicalAddress: itemInfo.physicalAddress,
    publicIP: itemInfo.publicIP,
    availabilityTimeFrom: itemInfo.availabilityFromTime,
    availabilityTimeTo: itemInfo.availabilityToTime,
    currency: itemInfo.currency == 'USD' ? CurrencyEnum.USD : CurrencyEnum.MXN,
    currencySource: currSource,
    hasServiceMaxRate: itemInfo.hasServiceMaxRate ? itemInfo.hasServiceMaxRate : false,
    serviceMaxRate: itemInfo.serviceMaxRate,
  };
  const result = await api.apiItemCreateUpdateItemCurrencyPost(body);
  // const result = await api.itemCreateUpdateLndItemWithDeliveryOptions(itemInfo);
  return result;
}

// Subsection for creating item and user lend page dynamic links
async function createUpdateItemDynamicLinks(resultItem: any) {
  const api = ApiLMI();
  const resultIdItem = JSON.parse(resultItem.data.toString()).Response[0].IdItemLnd;
  if (resultIdItem != null && resultIdItem > 0) {
    const bodyItemCreateItemDetailDynamicLink = {
      idCatLndItem: resultIdItem,
      toc: 'testToken',
    };
    await api.itemCreateItemDetailDynamicLink(bodyItemCreateItemDetailDynamicLink);

    const bodycreateULPDL = {
      toc: 'testToken',
    };
    const createULPDL = await api.userCreateUserLendPageDynamicLink(bodycreateULPDL);

    console.log(createULPDL);

    return createULPDL;
  }
  return 0;
}

export async function UpdateLndItemStatuses(itemInfo: AddItemInfoModel) {
  const api = ApiLMI();
  const bodyItemStatus = {
    toc: 'testToken',
    idCatLndItem: itemInfo.idCatLndItem,
    active: itemInfo.active,
    circlesOnly: itemInfo.circlesOnly,
    circlesExplore: itemInfo.circlesAndExplore,
    exploreOnly: itemInfo.exploreOnly,
  };

  const _idCatLndItemStatus = await api.itemUpdateLndItemStatuses(bodyItemStatus);
  return _idCatLndItemStatus;
}

async function UpdateItemImages(itemImages: any, idCatLndItem: number, t: any) {
  // ADD ITEM PICTURES
  const api = ApiLMI();
  let controlImgs = 0;
  try {
    // eslint-disable-next-line no-restricted-syntax
    for await (const element of itemImages) {
      if (element.idDetLndItemImage == null) {
        const bodyE = {
          toc: 'testToken',
          profilePicturebase: element.split(',')[1],
        };

        const newPictureObject = await api.itemUploadItemPicture(bodyE);

        if (newPictureObject != null) {
          const { data } = newPictureObject;

          if (typeof data === 'string') {
            const result = JSON.parse(data.toString());
            const { Response, Error: error } = result;

            if (error == '' && Response.length > 0) {
              const imgUrl = Response[0].fileName;
              const bodyE2 = {
                toc: 'testToken',
                idCatLndItem,
                imgPath: imgUrl,
                position: controlImgs + 1,
              };

              await api.itemCreateUpdateLndItemImage(bodyE2);
            } else {
              toast.error(error);
            }
          } else {
            toast.error(t('listing_data.problem_saving'));
          }
        } else {
          toast.error(t('listing_data.problem_saving'));
        }
      } else {
        // Update existing picture position
        const bodyPic = {
          idCatLndItem: element.idCatLndItem,
          fileName: element.fileName,
          position: controlImgs + 1,
          active: 1,
          toc: 'testToken',
        };

        await api.itemUpdateItemImage(bodyPic);
      }

      controlImgs += 1;
    }
  } catch (e: any) {
    toast.error(t('listing_data.problem_saving'));
  }
}

async function updateUserReqs(userTerms: any, idCatLndItem: number) {
  const api = ApiLMI();
  // DELETE ITEM REQS ADD ITEM REQS
  const bodyDeleteTerms = {
    toc: 'testToken',
    idCatLndItem,
  };
  await api.itemDeleteItemTermsAndConditionsRelationToItem(bodyDeleteTerms);
  if (userTerms && userTerms.length > 0) {
    // eslint-disable-next-line no-restricted-syntax
    for (const element of userTerms) {
      const bodyNewTerm = {
        toc: 'testToken',
        termDetail: element.termDetail,
        idTerm: element.idTerm,
      };
      // eslint-disable-next-line no-await-in-loop
      const idNewTerm = await api.userCreateUpdateUserTermsAndConditions(bodyNewTerm);
      const bodyNewTerm2 = {
        toc: 'testToken',
        idCatLndItem,
        idTerm: JSON.parse(idNewTerm.data.toString()).Response[0].idTerm,
      };
      if (element.relatedToItem) {
        // eslint-disable-next-line no-await-in-loop
        await api.itemCreateRelUserTermWithItem(bodyNewTerm2);
      }
    }
  }
}

async function deleteCreateDeliveryPrices(itemInfo: any) {
  const api = ApiLMI();
  //  Delete previous delivery prices row of the item
  const bodyItemDeleteItemDeliveryPrices = {
    idCatLndItem: itemInfo.idCatLndItem,
    toc: 'testToken',
  };
  await api.itemDeleteItemDeliveryPrices(bodyItemDeleteItemDeliveryPrices);

  await api.itemCreateItemDeliveryPrices(itemInfo);
}

async function deleteCreateAcceptOffers(itemInfo: any) {
  const api = ApiLMI();
  //  Delete previous salePrice value
  const bodyItemDeleteItemForSalePrice = {
    idCatLndItem: itemInfo.idCatLndItem,
    toc: 'testToken',
  };
  await api.itemDeleteItemForSalePrice(bodyItemDeleteItemForSalePrice);

  if (itemInfo.acceptOffers && itemInfo.salePrice > 0) {
    const bodyItemUpdateItemAcceptOffersPriceSale = {
      idCatLndItem: itemInfo.idCatLndItem,
      toc: 'testToken',
      acceptOffers: itemInfo.acceptOffers ? 1 : 0,
      salePrice: itemInfo.salePrice,
    };
    await api.itemUpdateItemAcceptOffersPriceSale(bodyItemUpdateItemAcceptOffersPriceSale);
  }
}

async function createUpdateaccessories(itemAccessories: any, idCatLndItem: number) {
  const api = ApiLMI();
  // UPDATE ACCESSORIES
  // eslint-disable-next-line no-restricted-syntax
  for (const element of itemAccessories) {
    const bodyNewAccessory = {
      toc: 'testToken',
      idCatLndItem,
      idRelItemAccesorie: element.idRelItemAccesorie,
      name: element.name,
      dsription: element.dscription,
      rentalPrice: element.dailyRentalPrice,
      active: element.active,
      hourlyPrice: element.hourlyRentalPrice,
      weeklyPrice: element.weeklyRentalPrice,
      monthlyPrice: element.monthlyRentalPrice,
      replacementCost: element.replacementCost,
      weight: element.weight,
      height: element.height,
      width: element.width,
      length: element.length,
    };
    // eslint-disable-next-line no-await-in-loop
    await api.itemCreateUpdateItemAccesorieWithDelivery(bodyNewAccessory);
  }
}

async function deleteBlockedIndDates(idCatLndItem: any) {
  const api = ApiLMI();
  //  Delete previous salePrice value
  const bodyItemDeleteItemForSalePrice = {
    idCatLndItem,
    toc: 'testToken',
  };
  await api.itemDeleteItemBlockedIndDates(bodyItemDeleteItemForSalePrice);
}

async function createUpdateItemBlockedIndDates(itemBlockedDates: any, idCatLndItem: number) {
  const api = ApiLMI();
  // eslint-disable-next-line no-restricted-syntax
  for (const element of itemBlockedDates) {
    const bodyNewAccessory = {
      toc: 'testToken',
      idCatLndItem,
      idatLndBlockedIndDates: 0,
      name: '',
      title: '',
      description: '',
      active: true,
      blockedDate: element,
    };
    // eslint-disable-next-line no-await-in-loop
    await api.itemCreateUpdateItemBlockedIndDates(bodyNewAccessory);
  }
}

async function deleteItemImages(deletedImages: any, idCatLndItem: number) {
  // ADD ITEM PICTURES
  const api = ApiLMI();
  let controlImgs = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const element of deletedImages) {
    // Update existing picture position
    const bodyPic = {
      idCatLndItem,
      fileName: element.fileName,
      position: controlImgs + 1,
      active: 0,
      toc: 'testToken',
    };

    // eslint-disable-next-line no-await-in-loop
    await api.itemUpdateItemImage(bodyPic);

    // eslint-disable-next-line no-plusplus
    controlImgs++;
  }
}

export async function uploadItemVideo() {
  try {
    const api = ApiVideoLMI();
    // const urlApi = process.env.REACT_APP_API_VIDEO_BACKEND!;
    const testName = 'vid_test_thing122.mp4';
    const urlSend = `/item/uploaditemvideo/proxy+/?name=${testName}`;
    await api.get(urlSend);
  } catch (e) {
    console.log(e);
  }

  // var res = await http.get(firstRequest.url, headers: firstRequest.headers);
  // final decodedData = json.decode(utf8.decode(res.bodyBytes));
  // final uploadEndPoint = decodedData["signed_url"];

  // var res2 =
  //     await http.put(uploadEndPoint, body: File(path).readAsBytesSync());

  // if (res2.statusCode == 200) {
  //   return name;
  // } else
  //   return "";
}

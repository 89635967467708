import LmiTheme from '../../../../../themes/lmi-mui-theme/lmi-theme';

const FilterStyle = {
  minHeight: 'calc(100vh - 200px)',
  borderRadius: 0,
  top: { xs: 0, sm: 64 },
  minWidth: { xs: 230, sm: 210, md: 230 },
  '.lmiw-content': {
    borderRight: `1px solid ${LmiTheme.light.systemBackground.separator}`,
    pb: '0 !important',
  },
  '.lmiw-title': {
    lineHeight: 1.2,
  },
  '.lmiw-subtitle': {
    lineHeight: 1.2,
    fontWeight: 'bold',
  },
  '.lmiw-list': {
    width: '100%',
    bgcolor: 'background.paper',
    height: { xs: 'calc(100vh - 110px)', sm: 'calc(100vh - 180px)' },
    overflow: 'auto',
  },
  '.lmiw-btn-apply': {
    minWidth: '100px',
    height: '44px',
    borderRadius: 6,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    mb: 3,
  },

  '.lmiw-box-checkbox': {
    width: '18px',
    height: '18px',
    padding: '0',
    borderRadius: '5px',
    border: `1px solid${LmiTheme.light.systemBackground.secondary}`,
    '&.active': {
      background: LmiTheme.light.accent,
      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
};

export default FilterStyle;

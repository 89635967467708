// import { LmiTheme } from '../../../../components/Common';

const ActivityChatStyle = {
  width: '100%',
  // height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '.lmiw-activity-chat-paper': {
    // width: '80vw',
    // height: '80vh',
    // maxWidth: '500px',
    // maxHeight: '700px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
  },

};

export default ActivityChatStyle;

import { ReactElement } from 'react';

export interface AddItemModel {
    edit?: boolean;
    content?: ReactElement,
}

export interface CircleDetailInfoModel {
    idCatLndCircle: number,
    name: string,
    dscription: string,
    circlePicture: string,
    showCircleLocation: boolean,
    isPublic: boolean,
    active: boolean,
    myCircle: boolean,
    totalMembers: number,
    isAdmin: boolean,
    isOwner: boolean,
    isMember: boolean,
    deepLink: string,
}

export const emptyCircleModel = (): CircleDetailInfoModel => ({
  idCatLndCircle: 0,
  name: '',
  dscription: '',
  circlePicture: '',
  showCircleLocation: true,
  isPublic: false,
  active: true,
  myCircle: true,
  totalMembers: 0,
  isAdmin: true,
  isOwner: true,
  isMember: true,
  deepLink: '',
});

export interface AddEditCircleModel {

  idCatLndCircle: number,
  name: string,
  dscription: string,
  circlePicture: string,
  circlePictureFile: string,
  deepLink: string,
  isPublic: boolean,
  active: boolean,
  myCircle: boolean,
  totalMembers: number,
  totalItems: number,
  isAdmin: boolean,
  isOwner: boolean,
  isMember: boolean,
  rentOnly: boolean,
  pendingApproval: boolean,
  idCatLndCircleAddress: number,
  showCircleLocation: boolean,
  conditions: [],
  tags: string,
  creationDate: Date,
  modifyDate: Date
  lat: string,
  lon: string
  isCircleFeeActive: boolean,
  circleFee: number,
  hasPayOut: boolean,
  canUploadItem: number,
  totViews: number,
  address: string,
  lng: number,
  country: string,
  state: string,
  city: string,
  zipCode: string,
  street: string,
}

export const emptyAddEditCircleModel = (): AddEditCircleModel => ({

  idCatLndCircle: 0,
  name: '',
  dscription: '',
  circlePicture: '',
  circlePictureFile: '',
  deepLink: '',
  isPublic: false,
  active: true,
  myCircle: true,
  totalMembers: 0,
  totalItems: 0,
  isAdmin: false,
  isOwner: true,
  isMember: false,
  rentOnly: false,
  pendingApproval: false,
  idCatLndCircleAddress: 0,
  showCircleLocation: true,
  conditions: [],
  tags: '',
  creationDate: new Date(),
  modifyDate: new Date(),
  lat: '',
  lon: '',
  isCircleFeeActive: false,
  circleFee: 0,
  hasPayOut: false,
  canUploadItem: 0,
  totViews: 0,
  address: '',
  lng: 0.0,
  country: '',
  state: '',
  city: '',
  zipCode: '',
  street: '',
});

export interface CircleAddress {
  idCatLndCircleAddress: number,
  name: string,
  address: string,
  lat: number,
  lng: number,
  country: string,
  state: string,
  city: string,
  zipCode: string,
  street: string,
  active: boolean,
}

export const emptyCircleAddressModel = (): CircleAddress => ({
  idCatLndCircleAddress: 0,
  name: '',
  address: '',
  lat: 0.0,
  lng: 0.0,
  country: '',
  state: '',
  city: '',
  zipCode: '',
  street: '',
  active: true,
});

export interface circleItemElementModel {
  idCatLndCircleItem: number,
  idCatLndItem: number,
  itemPicture: string,
  active: boolean,
  blocked: boolean,
  memberItem: boolean,
  name: string,
  dscription: string,
  price: number,
  circlesAvailable: boolean,
  setPage: Function
}

export interface circleMemberElementModel {
  idCatLndCircleMember: number,
  idCatLndUser: number,
  memberName: string,
  memberPicture: string,
  totalMembers: number,
  isOwner: boolean,
  isAdmin: boolean,
  blocked: boolean,
  isMember: boolean,
  joinDate: string,
  isUserOwner: boolean,
  recommended: number,
  canEdit: boolean,
  rentOnly: boolean,
}

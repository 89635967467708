import { useEffect, useState } from 'react';
import { CameraAltRounded } from '@mui/icons-material';
import {
  Avatar,
  Badge,
  Box, Grid, Stack, TextField, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';
import LmiTheme from '../../../themes/lmi-mui-theme/lmi-theme';

function DialogProfile({
  completeName,
  imagePicture,
  updateInfo,
  kOnClickEditPhoto,
  triggerUpdatePicture,
}: any) {
  const { t } = useTranslation('global');
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));
  const limitDescription = 45;
  const fileTypes = [
    'JPG', 'JPEG',
    'PNG', 'WEBP',
    'BMP', 'TIFF',
  ];

  useEffect(() => {
    if (completeName) {
      validateFullName(completeName);
    }
  }, [completeName]);

  const [isFullNameValid, setIsFullNameValid] = useState(true);

  const validateFullName = (fullName: string) => {
    const isValid = (/[a-zA-Z]{1,}\s[a-zA-Z]{1,}‘?-?[a-zA-Z]{1,}\s?([a-zA-Z]{1,})?/).test(fullName);
    if (fullName.length == 0) {
      setIsFullNameValid(false);
    } else {
      setIsFullNameValid(isValid);
    }
  };

  const changeData = (e: any) => {
    validateFullName(e.target.value);
    updateInfo(e.target.value);
  };

  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid
          item
          xs={12}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <br />
          <Box sx={{ height: 10 }} />
          <Box>
            <FileUploader
              maxSize={256}
              multiple={false}
              handleChange={triggerUpdatePicture}
              name="file"
              types={fileTypes}
            >
              <Badge
                onClick={kOnClickEditPhoto}
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                sx={{ cursor: 'pointer' }}
                badgeContent={
                  ((
                    <Avatar
                      onClick={kOnClickEditPhoto}
                      sx={{
                        mt: -3,
                        ml: -3,
                        bgcolor: LmiTheme.light.accent,
                        width: 44,
                        height: 44,
                      }}
                    >
                      <CameraAltRounded />
                    </Avatar>
                  )
                  )
                }
              >
                <Avatar
                  alt="props.kName"
                  src={imagePicture}
                  sx={{
                    mt: isTablet ? -7 : -5,
                    width: isTablet ? 170 : 130,
                    height: isTablet ? 170 : 130,
                    border: `5px solid ${LmiTheme.light.systemBackground.elevation}`,
                    cursor: 'pointer',
                  }}
                />
              </Badge>
            </FileUploader>
          </Box>
          <Box>
            <Typography component="span" fontWeight={100} color={LmiTheme.light.accent}>To continue please upload a profile picture</Typography>
          </Box>
          <Box sx={{ height: 10 }} />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            pb={1}
          >
            <Box>
              <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
              <Typography component="span" fontWeight={500}>{t('listing_detail.full_name')}</Typography>
            </Box>
            <Typography component="span" color={LmiTheme.light.systemBackground.tertiary} />
          </Stack>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            name="completeName"
            placeholder={t('listing_detail.full_name')}
            value={completeName}
            error={!isFullNameValid}
            helperText={!isFullNameValid && t('profile.required_names')}
            multiline
            maxRows={4}
            onChange={changeData}
            sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
            inputProps={{
              maxLength: limitDescription,
            }}
          />

        </Grid>
      </Grid>
    </>
  );
}

export default DialogProfile;

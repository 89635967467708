import {
  Box,
  CardContent,
  Divider,
  Paper,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import MyCirclesListingContext from '../../../../../context/myCirclesListing/myCirclesListingContext';
import Heading from '../../AddCircle/Components/Heading/Heading';
import AboutMember from './Components/AboutMember/AboutMember';
import EditMember from './Components/EditMember/EditMember';
import MemberCircles from './Components/MemberCircles/MemberCircles';
import MemberDetailHeader from './Components/MemberDetailHeader/MemberDetailHeader';
import MemberListings from './Components/MemberListings/MemberListings';
import MemberReviews from './Components/MemberReviews/MemberReviews';

export default function MemberDetail(props: any) {
  const {
    circle,
  }: any = useContext(MyCirclesListingContext);

  const { t } = useTranslation('global');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <CardContent sx={{ p: 0 }}>
        <MemberDetailHeader />

        <Box sx={{ px: isMobile ? 2 : 4 }}>
          {!props.kOpensFromCircleDetail && (circle?.isOwner || circle?.isAdmin) && (
            <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
              <EditMember />
            </Paper>
          )}

          <Box mt="30px">
            <Heading kTitle={t('circles.members.about_me')} />
          </Box>
          <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
            <AboutMember />
          </Paper>

          <Box mt="30px">
            <Heading kTitle={t('circles.members.reviews')} />
          </Box>
          <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
            <MemberReviews />
          </Paper>

          <Box mt="30px">
            <Heading kTitle={t('circles.members.circles')} />
          </Box>
          <Paper variant="outlined" sx={{ borderRadius: '10px', pt: '5px', pb: '5px' }}>
            <MemberCircles />
          </Paper>

          <Box mt="30px">
            <Heading kTitle={t('circles.members.listings')} />
          </Box>
        </Box>
        <Divider />
        <Box sx={{ px: 2 }}>
          <MemberListings />
        </Box>

      </CardContent>
    </>

  );
}

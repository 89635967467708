/* eslint-disable react/jsx-props-no-spreading */
import TextField from '@mui/material/TextField';
import {
  Box,
  Grid, Link, Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import addEditItemContext from '../../../../../../../context/addEditItem/addEditItemContext';
import { getKeyValue } from '../../../../../../../services/common';
import { ServiceRoadieModel } from './ServiceRoadie.models';
import { LmiTheme } from '../../../../../../../components/Common';
import InputTextField from '../../../../../AddItem/Components/InputTextField/InputTextField';

export default function ServiceRoadie(props: ServiceRoadieModel) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const addEditContextLog = useContext(addEditItemContext);

  const {
    itemInfo,
    updateItemInfo,
    canModify,
    checkRoadie,
    updateValidationStatus,
  }: any = addEditContextLog;

  const initialStartTime = '2022-02-02T12:00';

  const initialEndTime = '2022-02-02T19:00';
  const [isLengthValid, setIsLengthValid] = useState(true);
  const [isWidthValid, setIsWidthValid] = useState(true);
  const [isHeightValid, setIsHeightValid] = useState(true);
  const [isWeightValid, setIsWeightValid] = useState(true);

  useEffect(() => {
    let isEverythingOk = true;
    if (checkRoadie) {
      if (itemInfo.roadie) {
        if (itemInfo.length === 0) {
          isEverythingOk = false;
          setIsLengthValid(false);
        }
        if (itemInfo.width === 0) {
          isEverythingOk = false;
          setIsWidthValid(false);
        }
        if (itemInfo.height === 0) {
          isEverythingOk = false;
          setIsHeightValid(false);
        }
        if (itemInfo.width === 0) {
          isEverythingOk = false;
          setIsWeightValid(false);
        }

        if (!isEverythingOk) { toast.warning(t('add_item_page.sizesRequired')); }
        updateValidationStatus(isEverythingOk);
      } else {
        updateValidationStatus(true);
      }
    }
  }, [checkRoadie]);

  const changeStartTime = (e: any) => {
    if (itemInfo.endDeliveryTime != '') {
      let endTime = itemInfo.endDeliveryTime;
      if (typeof endTime != 'object') {
        endTime = new Date(endTime);
      }
      const diff = Math.abs(endTime.getTime() - e.getTime()) / 3600000;
      if (diff < 4) {
        toast.error(t('add_item_page.error_time_period'));
      } else {
        const dateString = moment(e).format('YYYY-MM-DDTHH:mm');
        updateItemInfo({
          ...itemInfo,
          startDeliveryTime: dateString,
        });
      }
    }
  };

  const changeEndTime = (e: any) => {
    if (itemInfo.startDeliveryTime != '') {
      let startTime = itemInfo.startDeliveryTime;
      if (typeof startTime != 'object') {
        startTime = new Date(startTime);
      }
      const diff = Math.abs(startTime.getTime() - e.getTime()) / 3600000;
      if (diff < 4) {
        toast.error(t('add_item_page.error_time_period'));
      } else {
        const dateString = moment(e).format('YYYY-MM-DDTHH:mm');
        updateItemInfo({
          ...itemInfo,
          endDeliveryTime: dateString,
        });
      }
    }
  };

  const onChange = (e: any) => {
    const dataType = getKeyValue(itemInfo, e.target.name);

    let value;
    if (e.target.name === 'title') {
      e.target.value = e.target.value.substring(0, 200);
    }
    if (e.target.name === 'lastName') {
      e.target.value = e.target.value.substring(0, 200);
    }
    if (e.target.name === 'email') {
      e.target.value = e.target.value.substring(0, 200);
    }
    if (e.target.value == '') {
      value = 0;
    }

    switch (typeof (dataType)) {
      case 'number':
        value = parseFloat(e.target.value != '' ? e.target.value : 0);
        break;
      case 'boolean':
        value = e.target.checked;
        break;
      case 'undefined':
        value = parseFloat('0');
        break;
      default:
        value = e.target.value;
        break;
    }

    if (canModify) {
      updateItemInfo({
        ...itemInfo,
        [e.target.name]: value,
      });
    }
  };

  const { t } = useTranslation('global');

  return (
    <>
      { props.kAvailable ? (
        <Box pt={1} pb={0}>
          <Grid item xs={12}>
            <Typography py={1} fontSize="body2.fontSize" color={LmiTheme.light.systemBackground.tertiary}>
              {t('add_item_page.roadie_txt')}
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <InputTextField
                kInputId="length"
                kLabel={t('add_item_page.length')}
                kDisabled={itemInfo?.roadie != undefined ? !itemInfo.roadie : true}
                kInputValue={itemInfo?.length != undefined ? itemInfo.length : ''}
                kInputOnChange={onChange}
                kInputRequired={false}
                kInputName="length"
                kInputPlaceholder="0"
                kInputError={!isLengthValid}
                kInputTextAlign="center"
                kLabelColor={LmiTheme.light.systemBackground.secondary}
                kLabelCentered
                kLabelRequiredHide
                kInputMaxLength={7}
                kInputMaxLengthHide
                kInputType="text"
                kInputMultiline={false}
                kInputRows={1}
                kInputMaxRows={1}
              />
              <Stack direction="row" justifyContent="center">
                <Typography py={1} fontSize="body2.fontSize" color={LmiTheme.light.systemBackground.tertiary}>
                  {t('item_detail.inches')}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <InputTextField
                kInputId="width"
                kLabel={t('item_detail.width')}
                kDisabled={itemInfo?.roadie != undefined ? !itemInfo.roadie : true}
                kInputValue={itemInfo?.width != undefined ? itemInfo.width : ''}
                kInputOnChange={onChange}
                kInputRequired={false}
                kInputName="width"
                kInputPlaceholder="0"
                kInputTextAlign="center"
                kLabelColor={LmiTheme.light.systemBackground.secondary}
                kLabelCentered
                kLabelRequiredHide
                kInputMaxLength={7}
                kInputError={!isWidthValid}
                kInputMaxLengthHide
                kInputType="text"
                kInputMultiline={false}
                kInputRows={1}
                kInputMaxRows={1}
              />
              <Stack direction="row" justifyContent="center">
                <Typography py={1} fontSize="body2.fontSize" color={LmiTheme.light.systemBackground.tertiary}>
                  {t('item_detail.inches')}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <InputTextField
                kInputId="height"
                kLabel={t('item_detail.height')}
                kDisabled={itemInfo?.roadie != undefined ? !itemInfo.roadie : true}
                kInputValue={itemInfo?.height != undefined ? itemInfo.height : ''}
                kInputOnChange={onChange}
                kInputRequired={false}
                kInputName="height"
                kInputPlaceholder="0"
                kInputTextAlign="center"
                kLabelColor={LmiTheme.light.systemBackground.secondary}
                kLabelCentered
                kInputError={!isHeightValid}
                kLabelRequiredHide
                kInputMaxLength={7}
                kInputMaxLengthHide
                kInputType="text"
                kInputMultiline={false}
                kInputRows={1}
                kInputMaxRows={1}
              />
              <Stack direction="row" justifyContent="center">
                <Typography py={1} fontSize="body2.fontSize" color={LmiTheme.light.systemBackground.tertiary}>
                  {t('item_detail.inches')}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <InputTextField
                kInputId="weight"
                kLabel={t('item_detail.weight')}
                kDisabled={itemInfo?.roadie != undefined ? !itemInfo.roadie : true}
                kInputValue={itemInfo?.weight != undefined ? itemInfo.weight : ''}
                kInputOnChange={onChange}
                kInputRequired={false}
                kInputName="weight"
                kInputPlaceholder="0"
                kInputError={!isWeightValid}
                kInputTextAlign="center"
                kLabelColor={LmiTheme.light.systemBackground.secondary}
                kLabelCentered
                kLabelRequiredHide
                kInputMaxLength={7}
                kInputMaxLengthHide
                kInputType="text"
                kInputMultiline={false}
                kInputRows={1}
                kInputMaxRows={1}
              />
              <Stack direction="row" justifyContent="center">
                <Typography py={1} fontSize="body2.fontSize" color={LmiTheme.light.systemBackground.tertiary}>
                  {t('item_detail.pounds')}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography py={1} fontSize="body2.fontSize" color={LmiTheme.light.systemBackground.tertiary}>
              {t('add_item_page.roadie_txt2')}
            </Typography>
          </Grid>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid
              container
              spacing={2}
              sx={{ mt: 0 }}
            >
              <Grid item xs={isMobile ? 6 : 5}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                  <Typography fontSize="body2.fontSize" fontWeight={700} color={LmiTheme.light.systemBackground.secondary}>
                    {t('item_detail.start_time')}
                  </Typography>
                  <TimePicker
                    cancelText={t('global.cancel')}
                    toolbarTitle={t('global.select_time')}
                    minTime={new Date(0, 0, 0, 8, 0)}
                    maxTime={new Date(0, 0, 0, 19, 0)}
                    onOpen={() => {
                      setTimeout(() => {
                        const el = document.activeElement;
                        if (el) {
                          (el as HTMLElement).blur();
                        }
                      });
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        variant="outlined"
                        sx={{
                          bgcolor: LmiTheme.light.systemBackground.base,
                          maxWidth: '170px',
                          textAlign: 'center',
                        }}
                        {...params}
                      />
                    )}
                    value={itemInfo?.startDeliveryTime != '' ? itemInfo.startDeliveryTime : initialStartTime}
                    disabled={props.kIsReadOnly}
                    onChange={(newValue: any) => {
                      if (canModify) { changeStartTime(newValue); }
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={isMobile ? 6 : 5}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                  <Typography fontSize="body2.fontSize" fontWeight={700} color={LmiTheme.light.systemBackground.secondary}>
                    {t('item_detail.end_time')}
                  </Typography>
                  <TimePicker
                    minTime={new Date(0, 0, 0, 12)}
                    maxTime={new Date(0, 0, 0, 23, 59)}
                    onOpen={() => {
                      setTimeout(() => {
                        const el = document.activeElement;
                        if (el) {
                          (el as HTMLElement).blur();
                        }
                      });
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        variant="outlined"
                        sx={{
                          bgcolor: LmiTheme.light.systemBackground.base,
                          maxWidth: '170px',
                          textAlign: 'center',
                        }}
                        {...params}
                      />
                    )}
                    value={itemInfo?.endDeliveryTime != '' ? itemInfo.endDeliveryTime : initialEndTime}
                    disabled={props.kIsReadOnly}
                    onChange={(newValue: any) => {
                      if (canModify) { changeEndTime(newValue); }
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
          </LocalizationProvider>
          <Grid item xs={12}>
            <Typography py={2} fontSize="body2.fontSize" color={LmiTheme.light.systemBackground.tertiary}>
              {t('add_item_page.roadie_txt3')}
              <Link href="/terms" rel="noopener noreferrer" underline="hover">{t('add_item_page.roadie_txt4')}</Link>
              {t('add_item_page.roadie_txt5')}
            </Typography>
          </Grid>
        </Box>
      ) : null }
    </>
  );
}

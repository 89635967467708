import {
  Button,
  FormControl, MenuItem, Select, Typography, Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LmiTheme } from '../../../../../../../components/Common';

export default function ListingStatus(props: any) {
  const updateFields = props.updateStatus;
  const disableDropdown = props.itemInfo?.idCatLndItem == 0;
  const { t } = useTranslation('global');
  return (
    <Box mb={{ xs: 0, md: 0 }}>
      <FormControl fullWidth>
        <Select
          disabled={disableDropdown}
          value={props.itemInfo?.itemStatus ?? 0}
          onChange={updateFields}
          sx={{ bgcolor: LmiTheme.light.systemBackground.base }}
        >
          <MenuItem value={1}>{t('listing_status.list_everywhere')}</MenuItem>
          <MenuItem value={2}>{t('listing_status.circles_only')}</MenuItem>
          <MenuItem value={3}>{t('listing_status.explore_only')}</MenuItem>
          <MenuItem value={0}>{t('listing_status.unavailable')}</MenuItem>
          <MenuItem value={4}>{t('listing_status.deleteListing')}</MenuItem>
        </Select>
      </FormControl>

      {props.itemInfo?.idCatLndItem != 0 ? (
        <Typography fontSize="subtitle2.fontSize" fontWeight={500} py={3} color={LmiTheme.light.systemBackground.tertiary}>

          {
            (props.itemInfo?.itemStatus == 1) && t('listing_status.subtitle_1')
          }
          {
            (props.itemInfo?.itemStatus == 2) && t('listing_status.circles_only_text')
          }
          {
            (props.itemInfo?.itemStatus == 3) && t('listing_status.explore_only_text')
          }
          {
            (props.itemInfo?.itemStatus == 0) && t('listing_status.unavailable_text')
          }
          {
            (props.itemInfo?.itemStatus == 4) && t('listing_status.deleteListingMessage')
          }
        </Typography>
      ) : (
        <Typography fontSize="subtitle2.fontSize" fontWeight={500} py={3} color={LmiTheme.light.systemBackground.tertiary}>
          {t('listing_status.subtitle_2')}
        </Typography>
      )}
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Button
          disableElevation
          size="large"
          variant="contained"
          onClick={props.kSaveItem}
          sx={{
            p: 1.5,
            borderRadius: 3,
            width: '100%',
            maxWidth: '375px',
          }}
        >
          {t('global.save')}
        </Button>
      </Box>

    </Box>

  );
}

import { LmiTheme } from '..';

const CopyrightStyle = {
  borderTop: `1px solid${LmiTheme.light.systemBackground.separator}`,
  background: LmiTheme.light.systemBackground.base,
  '.lmiw-stackcontent': {
    minHeight: { xs: '120px', md: '80px' },
    '.lmiw-fabicon-social-footer': {
      boxShadow: 'none',
      color: LmiTheme.light.systemBackground.primary,
      backgroundColor: LmiTheme.light.systemBackground.solid,
      border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
    },
    '.lmiw-rights-reserved': {
      textAlign: { xs: 'center', md: 'right' },
      color: LmiTheme.light.systemBackground.secondary,
      fontSize: '14px',
      fontWeight: '400',
      width: '100%',
      marginRight: { xs: '0px', md: '70px', xl: '0' },
      maxWidth: { xs: '250px', sm: '100%' },
    },
  },

};

export default CopyrightStyle;

import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  ClickAwayListener, Fab, Menu, MenuItem,
} from '@mui/material';
import i18next from 'i18next';
import cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import authContext from '../../../context/authentication/authContext';
import homeContext from '../../../context/home/homeContext';
import { ChangeLocation } from '..';
import { LocationModel } from '../../../context/home/models/locationModel';
import publicProfileContext from '../../../context/publicProfile/publicProfileContext';
import ListUserMenu from './Components/ListUserMenu/ListUserMenu';
import BtnUser from './Components/BtnUser/BtnUser';
import ListUserMenuStyle from './Components/ListUserMenu/ListUserMenu.style';
import { ReactComponent as GlobeIcon } from '../../../assets/icons/globe.svg';
import BtnTranslationStyle from './Components/BtnTranslation/BtnTranslation.style';
import BtnGuestUser from './Components/BtnGuestUser/BtnGuestUser';

export default function UserMenu(props: any) {
  const {
    token,
  }: any = useContext(authContext);
  const {
    setLocation,
    address,
    lat,
    long,
  }: any = useContext(homeContext);

  const { t } = useTranslation('global');

  const {
    privateProfileInfo,
  }: any = useContext(publicProfileContext);

  const [anchorEl, setAnchorEl] = React.useState<false | HTMLElement>(false);
  const open = Boolean(anchorEl);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const locationModel: LocationModel = {
      lat: parseFloat(lat),
      long: parseFloat(long),
    };
    setLocation(locationModel);
  }, [address]);

  function changeLocation() {
    setOpenModal(!openModal);
  }

  const [anchorElement, setAnchorElement] = useState(null);
  const openTranslationMenu = Boolean(anchorElement);
  const handleClick = (e: any) => {
    setAnchorElement(e.currentTarget);
  };
  const handleCloseEn = () => {
    setAnchorElement(null);
    i18next.changeLanguage('en');
  };
  const handleCloseEs = () => {
    setAnchorElement(null);
    i18next.changeLanguage('es');
  };

  const [localStorageUserName, setLocalStorageUserName] = useState(privateProfileInfo.completeName ? privateProfileInfo.completeName : localStorage.getItem('name'));
  const [localStorageUserImage, setLocalStorageUserImage] = useState(privateProfileInfo.profilePicture ? privateProfileInfo.profilePicture : localStorage.getItem('profilePicture'));

  useEffect(() => {
    if (privateProfileInfo.completeName) {
      localStorage.setItem('name', privateProfileInfo.completeName);
      setLocalStorageUserName(privateProfileInfo.completeName);
    }

    if (privateProfileInfo.profilePicture) {
      localStorage.setItem('profilePicture', privateProfileInfo.profilePicture);
      setLocalStorageUserImage(privateProfileInfo.profilePicture);
    }
  }, [localStorageUserName, localStorageUserImage, privateProfileInfo.completeName]);

  const history = useHistory();

  const BtnLoginStyle = {
    height: '44px',
    fontWeight: '600',
    borderRadius: '50px',
    minWidth: { sm: '130px', xs: '90px' },
    '&.lmiw-btn-login': {
      textTransform: 'capitalize',
    },
  };

  return (
    <>
      {
        token && (
          <BtnUser
            kOpenMenu={(event) => { setAnchorEl(event.currentTarget); }}
            kChangeLocation={() => changeLocation()}
            kUserName={localStorageUserName}
            kProfilePicture={localStorageUserImage}
            kAddress={address}
            kType={props.kType}
          />
        )
      }
      {
        !token && (
          <Button
            sx={BtnLoginStyle}
            onClick={() => history.push('/listing', { public: true })}
            variant="contained"
            fullWidth={false}
            color="primary"
            className="lmiw-btn-login"
          >
            {t('listing.start_listing')}
          </Button>
        )
      }

      <ListUserMenu
        kOpen={open}
        kClose={() => setAnchorEl(false)}
        kEven={anchorEl}
        kChangeLocation={() => changeLocation()}
      />
      <ChangeLocation
        open={openModal}
        kClose={() => changeLocation()}
        kChangeLocation={(location: LocationModel) => setLocation(location)}
        kLat={lat}
        kLon={long}
        kAddress={address}
      />
      {!token && (
        <>
          <ClickAwayListener
            aria-expanded={openTranslationMenu ? 'true' : undefined}
            aria-haspopup="true"
            aria-controls="translations"
            variant="contained"
            onClickAway={() => setAnchorElement(null)}
            sx={{ display: { xs: 'none', sm: 'flex' } }}
          >
            <Fab
              onClick={handleClick}
              sx={BtnTranslationStyle}
            >
              <GlobeIcon />
            </Fab>
          </ClickAwayListener>
          <Menu
            id="translations"
            anchorEl={anchorElement}
            open={openTranslationMenu}
            sx={ListUserMenuStyle}
            PaperProps={{
              elevation: 0,
            }}
          >
            <MenuItem onClick={handleCloseEn} disabled={cookies.get('i18next') === 'en'}>English</MenuItem>
            <MenuItem onClick={handleCloseEs} disabled={cookies.get('i18next') === 'es'}>Español</MenuItem>
          </Menu>

          <BtnGuestUser kChangeLocation={() => changeLocation()} />
        </>
      )}

    </>
  );
}

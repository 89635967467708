/* eslint-disable no-nested-ternary */
import {
  Box, Button, Stack, InputBase, Typography, InputAdornment, CircularProgress, Popover, Alert,

} from '@mui/material';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import React, { useEffect, useState } from 'react';
import { ContentLayout, LmiTheme } from '../../../../../components/Common';
import subscription from '../../../../../assets/subscription_for_news/bgcanoe.jpg';

export default function SubscriptionForNews() {
  const { t } = useTranslation('global');
  const url = 'https://lendmeit.us19.list-manage.com/subscribe/post?u=4cda00e301f75163f28557cfa&amp;id=c0c53e528d';

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        height: '600px',
        position: 'relative',
        backgroundImage: `url(${subscription})`,
        backgroundSize: 'cover',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          background: 'rgb(0 0 0 / 20%)',
          zIndex: 1,
        }}
      />
      <ContentLayout>
        <Box sx={{
          zIndex: 2,
          position: 'relative',
        }}
        >
          <Typography
            variant="h4"
            color={LmiTheme.light.systemBackground.elevation}
            sx={{
              textAlign: 'center',
              mb: 2,
              fontWeight: 'bold',
            }}
          >
            {t('suscribe.sign_up_txt')}
          </Typography>
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <CustomForm
                status={status}
                message={message}
                onValidated={(formData: any) => subscribe(formData)}
              />
            )}
          />
        </Box>
      </ContentLayout>
    </Stack>

  );
}

// eslint-disable-next-line react/prop-types
const CustomForm = ({ status, message, onValidated }: any) => {
  const { t } = useTranslation('global');
  const [inputEmail, setInputEmail] = useState('');
  const [messageText, setMessageText] = useState('');
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    console.log(inputEmail);
    console.log(event.currentTarget);
    setAnchorEl(null);
    submit(inputEmail);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const submit = async (email: string) => {
    const isValid = email && email.indexOf('@') > -1 && onValidated({
      EMAIL: email,
    });

    if (isValid != false) {
      const sended = await emailjs.send('service_0lj4_subscribe', 'template_j9prdui', {
        from_name: 'subscribe@moddtech.com',
        to_name: email,
        message: `Hi i am subscribe ${process.env.REACT_APP_EMAILJS_SERVICE_TEST_MESSAGE}`,
      }, process.env.REACT_APP_EMAILJS_SERVICE!);
      if (sended.status == 200) {
        toast.success(t('suscribe.success'));
        setInputEmail('');
      } else {
        toast.warning(t('suscribe.warning'));
      }
    }

    return isValid;
  };

  useEffect(() => {
    if (message) {
      setMessageText(message);
    }
  }, [message]);

  return (
    <>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <InputBase
          aria-describedby="inputEmail"
          id="outlined-basic"
          fullWidth
          placeholder={t('suscribe.email')}
          value={inputEmail}
          onChange={(e:any) => setInputEmail(e.target.value)}
          // ref={(node) => (setEmail(node))}
          endAdornment={<InputAdornment position="end">{status === 'sending' && <CircularProgress size="25" /> }</InputAdornment>}
          type="email"
          sx={{
            px: 4,
            height: 70,
            maxWidth: '500px',
            borderRadius: 2,
            borderColor: 'white',
            background: 'white',
          }}
        />
        <Button
          sx={{
            minWidth: '150', height: 70, color: 'white', borderColor: 'white',
          }}
          variant="outlined"
          size="large"
          onClick={(e) => { handleClick(e); }}
        >
          {t('suscribe.submit')}
        </Button>
      </Stack>
      <Popover
        id="inputEmail"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Alert severity={status === 'error' ? 'error' : status === 'success' ? 'success' : 'info'}>{messageText}</Alert>

      </Popover>
      <Box sx={{ p: { color: 'white' } }}>
        {/* {status === 'sending' && <Typography component="p">sending...</Typography>} */}
        {/* {status === 'error' && (<Typography component="p" dangerouslySetInnerHTML={{ __html: message }} />)}
        {status === 'success' && (<Typography component="p" dangerouslySetInnerHTML={{ __html: message }} />)} */}
      </Box>
    </>

  );
};

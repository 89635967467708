import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CardContent,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  DialogCommon,
  LmiTheme,
  MainInformationTabPanel,
} from '..';
import DialogChangePassword from './DialogChangePassword';
import { TabPanelChangePasswordModels } from './TabPanelChangePassword.models';
import publicProfileContext from '../../../../../context/publicProfile/publicProfileContext';
// import authContext from '../../../../../context/authentication/authContext';

function TabPanelChangePassword(props: TabPanelChangePasswordModels) {
  // const authContextLog = useContext(authContext);
  // const {
  //   changePassword,
  // }: any = authContextLog;
  const pPublicProfileContext = useContext(publicProfileContext);

  const {
    changePasswordInfo,
    resetChangePasswordInfo,
    changePassword,
    closeModal,
  }: any = pPublicProfileContext;
  const [openDialogChangePassword, setOpenDialogChangePassword] = useState(false);
  const clickOpenDialogChangePassword = () => setOpenDialogChangePassword(true);
  const clickCloseDialogChangePassword = () => setOpenDialogChangePassword(false);

  const saveChangePassword = () => {
    if (changePasswordInfo?.newPass == changePasswordInfo?.newPassConfirm) {
      changePassword(changePasswordInfo);
    } else {
      toast.error(t('profile.password_error'));
    }
    // if (isChangedPass) {
    // }
  };
  useEffect(() => {
    if (closeModal) {
      setOpenDialogChangePassword(false);
      resetChangePasswordInfo();
      toast.success(t('profile.password_update_success'));
    }
  }, [closeModal]);

  const { t } = useTranslation('global');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <MainInformationTabPanel
        kTabValue={props.kTabValue}
        kTabIndex={props.kTabIndex}
        kContent={
          (
            <>
              <CardContent sx={{ p: 3 }}>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={3}
                  py={3}
                >
                  <Box
                    sx={{
                      fontSize: 'subtitle2.fontSize',
                      fontWeight: 500,
                      color: LmiTheme.light.systemBackground.tertiary,
                      maxWidth: '90%',
                      textAlign: 'center',
                      pb: 2,
                    }}
                  >
                    {t('profile.change_password?')}
                  </Box>
                  <Button
                    disableElevation
                    variant="outlined"
                    size="large"
                    onClick={clickOpenDialogChangePassword}
                    sx={{
                      px: 3,
                      fontSize: 'subtitle1.fontSize',
                      textTransform: 'none',
                      fontWeight: 'bold',
                      borderRadius: '10px',
                    }}
                  >
                    {t('profile.change_password')}
                  </Button>
                </Stack>
              </CardContent>
              <DialogCommon
                kTitle={t('profile.change_password')}
                kMaxWidth="sm"
                kMaxHeight="550px"
                kAlignBtn="center"
                kBorderRadius={isMobile ? 0 : '10px'}
                kFixedDialog={0}
                kFixedDialogPosition="fixed"
                kDisableBottomDivider
                kKeepBtnSize
                kTopDivider
                kSlideTransition={isMobile}
                kOpenDialogLists={openDialogChangePassword}
                kOnClose={clickCloseDialogChangePassword}
                kOnSecondary={clickCloseDialogChangePassword}
                kOnPrimary={saveChangePassword}
                kDisableBtnSave={
                  changePasswordInfo == undefined
                  || !changePasswordInfo?.oldPassword
                  || !changePasswordInfo?.newPass
                  || !changePasswordInfo?.newPassConfirm
                  || changePasswordInfo?.oldPassword?.length < 8
                  || changePasswordInfo?.newPass?.length < 8
                  || changePasswordInfo?.newPassConfirm?.length < 8
                }
                kContent={
                  (
                    <DialogChangePassword />
                  )
                }
              />
            </>
          )
        }
      />
    </>
  );
}

export default TabPanelChangePassword;

/* eslint-disable import/no-unresolved */
import * as React from 'react';
import IMask from 'imask';
import { IMaskInput } from 'react-imask';
import NumberFormat from 'react-number-format';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

interface CustomProps {
  // eslint-disable-next-line no-unused-vars
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const TextCardNumber = React.forwardRef<HTMLElement, CustomProps>(
  (props: any, ref: any) => {
    const { onChange, ...other }: any = props;
    return (
      <IMaskInput
      // eslint-disable-next-line react/jsx-props-no-spreading
        {...other}
        mask="0000 0000 0000 0000"
        definitions={{
          '#': /[1-9]/,
        }}
        getInputRef={ref}
          // getInputRef={ref}
        onAccept={(value: any) => {
          onChange({ target: { name: props.name, value } });
        }}
        overwrite
      />
    );
  },
);

export const TextExpireDate = React.forwardRef<HTMLElement, CustomProps>(
  (props: any, ref: any) => {
    const { onChange, ...other }: any = props;
    return (
      <IMaskInput
      // eslint-disable-next-line react/jsx-props-no-spreading
        {...other}
        // pattern="0000"
        mask="MM/YY"
        // definitions={{
        //   '#': /[0-9]/,
        // }}
        blocks={{
          YY: { mask: IMask.MaskedRange, from: 0, to: 99 },
          MM: { mask: IMask.MaskedRange, from: 1, to: 12 },
        }}
        getInputRef={ref}
          // getInputRef={ref}
        onAccept={(value: any) => {
          onChange({ target: { name: props.name, value } });
        }}
        overwrite
      />
    );
  },
);

export const TextCVCMask = React.forwardRef<HTMLElement, CustomProps>(
  (props: any, ref: any) => {
    const { onChange, ...other }: any = props;
    return (
      <IMaskInput
      // eslint-disable-next-line react/jsx-props-no-spreading
        {...other}
        mask="0000"
        definitions={{
          '#': /[0-9]/,
        }}
        getInputRef={ref}
        onAccept={(value: any) => {
          onChange({ target: { name: props.name, value } });
        }}
        overwrite
      />
    );
  },
);

export const TextMaskPhone = React.forwardRef<HTMLElement, CustomProps>(
  (props: any, ref: any) => {
    const { onChange, ...other }: any = props;
    return (
      <IMaskInput
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...other}
        mask="#000000000"
        definitions={{
          '#': /[1-9]/,
        }}
        getInputRef={ref}
        onAccept={(value: any) => {
          onChange({ target: { name: props.name, value } });
        }}
        overwrite
      />
    );
  },
);

export const NumberFormatCustom = React.forwardRef<NumberFormat<string>, CustomProps>(
  (props, ref) => {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix="$"
      />
    );
  },
);

export interface StateInputsModel {
  textmask: string;
  numberformat: string;
}

// EXAMPLES
export default function FormattedInputs() {
  const [values, setValues] = React.useState<StateInputsModel>({
    textmask: '',
    numberformat: '1320',
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Box
      sx={{
        '& > :not(style)': {
          m: 1,
        },
      }}
    >
      <FormControl variant="standard">
        <InputLabel htmlFor="formatted-text-mask-input">react-imask</InputLabel>
        <Input
          value={values.textmask}
          onChange={handleChange}
          name="textmask"
          id="formatted-text-mask-input"
          inputComponent={TextMaskPhone as any}
        />
      </FormControl>
      <TextField
        label="react-number-format"
        value={values.numberformat}
        onChange={handleChange}
        name="numberformat"
        id="formatted-numberformat-input"
        InputProps={{
          inputComponent: NumberFormatCustom as any,
        }}
        variant="standard"
      />
    </Box>
  );
}

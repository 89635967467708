/* eslint-disable max-lines */
// import React from 'react';
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  Card, CardContent, Divider, Stack, Collapse,
  Grid, useMediaQuery, useTheme, ToggleButton,
  ToggleButtonGroup, Box, Accordion, AccordionDetails, AccordionSummary, IconButton, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ArrowBackIosRounded, Favorite, FavoriteBorder,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';

import {
  ItemViews,
  AboutLender,
  PickupLocation,
  Availability,
  DeliveryOptions,
} from './Components/index';
import {
  RENT,
  BUY,
  PRECING_HOURLY,
} from '../../../types';
import {
  SectionTitle, IconLinkButton, SectionDescription, SpecialInstructions, AccessoriesOptions,
  ItemLocation, ContentLayout, MapComponent,
  DialogCommon,
  LmiTheme,
  BottomBarSummary,
  Summary,
} from '../../../components/Common';
import bookItemContext from '../../../context/bookItem/bookContext';
import publicProfileContext from '../../../context/publicProfile/publicProfileContext';
import SwiperThumbnails from '../../../components/Common/SwiperThumbnails/SwiperThumbnails';
import AppBarSecondary from '../../../components/Layout/AppBarSecondary/AppBarSecondary';
import { HeaderTitle } from '../ProfilePublic/Components';
import ShareButtons from '../../BackOffice/Listing/Components/AddItem/Components/ShareButtons/ShareButtons';
import LoadingLayout from '../../../components/Common/LoadingLayout/LoadingLayout';
import DialogProfile from '../../../components/Common/Profile/dialogProfile';
import { onImageDropped } from '../../../services/common';
import OtherListingsCarousel from './Components/OtherListingsCarousel/OtherListingsCarousel';
import IdVerification from './Components/IdVerification/IdVerification';
import shuftiProContext from '../../../context/shuftiPro/shuftiProContext';

function BookItemPage() {
  const history = useHistory();
  const { state }: any = history.location;
  const mountedRef = useRef(false);

  if (!state) {
    history.push('/');
  }

  const handleBackButtonClick = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push('/search');
    }
  };

  const pShuftiProContext = useContext(shuftiProContext);
  const {
    shuftiUrl,
  }: any = pShuftiProContext;

  const [openShuftiIframe, setOpenShuftiIframe] = useState(false);
  const clickCloseShuftiIframe = () => setOpenShuftiIframe(false);

  const theme = useTheme();
  const isMobileBr = useMediaQuery(theme.breakpoints.down('sm'));

  const [renderIframe, setRenderIframe] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));
  const [open, setOpen] = useState(false);
  const handleModalToggle = () => {
    setOpen(!open);
  };

  const bookContext = useContext(bookItemContext);
  const pProfileContext = useContext(publicProfileContext);

  const {
    images,
    accesories,
    bookDetails,
    condictions,
    getBookItemDetails,
    funBookType,
    bookType,
    loading,
    precingType,
    editAccesory,
    createItemFavorite,
    // clearData,
    validateBookNow,
    setBookItemDetails,
    completeProfile,
    closePendingInfo,
    changeDeliveryType,
    deliveryType,
    // showAcceptDistance,
    showAcceptDistanceModal,
    closeShowAcceptDistance,
    showNeedsVerifyModal,
    closeNeedsVerifyModal,
    getLenderItems,
    setDateRange,
    availability: {
      dateRange,
    },
    setViewedItem,
  }: any = bookContext;

  const {
    privateProfileInfo,
    updateProfileInfo,
    uploadUpdateProfilePicture,
    updateUserNameAndDescription,
    newFullName,
    updateCompleteName,
  }: any = pProfileContext;

  const { roadie, isLenderDelivery } = bookDetails;

  window.onpopstate = async () => {
    switch (history.location.pathname) {
      case '/bookitem':
        const bookState: any = history.location.state;
        if (bookState?.bookItem?.idCatLndItem && bookState?.bookItem?.idCatLndItem != bookDetails?.idCatLndItem) {
          await getBookItemDetails({ idCatLndItem: bookState?.bookItem?.idCatLndItem, idCatLndCircle: 0 });
        } else if (bookState?.bookItem?.bookDetails?.idCatLndItem && bookState?.bookItem?.bookDetails?.idCatLndItem != bookDetails?.idCatLndItem) {
          await getBookItemDetails({ idCatLndItem: bookState?.bookItem?.bookDetails?.idCatLndItem, idCatLndCircle: 0 });
        } else history.push('/search');
        break;
      case '/checkout':
        const checkoutState: any = history.location.state;
        if (checkoutState?.bookDetails.idCatLndItem != bookDetails?.idCatLndItem) {
          await getBookItemDetails({ idCatLndItem: checkoutState?.bookDetails.idCatLndItem, idCatLndCircle: 0 });
        }
        changeDeliveryType(checkoutState?.deliveryType);
        checkoutState?.accesories.forEach((accesorie: any) => (accesorie?.isAdded ?? editAccesory({ checked: true, idRelItemAccesorie: accesorie?.idRelItemAccesorie })));
        setDateRange(checkoutState?.dateRange);
        break;
      default:
        break;
    }
    // clearData();
  };

  const { bookItem }: any = state ?? { bookItem: { idCatLndItem: 0 } };

  const categoryDesc = bookDetails?.category ?? bookItem?.categoryDesc;

  const [openDistanceModal, setOpenDistanceModal] = useState(false);
  const [openNeedsVerifyModal, setOpenNeedsVerifyModal] = useState(false);
  const [roadieVisibleFromPhoneCode, setRoadieVisibleFromPhoneCode] = useState(true);
  const storageIdItem = localStorage.getItem('storageIdItem');
  const storageDeliveryType = localStorage.getItem('storageDeliveryType');
  const storageAccesories: any = localStorage.getItem('storageAccesories');

  useEffect(() => {
    if (bookDetails.idCatLndItem == storageIdItem) {
      if (storageDeliveryType != null) { changeDeliveryType(storageDeliveryType); }
      if (storageAccesories != null) {
        const updateAccessory: any = storageAccesories.split(',');
        updateAccessory.forEach((idRelItemAccesorie: any) => editAccesory({ checked: true, idRelItemAccesorie }));
      }
    }
  }, [bookDetails]);

  useEffect(() => {
    // Phone Code validation
    const renterPhoneNumb = bookDetails?.renterPhoneNumber;
    if (renterPhoneNumb.length > 0) {
      const phoneCode = renterPhoneNumb.slice(0, -10);
      if (phoneCode != '+1') {
        setRoadieVisibleFromPhoneCode(false);
      }
    }
  }, [bookDetails?.renterPhoneNumber]);

  useEffect(() => {
    if (bookDetails.idLenderUser) {
      getLenderItems(bookDetails.idLenderUser);
      setViewedItem(bookDetails.idCatLndItem);
    }
  }, [bookDetails.idLenderUser]);

  const finishVerification = () => {
    setRenderIframe(false);
    // checkConfirmationStatusId(true, privateProfileInfo?.email, privateProfileInfo?.userName);
    clickCloseShuftiIframe();
    continueBookAfterIdVerif();
  };

  useEffect(() => {
    setOpenDistanceModal(showAcceptDistanceModal);
  }, [showAcceptDistanceModal]);

  useEffect(() => {
    setOpenNeedsVerifyModal(showNeedsVerifyModal);
  }, [showNeedsVerifyModal]);

  // eslint-disable-next-line no-nested-ternary
  const id = bookItem.idCatLndItem > 0 ? bookItem.idCatLndItem : (bookItem.bookDetails ? bookItem.bookDetails.idCatLndItem : 0);

  useEffect(() => {
    if (!mountedRef.current) {
      if (loading && bookDetails.idCatLndItem == 0) {
        (async function fetchBookItemDetails() {
          if (bookItem.bookDetails && (!state?.roadie || Object.keys(state?.roadie)?.length == 0) && (!state?.pickupLender || Object.keys(state?.pickupLender).length == 0)) {
            // await setBookItemDetails(bookItem);
            await setBookItemDetails(bookItem);
          } else if (state?.roadie && Object.keys(state?.roadie)?.length > 0) {
            await setBookItemDetails(state);
          } else if (state?.pickupLender && Object.keys(state?.pickupLender)?.length > 0) {
            await setBookItemDetails(state);
          } else {
            await getBookItemDetails({ idCatLndItem: id, idCatLndCircle: state?.idCatLndCircle ?? 0 });
          }
        }());
      }
    }

    return () => {
      mountedRef.current = false;
    };
  }, [loading]);

  useEffect(() => {
    if (bookItem?.categoryDesc == '' || !bookItem?.categoryDesc) {
      (async function fetchBookItemDetails() {
        await getBookItemDetails({ idCatLndItem: id, idCatLndCircle: state?.idCatLndCircle ?? 0 });
      }());
    }
  }, []);

  const mapType = (
    <MapComponent
      kDraggable={false}
      state={{
        lat: bookDetails?.lat,
        long: bookDetails?.lon,
      }}
      exactAddress={bookDetails?.exactAddress}
    />
  );

  const updatePicture = (result: string) => {
    uploadUpdateProfilePicture(result);
    updateProfileInfo({
      ...privateProfileInfo,
      profilePicture: result,
    });
  };

  const clickSaveFullNameEdit = async (test: any) => {
    await updateUserNameAndDescription({
      description: privateProfileInfo?.dscription,
      userName: newFullName,
    });

    await updateProfileInfo({
      ...privateProfileInfo,
      completeName: newFullName,
    });

    closePendingInfo(test);
  };

  const continueBookAfterIdVerif = async () => {
    closeNeedsVerifyModal();
    const { redirect } = await validateBookNow(true, true);
    // const stateRedirect = toState;
    if (redirect && redirect != '') {
      history.push(redirect, {
        accesories,
        bookDetails,
        deliveryType,
        dateRange,
      });
    }
  };

  const continueBook = async () => {
    const { redirect } = await validateBookNow();
    // const stateRedirect = toState;
    if (redirect && redirect != '') {
      history.push(redirect, {
        accesories,
        bookDetails,
        deliveryType,
        dateRange,
      });
    }
  };

  const acceptDistanceBook = async () => {
    const { redirect } = await validateBookNow(true);
    // const stateRedirect = toState;
    closeShowAcceptDistance();
    setOpenDistanceModal(false);
    if (redirect && redirect != '') {
      history.push(redirect, {
        accesories,
        bookDetails,
        deliveryType,
        dateRange,
      });
    }
  };

  const { t } = useTranslation('global');

  return (
    <>
      {bookDetails
        ? (
          <DialogCommon
            kTitle={t('listing_detail.item_location')}
            kMaxWidth="lg"
            kOpenDialogLists={open}
            kOnClose={handleModalToggle}
            kDisableActions
            kContent={mapType}
          />

        ) : null}

      <DialogCommon
        kTitle="Profile update"
        kMaxWidth="sm"
        kOpenDialogLists={completeProfile.openModal}
        kOnClose={closePendingInfo}
        kOnPrimary={() => clickSaveFullNameEdit('ttest')}
        kContent={
          (
            <DialogProfile
              dataInfo={completeProfile}
              imagePicture={privateProfileInfo?.profilePicture}
              completeName={newFullName}
              updateInfo={updateCompleteName}
              triggerUpdatePicture={(e: any) => onImageDropped(e, updatePicture)}
            />
          )
        }
      />
      <DialogCommon
        kTitle="Book Item"
        kMaxWidth="sm"
        kOpenDialogLists={openDistanceModal}
        kOnClose={closeShowAcceptDistance}
        kOnPrimary={() => acceptDistanceBook()}
        kPrimaryBtnText="Continue"
        kSecondaryBtnText="Cancel"
        kOnSecondary={closeShowAcceptDistance}
        kContent={
          (
            <Typography>
              {t('book_state.item_more_than')}
              {' '}
              50 miles
              {' '}
              {t('book_state.item_more_than3')}
            </Typography>
          )
        }
      />
      <DialogCommon
        kTitle={t('listing_detail.verify_id')}
        kMaxWidth="sm"
        kOpenDialogLists={openNeedsVerifyModal}
        kOnClose={closeNeedsVerifyModal}
        kBorderRadius={isMobile ? 0 : '10px'}
        kKeepBtnSize
        kTopDivider
        kDisableBottomDivider
        kOnPrimary={() => {
          closeNeedsVerifyModal();
          setRenderIframe(true);
          setOpenShuftiIframe(true);
        }}
        kPrimaryBtnText={t('listing_detail.verify_id')}
        kSecondaryBtnText={t('listing_detail.skip')}
        kOnSecondary={continueBookAfterIdVerif}
        kContent={
          (
            <Box>
              <Typography sx={{ fontSize: 18 }}>
                {t('listing_detail.requires_id_verification')}
              </Typography>
              <Typography sx={{ fontSize: 14 }}>
                {t('listing_detail.requires_idv_2')}
              </Typography>
            </Box>

          )
        }
      />
      { renderIframe ? (
        <DialogCommon
          kTitle={t('activity_detail.id_verification')}
          kMaxWidth="md"
          kAlignBtn={isMobile ? 'center' : 'flex-end'}
          kBorderRadius={!isMobile ? '10px' : 0}
          kSlideTransition={isMobile}
          kKeepBtnSize
          kOpenDialogLists={openShuftiIframe}
          kOnClose={() => { finishVerification(); }}
          kOnSecondary={() => { finishVerification(); }}
          kOnPrimary={() => { finishVerification(); }}
          kDisableActions
          kContent={
          (
            <Card sx={{ height: 600, width: 800 }}>
              <iframe
                title="shufti"
                height="100%"
                width="100%"
                src={shuftiUrl}
                id="shuftipro-iframe"
                allow="camera"
                frameBorder="0"
              />
            </Card>
          )
        }
        />
      ) : null}

      <AppBarSecondary
        kBack
        kBackClick={handleBackButtonClick}
        kTitle={(
          <SectionTitle
            kMarginTop={0}
            kMarginBottom={0}
            kTitle={t('listing_detail.listing_detail')}
          />

        )}
        kActions={(
          <>
            <IconLinkButton
              kStartIcon={bookDetails?.isFavorite === 'active' ? <Favorite /> : <FavoriteBorder />}
              kText={t('listing_detail.favorite')}
              kHideText={isMobileBr}
              kFontSize={isMobileBr ? 'body2.fontSize' : 'body1.fontSize'}
              kFontWeight={isMobileBr ? '400' : 'bold'}
              kOnClick={() => createItemFavorite(id)}
            />
            <ShareButtons
              deepLink={bookDetails?.itemDeepLink}
            />
          </>
        )}
      />

      <ContentLayout kPaddingXs={0}>
        <Grid
          container
          spacing={{ md: 3 }}
        >
          <Grid item mb={{ md: 3, xs: 15 }} xs={12} sm={12} md={8}>
            <Card elevation={0}>
              <Stack
                px={1}
                py={1}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={3}
                sx={{
                  display: { xs: 'none', md: 'flex' },
                }}
              >
                <IconButton
                  aria-label="back"
                  color="primary"
                  onClick={() => { handleBackButtonClick(); localStorage.removeItem('startTime'); localStorage.removeItem('endTime'); }}
                  sx={{ py: 1.4 }}
                >
                  <ArrowBackIosRounded />
                </IconButton>
                <HeaderTitle
                  kTitle={t('listing_detail.listing_detail')}
                />
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={3}
                >
                  <IconLinkButton
                    kStartIcon={
                      bookDetails?.isFavorite === 'active' ? <Favorite />
                        : <FavoriteBorder />

                    }
                    kText={t('listing_detail.favorite')}
                    kFontSize={isMobileBr ? 'body2.fontSize' : 'body1.fontSize'}
                    kFontWeight={isMobileBr ? '400' : 'bold'}
                    // kHideText={isMobileBr ?? false}
                    kOnClick={() => createItemFavorite(id)}
                  />
                  <ShareButtons
                    deepLink={bookDetails?.itemDeepLink}
                  />
                </Stack>

              </Stack>
              <Divider sx={{
                display: { xs: 'none', md: 'flex' },
              }}
              />
              <CardContent>
                {bookDetails
                  ? (
                    <>
                      {/* <Swiper images={images} height={460} thumbnailHeight={100} /> */}
                      <SwiperThumbnails
                        itemImages={images}
                        height={460}
                        thumbnailHeight={80}
                      />
                      {/* <LmiSwiper /> */}
                      {/* <TabsRentBuy kHandleChange={handleChange} kValue={tabIndexValue} /> */}
                      {bookDetails.salePrice > 0
                        ? (
                          <Box
                            mt={3}
                            sx={{
                              '.Mui-selected': {
                                backgroundColor: LmiTheme.light.accent,
                                color: 'white',
                                fontWeight: 'bold',
                                span: { color: 'white' },
                                '&:hover': {
                                  backgroundColor: LmiTheme.light.accent,
                                },
                              },
                            }}
                          >
                            { bookDetails?.lenderVerified
                              ? (
                                <ToggleButtonGroup onChange={funBookType} value={bookType} color="primary" fullWidth exclusive>
                                  <ToggleButton disableFocusRipple disableRipple value={RENT} sx={{ flexDirection: 'column' }}>{t('global.rent_this_item')}</ToggleButton>
                                  <ToggleButton disableFocusRipple disableRipple value={BUY} sx={{ flexDirection: 'column' }}>{t('global.buy_this_item')}</ToggleButton>
                                </ToggleButtonGroup>
                              ) : null }
                          </Box>
                        )
                        : <></>}

                      <SectionTitle
                        kTitle={bookDetails.name}
                        kFontSize="h4.fontSize"
                        kMarginLeft={0}
                        kMarginBottom={1}
                      />
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={3}
                      >
                        <Grid container spacing={1}>
                          <Grid item>
                            <ItemLocation
                              kOnClick={() => { handleModalToggle(); }}
                              kText={bookDetails.exactAddress ? bookDetails.address : bookDetails.itemLocationName}
                            />
                          </Grid>
                          <Grid item>
                            <ItemViews kViews={bookDetails.totalViews} />
                          </Grid>
                        </Grid>
                      </Stack>
                      <SectionDescription
                        kMarginBottom={0}
                        kTitle={t('listing_detail.description')}
                        kDescription={bookDetails.dscription}
                      />
                      <Box
                        my={1}
                        sx={{
                          '.Mui-selected': {
                            backgroundColor: LmiTheme.light.accent,
                            color: 'white !important',
                            fontWeight: 'bold',
                            '&:hover': {
                              backgroundColor: LmiTheme.light.accent,
                            },
                          },
                        }}
                      >
                        <Accordion disableGutters elevation={0} expanded={bookType === RENT ?? false}>
                          <AccordionSummary sx={{ display: 'none', padding: 0 }}>
                            <></>
                          </AccordionSummary>
                          <AccordionDetails sx={{ padding: 0 }}>
                            <Availability />
                          </AccordionDetails>
                        </Accordion>
                      </Box>

                    </>
                  )
                  : null}

                <IdVerification requiresIdVerification={bookDetails?.requiresRenterIdVerification} />

                {/* {(bookType != 'RENT' || ((!roadie || !roadieVisibleFromPhoneCode) && !isLenderDelivery)) ? null
                  : (
                    <DeliveryOptions
                      kTitle={t('listing_detail.delivery')}
                      roadie={(roadie && roadieVisibleFromPhoneCode)}
                      lenderDelivery={isLenderDelivery}
                      changeDeliveryType={changeDeliveryType}
                      deliveryType={deliveryType}
                      visiblePhoneCode={roadieVisibleFromPhoneCode}
                    />
                  )} */}
                {(((!roadie || !roadieVisibleFromPhoneCode) && !isLenderDelivery) || precingType == PRECING_HOURLY) ? null
                  : (
                    <DeliveryOptions
                      kTitle={t('listing_detail.delivery')}
                      roadie={(roadie && roadieVisibleFromPhoneCode)}
                      lenderDelivery={isLenderDelivery}
                      changeDeliveryType={changeDeliveryType}
                      deliveryType={deliveryType}
                      visiblePhoneCode={roadieVisibleFromPhoneCode}
                    />
                  )}
                {accesories && accesories.length > 0 ? (

                  <Collapse in={bookType === RENT ?? false}>
                    <AccessoriesOptions
                      kTitle={t('listing_detail.accessories')}
                      accesories={accesories}
                      precingType={precingType}
                      editAccesory={editAccesory}
                      bookType={bookType}
                      currency={bookDetails.currency}
                    />
                  </Collapse>

                ) : null}
                {condictions && condictions.length > 0
                  ? (
                    <SpecialInstructions
                      kTitle={t('listing_detail.special_instructions')}
                      condictions={condictions}
                    />
                  )
                  : null}
                {bookDetails
                  ? (
                    <AboutLender
                      kTitle={t('listing_detail.about_lender')}
                      kPhoto={bookDetails.lenderProfilePicture}
                      kName={bookDetails.lenderName}
                      kBookItem={bookDetails}
                      kRecommended={bookDetails.sumRatesReceived}
                      kIdVerified={t('listing_detail.id_verified')}
                      kDescription=" "
                      kIdLender={bookDetails.idLenderUser}
                    />
                  )
                  : null}
                <PickupLocation
                  kTitle={t('listing_detail.pickup_location')}
                />
                <OtherListingsCarousel kType="lenderItems" />
                <OtherListingsCarousel kType={categoryDesc} />
              </CardContent>
            </Card>
          </Grid>
          <Grid
            sx={{
              display: {
                xs: 'none', md: 'block',
              },
            }}
            item
            xs={12}
            sm={12}
            md={4}
          >
            <Card elevation={0} sx={{ position: 'sticky', top: '50px', p: 2 }}>
              <Summary
                // eslint-disable-next-line no-return-await
                kOnClickOpenDialogCongratulations={() => continueBook()}
                kIsBook
              />
            </Card>

          </Grid>
        </Grid>
      </ContentLayout>
      {!isTablet ? <BottomBarSummary /> : <></>}
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 9999,
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          display: loading ? 'block' : 'none',
        }}
      >
        <LoadingLayout />
      </Box>
    </>
  );
}

export default BookItemPage;

import { Typography } from '@mui/material';
import { SectionTitleModels } from './SectionTitle.models';

function SectionTitle(props: SectionTitleModels) {
  return (
    <>
      <Typography
        variant={props.kVariant}
        component={props.kComponent}
        ml={props.kMarginLeft}
        mr={props.kMarginRight}
        mt={props.kMarginTop}
        mb={props.kMarginBottom}
        sx={{
          fontSize: props.kFontSize,
          fontWeight: props.kFontWeight,
          color: props.kColor,
        }}
      >
        {props.kTitle}
        {props.children}
      </Typography>
    </>
  );
}

SectionTitle.defaultProps = {
  kTitle: 'kTitle',
  kVariant: 'h6',
  kComponent: 'h6',
  kFontWeight: 'bold',
  kColor: 'black',
  kMarginLeft: 0,
  kMarginRight: 0,
  kMarginTop: 4,
  kMarginBottom: 2,
};

export default SectionTitle;

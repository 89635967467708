import {
  Box, Typography, Button, Stack, Divider, useTheme, useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { useContext, useEffect, useState } from 'react';
import { DialogCommon, LmiTheme } from '../../../../../../components/Common';
import AddCircleConditions from './AddCircleConditions/AddCircleConditions';
import AddEditCirclesContext from '../../../../../../context/circles/addEditCirclesContext';
import ListSpecialConditions from './AddCircleConditions/ListSpecialConditions';

export default function ConditionsToJoin(props: any) {
  const [open, setOpen] = useState(false);
  const [flagAdd, setFlagAdd] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const addEditCirclesContextLog = useContext(AddEditCirclesContext);
  const {
    conditionsList,
    checkConditionValidations,
    changedConditionFields,
    areConditionFieldsValidated,
    addEditConditionInfo,
    updateConditionInfo,
    setConditionIndex,
    conditionIndex,
    updateConditionsList,
    deleteCondition,
    updateDraftCircleConditionsList,
  }: any = addEditCirclesContextLog;

  const closeDialogModal = () => {
    setOpen(false);
  };

  const handleModalToggle = (value: any) => {
    setConditionIndex(value);
    if (value >= 0) {
      updateConditionInfo(conditionsList[value], value);
    } else {
      updateConditionInfo('');
    }
    setOpen(!open);
    setIsEditing(true);
  };

  const submitCondition = () => {
    setFlagAdd(!flagAdd);
    checkConditionValidations(true);
    setIsEditing(false);
  };

  const removeCondition = () => {
    setOpen(false);
    deleteCondition();
    setIsEditing(false);
  };

  useEffect(() => {
    if (areConditionFieldsValidated) {
      saveUpdateRequirement();
    }
  }, [changedConditionFields]);

  const saveUpdateRequirement = () => {
    if (addEditConditionInfo != '') {
      if (conditionIndex > -1) {
        conditionsList.splice(conditionIndex, 1, addEditConditionInfo);
      } else {
        conditionsList.push(addEditConditionInfo);
      }
      updateConditionsList(conditionsList);
      updateDraftCircleConditionsList(conditionsList);
      setOpen(!open);
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation('global');

  return (
    <Box>
      <Stack p={2} direction="row" justifyContent="space-between" spacing={3}>
        <Typography fontSize="17px" color={LmiTheme.light.systemBackground.tertiary}>
          {t('circles.add_circle_page.add_conditions_circle')}
        </Typography>
        <Typography fontSize="17px" fontWeight={500} color={LmiTheme.light.systemBackground.tertiary}>
          {conditionsList?.length}
          /10
        </Typography>
      </Stack>
      <Divider sx={{ mb: -1 }} />
      <ListSpecialConditions key="0" editCondition={handleModalToggle} />
      <Box mb={2} sx={{ display: 'flex', justifyContent: 'center' }}>
        {
          conditionsList?.length !== 10 && (
            <Button
              disabled={props.isBlocked}
              variant="outlined"
              onClick={() => { handleModalToggle(-1); setIsEditing(false); }}
              startIcon={<AddIcon fontSize="large" />}
              size="large"
              sx={{
                my: 2,
                textTransform: 'none',
                fontWeight: 700,
                borderRadius: '10px',
              }}
            >
              {t('circles.add_circle_page.add_conditions')}
            </Button>
          )
        }

        <DialogCommon
          kTitle={t('circles.add_circle_page.add_condition_join')}
          kMaxWidth="sm"
          kOpenDialogLists={open}
          kMaxHeight={isMobile ? '325px' : '350px'}
          kAlignBtn="center"
          kBorderRadius={isMobile ? 0 : '10px'}
          kFixedDialog={0}
          kFixedDialogPosition="fixed"
          kDisableBottomDivider
          kKeepBtnSize
          kSlideTransition={isMobile}
          kTopDivider
          kOnClose={closeDialogModal}
          kOnPrimary={submitCondition}
          kOnSecondary={isEditing ? removeCondition : () => setOpen(false)}
          kSecondaryBtnText={isEditing ? t('global.delete') : t('global.cancel')}
          kContent={(<AddCircleConditions />)}
        />
      </Box>
    </Box>
  );
}

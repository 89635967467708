import {
  Box, Typography, Paper, Stack, Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SectionTitle } from '../../../../../../components/Common';
import ActivitySummaryStyle from './ActivitySummary.style';

export default function ActivitySummary({ accessoriesQty, activitySummary, notifDetail }: any) {
  let rentalUnit = '';
  let totalUnits = 0;
  const { t } = useTranslation('global');

  if (activitySummary && notifDetail) {
    if (activitySummary.totalDays > 0) {
      rentalUnit = activitySummary?.totalDays > 1 ? `${t('activity.summary.days')}` : `${t('activity.summary.day')}`;
      totalUnits = parseInt(activitySummary.totalDays, 10);
    }
    if (activitySummary?.totalHours > 0) {
      rentalUnit = activitySummary?.totalHours > 1 ? `${t('activity.summary.hours')}` : `${t('activity.summary.hour')}`;
      totalUnits = parseInt(activitySummary?.totalHours, 10);
    }
    if (activitySummary?.totalMonths > 0) {
      rentalUnit = activitySummary?.totalMonths > 1 ? `${t('activity.summary.months')}` : `${t('activity.summary.month')}`;
      totalUnits = parseInt(activitySummary?.totalMonths, 10);
    }
    if (activitySummary?.totalWeeks > 0) {
      rentalUnit = activitySummary?.totalWeeks > 1 ? `${t('activity.summary.weeks')}` : `${t('activity.summary.week')}`;
      totalUnits = parseInt(activitySummary?.totalWeeks, 10);
    }
    return (
      <Box sx={ActivitySummaryStyle}>
        <SectionTitle
          kMarginTop={2}
          kTitle={t('activity.summary.summary')}
          kFontSize={18}
        />
        <Paper
          className="lmiw-activity-summary"
          variant="outlined"
          elevation={0}
        >
          <Box p={2}>
            {notifDetail.isBuy
              ? (
                <Box>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography>
                      {t('activity.summary.item_purchase')}
                    </Typography>
                    <Typography>
                      $
                      {parseFloat(activitySummary.rentalSubtotal).toFixed(2)}
                      {' '}
                      {activitySummary.currency}
                    </Typography>
                  </Stack>
                </Box>
              )
              : (
                <Box>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography>
                      {`${t('activity.summary.summary')} (
                        ${totalUnits}

                        ${rentalUnit}
                        )`}

                    </Typography>
                    <Typography>
                      $
                      {parseFloat(activitySummary.rentalSubtotal).toFixed(2)}
                      {' '}
                      {activitySummary.currency}
                    </Typography>
                  </Stack>
                </Box>
              )}

            {(notifDetail?.isRent && activitySummary.totalAccesoriesAmmount > 0)
              && (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className="lmiw-summary-concept"
                >
                  <Typography>
                    {`${t('activity.summary.accessories')} (
                 ${accessoriesQty}
                 )`}
                  </Typography>
                  {(activitySummary.totalAccesoriesAmmount == null) ? (<Typography>$0.00</Typography>)
                    : (<Typography>{`$${parseFloat(activitySummary.totalAccesoriesAmmount).toFixed(2)} ${activitySummary.currency}`}</Typography>)}
                </Stack>
              )}
            {(activitySummary.shippingCost > 0)
              && (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className="lmiw-summary-concept"
                >
                  <Typography>{t('activity.summary.delivery_pickup')}</Typography>
                  <Typography>{`$${parseFloat(activitySummary.shippingCost).toFixed(2)} ${activitySummary.currency}`}</Typography>
                </Stack>
              )}

            {/* // Discount
             widget.item.notifType == 'RENT' ?
                state.items[0].codeCoupon != '' ? ListTile(
                    visualDensity: VisualDensity(horizontal: 0, vertical: -4),
                    dense: true,
                    contentPadding: const EdgeInsets.symmetric(
                        horizontal: 16.0, vertical: 0.0),
                    title: LmiItemDetailLabel(
                      label:
                          'Discount ' + '(' + state.items[0].codeCoupon + ')',
                      labelColor: Theme.of(context).colorScheme.primary,
                      labelSize: 14.0,
                    ),
                    trailing: LmiItemDetailLabel(
                      label: widget.item.notifType == 'RENT'
                          ? '\-\$${state.items[0].discount.toStringAsFixed(2)}'
                          : '\$0.00',
                      labelColor: Colors.red,
                      labelSize: 14.0,
                    ),
                  ) : SizedBox()
                : SizedBox(), */}
            {(notifDetail?.notifType == 'RENT')
              && (
                activitySummary?.codeCoupon != undefined && activitySummary?.codeCoupon != ''
                && (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className="lmiw-summary-concept"
                  >
                    <Typography>
                      {t('activity.summary.discount')}
                      {' '}
                      {activitySummary.codeCoupon}
                    </Typography>
                    {(notifDetail?.notifType == 'RENT')
                      ? (<Typography className="red">{`$${parseFloat(activitySummary?.discount).toFixed(2)} ${activitySummary.currency}`}</Typography>)
                      : (<Typography>$0.00</Typography>)}
                  </Stack>
                )
              )}
            {(notifDetail?.notifType != 'RENT')
              && (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className="lmiw-summary-concept"
                >
                  <Typography>{t('activity.summary.service_fee')}</Typography>
                  {(notifDetail?.notifType == 'RENT')
                    ? (<Typography>$0.00</Typography>)
                    : (<Typography className="red">{`$${parseFloat(activitySummary.paymentLmiFee).toFixed(2)} ${activitySummary.currency}`}</Typography>)}
                </Stack>
              )}
            {(notifDetail?.notifType != 'RENT')
              && (
                activitySummary.circleOwnerFee > 0
                && (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className="lmiw-summary-concept"
                  >
                    <Typography>
                      {t('activity.summary.circle_owner_fee')}
                      {' ('}
                      {activitySummary.circlePercentage}
                      )
                      %
                    </Typography>
                    {(notifDetail?.notifType == 'RENT')
                      ? (<Typography>$0.00</Typography>)
                      : (<Typography className="red">{`$${parseFloat(activitySummary.circleOwnerFee).toFixed(2)} ${activitySummary.currency}`}</Typography>)}
                  </Stack>
                )
              )}
            <Divider />
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className="lmiw-summary-total"
            >
              {(notifDetail?.notifType == 'RENT')
                ? (<Typography>{t('activity.summary.total_payment')}</Typography>)
                : (<Typography>{t('activity.summary.total_earnings')}</Typography>)}
              {(notifDetail?.notifType == 'RENT')
                ? (<Typography>{`$${parseFloat(activitySummary.paymentTotalWithDiscount).toFixed(2)} ${activitySummary.currency}`}</Typography>)
                : (<Typography>{`$${parseFloat(activitySummary.totalEarnings).toFixed(2)} ${activitySummary.currency}`}</Typography>)}
            </Stack>
          </Box>

        </Paper>

        {(notifDetail?.notifType != 'RENT')
          && (
            <Typography className="lmiw-summary-info">
              {t('activity.summary.info')}
            </Typography>
          )}
      </Box>
    );
  }
  return null;
}

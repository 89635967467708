/* eslint-disable no-nested-ternary */
import { EditRounded, LocationOnRounded } from '@mui/icons-material';
import {
  Box, Typography, Stack, InputAdornment, IconButton, useTheme, useMediaQuery,
} from '@mui/material';
import { useJsApiLoader } from '@react-google-maps/api';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  DialogCommon, LmiTheme, StaticMap,
} from '../../../../../../components/Common';
import { MapData } from '../../../../../../components/Common/Map/Map.models';
import SuggestedMap from '../../../../../../components/Common/Map/MapPlaces';
import AddEditCirclesContext from '../../../../../../context/circles/addEditCirclesContext';
import InputTextField from '../../../../AddItem/Components/InputTextField/InputTextField';

export default function CircleLocation() {
  const circlesContextLog = useContext(AddEditCirclesContext);

  const {
    circleInfoEdit,
    updateCircleInfo,
    setCircleLocation,
    updateDraftCircleInfo,
  }: any = circlesContextLog;

  const onChange = () => { };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [openChangeAddress, setOpenChangeAddress] = useState(false);

  const [addressHasChanged, setAddressHasChanged] = useState(false);

  const history = useHistory();
  const state = history.location.state as {
    kCircleIsNew: boolean
  };

  const [libraries]: any = useState(['places']);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
  });

  const [usrLat, setUsrLat] = React.useState(0);
  const [usrLng, setUsrLng] = React.useState(0);

  const [cancelLat, setCancelLat] = React.useState('');
  const [cancelLng, setCancelLng] = React.useState('');
  const [cancelAddress, setCancelAddress] = React.useState('');

  const openMap = () => {
    setOpenChangeAddress(true);
    setCancelLat(circleInfoEdit?.lat);
    setCancelLng(circleInfoEdit?.lon);
    setCancelAddress(circleInfoEdit?.address);
  };

  const cancelCloseMap = () => {
    setOpenChangeAddress(!openChangeAddress);

    updateCircleInfo({
      ...circleInfoEdit,
      lat: cancelLat,
      lon: cancelLng,
      address: cancelAddress,
    });
    updateDraftCircleInfo({
      ...circleInfoEdit,
      lat: cancelLat,
      lon: cancelLng,
      address: cancelAddress,
    });
  };

  useEffect(() => {
    const localLat = localStorage.getItem('lat');
    const localLng = localStorage.getItem('lng');
    if (localLat === null && localLng === null) {
      if (usrLat === 0 && usrLng === 0) {
        navigator.geolocation.getCurrentPosition((position) => {
          setUsrLat(position.coords.latitude);
          setUsrLng(position.coords.longitude);
        }, () => {
          setUsrLat(34.0536909);
          setUsrLng(-118.242766);
        });
      }
    } else {
      setUsrLat(localLat ? parseFloat(localLat) : 34.0536909);
      setUsrLng(localLng ? parseFloat(localLng) : -118.242766);
    }
  }, []);

  const updateMapa = (mapaData: MapData) => {
    setAddressHasChanged(true);
    setCircleLocation({
      lat: mapaData?.lat,
      lon: mapaData?.long,
      address: mapaData?.address,
      streetAddress: mapaData?.streetAddress,
      extendedAddress: mapaData?.extendedAddress,
      city: mapaData?.locality,
      region: mapaData?.region,
      zipCode: mapaData?.postalCode,
      country: mapaData?.countryName,
    });
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation('global');

  return (
    <Box>
      <Stack p={2} direction="column" justifyContent="space-between" spacing={3}>
        <Typography fontSize="17px" color={LmiTheme.light.systemBackground.tertiary}>
          {t('circles.add_circle_page.circle_location_map')}
        </Typography>
        <InputTextField
          kLabelRequiredHide
          kLabel=""
          kOnClick={openMap}
          kInputMaxLengthHide
          kInputId="location"
          kInputType="text"
          kInputPlaceholder={t('circles.add_circle_page.add_location')}
          kInputValue={circleInfoEdit?.address != undefined ? circleInfoEdit.address : ''}
          kInputMultiline={false}
          kDisabled
          kInputRows={1}
          kInputMaxRows={1}
          kInputOnChange={onChange}
          kInputRequired
          kInputError={((circleInfoEdit?.lat == '' && circleInfoEdit?.lon == ''))}
          kInputHelperText={(circleInfoEdit?.lat == '' && circleInfoEdit?.lon == '') ? `${t('item_detail.field_required')}` : ''}
          kInputName="address"
          kStartAdornment={(
            <InputAdornment position="start">
              <IconButton onClick={() => openMap()} edge="start">
                <LocationOnRounded sx={{ color: LmiTheme.light.systemBackground.primary }} />
              </IconButton>
            </InputAdornment>
          )}
          kEndAdornment={(
            <InputAdornment position="end">
              <IconButton onClick={() => openMap()} edge="end">
                <EditRounded sx={{ color: LmiTheme.light.accent }} />
              </IconButton>
            </InputAdornment>
          )}
        />
        <StaticMap
          kExactLocation={false}
          kLat={
            !state.kCircleIsNew
              ? Number(circleInfoEdit?.lat)
              : (!addressHasChanged ? usrLat : circleInfoEdit?.lat)
          }
          kLng={
            !state.kCircleIsNew
              ? Number(circleInfoEdit?.lon)
              : (!addressHasChanged ? usrLng : circleInfoEdit?.lon)
          }
        />

      </Stack>
      {isLoaded
        ? (
          <DialogCommon
            kTitle={t('circles.add_circle_page.circle_location')}
            kMaxWidth="md"
            kSlideTransition={isMobile}
            kDisableContentPadding={isMobile}
            kDisableBottomDivider
            kDisableContentPaddinginY={isMobile}
            kKeepBtnSize
            kAlignBtn="center"
            kBorderRadius={isMobile ? 0 : '10px'}
            kDisablePaddingInY={isMobile}
            kTopDivider
            kOpenDialogLists={openChangeAddress}
            kOnClose={cancelCloseMap}
            kOnPrimary={() => { setOpenChangeAddress(!openChangeAddress); }}
            kOnSecondary={cancelCloseMap}
            kContent={
              (
                <SuggestedMap
                  state={{
                    address: circleInfoEdit?.address ?? '',
                    lat: circleInfoEdit?.lat,
                    long: circleInfoEdit?.lon,
                    typeItem: circleInfoEdit?.idCatTypeItem ?? 3,
                  }}
                  usrLat={usrLat}
                  usrLng={usrLng}
                  userLatLng={{ lat: usrLat, lng: usrLng }}
                  funUpdateInfo={updateMapa}
                  kDisableInsideInput
                />
              )
            }
          />
        )
        : <></>}

    </Box>

  );
}

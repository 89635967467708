/* eslint-disable max-lines */
import {
  ClsModApproveRejectCircleInvitation,
  ClsModApproveItemBuy,
  ClsModApproveRejectCircleJoinRequest,
  ClsModApproveRejectReservation,
  ClsModApproveRejectReservationNewDeliveryTime,
  ClsModCancelReservationNewFlow,
  ClsModCheckJoinRequestProcessed,
  ClsModCheckLenderCanceledApproved,
  ClsModCreateDetReviewThread,
  ClsModCreateRntItemDelRetImageStage,
  ClsModCreateRntItemDelRetVideoStage,
  ClsModCreateUpdateRntItemUserReviewExperience,
  ClsModGetIsSameDateRentalApproved,
  ClsModGetLenderProfileInfoNewFlow,
  ClsModGetRentalRoadieNewDeliveryTime,
  ClsModSendNotificationGeneralNotification,
  ClsModSetOpenedUserNotification,
  ClsModUploadReturnalPicture,
} from '@lendmeit/api_backend_lmi/dist/models';
import { useReducer } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { Storage } from 'aws-amplify';
// import { generateVideoThumbnails } from '@rajesh896/video-thumbnails-generator';
import { ActivityDetailModel } from '../../../page/BackOffice/Activity/Activity.models';
import ApiLMI from '../../../services/apilmi';
import { formatYYYYMMDD } from '../../../services/common';
import {
  ACTIVITY_VALIDATES_APPROVED_STATE,
  UPDATE_REJECT_REASON,
  ACTIVITY_START_TASK,
  REFRESH_ACTIVITY_TASK_DONE,
  ROADIE_IN_PAST,
  APPROVE_REJECT_FAILURE,
  // UPDATE_REVIEW_RATE,
  UPDATE_REVIEW_COMMENT,
  UPDATE_REVIEW_THUMBS,
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
  // SET_DISABLE_BUTTON,
  ACCEPTED_CIRCLE_JOIN_REQUEST,
  UPLOAD_PICTURE_FAILURE,
  // ACTIVITY_START_APPROVAL_PROCESS,
  ACTIVITY_START_VALIDATES_APPROVED_STATE,
  CLEAR_NEW_DELIVERY_FLAG,
} from '../../../types';
import { LocationFullModel } from '../../home/models/locationFullModel';
import ActivityActionsContext from './activityActionsContext';
import ActivityActionsReducer from './activityActionsReducer';
import videoThumbnailGet from '../../../services/videoThumbnailGet';
// import { useTranslation } from 'react-i18next';

const ActivityActionsState = (props: any) => {
  const initialState = {
    loadingActAction: false,
    disableButton: false,
  };

  const [state, dispatch] = useReducer(ActivityActionsReducer, initialState);

  const sendMessage = () => {
    // socket send message pending;
  };

  const { t } = useTranslation('global');

  const cancelReservationPressed = async (cancelReason: string, item: any) => {
    // const isOwnItem = item?.notifType != 'RENT';
    const _api: any = ApiLMI();
    try {
      dispatch({
        type: SET_LOADING_TRUE,
      });
      // const lenderCanceledApproved = await _api.activityCheckLenderCanceledApprovedPost();
      const bodyLenderCanceledApproved: ClsModCheckLenderCanceledApproved = {
        idCatLndRntItem: item.idCatLndRntItem,
        toc: '',
      };
      const lenderCanceledApproved: any = await _api.apiActivityCheckLenderCanceledApprovedPost(bodyLenderCanceledApproved);
      const parsedLenderCanceledApproved = JSON.parse(lenderCanceledApproved.data).Response[0];

      if (parsedLenderCanceledApproved == null) {
        // dispatch Approve Reject Failure
        dispatch({
          type: APPROVE_REJECT_FAILURE,
          payload: {
            rejectFailure: true,
            rejectFailureMessage: `${t('actions_state.reservation_status')}`,
          },
        });
      } else if (parsedLenderCanceledApproved.lenderCanceledApproved || parsedLenderCanceledApproved.lenderApproved) {
        // dispathc AproveReject Failure
        const msg = lenderCanceledApproved[0]
          ? `${t('actions_state.canceled_reservation')}`
          : `${t('actions_state.approved_reservation')}`;
        dispatch({
          type: APPROVE_REJECT_FAILURE,
          payload: {
            rejectFailure: true,
            rejectFailureMessage: msg,
          },
        });
      }

      const bodyCancelReservationNewFlow: ClsModCancelReservationNewFlow = {
        cancelComments: cancelReason,
        toc: '',
        idCatLndRntItem: item.idCatLndRntItem,
      };

      const cancelRes: any = await _api.itemCancelReservationNewFlow(bodyCancelReservationNewFlow);

      const parsedCancelRes = JSON.parse(cancelRes.data);

      if (parsedCancelRes.Error != '') {
        dispatch({
          type: APPROVE_REJECT_FAILURE,
          payload: {
            rejectFailure: true,
            rejectFailureMessage: parsedCancelRes.Error,
          },
        });
      } else {
        // dispatch success cancelation
        dispatch({
          type: REFRESH_ACTIVITY_TASK_DONE,
          payload: {
            taskEnded: true,
          },
        });
      }
    } catch (err) {
      console.log('no err');
    }
  };

  const activityValidatesApproved = async (approved: boolean, notifDetail: any, lenderLocation: LocationFullModel, rejectReason: string = '', messageToRenter: string = '') => {
    const _api = ApiLMI();
    try {
      const idUserToSearch = notifDetail?.notifType == 'RENT'
        ? notifDetail?.idRenter
        : notifDetail?.idLender;

      const bodyRenterLenderInfo: ClsModGetLenderProfileInfoNewFlow = {
        idCatLndUser: idUserToSearch,
        toc: '',
      };
      dispatch({
        type: ACTIVITY_START_VALIDATES_APPROVED_STATE,
      });
      let pendingUserStepts = false;
      // if (notifDetail?.notifType == 'RENT') {

      // }
      const renterLenderInfo: any = await _api.userGetLenderProfileInfoNewFlow(bodyRenterLenderInfo);
      const parsedRenterLenderInfo = JSON.parse(renterLenderInfo.data).Response[0];
      const requireIDVerificationDisabled = (notifDetail.lenderVerified && notifDetail.renterVerified);
      if (parsedRenterLenderInfo) {
        if (!parsedRenterLenderInfo?.hasPayOutAccount) {
          pendingUserStepts = true;
        } else if (!requireIDVerificationDisabled && !parsedRenterLenderInfo.lenderVerified) {
          pendingUserStepts = true;
        }
        const bodyIsSameDateRental: ClsModGetIsSameDateRentalApproved = {
          toc: 'sad',
          idCatLndItem: notifDetail.idCatLndItem,
          idCatLndRntItem: notifDetail.idCatLndRntItem,
          fromDate: notifDetail.fromDate,
          toDate: notifDetail.toDate,
        };
        const rentalDuplicated: any = await _api.itemGetIsSameDateRentalApproved(bodyIsSameDateRental);
        const parsedRentalDuplicated = JSON.parse(rentalDuplicated.data).Response[0];

        if (!parsedRentalDuplicated?.rentExists) {
          if (!pendingUserStepts) {
            if (lenderLocation != null && lenderLocation != undefined) {
              // dispatchear el startapprovalprocessState

              // If is rent do this
              // else is buy
              if (notifDetail.isRent) {
                if (messageToRenter.length > 0) {
                  sendMessage();
                }
                activityActionsApproveReject(approved, notifDetail.exchangeAddress ?? notifDetail.addressComplete, '', lenderLocation, notifDetail, rejectReason, messageToRenter);
              } else if (!approved) {
                activityActionsApproveReject(approved, notifDetail.exchangeAddress ?? notifDetail.addressComplete, '', lenderLocation, notifDetail, rejectReason, messageToRenter);
              } else {
              // approve item buy
                const appItmBuyObj: ClsModApproveItemBuy = {
                  approved,
                  address: notifDetail.exchangeAddress ?? notifDetail.addressComplete,
                  idCatLndRntItem: notifDetail.idCatLndRntItem,
                  lat: lenderLocation.lat.toString(),
                  lon: lenderLocation.lon.toString(),
                  toc: '',
                };
                const approveItemBuy: any = await _api.activityApproveItemBuy(appItmBuyObj);
                // debugger;
                const approvedItemBuyParse = JSON.parse(approveItemBuy.data);
                if (approvedItemBuyParse.Error == '') {
                  if (approvedItemBuyParse.Response[0].IdRentedItem > 0) {
                  // Send notifications to the lender and renter on rental approved
                  // APPROVED
                    const bodyPushNotif: ClsModSendNotificationGeneralNotification = {
                      idCatLndRntItem: notifDetail.idCatLndRntItem,
                      status: '',
                      notificationType: 7,
                      sendSMS: true,
                      sendPush: false,
                      sendEmail: false,
                    };
                    await _api.pushnotificationSendNotificationGeneralNotification(bodyPushNotif);
                    const bodyPushNotif2: ClsModSendNotificationGeneralNotification = {
                      idCatLndRntItem: notifDetail.idCatLndRntItem,
                      status: '',
                      notificationType: 9,
                      sendSMS: true,
                      sendPush: false,
                      sendEmail: false,
                    };
                    await _api.pushnotificationSendNotificationGeneralNotification(bodyPushNotif2);
                    // Let both the seller and the buyer know they can review their transaction

                    const bodyPushNotif3: ClsModSendNotificationGeneralNotification = {
                      idCatLndRntItem: notifDetail.idCatLndRntItem,
                      status: '',
                      notificationType: 13,
                      sendSMS: true,
                      sendPush: false,
                      sendEmail: false,
                    };
                    await _api.pushnotificationSendNotificationGeneralNotification(bodyPushNotif3);
                    const bodyPushNotif4: ClsModSendNotificationGeneralNotification = {
                      idCatLndRntItem: notifDetail.idCatLndRntItem,
                      status: '',
                      notificationType: 14,
                      sendSMS: true,
                      sendPush: false,
                      sendEmail: false,
                    };
                    await _api.pushnotificationSendNotificationGeneralNotification(bodyPushNotif4);
                    // dispatch
                    dispatch({
                      type: REFRESH_ACTIVITY_TASK_DONE,
                      payload: {
                        taskEnded: true,
                      },
                    });
                  }
                } else {
                  dispatch({
                    type: APPROVE_REJECT_FAILURE,
                    payload: {
                      rejectFailure: true,
                      rejectFailureMessage: `${t('actions_state.approving_reservation')}`,
                    },
                  });
                }
              }
            } else {
              // dispatchear mensaje de seteo locacion

              dispatch({
                type: ACTIVITY_VALIDATES_APPROVED_STATE,
                payload: {
                  message: `${t('actions_state.set_location')}`,
                },
              });
            }
          } else {
            // dispatchear la wea pending user steps

            dispatch({
              type: ACTIVITY_VALIDATES_APPROVED_STATE,
              payload: {
                message: `${t('actions_state.setup_payout_account')}`,
              },
            });
          }
        } else if (!approved && rejectReason != '') {
          activityActionsApproveReject(approved, notifDetail.exchangeAddress ?? notifDetail.addressComplete, '', lenderLocation, notifDetail, rejectReason, messageToRenter);
        } else {
          // dispatchear mensaje de valió dickelroy
          dispatch({
            type: ACTIVITY_VALIDATES_APPROVED_STATE,
            payload: {
              message: `${t('actions_state.cant_approve_reservation')}`,
            },
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addHours = (numOfHours: number, date = new Date()) => {
    date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);

    return date;
  };

  const setTaskDone = async () => {
    dispatch({
      type: REFRESH_ACTIVITY_TASK_DONE,
      payload: {
        taskEnded: true,
      },
    });
  };

  const clearSetNewDelivery = async () => {
    dispatch({
      type: CLEAR_NEW_DELIVERY_FLAG,
    });
  };

  const activityActionsApproveReject = async (
    approved: boolean,
    address: string,
    newDeliveryTime: any,
    lenderLocation: LocationFullModel,
    notifDetail: any,
    rejectReason: string,
    message: string,
  ) => {
    // approve reject
    const idtoast = toast.loading(`${t('actions_state.saving_info')}`);
    try {
      dispatch({
        type: ACTIVITY_START_TASK,
      });
      const _api = ApiLMI();
      const userPendingInfo: any = await _api.userValidatesUserPendingInfo({ toc: '' });
      const parsedUserPendingInfo = JSON.parse(userPendingInfo.data).Response[0];
      let approvedRejectRes: any;
      let parsedApprovedRejectRes: any;
      if (parsedUserPendingInfo.firstName.length == 0
        || parsedUserPendingInfo.lastName.length == 0
        || parsedUserPendingInfo.profilePicture.length == 0) {
        const pendingName = parsedUserPendingInfo.firstName.length == 0;
        const pendingPicture = parsedUserPendingInfo.profilePicture.length == 0;
        console.log(pendingName, pendingPicture);
        toast.dismiss();

        dispatch({
          type: ACTIVITY_VALIDATES_APPROVED_STATE,
          payload: {
            message: `${t('actions_state.upload_picture')}`,
          },
        });
        // notify the user of pending info
        // dispatch  pending info
        //   emit(ActivityPendingUserInfo(
        //       pendingName: pendingName, pendingProfilPicture: pendingPicture));
      } else {
        if (newDeliveryTime.length > 0) {
          // approve reject different time frame pending

          const today = new Date();
          const datem = `${formatYYYYMMDD(today)} ${newDeliveryTime.replaceAll('AM', '').replaceAll('PM', '').replace('p.m.', '').replace('a.m.', '')
            .trim()}`;

          const newDeliveryTimeStart = new Date(datem);
          const newDeliveryTimeEnd = addHours(4, today);

          const roadieStartTime = newDeliveryTimeStart.toISOString();
          const roadieEndTime = newDeliveryTimeEnd.toISOString();
          const appRejNewDelTimeBody: ClsModApproveRejectReservationNewDeliveryTime = {
            approved,
            reason: rejectReason,
            lat: lenderLocation.lat.toString(),
            lon: lenderLocation.lon.toString(),
            address,
            idCatLndRntItem: notifDetail.idCatLndRntItem,
            newDeliveryEndTime: roadieEndTime,
            newDeliveryStartTime: roadieStartTime,
          };

          approvedRejectRes = await _api.activityApproveRejectReservationNewDeliveryTime(appRejNewDelTimeBody);
          parsedApprovedRejectRes = JSON.parse(approvedRejectRes.data);
        } else {
          // Approve reservation with delivery or not
          const bodyApproveReject: ClsModApproveRejectReservation = {
            toc: '',
            approved,
            lat: lenderLocation.lat.toString(),
            lon: lenderLocation.lon.toString(),
            address,
            idCatLndRntItem: notifDetail.idCatLndRntItem,
            reason: rejectReason ?? '',
          };
          approvedRejectRes = await _api.activityApproveRejectReservation(bodyApproveReject);
          parsedApprovedRejectRes = JSON.parse(approvedRejectRes.data);
        }
        // if approved returns error
        if (parsedApprovedRejectRes.Error != '') {
          console.log('has error contains');

          if (parsedApprovedRejectRes.Error == `${t('actions_state.delivery_window')}` || parsedApprovedRejectRes.Error == 'Delivery window start time is too far in the past.') {
            // get Roadie delivery new delovery start time
            // String newDeliveryTime =
            //     await activityProvider.getRoadieNewDeliveryTime(user,
            //         activityItem.idCatLndItem, activityItem.idCatLndRntItem);
            const bodyNewDelivery: ClsModGetRentalRoadieNewDeliveryTime = {
              toc: '',
              idCatLndRntItem: notifDetail.idCatLndRntItem,
              idCatLndItem: notifDetail.idCatLndItem,
            };
            const newDeliveryTimeResp: any = await _api.activityGetRentalRoadieNewDeliveryTime(bodyNewDelivery);
            parsedApprovedRejectRes = JSON.parse(newDeliveryTimeResp.data).Response[0].fromHour;

            console.log(newDeliveryTimeResp);
            toast.dismiss(idtoast);
            dispatch({
              type: ROADIE_IN_PAST,
              payload: {
                diffRoadieMessage: `${t('actions_state.roadie_msg')} ${parsedApprovedRejectRes}?`,
                newStartTime: parsedApprovedRejectRes,
                lenderLocation,
                lenderLocationAddress: address,
                messageToRenter: message,
              },
            });
            // return;
          } else {
            toast.dismiss(idtoast);

            let errorToShow = parsedApprovedRejectRes.Error;

            if (errorToShow != '') {
              errorToShow = parsedApprovedRejectRes.Error;
            }
            dispatch({
              type: APPROVE_REJECT_FAILURE,
              payload: {
                rejectFailure: true,
                rejectFailureMessage: errorToShow,
              },
            });
            console.log('failure normal');
            // emit(ActivityActionsApproveRejectFailure(
            //     message: activityProvider.hasError.toString()));
          }
        } else if (approved) {
          // notification provider, send general notification
          const bodyPushNotif: ClsModSendNotificationGeneralNotification = {
            idCatLndRntItem: notifDetail.idCatLndRntItem,
            status: '',
            notificationType: 7,
            sendSMS: false,
            sendPush: false,
            sendEmail: false,
          };
          const respNotif: any = await _api.pushnotificationSendNotificationGeneralNotification(bodyPushNotif);
          const parsedRespNotif = JSON.parse(respNotif.data);
          let parsedSecondRespNotif;
          if (newDeliveryTime.length > 0) {
            const bodyPushNotifDel: ClsModSendNotificationGeneralNotification = {
              idCatLndRntItem: notifDetail.idCatLndRntItem,
              status: '',
              notificationType: 16,
              sendSMS: true,
              sendPush: false,
              sendEmail: false,
            };
            const secondRespNotif: any = await _api.pushnotificationSendNotificationGeneralNotification(bodyPushNotifDel);
            parsedSecondRespNotif = JSON.parse(secondRespNotif.data);
          } else {
            const bodyPushNotifNoDel: ClsModSendNotificationGeneralNotification = {
              idCatLndRntItem: notifDetail.idCatLndRntItem,
              status: '',
              notificationType: 9,
              sendSMS: true,
              sendPush: false,
              sendEmail: false,
            };
            const secondRespNotif: any = await _api.pushnotificationSendNotificationGeneralNotification(bodyPushNotifNoDel);
            parsedSecondRespNotif = JSON.parse(secondRespNotif.data);
          }
          if (parsedRespNotif.Error == '' && parsedSecondRespNotif.Error == '') {
            // dispatch rejected reservation
            dispatch({
              type: REFRESH_ACTIVITY_TASK_DONE,
              payload: {
                taskEnded: true,
              },
            });
          } else {
            console.log('there was an error sending these messages');
          }
        } else {
          // REJECTED
          const bodyPushNotif: ClsModSendNotificationGeneralNotification = {
            idCatLndRntItem: notifDetail.idCatLndRntItem,
            status: '',
            notificationType: 8,
            sendSMS: false,
            sendPush: false,
            sendEmail: false,
          };
          const respNotif: any = await _api.pushnotificationSendNotificationGeneralNotification(bodyPushNotif);
          const parsedRespNotif = JSON.parse(respNotif.data);
          const bodyPushNotifSecond: ClsModSendNotificationGeneralNotification = {
            idCatLndRntItem: notifDetail.idCatLndRntItem,
            status: '',
            notificationType: 10,
            sendSMS: true,
            sendPush: false,
            sendEmail: false,
          };
          const secondRespNotif: any = await _api.pushnotificationSendNotificationGeneralNotification(bodyPushNotifSecond);
          const parsedSecondRespNotif = JSON.parse(secondRespNotif.data);

          if (parsedRespNotif.Error == '' && parsedSecondRespNotif.Error == '') {
            // dispatch rejected reservation
            dispatch({
              type: REFRESH_ACTIVITY_TASK_DONE,
              payload: {
                taskEnded: true,
              },
            });
          } else {
            console.log('there was an error sending these messages');
          }
        }
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: APPROVE_REJECT_FAILURE,
        payload: {
          rejectFailure: true,
          rejectFailureMessage: '',
        },
      });
      toast.error({
        render: `${t('actions_state.error_occurred')}`,
        type: 'error',
        isLoading: false,
        autoClose: 1500,
      });
    }
    toast.dismiss(idtoast);
  };

  const setRejectReason = async (rejectReason: string) => {
    dispatch({
      type: UPDATE_REJECT_REASON,
      payload: {
        rejectReason,
      },
    });
  };

  const approveNewDeliveryTime = async (notifDetail: any, lenderLocation: LocationFullModel) => {
    activityActionsApproveReject(true, notifDetail.exchangeAddress ?? notifDetail.addressComplete, state.newStartTime, lenderLocation, notifDetail, '', '');
  };

  const setLoadingFalse = async () => {
    dispatch({
      type: SET_LOADING_FALSE,
    });
  };

  const setLoadingTrue = async () => {
    dispatch({
      type: SET_LOADING_TRUE,
    });
  };

  const saveActivityReview = async (notifDetail: ActivityDetailModel) => {
    let idReviewNumber;
    let idCatLndReviewByRenter;
    let idCatLndReviewByLender;

    dispatch({
      type: ACTIVITY_START_TASK,
    });
    try {
      if (state.isThumbsUpReview == true) {
        idReviewNumber = 5;
      } else {
        idReviewNumber = 1;
        // Si es una mala review y no se dejó un comentario, salga de la función.
        if (idReviewNumber == 1 && state.reviewComment.length == 0) {
          dispatch({
            type: SET_LOADING_FALSE,
          });
          // aquí podrías hacer algo para notificar al usuario que necesita dejar un comentario para una mala review
          return;
        }
      }

      if (notifDetail.idCatLndUser == notifDetail.idRenter) {
        idCatLndReviewByRenter = idReviewNumber;
        idCatLndReviewByLender = 6;
      } else {
        idCatLndReviewByLender = idReviewNumber;
        idCatLndReviewByRenter = 6;
      }

      const bodyNewReviewExp: ClsModCreateUpdateRntItemUserReviewExperience = {
        toc: '',
        idCatLndRntItem: notifDetail.idCatLndRntItem,
        idCatLndLender: notifDetail.idLender,
        idCatLndRenter: notifDetail.idRenter,
        idCatLndReviewByLender,
        idCatLndReviewByRenter,
      };

      const _api = ApiLMI();
      const idNewReviewExperience: any = await _api.userCreateUpdateRntItemUserReviewExperience(bodyNewReviewExp);
      const idNewMessageThreadCreated = JSON.parse(idNewReviewExperience.data).Response[0];
      if (idNewMessageThreadCreated.idCatLndUserExperienceReview != 0) {
        const bodyNewThread: ClsModCreateDetReviewThread = {
          toc: '',
          active: 1,
          sent: 1,
          received: 0,
          opened: 0,
          message: state.reviewComment,
          idCatLndUser: 0,
          idCatndUserExperienceReview: idNewMessageThreadCreated.idCatLndUserExperienceReview,
        };
        const respThread: any = await _api.userCreateDetReviewThread(bodyNewThread);
        const respThreadParsed = JSON.parse(respThread.data).Response[0];
        if (respThreadParsed.idDetLndReviewThread > 0) {
          // Success review
          dispatch({
            type: REFRESH_ACTIVITY_TASK_DONE,
            payload: {
              taskEnded: true,
            },
          });
        } else {
          // error or review
          dispatch({
            type: SET_LOADING_FALSE,
          });
        }
      }
    } catch (e) {
      dispatch({
        type: SET_LOADING_FALSE,
      });
      dispatch({
        type: REFRESH_ACTIVITY_TASK_DONE,
        payload: {
          taskEnded: true,
        },
      });
    }
  };

  const setReviewComment = async (comment: string) => {
    dispatch({
      type: UPDATE_REVIEW_COMMENT,
      payload: {
        reviewComment: comment,
      },
    });
  };

  const setThumbsReview = async (isUp: boolean) => {
    dispatch({
      type: UPDATE_REVIEW_THUMBS,
      payload: {
        isThumbsUpReview: isUp,
      },
    });
  };

  const approveRejectJoinCircle = async (
    circleActivityItem: ActivityDetailModel,
    approved: boolean,
    comments: string,
  ) => {
    const _api = ApiLMI();

    dispatch({
      type: SET_LOADING_TRUE,
    });

    dispatch({
      type: ACTIVITY_START_TASK,
    });

    try {
      const bodyCheckRequest: ClsModCheckJoinRequestProcessed = {
        toc: 'toc',
        idCatLndCircle: circleActivityItem.idCatLndCircle,
        idCatLndCircleJoinRequest: circleActivityItem.idCatLndCircleJoinRequest,
      };

      const checkJoinRequest: any = await _api.circleCheckJoinRequestProcessed(bodyCheckRequest);

      const respCheckJoinRequest = JSON.parse(checkJoinRequest.data);
      if (respCheckJoinRequest.Error == '') {
        if (!respCheckJoinRequest.Response[0].processed) {
          const bodyAppRejCircle: ClsModApproveRejectCircleJoinRequest = {
            approved,
            comments,
            denied: !approved,
            idCatLndCircle: circleActivityItem.idCatLndCircle,
            idCatLndCircleJoinRequest: circleActivityItem.idCatLndCircleJoinRequest,
          };

          const appRejCircle: any = await _api.circleApproveRejectCircleJoinRequest(bodyAppRejCircle);

          const respAppRejCircle = JSON.parse(appRejCircle.data);

          if (respAppRejCircle.Error == '') {
            // success devolver

            const bodySetNotifOpen: ClsModSetOpenedUserNotification = {
              toc: 'toc',
              idCatLndUserNotification: circleActivityItem.idDetLndUserNotification,
            };
            await _api.activitySetOpenedUserNotification(bodySetNotifOpen);

            dispatch({
              type: ACCEPTED_CIRCLE_JOIN_REQUEST,
              payload: {
                taskEnded: true,
                taskError: 'Join request processed',
              },
            });
          } else {
            dispatch({
              type: ACCEPTED_CIRCLE_JOIN_REQUEST,
              payload: {
                taskEnded: false,
                taskError: 'There was an error while doing this. Please try again later',
              },
            });
            // dispatch error request acceptedRejected
          }
        } else {
          // Join request has already been processed by someone else

          await _api.activitySetOpenedUserNotification(
            { toc: '', idCatLndUserNotification: circleActivityItem.idDetLndUserNotification },
          );

          dispatch({
            type: ACCEPTED_CIRCLE_JOIN_REQUEST,
            payload: {
              taskEnded: true,
              taskError: 'The request has already been processed by someone else',
            },
          });
          // dispatch message request already processed
        }
      } else {
        dispatch({
          type: ACCEPTED_CIRCLE_JOIN_REQUEST,
          payload: {
            taskEnded: false,
            taskError: 'There was an error while doing this. Please try again later',
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ACCEPTED_CIRCLE_JOIN_REQUEST,
        payload: {
          taskEnded: false,
          taskError: 'There was an error while doing this. Please try again later',
        },
      });
    }
  };

  const approveRejectCircleInvitation = async (
    circleActivityItem: ActivityDetailModel,
    approved: boolean,
    adminInvited: boolean,
  ) => {
    const _api = ApiLMI();

    dispatch({
      type: SET_LOADING_TRUE,
    });

    dispatch({
      type: ACTIVITY_START_TASK,
    });

    try {
      const bodyAppRejCircleInvitation: ClsModApproveRejectCircleInvitation = {
        idCatLndCircleInvitation: circleActivityItem.idCatLndCircleInvitation,
        approved,
        adminInvited,
      };

      const appRejCircleInvitation: any = await _api.circleApproveRejectCircleInvitation(bodyAppRejCircleInvitation);

      const respAppRejCircle = JSON.parse(appRejCircleInvitation.data);

      if (respAppRejCircle.Error == '') {
        // success devolver

        const bodySetNotifOpen: ClsModSetOpenedUserNotification = {
          toc: 'toc',
          idCatLndUserNotification: circleActivityItem.idDetLndUserNotification,
        };
        await _api.activitySetOpenedUserNotification(bodySetNotifOpen);

        dispatch({
          type: ACCEPTED_CIRCLE_JOIN_REQUEST,
          payload: {
            taskEnded: true,
            taskError: 'Invitation processed',
          },
        });
      } else {
        dispatch({
          type: ACCEPTED_CIRCLE_JOIN_REQUEST,
          payload: {
            taskEnded: false,
            taskError: 'There was an error while doing this. Please try again later',
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ACCEPTED_CIRCLE_JOIN_REQUEST,
        payload: {
          taskEnded: false,
          taskError: 'There was an error while doing this. Please try again later',
        },
      });
    }
  };

  const getRentalType = (type: string): number => {
    switch (type) {
      case 'pickup':
        return 1;
      case 'dropoff':
        return 2;
      default:
        return 0;
    }
  };

  const dispatchUploadFailure = () => {
    dispatch({
      type: UPLOAD_PICTURE_FAILURE,
      payload: {
        uploadPictureError: true,
        uploadPictureErrorMessage: 'Error',
      },
    });
  };

  const dispatchTaskDone = () => {
    dispatch({
      type: REFRESH_ACTIVITY_TASK_DONE,
      payload: {
        taskEnded: true,
      },
    });
  };

  const saveActivityPictures = async (notifDetail: ActivityDetailModel, pictures: any[], picturesType: any): Promise<void> => {
    const rentalTypePickOrDrop = getRentalType(picturesType);
    const _api = ApiLMI();

    dispatch({
      type: ACTIVITY_START_TASK,
    });
    try {
      await Promise.all(
        pictures.map(async (element) => {
          const body: ClsModUploadReturnalPicture = {
            toc: 'toc',
            returnalPicture: element.split(',')[1],
          };

          const uploadPictureResponse: any = await _api.itemUploadReturnalPicture(body);
          const parsedResponse = JSON.parse(uploadPictureResponse.data);

          if (parsedResponse.Error === '') {
            const pictureName = parsedResponse.Response[0].fileName;
            const bodyRel: ClsModCreateRntItemDelRetImageStage = {
              toc: 'toc',
              idCatLndRntItem: notifDetail.idCatLndRntItem,
              imageUrl: pictureName,
              type: rentalTypePickOrDrop,
            };

            const idCatLndPhotoRelation: any = await _api.itemCreateRntItemDelRetImageStage(bodyRel);
            const parsedIdCatLndRelation: any = JSON.parse(idCatLndPhotoRelation.data);

            if (parsedIdCatLndRelation.Response[0].idRelLndDeliveryReturnalImage === 0) {
              dispatchUploadFailure();
              throw new Error(t('activity_detail.upload_failure'));
            }
          } else {
            dispatchUploadFailure();
            throw new Error(t('activity_detail.upload_failure'));
          }
        }),
      );
      dispatchTaskDone();
    } catch (error) {
      console.error(error);
    }
  };

  const uploadActVideo2 = async (file: any, fileName: any) => {
    try {
      const result = await new Promise((resolve, reject) => {
        Storage.put(`${fileName}`, file.file, {
          customPrefix: {
            public: process.env.REACT_APP_VIDEO_BUCKET_FOLDER_NAME,
          },
        })
          .then((resp) => {
            console.log(resp);
            resolve({});
          }).catch((err) => {
            console.log(err);
            reject(err);
          });
      });
      console.log(result);
      return Location;
    } catch (e: any) {
      console.log(e);
      return '';
    }
  };

  const saveActivityVideos = async (notifDetail: ActivityDetailModel, videos: any[], picturesType: any): Promise<void> => {
    const rentalTypePickOrDrop = getRentalType(picturesType);
    const _api = ApiLMI();

    dispatch({
      type: ACTIVITY_START_TASK,
    });
    try {
      await Promise.all(
        videos.map(async (element) => {
          const fileName = `video${uuid()}.mp4`;
          await uploadActVideo2(element, fileName);

          const videoThumbnail = await videoThumbnailGet(element.file);

          const body: ClsModUploadReturnalPicture = {
            toc: 'toc',
            returnalPicture: videoThumbnail.split(',')[1],
          };

          const uploadPictureResponse: any = await _api.itemUploadReturnalPicture(body);
          const parsedResponse = JSON.parse(uploadPictureResponse.data);

          if (parsedResponse.Error === '') {
            const thumbnailPath = parsedResponse?.Response[0]?.fileName;
            const bodyRel: ClsModCreateRntItemDelRetVideoStage = {
              toc: 'toc',
              idCatLndRntItem: notifDetail.idCatLndRntItem,
              videoUrl: `${process.env.REACT_APP_VIDEO_BUCKET_FOLDER_NAME}${fileName}`,
              thumbnailUrl: thumbnailPath,
              type: rentalTypePickOrDrop,
            };

            const idCatLndPhotoRelation: any = await _api.itemCreateRntItemDelRetVideoStage(bodyRel);
            const parsedIdCatLndRelation: any = JSON.parse(idCatLndPhotoRelation.data);

            if (parsedIdCatLndRelation.Response[0].idRelLndDeliveryReturnalVideo === 0) {
              dispatchUploadFailure();
              throw new Error(t('activity_detail.upload_failure'));
            }
          } else {
            dispatchUploadFailure();
            throw new Error(t('activity_detail.upload_failure'));
          }
        }),
      );
      dispatchTaskDone();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ActivityActionsContext.Provider
      value={{
        ...state,
        rejectReason: state.rejectReason,
        newStartTime: state.newStartTime,
        lenderLocation: state.lenderLocation,
        setNewDelivery: state.setNewDelivery,
        diffRoadieMessage: state.diffRoadieMessage,
        reviewComment: state.reviewComment,
        idCatLndReviewByRenter: state.idCatLndReviewByRenter,
        idCatLndReviewByLender: state.idCatLndReviewByLender,
        isThumbsUpReview: state.isThumbsUpReview,
        loadingActAction: state.loadingActAction,
        activityValidatesApproved,
        setRejectReason,
        approveNewDeliveryTime,
        cancelReservationPressed,
        saveActivityReview,
        setReviewComment,
        setThumbsReview,
        setTaskDone,
        approveRejectJoinCircle,
        approveRejectCircleInvitation,
        saveActivityPictures,
        setLoadingFalse,
        setLoadingTrue,
        clearSetNewDelivery,
        saveActivityVideos,
      }}
    >
      {props.children}
    </ActivityActionsContext.Provider>
  );
};

export default ActivityActionsState;

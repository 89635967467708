import {
  Button, Grid, Stack, useMediaQuery, useTheme,
} from '@mui/material';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { useTranslation } from 'react-i18next';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import { useContext, useEffect, useState } from 'react';
import LmiTheme from '../../../../themes/lmi-mui-theme/lmi-theme';
import CardLend from '../../Listing/Components/CardLend/CardLend';
import CircleDetail from '../CircleDetail/CircleDetail';
import { DialogCommon, LoadingLayout } from '../../../../components/Common';
import ShareCircle from '../AddCircle/Components/CircleCreated/ShareCircle';
import ReportDialog from '../CircleDetail/Components/ReportCircle/ReportDialog';
import MyCirclesListingContext from '../../../../context/myCirclesListing/myCirclesListingContext';
import { CircleDetailCardModels } from './CircleDetailCard.models';
import EmptyCircles from '../EmptyCircles/EmptyCircles';

function CircleDetailCard(props: CircleDetailCardModels) {
  const {
    circle,
    circles,
  }: any = useContext(MyCirclesListingContext);

  const { t } = useTranslation('global');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [deepLink, setDeepLink] = useState('');

  useEffect(() => {
    setDeepLink(circle?.deepLink);
  });

  return (
    <>
      <Grid
        item
        xs={12}
        md={8}
        sx={{
          display: {
            xs: props.kOpenCircleDetail ? 'block' : 'none',
            md: 'initial',
          },
        }}
      >
        {
          (circles?.length == 0 && !props.kOpenCircleDetail) ? (
            <EmptyCircles />
          ) : (
            <CardLend
              kTitle={t('circles.main.circle_detail')}
              kActions={(
                <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                  {
                    (circle?.isOwner || circle?.isAdmin) ? (
                      null
                    ) : (
                      <Button
                        variant="outlined"
                        onClick={() => props.kSetOpenReportCircleModal(true)}
                        sx={{
                          display: { xs: 'none', sm: 'inherit' },
                          borderRadius: 3,
                          fontWeight: 'bold',
                          textTransform: 'capitalize',
                          border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
                          mr: '16px',
                        }}
                        startIcon={<ErrorOutlineRoundedIcon />}
                      >
                        {t('circles.main.report')}
                      </Button>
                    )
                  }
                  <Button
                    variant="outlined"
                    onClick={() => props.kSetOpen(true)}
                    sx={{
                      display: { xs: 'none', sm: 'inherit' },
                      borderRadius: 3,
                      fontWeight: 'bold',
                      textTransform: 'capitalize',
                      border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
                      mr: '10px',
                    }}
                    startIcon={<IosShareOutlinedIcon />}
                  >
                    {t('circles.main.share')}
                  </Button>
                </Stack>
              )}
              kContent={(
                circle
                  ? <CircleDetail />
                  : <LoadingLayout />
              )}
            />
          )
        }

        <DialogCommon
          kTitle={t('circles.main.share_circle')}
          kMaxWidth="sm"
          kEnableBorderRadius
          kMaxHeight={isMobile ? '450px' : 'auto'}
          kBorderRadius={isMobile ? 0 : '10px'}
          kFixedDialog={0}
          kFixedDialogPosition="fixed"
          kDisableBottomDivider
          kDisableContentPadding
          kKeepBtnSize
          kCenterTitle
          kSlideTransition={isMobile}
          kTopDivider
          kOpenDialogLists={props.kOpen}
          kOnClose={() => props.kCloseDialogModal()}
          kDisableActions
          kContent={
            (
              <ShareCircle deepLink={deepLink} />
            )
          }
        />
        <DialogCommon
          kTitle={t('circles.main.report_circle')}
          kMaxWidth="sm"
          kMaxHeight={isMobile ? '480px' : 'auto'}
          kAlignBtn="center"
          kBorderRadius={isMobile ? 0 : '10px'}
          kFixedDialog={0}
          kFixedDialogPosition="fixed"
          kDisableBottomDivider
          kDisableContentPadding
          kKeepBtnSize
          kSlideTransition={isMobile}
          kOpenDialogLists={props.kOpenReportCircleModal}
          kOnClose={() => props.kCloseDialogModal()}
          kOnPrimary={() => props.kCloseDialogModal()}
          kOnSecondary={() => props.kCloseDialogModal()}
          kPrimaryBtnText={t('circles.members.report')}
          kContent={(
            <ReportDialog />
          )}
        />
      </Grid>
    </>
  );
}

export default CircleDetailCard;

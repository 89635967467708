/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable react/jsx-props-no-spreading */
import SwiperCore, {
  Pagination, Navigation, Thumbs,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
// import { useHistory } from 'react-router-dom';
import 'swiper/swiper-bundle.min.css';
import {
  Avatar, Stack, Box, Paper, Fab, CardMedia,
  useMediaQuery, useTheme,
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React, { useState } from 'react';
import { ActivitySwiperStyle, ActivitySwiperModalStyle } from './ActivitySwiper.style';
import { ActivitySwiperModels } from './ActivitySwiper.models';
import { DialogCommon, SectionTitle } from '../../../../../../components/Common';

SwiperCore.use([Pagination, Navigation, Thumbs, Pagination]);
export default React.memo(
  (props: ActivitySwiperModels) => {
    const theme = useTheme();
    const [openImage, setOpenImage] = useState(false);
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const [mainSwiper, setMainSwiper] = useState<any>(null);
    const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);

    const MainParams = {
      modules: [Pagination, Navigation],
      // slidesPerView: 10,
      spaceBetween: 15,
      slidesPerGroup: 3,
      navigation: {
        prevEl: `.prev-${props.kType}`,
        nextEl: `.next-${props.kType}`,
      },
      breakpoints: {
        1200: {
          slidesPerView: 3,
        },

        900: {
          slidesPerView: 2,
        },

        600: {
          slidesPerView: 3,
        },

        400: {
          slidesPerView: 2.5,
        },

        300: {
          slidesPerView: 1.5,
          slidesPerGroup: 1,
        },
        1: {
          slidesPerView: 1.5,
          slidesPerGroup: 1,
        },
      },
    };

    const ModalParams = {
      modules: [Navigation, Thumbs, Pagination],
      watchSlidesProgress: true,
      navigation: !isTablet && props.kImage.length > 1,
      pagination: isTablet,
      thumbs: {
        swiper: thumbsSwiper,
      },
      onSwiper: (data: any) => setMainSwiper(data),
    };

    const ThumbnailsModalParam = {
      spaceBetween: 10,
      slidesPerView: 4,
      watchSlidesProgress: true,
      onSwiper: (data: any) => { setThumbsSwiper(data); },
    };

    function showImage(index: number) {
      setOpenImage(true);
      mainSwiper?.slideTo(index, 0, false);
      thumbsSwiper?.slideTo(index, 0, false);
    }

    const { t } = useTranslation('global');

    const videoRefs: any = [];
    const handleVideoPlay = (videoRef: any) => {
      const refIndex = videoRefs.findIndex((e: any) => e == videoRef);
      if (refIndex < 0 && videoRef) {
        videoRefs.push(videoRef);
      }
    };

    const clearVideos = () => {
      videoRefs.forEach((emnt: any) => {
        emnt?.pause();
        emnt.currentTime = 0;
      });
    };

    return (
      <>
        <Box sx={ActivitySwiperStyle}>
          <SectionTitle
            kMarginTop={2}
            kTitle={props.kType === 'PickUp' ? `${t('activity.swiper.pickup_pictures')}` : `${t('activity.swiper.dropoff_pictures')}`}
            kFontSize={18}
          />
          <Paper
            className="lmiw-activity-swiper"
            variant="outlined"
            elevation={0}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              p={2}
            >
              <Fab
                size="medium"
                aria-label={t('activity.swiper.left')}
                className={`prev prev-${props.kType}`}
              >
                <KeyboardArrowLeftIcon />
              </Fab>
              <Box sx={{ width: { sm: 'calc(100% - 144px)', xs: '100%' }, ml: '0 !important' }}>
                <Swiper id={`swiper-${props.kType}`} {...MainParams}>
                  {props.kImage?.map((data: any, index: number) => (
                    <SwiperSlide key={data.imageUrl} onClick={() => showImage(index)}>
                      <Avatar
                        alt={data.imageUrl}
                        variant="rounded"
                        className="lmiw-swiper-image"
                        src={`${data.imageUrl}`}
                      />
                    </SwiperSlide>

                  ))}
                </Swiper>
              </Box>
              <Fab
                size="medium"
                aria-label={t('activity.swiper.right')}
                className={`next next-${props.kType}`}
              >
                <ChevronRightIcon />
              </Fab>
            </Stack>

          </Paper>
        </Box>
        <DialogCommon
          kTitle={props.kType === 'PickUp' ? `${t('activity.swiper.pickup_pictures')}` : `${t('activity.swiper.dropoff_pictures')}`}
          kMaxWidth="sm"
          kOpenDialogLists={openImage}
          kOnClose={() => {
            setOpenImage(false);
            clearVideos();
          }}
          kDisableActions
          kContent={(
            <Box sx={ActivitySwiperModalStyle}>
              <Swiper
                id={`main-swiper-${props.kType}`}
                {...ModalParams}
                className="main-swiper"
                onSlideChange={() => {
                  clearVideos();
                }}
              >
                {/* <Swiper {...ModalParams} className="main-swiper"> */}
                {props.kImage?.map((data: any) => (
                  <SwiperSlide key={`main-swiper-${props.kType}-${data.imageUrl.split('returnal')[1]}`}>
                    {data.assetType == 'video' ? (
                      <CardMedia
                        component="video"
                        height="500"
                        src={data.videoPath}
                        ref={handleVideoPlay}
                        controls
                      />
                    )
                      : (
                        <CardMedia
                          component="img"
                          height="500"
                          image={data.imageUrl}
                          alt={data.imageUrl}
                        />
                      )}
                  </SwiperSlide>

                ))}
              </Swiper>
              <Box mt={2} />
              <Swiper id={`thumbnail-swiper-${props.kType}`} {...ThumbnailsModalParam} className="thumbnail-swiper">
                {props.kImage?.map((data: any) => (
                  <SwiperSlide key={`thumbnail-swiper-${data.imageUrl.split('returnal')[1]}`}>
                    {/* <SwiperSlide> */}
                    <Avatar
                      alt={data.imageUrl}
                      src={data.imageUrl}
                      variant="rounded"
                      className="lmiw-thumbnail-image"
                    />
                  </SwiperSlide>

                ))}
              </Swiper>
            </Box>

          )}
        />
      </>
    );
  },
);

/// LMI Color Palette
const LmiColorPalette = {

  /// Brand Blue Color
  blueScheme: {
    50: '#E3F3FC',
    100: '#BADFF9',
    200: '#8FCCF6',
    300: '#63B8F3',
    400: '#3EA9F1',
    500: '#0F9AF0',
    600: '#038DE2',
    700: '#007BCF',
    800: '#006ABE',
    900: '#004C9F',
  },

  /// Deep Blue Color
  deepBlueScheme: {
    50: '#E3F3FC',
    100: '#C6C9FC',
    200: '#9DA7FB',
    300: '#7084FA',
    400: '#4966F8',
    500: '#0548F4',
    600: '#003FE8',
    700: '#0034DB',
    800: '#0027D0',
    900: '#000AB8',
  },

  /// Purple Color
  purpleScheme: {
    50: '#F4E4FE',
    100: '#E1BCFD',
    200: '#CD8FFD',
    300: '#B75EFD',
    400: '#A533FC',
    500: '#9200F9',
    600: '#7D00F4',
    700: '#5B00ED',
    800: '#3100E9',
    900: '#0012DC',
  },

  /// Pink Color
  pinkScheme: {
    50: '#F8E2EE',
    100: '#EFB6D4',
    200: '#E786B8',
    300: '#E0549B',
    400: '#DC2785',
    500: '#DA006D',
    600: '#C90069',
    700: '#B30063',
    800: '#9D005E',
    900: '#770053',
  },

  /// Green Color
  greenScheme: {
    50: '#E6F4E5',
    100: '#C3E5BF',
    200: '#9CD396',
    300: '#73C36B',
    400: '#52B74A',
    500: '#2DAA24',
    600: '#229B1B',
    700: '#0F8A0C',
    800: '#007900',
    900: '#005A00',
  },

  /// Yellow Color
  yellowScheme: {
    50: '#FFF7D0',
    100: '#FFF2B1',
    200: '#FEEC92',
    300: '#FEE773',
    400: '#FEDA27',
    500: '#F8CF01',
    600: '#E3BE01',
    700: '#BA9B01',
    800: '#917901',
    900: '#7C6701',
  },

  /// Orange Color
  orangeScheme: {
    50: '#FFF3DE',
    100: '#FFE0AE',
    200: '#FFCB78',
    300: '#FFB640',
    400: '#FFA600',
    500: '#FF9600',
    600: '#FC8A00',
    700: '#F77900',
    800: '#F26800',
    900: '#E94B00',
  },

  /// Red Color
  redScheme: {
    50: '#FFEAEC',
    100: '#FFCACE',
    200: '#F29592',
    300: '#E86B68',
    400: '#F14741',
    500: '#F43322',
    600: '#E62623',
    700: '#D4191E',
    800: '#C80C16',
    900: '#B90006',
  },

  /// Brown Color
  brownScheme: {
    50: '#FFE7C2',
    100: '#F0C6A2',
    200: '#D2A47E',
    300: '#B28359',
    400: '#9A6A3C',
    500: '#82511F',
    600: '#77481A',
    700: '#683B11',
    800: '#5B2D0B',
    900: '#4C1F00',
  },

  /// System Background Colors
  grayAccentScheme: {
    50: '#FFFFFF',
    100: '#F7F7F7',
    200: '#F3F3F3',
    300: '#E8E8E8',
    400: '#999DA2',
    500: '#707479',
    600: '#3E4145',
    700: '#33363A',
    800: '#1D2024',
    900: '#121212',
  },

  /// /// Social Network Colors
  socialScheme: {
    facebook: '#3b5998',
    google: '#DB4437',
  },
};

export default LmiColorPalette;

import { Edit } from '@mui/icons-material';
import {
  List,
  Box,
  Stack,
} from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AccesoriesPanelListModels } from './AccesoriesPanelList.models';
import { IconLinkButton, SectionTitle } from '..';
import AccesoriesPanelListItem from './AccesoriesPanelListItem';
import bookItemContext from '../../../../../context/bookItem/bookContext';
import { RENT } from '../../../../../types';
import { getAccesoryPrice } from '../../../../../services/common';

function AccesoriesPanelList(props: AccesoriesPanelListModels) {
  const { t } = useTranslation('global');
  const {
    accesories: accesoriesList,
    bookType,
    precingType,
  }: any = useContext(bookItemContext);
  return (
    <>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ pl: 2, pr: 1 }}
        >
          <SectionTitle
            kMarginLeft={0}
            kMarginRight={0}
            kMarginTop={3}
            kMarginBottom={2}
            kTitle={t(`${props.kTitle}`)}
          />
          <IconLinkButton
            kOnClick={props.kOnClick}
            kStartIcon={<Edit />}
            kText={t('global.change')}
            kFontSize="body1.fontSize"
            kFontWeight="bold"
          />
        </Stack>
        <>
          <List disablePadding>
            {
              /* eslint-disable react/jsx-props-no-spreading */
              accesoriesList.filter((s: any) => s.isAdded == true)
                .map((l : any, i: any) => (
                  <AccesoriesPanelListItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${i}-item`}
                    kPrice={getAccesoryPrice(l, bookType, precingType)}
                    kPriceType={bookType == RENT ? precingType : ''}
                    kPrimaryText={l.name}
                    kSecondaryText={l.dscription}
                    kDivider={props.kDivider ?? i < accesoriesList.length - 1 ?? false}
                    kCurrency={props.kCurrency!}
                  />
                ))
            }
          </List>
        </>
      </Box>
    </>
  );
}

AccesoriesPanelList.defaultProps = {
  kTitle: 'checkout.accessories',
  kDivider: true,
};

export default AccesoriesPanelList;

//  import * as React from 'react';
import {
  useEffect, useState, forwardRef, useImperativeHandle,
} from 'react';
import {
  Container,
  Paper, Box, Typography, Stack, Button, FormGroup, FormControlLabel, Checkbox, useMediaQuery, useTheme, CircularProgress,
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
// import ReactDOM from 'react-dom';
// import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';

import { Link } from 'react-router-dom';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import externalTagManagert from '../../../utils/googleTagManager';
import {
  LmiTheme, Logo, DialogCommon,
} from '..';
import { BannerCookiesStyle, ModalCookiesStyle, ZendeskLoadingStyle } from './BannerCookies.style';
import { BannerCookiesModel } from './BannerCookies.models';

const tagManagerArgs = { gtmId: 'GTM-NQF3M4J' };
// const TRACKING_ID = 'UA-138871289-1';
// const TRACKING_ID = 'G-ZLMT9JTYE8';
const TRACKING_ID = 'G-4EHHG3EJ27';

function BannerCookies(props: BannerCookiesModel, ref: any) {
  useImperativeHandle(ref, () => ({
    refToggleMessagesModal() {
      toggleMessagesModal();
    },
    refTogglePreferencesCookies() {
      togglePreferencesCookies();
    },

  }));

  const cookieStorage = {
    getItem: (item: any) => {
      const cookies = document.cookie
        .split(';')
        .map((cookie) => cookie.split('='))
        .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
      return cookies[item];
    },
    setItem: (item: any, value: any) => {
      const d = new Date();
      const exdays = 7;
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      const expires = `expires=${d.toUTCString()}`;
      document.cookie = `${item}=${value};${expires}`;
    },
  };

  const theme = useTheme();
  // const childRef = useRef();
  const isMobileMedia = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.up('sm') && theme.breakpoints.down('md'));
  // const [acceptCookies, setAcceptCookies] = useState(false);

  const [loadingZe, setLoadingZe] = useState(false);

  const consentNecessaryCookies = true;
  const [consentFunctionalCookies, setConsentFunctionalCookies] = useState(
    cookieStorage.getItem('consentFunctionalCookies') ? cookieStorage.getItem('consentFunctionalCookies') : true,
  );
  const [consentTrackingCookies, setConsentTrackingCookies] = useState(
    cookieStorage.getItem('consentTrackingCookies') ? cookieStorage.getItem('consentTrackingCookies') : true,
  );
  const [consentTargetingCookies, setConsentTargetingCookies] = useState(
    cookieStorage.getItem('consentTargetingCookies') ? cookieStorage.getItem('consentTargetingCookies') : true,
  );
  const [consentCookies, setConsentCookies] = useState(
    cookieStorage.getItem('consent_cookies') ? cookieStorage.getItem('consent_cookies') : true,
  );

  const [openModalPreferences, setOpenModalPreferences] = useState(false);
  const [openModalMessage, setOpenModalMessage] = useState(false);

  const togglePreferencesCookies = () => {
    setOpenModalPreferences(!openModalPreferences);
    setOpenModalMessage(false);
  };

  const toggleMessagesModal = () => {
    setOpenModalMessage(!openModalMessage);
  };

  const deleteAllCookies = () => {
    const cookies = document.cookie.split(';');
    // eslint-disable-next-line no-plusplus
    for (let i: number = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
  };

  const savePreferences = () => {
    deleteAllCookies();
    setConsentCookies(true);
    cookieStorage.setItem('consent_cookies', true);
    cookieStorage.setItem('consentNecessaryCookies', consentNecessaryCookies);
    cookieStorage.setItem('consentFunctionalCookies', consentFunctionalCookies);
    cookieStorage.setItem('consentTrackingCookies', consentTrackingCookies);
    cookieStorage.setItem('consentTargetingCookies', consentTargetingCookies);
    togglePreferencesCookies();
    startCookies();
    setLoadingZe(true);
    setTimeout(() => {
      setLoadingZe(false);
    }, 8000);
  };

  const acceptAllCookies = () => {
    setConsentCookies(true);
    cookieStorage.setItem('consent_cookies', true);
    cookieStorage.setItem('consentNecessaryCookies', true);
    cookieStorage.setItem('consentFunctionalCookies', true);
    cookieStorage.setItem('consentTrackingCookies', true);
    cookieStorage.setItem('consentTargetingCookies', true);
    startCookies();
    // togglePreferencesCookies();
    startCookies();
    setLoadingZe(true);
    setTimeout(() => {
      setLoadingZe(false);
    }, 8000);
  };

  const startCookies = () => {
    // *** consentFunctionalCookies
    if (cookieStorage.getItem('consentFunctionalCookies') == 'true') {
      // console.log('consentFunctionalCookies: true')
    }
    // *** consentNecessaryCookies
    if (cookieStorage.getItem('consentNecessaryCookies') == 'true') {
      // chat
      const zeSnippet = document.createElement('script');
      zeSnippet.src = 'https://static.zdassets.com/ekr/snippet.js?key=51ef7a3d-1e43-4219-ae4b-2fc6c58768d0';
      zeSnippet.async = true;
      zeSnippet.id = 'ze-snippet';
      document.body.appendChild(zeSnippet);
    }

    // *** consentTrackingCookies
    if (cookieStorage.getItem('consentTrackingCookies') == 'true') {
      // Global site tag (gtag.js) - Google Analytics
      const tagManagerScript = document.createElement('script');
      tagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${TRACKING_ID}`;
      document.body.appendChild(tagManagerScript);

      externalTagManagert(TRACKING_ID);

      // Google Analytics
      // ReactGA.initialize(TRACKING_ID);

      // TagManager
      TagManager.initialize(tagManagerArgs);

      // Facebook Pixel Widget script
      ReactPixel.init('805911206475905');
      ReactPixel.pageView();

      // pingdom
      const pingdom = document.createElement('script');
      pingdom.src = 'https://rum-static.pingdom.net/pa-5cb20e14e989400016000003.js';
      pingdom.async = true;
      document.body.appendChild(pingdom);
    }

    // *** consentTargetingCookies
    if (cookieStorage.getItem('consentTargetingCookies') == 'true') {
      // console.log('consentTargetingCookies: true')
    }
  };

  useEffect(() => {
    // ReactGA.pageview(window.location.pathname + window.location.search);
    if (cookieStorage.getItem('consent_cookies') === 'true') {
      setConsentCookies(true);
      setConsentFunctionalCookies(JSON.parse(cookieStorage.getItem('consentFunctionalCookies')));
      setConsentTrackingCookies(JSON.parse(cookieStorage.getItem('consentTrackingCookies')));
      setConsentTargetingCookies(JSON.parse(cookieStorage.getItem('consentTargetingCookies')));
      startCookies();
      setLoadingZe(true);
      setTimeout(() => {
        setLoadingZe(false);
      }, 8000);
    } else if (props.kShowBanner) {
      setConsentCookies(false);
      setLoadingZe(false);
    }
  }, []);

  // eslint-disable-next-line no-nested-ternary
  const sizeLogo = isMobileMedia ? 50 : isTablet ? 180 : 210;

  const { t } = useTranslation('global');

  return (
    <>
      {!consentCookies ? (
        <Box sx={{
          ...BannerCookiesStyle, position: 'fixed', zIndex: 99,
        }}
        >
          <Paper elevation={8} sx={{ maxWidth: 1060, mx: 'auto' }}>
            <Stack
              direction={{ md: 'row', xs: 'column' }}
              justifyContent="center"
              alignItems="center"
              gap={2}
              p={3}
            >
              <Typography component="p" variant="body2">
                {t('cookies.info')}
              </Typography>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                gap={2}
              >
                <Button
                  onClick={() => { togglePreferencesCookies(); }}
                  variant="outlined"
                  fullWidth={false}
                  className="btn-preferences btn-textTransform-none"
                >
                  {t('cookies.preferences')}
                </Button>
                <Button
                  onClick={() => acceptAllCookies()}
                  variant="contained"
                  fullWidth={false}
                  className="btn-agree btn-textTransform-none"
                >
                  {t('cookies.agree')}
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </Box>
      ) : <></>}
      <DialogCommon
        kDisableTitle
        kMaxWidth="sm"
        kOpenDialogLists={openModalPreferences}
        kOnClose={() => { togglePreferencesCookies(); }}
        kOnPrimary={async () => { savePreferences(); }}
        kPrimaryBtnText={t('cookies.save_preferences')}
        kAlignBtn={isMobileMedia ? 'center' : 'flex-end'}
        kFullScreenDesktop
        kContent={
          (
            <Container maxWidth="lg">
              <Box sx={ModalCookiesStyle}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box width="250px" sx={{ my: 5 }}>
                    <Logo width={sizeLogo} icon={isMobileMedia} iconColor="accent" textColor="accent" />
                  </Box>
                  <CloseRoundedIcon onClick={() => togglePreferencesCookies()} className="closeModal" />
                </Stack>
                <Typography component="h4" variant="h4" sx={{ fontWeight: 'bold' }}>
                  {t('cookies.title')}
                </Typography>
                <Typography component="p" variant="body2">
                  {t('cookies.title_txt')}
                </Typography>
                <Typography component="p" variant="body2">
                  {t('cookies.title_txt2')}
                </Typography>
                <FormGroup>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checkedIcon={(
                          <Box className="box-checkbox active">
                            <CheckOutlinedIcon />
                          </Box>
                        )}
                        icon={<Box className="box-checkbox" />}
                        disabled
                        defaultChecked
                      />
                    )}
                    label={(
                      <>
                        <Typography component="h5" variant="h5">
                          {t('cookies.strict_cookie')}
                        </Typography>
                        <Typography component="p" variant="body2">
                          {t('cookies.strict_cookie_txt')}
                        </Typography>
                      </>
                    )}
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checkedIcon={(
                          <Box className="box-checkbox active">
                            <CheckOutlinedIcon />
                          </Box>
                        )}
                        icon={<Box className="box-checkbox" />}
                        checked={!!consentFunctionalCookies}
                        onChange={() => setConsentFunctionalCookies(!consentFunctionalCookies)}
                      />
                    )}
                    label={(
                      <>
                        <Typography component="h5" variant="h5">
                          {t('cookies.functionality')}
                        </Typography>
                        <Typography component="p" variant="body2">
                          {t('cookies.functionality_txt')}

                        </Typography>
                      </>
                    )}
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checkedIcon={(
                          <Box className="box-checkbox active">
                            <CheckOutlinedIcon />
                          </Box>
                        )}
                        icon={<Box className="box-checkbox" />}
                        checked={!!consentTrackingCookies}
                        onChange={() => setConsentTrackingCookies(!consentTrackingCookies)}
                      />
                    )}
                    label={(
                      <>
                        <Typography component="h5" variant="h5">
                          {t('cookies.tracking')}
                        </Typography>
                        <Typography component="p" variant="body2">
                          {t('cookies.tracking_txt')}

                        </Typography>
                      </>
                    )}
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checkedIcon={(
                          <Box className="box-checkbox active">
                            <CheckOutlinedIcon />
                          </Box>
                        )}
                        icon={<Box className="box-checkbox" />}
                        checked={!!consentTargetingCookies}
                        onChange={() => setConsentTargetingCookies(!consentTargetingCookies)}
                      />
                    )}
                    label={(
                      <>
                        <Typography component="h5" variant="h5">
                          {t('cookies.targeting')}
                        </Typography>
                        <Typography component="p" variant="body2">
                          {t('cookies.targeting_txt')}

                        </Typography>
                      </>
                    )}
                  />
                </FormGroup>
                <Typography component="h5" variant="h5">
                  {t('cookies.more_information')}
                </Typography>
                <Typography component="p" variant="body2">
                  {t('cookies.privacy_policy_txt')}
                  {' '}
                  <Link to="/privacy-policy" onClick={() => togglePreferencesCookies()}>{t('cookies.privacy_policy_txt2')}</Link>
                  {t('cookies.privacy_policy_txt3')}
                </Typography>
              </Box>
            </Container>
          )
        }
      />

      <DialogCommon
        kTitle="Cookies"
        kMaxWidth="sm"
        kOpenDialogLists={openModalMessage}
        kOnClose={() => { toggleMessagesModal(); }}
        kOnSecondary={async () => { togglePreferencesCookies(); }}
        kSecondaryBtnText={t('cookies.preferences')}
        kDisableContentPadding
        kFullScreenMobile={false}
        kContent={
          (
            <>
              <Box
                sx={{
                  fontSize: 16,
                  fontWeight: 300,
                  lineHeight: 1.4,
                  color: LmiTheme.light.systemBackground.secondary,
                  textAlign: 'center',
                  py: 2,
                  px: 2,
                }}
              >
                {t('cookies.customer_support')}
              </Box>
            </>
          )
        }
      />
      {loadingZe
        ? (
          <Stack
            sx={{
              position: 'fixed',
              width: isMobile ? '48px' : '135px',
              height: isMobile ? '48px' : '46px',
              margin: isMobile ? '12px 20px  !important' : '14px 20px  !important',
              ...ZendeskLoadingStyle,
            }}
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <CircularProgress />
            <Typography
              component="p"
              sx={{ display: isMobile ? 'none' : 'block' }}
            >
              {t('cookies.support')}
            </Typography>
          </Stack>
        )
        : <></>}

    </>
  );
}

export default forwardRef(BannerCookies);

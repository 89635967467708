import {
  GET_ALL,
  DETAILS,
  GET_BY_ID,
  PRECING_CHANGE,
  CLEAR,
  FILTER_CIRCLE_ITEMS,
  CIRCLES_TYPE,
  GET_SEARCHED_CIRCLES,
  UPDATE_SUGGESTED_CIRCLES,
  UPDATE_SEARCHED_CIRCLES,
  INFO_IS_LOADING,
  LOAD_SEARCH_CIRCLES,
  LOAD_CIRCLES,
  LOAD_CIRCLES_DETAILS,
} from '../../types';

export default (state: any, action: any) => {
  switch (action.type) {
    case GET_ALL:
      return {
        ...state,
        circles: action.payload,
        suggestedCircles: action.suggestedCircles,
        circlesTypeToShow: action.payload.length > 0 ? '' : 'suggested',
      };
    case DETAILS:
      const
        circle = action.payload;
      return {
        ...state,
        circle,
        loading: false,
        members: action.members,
        items: action.items,
      };
    case GET_BY_ID:
      const {
        images,
        condictions,
        accesories,
        itemBlockedIndDates,
      } = action.payload;
      return {
        ...state,
        images,
        condictions,
        accesories,
        itemBlockedIndDates,
        loading: false,
      };
    case PRECING_CHANGE:
      return {
        ...state,
        precingType: action.payload,
      };
    case FILTER_CIRCLE_ITEMS: {
      return {
        ...state,
        itemsFiltered: action.itemsFiltered,
      };
    }
    case CIRCLES_TYPE: {
      return {
        ...state,
        circlesTypeToShow: action.payload,
      };
    }
    case GET_SEARCHED_CIRCLES: {
      return {
        ...state,
        searchedCircles: action.payload,
        circlesTypeToShow: 'searched',
      };
    }
    case UPDATE_SUGGESTED_CIRCLES: {
      return {
        ...state,
        suggestedCircles: action.payload,
        circle: action.circle,
      };
    }
    case UPDATE_SEARCHED_CIRCLES: {
      return {
        ...state,
        searchedCircles: action.payload,
        circle: action.circle,
      };
    }
    case INFO_IS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_SEARCH_CIRCLES: {
      return {
        ...state,
        loadingSearch: action.payload,
      };
    }
    case LOAD_CIRCLES: {
      return {
        ...state,
        isLoadedCircles: action.payload,
      };
    }
    case LOAD_CIRCLES_DETAILS: {
      return {
        ...state,
        isLoadedCircleDetails: action.payload,
      };
    }
    case CLEAR:
      const initialState = action.payload;
      return { ...initialState };

    default:
      return state;
  }
};

/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable max-lines */
/* eslint import/no-unresolved: [2, { commonjs: true, amd: true }] */
import { useReducer } from 'react';
import * as AWS from 'aws-sdk/global';
// import { S3 } from 'aws-sdk';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Storage } from 'aws-amplify';
import {
  ClsModCreateUpdateItemMaxRate, ClsModDeleteItem, ClsModGetItemBlockedIndDates, ClsModGetItemDetailInfo, ClsModGetItemImages,
  ClsModGetItemPendingReservations, ClsModGetItemRelatedAccessories, ClsModGetItemRentedUnavailableDates,
  ClsModGetUserTermsAndConditions, CurrencyEnum, CurrencySourceEnum,
} from '@lendmeit/api_backend_lmi/dist/models';
import { v4 as uuid } from 'uuid';
import AddEditItemContext from './addEditItemContext';
import AddEditItemReducer from './addEditItemReducer';
import {
  ITEM_BASIC_INFO_CHANGED,
  ITEM_INFO_LOAD,
  ACCESORY_ADD_EDIT,
  ACCESORY_UPDATE_LIST,
  REQUIREMENT_ADD_EDIT,
  REQUIREMENT_UPDATE_LIST,
  IMAGES_UPDATE_LIST,
  VIDEOS_UPDATE_LIST,
  CHECK_VALIDATIONS,
  UPDATE_VALIDATION_STATUS,
  UPDATE_UPDATED_ITEM_STATUS,
  CHECK_ACC_VALIDATIONS,
  UPDATE_ACC_VALIDATION_STATUS,
  CHECK_REQ_VALIDATIONS,
  UPDATE_REQ_VALIDATION_STATUS,
  DELETED_IMAGES_UPDATE_LIST,
  DELETED_VIDEOS_UPDATE_LIST,
  BLOCKED_IND_DATES_UPDATE_LIST,
  SET,
  RESET_ITEM_INFO,
  INFO_IS_LOADING,
  ITEM_INFO_LOAD_REFRESH_PAGE,
  REMOVE_REQUIREMENT,
  PRECING_TYPE,
  PRECING_CHANGE,
  CHECK_ROADIE_FIELDS,
  SET_ADD_ITEM_FLAG,
  VALID_ERRORS,
  CLEAN_ERRORS,
} from '../../types';
import {
  AddItemInfoModel, ItemImageModel, UserTermsModel, emptyItemModel,
} from '../../page/BackOffice/Listing/Components/AddItem/AddItem.models';

import { ItemAccessoriesModel } from '../../page/BackOffice/Listing/Components/AddItem/Components/ItemAccessories/ItemAccessories.models';
import ApiLMI from '../../services/apilmi';
import videoThumbnailGet from '../../services/videoThumbnailGet';

const AddEditItemState = (props: any) => {
  const history = useHistory();
  const initialAddEditAccessoryInfo: ItemAccessoriesModel = {
    idRelItemAccesorie: 0,
    idCatLndItem: 0,
    name: '',
    actve: false,
    dscription: '',
    dailyRentalPrice: 0,
    hourlyRentalPrice: 0,
    weeklyRentalPrice: 0,
    monthlyRentalPrice: 0,
    length: 0,
    width: 0,
    height: 0,
    weight: 0,
    replacementCost: 0,
    generatedNewID: '',
  };

  const initialAddEditRequirementInfo: UserTermsModel = {

    idTerm: 0,
    idCatLndUser: 0,
    active: true,
    termDetail: '',
    creationDate: '',
    modifyDate: '',
    relatedToItem: true,
    generatedNewID: '',
  };

  const initialState: AddItemInfoModel = {
    ...emptyItemModel(),
  };
  const [state, dispatch] = useReducer(AddEditItemReducer, initialState);
  AWS.config.region = process.env.REACT_APP_REGION;

  const updateDraftItemInfo = async (values: any) => {
    if (values?.idCatLndItem == 0) {
      localStorage.setItem('draftItemInfo', JSON.stringify(values));
    }
  };
  const updateDraftAccessoriesInfo = async (values: any) => {
    localStorage.setItem('draftAccessoriesInfo', JSON.stringify(values));
  };
  const updateDraftRequirementList = async (values: any) => {
    localStorage.setItem('draftRequirementList', JSON.stringify(values));
  };

  const updateBlockedIndDates = async (values: any) => {
    localStorage.setItem('draftBlockedIndDates', JSON.stringify(values));
  };
  const updateItemIdImage = async (values: any) => {
    localStorage.setItem('draftItemIdImage', JSON.stringify(values));
  };
  const updateItemIdVideo = async (values: any) => {
    const draftItemIdVideo: any = [];
    values.forEach((itemIdVideo: any) => {
      if (itemIdVideo?.sizeInMB < 5) {
        draftItemIdVideo.push(itemIdVideo);
      }
    });
    localStorage.setItem('draftItemIdVideo', JSON.stringify(draftItemIdVideo));
  };

  const updateItemInfo = async (values: any) => {
    dispatch({
      type: ITEM_BASIC_INFO_CHANGED,
      itemInfo: values,
    });
  };

  const updateAccessoryInfo = async (values: any) => {
    dispatch({
      type: ACCESORY_ADD_EDIT,
      addEditAccessoryInfo: values,
      checkAccValidate: false,
      areAccFieldsValidated: false,
    });
  };

  const updateAccessoriesList = async (values: any) => {
    dispatch({
      type: ACCESORY_UPDATE_LIST,
      itemAccessories: values,
      checkAccValidate: false,
    });
  };

  const updateRequirementInfo = async (values: any) => {
    dispatch({
      type: REQUIREMENT_ADD_EDIT,
      addEditRequirementInfo: values,
      checkReqValidate: false,
    });
  };

  const updateRequirementsList = async (values: any) => {
    dispatch({
      type: REQUIREMENT_UPDATE_LIST,
      addEditRequirementInfo: values,
      checkReqValidate: false,
    });
  };

  const updateItemImages = async (values: any) => {
    dispatch({
      type: IMAGES_UPDATE_LIST,
      itemImages: values,
    });
  };

  const updateDeletedImages = async (values: any) => {
    dispatch({
      type: DELETED_IMAGES_UPDATE_LIST,
      deletedImages: values,
    });
  };

  const updateItemVideos = async (values: any) => {
    dispatch({
      type: VIDEOS_UPDATE_LIST,
      itemVideos: values,
    });
  };

  const updateDeletedVideos = async (values: any) => {
    dispatch({
      type: DELETED_VIDEOS_UPDATE_LIST,
      deletedVideos: values,
    });
  };

  const updateItemBlockedIndDates = async (values: any) => {
    dispatch({
      type: BLOCKED_IND_DATES_UPDATE_LIST,
      blockedIndDates: values,
    });
  };

  const cleanErrorsItem = async () => {
    dispatch({
      type: CLEAN_ERRORS,
    });
  };

  const checkValidations = async (values: any) => {
    const {
      replacementCost = '',
      address = '',
      dscription = '',
      name = '',
    } = state.itemInfo;
    if (
      !replacementCost
      || replacementCost === 0
      || address === ''
      || state.itemImages.length === 0
      || dscription === ''
      || name === ''
    ) {
      dispatch({
        type: VALID_ERRORS,
        errors: {
          replacementCost: !replacementCost || replacementCost === 0,
          address: address === '',
          images: state.itemImages.length === 0,
          descrip: dscription === '',
          title: name === '',
        },
      });
    }
    dispatch({
      type: CHECK_VALIDATIONS,
      checkValidate: values,
    });
  };

  const checkRoadieFields = async (values: any) => {
    dispatch({
      type: CHECK_ROADIE_FIELDS,
      checkRoadie: values,
    });
  };

  const checkAccValidations = async (values: any) => {
    dispatch({
      type: CHECK_ACC_VALIDATIONS,
      checkAccValidate: values,
    });
  };

  const checkReqValidations = async (values: any) => {
    dispatch({
      type: CHECK_REQ_VALIDATIONS,
      checkReqValidate: values,
    });
  };

  const updateValidationStatus = async (values: any) => {
    dispatch({
      type: UPDATE_VALIDATION_STATUS,
      areFieldsValidated: values,
      checkValidate: false,
      areReqFieldsValidated: false,
      checkRoadie: false,
    });
  };

  const updateUpdatedItemStatus = async (values: any) => {
    dispatch({
      type: UPDATE_UPDATED_ITEM_STATUS,
      isItemSavedUpdated: values,
      areFieldsValidated: false,
      checkValidate: false,
    });
  };

  const updateAccValidationStatus = async (values: any) => {
    dispatch({
      type: UPDATE_ACC_VALIDATION_STATUS,
      areAccFieldsValidated: values,
      checkAccValidate: false,
    });
  };

  const updateReqValidationStatus = async (values: any) => {
    dispatch({
      type: UPDATE_REQ_VALIDATION_STATUS,
      areReqFieldsValidated: values,
      checkReqValidate: false,
    });
  };

  const setItemInfo = async (itemdModel: AddItemInfoModel, itemImages: Array<ItemImageModel>, itemVideos: Array<ItemImageModel>) => {
    dispatch({
      type: SET,
      payload: itemdModel,
      itemImages,
      itemVideos,
      areFieldsValidated: false,
      deletedImages: [],
      deletedVideos: [],
    });
  };

  const resetItemInfo = async () => {
    dispatch({
      type: RESET_ITEM_INFO,
      itemInfo: initialState,
      itemImages: [],
      itemVideos: [],
      areFieldsValidated: false,
      checkValidate: false,
    });
  };

  const deleteRequirement = async () => {
    const id = state.addEditRequirementInfo.idTerm;

    if (id > 0) {
      state.userTerms = state.userTerms
        .filter((r: any) => r.idTerm !== id);
    } else if (state.addEditRequirementInfo.generatedNewID
      && state.addEditRequirementInfo.generatedNewID != '') {
      state.userTerms = state.userTerms
        .filter((r: any) => r.generatedNewID !== state.addEditRequirementInfo.generatedNewID);
    }

    dispatch({
      type: REMOVE_REQUIREMENT,
      payload: state.userTerms,
    });

    return true;
  };

  const isLoading = async () => {
    dispatch({
      type: INFO_IS_LOADING,
    });
  };

  const setActivesRentalPrice = async (active: boolean, rental: PRECING_TYPE) => {
    let {
      isHourlyRental,
      isDailyRental,
      isWeeklyRental,
      isMonthlyRental,
    } = state;

    switch (rental) {
      case 'HOURLY':
        isHourlyRental = active;
        break;
      case 'DAILY':
        isDailyRental = active;
        break;
      case 'WEEKLY':
        isWeeklyRental = active;
        break;
      case 'MONTHLY':
        isMonthlyRental = active;
        break;
      default:
        break;
    }

    dispatch({
      type: PRECING_CHANGE,
      payload: {
        isHourlyRental,
        isDailyRental,
        isWeeklyRental,
        isMonthlyRental,
      },
    });
  };

  const getItemInfo = async (idCatLndItem: number) => {
    const userTermsAndConditions: Array<UserTermsModel> = await userGetUserTermsAndConditions(idCatLndItem);
    try {
      if (idCatLndItem > 0) {
        // let itemdModel: AddItemInfoModel;

        const itemAccessoriesModel: Array<ItemAccessoriesModel> = await getItemAccesories(idCatLndItem);
        const itemImages: Array<ItemImageModel> = await getItemImages(idCatLndItem);
        const itemVideos: Array<ItemImageModel> = await getItemVideos(idCatLndItem);

        const itemBlockedIndDates: any = await getItemBlockedIndDates(idCatLndItem);
        const itemRentedUnavDates: any = await getItemGetRentedUnavailableDates(idCatLndItem);

        // if item is null , the page has been refreshed
        if (state.itemInfo == undefined || state.itemInfo == null) {
          isLoading();
          const itemdModel = await getItemInfoById(idCatLndItem);

          if (itemdModel != undefined) {
            if (itemdModel.circlesOnly && itemdModel.active) {
              itemdModel.itemStatus = 2;
            } if (itemdModel.exploreOnly && itemdModel.active) {
              itemdModel.itemStatus = 3;
            } if (itemdModel.circlesAndExplore && itemdModel.active) {
              itemdModel.itemStatus = 1;
            } if (!itemdModel.active) {
              itemdModel.itemStatus = 0;
            }
            itemdModel.roadie = itemdModel.roadie ?? false;
            itemdModel.length = itemdModel.length != null ? itemdModel.length : 0;
            itemdModel.width = itemdModel.width != null ? itemdModel.width : 0;
            itemdModel.height = itemdModel.height != null ? itemdModel.height : 0;
            itemdModel.weight = itemdModel.weight != null ? itemdModel.weight : 0;
            itemdModel.startDeliveryTime = itemdModel.startDeliveryTime != null
              && itemdModel.startDeliveryTime != ''
              ? itemdModel.startDeliveryTime
              : '8:00AM';
            itemdModel.endDeliveryTime = itemdModel.endDeliveryTime != null
              && itemdModel.endDeliveryTime != ''
              ? itemdModel.endDeliveryTime
              : '6:00PM';
            itemdModel.availabilityFromTime = itemdModel.availabilityFromTime != null
              && itemdModel.availabilityFromTime != ''
              ? itemdModel.availabilityFromTime
              : '9:00AM';
            itemdModel.availabilityToTime = itemdModel.availabilityToTime != null
              && itemdModel.availabilityToTime != ''
              ? itemdModel.availabilityToTime
              : '5:00PM';
            itemdModel.currency = itemdModel.currency ?? 'USD';
            itemdModel.currencySource = itemdModel.currencySource ?? 'Payout';

            dispatch({
              type: ITEM_INFO_LOAD_REFRESH_PAGE,
              itemInfo: itemdModel,
              itemAccessories: itemAccessoriesModel,
              userTerms: userTermsAndConditions,
              addEditAccessoryInfo: initialAddEditAccessoryInfo,
              addEditRequirementInfo: initialAddEditRequirementInfo,
              deletedImages: [],
              deletedVideos: [],
              itemImages,
              itemVideos,
              blockedIndDates: itemBlockedIndDates,
              unavRentedDates: itemRentedUnavDates,
              areFieldsValidated: false,
              areAccFieldsValidated: false,
            });
          } else {
            history.goBack();
          }
        } else {
          dispatch({
            type: ITEM_INFO_LOAD,
            // itemInfo: initialState,
            itemInfo: state.itemInfo,
            itemAccessories: itemAccessoriesModel,
            userTerms: userTermsAndConditions,
            addEditAccessoryInfo: initialAddEditAccessoryInfo,
            addEditRequirementInfo: initialAddEditRequirementInfo,
            deletedImages: [],
            deletedVideos: [],
            itemImages,
            itemVideos,
            blockedIndDates: itemBlockedIndDates,
            unavRentedDates: itemRentedUnavDates,
            areFieldsValidated: false,
            areAccFieldsValidated: false,
          });
        }
      } else {
        // if item is null , the page has been refreshed
        // eslint-disable-next-line no-lonely-if
        const item_info = localStorage.getItem('draftItemInfo');
        const item_accessories_info = localStorage.getItem('draftAccessoriesInfo');
        const item_requirement = localStorage.getItem('draftRequirementList');
        const item_blockedIndDates = localStorage.getItem('draftBlockedIndDates');
        const item_itemIdImage = localStorage.getItem('draftItemIdImage');
        const item_itemIdVideo = localStorage.getItem('draftItemIdVideo');
        const item_info_json = item_info ? JSON.parse(item_info) : initialState;
        const item_accessories_info_json = item_accessories_info !== null ? JSON.parse(item_accessories_info) : [];
        const item_requirement_json = item_requirement !== null ? JSON.parse(item_requirement) : userTermsAndConditions;
        const item_blockedIndDates_json = item_blockedIndDates !== null ? JSON.parse(item_blockedIndDates) : [];
        const item_itemIdImage_json = item_itemIdImage !== null ? JSON.parse(item_itemIdImage) : [];
        const item_itemIdVideo_json = item_itemIdVideo !== null ? JSON.parse(item_itemIdVideo) : [];

        if ((state.itemInfo == undefined || state.itemInfo == null)) {
          dispatch({
            type: ITEM_INFO_LOAD_REFRESH_PAGE,
            itemInfo: item_info_json,
            itemAccessories: item_accessories_info_json,
            itemImages: item_itemIdImage_json,
            itemVideos: item_itemIdVideo_json,
            userTerms: item_requirement_json,
            addEditAccessoryInfo: initialAddEditAccessoryInfo,
            addEditRequirementInfo: initialAddEditRequirementInfo,
            deletedImages: [],
            deletedVideos: [],
            blockedIndDates: item_blockedIndDates_json,
            unavRentedDates: [],
            areFieldsValidated: false,
            areAccFieldsValidated: false,
          });
        } else {
          dispatch({
            type: ITEM_INFO_LOAD,
            itemInfo: item_info_json,
            itemAccessories: item_accessories_info_json,
            itemImages: item_itemIdImage_json,
            itemVideos: item_itemIdVideo_json,
            userTerms: item_requirement_json,
            addEditAccessoryInfo: initialAddEditAccessoryInfo,
            addEditRequirementInfo: item_requirement_json,
            deletedImages: [],
            deletedVideos: [],
            blockedIndDates: item_blockedIndDates_json,
            unavRentedDates: [],
            areFieldsValidated: false,
            areAccFieldsValidated: false,
          });
        }
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const setFlag = async () => {
    dispatch({
      type: SET_ADD_ITEM_FLAG,
    });
  };

  const getItemInfoById = async (idCatLndItem: number) => {
    const api = ApiLMI();
    const body: ClsModGetItemDetailInfo = {
      idCatLndItem,
      // idTerm: 0,
      toc: 'jsjdjajd',
    };
    try {
      const result = await api.itemGetUserLndItems(body);
      const data = JSON.parse(result.data.toString());
      data.Response[0].requireIdVerify = data.Response[0].requiresIdVerify;
      data.Response[0].acceptOffers = data.Response[0].accept_offers != null
        ? data.Response[0].accept_offers
        : false;

      return data.Response[0];
    } catch (e) {
      return null;
    }
  };

  const getItemAccesories = async (idCatLndItem: number) => {
    const api = ApiLMI();
    const body: ClsModGetItemRelatedAccessories = {
      idCatLndItem,
      toc: 'jsjdjajd',
    };
    try {
      const result = await api.itemGetItemRelatedAccessoriesEdit(body);
      const data = JSON.parse(result.data.toString());
      return data.Response;
    } catch (e) {
      return 0;
    }
  };

  const getItemBlockedIndDates = async (idCatLndItem: number) => {
    const api = ApiLMI();
    const body: ClsModGetItemBlockedIndDates = {
      idCatLndItem,
      toc: 'jsjdjajd',
    };
    try {
      const result = await api.itemGetItemBlockedIndDates(body);
      const data = JSON.parse(result.data.toString());
      const arrayDates: any = [];
      data.Response.forEach((e: any) => {
        arrayDates.push(e.blockedDate);
      });
      // return data.Response;
      return arrayDates;
    } catch (e) {
      return 0;
    }
  };

  const getItemGetRentedUnavailableDates = async (idCatLndItem: number) => {
    const api = ApiLMI();
    const body: ClsModGetItemRentedUnavailableDates = {
      idCatLndItem,
      toc: 'jsjdjajd',
    };
    try {
      const result = await api.itemGetItemRentedUnavailableDates(body);
      const data = JSON.parse(result.data.toString());
      const arrayDates: any = [];
      data.Response.forEach((e: any) => {
        arrayDates.push(e.no_date);
      });
      // return data.Response;
      return arrayDates;
    } catch (e) {
      return 0;
    }
  };

  const userGetUserTermsAndConditions = async (idCatLndItem: number) => {
    const api = ApiLMI();
    const body: ClsModGetUserTermsAndConditions = {
      idCatLndItem,
      toc: 'jsjdjajd',
      ownTerms: 0,
    };
    try {
      const result = await api.userGetUserTermsAndConditions(body);
      const data = JSON.parse(result.data.toString());
      if (data.Error == '') {
        return data.Response;
      }
      return [];
    } catch (e) {
      console.log(e);
      return 0;
    }
  };

  const getItemImages = async (idCatLndItem: number) => {
    const api = ApiLMI();
    const body: ClsModGetItemImages = {
      toc: 'authToken',
      idCatLndItem,
    };
    try {
      const result = await api.itemGetItemImages(body);
      const data = JSON.parse(result.data.toString());
      const images = data.Response.filter((element: any) => element.assetType === 'picture');
      return images;
    } catch (e) {
      return 0;
    }
  };

  const getItemVideos = async (idCatLndItem: number) => {
    const api = ApiLMI();
    const body: ClsModGetItemImages = {
      toc: 'authToken',
      idCatLndItem,
    };
    try {
      const result = await api.itemGetItemVideos(body);
      const data = JSON.parse(result.data.toString());
      return data.Response;
    } catch (e) {
      return null;
    }
  };

  const validateReplacementCostAndCurrency = (itemInfo: any, currency: any, t: any) => {
    if (itemInfo.idCatTypeItem === 1 && itemInfo.replacementCost < 5 && currency === 'USD') {
      toast.error(t('listing_data.item_value_info'));
      return false;
    }
    if (itemInfo.idCatTypeItem === 1 && itemInfo.replacementCost < 50 && currency === 'MXN') {
      toast.error(t('listing_data.item_value_info_mxn'));
      return false;
    }
    return true;
  };

  const validateItemImages = (itemImages: any, t: any) => {
    if (!itemImages || (itemImages && itemImages.length === 0)) {
      toast.error(t('listing_data.photo_required'));
      return false;
    }
    return true;
  };

  const validateRentalPrices = (itemInfo: any, rentalOptions: any, t: any) => {
    const {
      isHourlyRental, isDailyRental, isWeeklyRental, isMonthlyRental,
    } = rentalOptions;

    if (
      (isHourlyRental && itemInfo.hourlyRentalPrice >= 5)
      || (isDailyRental && itemInfo.dailyRentalPrice >= 5)
      || (isWeeklyRental && itemInfo.weeklyRentalPrice >= 5)
      || (isMonthlyRental && itemInfo.monthlyRentalPrice >= 5)
    ) {
      return true;
    }
    toast.error(t('listing_data.minimum_rent'));
    return false;
  };

  const createUpdateItemInfo = async (
    itemInfo: any,
    itemImages: any,
    userTerms: any,
    itemVideos: any,
    deletedVideos: any,
    itemAccessories: any,
    deletedImages: any,
    blockedIndDates: any,
    rentalOptions: any,
    currency: any,
    currencySource: any,
    t: any,
  ) => {
    localStorage.setItem('isSavingItem', 'true');

    try {
      const idtoast = toast.loading(t('listing_data.saving_info'));
      itemInfo.toc = 'testToken';
      const format1 = 'YYYY-MM-DD HH:mm:ss';
      itemInfo.startDeliveryTime = moment(itemInfo.startDeliveryTime).format(format1);
      itemInfo.endDeliveryTime = moment(itemInfo.endDeliveryTime).format(format1);
      itemInfo.availabilityFromTime = moment(itemInfo.availabilityFromTime, ['MM/DD/YYYYTHH:mm', 'DD/MM/YYYYTHH:mm', 'YYYY-MM-DDTHH:mm']).format(format1);
      itemInfo.availabilityToTime = moment(itemInfo.availabilityToTime, ['MM/DD/YYYYTHH:mm', 'DD/MM/YYYYTHH:mm', 'YYYY-MM-DDTHH:mm']).format(format1);

      if (
        !validateReplacementCostAndCurrency(itemInfo, currency, t)
        || !validateRentalPrices(itemInfo, rentalOptions, t)
      ) {
        toast.dismiss(idtoast);
        return 0;
      }
      itemInfo = {
        ...itemInfo, ...rentalOptions, acceptOffers: itemInfo.accept_offers, currency,
      };
      const resultItem = await createUpdateItemBasicInfo(itemInfo, currencySource);

      const resultIdItem = JSON.parse(resultItem.data.toString()).Response[0].IdItemLnd;
      itemInfo.idCatLndItem = resultIdItem;

      if (resultItem) {
        await UpdateLndItemStatuses(itemInfo);
        await saveItemAddress(itemInfo);
        const imagesUpdated = await UpdateItemImages(itemImages, resultIdItem, t);

        if (!imagesUpdated) {
          toast.error(t('error_images_not_saved'));
          toast.dismiss(idtoast);
          return 0;
        }
        await deleteItemImages(deletedImages, resultIdItem);
        await UpdateItemVideos(itemVideos, resultIdItem, t);
        await deleteItemVideos(deletedVideos, resultIdItem);
        await updateUserReqs(userTerms, resultIdItem);
        await deleteCreateAcceptOffers(itemInfo);
        await deleteCreateDeliveryPrices(itemInfo);
        const acceUpdated = await createUpdateaccessories(itemAccessories, resultIdItem);

        if (!acceUpdated) {
          toast.error(t('listing_data.problem_saving_accessories'));
          toast.dismiss(idtoast);
          return 0;
        }
        await createUpdateItemDynamicLinks(resultItem);
        await deleteBlockedIndDates(resultIdItem);
        await createUpdateItemBlockedIndDates(blockedIndDates, resultIdItem);
        await setFlag();
      }
      toast.dismiss(idtoast);
      localStorage.removeItem('isSavingItem');

      return 1;
    } catch (e) {
      console.error(e);
      toast.error(t('listing_data.problem_saving'));
      return 0;
    }
  };

  const deleteItem = async (itemInfo: any, t: any) => {
    const api = ApiLMI();
    const idtoast = toast.loading(t('listing_data.saving_info'));
    try {
      const body: ClsModGetItemPendingReservations = {
        idCatLndItem: itemInfo.idCatLndItem,
        toc: '',
      };

      // Check if item has pending reservations
      const itemReservations: any = await api.apiItemGetItemPendingReservationsPost(body);
      const itemReservationsCreated = JSON.parse(itemReservations.data).Response[0];
      if (itemReservationsCreated.pendingReservations == 0) {
        const bodyDelete: ClsModDeleteItem = {
          idCatLndItem: itemInfo.idCatLndItem,
          toc: '',
        };
        // Delete the item
        const deleteItemResponse: any = await api.apiItemDeleteItemPost(bodyDelete);
        const deleteItemResponseCreated = JSON.parse(deleteItemResponse.data).Response[0];
        if (deleteItemResponseCreated.idCatLndItem == itemInfo.idCatLndItem) {
          toast.success(t('listing_data.itemDeletedSuccesfully'));
        } else {
          toast.dismiss(idtoast);
          toast.error(t('listing_data.errorDeletingItem'));
          return 0;
        }
      } else {
        toast.dismiss(idtoast);
        toast.error(t('listing_data.transactionsInProgress'));
        return 0;
      }
      toast.dismiss(idtoast);
      return 1;
    } catch (e) {
      console.error(e);
      toast.dismiss(idtoast);
      toast.error(t('listing_data.errorCheckingStatus'));
      return 0;
    }
  };

  const saveItemAddress = async (itemInfo: AddItemInfoModel) => {
    const api = ApiLMI();
    const bodyCreateUpdateAddress = {
      adress: itemInfo.address,
      toc: 'testToken',
      idCatLndItemAddress: itemInfo.idCatLndItemAdress,
      lat: itemInfo.lat,
      lng: itemInfo.lon,
      active: true,
      name: itemInfo.extendedAddress,
      country: itemInfo.country,
      state: itemInfo.state,
      city: itemInfo.city,
      zipCode: itemInfo.zipCode,
      street: itemInfo.street,
    };
    const resultAddress = await api.itemCreateUpdateItemUserMapAdress(bodyCreateUpdateAddress);
    if (resultAddress != null) {
      const idAddress = JSON.parse(resultAddress.data.toString()).Response[0].IdItemAdress;
      itemInfo.idCatLndItemAdress = idAddress;
      const bodyUpdateAddress = {
        toc: 'testToken',
        idCatLndItem: itemInfo.idCatLndItem,
        idCatLndItemAddress: idAddress,
      };

      await api.itemUpdateItemAddress(bodyUpdateAddress);
    }
  };

  // subfunctions for creating or updating an item

  // Subsection for creating or updating item for basic information
  const createUpdateItemBasicInfo = async (itemInfo: AddItemInfoModel, currencySource: any) => {
    const api = ApiLMI();
    const currSource = currencySource == 'PhoneNumber' ? CurrencySourceEnum.PhoneNumber : CurrencySourceEnum.Payout;
    const body: ClsModCreateUpdateItemMaxRate = {

      idCatLndItem: itemInfo.idCatLndItem,
      toc: 'token',
      name: itemInfo.name,
      dscription: itemInfo.dscription,
      dscription2: '',
      brand: itemInfo.brand,
      model: itemInfo.model,
      year: parseInt(itemInfo.year),
      idCatLndCategory: itemInfo.idCatLndCategory,
      idCatLndCondition: itemInfo.idCatLndCondition,
      finish: itemInfo.finish,
      color: itemInfo.color,
      replacementCost: itemInfo.replacementCost,
      dailyRentalPrice: itemInfo.isDailyRental ? itemInfo.dailyRentalPrice : 0,
      acceptOffers: itemInfo.acceptOffers,
      zipCode: itemInfo.zipCode,
      address: itemInfo.address,
      pickUpOnly: itemInfo.delivery,
      idCatLndItemStatus: 1,
      roadie: itemInfo.roadie,
      startDeliveryTime: itemInfo.startDeliveryTime,
      endDeliveryTime: itemInfo.endDeliveryTime,
      weight: itemInfo.weight,
      idCatLndUomWeight: 1,
      height: itemInfo.height,
      width: itemInfo.width,
      length: itemInfo.length,
      idCatLndUomDimension: 1,
      lat: '',
      lon: '',
      location: '',
      active: itemInfo.active,
      usShippingCost: itemInfo.usCost,
      internationalShippingCost: itemInfo.internationalCost,
      conditions: itemInfo.conditions,
      minRentDays: itemInfo.minRentDays,
      requiresDeposit: itemInfo.requiresDeposit,
      depositPercentage: itemInfo.depositPercentage,
      depositDueDays: 0,
      fromPickUpTime: itemInfo.fromPickUpTime,
      toPickUpTime: itemInfo.toPickUpTime,
      fromDropOffTime: itemInfo.fromDropOffTime,
      toDropOffTime: itemInfo.toDropOfftime,
      securityDeposit: itemInfo.securityDeposit,
      canBeArranged: itemInfo.canBeArranged,
      isAvailable: itemInfo.active,
      exactAddress: itemInfo.exactAddress,
      requireIdVerify: itemInfo.requiresIdVerify,
      hourlyRentalPrice: itemInfo.isHourlyRental ? itemInfo.hourlyRentalPrice : 0,
      weeklyRentalPrice: itemInfo.isWeeklyRental ? itemInfo.weeklyRentalPrice : 0,
      monthlyRentalPrice:
        itemInfo.isMonthlyRental ? itemInfo.monthlyRentalPrice : 0,
      idCatTypeItem:
        itemInfo.idCatTypeItem != null ? itemInfo.idCatTypeItem : 1,
      isLenderDelivery: itemInfo.isLenderDelivery,
      physicalLat: itemInfo.physicalLat,
      physicalLon: itemInfo.physicalLon,
      physicalAddress: itemInfo.physicalAddress,
      publicIP: itemInfo.publicIP,
      availabilityTimeFrom: itemInfo.availabilityFromTime,
      availabilityTimeTo: itemInfo.availabilityToTime,
      currency: itemInfo.currency == 'USD' ? CurrencyEnum.USD : CurrencyEnum.MXN,
      currencySource: currSource,
      hasServiceMaxRate: itemInfo.hasServiceMaxRate ? itemInfo.hasServiceMaxRate : false,
      serviceMaxRate: itemInfo.serviceMaxRate,

    };
    // const result = await api.apiItemCreateUpdateItemCurrencyPost(body);
    // api.apiItemCreateUpdateItem
    const result = api.itemCreateUpdateItemMaxRate(body);
    return result;
  };

  const createUpdateItemDynamicLinks = async (resultItem: any) => {
    const api = ApiLMI();
    const resultIdItem = JSON.parse(resultItem.data.toString()).Response[0].IdItemLnd;
    if (resultIdItem != null && resultIdItem > 0) {
      const bodyItemCreateItemDetailDynamicLink = {
        idCatLndItem: resultIdItem,
        toc: 'testToken',
      };
      await api.itemCreateItemDetailDynamicLink(bodyItemCreateItemDetailDynamicLink);

      const bodycreateULPDL = {
        toc: 'testToken',
      };
      const createULPDL = await api.userCreateUserLendPageDynamicLink(bodycreateULPDL);

      return createULPDL;
    }
    return 0;
  };

  const UpdateLndItemStatuses = async (itemInfo: AddItemInfoModel) => {
    const api = ApiLMI();
    const bodyItemStatus = {
      toc: 'testToken',
      idCatLndItem: itemInfo.idCatLndItem,
      active: itemInfo.active,
      circlesOnly: itemInfo.circlesOnly,
      circlesExplore: itemInfo.circlesAndExplore,
      exploreOnly: itemInfo.exploreOnly,
    };

    const _idCatLndItemStatus = await api.itemUpdateLndItemStatuses(bodyItemStatus);
    return _idCatLndItemStatus;
  };

  const createNewPicture = async (api: any, element: any, idCatLndItem: any, controlImgs: any, t: any) => {
    const bodyE = {
      toc: 'testToken',
      profilePicturebase: element.split(',')[1],
    };

    const newPictureObject = await api.itemUploadItemPicture(bodyE);

    if (newPictureObject) {
      const { data } = newPictureObject;

      if (typeof data === 'string') {
        const result = JSON.parse(data.toString());
        const { Response, Error: error } = result;

        if (error === '' && Response.length > 0) {
          const imgUrl = Response[0].fileName;
          const bodyE2 = {
            toc: 'testToken',
            idCatLndItem,
            imgPath: imgUrl,
            position: controlImgs + 1,
          };

          await api.itemCreateUpdateLndItemImage(bodyE2);
        } else {
          toast.error(error);
          throw new Error(error);
        }
      } else {
        toast.error(t('listing_data.problem_saving'));
        throw new Error(t('listing_data.problem_saving'));
      }
    } else {
      toast.error(t('listing_data.problem_saving'));
      throw new Error(t('listing_data.problem_saving'));
    }
  };

  const updateExistingPicture = (api: any, element: any, controlImgs: any): Promise<void> => new Promise<void>(async (resolve, reject) => {
    const bodyPic = {
      idCatLndItem: element.idCatLndItem,
      fileName: element.fileName,
      position: controlImgs + 1,
      active: 1,
      toc: 'testToken',
    };

    try {
      await api.itemUpdateItemImage(bodyPic);
      resolve();
    } catch (error) {
      reject(error);
    }
  });

  const UpdateItemImages = async (itemImages: any, idCatLndItem: any, t: any) => {
    const api = ApiLMI();
    let controlImgs = 0;

    try {
      for await (const element of itemImages) {
        if (element.idDetLndItemImage == null) {
          await createNewPicture(api, element, idCatLndItem, controlImgs, t);
        } else {
          await updateExistingPicture(api, element, controlImgs);
        }
        controlImgs += 1;
      }

      return true;
    } catch (e) {
      toast.error(t('listing_data.problem_saving'));
      return false;
    }
  };

  const createVideo = async (api: any, element: any, idCatLndItem: any, position: any, t: any, fileName: any) => {
    const videoThumbnail = await videoThumbnailGet(element.file);

    const bodyE = {
      toc: 'testToken',
      profilePicturebase: videoThumbnail.split(',')[1],
    };

    const newPictureObject = await api.itemUploadItemPicture(bodyE);

    if (newPictureObject) {
      const { data: pictureData } = newPictureObject;

      const resultPicture = JSON.parse(pictureData.toString());
      const { Response: ResponsePicture } = resultPicture;
      const imgUrl = ResponsePicture[0].fileName;

      const bodyE2 = {
        idCatLndItem,
        videoPath: `${process.env.REACT_APP_VIDEO_BUCKET_FOLDER_NAME}${fileName}`,
        thumbnailPath: imgUrl,
        position,
        toc: 'querty',
      };

      const newVideoObject = await api.itemCreateUpdateLndItemVideo(bodyE2);
      if (newVideoObject != null) {
        const { data } = newVideoObject;

        if (typeof data === 'string') {
          const result = JSON.parse(data.toString());
          const { Response, Error: error } = result;

          if (error == '' && Response.length > 0) {
            // Video created successfully
          } else {
            toast.error(error);
          }
        } else {
          toast.error(t('listing_data.problem_saving'));
        }
      } else {
        toast.error(t('listing_data.problem_saving'));
      }
    } else {
      toast.error(t('listing_data.problem_saving'));
      throw new Error(t('listing_data.problem_saving'));
    }
  };

  const updateVideo = async (api: any, element: any, position: any, t: any) => {
    const bodyPic = {
      idCatLndItem: element.idCatLndItem,
      fileName: element.fileName,
      position,
      active: 1,
      toc: 'test',
    };

    const updatedVideoObject = await api.itemUpdateItemVideo(bodyPic);

    if (updatedVideoObject != null) {
      const { data } = updatedVideoObject;

      if (typeof data === 'string') {
        const result = JSON.parse(data?.toString());
        const { Response, Error: error } = result;

        if (error == '' && Response.length > 0) {
          // Video updated successfully
        } else {
          toast.error(error);
        }
      } else {
        toast.error(t('listing_data.problem_saving'));
      }
    } else {
      toast.error(t('listing_data.problem_saving'));
    }
  };

  const UpdateItemVideos = async (itemVideos: any, idCatLndItem: any, t: any) => {
    const api = ApiLMI();

    try {
      await Promise.all(
        itemVideos.map(async (element: any, index: any) => {
          if (element.idDetLndItemImage == null) {
            const fileName = `video${uuid()}.mp4`;
            await uploadItemVideo2(element, fileName);
            await createVideo(api, element, idCatLndItem, index + 1, t, fileName);
          } else {
            await updateVideo(api, element, index + 1, t);
          }
        }),
      );
    } catch (e) {
      toast.error(t('listing_data.problem_saving'));
    }
  };

  const updateUserReqs = async (userTerms: any, idCatLndItem: number) => {
    const api = ApiLMI();
    // DELETE ITEM REQS ADD ITEM REQS
    const bodyDeleteTerms = {
      toc: 'testToken',
      idCatLndItem,
    };
    await api.itemDeleteItemTermsAndConditionsRelationToItem(bodyDeleteTerms);
    if (userTerms && userTerms.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const element of userTerms) {
        const bodyNewTerm = {
          toc: 'testToken',
          termDetail: element.termDetail,
          idTerm: element.idTerm,
        };
        // eslint-disable-next-line no-await-in-loop
        const idNewTerm = await api.userCreateUpdateUserTermsAndConditions(bodyNewTerm);
        const bodyNewTerm2 = {
          toc: 'testToken',
          idCatLndItem,
          idTerm: JSON.parse(idNewTerm.data.toString()).Response[0].idTerm,
        };
        if (element.relatedToItem) {
          // eslint-disable-next-line no-await-in-loop
          await api.itemCreateRelUserTermWithItem(bodyNewTerm2);
        }
      }
    }
  };

  const deleteCreateDeliveryPrices = async (itemInfo: any) => {
    const api = ApiLMI();
    //  Delete previous delivery prices row of the item
    const bodyItemDeleteItemDeliveryPrices = {
      idCatLndItem: itemInfo.idCatLndItem,
      toc: 'testToken',
    };
    await api.itemDeleteItemDeliveryPrices(bodyItemDeleteItemDeliveryPrices);

    await api.itemCreateItemDeliveryPrices(itemInfo);
  };

  const deleteCreateAcceptOffers = async (itemInfo: any) => {
    const api = ApiLMI();
    //  Delete previous salePrice value
    const bodyItemDeleteItemForSalePrice = {
      idCatLndItem: itemInfo.idCatLndItem,
      toc: 'testToken',
    };
    await api.itemDeleteItemForSalePrice(bodyItemDeleteItemForSalePrice);

    if (itemInfo.acceptOffers && itemInfo.salePrice > 0) {
      const bodyItemUpdateItemAcceptOffersPriceSale = {
        idCatLndItem: itemInfo.idCatLndItem,
        toc: 'testToken',
        acceptOffers: itemInfo.acceptOffers ? 1 : 0,
        salePrice: itemInfo.salePrice,
      };
      await api.itemUpdateItemAcceptOffersPriceSale(bodyItemUpdateItemAcceptOffersPriceSale);
    }
  };

  const createUpdateaccessories = async (itemAccessories: any, idCatLndItem: number) => {
    const api = ApiLMI();
    try {
    // UPDATE ACCESSORIES
    // eslint-disable-next-line no-restricted-syntax
      for (const element of itemAccessories) {
        const bodyNewAccessory = {
          toc: 'testToken',
          idCatLndItem,
          idRelItemAccesorie: element.idRelItemAccesorie,
          name: element.name,
          dsription: element.dscription,
          rentalPrice: element.dailyRentalPrice,
          active: element.actve,
          hourlyPrice: element.hourlyRentalPrice,
          weeklyPrice: element.weeklyRentalPrice,
          monthlyPrice: element.monthlyRentalPrice,
          replacementCost: element.replacementCost,
          weight: element.weight,
          height: element.height,
          width: element.width,
          length: element.length,
        };
        // eslint-disable-next-line no-await-in-loop
        const updateItemAccesorie = await api.itemCreateUpdateItemAccesorieWithDelivery(bodyNewAccessory);
        if (updateItemAccesorie != null) {
          const { data } = updateItemAccesorie;
          if (typeof data != 'string') {
            return false;
          }
        }
      } return true;
    } catch (e) {
      return false;
    }
  };

  const deleteBlockedIndDates = async (idCatLndItem: any) => {
    const api = ApiLMI();
    //  Delete previous salePrice value
    const bodyItemDeleteItemForSalePrice = {
      idCatLndItem,
      toc: 'testToken',
    };
    await api.itemDeleteItemBlockedIndDates(bodyItemDeleteItemForSalePrice);
  };

  const createUpdateItemBlockedIndDates = async (itemBlockedDates: any, idCatLndItem: number) => {
    const api = ApiLMI();
    // eslint-disable-next-line no-restricted-syntax
    for (const element of itemBlockedDates) {
      const bodyNewAccessory = {
        toc: 'testToken',
        idCatLndItem,
        idatLndBlockedIndDates: 0,
        name: '',
        title: '',
        description: '',
        active: true,
        blockedDate: element,
      };
      // eslint-disable-next-line no-await-in-loop
      await api.itemCreateUpdateItemBlockedIndDates(bodyNewAccessory);
    }
  };

  const deleteItemImages = async (deletedImages: any, idCatLndItem: number) => {
    const api = ApiLMI();
    let controlImgs = 0;
    for (const element of deletedImages) {
      // Update existing picture position
      const bodyPic = {
        idCatLndItem,
        fileName: element.fileName,
        position: controlImgs + 1,
        active: 0,
        toc: 'testToken',
      };

      await api.itemUpdateItemImage(bodyPic);

      controlImgs++;
    }
  };

  const deleteItemVideos = async (deletedVideos: any, idCatLndItem: number) => {
    const api = ApiLMI();
    let controlVideos = 0;

    for (const element of deletedVideos) {
      // Update existing video position
      const bodyPic = {
        idCatLndItem,
        fileName: element.fileName,
        position: controlVideos,
        active: 0,
        toc: 'testToken',
      };

      await api.itemUpdateItemVideo(bodyPic);

      controlVideos++;
    }
  };

  const uploadItemVideo2 = async (file: any, fileName: any) => {
    try {
      const result = await new Promise((resolve, reject) => {
        Storage.put(`${fileName}`, file.file, {
          customPrefix: {
            public: process.env.REACT_APP_VIDEO_BUCKET_FOLDER_NAME,
          },
        })
          .then((resp) => {
            console.log(resp);
            resolve({});
          }).catch((err) => {
            console.log(err);
            reject(err);
          });
      });
      console.log(result);
      return Location;
    } catch (e: any) {
      console.log(e);
      return '';
    }
  };

  const convertVideoInBackend = async (file:any, sizeInMB: any, t: any) => {
    try {
      isLoading();

      const formData = new FormData();
      formData.append('videoFile', file);
      const requestOptions = {
        method: 'POST',
        body: formData,
      };

      await fetch(process.env.REACT_APP_CONVERT_MOV_VIDEO!, requestOptions).then((data) => data.json()).then((data) => {
        const objNewVideo = {
          base64: `data:video/mp4;base64,${data.fileContents}`,
          name: `vid_${uuid()}_${file.name}`,
          file, // Almacenar el archivo completo aquí
          sizeInMB: sizeInMB.toFixed(2),
        };
        state.itemVideos?.push(objNewVideo);
      });
    } catch (e: any) {
      toast.error(t('add_item_page.error_video'));
    }
  };

  return (
    <AddEditItemContext.Provider
      value={
        {
          ...state,
          canModify: state.canModify,
          itemInfo: state.itemInfo,
          itemAccessories: state.itemAccessories,
          userTerms: state.userTerms,
          itemImages: state.itemImages,
          deletedImages: state.deletedImages,
          itemVideos: state.itemVideos,
          deletedVideos: state.deletedVideos,
          addEditAccessoryInfo: state.addEditAccessoryInfo,
          addEditRequirementInfo: state.addEditRequirementInfo,
          checkValidate: state.checkValidate,
          checkRoadie: state.checkRoadie,
          areFieldsValidated: state.areFieldsValidated,
          areAccFieldsValidated: state.areAccFieldsValidated,
          areReqFieldsValidated: state.areReqFieldsValidated,
          checkReqValidate: state.checkReqValidate,
          checkAccValidate: state.checkAccValidate,
          changedReqFields: state.changedReqFields,
          changedAccFields: state.changedAccFields,
          blockedIndDates: state.blockedIndDates,
          unavRentedDates: state.unavRentedDates,
          isItemSavedUpdated: state.isItemSavedUpdated,
          isHourlyRental: state.isHourlyRental,
          isDailyRental: state.isDailyRental,
          isWeeklyRental: state.isWeeklyRental,
          isMonthlyRental: state.isMonthlyRental,
          errors: state.errors,
          createUpdateItemInfo,
          deleteItem,
          setFlag,
          setItemInfo,
          getItemInfo,
          updateItemInfo,
          updateDraftItemInfo,
          updateAccessoryInfo,
          updateDraftAccessoriesInfo,
          updateAccessoriesList,
          updateRequirementInfo,
          updateRequirementsList,
          updateDraftRequirementList,
          updateItemImages,
          updateItemVideos,
          checkValidations,
          updateItemIdImage,
          updateItemIdVideo,
          updateValidationStatus,
          checkAccValidations,
          checkReqValidations,
          updateAccValidationStatus,
          updateReqValidationStatus,
          updateDeletedImages,
          updateDeletedVideos,
          updateItemBlockedIndDates,
          updateBlockedIndDates,
          updateUpdatedItemStatus,
          resetItemInfo,
          deleteRequirement,
          setActivesRentalPrice,
          checkRoadieFields,
          cleanErrorsItem,
          validateItemImages,
          convertVideoInBackend,
        }
      }
    >
      {props.children}
    </AddEditItemContext.Provider>
  );
};

export default AddEditItemState;

import {
  Box, Typography, Stack, FormControlLabel, useTheme, useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogCommon, LmiTheme } from '../../../../../../components/Common';
import AddEditCirclesContext from '../../../../../../context/circles/addEditCirclesContext';

export default function CircleAvailable() {
  const circlesContextLog = useContext(AddEditCirclesContext);
  const {
    circleInfoEdit,
    circleUpdated,
    enableDisableCircle,
  }: any = circlesContextLog;

  const [openDialog, setOpenDialog] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value);
    setOpenDialog(!openDialog);
  };

  const activeDeactiveCircle = () => {
    enableDisableCircle();
  };

  const Theme = useTheme();

  const isMobile = useMediaQuery(Theme.breakpoints.down('md'));
  useEffect(() => {
    if (circleUpdated) {
      setOpenDialog(false);
    }
  }, [circleUpdated]);

  const IOSSwitch = styled((props: SwitchProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#038DE2' : '#038DE2',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  const { t } = useTranslation('global');

  return (
    <>
      <Box>
        <Stack minHeight="53px" pr={2} pl={3} direction="row" justifyContent="space-between" alignItems="center" spacing={0}>
          {
            isMobile ? (
              <Typography
                fontSize="17px"
                color={LmiTheme.light.systemBackground.tertiary}
                sx={{ mr: '25px', pt: '10px', pb: '10px' }}
              >
                {t('circles.add_circle_page.circle_able_users')}
              </Typography>
            ) : (
              <Typography fontSize="17px" color={LmiTheme.light.systemBackground.tertiary}>
                {t('circles.add_circle_page.circle_able_users')}
              </Typography>
            )
          }
          <FormControlLabel
            label=""
            control={(
              <IOSSwitch
                checked={circleInfoEdit?.active}
                onChange={handleChange}
                sx={{ mr: -1 }}
              />
            )}
          />
        </Stack>
      </Box>

      <DialogCommon
        kTitle={circleInfoEdit?.active ? t('circles.add_circle_page.disable') : t('circles.add_circle_page.enable')}
        kMaxWidth="sm"
        kMaxHeight="250px"
        kAlignBtn="center"
        kBorderRadius={isMobile ? 0 : '10px'}
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kKeepBtnSize
        kSlideTransition={isMobile}
        kTopDivider
        kOpenDialogLists={openDialog}
        kOnClose={() => setOpenDialog(false)}
        kContent={(
          <Typography fontSize="17px" color={LmiTheme.light.systemBackground.tertiary}>
            {circleInfoEdit?.active ? t('circles.add_circle_page.disableDialog') : t('circles.add_circle_page.enableDialog')}
          </Typography>
        )}
        kOnPrimary={() => activeDeactiveCircle()}
        kOnSecondary={() => setOpenDialog(false)}
      />
    </>
  );
}

import {
  ListItem,
  ListItemButton,
  Paper,
  Stack,
  Box,
  Container,
  Typography,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  LmiTheme,
} from '..';
import { DialogPromoCodesItemModels } from './DialogPromoCodesItem.models';

function DialogPromoCodesItem(props: DialogPromoCodesItemModels) {
  const { t } = useTranslation('global');
  const [seeDetails, setSeeDetails] = useState(false);
  return (
    <>
      <Paper variant="outlined" sx={{ mx: 1, my: 2, border: props.kPromoSelectedIndex ? `2px solid ${LmiTheme.light.accent}` : '2px solid transparent' }}>
        <ListItem
          disablePadding
          divider={props.kDivider}
          sx={{
            py: 1,
            '&& .Mui-selected, && .Mui-selected:hover': {
              bgcolor: 'transparent',
            },
            '& .MuiListItemButton-root:hover': {
              bgcolor: 'transparent',
            },
            '&& .Mui-selected, && span': {
              bgcolor: 'transparent',
            },
          }}
        >
          <ListItemButton
            disableGutters
            disableRipple
            disableTouchRipple
            selected={props.kPromoSelectedIndex}
            // onClick={props.kHandlePaymentSelected}
            disabled={!props.kCodeValidity ?? false}
          >
            <Container>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
              >
                <Box fontWeight="bold">{props.kCodeName?.toUpperCase()}</Box>
                <Box fontWeight="bold">{`${props.kDiscountPercent?.split('.')[0]}% ${t('listing_detail.off')}`}</Box>
              </Stack>
              <Typography py={1} color={LmiTheme.light.systemBackground.secondary}>
                { (seeDetails || props.kCodeDescription!.length < 100) ? props.kCodeDescription : `${props.kCodeDescription?.slice(0, 100)}...`}
              </Typography>
              <Typography>
                {props.kCodeValidDate}
              </Typography>
              <Stack pt={2} spacing={3} direction="row">
                <Button
                  disableElevation
                  disableFocusRipple
                  disableTouchRipple
                  onClick={props.kHandlePaymentSelected}
                  variant="text"
                  size="large"
                  sx={{
                    px: 0,
                    py: 1,
                    ml: -1,
                    fontWeight: 'bold',
                    '&.MuiButtonBase-root:hover': { bgcolor: 'transparent' },
                  }}
                >
                  {props.kPromoSelectedIndex ? `${t('dialog.unselect')} ` : `${t('dialog.select')} `}
                </Button>
                {props.kCodeDescription!.length > 100 ? (
                  <Button
                    onClick={() => {
                      setSeeDetails(!seeDetails);
                    }}
                    disableElevation
                    disableFocusRipple
                    disableTouchRipple
                    variant="text"
                    size="large"
                    sx={{
                      px: 0,
                      py: 1,
                      fontWeight: 'bold',
                      '&.MuiButtonBase-root:hover': { bgcolor: 'transparent' },
                    }}
                  >
                    {seeDetails ? t('dialog.see_less') : t('dialog.details')}
                  </Button>
                ) : (<Box />)}
              </Stack>
            </Container>
          </ListItemButton>
        </ListItem>
      </Paper>
    </>
  );
}

DialogPromoCodesItem.defaultProps = {
  kDivider: true,
  kListItemIcon: undefined,
  kListItemIconColor: undefined,
  kAvatarVariant: undefined,
  kAvatarAlt: 'Avatar',
  kAvatarImageSrc: undefined,
  kAvatarWidth: 44,
  kAvatarHeight: 44,
  kPrimaryText: '',
  kSecondaryText: '',
  kPrice: undefined,
  kPriceType: undefined,
};

export default DialogPromoCodesItem;

import {
  Avatar,
  Box, Button, Card, ListItemAvatar, Paper, Stack, Typography,
} from '@mui/material';
import React, { useContext } from 'react';
import { Verified } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ActivityContext from '../../../../../context/activity/activityContext';
import ActivityDetailStyle from '../ActivityDetail.style';
import ActivityCircleCard from '../Components/ActivityCircleCard/ActivityCircleCard';
import { LoadingLayout, SectionTitle } from '../../../../../components/Common';
import BtnStyle from './BtnStyle.style';
import activityActionsContext from '../../../../../context/activity/actions/activityActionsContext';
import { ThumbsUp } from '../../../../../assets';
import ActivityUserStyle from '../Components/ActivityUser/ActivityUser.style';

export default function CircleInvitationDetail() {
  const history = useHistory();
  const activityContext = useContext(ActivityContext);
  const { openedActivityItem, circleInvitationInfo }: any = activityContext;

  const activityActionContext = React.useContext(activityActionsContext);
  const {
    approveRejectCircleInvitation,
    loading,
    loadingActAction,
  }: any = activityActionContext;

  function visibleToOthersName(userFullname: String) {
    let _visibleToOthersName: String;
    let names: any = [];
    try {
      names = userFullname.toString().split(' ');

      _visibleToOthersName = `${capitalizeFirstLetter(names[0])} ${names[1].substring(0, 1).toUpperCase()}.`;
    } catch (err: any) {
      if (err.message == 'Value not in range') {
        try {
          _visibleToOthersName = `${capitalizeFirstLetter(names[0])} ${names[2].substring(0, 1).toUpperCase()}.`;
        } catch (error: any) {
          _visibleToOthersName = userFullname;
        }
      } else {
        _visibleToOthersName = userFullname;
      }
    }

    return _visibleToOthersName;
  }

  function capitalizeFirstLetter(word: String) {
    return `${word.substring(0, 1).toUpperCase()}${word.substring(1).toLowerCase()}`;
  }

  const adminInvited = circleInvitationInfo?.invitingUserIsOwner!
  || circleInvitationInfo?.invitingUserIsAdmin!;

  const { t } = useTranslation('global');

  return (
    <>
      {(!loading && !loadingActAction)
        ? (
          <Card elevation={0} sx={ActivityDetailStyle}>

            <Box className="lmiw-detail-content">

              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="end"
                spacing={2}
                px={2}
                pb={2}
              >
                <Box sx={ActivityUserStyle}>
                  <SectionTitle
                    kMarginTop={2}
                    kTitle={`${visibleToOthersName(circleInvitationInfo?.invitingUserName)}${t('activity_item.invitesYouToJoin')}`}
                    kFontSize={18}
                  />
                  <Paper className="lmiw-activity-user" variant="outlined" elevation={0}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                    >
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                      >
                        <ListItemAvatar>
                          <Avatar
                            src={circleInvitationInfo?.invitingUserProfilePicture}
                            alt="username"
                            sx={{ width: { md: 100, xs: 65 }, height: { md: 100, xs: 65 } }}
                          />
                        </ListItemAvatar>
                        <Box>
                          <Typography className="lmiw-user-name" mb={0.5}>
                            {visibleToOthersName(circleInvitationInfo?.invitingUserName)}
                          </Typography>
                          <Stack
                            direction="row"
                            className="lmiw-user-like"
                          >
                            <ThumbsUp />
                            {
                              circleInvitationInfo?.invitingUserSumRatesReceived?.toFixed(2) > 0
                                ? (
                                  <Typography fontSize={14}>
                                    {circleInvitationInfo?.invitingUserSumRatesReceived.toFixed(2)}
                                    {t('profile.recommended')}
                                  </Typography>
                                )
                                : (
                                  <Typography fontSize={14}>
                                    100
                                    {t('profile.recommended')}
                                  </Typography>
                                )
                            }
                          </Stack>
                          {
                            (circleInvitationInfo?.invitingUserIsVerified)
                            && (
                              <Stack
                                direction="row"
                                className="lmiw-user-verified"
                              >
                                <Verified />
                                <Typography>
                                  {t('profile.id_verified')}
                                </Typography>
                              </Stack>
                            )
                          }
                        </Box>
                      </Stack>
                      <Button
                        sx={BtnStyle}
                        variant="outlined"
                        fullWidth={false}
                        color="primary"
                        className="lmiw-btn-login"
                        onClick={() => {
                          history.push('/public-profile', {
                            publicProfileId: circleInvitationInfo?.invitingUser,
                          });
                        }}
                      >
                        {t('profile.view_profile')}
                      </Button>
                    </Stack>
                  </Paper>
                </Box>

                <ActivityCircleCard
                  circleInfo={circleInvitationInfo}
                />

                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="end"
                  spacing={2}
                  px={2}
                  pb={2}
                >
                  <Button
                    sx={BtnStyle}
                    variant="outlined"
                    fullWidth={false}
                    color="primary"
                    className="lmiw-btn-login"
                    onClick={() => {
                      approveRejectCircleInvitation(openedActivityItem, false, adminInvited);
                    }}
                  >
                    Ignore
                  </Button>
                  <Button
                    sx={BtnStyle}
                    variant="contained"
                    fullWidth={false}
                    color="primary"
                    className="lmiw-btn-login"
                    onClick={() => {
                      approveRejectCircleInvitation(openedActivityItem, true, adminInvited);
                    }}
                  >
                    Join Circle
                  </Button>
                </Stack>

              </Stack>
            </Box>
          </Card>
        )
        : (<LoadingLayout />)}
    </>
  );
}

import moment from 'moment';
// import { ItemAccessoriesModel } from '../../page/BackOffice/Listing/Components/AddItem/Components/ItemAccessories/ItemAccessories.models';
import {
  GET_BY_ID,
  PRECING_CHANGE,
  BOOK_TYPE_CHANGE,
  UPDATE_LENDER_INFO,
  UPDATE_ROADIE_INFO,
  UPDATE_ACCESORY,
  UPDATE_FAVORITE,
  LOADING,
  INITIAL,
  SET_SUMMARY,
  AVAILABILITY_SET_RANGES,
  AVAILABILITY_SET_HOURS,
  AVAILABILITY_SET_ERROR,
  DELIVERY_CHANGE,
  PICKUP_CHANGE,
  SET_STATE,
  SET_STATE_ALL,
  NEED_COMPLETE_PROFILE,
  UPDATE_PENDING_INFO,
  COUPON_LIST,
  MODIFY_ACCESORY,
  UPDATE_LENDER_INFO_TEMP,
  SET_SUMMARY_TEMP,
  UPDATE_ROADIE_INFO_TEMP,
  DELIVERY_CHANGE_TEMP,
  COUPON_NEW,
  PROMO_CODE_CHANGE,
  PROMO_CODE_CHANGE_TEMP,
  NEED_ACCEPT_LONG_DISTANCE,
  RENTAL_CREATED,
  RENTAL_CREATION_ERROR,
  SET_WARNING_ROADIE,
  NEED_VERIFY_ACCOUNT,
  UPDATE_FROM_HOUR,
  UPDATE_TO_HOUR,
  GET_LENDER_ITEMS,
  CLEAR_BOOK_ERROR,
} from '../../types';

export default (state: any, action: any) => {
  switch (action.type) {
    case GET_BY_ID:
      localStorage.removeItem('endTime');
      localStorage.removeItem('startTime');
      return {
        ...state,
        loading: false,
        bookItem: action.payload.bookItem,
        precingType: action.payload.precingType,
        bookType: action.payload.bookType,
        rentalCreated: false,
        // rentalError: false,
        showAcceptDistanceModal: false,
        showNeedsVerifyModal: false,
        roadieWarning: '',
        pastHourRange: false,
        roadie: {},
        pickupLender: {},
      };
    case GET_LENDER_ITEMS:
      return {
        ...state,
        lenderItemList: action.payload.lenderItemList,
      };
    case PRECING_CHANGE:
      localStorage.setItem('precingType', action.payload);
      if (action.payload != 'HOURLY') {
        localStorage.removeItem('endTime');
        localStorage.removeItem('startTime');
        localStorage.removeItem('hourlyDateRange');
        const { availability: availavState } = state;
        return {
          ...state,
          precingType: action.payload,
          availability: { ...availavState, hours: 0 },
          toHour: null,
          fromHour: null,
        };
      }
      return {
        ...state,
        precingType: action.payload,
      };
    case DELIVERY_CHANGE:
      localStorage.setItem('deliveryType', action.payload);
      return {
        ...state,
        deliveryType: action.payload,
        deliveryTypeTemp: action.payload,
      };
    case DELIVERY_CHANGE_TEMP:
      return {
        ...state,
        deliveryTypeTemp: action.payload,
      };
    case PICKUP_CHANGE:
      return {
        ...state,
        pickupChange: !state.pickupChange,
      };
    case BOOK_TYPE_CHANGE:
      localStorage.setItem('bookType', action.payload);
      return {
        ...state,
        bookType: action.payload,
      };
    case UPDATE_LENDER_INFO:
      localStorage.setItem('pickupLender', JSON.stringify(action.payload));
      return {
        ...state,
        pickupLender: action.payload,
        pickupChange: action.payload.countryName ? !state.pickupChange : state.pickupChange,
      };
    case UPDATE_LENDER_INFO_TEMP:
      return {
        ...state,
        pickupLenderTemp: action.payload,
        pickupChangeTemp: action.payload.streetAddress ? !state.pickupChange : state.pickupChange,
      };
    case UPDATE_ROADIE_INFO:
      localStorage.setItem('roadie', JSON.stringify(action.payload));
      return {
        ...state,
        roadie: action.payload,
        roadieChange: action.payload.countryName ? !state.roadieChange : state.roadieChange,
      };
    case UPDATE_ROADIE_INFO_TEMP:
      return {
        ...state,
        roadieTemp: action.payload,
        roadieChangeTemp: action.payload.streetAddress && action.payload.streetAddress != '' ? !state.roadieChangeTemp : state.roadieChangeTemp,
      };
    case UPDATE_ACCESORY:
      const { bookItem: book } = state;
      const { accesories } = book;

      for (let index = 0; index < accesories.length; index += 1) {
        const accesory = accesories[index];

        if (accesory.idRelItemAccesorie == action.payload.idRelItemAccesorie) {
          accesory.isAdded = action.payload.checked;
        }
      }

      // const objIndex = accesories
      //   .findIndex(((accesory : ItemAccessoriesModel) => accesory.idRelItemAccesorie == action.payload.idRelItemAccesorie
      //   ));

      // accesories[objIndex].isAdded = action.payload.checked;

      book.accesories = accesories;

      return {
        ...state,
        bookItem: book,
        accesoriesUpdated: !state.accesoriesUpdated,
      };
    case MODIFY_ACCESORY:
      const { bookItem: bookModify } = state;
      const arrAccesories = action.payload;

      for (let index = 0; index < arrAccesories.length; index += 1) {
        const accesory = arrAccesories[index];

        accesory.isAdded = accesory.tempAdded ?? false;
      }

      bookModify.accesories = arrAccesories;

      return {
        ...state,
        bookItem: bookModify,
        accesoriesUpdated: !state.accesoriesUpdated,
      };

    case UPDATE_FAVORITE:
      const { bookItem } = state;

      bookItem.bookDetails.isFavorite = action.payload;

      return {
        ...state,
        loading: false,
        bookItem,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_SUMMARY:
      return {
        ...state,
        summary: action.payload,
        rentalCreated: false,
        showAcceptDistanceModal: false,
        showNeedsVerifyModal: false,
        errorBookMessage: '',
      };
    case RENTAL_CREATED:
      return {
        ...state,
        rentalCreated: true,
      };
    case RENTAL_CREATION_ERROR:
      return {
        ...state,
        rentalError: !state.rentalError,
        errorBookMessage: action.payload.errorBookMessage,
      };
    case SET_SUMMARY_TEMP:
      return {
        ...state,
        summaryTemp: action.payload,
      };
    case AVAILABILITY_SET_RANGES:
      const { availability } = state;
      const dateRange = action.payload;
      let errorAvailability = '';

      if (dateRange[1]) {
        const a = moment(dateRange[0]);
        const b = moment(dateRange[1]);
        const numberOfDays = b.diff(a, 'days');
        switch (state.precingType) {
          case 'DAILY':
            if (numberOfDays < 1 || numberOfDays > 365) {
              errorAvailability = 'book_reducer.error_txt';
            } else {
              errorAvailability = '';
            }
            break;
          case 'WEEKLY':
            if (numberOfDays % 7 == 0 && numberOfDays <= 365) {
              errorAvailability = '';
            } else {
              errorAvailability = 'book_reducer.error_txt2';
            }
            break;
          case 'MONTHLY':
            if (numberOfDays % 31 == 0 && numberOfDays <= 365) {
              errorAvailability = '';
            } else {
              errorAvailability = 'book_reducer.error_txt3';
            }
            break;
          default:
            errorAvailability = '';
            break;
        }
      }

      return {
        ...state,
        availability: {
          ...availability,
          dateRange: action.payload,
          errorAvailability,
        },
      };
    case AVAILABILITY_SET_HOURS:
      const { availability: availaState } = state;
      return {
        ...state,
        availability: { ...availaState, hours: action.payload },
      };
    case AVAILABILITY_SET_ERROR:
      const { availability: availaAllState } = state;
      return {
        ...state,
        availability: { ...availaAllState, errorAvailability: action.payload },
      };
    case INITIAL:
      const initialState = action.payload;
      return initialState;
    case SET_STATE:
      return {
        ...state,
        bookItem: action.payload,
        // bookItem: action.payload.bookItem,
      };
    case SET_STATE_ALL:
      return {
        ...state,
        roadie: action.payload.roadie,
        accesoriesUpdated: action.payload.accesoriesUpdated,
        availability: action.payload.availability,
        bookItem: action.payload.bookItem,
        bookType: action.payload.bookType,
        completeProfile: action.payload.completeProfile,
        couponList: action.payload.couponList,
        couponSelected: action.payload.couponSelected,
        deliveryType: action.payload.deliveryType,
        deliveryTypeTemp: action.payload.deliveryTypeTemp,
        errorBookMessage: action.payload.errorBookMessage,
        fromHour: action.payload.fromHour,
        includeAccesories: action.payload.includeAccesories,
        lenderItemList: action.payload.lenderItemList,
        pickupLender: action.payload?.pickupLender,
        pickupLenderTemp: action.payload?.pickupLenderTemp,
        pickupChangeTemp: action.payload?.pickupChangeTemp,
        roadieTemp: action.payload?.roadie,
      };
    case NEED_COMPLETE_PROFILE:
      return {
        ...state,
        completeProfile: action.payload,
      };
    case NEED_ACCEPT_LONG_DISTANCE:
      return {
        ...state,
        showAcceptDistance: action.payload,
        showAcceptDistanceModal: action.payload.openModal,
      };
    case NEED_VERIFY_ACCOUNT:
      return {
        ...state,
        showNeedsVerifyModal: action.payload,
      };
    case UPDATE_PENDING_INFO:
      return {
        ...state,
        completeProfile: action.payload,
      };
    case COUPON_LIST:
      return {
        ...state,
        couponList: action.payload,
      };
    case COUPON_NEW:
      return {
        ...state,
        couponList: [...state.couponList, action.payload],
      };
    case PROMO_CODE_CHANGE:
      const couponSelected = state.couponList.find((c: any) => c.idCatLndUserCouponCodes == state.promoSelectedTemp);
      return {
        ...state,
        promoSelected: action.payload,
        couponSelected,
      };
    case PROMO_CODE_CHANGE_TEMP:
      return {
        ...state,
        promoSelectedTemp: action.payload,
      };

    case SET_WARNING_ROADIE:
      return {
        ...state,
        roadieWarning: action.message,
      };
    case UPDATE_FROM_HOUR:

      // localStorage.setItem('startTime', moment(action.payload).toISOString());
      return {
        ...state,
        fromHour: action.payload,
      };
    case UPDATE_TO_HOUR:
      // localStorage.setItem('endTime', moment(action.payload).toISOString());
      return {
        ...state,
        toHour: action.payload,
      };
    case CLEAR_BOOK_ERROR:
      return {
        ...state,
        errorBookMessage: '',
      };
    default:
      return state;
  }
};

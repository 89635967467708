/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-lines */
import React, {
  useContext, useEffect, useState,
} from 'react';
import {
  Box,
  Grid,
  Paper,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { enUS, es } from 'date-fns/locale';
import cookies from 'js-cookie';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';
import { CardStripe, LmiTheme } from '../..';
import payoutAccountContext from '../../../../../../context/payoutAccount/payoutAccountContext';
import { TextExpireDate } from '../../../../../../utils/inputsFormats';

function IndividualPayout(props: any) {
  const pPayoutContext = useContext(payoutAccountContext);
  const {
    payoutAccountDetails,
    updateExistingPayoutAccountDetails,
    checkPayoutValidate,
    updatePayoutValidationStatus,
    triggerCheckValidate,
  }: any = pPayoutContext;

  const [inputDate, setInputDate] = useState<any>(payoutAccountDetails?.dateOfBirth ? moment(payoutAccountDetails.dateOfBirth, ['YYYY-MM-DD']).format('YYYY/MM/DD') : null);

  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [isBirthDateValid, setIsBirthDateValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isExpDateValid, setIsExpDateValid] = useState(true);

  const firstNameRef = React.useRef<HTMLInputElement>(null);
  const lastNameRef = React.useRef<HTMLInputElement>(null);
  const emailRef = React.useRef<HTMLInputElement>(null);
  const birthDateRef = React.useRef<HTMLInputElement>(null);
  const expDateRef = React.useRef<HTMLInputElement>(null);

  const isValidExpirationDate = (date: string) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear() % 100;
    const currentMonth = currentDate.getMonth() + 1;

    const [month, year] = date.split('/');
    const monthInt = parseInt(month, 10);
    const yearInt = parseInt(year, 10);

    return (
      date.length === 5
      && monthInt >= 1
      && monthInt <= 12
      && yearInt >= currentYear
      && (yearInt > currentYear || (yearInt === currentYear && monthInt >= currentMonth))
    );
  };

  useEffect(() => {
    if (checkPayoutValidate) {
      const validations = {
        firstName: { ref: firstNameRef, validator: (value: any) => value.length > 0, setter: setIsFirstNameValid },
        lastName: { ref: lastNameRef, validator: (value: any) => value.length > 0, setter: setIsLastNameValid },
        email: { ref: emailRef, validator: (value: any) => value.length > 0, setter: setIsEmailValid },
        dateOfBirth: { ref: birthDateRef, validator: (value: any) => value.length > 0, setter: setIsBirthDateValid },
        expiration: {
          ref: expDateRef, validator: isValidExpirationDate, condition: () => payoutAccountDetails.isDebitCard, setter: setIsExpDateValid,
        },
      };

      let firstErrorRef = null;
      let isEverythingOk = true;

      for (const key in validations) {
        const field = validations[key];
        const conditionMet = !field.condition || (field.condition && field.condition());
        if (conditionMet && !field.validator(payoutAccountDetails[key])) {
          isEverythingOk = false;
          field.setter(false);
          if (!firstErrorRef) {
            firstErrorRef = field.ref;
          }
        } else {
          field.setter(true);
        }
      }

      if (firstErrorRef && firstErrorRef.current) {
        props.onScrollToTop();
      }

      updatePayoutValidationStatus(isEverythingOk);
    }
  }, [triggerCheckValidate]);

  const [selectedIndividualOrBussines, setSelectedIndividualOrBussines] = useState('individual');
  const changeIndividualOrBussines = (
    _event: React.MouseEvent<HTMLElement>,
    newSelectedIndividualOrBussines: string,
  ) => {
    if (newSelectedIndividualOrBussines !== null) {
      setSelectedIndividualOrBussines(newSelectedIndividualOrBussines);
      if (newSelectedIndividualOrBussines == 'individual') {
        updateExistingPayoutAccountDetails({
          ...payoutAccountDetails,
          isIndividual: true,
          isBusiness: false,
        });
      } else {
        updateExistingPayoutAccountDetails({
          ...payoutAccountDetails,
          isBusiness: true,
          isIndividual: false,
        });
      }
    }
  };

  const handleInputchange = ({ target }: any) => {
    updateExistingPayoutAccountDetails({
      ...payoutAccountDetails,
      [target.name]: target.value,
    });
  };

  const limitFullName = 45;

  const { t } = useTranslation('global');

  const expiration = payoutAccountDetails?.expiration;
  const formattedExpiration = expiration.length === 4 && !expiration.startsWith('1') ? `0${expiration}` : expiration;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Typography py={2} component="span" textAlign="center" color={LmiTheme.light.systemBackground.tertiary}>{t('payout_info.payment_secure')}</Typography>
        <CardStripe width={56} height={56} style={{ marginRight: 30 }} />
      </Stack>
      <Box sx={{ p: 1, fontWeight: 'bold' }}>
        {t('payout_info.payout_information')}
      </Box>
      <Paper variant="outlined" sx={{ p: 2, borderRadius: '10px' }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={1}
          component="form"
          sx={{
            '& > :not(style)': { p: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        >
          {payoutAccountDetails.accId == '' ? (
            <Grid item xs={12}>
              <ToggleButtonGroup
                onChange={changeIndividualOrBussines}
                value={selectedIndividualOrBussines}
                color="primary"
                fullWidth
                exclusive
              >
                <ToggleButton
                  disableFocusRipple
                  disableRipple
                  value="individual"
                >
                  {t('payout_info.individual')}
                </ToggleButton>
                <ToggleButton
                  disableFocusRipple
                  disableRipple
                  value="business"
                >
                  {t('payout_info.business')}
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          ) : (
            <Box>
              <Typography component="span" fontWeight={500}>
                {t('payout_info.individual_account')}
              </Typography>
            </Box>
          )}
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              pb={1}
            >
              <Box>
                <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                <Typography component="span" fontWeight={500}>{t('payout_info.first_name')}</Typography>
              </Box>
              <Typography component="span" color={LmiTheme.light.systemBackground.tertiary}>
                {`${payoutAccountDetails.firstName.length}/${limitFullName}`}
              </Typography>
            </Stack>
            <div ref={firstNameRef}>
              <TextField
                fullWidth
                disabled={props.kReadOnly}
                variant="outlined"
                type="text"
                placeholder={t('payout_info.first_name')}
                name="firstName"
                value={payoutAccountDetails?.firstName}
                error={!isFirstNameValid}
                helperText={!isFirstNameValid && `${t('payout_info.invalid_first_name')}`}
                onChange={handleInputchange}
                sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                inputProps={{
                  maxLength: limitFullName,
                }}
                FormHelperTextProps={{
                  style: {
                    backgroundColor: LmiTheme.light.systemBackground.elevation,
                    margin: 0,
                    paddingTop: '3px',
                    paddingRight: '14px',
                    paddingBottom: '0',
                    paddingLeft: '14px',
                  },
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              pb={1}
            >
              <Box>
                <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                <Typography component="span" fontWeight={500}>{t('payout_info.last_name')}</Typography>
              </Box>
              <Typography component="span" color={LmiTheme.light.systemBackground.tertiary}>
                {`${payoutAccountDetails.lastName.length}/${limitFullName}`}
              </Typography>
            </Stack>
            <div ref={lastNameRef}>
              <TextField
                ref={lastNameRef}
                fullWidth
                disabled={props.kReadOnly}
                variant="outlined"
                type="text"
                placeholder={t('payout_info.last_name')}
                name="lastName"
                error={!isLastNameValid}
                helperText={!isLastNameValid && `${t('payout_info.invalid_last_name')}`}
                value={payoutAccountDetails?.lastName}
                onChange={handleInputchange}
                sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                inputProps={{
                  maxLength: limitFullName,
                }}
                FormHelperTextProps={{
                  style: {
                    backgroundColor: LmiTheme.light.systemBackground.elevation,
                    margin: 0,
                    paddingTop: '3px',
                    paddingRight: '14px',
                    paddingBottom: '0',
                    paddingLeft: '14px',
                  },
                }}
              />
            </div>

          </Grid>
          <Grid item p={0} m={0} xs={12}>
            <Box pb={1}>
              <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
              <Typography component="span" fontWeight={500}>{t('payout_info.date_birth')}</Typography>
            </Box>
            <LocalizationProvider locale={(cookies.get('i18next') === 'en') ? enUS : es} dateAdapter={AdapterDateFns}>

              <DesktopDatePicker
                dayOfWeekFormatter={(day) => t(`days.${day}`)}
                disabled={props.kReadOnly}
                value={inputDate}
                onChange={(newValue) => {
                  setInputDate(newValue);
                  if (newValue != null) {
                    updateExistingPayoutAccountDetails({
                      ...payoutAccountDetails,
                      dateOfBirth: moment(newValue).format('YYYY/MM/DD').toString(),
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    ref={birthDateRef}
                    error={!isBirthDateValid}
                    helperText={!isBirthDateValid && `${t('payout_info.invalid_date_birth')}`}
                    FormHelperTextProps={{
                      style: {
                        backgroundColor: LmiTheme.light.systemBackground.elevation,
                        margin: 0,
                        paddingTop: '3px',
                        paddingRight: '14px',
                        paddingBottom: '0',
                        paddingLeft: '14px',
                      },
                    }}
                    {...params}
                    sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                    onBlur={() => {
                      updateExistingPayoutAccountDetails({
                        ...payoutAccountDetails,
                        dateOfBirth: moment(inputDate).format('YYYY/MM/DD').toString(),
                      });
                    }}
                  />
                )}
              />
            </LocalizationProvider>

          </Grid>
          <Grid item xs={12}>
            <Box pb={1}>
              <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
              <Typography component="span" fontWeight={500}>{t('profile.email')}</Typography>
            </Box>
            <div ref={emailRef}>
              <TextField
                ref={emailRef}
                fullWidth
                variant="outlined"
                name="email"
                value={payoutAccountDetails?.email}
                error={!isEmailValid}
                helperText={!isEmailValid && `${t('payout_info.invalid_email')}`}
                onChange={handleInputchange}
                disabled={props.kReadOnly}
                placeholder={t('payout_info.enter_email')}
                sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                FormHelperTextProps={{
                  style: {
                    backgroundColor: LmiTheme.light.systemBackground.elevation,
                    margin: 0,
                    paddingTop: '3px',
                    paddingRight: '14px',
                    paddingBottom: '0',
                    paddingLeft: '14px',
                  },
                }}
              />
            </div>
          </Grid>
        </Grid>
        {payoutAccountDetails?.externalAcc != '' && (
          <Grid item xs={12}>
            <Box>
              <Typography component="span" fontSize={14} fontWeight={500}>
                {`${t('payout_info.card_ending')}`}
                {payoutAccountDetails?.externalAcc.data[0].last4}

              </Typography>
            </Box>
          </Grid>
        )}
        {payoutAccountDetails.accountHolderName != '' && (
          <Grid item xs={12}>
            <Box pb={1}>
              <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
              <Typography component="span" fontWeight={500}>{t('payout_info.account_name')}</Typography>
            </Box>
            <TextField
              fullWidth
              variant="outlined"
              name="accountHolderName"
              value={payoutAccountDetails?.accountHolderName}
              onChange={handleInputchange}
              disabled={props.kReadOnly}
              placeholder={t('payout_info.enter_account_number')}
              sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
              FormHelperTextProps={{
                style: {
                  backgroundColor: LmiTheme.light.systemBackground.elevation,
                  margin: 0,
                  paddingTop: '3px',
                  paddingRight: '14px',
                  paddingBottom: '0',
                  paddingLeft: '14px',
                },
              }}
            />
          </Grid>
        )}
        {!payoutAccountDetails.isBankAccount && (
          <Grid item xs={isMobile ? 6 : 3}>
            <Box pb={1}>
              <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
              <Typography component="span" fontWeight={500}>{t('payout_info.expiration')}</Typography>
            </Box>
            <div ref={lastNameRef}>
              <TextField
                ref={expDateRef}
                fullWidth
                variant="outlined"
                type="text"
                name="expiration"
                placeholder="MM/YY"
                value={formattedExpiration}
                error={!isExpDateValid}
                helperText={!isExpDateValid && `${t('payout_info.invalid_exp_date')}`}
                onChange={handleInputchange}
                sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                InputProps={{
                  inputComponent: TextExpireDate as any,
                }}
                FormHelperTextProps={{
                  style: {
                    backgroundColor: LmiTheme.light.systemBackground.elevation,
                    margin: 0,
                    paddingTop: '3px',
                    paddingRight: '14px',
                    paddingBottom: '0',
                    paddingLeft: '14px',
                  },
                }}
              />
            </div>
          </Grid>
        )}
      </Paper>
    </>
  );
}

export default React.memo(IndividualPayout);

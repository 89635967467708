import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  CardContent,
  Grid,
  Stack,
  Tab,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import {
  MainInformationTabPanel,
  ReviewsItem,
  ReviewsSummary,
} from '..';
import { TabPanelReviewsModels } from './TabPanelReviews.models';

function TabPanelReviews(props: TabPanelReviewsModels) {
  const [tabValue, tabSetValue] = useState('1');

  const tabChange = (_event: React.SyntheticEvent, newValue: string) => {
    tabSetValue(newValue);
  };
  const dataListReceived = props.kUserReviews;
  const dataListGiven = props.kGivenReviews;
  const { t } = useTranslation('global');

  return (
    <>
      <MainInformationTabPanel
        kTabValue={props.kTabValue}
        kTabIndex={props.kTabIndex}
        kContent={
          (
            <>
              <CardContent sx={{ px: 3, py: 0 }}>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  py={3}
                >
                  <Grid container spacing={3}>
                    {
                      props.hideReviewsSummary ? null : (
                        <Grid item xs={12} sm={12} md={4}>
                          <ReviewsSummary
                            kRecommendedReview={props.kRecommendedNumber}
                            kPositiveReview={9}
                            kNegativeReview={1}
                            kTotalReview={dataListReceived?.length}
                          />
                        </Grid>
                      )
                    }
                    <Grid item xs={12} sm={12} md={props.hideReviewsSummary ? 12 : 8}>
                      <TabContext value={tabValue}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <TabList onChange={tabChange} variant="fullWidth">
                            <Tab label={t('profile.received')} value="1" />
                            <Tab label={t('profile.given')} value="2" />
                          </TabList>
                        </Box>
                        <TabPanel value="1" sx={{ px: 0, py: 1 }}>
                          {
                            /* eslint-disable react/jsx-props-no-spreading */
                            dataListReceived?.length !== 0 ? (
                              dataListReceived?.map((d: any) => (
                                <ReviewsItem
                                  kDivider
                                  kLenderName={d.userNameWhoReviewedYou}
                                  kLenderPhoto={d.profilePicture}
                                  kReviewDate={d.creationDate}
                                  kReviewDescription={d.message}
                                  kReviewLike={d.idRateUserWhoReviewedYou}
                                />
                              ))
                            ) : (
                              <Typography
                                fontSize={14}
                                sx={{
                                  textAlign: 'center',
                                  color: '#999da2',
                                  p: 3,
                                }}
                              >
                                {t('profile.received_reviews')}
                              </Typography>
                            )
                          }
                        </TabPanel>
                        <TabPanel value="2" sx={{ px: 0, py: 1 }}>
                          {
                            /* eslint-disable react/jsx-props-no-spreading */
                            dataListGiven?.length !== 0 ? (
                              dataListGiven?.map((d: any) => (
                                <ReviewsItem
                                  kDivider
                                  kLenderName={d.userNameYouReviewed}
                                  kLenderPhoto={d.profilePicture}
                                  kReviewDate={d.creationDate}
                                  kReviewDescription={d.message}
                                  kReviewLike={d.idRateUserWhoYouReviewed}
                                />
                              ))
                            ) : (
                              <Typography
                                fontSize={14}
                                sx={{
                                  textAlign: 'center',
                                  color: '#999da2',
                                  p: 3,
                                }}
                              >
                                {t('profile.given_reviews')}
                              </Typography>
                            )

                          }
                        </TabPanel>
                      </TabContext>
                    </Grid>
                  </Grid>
                </Stack>
              </CardContent>
            </>
          )
        }
      />
    </>
  );
}

export default TabPanelReviews;

/* eslint-disable react/jsx-props-no-spreading */
import {
  useState,
  useEffect,
  useContext,
} from 'react';
// import { useTranslation } from 'react-i18next';
import {
  CardMedia,
  useMediaQuery, useTheme,
} from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Navigation, Thumbs, Pagination, Controller,
} from 'swiper';
import { useHistory } from 'react-router-dom';
import { ItemImageModel } from '../../../page/BackOffice/Listing/Components/AddItem/AddItem.models';

import { MapCarouselModels } from './SwiperThumbnails.models';
import 'swiper/swiper-bundle.min.css';
import './SwiperThumbnails.style.css';
import LightBox from '../Lightbox/Lightbox';
import PickUpDropOffPicturesContext from '../../../context/activity/pickUpDropOffPictures/pickUpdropOffPicturesContext';

SwiperCore.use([Navigation, Thumbs, Pagination, Controller]);
export default function SwiperThumbnails(props: MapCarouselModels) {
  const theme = useTheme();
  const history = useHistory();
  const state = history.location.state as { fromId: string, itemIndex: number, updateSwiper: boolean };
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
  const [mainSwiper, setMainSwiper] = useState<any>(null);
  const [openFullImage, setOpenFullImage] = useState<boolean>(false);
  const [indexImage, setIndexImage] = useState<any>();
  const [typeImage, setTypeImage] = useState<string>('');
  const images = props.itemImages ? props.itemImages : [];

  const {
    playVideos,
  }: any = useContext(PickUpDropOffPicturesContext);

  useEffect(() => {
    if (state?.updateSwiper) {
      mainSwiper?.update();
      thumbsSwiper?.update();
    }
  }, [state]);

  useEffect(() => {
    clearVideos();
  }, [playVideos]);

  const MainParams = {
    modules: [Navigation, Thumbs, Pagination, Controller],
    watchSlidesProgress: true,
    spaceBetween: 10,
    slidesPerView: 1,
    navigation: !isTablet && images.length > 1,
    pagination: isTablet,
    thumbs: {
      swiper: thumbsSwiper,
    },
    // onSwiper: (data: any) => { setThumbsSwiper(data); },
    onSwiper: (data: any) => setMainSwiper(data),
  };

  const videoRefs: any = [];
  const handleVideoPlay = (videoRef: any) => {
    const refIndex = videoRefs.findIndex((e: any) => e == videoRef);
    if (refIndex < 0 && videoRef) {
      videoRefs.push(videoRef);
    }
  };

  const clearVideos = () => {
    videoRefs.forEach((emnt: any) => {
      emnt?.pause();
      emnt.currentTime = 0;
    });
  };

  const thumbnailsParams = {
    spaceBetween: 10,
    slidesPerView: 8,
    watchSlidesProgress: true,

    onSwiper: (data: any) => { setThumbsSwiper(data); },
    breakpoints: {

      1200: {
        slidesPerView: 8,
      },
      900: {
        slidesPerView: 6,
      },
      700: {
        slidesPerView: 8,
      },
      600: {
        slidesPerView: 6,
      },
      400: {
        slidesPerView: 4,
      },
      1: {
        slidesPerView: 3,
      },
    },
  };

  const handleImageModalToggle = (data: any, index: number) => {
    setIndexImage(index);
    setTypeImage(data.assetType);
    setOpenFullImage(true);
  };

  return (
    <>
      <Swiper
        {...MainParams}
        className="lmiw-c-swiper lmiw-c-swiper--main"
        onSlideChange={() => {
          clearVideos();
        }}
      >
        {images && images?.length > 0
          ? images.map((data: ItemImageModel, index: number) => (
            ((data.videoPath != '' && data.videoPath != undefined) || data?.base64?.toString().includes('data:video/mp4') || data?.base64?.toString().includes('data:video/quicktime'))
              ? (
                <SwiperSlide
                  key={data.idDetLndItemImage}
                  className="__svideo"
                  style={{ aspectRatio: '5 / 4', height: isMobile ? '250px' : `${props.height}px` }}
                >
                  <CardMedia
                    component="video"
                    controls
                    height="500"
                    src={data.videoPath != undefined ? data.videoPath : data.base64}
                    ref={handleVideoPlay}
                    sx={{
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </SwiperSlide>
              )
              : (
                <SwiperSlide
                  key={data.idDetLndItemImage}
                  className="__image"
                  style={{
                    backgroundImage: `url(${data.imgPath != undefined ? data.imgPath : data})`,
                    // backgroundImage: `url(${preview})`,
                    aspectRatio: '5 / 4',
                    height: isMobile ? '250px' : `${props.height}px`,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleImageModalToggle(data, index)}
                />
              )

          )) : null}
        {!images.length
          ? (
            <SwiperSlide
              key="0"
              className="__none"
              slot="wrapper-end"
              style={{
                height: isMobile ? '250px' : `${props.height}px`,
              }}
            />
          ) : ''}
      </Swiper>
      {/* thumbnail swiper */}
      <Swiper {...thumbnailsParams} className="lmiw-c-swiper lmiw-c-swiper--thumbnail">
        {images && images?.length > 0
          ? images.map((data: ItemImageModel, index: number) => (
            ((data.videoPath != '' && data.videoPath != undefined) || data?.base64?.toString().includes('data:video/mp4'))
              ? (
                <SwiperSlide
                  key={data.idDetLndItemImage}
                  className={`__video ${index == 0 ? 'swiper-slide-thumb-active' : ''}`}
                  style={{ aspectRatio: '5 / 4', height: `${props.thumbnailHeight}px`, backgroundColor: 'black' }}
                >
                  <CardMedia
                    component="video"
                    height="500"
                    src={data.videoPath != undefined ? data.videoPath : data.base64}
                    sx={{
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </SwiperSlide>
              )
              : (
                <SwiperSlide
                  key={data.idDetLndItemImage}
                  className={`__image ${index == 0 ? 'swiper-slide-thumb-active' : ''}`}
                  style={{
                    backgroundImage: `url(${data.imgPath != undefined ? data.imgPath : data})`,
                    aspectRatio: '5 / 4',
                    height: `${props.thumbnailHeight}px`,
                  }}
                />
              )
          )) : null}

      </Swiper>
      <LightBox
        openImageScreen={openFullImage}
        imageIndex={indexImage}
        handleOpenLightBox={() => setOpenFullImage(false)}
        images={images}
        kType={typeImage}
      />

    </>
  );
}

import { LmiTheme } from '..';

const LogoStyle = {
  '.lmiw-color-accent': {
    fill: LmiTheme.light.accent,
  },
  '.lmiw-color-white': {
    fill: 'white',
  },
  '.lmiw-color-black': {
    fill: '#038DE2',
  },
  '.lmiw-color-dark': {
    fill: LmiTheme.light.systemBackground.dark,
  },

};

export default LogoStyle;

import {
  LOAD_PICKUP_PICTURES,
  LOAD_DROPOFF_PICTURES,
  LOAD_PICKUP_VIDEOS,
  STOP_SWIPER_VIDEOS,
  NEW_VIDEO_CONVERTED,
} from '../../../types/index';

export default (state: any, action: any) => {
  switch (action.type) {
    case LOAD_PICKUP_PICTURES: return {
      ...state,
      pickUpPictures: action.payload,
    };
    case LOAD_DROPOFF_PICTURES: return {
      ...state,
      dropOffPictures: action.payload,
    };
    case LOAD_PICKUP_VIDEOS: return {
      ...state,
      pickUpVideos: action.payload,
    };
    case STOP_SWIPER_VIDEOS: return {
      ...state,
      playVideos: !state.playVideos,
    };
    case NEW_VIDEO_CONVERTED: return {
      ...state,
      videoConverted: action.payload,
    };
    default:
      return state;
  }
};

/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import {
  ListItemButton, Avatar, ListItemAvatar, ListItem, List, Typography, Stack, Chip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import ThumbUpOffAltRoundedIcon from '@mui/icons-material/ThumbUpOffAltRounded';
import { useContext } from 'react';
import { CircleMembersModels } from './CircleMembers.models';
import AddEditCirclesContext from '../../../../../context/circles/addEditCirclesContext';
import { circleMemberElementModel } from '../../AddCircle/AddCircle.models';
import { LoadingLayout } from '../../../../../components/Common';
import MyCirclesListingContext from '../../../../../context/myCirclesListing/myCirclesListingContext';

export default function CircleMembers(props: CircleMembersModels) {
  const { t } = useTranslation('global');

  const circlesContextLog = useContext(AddEditCirclesContext);
  const {
    circleMembersEditFilter,
  }: any = circlesContextLog;

  const {
    loading,
  }: any = useContext(MyCirclesListingContext);

  const arrayMembers = props.kAdminMembers
    ? circleMembersEditFilter?.filter((memberElement: circleMemberElementModel) => (memberElement.isAdmin || memberElement.isOwner))
    : circleMembersEditFilter?.filter((memberElement: circleMemberElementModel) => (memberElement.isMember && !memberElement.isAdmin && !memberElement.isOwner));
  return (
    <>
      {
        arrayMembers ? (
          <List
            disablePadding
            sx={{
              width: '100%',
              bgcolor: 'background.paper',
              'li:last-child': {
                pb: 0,
              },
            }}
          >

            {
              arrayMembers && arrayMembers.length > 0
              && arrayMembers.map((member: circleMemberElementModel, index: number) => (
                <ListItem
                  key={uuid()}
                  disabled={loading}
                  alignItems="flex-start"
                  disablePadding
                  onClick={() => {
                    !loading ? props.onChange(member, index) : null;
                  }}
                >
                  <ListItemButton
                    divider
                    onClick={() => {
                      props.kOpenMemberDetail?.(true);
                    }}
                    sx={{
                      maxHeight: '150px',
                      pt: '20px',
                      pb: '20px',
                    }}
                  >
                    <ListItemAvatar sx={{ marginRight: '2px' }}>
                      <Avatar
                        sx={{
                          width: 80,
                          height: 80,
                          marginRight: 1,
                        }}
                        variant="circular"
                        alt={member.memberName}
                        src={member.memberPicture}
                      />
                    </ListItemAvatar>
                    <Stack
                      direction="column"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      sx={{ width: 'calc(100% - 50px)' }}
                    >
                      <Stack flexDirection="row" justifyContent="space-between" sx={{ width: '100%' }}>
                        <Typography
                          component="label"
                          noWrap
                          fontSize={16}
                          fontWeight="500"
                          sx={{
                            textTransform: 'capitalize',
                            width: '100%',
                            maxWidth: { md: '150px', lg: '210px' },
                            display: 'block',
                            color: '#1d2024',
                            mb: '5px',
                          }}
                        >
                          {member.memberName}
                        </Typography>
                        {
                          (member?.isAdmin || member?.isOwner || member?.blocked) && (
                            <Chip
                              label={member?.isAdmin ? t('circles.members.admin').toUpperCase()
                                : member?.isOwner ? t('circles.members.owner').toUpperCase() : t('circles.members.blocked').toUpperCase()}
                              variant="outlined"
                              sx={
                                member?.isOwner
                                  ? ({
                                    height: '20px',
                                    fontSize: '10px',
                                    borderColor: '#2daa24',
                                    color: '#2daa24',
                                  })
                                  : member?.isAdmin ? ({
                                    height: '20px',
                                    fontSize: '10px',
                                    borderColor: '#922bfa',
                                    color: '#922bfa',
                                  }) : ({
                                    height: '20px',
                                    fontSize: '10px',
                                    borderColor: '#B64129',
                                    color: '#B64129',
                                  })
                              }
                            />
                          )
                        }
                      </Stack>

                      <Typography
                        component="p"
                        fontSize={14}
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                          color: '#1d2024',
                          mb: '5px',
                        }}
                      >
                        {member.joinDate}
                      </Typography>
                      <Stack flexDirection="row" justifyContent="flex-start" alignItems="center">
                        <ThumbUpOffAltRoundedIcon sx={{ mr: '8.8px', color: '#2daa23' }} />
                        <Typography
                          component="p"
                          fontSize={14}
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                            color: '#707479',
                          }}
                        >
                          {`${member.recommended}% ${t('circles.members.recommended')}`}
                        </Typography>
                      </Stack>

                    </Stack>

                  </ListItemButton>
                </ListItem>
              ))
            }
          </List>
        ) : (<LoadingLayout />)
      }

    </>
  );
}

// import { Route, Switch } from 'react-router-dom';
import {
  Button, Box, Stack, Typography, Avatar,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useState, useEffect } from 'react';
import { ListingDetail } from '../../../components/Template';
import ActivityList from './ActivityList/ActivityList';
import ActivityDetail from './ActivityDetail/ActivityDetail';
import { SectionTitle, LmiTheme, DialogCommon } from '../../../components/Common';
import ActivityListStyle from './Activity.style';
import ActivityChat from './ActivityChat/ActivityChat';
import ActivityContext from '../../../context/activity/activityContext';
import SocketContext from '../../../context/socket/socketContext';

export default function Activity() {
  const [tabList, setTabList] = useState('1');
  const [openChat, setOpenChat] = useState(false);

  const activityContext = useContext(ActivityContext);
  const {
    openedActivityItem,
  }: any = activityContext;
  const { t } = useTranslation('global');

  const socketContext = useContext(SocketContext);
  const {
    initWebSocketConnection,
    ws,
  }: any = socketContext;

  const picture = openedActivityItem?.notifType == 'LEND' ? `${openedActivityItem?.renterPicture}` : `${openedActivityItem?.lenderPicture}`;
  const userName = openedActivityItem?.notifType == 'LEND' ? `${openedActivityItem?.renterName}` : `${openedActivityItem?.lenderName}`;

  useEffect(() => {
    if (initWebSocketConnection) {
      initWebSocketConnection();
    }
  }, []);

  return (
    <>
      <Box sx={ActivityListStyle}>
        <ListingDetail
          kListingPath="/activity"
          kListingHeaderJustify="flex-start"
          kListingHeader={(
            <>
              <Button
                className={tabList === '1' ? 'lmiw-tab active' : 'lmiw-tab'}
                onClick={() => setTabList('1')}
                variant="text"
              >
                {t('activity_detail.activity')}
              </Button>
              <Button
                className={tabList === '2' ? 'lmiw-tab active' : 'lmiw-tab'}
                onClick={() => setTabList('2')}
                variant="text"
              >
                {t('activity_detail.history')}
              </Button>
            </>
          )}
          kListingBody={(
            <ActivityList
              kValueTab={tabList}
              kOpenChat={() => {
                localStorage.setItem('OpenChat', 'true');
                setOpenChat(true);
              }}
            />
          )}
          kDetailPath="/activity/detail"
          kDetailHeader={(
            <>
              <SectionTitle
                kMarginLeft={0}
                kMarginRight={0}
                kMarginTop={0}
                kMarginBottom={0}
                kTitle={t('activity_detail.activity_detail')}
              />
              {openedActivityItem?.reservationCode && (
                <SectionTitle
                  kMarginLeft={0}
                  kMarginRight={0}
                  kMarginTop={0}
                  kMarginBottom={0}
                  kTitle={openedActivityItem?.reservationCode}
                  kFontWeight="400"
                  kFontSize={16}
                  kColor={LmiTheme.light.systemBackground.secondary}
                />
              )}
            </>
          )}
          kDetailBody={<ActivityDetail kOpenChat={() => setOpenChat(true)} />}
          kHideDetailHeaderInMobile
        />
      </Box>
      <DialogCommon
        kMaxWidth="sm"
        kHeaderComponent={(
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Avatar sx={{ width: '55px', height: '55px' }} src={picture} />
            <Stack direction="column" spacing={0}>
              <Typography
                sx={{
                  textTransform: 'capitalize',
                  color: LmiTheme.light.accent,
                  cursor: 'pointer',
                }}
                fontWeight="400"
                fontSize="14px"
              >
                {openedActivityItem?.name}
              </Typography>
              <Typography
                sx={{
                  textTransform: 'capitalize',
                }}
                fontWeight="bold"
                fontSize="h6.fontSize"

              >
                {userName}
              </Typography>

            </Stack>
          </Stack>
        )}
        kOpenDialogLists={openChat}
        kOnClose={() => {
          localStorage.setItem('OpenChat', 'false');
          setOpenChat(false);
          ws.send(JSON.stringify({
            action: 'notifications',
          }));
        }}
        kDisableActions
        kContent={openChat ? (
          <ActivityChat kMessage="Este es un chat" />
        ) : null}
      />
    </>
  );
}

/* eslint-disable no-nested-ternary */
import { useHistory } from 'react-router-dom';

import {
  Divider, Grid, Box, Skeleton, Typography, Card, CardContent, Chip, Stack, Button, Fade,

} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Filters from '../Filters/Filters';

import ExploreContext from '../../../../../context/explore/exploreContext';
import BookContext from '../../../../../context/bookItem/bookContext';
import { EmptyState, SectionTitle } from '../../../../../components/Common';
import ResultListingStyle from './ResultListing.style';
import empty_lend from '../../../../../assets/empty_state/empty_lend.png';

export default function ResultListing() {
  const {
    loading,
    items,
    categoriesApplied,
    removeCategoryApplied,
    more,
    loadingItems,
    loadingMore,
    searched,
    searchCurrency,
  }: any = useContext(ExploreContext);
  const {
    clearData,
  }: any = useContext(BookContext);
  const [showMore, setShowMore] = useState(true);
  const [lastQty, setLastQty] = useState(0);

  const listSize = 32;

  const history = useHistory();

  function handleClick(bookItem: any): void {
    clearData();
    clearStorageBookItem();
    localStorage.setItem('storageIdItem', bookItem.idCatLndItem);
    localStorage.setItem('checkout', '');
    localStorage.removeItem('precingType');
    localStorage.setItem('coords', JSON.stringify({
      y: window.scrollY,
      x: window.scrollX,
    }));
    history.push('/bookitem', {
      bookItem,
    });
  }

  function clearStorageBookItem() {
    localStorage.removeItem('storageDateRange');
    localStorage.removeItem('storageDeliveryType');
    localStorage.removeItem('storageAccesories');
  }

  useEffect(() => {
    if (items) {
      const qty = items.length;

      if ((qty % listSize) == 0) {
        setShowMore(true);
      } else {
        setShowMore(false);
      }
      const sCurrencyStorage = localStorage.getItem('searchCurrency');
      if (sCurrencyStorage != '' && sCurrencyStorage != null) {
        if (lastQty == qty) {
          setShowMore(false);
        } else {
          setShowMore(true);
        }
      }
      setLastQty(qty);

      const coords = localStorage.getItem('coords');
      if (coords) {
        if (typeof coords === 'string') {
          const jsonCoords = JSON.parse(coords);
          window.scrollTo(jsonCoords.x, jsonCoords.y);
          localStorage.removeItem('coords');
        }
      }
    }
  }, [items]);

  const fnBtnMore = () => {
    more();
  };

  const skeletonListing = (index: any) => (
    <Grid key={uuidv4()} item xs={6} sm={6} md={4} lg={3}>
      <Box>
        <Skeleton
          variant="rectangular"
          sx={{
            borderRadius: '7px', width: '100%', height: '170px', aspectRatio: '5 / 4',
          }}
        />
        <Skeleton height="30px" width={(index % 2 == 0) ? '92%' : '58%'} />
      </Box>
    </Grid>
  );

  const { t } = useTranslation('global');

  return (
    <Box sx={ResultListingStyle}>
      <Card
        elevation={0}
        className="lmiw-header"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          m={2}
          spacing={2}
          className="lmiw-stack"
        >
          <SectionTitle
            kMarginTop={0}
            kMarginBottom={0}
            kComponent="h2"
            kVariant="h5"
            kTitle={searched ? `"${searched.toUpperCase()}" ${t('search_page.on_lmi')}` : `${t('search_page.items_near_you')}`}
          />
          <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            <Filters />
          </Box>
        </Stack>
        <Divider />
        {categoriesApplied
          ? (
            <Box m={2}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                {categoriesApplied.map((data: any) => (
                  <Grid item>
                    <Chip
                      size="small"
                      label={`${(t(`categories.${data.title.trim()}`))}`}
                      sx={{ px: 0.5, fontSize: 11 }}
                      variant="outlined"
                      onDelete={() => removeCategoryApplied(data)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : null}
      </Card>
      <>
        <Card elevation={0}>
          <CardContent className="lmiw-content">
            <Grid container spacing={2} mt={0}>
              {!loadingItems && items
                ? items.length > 0 ? items.map((item: any) => (
                  <Grid item xs={6} sm={6} md={4} lg={3} key={item.idCatLndItem}>
                    <Fade
                      in={!!items}
                      timeout={searchCurrency != ''
                        ? item.rowNumber > items.length - listSize && items.length - listSize > 0
                          ? (item.rowNumber - (items.length - listSize)) * 20
                          : item.rowNumber * 20
                        : item.rowNumber > items.length - listSize && items.length - listSize > 0
                          ? (item.rowNumber - (items.length - listSize)) * 100
                          : item.rowNumber * 100}
                    >
                      <Box className="lmiw-item" onClick={() => handleClick(item)}>
                        <Stack
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="flex-end"
                          className="lmiw-img"
                          sx={{
                            backgroundImage: `url(${item.itemPicture})`,
                          }}
                        >
                          <Chip
                            label={item.dailyRentalPrice !== '0.00'
                              ? `$${parseInt(item.dailyRentalPrice, 10)} ${item.currency}`
                              : item.hourlyRentalPrice !== '0.00'
                                ? `$${parseInt(item.hourlyRentalPrice, 10)} ${item.currency}`
                                : item.weeklyRentalPrice !== '0.00'
                                  ? `$${parseInt(item.weeklyRentalPrice, 10)} ${item.currency}`
                                  : `$${parseInt(item.monthlyRentalPrice, 10)} ${item.currency}`}
                            // label={item.rowNumber > (items.length - listSize) && items.length - listSize > 0 ? (item.rowNumber - (items.length - listSize)) * 100 : (item.rowNumber * 100)}
                            variant="outlined"
                            className="lmiw-item__price"
                          />
                        </Stack>
                        <Box sx={{ pt: 1 }}>
                          <Typography
                            gutterBottom
                            variant="subtitle2"
                            className="lmiw-item__name"
                          >
                            {item.name}
                          </Typography>
                        </Box>
                      </Box>
                    </Fade>
                  </Grid>
                ))
                  : (!loading ? (
                    <Grid item xs={12} sx={{ width: '100%', height: { xs: 'auto', md: '70vh' } }}>

                      <EmptyState
                        kImage={empty_lend}
                        kTitle={t('search_page.no_results')}
                        kHeightAuto
                        kDescription={t('search_page.no_results_info')}
                      />
                    </Grid>
                  ) : null)
                : (
                  <>
                    {[...Array(listSize)].map((_value: any, index: number) => (
                      skeletonListing(index)
                    ))}
                  </>
                )}

              {loadingMore ? (
                <>
                  {[...Array(listSize)].map((_value: any, index: number) => (
                    <Fade in={loading} timeout={index * 100} key={uuidv4()}>
                      {skeletonListing(index)}
                    </Fade>
                  ))}
                </>
              ) : null}
            </Grid>
          </CardContent>
        </Card>
        {(items && items[0]?.totalRows !== items.length && items.length > 0) && showMore
          ? (
            <Stack
              m={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                onClick={() => fnBtnMore()}
                variant="outlined"
                className="lmiw-btn-more"
                disabled={loading}
              >
                {t('search_page.more')}
              </Button>

            </Stack>
          )
          : null}

      </>
    </Box>
  );
}

import {
  Box,
  Button,
  CardMedia,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import { DialogCommon, LmiTheme } from '../../../../ProfilePrivate/Components';
import createdCircleImage from '../../../../../../assets/create_circle/circleCreated.png';
import confetti from '../../../../../../assets/create_circle/confeti.png';
import confettiMobile from '../../../../../../assets/create_circle/confetimobile.png';
import { DialogCircleCreatedModels } from './CircleCreated.models';
import ShareCircle from './ShareCircle';
import MyCirclesListingContext from '../../../../../../context/myCirclesListing/myCirclesListingContext';

function DialogCircleCreated(props: DialogCircleCreatedModels) {
  const {
    circle,
  }: any = useContext(MyCirclesListingContext);

  const { t } = useTranslation('global');

  const [open, setOpen] = React.useState(false);

  const [deepLink, setDeepLink] = useState('');

  useEffect(() => {
    setDeepLink(circle?.deepLink);
  }, []);

  const handleModalToggle = () => {
    setOpen(true);
  };

  const closeDialogModal = () => {
    setOpen(false);
  };

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        maxWidth="686px"
        spacing={0}
        py={3}
      >
        {
          isMobile ? (
            <>
              <Box
                width="100%"
                height="54%"
                position="absolute"
                sx={{
                  top: 0,
                  zIndex: 0,
                  backgroundImage: `url(${confettiMobile})`,
                  backgroundPosition: 'center',
                  height: '196px',
                }}
              />
              <Typography sx={{
                mb: -1,
                fontSize: 'h5.fontSize',
                fontWeight: 600,
                maxWidth: '235px',
                textAlign: 'center',
              }}
              >
                {t('circles.add_circle_page.created_circle')}
              </Typography>

            </>
          ) : (
            <>
              <Box
                width="100%"
                height="54%"
                position="absolute"
                sx={{
                  top: 0,
                  zIndex: 0,
                  backgroundImage: `url(${confetti})`,
                  backgroundPosition: 'center',
                  height: '281.3px',
                }}
              />
              <Typography sx={{
                pt: '35px',
                fontSize: 'h5.fontSize',
                fontWeight: 600,
                maxWidth: '285px',
                textAlign: 'center',
              }}
              >
                {t('circles.add_circle_page.created_circle')}
              </Typography>

            </>
          )
        }

        <CardMedia
          component="img"
          alt="confetti"
          image={createdCircleImage}
          sx={{ width: '70%', maxWidth: '325px', zIndex: 2 }}
        />
        <Box
          sx={{
            fontSize: 'subtitle1.fontSize',
            fontWeight: 500,
            color: LmiTheme.light.systemBackground.tertiary,
            maxWidth: '70%',
            textAlign: 'center',
            mb: '30px',
            zIndex: 1,
          }}
        >
          {t('circles.add_circle_page.add_friends')}
        </Box>
        <Button
          onClick={() => {
            handleModalToggle();
          }}
          disableElevation
          variant="contained"
          size="large"
          sx={{
            px: 3,
            fontSize: 'subtitle1.fontSize',
            textTransform: 'none',
            fontWeight: 'bold',
            mb: '30px',
          }}
        >
          {t('circles.add_circle_page.share_with_friends')}
        </Button>
        <Button
          variant="text"
          onClick={props.kOnContinue}
          sx={{
            textTransform: 'none',
            fontSize: '17px',
          }}
        >
          {t('circles.add_circle_page.close')}
        </Button>

        <DialogCommon
          kTitle={t('circles.add_circle_page.share_circle')}
          kMaxWidth="sm"
          kEnableBorderRadius
          kMaxHeight={isMobile ? '450px' : 'auto'}
          kBorderRadius={isMobile ? 0 : '10px'}
          kFixedDialog={0}
          kFixedDialogPosition="fixed"
          kDisableBottomDivider
          kDisableContentPadding
          kKeepBtnSize
          kCenterTitle
          kSlideTransition={isMobile}
          kTopDivider
          kOpenDialogLists={open}
          kOnClose={closeDialogModal}
          kFullScreenMobile={false}
          kDisableActions
          kContent={
            (
              <ShareCircle deepLink={deepLink !== '' ? deepLink : false} />
            )
          }
        />
      </Stack>
    </>
  );
}

export default DialogCircleCreated;

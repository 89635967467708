import * as React from 'react';
import { useState } from 'react';
import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import {
  Paper,
  BottomNavigation,
  Divider,
  Grid,
  Accordion,
  AccordionSummary,
  Button,
  AccordionDetails,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import LmiTheme from '../../../themes/lmi-mui-theme/lmi-theme';
import Summary from '../Summary/Summary';
import { BottomBarSummaryModels } from './BottomBarSummary.models';
import bookItemContext from '../../../context/bookItem/bookContext';
import { formatter } from '../../../services/common';

function BottomBarSummary(props: BottomBarSummaryModels) {
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChangeExpanded = (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const bookContext = React.useContext(bookItemContext);
  const {
    accesories,
    validateBookNow,
    summary,
    bookDetails,
    bookType,
    deliveryType,
    availability: {
      dateRange,
    },
  }: any = bookContext;

  const [bookSummary, setBookSummary] = useState(summary);

  const [grandTotal, setGrandTotal] = useState(bookSummary?.grandTotal && bookSummary?.grandTotal != 0 ? bookSummary?.grandTotal : 0);

  React.useEffect(() => {
    setBookSummary(summary);
    setGrandTotal(summary?.grandTotal && summary?.grandTotal != 0 ? summary?.grandTotal : 0);
  }, [summary]);

  const history = useHistory();

  const continueBook = async () => {
    const { redirect } = await validateBookNow();
    // const stateRedirect = toState;
    if (redirect && redirect != '') {
      history.push(redirect, {
        accesories,
        bookDetails,
        deliveryType,
        dateRange,
      });
    }
  };

  const { t } = useTranslation('global');
  return (
    <>
      <Paper
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 3,
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          sx={{
            height: 'auto',
          }}
        >
          <Grid item xs={12} sm={12} md={4}>
            <Accordion
              disableGutters
              expanded={expanded === 'expanded'}
              onChange={handleChangeExpanded('expanded')}
              elevation={5}
            >
              <AccordionSummary sx={{ p: 0 }}>
                <Grid container direction="row" justifyContent="center" alignItems="center" rowSpacing={expanded !== 'expanded' ? 1 : 0} columnSpacing={{ xs: expanded !== 'expanded' ? 1 : 0 }}>
                  {
                    expanded !== 'expanded'
                    && (
                      <Grid ml={2} item xs="auto" sx={{ fontSize: 'subtitle1.fontSize', fontWeight: '500' }}>
                        {t('other.total_cost')}
                      </Grid>
                    )
                  }
                  <Grid px={2} item xs sx={{ fontSize: expanded !== 'expanded' ? 'subtitle1.fontSize' : 'h6.fontSize', fontWeight: 'bold' }}>
                    {expanded !== 'expanded' ? (
                      <Typography sx={{ fontSize: 'subtitle1.fontSize', fontWeight: 'bold' }}>
                        {formatter.format(grandTotal)}
                        {' '}
                        {bookDetails?.currency}
                      </Typography>
                    ) : t('checkout.summary')}
                  </Grid>
                  <Grid mr={expanded !== 'expanded' ? 2 : 1} item xs={1} sx={{ fontSize: 'subtitle1.fontSize', fontWeight: 'bold' }}>
                    {
                      expanded !== 'expanded'
                        ? <ExpandLessRounded sx={{ fontSize: '2em', color: LmiTheme.light.accent }} />
                        : <ExpandMoreRounded sx={{ fontSize: '2em', color: LmiTheme.light.accent }} />
                    }
                  </Grid>
                  <Grid item xs={12} sx={{ fontSize: 'subtitle1.fontSize', fontWeight: 'bold' }}>
                    <Divider sx={{ mb: expanded !== 'expanded' ? 1 : 0, mt: expanded !== 'expanded' ? 0 : 1 }} />
                  </Grid>
                  <Grid item pb={expanded !== 'expanded' ? 1 : 0} xs={12}>
                    <Accordion disableGutters elevation={0} expanded={expanded !== 'expanded'}>
                      <AccordionSummary sx={{ display: 'none', p: 0 }} />
                      <AccordionDetails sx={{ py: 0 }}>
                        {
                          props.kOnClickOpenDialogCongratulations ? (
                            <Button
                              onClick={() => continueBook()}
                              fullWidth
                              variant="contained"
                              size="large"
                              disableElevation
                              sx={{ textTransform: 'none', fontSize: 'subtitle1.fontSize', fontWeight: 'bold' }}
                            >
                              {t('listing_detail.pay_now')}
                            </Button>
                          ) : (
                            <Button
                              onClick={() => continueBook()}
                              fullWidth
                              variant="contained"
                              size="large"
                              disableElevation
                              sx={{ textTransform: 'none', fontSize: 'subtitle1.fontSize', fontWeight: 'bold' }}
                            >
                              {(bookType == 'BUY') ? t('listing_detail.buy_now') : t('listing_detail.book_now')}
                            </Button>
                          )
                        }
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Summary
                  kOnClickOpenDialogAvailability={props.kOnClickOpenDialogAvailability}
                  kOnClickOpenDialogCongratulations={props.kOnClickOpenDialogCongratulations}
                  kIsBook={!props.kOnClickOpenDialogCongratulations}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </BottomNavigation>
      </Paper>
    </>
  );
}

export default BottomBarSummary;

import { LmiTheme } from '../../../../../../ProfilePrivate/Components';

const AdminsChatMessagesRightStyle = {
  width: '100%',
  '.lmiw-box-message': {
    position: 'relative',
    marginBottom: '10px',
    padding: '10px',
    maxWidth: '45%',
    border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
    borderRadius: '10px',
    '.lmiw-timestamp-message': {
      mt: 0.5,
      color: LmiTheme.light.systemBackground.secondary,
      fontSize: '11px',
    },
  },
};

export default AdminsChatMessagesRightStyle;

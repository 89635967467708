import {
  ACTIVITY_INFO_LOADED,
  ACTIVITY_HISTORY_INFO_LOADED,
  ACTIVITY_HISTORY_INFO_FILTERED,
  ACTIVITY_INFO_FILTERED,
  ACTIVITY_DETAIL_ITEM_SET,
  ACTIVITY_EXTRA_INFO_LOADED,
  RENTAL_PICKUP_IMAGES_LOADED,
  RENTAL_DROPOFF_IMAGES_LOADED,
  SET_NEW_ACTIVITY_LOCATION,
  ACTIVITY_CIRCLE_JOIN_EXTRA_INFO,
  SET_LOADING_TRUE,
  UNSET_ACTIVITY_ITEM,
  ACTIVITY_CIRCLE_INVITATION_INFO,
} from '../../types';

export default (state: any, action: any) => {
  switch (action.type) {
    case ACTIVITY_INFO_LOADED: {
      return {
        ...state,
        activityList: action.payload.activityList,
        untouchedActivityList: action.payload.untouchedActivityList,
        loading: false,
      };
    }
    case ACTIVITY_HISTORY_INFO_LOADED: {
      return {
        ...state,
        activityHistoryList: action.payload.activityHistoryList,
        untouchedActivityHistoryList: action.payload.untouchedActivityHistoryList,
        loading: false,
      };
    }
    case ACTIVITY_HISTORY_INFO_FILTERED: {
      return {
        ...state,
        activityHistoryList: action.payload.historyFilteredList,
      };
    }
    case ACTIVITY_INFO_FILTERED: {
      return {
        ...state,
        activityList: action.payload.activityFilteredList,
      };
    }
    case ACTIVITY_DETAIL_ITEM_SET: {
      return {
        ...state,
        openedActivityItem: action.payload.openedActivityItem,
        pickUpImages: action.payload.pickUpImages,
        dropOffImages: action.payload.dropOffImages,
        canCancel: action.payload.canCancel,
        itemDeleted: action.payload.itemDeleted,
        loading: true,
      };
    }
    case ACTIVITY_EXTRA_INFO_LOADED: {
      return {
        ...state,
        renterLenderInfo: action.payload.renterLenderInfo,
        userOwnInfo: action.payload.userOwnInfo,
        rentalSummary: action.payload.rentalSummary,
        rentalAccessories: action.payload.rentalAccessories,
        rentalReviewComment: action.payload.rentalReviewComment,
        rentalPaymentMethod: action.payload.rentalPaymentMethod,
        termsAndConditions: action.payload.termsAndConditions,
        requiresRenterIdVerification: action.payload.requiresRenterIdVerification,
        loading: false,
      };
    }
    case RENTAL_DROPOFF_IMAGES_LOADED: {
      return {
        ...state,
        dropOffImages: action.payload.dropOffImages,
      };
    }
    case RENTAL_PICKUP_IMAGES_LOADED: {
      return {
        ...state,
        pickUpImages: action.payload.pickUpImages,
      };
    }
    case SET_NEW_ACTIVITY_LOCATION: {
      return {
        ...state,
        openedActivityItem: action.payload.openedActivityItem,
      };
    }
    case ACTIVITY_CIRCLE_JOIN_EXTRA_INFO: {
      return {
        ...state,
        openedActivityItem: action.payload.openedActivityItem,
        renterLenderInfo: action.payload.renterLenderInfo,
        circleInfo: action.payload.circleInfo,
        loading: false,
      };
    }
    case ACTIVITY_CIRCLE_INVITATION_INFO: {
      return {
        ...state,
        openedActivityItem: action.payload.openedActivityItem,
        circleInvitationInfo: action.payload.circleInvitationInfo,
        loading: false,
      };
    }
    case UNSET_ACTIVITY_ITEM: {
      return {
        ...state,
        openedActivityItem: undefined,
      };
    }

    case SET_LOADING_TRUE: {
      return {
        ...state,
        loading: true,
      };
    }
    default:
      return state;
  }
};

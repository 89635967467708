import {
  Box,
  FormControlLabel,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatter } from '../../../services/common';
import LmiTheme from '../../../themes/lmi-mui-theme/lmi-theme';
import { AccessoryModels } from './Accessory.models';

function Accessory(props: AccessoryModels) {
  const { funUpdate } = props;
  const { t } = useTranslation('global');
  return (
    <Box p={0} m={0}>
      <ListItem
        dense
        key={0}
        disablePadding
        divider={props.kDivider}
        sx={{
          py: 1,
          pr: 1,
          pl: 2,
        }}
      >
        <ListItemText
          primaryTypographyProps={{ fontWeight: '500', fontSize: 'subtitle1.fontSize' }}
          secondaryTypographyProps={{ color: LmiTheme.light.systemBackground.tertiary, fontSize: 'subtitle2.fontSize' }}
          primary={props.kTitle}
          secondary={props.kDescription}
        />
        <ListItemSecondaryAction sx={{ top: 25 }}>
          <FormControlLabel
            value={props.kValue}
            control={(
              <Switch
                disabled={props.kIsReadOnly}
                color="primary"
                onChange={(e) => {
                  if (funUpdate) { funUpdate(e, props); }
                }}
                checked={props.kChecked}
              />
)}
            label={
              (
                <Typography fontSize="subtitle1.fontSize" fontWeight={500}>
                  {formatter.format(props.kPrice!)}
                  {' '}
                  {props.kCurrency}
                  <> </>
                  {`${t(`price_type.${props.kPriceType}`)}`}
                </Typography>
              )
            }
            labelPlacement="start"
            sx={{ fontWeight: '700' }}
          />
        </ListItemSecondaryAction>
      </ListItem>
    </Box>
  );
}

export default Accessory;

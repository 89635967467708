/* eslint-disable dot-notation */
// import { useEffect, useState } from 'react';
import { List } from '@mui/material';
import DialogAccessoriesItem from './DialogAccessoriesItem';
import { getAccesoryPrice } from '../../../../../services/common';
import { RENT } from '../../../../../types';

function DialogAccessories({
  accList,
  bookType,
  precingType,
  tempEditAccesory,
  currency,
}: any) {
  return (
    <>
      <List sx={{ pl: 1 }}>
        {
          /* eslint-disable react/jsx-props-no-spreading */
          accList.map((l:any, i:any) => (
            <DialogAccessoriesItem
              key={`${l.idRelItemAccesorie}-editModal`}
              kPrice={getAccesoryPrice(l, bookType, precingType)}
              kPriceType={bookType == RENT ? precingType : ''}
              kTitle={l.name}
              kDescription={l.dscription}
              kValue={i}
              kDivider={i < accList.length - 1 ?? false}
              kChecked={l.tempAdded ?? false}
              kOnChange={tempEditAccesory}
              kCurrency={currency}
              {...l}
            />
          ))
        }
      </List>
    </>
  );
}

export default DialogAccessories;

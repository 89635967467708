import {
  Box,
  Typography, Grid, Button, Stack, Chip, useMediaQuery, useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import RoomIcon from '@mui/icons-material/Room';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useContext, useState, useEffect } from 'react';
import { LmiTheme, MapComponent, DialogCommon } from '../../../../../components/Common';
import { ItemDetailLocationModels } from './ItemDetaiModels';
import LmiColorPalette from '../../../../../themes/lmi-mui-theme/lmi-color-palette';
// import DialogCommon from '../../../../../components/Common/DialogCommon/DialogCommon';
import StaticMap from '../../../../../components/Common/StaticMap/StaticMap';
import addEditItemContext from '../../../../../context/addEditItem/addEditItemContext';
import { MapData } from '../../../../../components/Common/Map/Map.models';

export default function ItemDetailLocation(props: ItemDetailLocationModels) {
  const addEditContextLog = useContext(addEditItemContext);

  const {
    itemInfo,
    updateItemInfo,
  }: any = addEditContextLog;

  const { t } = useTranslation('global');

  const updateMapa = (mapaData: MapData) => {
    updateItemInfo(
      {
        ...itemInfo,
        lat: mapaData.lat,
        lon: mapaData.long,
      },
    );
  };
  const [open, setOpen] = useState(false);
  const [titleByCat, setTitleByCat] = useState<string>(t('item_detail.exact_item_location'));
  const handleModalToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (itemInfo?.idCatTypeItem == 1) {
      setTitleByCat(t('item_detail.exact_item_location'));
    } else if (itemInfo?.idCatTypeItem == 2) {
      setTitleByCat(t('item_detail.exact_service_location'));
    } else {
      setTitleByCat(t('item_detail.exact_place_location'));
    }
  }, [itemInfo?.idCatTypeItem]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Button
            size="large"
            variant="outlined"
            onClick={() => { handleModalToggle(); }}
            sx={{
              borderColor: LmiTheme.light.systemBackground.separator,
              borderRadius: 3,
              mb: 2,
              height: 56,
            }}
            fullWidth
            startIcon={<RoomIcon sx={{ color: LmiTheme.light.systemBackground.primary }} />}
            endIcon={<ChevronRightIcon />}
          >
            <Typography
              noWrap
              component="label"
              sx={{
                fontWeight: 'regular',
                color: LmiTheme.light.systemBackground.primary,
                textTransform: 'capitalize',
                width: '100%',
                textAlign: 'left',
                cursor: 'pointer',
              }}
            >
              {props.kAdress ? props.kAdress : `${t('item_detail.item_location')}`}
            </Typography>
          </Button>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            mb={2}
          >
            <Typography noWrap component="label" fontSize="16" color={LmiTheme.light.systemBackground.secondary}>
              {titleByCat}
            </Typography>
            <Chip
              label={props.kExactAddress ? `${t('item_detail.showing')}` : `${t('item_detail.hidden')}`}
              variant="filled"
              sx={{
                fontWeight: 'bold',
                height: '25px',
                borderRadius: '7px',
                color: props.kExactAddress ? LmiColorPalette.greenScheme[900] : LmiTheme.light.systemBackground.primary,
                background: props.kExactAddress ? LmiColorPalette.greenScheme[50] : LmiTheme.light.systemBackground.solid,
              }}
            />
          </Stack>

          <Typography
            component="label"
            fontSize="16"
            sx={{
              fontWeight: '300',
              color: LmiTheme.light.systemBackground.secondary,
            }}
          >
            {t('item_detail.hide_location_txt')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StaticMap
            kExactLocation={props.kExactAddress}
            kLat={parseFloat(props?.kLat)}
            kLng={parseFloat(props?.kLong)}
          />
        </Grid>
      </Grid>
      <DialogCommon
        kTitle={t('item_detail.item_location')}
        kMaxWidth="md"
        kBorderRadius={isMobile ? 0 : '10px'}
        kDisableContentPadding={isMobile}
        kOpenDialogLists={open}
        kSlideTransition={isMobile}
        kOnClose={handleModalToggle}
        kDisableActions
        kContent={
          (
            <MapComponent
              kDraggable={false}
              state={{
                lat: props?.kLat,
                long: props?.kLong,
              }}
              funUpdateInfo={updateMapa}
            />
          )
        }
      />

    </Box>

  );
}

ItemDetailLocation.defaultProps = {
  kDescription: 'kDescription',
  kColor: LmiTheme.light.systemBackground.secondary,
  kFontSize: 'subtititle1.fontSize',
  kFontWeight: 'normal',
};

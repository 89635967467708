import {
  Box, Button, Card, Stack,
} from '@mui/material';
import React, { useContext, useState } from 'react';
// import { toast } from 'react-toastify';
import ActivityUser from '../Components/ActivityUser/ActivityUser';
// import ActivityContext from '../../../../../../../../context/activity/activityContext';
import ActivityContext from '../../../../../context/activity/activityContext';
import ActivityDetailStyle from '../ActivityDetail.style';
import ActivityCircleCard from '../Components/ActivityCircleCard/ActivityCircleCard';
import { LoadingLayout } from '../../../../../components/Common';
// import BtnLoginStyle from '../../../../../components/Common/UserMenu/Components/BtnLogin/BtnLogin.style';
import BtnStyle from './BtnStyle.style';
import activityActionsContext from '../../../../../context/activity/actions/activityActionsContext';
import InputTextField from '../../../AddItem/Components/InputTextField/InputTextField';

export default function JoinRequestDetail() {
  const activityContext = useContext(ActivityContext);
  const {
    openedActivityItem,
    renterLenderInfo,
    circleInfo,
  }: any = activityContext;

  const activityActionContext = React.useContext(activityActionsContext);
  const {
    approveRejectJoinCircle,
    loading,
    loadingActAction,
  }: any = activityActionContext;

  const [comment, setComment] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };
  return (
    <>
      { (!loading && !loadingActAction)
        ? (
          <Card elevation={0} sx={ActivityDetailStyle}>

            <Box className="lmiw-detail-content">

              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="end"
                spacing={2}
                px={2}
                pb={2}
              >
                <ActivityUser
                  notifDetail={openedActivityItem}
                  userInfo={renterLenderInfo}
                  kOpenChat={() => {}}
                  kType="verified"
                />

                <ActivityCircleCard
                  circleInfo={circleInfo}
                />

                <InputTextField
                  kLabel="Comments"
                  kInputMaxLength={100}
                  kInputId="Comments"
                  kInputType="text"
                  kInputPlaceholder="Reasons for approval or rejection"
                  kInputValue={comment}
                  kInputMultiline
                  kInputRows={4}
                  kInputOnChange={handleChange}
                  kInputRequired
                  kInputCurrentLength={comment.length}
                  kInputName=" "
                />

                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="end"
                  spacing={2}
                  px={2}
                  pb={2}
                >
                  <Button
                    sx={BtnStyle}
                    variant="outlined"
                    fullWidth={false}
                    color="primary"
                    className="lmiw-btn-login"
                    onClick={() => {
                      approveRejectJoinCircle(openedActivityItem, false, comment);
                    }}
                  >
                    Deny
                  </Button>
                  <Button
                    sx={BtnStyle}
                    variant="contained"
                    fullWidth={false}
                    color="primary"
                    className="lmiw-btn-login"
                    onClick={() => {
                      approveRejectJoinCircle(openedActivityItem, true, comment);
                    }}
                  >
                    Approve
                  </Button>
                </Stack>

              </Stack>
            </Box>
          </Card>
        )
        : (<LoadingLayout />)}

    </>
  );
}

import {
  REGISTRATION_SUCCESSFUL,
  REGISTRATION_ERROR,
  GET_USER,
  LOGIN_SUCCESSFUL,
  LOGIN_ERROR,
  CLOSE_SESION,
  CONFIRM_ACCOUNT,
  CONFIRM_ERROR,
  FORGOT_VERIFICATION,
  REQUEST_ID,
  USER_LMI,
  BLOCKED,
  NOTAUTHORIZED,
  EMAIL_PASS,
  TOKEN,
  CLEAN_ALERT,
  SET_NEW_USER,
} from '../../types';

export default (state: any, action: any) => {
  switch (action.type) {
    case REGISTRATION_SUCCESSFUL:
    case LOGIN_SUCCESSFUL:
      // localStorage.setItem('token', action.payload.token);
      localStorage.setItem('name', action.payload.userName);
      localStorage.setItem('logType', action.payload.appType);
      return {
        ...state,
        authenticated: true,
        message: null,
        isBlocked: false,
        confirmAccount: true,
        user: action.payload.user,
        userName: action.payload.userName,
        appType: action.payload.appType,
      };
    case GET_USER:
      return {
        ...state,
        authenticated: true,
        user: action.payload.user,
        isBlocked: false,
        confirmAccount: true,
        // token: action.payload.token,
      };
    case CONFIRM_ACCOUNT:
      localStorage.setItem('logType', action.payload.appType);
      if (action.payload.userName) {
        localStorage.setItem('name', action.payload.userName);
      }

      return {
        ...state,
        authenticated: false,
        user: action.payload.user,
        isBlocked: false,
        confirmAccount: action.payload.confirm,
        message: action.payload.message,
        appType: action.payload.appType,
        userName: action.payload.userName ?? state.userName,
        userLMI: action.payload.userLMI,
        phoneNumber: action.payload.phoneNumber ?? state.phoneNumber,
        email: action.payload.email ?? state.email,
        password: action.payload.password ?? state.password,
        comesForgot: false,
      };
    case USER_LMI:
      return {
        ...state,
        userLMI: action.payload,
        email: action.payload.email ?? state.email,
        isBlocked: false,
      };
    case BLOCKED:
      return {
        ...state,
        isBlocked: action.payload,
      };
    case CLOSE_SESION:
    case LOGIN_ERROR: {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      localStorage.removeItem('logType');
      // localStorage.clear();

      return {
        token: null,
        user: null,
        authenticated: null,
        confirmAccount: null,
        message: action.payload,
        isBlocked: false,
        codeSend: false,
        userName: '',
        closeSession: true,
      };
    }
    case CONFIRM_ERROR:
      return {
        ...state,
        message: action.payload,
        isBlocked: false,
      };
    case NOTAUTHORIZED:
      const {
        email, message, appType, userName,
      } = action.payload;
      if (userName) {
        localStorage.setItem('name', userName);
      }
      return {
        ...state,
        message,
        email: email ?? state.email,
        appType: appType ?? state.appType,
        isBlocked: false,
        userName: userName ?? state.userName,
      };
    case REGISTRATION_ERROR:
      return {
        ...state,
        message: action.payload,
        confirmAccount: true,
        isBlocked: false,
      };
    case FORGOT_VERIFICATION:
      const { codeSend, email: _email } = action.payload;
      return {
        ...state,
        codeSend,
        email: _email ?? state.email,
        comesForgot: true,
        isBlocked: false,
      };
    case REQUEST_ID:
      return {
        ...state,
        requestId: action.payload.requestId,
        phoneNumber: action.payload.phoneNumber,
        isBlocked: false,
      };
    case EMAIL_PASS:
      return {
        ...state,
        email: action.payload.email ?? state.email,
        password: action.payload.password ?? state.password,
      };
    case TOKEN:
      return {
        ...state,
        token: action.payload,
        message: null,
      };
    case CLEAN_ALERT:
      return {
        ...state,
        message: null,
      };
    case SET_NEW_USER:
      return {
        ...state,
        newUser: action.payload,
      };

    default:
      return state;
  }
};

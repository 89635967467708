import {
  Stack, Box, Paper, FormControlLabel,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import InputNumerictextField from '../InputNumericTextField/InputNumericTextField';
import Heading from '../Heading/Heading';
import IOSSwitch from '../../../Listing/Components/AddItem/Components/ItemBasicInformation/ItemBasicInformation.style';
import { MaxRateSwitchModels } from './MaxRateSwitch.models';

const MaxRateSwitch = (props: MaxRateSwitchModels) => {
  const { t } = useTranslation('global');
  // const [valueMaxRate, setMaxRateValue] = React.useState(props?.kItemInfo.serviceMaxRate != undefined && props?.kItemInfo.serviceMaxRate > 0 ? props?.kItemInfo.serviceMaxRate : '');
  /*
  const onChange = (e: any) => {
    setMaxRateValue(e.target.value);
  };
*/
  return (
    <>
      <Box mt={3}>
        <Heading kTitle={t('activity_detail.max_rate')} />
      </Box>
      <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" py={3}>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" pl={2} sx={{ width: '100%' }}>
            <InputNumerictextField
              kLabel=""
              kInputMaxLength={7}
              kInputId="maxRate"
              kInputType="text"
              kInputPlaceholder={t('item_detail.enter_value')}
              kInputMaxLengthHide
              kInputValue={props.kserviceMaxRate}
              kInputMultiline={false}
              kPrecision={0}
              kDisabled={!props.khasMaxRate}
              kInputRows={1}
              kInputMaxRows={1}
              kInputOnChange={props.konChangeServiceMaxRate}
              kInputRequired={false}
              kInputName="maxRate"
              kLabelRequiredHide

            />
            {' '}

          </Stack>
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <FormControlLabel
              control={(
                <IOSSwitch
                  name="hasMaxRate"
                  checked={props.khasMaxRate || false}
                  onChange={(e) => { props.kOnChangeMaxRate(e); }}
                  sx={{ mr: 0 }}
                />
              )}
              label=""
            />
          </Stack>
        </Stack>

      </Paper>
    </>
  );
};

export default MaxRateSwitch;

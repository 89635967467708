/* eslint-disable max-len */
import {
  Box, Stack, AvatarGroup, Avatar, Typography, useTheme, useMediaQuery,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import AddEditCirclesContext from '../../../../../../../context/circles/addEditCirclesContext';
import ManageItemsEmpty from './ManageItemsEmpty';

export default function ManageItems(props: any) {
  const circlesContextLog = useContext(AddEditCirclesContext);
  const {
    circleItemsEdit,
    circleInfoEdit,
  }: any = circlesContextLog;
  const { t } = useTranslation('global');

  const [arrayCircles, setArrayCircles] = useState([]);

  useEffect(() => {
    const { kIdCatLndCircle } = props;
    if (kIdCatLndCircle) {
      const filterOwnItems = circleItemsEdit?.filter((item: any) => item.idCatLndCircle === kIdCatLndCircle && item.active === true && !(circleInfoEdit?.canUploadItem === 2 && item.memberItem == true));

      setArrayCircles(filterOwnItems);
    }
  }, [circleItemsEdit, props.refresh]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box>
      <Stack p={2} direction="row" justifyContent="space-between" spacing={3}>
        {
          arrayCircles?.length ? (
            <>
              <AvatarGroup
                total={arrayCircles?.length}
                sx={{
                  '& .MuiAvatar-root': {
                    width: isMobile ? '50px' : '60px',
                    height: isMobile ? '50px' : '60px',
                    backgroundColor: '#f3f3f3',
                    color: 'black',
                    fontSize: '16px',
                  },
                }}
                variant="rounded"
                max={isMobile ? 3 : 5}
              >

                {arrayCircles?.map((item: any) => (
                  <Avatar key={uuid()} alt={item.name} src={item?.itemPicture} />
                ))}
              </AvatarGroup>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography sx={{
                  pt: 'auto', pb: 'auto', color: '#999da2', fontSize: '16px',
                }}
                >
                  {t('circles.add_circle_page.total_items')}
                  {' '}
                  {arrayCircles?.length}
                </Typography>
              </Box>
            </>
          ) : (<ManageItemsEmpty />)
        }
      </Stack>
    </Box>
  );
}

/* eslint-disable no-nested-ternary */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable react/jsx-props-no-spreading */
import SwiperCore, {
  Pagination, Navigation, Thumbs,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { alpha } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import 'swiper/swiper-bundle.min.css';
import {
  Stack, Box, Paper, Fab, CardContent, Card, IconButton, Typography,
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import { OtherListingsCarouselStyle } from './OtherListingsCarousel.style';
import { OtherListingsCarouselModels } from './OtherListingsCarousel.models';
import { LmiTheme, SectionTitle } from '../../../../../components/Common';
import bookContext from '../../../../../context/bookItem/bookContext';
import FavoritesContext from '../../../../../context/favorites/favoritesContext';
import exploreContext from '../../../../../context/explore/exploreContext';
import homeContext from '../../../../../context/home/homeContext';

SwiperCore.use([Pagination, Navigation, Thumbs, Pagination]);

export default function OtherListingsCategories(props: OtherListingsCarouselModels) {
  const {
    categories,
    getCategories,
  }: any = useContext(homeContext);
  const {
    similarItemList,
    searchFn,
    changedSimilarItems,
  }: any = useContext(exploreContext);

  const [isHeartClicked, setIsHeartClicked] = useState(false);

  const [similarItems, setSimilarItems] = useState([]);

  const {
    clearData,
    bookDetails,
    lenderItemList,
  }: any = useContext(bookContext);
  let filteredItems: never[] = [];

  useEffect(() => {
    if (!categories) {
      getCategories();
    }
  }, []);

  useEffect(() => {
    if (props.kType == 'lenderItems') {
      setSimilarItems(filteredItems);
    }
  }, [lenderItemList]);

  const history = useHistory();

  const {
    updateFavorite,
  }: any = useContext(FavoritesContext);

  function handleClick(bookItem: any): void {
    if (!isHeartClicked) {
      if (bookItem.idCatLndItem == bookDetails.idCatLndItem) {
        window.scrollTo(0, 0);
      } else {
        clearData();
        clearStorageBookItem();
        localStorage.removeItem('precingType');
        console.log(bookDetails.idCatLndItem);
        localStorage.setItem('storageIdItem', bookItem.idCatLndItem);
        history.push('/bookitem', {
          bookItem,
        });
      }
    } else {
      setIsHeartClicked(false);
    }
  }

  function clearStorageBookItem() {
    localStorage.removeItem('storageDateRange');
    localStorage.removeItem('storageDeliveryType');
    localStorage.removeItem('storageAccesories');
  }

  const clickedHeart = async (item: any, isFavorite: boolean) => {
    setIsHeartClicked(true);
    await updateFavorite(item, isFavorite);
  };

  const MainParams = {
    modules: [Pagination, Navigation],
    spaceBetween: 15,
    slidesPerGroup: 3,
    navigation: {
      prevEl: `.prev-${props.kType}`,
      nextEl: `.next-${props.kType}`,
    },
    breakpoints: {
      1200: {
        slidesPerView: 3,
      },

      900: {
        slidesPerView: 2,
      },

      600: {
        slidesPerView: 3,
      },

      400: {
        slidesPerView: 2.5,
      },

      300: {
        slidesPerView: 1.5,
        slidesPerGroup: 1,
      },
      1: {
        slidesPerView: 1.5,
        slidesPerGroup: 1,
      },
    },
  };

  const { t } = useTranslation('global');

  useEffect(() => {
    if ((props.kType != '' || bookDetails?.category != '') && (props.kType != 'lenderItems')) {
      if (categories) {
        if (bookDetails?.category == 'Other' || props.kType == 'Other') {
          searchFn('', 0);
        } else {
          const categoryToFindId = bookDetails?.category;
          const categoryToApply = categories?.find((element: any) => element.category == categoryToFindId);
          searchFn('', 0, categoryToApply ? categoryToApply?.idCategory : 0);
        }
      }
    }
  }, [bookDetails?.category]);

  useEffect(() => {
    if (props.kType != 'lenderItems') {
      if (similarItemList?.length > 0) {
        filteredItems = similarItemList?.filter((item: any) => item.categoryDesc === bookDetails?.category).slice(0, 10) as never[];
        setSimilarItems(filteredItems);
      }
    }
  }, [changedSimilarItems]);

  if (props.kType == 'lenderItems' && lenderItemList?.length <= 1) {
    return (<Box />);
  }

  if (props.kType == 'lenderItems') {
    filteredItems = lenderItemList;
  }

  return (
    <>
      <Box sx={OtherListingsCarouselStyle}>
        <SectionTitle
          kMarginTop={4}
          kTitle={props.kType == 'lenderItems' ? t('other.other_lender_listings') : t('other.similar_listings')}
          kFontSize={20}
        />
        <Paper
          className="lmiw-activity-swiper"
          variant="outlined"
          elevation={0}
          sx={{ mb: '16px' }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={0}
            px={1}
            my={2}
          >
            <Fab
              key={`fab-${props.kType}-left`}
              size="medium"
              aria-label={t('activity.swiper.left')}
              className={`prev prev-${props.kType}`}
            >
              <KeyboardArrowLeftIcon />
            </Fab>
            <Box sx={{ width: { sm: 'calc(100% - 144px)', xs: '100%' }, ml: '0 !important' }}>
              <Swiper id={`swiper-${props.kType}`} {...MainParams}>
                {similarItems && similarItems.map((item: any) => (
                  <SwiperSlide key={item.idCatLndItem}>
                    <Card elevation={0} sx={{ position: 'relative' }}>
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        onClick={() => handleClick(item)}
                        sx={{
                          cursor: 'pointer',
                          aspectRatio: '5 / 4',
                          backgroundImage: `url(${item.itemPicture})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          borderRadius: '10px',
                        }}
                      />
                      <IconButton
                        sx={{
                          position: 'absolute',
                          top: 5,
                          right: 5,
                          backgroundColor: alpha(LmiTheme.light.systemBackground.primary, 0.5),
                          '&:hover': {
                            backgroundColor: alpha(LmiTheme.light.systemBackground.primary, 0.8),
                          },
                        }}
                        onPointerEnter={() => {
                          setIsHeartClicked(true);
                        }}
                        onPointerLeave={() => {
                          setIsHeartClicked(false);
                        }}
                        onClick={() => {
                          clickedHeart(item, !item.isFavorite);
                        }}
                      >
                        {item.isFavorite ? (
                          <Favorite
                            sx={{ color: 'white' }}
                          />
                        ) : (
                          <FavoriteBorder
                            sx={{ color: 'white' }}
                          />
                        )}
                      </IconButton>
                      <CardContent sx={{ p: 0, mb: '-24px' }}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          px={0}
                          pt={1}
                          pb={0.5}
                        >
                          <Typography
                            variant="body1"
                            color="text.primary"
                            fontWeight={500}
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 1,
                            }}
                          >
                            {item.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            fontWeight={800}
                            fontSize={12}
                            sx={{ whiteSpace: 'nowrap' }}
                          >

                            {
                            item.dailyRentalPrice != 0
                              ? `${parseInt(item.dailyRentalPrice, 10)} ${item.currency} ${t('item_pricing.daily')}`

                              : item.hourlyRentalPrice != 0
                                ? `$${parseInt(item.hourlyRentalPrice, 10)} ${item.currency} ${t('item_pricing.hourly')}`
                                : item.weeklyRentalPrice != 0
                                  ? `$${parseInt(item.weeklyRentalPrice, 10)} ${item.currency} ${t('item_pricing.weekly')}`
                                  : `$${parseInt(item.monthlyRentalPrice, 10)} ${item.currency} ${t('item_pricing.monthly')}`
}

                          </Typography>
                        </Stack>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          fontSize="12px"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                          }}
                        >
                          {item?.dscription}
                        </Typography>
                      </CardContent>
                    </Card>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
            <Fab
              key={`fab-${props.kType}-right`}
              size="medium"
              aria-label={t('activity.swiper.right')}
              className={`next next-${props.kType}`}
            >
              <ChevronRightIcon />
            </Fab>
          </Stack>

        </Paper>
      </Box>

    </>
  );
}

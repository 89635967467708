import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useContext } from 'react';
import LoadingLayout from '../../../../../components/Common/LoadingLayout/LoadingLayout';

import MyListingContext from '../../../../../context/myListing/myListingContext';
import LmiTheme from '../../../../../themes/lmi-mui-theme/lmi-theme';
import { AddItemInfoModel } from '../AddItem/AddItem.models';
import CardLend from '../CardLend/CardLend';
import { GridListingsModels } from './GridListings.models';

export default function GridListings(props: GridListingsModels) {
  const {
    items,
    loading,
  }: any = useContext(MyListingContext);

  return (
    <>
      <Box p={3} mb={4}>
        <Grid container rowSpacing={0} columnSpacing={{ md: 3, sm: 2, xs: 1 }}>
          {
            /* eslint-disable react/jsx-props-no-spreading */
            items && items.length > 0 && !loading
              ? items.map((item: AddItemInfoModel, index: number) => (
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  key={item.idCatLndItem}
                >
                  <Card
                    elevation={0}
                    onClick={() => (!loading ? props.onChange(item, index) : null)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="flex-starts"
                      sx={{
                        backgroundImage: `url(${item.itemPicture})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        borderRadius: '10px',
                        border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
                        overflow: 'hidden',
                        minHeight: { xs: '140px', sm: '180px' },
                      }}
                    >
                      <Avatar
                        onClick={undefined}
                        sizes="large"
                        sx={{
                          bgcolor: item.salePrice == 0 ? LmiTheme.light.systemBackground.tertiary : LmiTheme.light.dialog.success,
                          width: 34,
                          height: 34,
                          m: 1,
                        }}
                      >
                        <AttachMoneyIcon sx={{ fontSize: 20 }} />
                      </Avatar>
                    </Stack>
                    <CardContent sx={{ p: 0 }}>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        px={0}
                        pt={1}
                        pb={0.5}
                      >
                        <Typography
                          component="label"
                          fontSize={18}
                          fontWeight="500"
                          sx={{
                            textTransform: 'capitalize',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                          }}
                        >
                          {item.name ? item.name : 'No title'}
                        </Typography>
                      </Stack>
                      <Typography
                        component="p"
                        fontSize={14}
                        color={LmiTheme.light.systemBackground.secondary}
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                        }}
                      >
                        {item.dscription ? item.dscription : 'No description'}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))
              : <CardLend kContent={(<LoadingLayout />)} />
          }
        </Grid>
      </Box>
    </>
  );
}

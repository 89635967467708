import {
  Box,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LmiTheme } from '../../../../../components/Common';
import { InputTextFieldModels } from './InputTextField.models';

function InputTextField(props: InputTextFieldModels) {
  return (
    <Box
      sx={{
        width: '100%',
        '.price-center': {
          '.MuiInputBase-root': {
            paddingLeft: '6px',
          },
          '.MuiInputAdornment-positionStart': {
            marginRight: '0px',
          },
          input: {
            paddingRight: '6px',
          },
        },
        'div.Mui-disabled': {
          backgroundColor: LmiTheme.light.systemBackground.separator,
          fieldset: {
            borderColor: `${LmiTheme.light.systemBackground.separator} !important`,
          },
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        pb={1}
      >
        <Box sx={{ m: props.kLabelCentered ? '0 auto' : 0 }}>
          {
            !props.kLabelRequiredHide
            && <Typography component="span" fontSize="caption.fontSize" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
          }
          <Typography component="span" fontSize="caption.fontSize" fontWeight={500} color={props.kLabelColor}>
            {props.kLabel}
          </Typography>
        </Box>
        {
          !props.kInputMaxLengthHide
          && (
            <Typography component="span" fontSize="caption.fontSize" color={LmiTheme.light.systemBackground.tertiary}>
              {/* {`${valuesDescription.description.length}/${limitDescription}`} */}
              {/* 0/ */}
              {`${props.kInputCurrentLength} /`}
              {props.kInputMaxLength}
            </Typography>
          )
        }
      </Stack>
      <TextField
        key={props.kInputId}
        fullWidth
        onClick={props.kOnClick}
        id={props.kInputId}
        variant="outlined"
        disabled={props.kDisabled}
        type={props.kInputType}
        placeholder={props.kInputPlaceholder}
        multiline={props.kInputMultiline}
        rows={props.kInputRows}
        ref={props.kRef}
        // maxRows={props.kInputMaxRows}
        required={props.kInputRequired}
        name={props.kInputName}
        value={props.kInputValue}
        onChange={props.kInputOnChange}
        error={props.kInputError}
        helperText={props.kInputHelperText}
        sx={{
          bgcolor: LmiTheme.light.systemBackground.base,
        }}
        inputProps={{
          style: {
            cursor: props.kOnClick === undefined ? 'auto' : 'pointer',
            textAlign: props.kInputTextAlign,
          },
          maxLength: props.kInputMaxLength,
          min: 0,
          // error: props.kInputError,
          // onChange: props.kInputOnChange,
          // value: props.kInputValue,
          // helper_text: props.kInputHelperText,
        }}
        /* eslint-disable */
        InputProps={{
          disabled: props.kDisabled,
          startAdornment: props.kStartAdornment && props.kStartAdornment,
          endAdornment: props.kEndAdornment && props.kEndAdornment,
        }}
        FormHelperTextProps={{
          style: {
            backgroundColor: LmiTheme.light.systemBackground.elevation,
            margin: 0,
            paddingTop: '3px',
            paddingRight: '14px',
            paddingBottom: '0',
            paddingLeft: '14px',
          },
        }}
      />
    </Box>
  );
}

InputTextField.defaultProps = {
  kOnClick: undefined,
  kLabel: 'kLabels',
  kLabelCentered: false,
  kLabelColor: LmiTheme.light.systemBackground.secondary,
  kLabelRequiredHide: false,
  // kInputMaxLength: 45,
  kInputMaxLengthHide: false,
  // kInputId: 'kIdInput',
  kInputType: 'text',
  kInputPlaceholder: 'kInputPlaceholder',
  // kInputValue: 'kInputValue',
  kInputMultiline: false,
  // kInputRows: 1,
  kInputMaxRows: 1,
  // kInputOnChange: undefined,
  kInputRequired: false,
  // kInputError: undefined,
  // kInputName: 'kInputName',
  // kInputHelperText: 'This field is required',
};

export default InputTextField;

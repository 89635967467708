/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import {
  GoogleMap, useJsApiLoader,
  Marker,
  Circle,
} from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import MapMarker from '../../../assets/icons/map_marker_32.png';

import { MapModels } from './Map.models';
import { CircleDetailInfoModel } from '../../../page/BackOffice/Circles/AddCircle/AddCircle.models';

const _mapStyleLight = [
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#7c93a3',
      },
      {
        lightness: '-10',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#c2d1d6',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#dde3e3',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#c2d1d6',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#a9b4b8',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#a3c7df',
      },
    ],
  },
];

export default function MapComponent({
  state,
  funUpdateInfo,
  kDraggable,
  kOverMap,
  zoom,
  usrLat,
  usrLng,
  kChangeLocation,
  exactAddress = true,
  kShowSearchedCircles,
  kDisableBorder,
  kFullHeight,
  kOnMarkerClick,
}: MapModels) {
  const {
    lat, long,
  } = state ?? {
    height: null, lat: null, long: null,
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const containerStyle = {
    width: '100%',
    height: kFullHeight && isMobile ? '100%' : '89%',
    minHeight: isMobile ? '70vh' : '45vh',
    borderRadius: isMobile || kDisableBorder ? '0px' : '10px',
  };

  const { t } = useTranslation('global');

  const DEFAULT_LAT = 34.0536909;
  const DEFAULT_LNG = -118.242766;
  const userLat = usrLat ?? DEFAULT_LAT;
  const userLng = usrLng ?? DEFAULT_LNG;
  let usrLatM = lat ? parseFloat(lat) : userLat;
  let usrLngM = long ? parseFloat(long) : userLng;

  if (typeof (usrLatM) == 'string' && typeof (usrLngM) == 'string') {
    usrLatM = parseFloat(usrLatM);
    usrLngM = parseFloat(usrLngM);
  }

  const [, setMap] = React.useState(null);
  const [center, setCenter] = React.useState({ lat: usrLatM, lng: usrLngM });

  const [updateLoc, setUpdateLoc]: any = React.useState(true);
  const [libraries]: any = useState(['places']);
  const mapRef: any = useRef();

  useEffect(() => {
    const timer = setTimeout(() => {
      setCenter({ lat: usrLatM, lng: usrLngM });
      if (mapRef.current != undefined) {
        mapRef.current.setZoom(16);
      }
    }, 2500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (usrLatM != 0 && usrLngM != 0) {
      setCenter({
        lat: usrLatM,
        lng: usrLngM,
      });
    }
    if (mapRef.current != undefined) {
      mapRef.current.setZoom(16);
    }
  }, [usrLatM, usrLngM]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
  });

  const onLoad = React.useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds();
    map.setZoom(zoom);
    map.fitBounds(bounds);

    mapRef.current = map;
    mapRef.current.fitBounds(bounds);
  }, []);

  const onUnmount = React.useCallback(() => {
    setMap(null);
    mapRef.current = null;
  }, []);

  useEffect(() => {
    geocodeLatLng();
  }, [kChangeLocation]);

  useEffect(() => {
    geocodeLatLng();
  }, [updateLoc]);

  async function getCountryOnDragMap(latD: any, lngD: any) {
    try {
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latD},${lngD}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}`);
      const data = await response.json();
      let countryName = '';
      let shortname = '';

      data.results[0].address_components.forEach((element: any) => {
        const longName = element.long_name;
        const shortn = element.short_name;
        const { types } = element;

        if (types.includes('country')) {
          countryName = longName;
          shortname = shortn;
        }
      });

      if (['Mexico', 'Canada', 'United States'].includes(countryName) || ['US', 'MX'].includes(shortname)) {
        if (funUpdateInfo) {
          funUpdateInfo({
            ...state,
            lat: latD,
            long: lngD,
          });
        }
        setUpdateLoc(!updateLoc);
      } else {
        toast.error(t('global.country_not_available'));
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function geocodeLatLng() {
    if (usrLatM === 0 || usrLngM === 0) {
      return;
    }

    try {
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${usrLatM},${usrLngM}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}`);
      const data = await response.json();

      if (!funUpdateInfo) {
        return;
      }

      const addressComponents = data.results[0]?.address_components.reduce((acc: any, curr: any) => {
        const { short_name, long_name, types } = curr;

        if (types.includes('colloquial_area')) {
          acc.streetAddress = long_name;
        }
        if (types.includes('street_number')) {
          acc.streetAddress = `${acc.streetAddress} ${long_name}`.trim();
        }
        if (types.includes('route')) {
          acc.streetAddress = `${acc.streetAddress} ${long_name}`.trim();
        } else if (types.includes('sublocality')) {
          acc.extendedAddress = long_name;
        } else if (types.includes('locality')) {
          acc.locality = long_name;
        } else if (types.includes('administrative_area_level_1')) {
          acc.region = short_name;
        } else if (types.includes('postal_code')) {
          acc.postalCode = long_name;
        } else if (types.includes('country')) {
          acc.countryName = long_name;
          acc.countryCode = short_name;
        }
        return acc;
      }, {
        streetAddress: '',
        extendedAddress: '',
        locality: '',
        region: '',
        postalCode: '',
        countryName: '',
      });

      mapRef.current?.setZoom(16);
      funUpdateInfo({
        ...state,
        address: data.results[0].formatted_address,
        long: usrLngM ? usrLngM.toString() : state?.long,
        lat: usrLatM ? usrLatM.toString() : state?.lat,
        ...addressComponents,
      });

      setCenter({
        lat: usrLatM,
        lng: usrLngM,
      });
    } catch (error) {
      console.error(error);
    }
  }

  const onMarkerDragEnd = (e: any) => {
    if (e.latLng.lat() != 0 && e.latLng.lng() != 0) {
      getCountryOnDragMap(e.latLng.lat()?.toString(), e.latLng.lng()?.toString());
    }
  };

  const markerClick = (circleSelected: CircleDetailInfoModel, index: number) => {
    kOnMarkerClick(circleSelected, index);
  };

  const options = {
    strokeColor: '0x00000060',
    strokeOpacity: 0.5,
    strokeWeight: 2,
    fillColor: '0x00000050',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 100,
    zIndex: 1,
  };

  return isLoaded ? (
    kShowSearchedCircles ? (
      <Box sx={{
        'img[src$="#circular_marker_image"]': { borderRadius: '50%' },
        height: 'inherit',
        width: 'inherit',
      }}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          ref={mapRef}
          center={center}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={{ disableDefaultUI: true, styles: _mapStyleLight }}
          zoom={15}
        >
          <>
            {
              kShowSearchedCircles.map((marker: any, index: number) => (
                <Marker
                  clickable
                  key={marker.deepLink} // Need to be unique
                  onClick={() => markerClick(marker, index)}
                  position={{
                    lat: parseFloat(marker.lat),
                    lng: parseFloat(marker.lon),
                  }}
                  icon={{
                    url: `${marker.circlePicture}#circular_marker_image`,
                    scaledSize: new google.maps.Size(50, 50),
                  }}
                />
              ))
            }
          </>
        </GoogleMap>
      </Box>
    ) : (
      <GoogleMap
        mapContainerStyle={containerStyle}
        ref={mapRef}
        center={center}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{ disableDefaultUI: true, styles: _mapStyleLight }}
        zoom={15}
      >
        { /* Child components, such as markers, info windows, etc. */}
        <>
          {
            exactAddress ? (
              <Marker
                key={1}
                draggable={kDraggable}
                onDragEnd={(e) => onMarkerDragEnd(e)}
                icon={MapMarker}
                position={{
                  lat: usrLatM,
                  lng: usrLngM,
                }}
              />
            ) : (
              <Circle
                radius={0.05}
                draggable={kDraggable}
                onDragEnd={(e) => onMarkerDragEnd(e)}
                center={center}
                options={options}
              />
            )
          }
          <div style={{ opacity: 0.99, padding: 12 }}>
            {kOverMap}
          </div>
        </>
      </GoogleMap>
    )
  ) : <></>;
}

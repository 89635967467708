import { LmiTheme } from '../../../../../../components/Common';

const ActivityInstructionsStyle = {
  width: '100%',
  height: '100%',
  '.lmiw-activity-instructions': {
    borderRadius: '10px',
    p: 2,
    ul: {
      padding: 0,
    },
  },
  '.lmiw-activity-instructions-item': {
    padding: 0,
    color: LmiTheme.light.systemBackground.secondary,
    alignItems: 'flex-start',
    '.MuiListItemIcon-root': {
      mt: 1,
      minWidth: 6,
      pr: '8px',
      svg: {
        width: 6,
        height: 6,
        color: LmiTheme.light.accent,
      },
    },
    '.MuiListItemText-root': {
      margin: 0,
      span: {
        fontSize: '14px',
      },
    },
  },

};

export default ActivityInstructionsStyle;

import LmiTheme from '../../../../../themes/lmi-mui-theme/lmi-theme';

const CategoriesSwiperStyle = {
  backgroundColor: 'white',
  overflow: 'hidden',
  '.lmi-avatar-category': {
    maxWidth: 115,
    maxHeight: 115,
    width: '100%',
    height: '100%',
    bgcolor: '#f7f7f7',
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 5px 5px 0 rgba(104, 129, 146, 0.25) !important',
    },
  },

  '.lmi-stack-btn': {
    '.prev, .next': {
      display: { xs: 'none', sm: 'flex' },
      width: 56,
      height: 56,
    },
    button: {
      fontWeight: 400,
      boxShadow: 'none',
      backgroundColor: 'white',
      textTransform: 'capitalize',
      color: LmiTheme.light.accent,
      border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
      '&.MuiButton-outlined': {
        borderRadius: 2,
        px: { xs: 2, sm: 4 },
        height: { xs: 44, sm: 56 },
      },
    },
  },
  '& .swiper-container': {
    maxWidth: 'lg',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflow: 'visible',
  },
  '& .swiper-slide': {
    padding: '0px',
  },
  // '& .swiper-slide:first-child': {
  //   marginLeft: { xs: '0', sm: '24px' },
  // },
  // '& .swiper-slide:last-child': {
  //   marginRight: '24px !important',
  // },
};

export default CategoriesSwiperStyle;

import {
  Box, Typography, Paper, Stack, Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SectionTitle } from '../../../../../../components/Common';
import ActivityScheduledStyle from './ActivityScheduled.style';

export default function ActivityScheduled({ notifDetail }: any) {
  let fromDate;
  let toDate;
  let boughtOn;

  const notifActionTypes = [
    'new_rental', 'approved', 'approve', 'rejected',
    'pickup', 'dropoff', 'review', 'payment_processed',
    'cancelled', 'verified_failed', 'verified',
    'verifypending', 'verified_ok', 'delivery_confirmed',
    'driver_assigned', 'at_pickup', 'at_delivery',
    'en_route_to_pickup', 'en_route_to_delivery',
    'pickup_confirmed', 'delivery_details_added', 'reminder',
    'extend_reservation_pending', 'extend_reservation_approved',
    'extend_reservation_denied', 'extend_reservation_canceled',
  ];

  if (notifDetail) {
    if (notifActionTypes.includes(notifDetail.notifActionType)) {
      if (notifDetail.isRent) {
        fromDate = notifDetail.fromDate;
        toDate = notifDetail.toDate;
      } else {
        boughtOn = notifDetail.notifDate;
      }
    } else if (notifDetail.notifActionType === 'new_buy' || notifDetail.notifActionType === 'approve_buy') {
      boughtOn = notifDetail.notifDate;
    }
  }

  const splitFromDay = fromDate?.split(' ')[0] ?? '';
  const splitFromMonth = fromDate?.split(' ')[1] ?? '';
  const splitFromYear = fromDate?.split(' ')[2] ?? '';

  const splitToDay = toDate?.split(' ')[0] ?? '';
  const splitToMonth = toDate?.split(' ')[1] ?? '';
  const splitToYear = toDate?.split(' ')[2] ?? '';

  const { t } = useTranslation('global');

  return (
    <Box sx={ActivityScheduledStyle}>
      {!notifDetail?.isRent
        ? (
          <SectionTitle
            kMarginTop={2}
            kTitle={t('activity.scheduled.bought_on')}
            kFontSize={18}
          />
        )
        : (
          <SectionTitle
            kMarginTop={2}
            kTitle={t('activity.scheduled.scheduled_for')}
            kFontSize={18}
          />
        )}
      {
        notifDetail?.isRent && !notifDetail?.isHourly
          ? (
            <Paper className="lmiw-activity-scheduled" variant="outlined" elevation={0}>
              <Stack
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                width="100%"
              >
                <Box textAlign="center">
                  <Typography className="lmiw-scheduled-label">{t('activity.scheduled.start_date')}</Typography>
                  <Typography className="lmiw-scheduled-date">
                    {splitFromDay}
                    {' '}
                    {splitFromMonth ? t(`months.${splitFromMonth}`) : ''}
                    {' '}
                    {splitFromYear}
                  </Typography>
                </Box>
                <Typography className="lmiw-scheduled-label">{t('activity.scheduled.to')}</Typography>
                <Box textAlign="center">
                  <Typography className="lmiw-scheduled-label">{t('activity.scheduled.end_date')}</Typography>
                  <Typography className="lmiw-scheduled-date">
                    {splitToDay}
                    {' '}
                    {splitToMonth ? t(`months.${splitToMonth}`) : ''}
                    {' '}
                    {splitToYear}
                  </Typography>
                </Box>
              </Stack>
            </Paper>
          )
          : (
            <Paper sx={{ pt: 20 }} className="lmiw-activity-scheduled" variant="outlined" elevation={0}>
              <Stack
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                width="100%"
              // spacing={6}
              >
                <Box textAlign="center">
                  <Typography className="lmiw-scheduled-label">{notifDetail.isHourly ? t('activity.scheduled.date') : t('activity.scheduled.bought_on')}</Typography>
                  <Typography className="lmiw-scheduled-date">{notifDetail.isHourly ? fromDate : boughtOn}</Typography>
                </Box>
              </Stack>
            </Paper>
          )
      }
      {
        notifDetail?.isRent && notifDetail?.isHourly
        && (
          <Paper sx={{ mt: 2 }} className="lmiw-activity-scheduled" variant="outlined" elevation={0}>
            <Stack
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              width="100%"
            >
              <Box textAlign="center">
                <Typography className="lmiw-scheduled-label">{t('activity.scheduled.from').toUpperCase()}</Typography>
                <Typography className="lmiw-scheduled-date">
                  {notifDetail?.fromHour}
                </Typography>
              </Box>
              {/* <hr /> */}
              <Divider sx={{ width: '20px', margin: 0 }} />
              {/* <Divider /> */}
              {/* <Typography className="lmiw-scheduled-label">{t('activity.scheduled.to').toUpperCase()}</Typography> */}
              <Box textAlign="center">
                <Typography className="lmiw-scheduled-label">{t('activity.scheduled.to').toUpperCase()}</Typography>
                <Typography className="lmiw-scheduled-date">
                  {notifDetail?.toHour}
                </Typography>
              </Box>
            </Stack>
          </Paper>
        )
      }
    </Box>
  );
}

/* eslint-disable no-undef */
import { useContext, useEffect, useState } from 'react';
import AWS from 'aws-sdk/global';
import { useHistory } from 'react-router-dom';
import { Auth, Hub } from 'aws-amplify';
import authContext from '../context/authentication/authContext';
import { setTimerAWS } from './common';

const LoginListener = () => {
  const history = useHistory();
  const authContextLog = useContext(authContext);
  const [diff, setDiff] = useState({});
  const [random, setChange] = useState(0);
  const { credentials }: any = AWS.config;
  const { timeExp }: any = history.location.state ?? { timeExp: undefined };
  const futureDate = timeExp ? new Date(timeExp) : timeExp;
  const expireTime = credentials?.expireTime!;

  const {
    amplifyAuthorize,
    signOff,
    token,
  }: any = authContextLog;

  function getUser() {
    return Auth.currentAuthenticatedUser();
  }

  useEffect(() => {
    const divEl: any = document.getElementById('root');

    divEl.addEventListener('mouseenter', () => setChange(Math.floor(Math.random() * 1500)));
    // divEl.addEventListener('mouseover', () => console.log('Event: mouseover'));

    Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
        case 'tokenRefresh':
          break;
        case 'cognitoHostedUI':
          getUser().then((userData: any) => {
            amplifyAuthorize(userData);
          });

          break;
        case 'signOut':
          break;
        case 'cognitoHostedUI_failure':
          console.error('Sign in failure');

          break;

        default:
          break;
      }
    });
  }, []);

  const getDateDiff = (date1:any, date2: any) => {
    const diff2 = new Date(date2.getTime() - date1.getTime());
    if ((diff2.getUTCFullYear() - 1970) == -1) {
      if (token) { signOff(); } else { credentials.refresh(); }
      return {};
    }

    return {
      year: diff2.getUTCFullYear() - 1970,
      month: diff2.getUTCMonth(),
      day: diff2.getUTCDate() - 1,
      hour: diff2.getUTCHours(),
      minute: diff2.getUTCMinutes(),
      second: diff2.getUTCSeconds(),
    };
  };

  const refreshTokenJWT = () => {
    credentials.refresh();
    getUser().then((userData: any) => {
      const { refreshToken } = userData.signInUserSession;
      userData.refreshSession(refreshToken, () => {
        setTimerAWS(userData, history);
      });
    }).catch(() => {
      if (token) { signOff(); } else { credentials.refresh(); }
    });
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (futureDate && expireTime) {
      timer = setInterval(() => {
        setDiff(getDateDiff(new Date(), futureDate));
      }, 1000);
    } else if (token) {
      Auth.currentAuthenticatedUser()
        .then((userData: any) => {
          setTimerAWS(userData, history);
        })
        .catch(() => {
          if (token) { signOff(); } else { credentials?.refresh(); }
        });
    }
    return () => {
      clearInterval(timer);
    };
  }, [futureDate, random]);

  useEffect(() => {
    if (Object.keys(diff).length > 0) {
      const { minute }: any = diff;
      if (minute <= 2) {
        refreshTokenJWT();
      } else if (minute <= 0) {
        if (token) { signOff(); } else { credentials.refresh(); }
      }
    }
  }, [diff]);

  return null;
};

export default LoginListener;

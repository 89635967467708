import { LmiTheme } from '../../Common';

const ListingDetailStyle = {
  // px: { lg: 0, md: 2, xs: 0 },
  // py: { md: 2, xs: 0 },
  height: 'auto',
  '.lmiw-listing': {
    // width: { xs: '100%', md: '40%' },
    width: { lg: '390px', md: '40%', xs: '100%' },
    // width: '50%',
    backgroundColor: 'white',
  },
  '.lmiw-detail': {
    width: { lg: 'calc(100% - 390px)', md: '60%', xs: '100%' },
    // display: { xs: 'none', md: 'block' },
    borderLeft: { md: `1px solid ${LmiTheme.light.systemBackground.separator}`, xs: 'none' },
    backgroundColor: 'white',
  },
  '.lmiw-detail-header, .lmiw-listing-header': {
    minHeight: '56px',
    borderBottom: `1px solid ${LmiTheme.light.systemBackground.separator}`,
    px: 2,
  },
  '.lmiw-listing-body, .lmiw-detail-body': {
    overflow: 'auto',
    height: { md: 'calc(100vh - 150px)', xs: '100%' },
  },

};

export default ListingDetailStyle;

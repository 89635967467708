import {
  DialogActions,
  Button,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DialogActionsListsModels } from './DialogActionsLists.models';

function DialogActionsLists(props: DialogActionsListsModels) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation('global');
  return (
    <>
      <DialogActions sx={{ px: isMobile ? 2 : 4, py: isMobile ? 2 : 3 }}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={3}
          pl={1}
        >
          <Button disableElevation size="large" variant="outlined" onClick={props.kCancelDialogAction}>{t('global.cancel')}</Button>
          <Button disableElevation size="large" variant="contained" onClick={props.kSaveDialogAction}>{t('global.save')}</Button>
        </Stack>
      </DialogActions>
    </>
  );
}

export default DialogActionsLists;

import { useContext, useEffect, useRef } from 'react';
import { Edit } from '@mui/icons-material';
import {
  Button,
  CardContent,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MainInformationTabPanel } from '..';
import { TabPanelPayoutAccountModels } from './TabPanelPayoutAccount.models';
import payoutAccountContext from '../../../../../context/payoutAccount/payoutAccountContext';
import IndividualPayout from './Forms/IndividualPayout';
import NewIndOrBusPayout from './Forms/NewIndOrBusPayout';
import BusinessPayout from './Forms/BusinessPayout';

function TabPanelPayoutAccount(props: TabPanelPayoutAccountModels) {
  const pPyaoutContext = useContext(payoutAccountContext);
  const {
    payoutAccountDetails,
    getUserPayoutAccount,
    checkNewPayoutValidations,
    changedPayoutFields,
    arePayoutFieldsValidated,
    createPayoutAccount,
    updatePayoutAccount,
  }: any = pPyaoutContext;

  useEffect(() => {
    (async function fetchPayoutAccount() {
      await getUserPayoutAccount();
    }());
  }, []);

  useEffect(() => {
    if (arePayoutFieldsValidated) {
      if (payoutAccountDetails?.isDebitCar && payoutAccountDetails?.expiration?.length >= 4) {
        const splitExpire = payoutAccountDetails.expiration.split('/');
        const month = splitExpire[0];
        const year = splitExpire[1];
        const arrToSend = {
          ...payoutAccountDetails,
          expirationMonth: month,
          expirationYear: year,
        };
        if (payoutAccountDetails.accId != '') {
          updatePayoutAccount(arrToSend);
        } else {
          createPayoutAccount(arrToSend, props.clickCloseDialogAddPayoutMethod);
        }
      } else {
        const arrToSend = {
          ...payoutAccountDetails,
          expirationMonth: '',
          expirationYear: '',
        };
        if (payoutAccountDetails.accId != '') {
          updatePayoutAccount(arrToSend);
        } else {
          createPayoutAccount(arrToSend, props.clickCloseDialogAddPayoutMethod);
        }
      }
    }
  }, [changedPayoutFields]);

  const clickSavePayout = () => {
    checkNewPayoutValidations(true);
  };

  const { t } = useTranslation('global');

  const modalScrollContainerRef = useRef<HTMLDivElement>(null);

  const scrollToTop = () => {
    if (modalScrollContainerRef && modalScrollContainerRef.current) {
      modalScrollContainerRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  };

  return (
    <>
      <MainInformationTabPanel
        kTabValue={props.kTabValue}
        kTabIndex={props.kTabIndex}
        kContent={
          (
            <>
              <CardContent>
                {payoutAccountDetails.accId == '' && <NewIndOrBusPayout />}
                {(payoutAccountDetails.accId
                  != ''
                  && payoutAccountDetails.isIndividual)
                  && (
                    <div ref={modalScrollContainerRef} style={{ overflowY: 'auto' }}>
                      <IndividualPayout onScrollToTop={scrollToTop} />
                    </div>
                  )}
                {(payoutAccountDetails.accId
                  != ''
                  && !payoutAccountDetails.isIndividual)
                  && <BusinessPayout />}
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  mt={2}
                >
                  <Stack mt={0}>
                    <Button
                      disableElevation
                      size="large"
                      variant="outlined"
                      onClick={clickSavePayout}
                      sx={{
                        mx: 'auto',
                        mt: 3,
                        textTransform: 'none',
                        borderRadius: '10px',
                      }}
                      endIcon={<Edit />}
                    >
                      {payoutAccountDetails?.accId != '' ? `${t('payout_info.update')}` : `${t('payout_info.submit')}`}
                    </Button>
                  </Stack>
                </Stack>
              </CardContent>
            </>
          )
        }
      />
    </>
  );
}

export default TabPanelPayoutAccount;

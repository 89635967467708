import {
  Box, Chip, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LmiTheme } from '../../../../../../components/Common';
import MyCirclesListingContext from '../../../../../../context/myCirclesListing/myCirclesListingContext';

export default function CircleTagsDetail() {
  const {
    circle,
  }: any = useContext(MyCirclesListingContext);

  const splitTags = circle?.tags ? circle?.tags.split(',') : [];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation('global');

  return (
    splitTags.length > 0
      ? (
        <Box sx={{
          padding: isMobile ? '16px 16px' : '30px 100px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: '20px',
        }}
        >
          {
              splitTags.map((d: any, i: number) => (
                i <= 10 && (
                <Chip
                  key={d}
                  label={d}
                  sx={{
                    background: LmiTheme.light.systemBackground.solid,
                  }}
                />
                )))
            }
        </Box>
      )
      : (
        <Box>
          <Typography
            fontSize="16px"
            color={LmiTheme.light.systemBackground.tertiary}
            sx={{
              padding: '16px 30px',
            }}
          >
            {t('circles.detail.no_tags')}
          </Typography>
        </Box>
      )
  );
}

import { useContext, useEffect, useReducer } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { ClsModGetItemsMainAppSearchCountry } from '@lendmeit/api_backend_lmi/dist/models';
import ExploreContext from './exploreContext';
import ExploreReducer from './exploreReducer';
import {
  ADD_RANGE,
  APPLY_CATEGORIES, CHANGE_PAGE, DELETE, DELETE_ALL, INITIAL, LOADING, LOADINGITEMS, LOCATION, MORE, SEARCH, SEARCH_SIMILAR,
} from '../../types';
import ApiLMI from '../../services/apilmi';
import homeContext from '../home/homeContext';

const ExploreState = ({ children }: any) => {
  const {
    categoriesSelected,
    removeCategorySelected,
    lat,
    long,
    countryName,
    removeAllCategoriesSelected,
    searchCurrency,
  }: any = useContext(homeContext);

  const initialState = {
    items: null,
    loading: true,
    categoriesSelected,
    categoriesApplied: localStorage.getItem('filtersApplied') ? JSON.parse(localStorage.getItem('filtersApplied')!) : null,
    searched: '',
    interaction: false,
    loadingItems: false,
    loadingMore: false,
    page: 1,
    lat,
    long,
  };

  const [state, dispatch] = useReducer(ExploreReducer, initialState);

  const searchFn = async (
    generalSearch: string,
    page: number = 0,
    category: number = 0,
  ) => {
    _loading();
    if (generalSearch) {
      _loadingItems();
    }
    let exploreItems;
    const _api = ApiLMI();

    try {
      let categoriesToApply = '';
      if (category > 0) {
        categoriesToApply = `${category}`;
      } else {
        categoriesToApply = categoriesSelected
          ? categoriesSelected.map((elem : any) => elem.id).join(',')
          : '';
      }
      page = page != 0 ? page : state.page;

      // const body: ClsModMainItemSearchNewFlowCategories = {
      //   generalSearch: generalSearch ?? '',
      //   page,
      //   pageSize: 32,
      //   categories: categoriesToApply,
      //   lat: `${lat}`,
      //   lng: `${long}`,
      //   milesRadius: 80467.2,
      // };
      const body: ClsModGetItemsMainAppSearchCountry = {
        generalSearch: generalSearch ?? '',
        page,
        pageSize: 32,
        categories: categoriesToApply,
        lat: `${lat}`,
        lng: `${long}`,
        milesRadius: 80467.2,
        forRent: true,
        forSale: true,
        priceRangeStart: 0,
        priceRangeEnd: 100000,
        country: countryName, // countrySelected,
        toc: 'qwer',
      };
      // const { data } = await _api
      //   .itemMainItemSearchNewFlowCategories(body);

      const { data } = await _api.apiItemGetItemsMainAppSearchCountryPost(body);

      if (typeof data === 'string') {
        const obj = JSON.parse(data.toString());
        // exploreItems = obj.Response;
        // exploreItems = obj.Response;
        // filter by currency
        if (searchCurrency == '') {
          exploreItems = obj.Response;
        } else {
          exploreItems = obj.Response.filter((item: any) => item.currency.toLowerCase() == searchCurrency.toLowerCase());
        }
      } else {
        toast.error(t('global.explore_error'));
      }
    } catch (err: any) {
      toast.error(err);
      exploreItems = [];
    }
    dispatch({
      type: category == 0 ? SEARCH : SEARCH_SIMILAR,
      payload: {
        items: exploreItems,
        searched: generalSearch ?? '',
        page,
      },
    });
  };

  const _loading = (load: boolean = true) => {
    dispatch({
      type: LOADING,
      payload: load,
    });
  };

  const _loadingItems = (load: boolean = true) => {
    dispatch({
      type: LOADINGITEMS,
      payload: load,
    });
  };

  const applyCategories = async () => {
    try {
      dispatch({
        type: APPLY_CATEGORIES,
        payload: categoriesSelected,
      });
    // eslint-disable-next-line no-empty
    } catch (_) {

    }
  };

  const addCategoriesApplied = async (categories: []) => {
    try {
      dispatch({
        type: ADD_RANGE,
        payload: categories,
      });
    // eslint-disable-next-line no-empty
    } catch (_) {

    }
  };

  const changePage = async (page: number = 1) => {
    try {
      dispatch({
        type: CHANGE_PAGE,
        payload: page,
      });
    // eslint-disable-next-line no-empty
    } catch (_) {

    }
  };

  const removeCategoryApplied = async (category: any) => {
    try {
      removeCategorySelected(category);
      dispatch({
        type: DELETE,
        payload: category,
      });
    // eslint-disable-next-line no-empty
    } catch (_) {

    }
  };

  useEffect(() => {
    if (state.interaction && window.location.pathname !== '/') {
      searchFn(state.searched);
    }
  }, [state.interaction]);

  useEffect(() => {
    if (lat != state.lat || long != state.long) {
      dispatch({
        type: LOCATION,
        payload: {
          lat,
          long,
        },
      });
    }
  }, [lat, long]);

  const removeAllCategoriesApplied = async () => {
    try {
      dispatch({
        type: DELETE_ALL,
        payload: null,
      });
    // eslint-disable-next-line no-empty
    } catch (_) {

    }
  };

  const more = async () => {
    try {
      dispatch({
        type: MORE,
        payload: null,
      });
    // eslint-disable-next-line no-empty
    } catch (_) {

    }
  };

  const clearData = async () => {
    try {
      removeAllCategoriesSelected();
      dispatch({
        type: INITIAL,
        payload: initialState,
      });
    // eslint-disable-next-line no-empty
    } catch (_) {
    }
  };

  const { t } = useTranslation('global');

  return (
    <ExploreContext.Provider
      value={
     {
       ...state,
       searched: state.searched,
       items: state.items,
       loading: state.loading,
       categoriesApplied: state.categoriesApplied,
       loadingItems: state.loadingItems,
       loadingMore: state.loadingMore,
       page: state.page,
       categoriesSelected: state.categoriesSelected,
       changedSimilarItems: state.changedSimilarItems,
       searchFn,
       applyCategories,
       removeCategoryApplied,
       addCategoriesApplied,
       removeAllCategoriesApplied,
       more,
       clearData,
       changePage,
     }
      }
    >
      {children}
    </ExploreContext.Provider>
  );
};

export default ExploreState;

/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable react/jsx-props-no-spreading */
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import 'swiper/swiper-bundle.min.css';
import {
  Stack, Box, Fab, Avatar, AvatarGroup, Button, Typography,
} from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { isMobile } from 'react-device-detect';
import { ContentLayout, SectionTitle } from '../../../../../components/Common';

import CircleSwiperStyle from './CircleSwiper.style';
import LmiTheme from '../../../../../themes/lmi-mui-theme/lmi-theme';

SwiperCore.use([Pagination, Navigation]);
export default function CircleSwiper() {
  const { t } = useTranslation('global');
  const images: any = [
    {
      id: 1,
      image: 'circles1',
      name: `${t('circles_page.swiper.mike_circle')}`,
      description: `${t('circles_page.swiper.mike_circle_txt')}`,
      members: 43,
      users: [
        { image: 'user1' },
        { image: 'user2' },
        { image: 'user3' },
        { image: 'user4' },
        { image: 'user5' },
        { image: 'user6' },
      ],
    },
    {
      id: 2,
      image: 'circles2',
      name: `${t('circles_page.swiper.tools_everyone')}`,
      description: `${t('circles_page.swiper.tools_everyone_txt')}`,
      members: 63,
      users: [
        { image: 'user7' },
        { image: 'user8' },
        { image: 'user9' },
        { image: 'user10' },
        { image: 'user11' },
        { image: 'user12' },
      ],
    },
    {
      id: 3,
      image: 'circles3',
      name: `${t('circles_page.swiper.outdoor_lovers')}`,
      description: `${t('circles_page.swiper.outdoor_lovers_txt')}`,
      members: 47,
      users: [
        { image: 'user13' },
        { image: 'user14' },
        { image: 'user15' },
        { image: 'user16' },
        { image: 'user17' },
        { image: 'user18' },
      ],
    },
    {
      id: 4,
      image: 'circles4',
      name: `${t('circles_page.swiper.music_club')}`,
      description: `${t('circles_page.swiper.music_club_txt')}`,
      members: 43,
      users: [
        { image: 'user19' },
        { image: 'user20' },
        { image: 'user21' },
        { image: 'user22' },
        { image: 'user23' },
        { image: 'user24' },
      ],
    },

  ];

  const MainParams = {
    modules: [Pagination, Navigation],
    // slidesPerView: 10,
    // slidesPerGroup: 1,
    // speed: 1000,
    // centeredSlides: false,
    // lazy: true,
    // freeMode: true,
    slidesPerGroup: 1,
    initialSlide: 1,
    navigation: {
      prevEl: '.prev',
      nextEl: '.next',
    },
    breakpoints: {

      1900: {
        slidesPerView: 2,
        spaceBetween: 24,
      },

      1200: {
        slidesPerView: 1.9,
        spaceBetween: 24,
        initialSlide: 0,
      },

      992: {
        slidesPerView: 1.7,
        spaceBetween: 24,
        speed: 600,
        initialSlide: 0,
      },

      768: {
        slidesPerView: 1.6,
        spaceBetween: 24,
        speed: 500,
        initialSlide: 0,
      },

      500: {
        slidesPerView: 1.5,
        spaceBetween: 24,
        speed: 500,
        initialSlide: 0,
      },

      300: {
        slidesPerView: 1.08,
        spaceBetween: 16,
        initialSlide: 0,
        speed: 500,
      },
    },
  };
  return (
    <>
      <Box pt={5} pb={5} component="section" sx={{ ...CircleSwiperStyle, bgcolor: '#fff' }}>
        <ContentLayout>
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              className="lmi-stack-btn"
              mb={5}
            >
              <SectionTitle kMarginTop={0} kMarginBottom={0} kComponent="h2" kVariant="h5" kTitle={t('circles_page.diff_themes')} />
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Fab sx={{ boxShadow: 'none', display: { xs: 'none', sm: 'flex' } }} size="medium" aria-label="left" className="prev">
                  <ArrowLeftIcon />
                </Fab>
                <Fab sx={{ boxShadow: 'none', display: { xs: 'none', sm: 'flex' } }} size="medium" aria-label="right" className="next">
                  <ArrowRightIcon />
                </Fab>
                {/* <Button color="primary" size="large" variant="outlined">See all</Button> */}

              </Stack>
            </Stack>
          </>
        </ContentLayout>

        <Swiper {...MainParams}>
          {images.map((data: any) => (
            <SwiperSlide key={data.id}>
              <Stack
                direction="column"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
                sx={{
                  height: {
                    xl: '730px', lg: '650px', sm: '500px', xs: '300px',
                  },
                  display: 'flex',
                  backgroundSize: 'cover',
                  borderRadius: '10px',
                  position: 'relative',
                  overflow: 'hidden',
                  backgroundImage: `url(${require(`../../../../../assets/circle_swiper/${data.image}.jpg`).default})`,
                }}
              >
                <Box sx={{ zIndex: 2 }} p={{ xs: 4, sm: 8 }}>
                  <Typography
                    color="white"
                    mt={0}
                    mb={{ xs: 1, sm: 2, md: 3 }}
                    component="h2"
                    fontSize={{ xs: 16, sm: 26, md: 32 }}
                    fontWeight="bold"
                    lineHeight="1.1"
                  >
                    {data.name}
                  </Typography>
                  <Typography
                    color="white"
                    mt={0}
                    mb={{ xs: 1, sm: 2, md: 3 }}
                    component="p"
                    fontSize={{ xs: 12, sm: 18, md: 22 }}
                    fontWeight="400"
                    lineHeight="1.1"
                  >
                    {data.description}
                  </Typography>
                  <Typography
                    color="white"
                    mt={0}
                    mb={{ xs: 1, sm: 2, md: 3 }}
                    component="p"
                    fontSize={{ xs: 12, sm: 18, md: 22 }}
                    fontWeight="400"
                    lineHeight="1.1"
                  >
                    {data.members}
                    {' '}
                    {t('circles_page.swiper.members')}
                  </Typography>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <AvatarGroup
                      total={data.members}
                      max={7}
                      sx={{
                        '.MuiAvatarGroup-avatar': {
                          backgroundColor: LmiTheme.light.accent,
                          fontSize: { xs: 10, sm: 12, md: 14 },
                          width: { xs: 18, sm: 32, md: 42 },
                          height: { xs: 18, sm: 32, md: 42 },
                        },
                      }}
                    >
                      {data.users.map((item: any) => (
                        <Avatar
                          key={`${data.id}-${item.image}`}
                          alt={item.image}
                          sx={{ width: { xs: 18, sm: 32, md: 42 }, height: { xs: 18, sm: 32, md: 42 } }}
                          src={require(`../../../../../assets/circle_swiper/${item.image}.jpg`).default}
                        />
                      ))}
                    </AvatarGroup>
                    {isMobile ? (
                      <Button
                        href="https://lendmeit.app/download-app-web"
                        variant="outlined"
                        sx={{
                          borderColor: 'white',
                          color: 'white',
                          borderRadius: 50,
                          textTransform: 'capitalize',
                          height: { xs: '44px', md: '56px' },
                          fontSize: { xs: 10, sm: 14, md: 16 },
                          ':hover': {
                            backgroundColor: LmiTheme.light.accent,
                            color: 'white',
                          },
                        }}
                      >
                        {t('circles_page.swiper.start_now')}
                      </Button>
                    ) : <></>}

                  </Stack>
                </Box>
                <Box
                  sx={{
                    zIndex: 1,
                    bottom: '0',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);',
                  }}
                />
              </Stack>
            </SwiperSlide>

          ))}
        </Swiper>
      </Box>

    </>
  );
}

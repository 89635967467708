import {
  // Stack,
  Typography, Chip, Table, TableBody, TableCell, TableContainer, TableRow, Stack,
  // Divider,
  // Chip, Avatar,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { ItemDetailAccesoriesModels, ItemDetailAccessoryModels } from './ItemDetaiModels';
import { LmiTheme, SectionTitle } from '../../../../../components/Common';
import LmiColorPalette from '../../../../../themes/lmi-mui-theme/lmi-color-palette';

export default function ItemDetailAccesories(props: ItemDetailAccesoriesModels) {
  const { t } = useTranslation('global');
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <SectionTitle
          kTitle={props.kTitle}
          kFontSize={props.kFontSize}
          kMarginTop={props.kMarginTop}
          kMarginBottom={props.kMarginBottom}
        />
        <Typography
          noWrap
          mt={props.kMarginTop}
          mb={props.kMarginBottom}
          color={LmiTheme.light.systemBackground.tertiary}
          component="span"
          fontWeight="500"
          fontSize={14}
        >
          {props.kAccesories.length}
          {' '}
          /15
        </Typography>
      </Stack>
      <TableContainer sx={{
        borderRadius: 2,
        border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
      }}
      >
        <Table>
          <TableBody>
            {props.kAccesories.length > 0
              ? props.kAccesories.map((row: ItemDetailAccessoryModels) => (
                <TableRow
                  key={row.idRelItemAccesorie}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Typography fontWeight="bold" fontSize={14}>{row.name}</Typography>
                  </TableCell>
                  <TableCell align="left" sx={{ color: LmiTheme.light.systemBackground.secondary, display: { xs: 'none', sm: 'table-cell' } }}>{row.dscription}</TableCell>
                  <TableCell align="right">
                    <Chip
                      label={row.actve ? `${t('item_detail.active')}` : `${t('item_detail.inactive')}`}
                      variant="filled"
                      sx={{
                        fontWeight: 'bold',
                        height: '25px',
                        borderRadius: '7px',
                        color: row.actve ? LmiColorPalette.greenScheme[900] : LmiTheme.light.systemBackground.primary,
                        background: row.actve ? LmiColorPalette.greenScheme[50] : LmiTheme.light.systemBackground.solid,
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))
              : (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left">
                    {t('item_detail.no_accessories')}
                  </TableCell>
                </TableRow>
              )}

          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

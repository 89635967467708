import {
  Button, Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import CardLend from '../../Listing/Components/CardLend/CardLend';
import MyCirclesListingContext from '../../../../context/myCirclesListing/myCirclesListingContext';
import { emptyCircleModel } from '../AddCircle/AddCircle.models';
import empty_circles from '../../../../assets/empty_circles/empty_circles.png';
import { EmptyState } from '../../../../components/Common';

export default function EmptyCircles() {
  const {
    details,
  }: any = useContext(MyCirclesListingContext);

  const history = useHistory();

  const state = history.location.state as {
    kCircleId: string,
    kCircleIndex: number,
    updateSwiper: false,
    kShowDetailsMobile: false
  };

  const openAddCirclePage = () => {
    window.scrollTo(0, 0);
    details(emptyCircleModel(), []);
    history.push({
      pathname: '/circles/new',
      state: {
        ...state,
        kCircleId: 0,
        kCircleIsNew: true,
        kCircleIndex: state?.kCircleIndex ? state.kCircleIndex : 0,
        updateSwiper: false,
        kShowDetailsMobile: false,
      },
    });
  };

  const { t } = useTranslation('global');

  return (
    <Grid item xs={12}>
      <CardLend
        kTitle={t('circles.empty_state.title')}
        kContent={(
          <EmptyState
            kImage={empty_circles}
            kTitle={t('circles.empty_state.welcome')}
            kDescription={t('circles.empty_state.description')}
            kDescription2={t('circles.empty_state.description2')}
            kAction={(
              <Button
                onClick={() => openAddCirclePage()}
                disableElevation
                variant="contained"
                size="large"
                sx={{
                  fontSize: 'subtitle1.fontSize',
                  textTransform: 'none',
                  fontWeight: 'bold',
                  height: 56,
                  borderRadius: 3,
                  paddingLeft: '38px',
                  paddingRight: '38px',
                }}
              >
                {t('circles.empty_state.button')}
              </Button>
            )}
          />
        )}
      />
    </Grid>
  );
}

import {
  Box,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { IconLinkButton } from '..';
import { HeaderTitleModels } from './HeaderTitle.models';

function HeaderTitle(props: HeaderTitleModels) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        ml={3}
        mr={2}
        mt={1}
        mb={1}
        width={{ md: '100%', xs: 'auto' }}
      >
        <Box
          sx={{
            fontSize: 'h6.fontSize',
            fontWeight: 'bold',
          }}
        >
          {props.kTitle}
        </Box>
        {props.kLinkText ? (
          <Box>
            <IconLinkButton
              kHref={props.kHref}
              kStartIcon={props.kLinkIcon}
              kText={props.kLinkText}
              kFontSize={isMobile ? 'body2.fontSize' : 'body1.fontSize'}
              kFontWeight={isMobile ? '400' : 'bold'}
              kOnClick={props.kOnClick}
            />
          </Box>
        ) : <></>}

      </Stack>
    </>
  );
}

HeaderTitle.defaultProps = {
  kTitle: '--',
  kLinkIcon: undefined,
  kLinkText: undefined,
};

export default HeaderTitle;

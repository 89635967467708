/* eslint-disable no-nested-ternary */
import {
  Grid, Divider, Card, CardContent, Stack, IconButton, Box, Typography, OutlinedInput, InputAdornment, FormControl, Button, useTheme, useMediaQuery, Fade, Skeleton,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ArrowBackIosRounded, FavoriteBorder, Favorite } from '@mui/icons-material';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import React, { useContext, useEffect, useState } from 'react';
import AppBarSecondary from '../../../../components/Layout/AppBarSecondary/AppBarSecondary';
import {
  ContentLayout, DialogCommon, LmiTheme, SectionTitle,
} from '../../../../components/Common';
import HeaderTitle from '../../../../components/Common/HeaderTitle/HeaderTitle';
import SuggestedMap from '../../../../components/Common/Map/MapPlaces';
import MyCirclesListingContext from '../../../../context/myCirclesListing/myCirclesListingContext';
import CircleItemsSwiper from './CircleItemsSwiper/CircleItemsSwiper';
import bookContext from '../../../../context/bookItem/bookContext';
import FavoritesContext from '../../../../context/favorites/favoritesContext';

export default function CircleItems() {
  const {
    items,
    itemsFiltered,
    filterCircleDetailItems,
    circle,
  }: any = useContext(MyCirclesListingContext);
  const {
    favItems,
    loadItems,
    updateFavorite,
  }: any = useContext(FavoritesContext);

  useEffect(() => {
    async function fetchItemsData() {
      await loadItems();
    }

    fetchItemsData();
  }, []);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [itemLat, setItemLat] = useState(items[selectedIndex]?.lat);
  const [itemLon, setItemLon] = useState(items[selectedIndex]?.lon);

  const [isHeartClicked, setIsHeartClicked] = useState(false);

  useEffect(() => {
    const selectedObject = items[selectedIndex];

    setItemLat(selectedObject?.lat != undefined ? selectedObject.lat : 0);
    setItemLon(selectedObject?.lon != undefined ? selectedObject.lon : 0);
  }, [selectedIndex]);

  const {
    clearData,
  }: any = useContext(bookContext);

  function handleClick(bookItem: any): void {
    if (!isHeartClicked) {
      clearData();
      const idCatLndCircle = circle?.idCatLndCircle ?? 0;
      history.push('/bookitem', {
        bookItem,
        idCatLndCircle,
      });
    } else {
      setIsHeartClicked(false);
    }
    // 👇 your logic here
  }

  const clickedHeart = async (item: any, isFavorite: boolean) => {
    setIsHeartClicked(true);
    await updateFavorite(item, isFavorite);
  };

  const skeletonListing = (index: any) => (
    <Grid key={index} item xs={6} sm={6} md={4} lg={3}>
      <Box>
        <Skeleton
          variant="rectangular"
          sx={{
            borderRadius: '7px', width: '100%', height: '170px', aspectRatio: '5 / 4',
          }}
        />
        <Skeleton height="30px" width={(index % 2 == 0) ? '92%' : '58%'} />
      </Box>
    </Grid>
  );

  const history = useHistory();

  const goToCircles = () => {
    window.scrollTo(0, 0);
    history.goBack();
  };

  const [search, setSearch] = React.useState('');

  useEffect(() => {
    const newTimer = setTimeout(() => {
      if (items) {
        filterCircleDetailItems(search);
      }
    }, 500);
    return () => clearTimeout(newTimer);
  }, [search]);

  const [openCircleItemsMap, setOpenCircleItemsMap] = React.useState(false);

  const openMapModal = () => {
    setOpenCircleItemsMap(true);
  };

  const cancelCloseMap = () => {
    setOpenCircleItemsMap(!openCircleItemsMap);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation('global');

  const listSize = items?.length;

  return (
    <>
      <AppBarSecondary
        kBack
        kTitle={
          (
            <SectionTitle
              kMarginTop={0}
              kMarginBottom={0}
              kTitle={t('circles.circle_items.title')}
            />
          )
        }
        kActions={
          (!isMobile ? (
            <Button
              onClick={() => openMapModal()}
              variant="outlined"
              sx={{
                display: { xs: 'inherit', sm: 'inherit' },
                borderRadius: 3,
                fontWeight: 'bold',
                textTransform: 'capitalize',
                border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
              }}
              startIcon={<PlaceOutlinedIcon />}
            >
              {t('circles.circle_items.map')}
            </Button>
          ) : (
            <Button
              onClick={() => openMapModal()}
              variant="outlined"
              sx={{
                display: { xs: 'inherit', sm: 'inherit' },
                borderRadius: '100%',
                width: '44px',
                height: '44px',
                minWidth: '44px',
                border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
              }}
            >
              <PlaceOutlinedIcon />
            </Button>
          )

          )
        }
      />
      <ContentLayout kPaddingXs={0}>
        <Card elevation={0} sx={{ maxWidth: 960, margin: 'auto' }}>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack
              px={1}
              py={1}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={3}
              sx={{
                display: { xs: 'none', md: 'flex' },
              }}
            >
              <IconButton color="primary" onClick={() => goToCircles()} sx={{ py: 1.4 }}>
                <ArrowBackIosRounded />
              </IconButton>
              <HeaderTitle
                kTitle={t('circles.circle_items.title')}
              />
            </Stack>
            <Button
              onClick={() => openMapModal()}
              variant="outlined"
              sx={{
                mr: '30px',
                display: { xs: 'none', sm: 'none', md: 'inherit' },
                borderRadius: 3,
                height: '40px',
                fontWeight: 'bold',
                textTransform: 'capitalize',
                border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
              }}
              startIcon={<PlaceOutlinedIcon />}
            >
              {t('circles.circle_items.map')}
            </Button>
          </Stack>

          <DialogCommon
            kTitle={t('circles.circle_items.title')}
            kMaxWidth="md"
            kBorderRadius={isMobile ? 0 : '10px'}
            kSlideTransition={isMobile}
            kDisableContentPadding={isMobile}
            kDisableBottomDivider
            kAlignBtn="center"
            kDisablePaddingInY={isMobile}
            kDisableContentPaddinginY={isMobile}
            kTopDivider
            kOnClose={cancelCloseMap}
            kOpenDialogLists={openCircleItemsMap}
            kDisableActions
            kMaxHeight={isMobile ? 'inherit' : '650px'}
            kContent={(
              <>
                <SuggestedMap
                  state={{
                    lat: itemLat,
                    long: itemLon,
                  }}
                  kDisableInsideInput
                  kCircleItems
                />
                <Box sx={{ ml: isMobile ? '25px' : 0 }}>
                  <CircleItemsSwiper
                    items={items}
                    handleSelectedIndex={(e: any) => setSelectedIndex(e)}
                  />
                </Box>

              </>
            )}
          />
          <Divider sx={{
            display: { xs: 'none', md: 'flex' },
          }}
          />
          <CardContent sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
          }}
          >
            <Box p={0}>
              <Stack flexDirection="column">
                <FormControl
                  className="lmi-formcontrol"
                  onChange={(e: any) => setSearch(e.target.value)}
                >
                  <OutlinedInput
                    sx={{ borderRadius: '10px', height: '48px', mt: '12px' }}
                    inputProps={{
                      maxLength: 50,
                    }}
                    value={search}
                    fullWidth
                    className="lmi-input-search"
                    startAdornment={(
                      <InputAdornment position="start">
                        <SearchRoundedIcon />
                      </InputAdornment>
                    )}
                    placeholder={t('circles.circle_items.filter_results')}
                  />
                </FormControl>
                <Grid container rowSpacing={0} columnSpacing={2} mt="30px">
                  {itemsFiltered !== undefined ? (
                    itemsFiltered?.map((item: any, index: number) => {
                      const isFavoriteIndex = favItems?.findIndex(
                        (element: any) => element.idCatLndItem == item.idCatLndItem,
                      );
                      item.isFavorite = false;
                      if (isFavoriteIndex > -1) {
                        item.isFavorite = true;
                      }

                      return (
                        <Grid item xs={6} sm={6} md={3} lg={3} key={item.idCatLndItem}>
                          <Fade
                            in={itemsFiltered.length > 0}
                            timeout={1000}
                            style={{ transitionDelay: `${index * 25}ms` }}
                          >
                            <Card elevation={0} sx={{ position: 'relative' }}>
                              <Stack
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-end"
                                onClick={() => handleClick(item)}
                                sx={{
                                  cursor: 'pointer',
                                  aspectRatio: '5 / 4',
                                  backgroundImage: `url(${item.itemPicture})`,
                                  backgroundSize: 'cover',
                                  backgroundPosition: 'center',
                                  borderRadius: '10px',
                                }}
                              />

                              <IconButton
                                sx={{
                                  position: 'absolute',
                                  top: 5,
                                  right: 5,
                                  backgroundColor: alpha(LmiTheme.light.systemBackground.primary, 0.5),
                                  '&:hover': {
                                    backgroundColor: alpha(LmiTheme.light.systemBackground.primary, 0.8),
                                  },
                                }}
                                onPointerEnter={() => {
                                  setIsHeartClicked(true);
                                }}
                                onPointerLeave={() => {
                                  setIsHeartClicked(false);
                                }}
                                onClick={() => {
                                  clickedHeart(item, !item.isFavorite);
                                }}
                              >
                                {item.isFavorite ? (
                                  <Favorite
                                    sx={{ color: 'white' }}
                                  />
                                ) : (
                                  <FavoriteBorder
                                    sx={{ color: 'white' }}
                                  />
                                )}
                              </IconButton>
                              <CardContent sx={{ p: 0 }}>
                                <Stack
                                  direction="column"
                                  px={0}
                                  pt={1}
                                  pb={0.5}
                                >
                                  <Typography
                                    variant="body1"
                                    color="text.primary"
                                    fontWeight={500}
                                    sx={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      display: '-webkit-box',
                                      WebkitBoxOrient: 'vertical',
                                      WebkitLineClamp: 1,
                                    }}
                                  >
                                    {item.name}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    fontWeight={400}
                                    sx={{ whiteSpace: 'nowrap' }}
                                  >

                                    {item.dailyRentalPrice
                                      ? `$${parseInt(item.dailyRentalPrice, 10)} ${item.currency} Daily`
                                      : item.hourlyRentalPrice
                                        ? `$${parseInt(item.hourlyRentalPrice, 10)} ${item.currency} Hourly`
                                        : item.weeklyRentalPrice
                                          ? `$${parseInt(item.weeklyRentalPrice, 10)} ${item.currency} Weekly`
                                          : `$${parseInt(item.monthlyRentalPrice, 10)} ${item.currency} Monthly`}

                                  </Typography>
                                </Stack>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                  fontSize="12px"
                                  sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 2,
                                  }}
                                >
                                  {item?.dscription}
                                </Typography>
                              </CardContent>
                            </Card>
                          </Fade>
                        </Grid>
                      );
                    }))
                    : (
                      <>
                        {[...Array(listSize)].map((_value: any, index: number) => (
                          skeletonListing(index)
                        ))}
                      </>
                    )}
                </Grid>
              </Stack>
            </Box>
          </CardContent>
        </Card>

      </ContentLayout>
    </>
  );
}

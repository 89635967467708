import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { LmiTheme } from '../../../../../components/Common';

export default function WelcomeLogin(param: any) {
  const { t } = useTranslation('global');
  let subtitle = `${t('sign_up.welcome')}`;
  let title = `${t('sign_up.signup_login')}`;

  if (param.step == 1) {
    if (!param.userExists) {
      subtitle = `${t('welcome.hi_there')}`;
      title = `${t('welcome.thanks_for_joining')}`;
    } else {
      subtitle = `${t('welcome.welcome_back')}`;
      title = param.user;
    }
  }
  if (param.step == 2) {
    subtitle = `${t('welcome.enter_phone')}`;
    title = `${t('welcome.phone_info')}`;
  }
  if (param.step == 3) {
    subtitle = `${t('welcome.last_thing')}`;
    title = `${t('welcome.sent_sms')}`;
  }
  if (param.step == 4) {
    subtitle = `${t('welcome.change_password')}`;
    title = `${t('welcome.create_password')}`;
  }

  if (param.step == 5) {
    subtitle = `${t('welcome.last_thing')}`;
    title = `${t('welcome.phone_info')}`;
  }

  if (param.step == 6) {
    subtitle = `${t('welcome.all_set')}`;
    title = `${t('welcome.upload')}`;
  }

  return (
    <Box sx={{ my: (param.step != 0 && param.step != 1) ? 4 : 5 }}>
      <Typography
        mb={param.step == 2 || param.step == 3 || param.step == 4 || param.step == 5 ? 2 : 0}
        fontSize="h6.fontSize"
        fontWeight={param.step == 2 || param.step == 3 || param.step == 4 || param.step == 5 ? 500 : 400}
        color={param.step == 2 || param.step == 3 || param.step == 4 || param.step == 5 ? LmiTheme.light.systemBackground.primary : LmiTheme.light.systemBackground.tertiary}
      >
        {subtitle}
      </Typography>
      {
        param.step !== 4
        && (
          <Typography
            fontSize={param.step == 2 || param.step == 3 || param.step == 5 ? 'body1.fontSize' : 'h6.fontSize'}
            fontWeight={param.step == 2 || param.step == 3 || param.step == 5 ? 400 : 500}
            color={param.step == 2 || param.step == 3 || param.step == 5 ? LmiTheme.light.systemBackground.secondary : LmiTheme.light.systemBackground.primary}
          >
            {title}
          </Typography>
        )
      }
    </Box>
  );
}

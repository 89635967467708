/* eslint-disable react/jsx-props-no-spreading */

import { LocalizationProvider, StaticDatePicker, PickersDay } from '@mui/x-date-pickers-pro';
import { useMediaQuery, useTheme } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { enUS, es } from 'date-fns/locale';
import cookies from 'js-cookie';
import moment from 'moment';
import { currentDate, currentDateYear } from '../../../../../../services/common';

function CalendarHourly({ dateRange, setDateRange, unavailableDates }: any) {
  const datearray = unavailableDates ?? [];
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <LocalizationProvider locale={(cookies.get('i18next') === 'en') ? enUS : es} dateAdapter={AdapterDateFns}>
      <StaticDatePicker
        displayStaticWrapperAs={isDesktop ? 'desktop' : 'mobile'}
        value={dateRange[0]}
        slots={{
          day: (props: any) => {
            const dayFormattedB = moment(props.day).format('YYYY-MM-DD').toString();
            const dayFormatted = moment(props.day, 'YYYY-MM-DD');
            const isBlocked = datearray?.indexOf(dayFormattedB) > -1;
            const date = new Date();
            const isPast = dayFormatted.isBefore(date.setDate(date.getDate() - 1));
            return (
              <PickersDay
              // MuiPickersDay-dayWithMargin
                sx={{
                  position: 'relative',
                  '&::after': isBlocked ? {
                    content: '"•"',
                    position: 'absolute',
                    bottom: '-13px',
                    color: 'orange',
                    fontSize: '25px',
                  } : {},
                }}
                selected
                {...props}
                disabled={isBlocked || isPast}
              />
            );
          },
        }}
        onChange={(valueDate) => {
          const resultDate: any = [valueDate, valueDate];
          localStorage.setItem('storageDateRange', resultDate);
          setDateRange(resultDate);
        }}
        minDate={new Date(currentDate)}
        maxDate={new Date(currentDateYear)}
      />
    </LocalizationProvider>
  );
}

export default CalendarHourly;

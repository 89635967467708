/* eslint-disable no-nested-ternary, react/jsx-props-no-spreading  */
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  Route,
  Redirect,
  BrowserRouter,
} from 'react-router-dom';
import * as AWS from 'aws-sdk/global';
// import { Admin } from '../components/Template';
import authContext from '../context/authentication/authContext';
import LoadingLayout from '../components/Common/LoadingLayout/LoadingLayout';

const ProtectedRoute = ({
  component: Component,
  title: Title, ...props
}: any) => {
  const controller = new AbortController();
  const mountedRef = useRef(false);
  const authContextLog = useContext(authContext);
  const {
    userAuthenticated,
    confirmAccount,
    user,
    // confirmAccount,
  }: any = authContextLog;
  const [isLoading, setLoad] = useState(true);
  const credentials: any = (AWS.config.credentials as AWS.CognitoIdentityCredentials);
  const token = localStorage.getItem('token');

  useEffect(() => {
    if ((!credentials?.accessKeyId || credentials?.accessKeyId === '')) {
      (async function authenticateUser() {
        await userAuthenticated(props.publicPage);
        if (!mountedRef.current) {
          setLoad(false);
        }
        controller.abort();
      }());
    } else {
      setLoad(false);
    }
    return () => {
      mountedRef.current = false;
      controller.abort();
    };
  }, [confirmAccount]);

  const access = props.publicPage ? true : (token && token !== '');
  const redirect = (!confirmAccount && (!user || user.length == 0) && !isLoading) || !access;

  const component = redirect ? (
    // props.publicPage ?
    <BrowserRouter forceRefresh>
      <Redirect to="/" />
    </BrowserRouter>
    // : <Redirect to="/login" />
  ) : (
    isLoading ? <LoadingLayout /> : <Component {...props} title={Title} />
    // : <Redirect to="/login" />
  );

  return (
    <Route
      {...props}
      render={() => component}
    />
  );
};

export default ProtectedRoute;

import { useReducer } from 'react';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';
import {
  LOAD_PICKUP_PICTURES, LOAD_DROPOFF_PICTURES, LOAD_PICKUP_VIDEOS, STOP_SWIPER_VIDEOS, NEW_VIDEO_CONVERTED,
} from '../../../types';
import PickUpDropOffPicturesContext from './pickUpdropOffPicturesContext';
import PickUpDropOffPicturesReducer from './pickUpdropOffPicturesReducer';

const PickUpDropOffPicturesState = ({ children }: any) => {
  const initialState = {
    pickUpPictures: [],
    dropOffPictures: [],
    pickupVideos: [],
  };

  const [state, dispatch] = useReducer(PickUpDropOffPicturesReducer, initialState);

  const updatePickUpPictures = (pictures: any) => {
    dispatch({
      type: LOAD_PICKUP_PICTURES,
      payload: pictures,
    });
  };

  const updatePickUpVideos = (videos: any) => {
    dispatch({
      type: LOAD_PICKUP_VIDEOS,
      payload: videos,
    });
  };

  const updateDropOffPictures = (pictures: any) => {
    dispatch({
      type: LOAD_DROPOFF_PICTURES,
      payload: pictures,
    });
  };

  const stopSwiperVideos = () => {
    dispatch({
      type: STOP_SWIPER_VIDEOS,
    });
  };

  const convertVideoInBackend = async (file:any, sizeInMB: any, t: any) => {
    try {
      const newVids = state.pickupVideos;
      const formData = new FormData();
      formData.append('videoFile', file);
      const requestOptions = {
        method: 'POST',
        body: formData,
      };

      await fetch(process.env.REACT_APP_CONVERT_MOV_VIDEO!, requestOptions).then((data) => data.json()).then((data) => {
        const objNewVideo = {
          base64: `data:video/mp4;base64,${data.fileContents}`,
          name: `vid_${uuid()}_${file.name}`,
          file, // Almacenar el archivo completo aquí
          sizeInMB: sizeInMB.toFixed(2),
        };
        newVids?.push(objNewVideo);

        dispatch({
          type: NEW_VIDEO_CONVERTED,
          payload: newVids,
        });
      });
    } catch (e: any) {
      toast.error(t('add_item_page.error_video'));
    }
  };

  return (
    <PickUpDropOffPicturesContext.Provider
      value={
     {
       ...state,
       pickUpPictures: state.pickUpPictures,
       dropOffPictures: state.dropOffPictures,
       playVideos: state.playVideos,
       videoConverted: state.videoConverted,
       updatePickUpPictures,
       updateDropOffPictures,
       updatePickUpVideos,
       stopSwiperVideos,
       convertVideoInBackend,
     }
      }
    >
      {children}
    </PickUpDropOffPicturesContext.Provider>
  );
};

export default PickUpDropOffPicturesState;

import * as React from 'react';
import {
  Radio, Stack, Typography, Box, Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ActivityCancelStyle from './ActivityCancel.style';
import { ActivityCancelModel } from './ActivityCancel.models';
import activityActionsContext from '../../../../../../context/activity/actions/activityActionsContext';
import InputForm from '../../../../../../components/Common/InputForm/InputForm';

export default function ActivityCancel(props: ActivityCancelModel) {
  const [selectedValue, setSelectedValue] = React.useState('a');
  const [reasonInput, setReasonInput] = React.useState('');
  const activityActionContext = React.useContext(activityActionsContext);
  const {
    setRejectReason,
  }: any = activityActionContext;

  const { t } = useTranslation('global');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value == 'a1') {
      setRejectReason(t('activity.cancel.profile_not_verified'));
      setSelectedValue(event.target.value);
    } else if (event.target.value == 'b1') {
      setRejectReason(t('activity.cancel.not_available'));
      setSelectedValue(event.target.value);
    } else if (event.target.value == 'c1') {
      setRejectReason(t('activity.cancel.time_doesnt_work'));
      setSelectedValue(event.target.value);
    } else {
      if (event.target.value !== 'd1') {
        setReasonInput(event.target.value);
        setRejectReason(event.target.value);
      }
      setSelectedValue('d1');
    }
  };

  React.useEffect(() => {
    if (selectedValue === 'd1' && reasonInput !== '') {
      setRejectReason(reasonInput);
    }
  }, [selectedValue]);

  return (
    <Box sx={ActivityCancelStyle}>
      {
        props.kLenderRenter?.notifType == 'LEND'
        && (
          <>
            <Typography
              px={3}
              py={2}
              className="lmiw-Activity-cancel-title"
            >
              {props.kMessage}

            </Typography>
            <Divider />
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              px={3}
              py={1}
            >
              <Typography>{t('activity.cancel.profile_not_verified')}</Typography>
              <Radio
                checked={selectedValue === 'a1'}
                onChange={handleChange}
                value="a1"
                name="radio-buttons"
                inputProps={{ 'aria-label': 'A1' }}
              />
            </Stack>
            <Divider />
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              px={3}
              py={1}
            >
              <Typography>{t('activity.cancel.not_available')}</Typography>
              <Radio
                checked={selectedValue === 'b1'}
                onChange={handleChange}
                value="b1"
                name="radio-buttons"
                inputProps={{ 'aria-label': 'B1' }}
              />
            </Stack>
            <Divider />
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              px={3}
              py={1}
            >
              <Typography>{t('activity.cancel.time_doesnt_work')}</Typography>
              <Radio
                checked={selectedValue === 'c1'}
                onChange={handleChange}
                value="c1"
                name="radio-buttons"
                inputProps={{ 'aria-label': 'C1' }}
              />
            </Stack>
            <Divider />
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              px={3}
              pt={1}
            >
              <Typography>{t('activity.cancel.other')}</Typography>
              <Radio
                checked={selectedValue === 'd1'}
                onChange={handleChange}
                value="d1"
                name="radio-buttons"
                inputProps={{ 'aria-label': 'D1' }}
              />
            </Stack>
            <Box
              px={3}
              pb={1}
              pt={0}
            >
              <InputForm
                id="other"
                label={t('activity.cancel.reason')}
                helper_text={t('activity.cancel.required_field')}
                max_lenght="420"
                disabled={selectedValue != 'd1'}
                input_params={{
                  multiline: false,
                  placeholder: `${t('activity.cancel.reason_rejection')}`,
                  name: 'Other',
                  onChange: handleChange,

                }}
                form_control_params={{
                  fullWidth: true,
                }}
              />
            </Box>
          </>
        )
      }
      {
        props.kLenderRenter?.notifType == 'RENT'
        && (
          <Box
            px={3}
            pb={1}
            pt={0}
          >
            <InputForm
              id="other"
              label={t('activity.cancel.cancelReservationSubtitle')}
              helper_text={t('activity.cancel.required_field')}
              max_lenght="420"
              input_params={{
                multiline: false,
                name: 'Other',
                onChange: handleChange,
              }}
              form_control_params={{
                fullWidth: true,
              }}
            />
          </Box>
        )
      }
    </Box>
  );
}

import {
  DELETE,
  APPLY_CATEGORIES,
  LOADING,
  SEARCH,
  ADD_RANGE,
  DELETE_ALL,
  MORE,
  LOCATION,
  INITIAL,
  LOADINGITEMS,
  CHANGE_PAGE,
  SEARCH_SIMILAR,
} from '../../types/index';

export default (state: any, action: any) => {
  switch (action.type) {
    case SEARCH:
      const items = action.payload.page == 1
        ? action.payload.items
        : state.items.concat(action.payload.items);

      return {
        ...state,
        items,
        similarItemList: items,
        searched: action.payload.searched,
        loading: false,
        interaction: false,
        loadingItems: false,
        loadingMore: false,
        changedSimilarItems: !state.changedSimilarItems,
      };
    case SEARCH_SIMILAR:
      const similarItemList = action.payload.page == 1
        ? action.payload.items
        : state.items.concat(action.payload.items);
      // returning items, this validations is made for when the user opens the book item page
      // from a deep link, if the user comes from a deeplink, the searched items become populated
      // with the similar listings, this for the user to have something to see if going back
      // to the search page
      return {
        ...state,
        similarItemList,
        items: state.items ?? similarItemList,
        changedSimilarItems: !state.changedSimilarItems,
        searched: action.payload.searched,
        loading: false,
        interaction: false,
        loadingItems: false,
        loadingMore: false,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case LOADINGITEMS: return {
      ...state,
      loadingItems: action.payload,
    };
    case CHANGE_PAGE: return {
      ...state,
      page: action.payload,
    };
    case APPLY_CATEGORIES:
      localStorage.setItem('filtersApplied', JSON.stringify(action.payload));
      return {
        ...state,
        categoriesApplied: action.payload,
        interaction: true,
        loadingItems: true,
        page: 1,
      };
    case DELETE:
      const category = action.payload;
      let newChecked = [...state.categoriesApplied ?? []];

      const exist = newChecked.filter((f:any) => f.id === category.id).length > 0;

      if (exist) {
        newChecked = newChecked.filter((f) => f.id !== category.id);
      }

      localStorage.setItem('filtersApplied', JSON.stringify(newChecked));
      return {
        ...state,
        categoriesApplied: newChecked,
        loading: false,
        interaction: true,
        loadingItems: true,
        page: 1,
      };
    case ADD_RANGE:
      localStorage.setItem('filtersApplied', JSON.stringify(action.payload));
      return {
        ...state,
        categoriesApplied: action.payload,
        // interaction: true,
      };
    case DELETE_ALL:
      localStorage.removeItem('filtersApplied');
      localStorage.setItem('searchCurrency', '');

      return {
        ...state,
        categoriesApplied: null,
        interaction: true,
        loadingItems: true,
      };
    case MORE:
      const page = state.page + 1;
      return {
        ...state,
        page,
        interaction: true,
        loadingMore: true,
      };
    case LOCATION:
      const {
        lat,
        long,
      }: any = action.payload;
      return {
        ...state,
        lat,
        long,
        interaction: true,
        page: 1,
        loadingItems: true,
        loadingMore: true,
      };
    case INITIAL:
      localStorage.removeItem('filtersApplied');
      localStorage.setItem('searchCurrency', '');

      // const initialState = action.payload;
      return {
        ...state,
        categoriesApplied: null,
        items: null,
        similarItems: null,
        loading: true,
        categoriesSelected: null,
        searched: '',
        interaction: false,
        loadingItems: false,
        loadingMore: false,
        page: 1,
      };

    default:
      return state;
  }
};

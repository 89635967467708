import { CloseRounded } from '@mui/icons-material';
import {
  Grid, IconButton,
} from '@mui/material';
// import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapComponent } from '../../../../components/Common';
import CardLend from '../../Listing/Components/CardLend/CardLend';
import { CirclesMapViewModels } from './CirclesMapView.models';

function CirclesMapView(props: CirclesMapViewModels) {
  const { t } = useTranslation('global');

  return (
    <>
      <Grid
        item
        xs={12}
        md={8}
        sx={{ display: props.openMapView ? 'inherit' : 'none' }}
      >
        <CardLend
          kTitle={t('circles.main.view_on_map')}
          kActions={(
            <IconButton color="primary" onClick={() => props.handleViewMap(false)}>
              <CloseRounded sx={{ fontSize: '30px', p: 0 }} />
            </IconButton>
          )}
          // kBackAction={}
          kContent={(
            <MapComponent
              kDisableInput
              kShowSearchedCircles={props.showSearchedCircles}
              kOnMarkerClick={props.kOnMarkerClick}
              kDisableBorder
              state={{
                lat: props.usrLat,
                long: props.usrLon,
              }}
              kFullHeight
            />
          )}
          kViewMap={props.openMapView}
        />
      </Grid>
    </>
  );
}

export default CirclesMapView;

import {
  Box, Stack, AvatarGroup, Avatar, Typography, useTheme, useMediaQuery, IconButton,
} from '@mui/material';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { useHistory } from 'react-router-dom';
import MyCirclesListingContext from '../../../../../../context/myCirclesListing/myCirclesListingContext';
import LmiTheme from '../../../../../../themes/lmi-mui-theme/lmi-theme';
import AddEditCirclesContext from '../../../../../../context/circles/addEditCirclesContext';
import { CircleMembersDetailModels } from './CircleMembersDetail.models';
import { IconLinkButton } from '../../../../ProfilePrivate/Components';

export default function CircleMembersDetail(props: CircleMembersDetailModels) {
  const history = useHistory();

  const state = history.location.state as {
    kOpensFromCircleDetail: boolean;
  };

  const openCircleMembersPage = () => {
    window.scrollTo(0, 0);
    history.push({
      pathname: '/circles/members',
      state: {
        ...state,
        kOpensFromCircleDetail: true,
      },
    });
  };

  const circlesContextLog = useContext(AddEditCirclesContext);
  const {
    unsetMembersEdit,
    loading,
  }: any = circlesContextLog;

  const {
    members,
  }: any = useContext(MyCirclesListingContext);

  const totalMembers = members?.length;

  props.kTotalMembers?.(totalMembers);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const numberOfAvatars = isMobile ? 2 : 4;

  useEffect(() => {
    (async function unsetMembersEditData() {
      await unsetMembersEdit();
    }());
  }, []);

  const { t } = useTranslation('global');

  return (
    <Box>
      {(totalMembers > 0 && !loading) ? (
        <Stack p={2} direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
          <AvatarGroup
            total={totalMembers}
            sx={{
              '& .MuiAvatar-root': {
                width: isMobile ? '50px' : '60px',
                height: isMobile ? '50px' : '60px',
                backgroundColor: '#f3f3f3',
                color: 'black',
                fontSize: '16px',
              },
            }}
          >
            {
              /* eslint-disable react/jsx-props-no-spreading */
              members?.map((member: any, i: number) => (
                i <= numberOfAvatars && (
                  <Avatar key={uuid()} alt={member?.name} src={member?.memberPicture} />
                )
              ))
            }
          </AvatarGroup>

          {
            isMobile ? (
              <IconButton
                onClick={() => openCircleMembersPage()}
              >
                <ArrowForwardIosOutlinedIcon color="primary" />
              </IconButton>
            ) : (
              <IconLinkButton
                kOnClick={() => openCircleMembersPage()}
                kEndIcon={<ArrowForwardIosOutlinedIcon />}
                kText={t('circles_card.see_all')}
                kFontSize="body1.fontSize"
                kFontWeight="bold"
                kHideText={false}
              />
            )
          }

        </Stack>
      )
        : (
          <Stack p={2} direction="row" justifyContent="space-between" alignItems="center" spacing={3}>

            <Typography
              fontSize="16px"
              color={LmiTheme.light.systemBackground.tertiary}
              sx={{
                padding: '16px 30px',
              }}
            >
              {t('circles.detail.no_members')}
            </Typography>
          </Stack>
        )}
    </Box>
  );
}

import { Box } from '@mui/material';
import { MainInformationTabPanelModels } from './MainInformationTabPanel.models';

function MainInformationTabPanel(props: MainInformationTabPanelModels) {
  return (
    <>
      <Box
        role="tabpanel"
        hidden={props.kTabValue !== props.kTabIndex}
        id={`simple-tabpanel-${props.kTabIndex}`}
        aria-labelledby={`simple-tab-${props.kTabIndex}`}
      >
        {props.kTabValue === props.kTabIndex && (
          <Box>
            {props.kContent}
          </Box>
        )}
      </Box>
    </>
  );
}

export default MainInformationTabPanel;

/* eslint-disable react/no-danger */
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import cookies from 'js-cookie';
// import i18next from 'i18next';

import {
  ContentLayout, SectionTitle,
} from '../../../components/Common';
import AncillaryPageStyle from './AncillaryPage.style';
import NavbarAncillary from './Components/NavBarAncillary/NavBarAncillary';
// const location = useLocation();

function useAsyncSinglePage(path: string) {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState('false');
  useEffect(() => {
    // async function fetchPostWp(idPost: string) {
    //   try {
    //     setLoading('true');
    //     const response = await fetch(
    //       `https://wp.lmiserv.net/wp-json/wp/v2/posts/${idPost}`,
    //     );

    //     const json = await response.json();
    //     setTitle(json.title.rendered);
    //     setContent(json.content.rendered);
    //   } catch (error) {
    //     setLoading('null');
    //   }
    // }
    async function fetchPageBySlugWp(slugPage: string) {
      try {
        setLoading('true');
        const slug = `${cookies.get('i18next')}-${slugPage}`;
        const response = await fetch(
          `https://wp.lmiserv.net/wp-json/wp/v2/pages?slug=${slug}`,
        );
        const json = await response.json();
        // console.log(json[0]);
        setTitle(json[0].title.rendered);
        setContent(json[0].content.rendered);
      } catch (error) {
        setLoading('null');
      }
    }

    if (path !== '') {
      switch (path) {
        case '/terms':
          fetchPageBySlugWp('terms');
          break;
        case '/privacy-policy':
          fetchPageBySlugWp('privacy-policy');
          break;
        case '/faq':
          switch (cookies.get('i18next')) {
            case 'en':
              setTitle('FAQ');
              break;
            case 'es':
              setTitle('Preguntas Frecuentes');
              break;
            default:
              break;
          }
          setContent('');
          break;
        case '/about':
          fetchPageBySlugWp('about');
          break;
        case '/guarantee':
          fetchPageBySlugWp('guarantee');
          break;
        case '/support':
          fetchPageBySlugWp('support');
          break;
        case '/eula':
          // fetchPostWp('1056');
          fetchPageBySlugWp('eula');
          break;
        default:
          break;
      }
    }
  }, [path]);

  return [title, content, loading];
}

function useAsyncListCat() {
  const [listCat, setListCat] = useState([]);
  const [idCatLang, setIdCatLang] = useState('');
  useEffect(() => {
    switch (cookies.get('i18next')) {
      case 'en':
        setIdCatLang('44');
        break;
      case 'es':
        setIdCatLang('45');
        break;
      default:
        break;
    }
    async function fetchFaqWp() {
      try {
        // setLoading('true');
        const response = await fetch(
          `https://wp.lmiserv.net/wp-json/wp/v2/posts?status=publish&hide_empty=true&order=asc&per_page=99&categories=${idCatLang}`,
        );

        const json = await response.json();
        setListCat(json);
      } catch (error) {
        setListCat([]);
      }
    }

    if (idCatLang !== '') {
      fetchFaqWp();
    }
  }, [idCatLang]);

  return [listCat];
}

export default function AncillaryPage() {
  const location = useLocation();
  const [title, content] = useAsyncSinglePage(location.pathname);
  const [listCat] = useAsyncListCat();

  return (
    <Box sx={AncillaryPageStyle}>
      <NavbarAncillary />
      <ContentLayout>
        <Box component="section">
          {/* {i18next.getFixedT('lng')} */}
          <SectionTitle kMarginTop={0} kMarginBottom={5} kComponent="h1" kVariant="h5" kTitle={title} />
          {/* <FacebookCircularProgress /> */}
          {location.pathname === '/faq'
            ? listCat.map((post: any) => (
              <div key={`-${post.title.rendered}`}>
                <h3 dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
                <p dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
              </div>
            ))
            : <div dangerouslySetInnerHTML={{ __html: content }} />}

        </Box>
      </ContentLayout>
    </Box>
  );
}

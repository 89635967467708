import { Button, Stack } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { LmiTheme, SectionTitle, UserMenu } from '../../../../../components/Common';
import AppBarSecondary from '../../../../../components/Layout/AppBarSecondary/AppBarSecondary';
import MyCirclesListingContext from '../../../../../context/myCirclesListing/myCirclesListingContext';
import { CirclesAppBarSecondaryModels } from './CirclesAppBarSecondary.models';

function CirclesAppBarSecondary(props: CirclesAppBarSecondaryModels) {
  const {
    circle,
  }: any = useContext(MyCirclesListingContext);

  const { t } = useTranslation('global');

  return (
    props.openCircleDetail || props.openMapView ? (
      <AppBarSecondary
        kBackClick={() => {
          props.openDetails(false);
          props.handleOpenMapView(false);
        }}
        kBack
        kTitle={
          (
            <SectionTitle
              kMarginTop={0}
              kMarginBottom={0}
              kTitle={props.openMapView ? t('circles.main.map_view') : t('circles.main.circle_detail')}
            />
          )
        }
        kActions={(
          <>
            {
              (circle?.isOwner || circle?.isAdmin) ? (
                null
              ) : (
                <Button
                  onClick={() => props.handleOpenReportCircleModal(true)}
                  variant="outlined"
                  sx={{
                    textTransform: 'capitalize',
                    borderRadius: '100%',
                    width: '44px',
                    height: '44px',
                    minWidth: '44px',
                    border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
                    mr: '10px',
                  }}
                >
                  <ErrorOutlineRoundedIcon />
                </Button>
              )
            }
            <Button
              onClick={() => props.handleOpen(true)}
              variant="outlined"
              sx={{
                textTransform: 'capitalize',
                borderRadius: '100%',
                width: '44px',
                height: '44px',
                minWidth: '44px',
                border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
                display: props.openMapView ? 'none' : 'inherit',
              }}
            >
              <IosShareOutlinedIcon />
            </Button>
          </>
        )}
      />
    ) : (
      <AppBarSecondary
        kTitle={
          (
            <SectionTitle
              kMarginTop={0}
              kMarginBottom={0}
              kTitle={!props.openSearchCircle ? t('circles.main.circles') : t('circles.main.search_circles')}
            />
          )
        }
        kBack={props.openSearchCircle}
        kBackClick={() => {
          props.handleOpenSearchCircle(false);
          props.changeCircleListingToShow('myCircles');
          props.handleSearchedCirclesInfo(false);
        }}
        kActions={(
          <>
            {
              !props.openSearchCircle ? (
                <Stack flexDirection="row" alignItems="center" gap={1}>
                  <Button
                    onClick={() => {
                      props.handleOpenSearchCircle(true);
                      props.changeCircleListingToShow('suggested');
                    }}
                    variant="outlined"
                    sx={{
                      textTransform: 'capitalize',
                      borderRadius: '100%',
                      width: '44px',
                      height: '44px',
                      minWidth: '44px',
                      border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
                    }}
                  >
                    <SearchOutlinedIcon />
                  </Button>
                  <UserMenu />
                </Stack>

              ) : <></>
            }
          </>
        )}
      />
    )
  );
}

export default CirclesAppBarSecondary;

import moment from 'moment';
import { RoadieItem } from './roadie_item';
import { RoadieLocation } from './roadie_location';
import { RoadieTimeWindow } from './roadie_time_window';
import { RoadieOptions } from './roadie_options';
import { RoadieDriver } from './roadie_driver';
import useGetRoadieShipmentState, { RoadieShipmentState } from './roadie_shipment_state';

export interface RoadieShipment {

   id: number;
   referenceId: string;
   description: string;
   state: RoadieShipmentState;
   alternateId1: string;
   alternateId2: string;
   items: Array<RoadieItem>;
   pickUp: RoadieLocation;
   dropOff: RoadieLocation;
   pickUpAfter: string;
   deliverBetween: RoadieTimeWindow;
   options: RoadieOptions;
   trackingNumber: string;
   driver?: RoadieDriver;
   price: number;
   estimatedDistance: number;
   canceled?: boolean;
}

// export default function roadieShipmentFromJson(roadieShipmentModel: any) {
export const roadieShipmentFromJson = (roadieShipmentModel: any) => {
  const objModel: any = {
    id: roadieShipmentModel.id,
    referenceId: roadieShipmentModel.reference_id,
    description: roadieShipmentModel.description,
    state: useGetRoadieShipmentState(roadieShipmentModel.state),
    alternateId1: roadieShipmentModel?.alternate_id_1 ?? '',
    alternateId2: roadieShipmentModel?.alternate_id_2 ?? '',
    items: roadieShipmentModel.items,
    pickUp: roadieShipmentModel.pickup_location,
    dropOff: roadieShipmentModel.delivery_location,
    pickUpAfter: roadieShipmentModel.pickup_after,
    deliverBetween: deliverBetweenLocal(roadieShipmentModel.deliver_between),
    options: roadieShipmentModel.options,
    trackingNumber: roadieShipmentModel.tracking_number,
    // driver: roadieShipmentModel?.roadie_driver,
    price: roadieShipmentModel.price,
    estimatedDistance: roadieShipmentModel.estimated_distance,
    // canceled: roadieShipmentModel.canceled,
  };

  return objModel;
};

// export default function roadieShipmentFromJson(roadieShipmentModel: any) {
export const preOrderFromJson = (roadieShipmentModel: any) => {
  const objModel: any = {
    items: [],
    // pickUp: pickupLocation,
    state: useGetRoadieShipmentState(null),
    deliverBetween: deliverBetweenLocal({
      start: roadieShipmentModel?.delivery_between_start,
      end: roadieShipmentModel?.delivery_between_end,
    }),
  };
  return objModel;
};

// TODO revisar para el guardado UTC
const deliverBetweenLocal = (roadieTimeWindow: any) => {
  const format2 = 'h:mm A';

  const splitStartWindow = roadieTimeWindow?.start.replace('T', ' ').replace('Z', '').split(' ');
  const splitEndWindow = roadieTimeWindow?.end.replace('T', ' ').replace('Z', '').split(' ');
  // added default year date for different calendar
  // spanish calendars have a different arrange 'YYYY/MM/DD' and US is 'YYYY/DD/MM' so it would show an invalid date
  const convertedStartUTC = new Date(`2022-01-01 ${splitStartWindow[1]}`);
  const convertedEndUTC = new Date(`2022-01-01 ${splitEndWindow[1]}`);
  const ticks1 = convertedStartUTC.getTime() - convertedStartUTC.getTimezoneOffset() * 60000;
  const ticks2 = convertedEndUTC.getTime() - convertedEndUTC.getTimezoneOffset() * 60000;
  const startDate2 = moment(new Date(ticks1).toLocaleString('en-US')).format(format2);
  const endDate2 = moment(new Date(ticks2).toLocaleString('en-US')).format(format2);
  const objModel: RoadieTimeWindow = {
    start: startDate2,
    end: endDate2,
  };
  return objModel;
};

import { LmiTheme } from '../../../../../../components/Common';

const ActivityScheduledStyle = {
  width: '100%',
  '.lmiw-activity-scheduled': {
    borderRadius: '10px',
    p: 2,
    '.lmiw-scheduled-label': {
      fontSize: '14px',
      fontWeight: 'bold',
      color: LmiTheme.light.systemBackground.secondary,
    },
    '.lmiw-scheduled-date': {
      fontSize: '18px',
      fontWeight: 'bold',
      color: 'black',
    },
  },

};

export default ActivityScheduledStyle;

import {
  Box, Card, Paper, Stack, Typography,
} from '@mui/material';
import { useContext } from 'react';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { useTranslation } from 'react-i18next';
import ActivityContext from '../../../../../context/activity/activityContext';
import ActivityDetailStyle from '../ActivityDetail.style';
import ActivityCircleCard from '../Components/ActivityCircleCard/ActivityCircleCard';
import { ActivityTitleStatusModel } from '../Components/ActivityStatus/ActivityStatus.models';
import SectionTitle from '../../../../../components/Common/SectionTitle/SectionTitle';
import ActivityStatusStyle from '../Components/ActivityStatus/ActivityStatus.style';
import CircleEarningsStyle from './CircleEarningsDetail.style';

export default function RejectedFromCircleDetail() {
  const { t } = useTranslation('global');
  const activityContext = useContext(ActivityContext);
  const {
    openedActivityItem,
  }: any = activityContext;
  const sectionTitle = `${t('activity_item.your_request')} ${openedActivityItem.name} ${t('activity_item.has_been_denied')} ${t('activity_item.for_the_following_reason')}`;
  return (
    <>
      <Card elevation={0} sx={ActivityDetailStyle}>

        <Box className="lmiw-detail-content">

          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="end"
            spacing={2}
            px={2}
            pb={2}
          >

            <Box sx={ActivityStatusStyle}>
              <SectionTitle
                kMarginTop={3}
                kTitle={t('activity.status.status')}
                kFontSize={18}
              />
              <Paper variant="outlined" elevation={0}>
                <CardTitle
                  kColorStatus="red"
                  kTextStatus="Rejected by Owner"
                  kActionProgress={() => { }}
                />
              </Paper>
            </Box>
            <ActivityCircleCard
              circleInfo={openedActivityItem}
            />

            <Box sx={CircleEarningsStyle}>
              <SectionTitle
                kMarginTop={2}
                kTitle={sectionTitle}
                kFontSize={18}
              />
              <Paper
                variant="outlined"
                elevation={0}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className="lmiw-summary-total"
                >
                  <Typography className="lmiw-more-info">
                    {openedActivityItem?.joinRequestDenyComments}
                  </Typography>
                </Stack>
              </Paper>
            </Box>
          </Stack>
        </Box>
      </Card>

    </>
  );
}

function CardTitle(props: ActivityTitleStatusModel) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={`lmiw-status-header lmiw-status-${props.kColorStatus}`}
      p={1}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <CheckCircleOutlineRoundedIcon />
        <Typography className="lmiw-status-title">
          {props.kTextStatus}
        </Typography>
      </Stack>
    </Stack>
  );
}

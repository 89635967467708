import React, { useContext, useEffect, useState } from 'react';
import {
  // Button,
  Card,
  Divider,
  Grid,
  ListItemIcon,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  HeaderTitle,
  MainInformation,
  TabPanelChangePassword,
  TabPanelMainInformation,
  TabPanelPaymentMethod,
  TabPanelReviews,
} from './Components';
import {
  ContentLayout, LmiTheme, Profile, SectionTitle, UserMenu,
} from '../../../components/Common';
import publicProfileContext from '../../../context/publicProfile/publicProfileContext';
import AppBarSecondary from '../../../components/Layout/AppBarSecondary/AppBarSecondary';

function PrivateProfile() {
  /// Breakpoints Media Query
  const theme = useTheme();
  const history = useHistory();
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));
  const pProfileContext = useContext(publicProfileContext);

  const state = history.location.state as {
    kGoToPayout: number,
  };

  /// Tabs Content view event
  const [kTabValue, kTabSetValue] = useState(state?.kGoToPayout == 1 ? 1 : 0);
  const kTabHandleChange = (_event: React.SyntheticEvent, kTabNewValue: number) => {
    kTabSetValue(kTabNewValue);
  };
  const kTabHandleChangeToReviews = () => kTabSetValue(3);

  const {
    privateProfileInfo,
    userReviews,
    givenReviewList,
    getPublicProfileInfo,
    updateCompleteName,

  }: any = pProfileContext;
  useEffect(() => {
    updateCompleteName(privateProfileInfo?.completeName);
  }, []);

  const gotoPublicProfile = () => {
    (async function fetchProfileInfo() {
      await getPublicProfileInfo({ idCatLender: 0 });
      history.push('/public-profile', { fromPage: 'private-profile', publicProfileId: 0 });
    }());
  };

  const { t } = useTranslation('global');

  return (
    <>
      <AppBarSecondary
        kTitle={
          (
            <SectionTitle
              kMarginTop={0}
              kMarginBottom={0}
              kTitle={t('profile.my_profile')}
            />
          )
        }
        kActions={<UserMenu />}
      />
      <ContentLayout kPaddingXs={0}>
        <Grid container spacing={{ md: 3 }} direction={!isTablet ? 'column-reverse' : 'row'}>
          <Grid item mb={isTablet ? 3 : 0} xs={12} sm={12} md={8}>
            <Card elevation={0}>
              <Stack
                px={1}
                py={1}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={3}
                sx={{
                  display: { xs: 'none', md: 'flex' },
                }}
              >
                <HeaderTitle
                  kTitle={t('profile.my_profile')}
                  kLinkIcon={(
                    <ListItemIcon sx={{
                      minWidth: '10px',
                      marginRight: '5px',
                      svg: {
                        fill: LmiTheme.light.accent,
                        width: '22px',
                        height: '22px',
                      },
                    }}
                    >
                      <Profile />
                    </ListItemIcon>
                  )}
                  kLinkText={t('profile.public')}
                  kOnClick={gotoPublicProfile}
                />

              </Stack>
              <Divider sx={{
                display: { xs: 'none', md: 'flex' },
              }}
              />
              <TabPanelMainInformation kTabValue={kTabValue} kProfileInfo={privateProfileInfo} kTabIndex={0} kOnClickGoToReviewsTab={kTabHandleChangeToReviews} kId="profile-tab-0" />
              <TabPanelPaymentMethod kTabValue={kTabValue} kTabIndex={1} kOpenPayoutModal={state?.kGoToPayout == 1} />
              <TabPanelChangePassword kTabValue={kTabValue} kTabIndex={2} />
              <TabPanelReviews
                kTabValue={kTabValue}
                kProfileInfo={privateProfileInfo}
                kRecommendedNumber={privateProfileInfo?.sumRatesReceived?.toFixed(2) ?? 100}
                kUserReviews={userReviews}
                kGivenReviews={givenReviewList}
                kTabIndex={3}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={4} sx={{ position: 'sticky', top: { md: 64, xs: 56 }, zIndex: 1 }}>
            <Card elevation={0} sx={{ display: 'block', position: 'sticky', top: 80 }}>
              <MainInformation
                kHandleChange={kTabHandleChange}
                kValue={kTabValue}
              />
            </Card>
          </Grid>
        </Grid>
      </ContentLayout>
    </>
  );
}

export default PrivateProfile;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {
  Paper,
  List,
  ListItemButton,
  ListItemIcon,
  Typography,
  InputBase,
  IconButton,
  FormControl,
  useTheme,
  useMediaQuery,
  Box,
  Stack,
  Divider,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useJsApiLoader } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import RoomIcon from '@mui/icons-material/Room';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { MapModels } from './Map.models';
import Map from './Map';
import DialogCommon from '../DialogCommon/DialogCommon';

export default function SuggestedMap({
  state,
  funUpdateInfo,
  usrLat,
  usrLng,
  kDisableInsideInput,
  kDisableInput,
  kShowSearchedCircles,
  kDisableBorder,
}: MapModels) {
  // Placeholders
  const [isWriting, setWriting] = useState(false);
  const [isVisiblePlaces, setIsVisiblePlaces] = useState(false);
  const [currentSelectIndex, setCurrentSelectIndex] = useState(0);
  const [mapZoom, setMapZoom] = useState(13);

  const [openLocationError, setOpenLocationError] = useState(false);
  const [updateAdress, setUpdateAdress] = useState(false);

  const [LocationErrorMsg, setLocationErrorMsg] = useState('');

  const [libraries]: any = useState(['places']);

  const RO = (usrLat != 0 && usrLng != 0 && typeof usrLat == 'number' && typeof usrLng == 'number')
    ? {
      componentRestrictions: {
        country: ['US', 'MX', 'CA'],
      },
      // eslint-disable-next-line no-undef
      location: new google.maps.LatLng(usrLat, usrLng),
      radius: 10000,
      /* Define search scope here */
    } : {
      componentRestrictions: {
        country: ['US', 'MX', 'CA'],
      },
      /* Define search scope here */
    };

  useJsApiLoader({
    id: 'google-map-script',
    libraries,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
  });
  const {
    value,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: RO,
    debounce: 300,
  });

  useEffect(() => {
    setWriting(true);
    if (state?.address != '') {
      setValue(state?.address ?? '');
    }
  }, [state?.address]);

  const onChange = (e: any) => {
    setWriting(true);
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const getLatLangUpdateAddressInfo = (results: any, lat: any, lng: any, add: any) => {
    let streetAddress = '';
    let extendedAddress = '';
    let locality = '';
    let region = '';
    let postalCode = '';
    let countryName = '';
    let countryCode = '';
    results.address_components.forEach((element: any) => {
      const shortName = element.short_name;
      const longName = element.long_name;

      const { types } = element;

      if (types.includes('colloquial_area')) {
        streetAddress = longName;
      }
      if (types.includes('street_number')) {
        streetAddress = `${streetAddress} ${longName}`.trim();
      }
      if (types.includes('route')) {
        streetAddress = `${streetAddress} ${longName}`.trim();
      } else if (types.includes('sublocality')) {
        extendedAddress = longName;
      } else if (types.includes('locality')) {
        locality = longName;
      } else if (types.includes('administrative_area_level_1')) {
        region = shortName;
      } else if (types.includes('postal_code')) {
        postalCode = longName;
      } else if (types.includes('country')) {
        countryName = longName;
        countryCode = shortName;
      }
    });
    setMapZoom(16);
    if (funUpdateInfo) {
      funUpdateInfo({
        ...state,
        lat: lat.toString(),
        long: lng.toString(),
        address: add,
        streetAddress,
        extendedAddress,
        locality,
        region,
        postalCode,
        countryName,
        countryCode,
      });
    }
    return getLatLng(results);
  };

  const [searchedLocations, setSearchedLocations] = useState<string[]>([]);

  useEffect(() => {
    const savedLocations = localStorage.getItem('searchedLocations');
    if (savedLocations) {
      const parsedLocations = JSON.parse(savedLocations);
      if (Array.isArray(parsedLocations)) {
        setSearchedLocations(parsedLocations.slice(-3));
      }
    }
  }, []);

  const handleSelect = async (description: any) => {
    try {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description.description, false);

      const newLocation = description;
      const latestLocations = searchedLocations.concat(newLocation).slice(-3);
      setSearchedLocations(latestLocations);

      localStorage.setItem('searchedLocations', JSON.stringify(latestLocations));

      clearSuggestions();
      const results = await getGeocode({ address: description.description });
      const res = results[0];
      setMapZoom(16);
      const { lat, lng } = await getLatLng(res);
      const add = description.description;
      getLatLangUpdateAddressInfo(res, lat, lng, add);
    } catch (error) {
      console.log('Error:', error);
    }
  };

  const onKeyPressed = (e: any) => {
    if (e.keyCode == '38') {
      if (currentSelectIndex > 0) {
        setCurrentSelectIndex(currentSelectIndex - 1);
      }
    } else if (e.keyCode == '40') {
      if (currentSelectIndex < 4) {
        setCurrentSelectIndex(currentSelectIndex + 1);
      }
    }
    if (e.keyCode == '13') {
      if (data.length > 0) {
        handleSelect(data[currentSelectIndex]);
      }
    }
  };

  const clearSearch = () => {
    setValue('');
    clearSuggestions();
  };

  const [loading, setLoading] = useState(false);

  const successCallback = (position: any) => {
    if (funUpdateInfo) {
      funUpdateInfo({
        ...state,
        lat: position.coords.latitude.toString(),
        long: position.coords.longitude.toString(),
      });
      setUpdateAdress(!updateAdress);
      setLoading(false);
    }
  };

  const errorCallback = (error: any) => {
    setLocationErrorMsg(error.message);
    setOpenLocationError(true);
    setLoading(false);
  };

  const gpsPressed = () => {
    setLoading(true);
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  };

  const renderSuggestions = () => {
    const inputIsEmpty = value.trim() === '';
    const suggestions = inputIsEmpty ? searchedLocations : data;

    return suggestions.map((suggestion, index) => {
      const place_id = typeof suggestion === 'string' ? suggestion : suggestion.place_id;
      const main_text = typeof suggestion === 'string' ? suggestion : suggestion.structured_formatting.main_text;
      const secondary_text = typeof suggestion === 'string' ? '' : suggestion.structured_formatting.secondary_text;

      return (
        <ListItemButton
          component="li"
          key={place_id}
          tabIndex={index}
          selected={currentSelectIndex === index}
          onClick={() => handleSelect(suggestion)}
        >
          <ListItemIcon sx={{ minWidth: '34px' }}>
            <RoomIcon />
          </ListItemIcon>
          <Typography component="label" variant="subtitle2" sx={{ fontWeight: '300' }}>
            {main_text}
            {', '}
            {secondary_text}
          </Typography>
        </ListItemButton>
      );
    });
  };

  const { t } = useTranslation('global');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Box>
        {
          kDisableInsideInput && (
            <Box sx={{
              width: '100%',
              display: kDisableInput ? 'none' : 'inherit',
            }}
            >
              <FormControl
                className="lmi-formcontrol"
                sx={{
                  width: isMobile ? '95%' : '100%',
                  mt: isMobile ? '12px' : '-8px',
                  mb: '16px',
                  mx: isMobile ? 1 : 0,
                  borderWidth: '1px',
                  borderColor: '#e8e8e8',
                  borderRadius: '10px',
                  borderStyle: 'solid',
                  height: '48px',
                  justifyContent: 'center',
                }}
              >
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <RoomOutlinedIcon color="primary" sx={{ ml: 0.5, mr: 0.5 }} />
                  <InputBase
                    onKeyDown={(e: any) => onKeyPressed(e)}
                    onFocus={() => {
                      setTimeout(() => {
                        setIsVisiblePlaces(true);
                      }, 100);
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        setIsVisiblePlaces(false);
                        clearSuggestions();
                      }, 500);
                    }}
                    sx={{
                      input: {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: 'inline-block',
                      },
                    }}
                    placeholder={t('user_session.search_location')}
                    inputProps={{ 'aria-label': 'search google maps', autoComplete: 'off' }}
                    value={isWriting ? value : state?.address ?? ''}
                    name="newAddress"
                    onChange={onChange}
                    fullWidth
                  />
                  <IconButton
                    disabled={value?.length == 0}
                    sx={{ borderRadius: '100%' }}
                    aria-label="clear"
                    onClick={() => clearSearch()}
                  >
                    <HighlightOffOutlinedIcon />
                  </IconButton>

                  <Divider orientation="vertical" flexItem />
                  <IconButton aria-label="clear" onClick={() => gpsPressed()}>
                    <MyLocationIcon />
                  </IconButton>
                </Stack>

              </FormControl>
              {
                isVisiblePlaces
                && (
                  <Paper
                    variant="outlined"
                    sx={{
                      width: '95%',
                      bgcolor: 'background.paper',
                      mt: -1.5,
                      ml: isMobile ? 1 : 0,
                      position: 'absolute',
                      zIndex: 333,
                    }}
                  >
                    <List component="ul">
                      {renderSuggestions()}
                    </List>
                  </Paper>
                )
              }
            </Box>
          )
        }
      </Box>

      <Box sx={{
        opacity: loading ? 0.4 : 1,
        pointerEvents: loading ? 'none' : 'inherit',
        position: 'relative',
      }}
      >
        <Map
          kDraggable
          state={{ lat: state?.lat, long: state?.long }}
          funUpdateInfo={funUpdateInfo}
          zoom={mapZoom}
          usrLat={usrLat}
          usrLng={usrLng}
          kChangeLocation={updateAdress}
          kShowSearchedCircles={kShowSearchedCircles}
          kDisableBorder={kDisableBorder}
          kOverMap={(
            <>
              {
                !kDisableInsideInput && !kDisableInput && (
                  <Paper
                    sx={{
                      p: '10px',
                      display: 'flex',
                      alignItems: 'center',
                      width: { md: '100%', xs: '80%' },
                      maxWidth: 450,
                      backgroundColor: 'white',
                      borderRadius: '10px',
                    }}
                  >
                    <RoomOutlinedIcon color="primary" />
                    <InputBase
                      onKeyDown={(e: any) => onKeyPressed(e)}
                      onFocus={() => {
                        setTimeout(() => {
                          setIsVisiblePlaces(true);
                        }, 100);
                      }}
                      onBlur={() => {
                        setTimeout(() => {
                          setIsVisiblePlaces(false);
                          clearSuggestions();
                        }, 500);
                      }}
                      sx={{
                        ml: 1,
                        flex: 1,
                        input: {
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: 'inline-block',
                        },
                      }}
                      placeholder={t('user_session.search_location')}
                      inputProps={{ 'aria-label': 'search google maps', autoComplete: 'off' }}
                      value={isWriting ? value : state?.address ?? ''}
                      name="newAddress"
                      onChange={onChange}
                      fullWidth
                    />
                    {value ? (
                      <IconButton aria-label="clear" onClick={() => clearSearch()}>
                        <HighlightOffOutlinedIcon />
                      </IconButton>
                    ) : null}
                    <Divider orientation="vertical" flexItem />
                    <IconButton aria-label="clear" onClick={() => gpsPressed()}>
                      <MyLocationIcon />
                    </IconButton>
                  </Paper>
                )
              }
              {/* We can use the "status" to decide whether we should display the dropdown or not */}
              {
                isVisiblePlaces
                && (
                  <Paper
                    variant="outlined"
                    sx={{
                      width: '100%',
                      maxWidth: 450,
                      bgcolor: 'background.paper',
                      mt: 0.5,
                      display: !kDisableInsideInput && !kDisableInput ? 'inherit' : 'none',
                    }}
                  >
                    <List component="ul">
                      {renderSuggestions()}
                    </List>
                  </Paper>
                )
              }
            </>
          )}
        />
        {
          loading && (
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{
                position: 'absolute',
                top: '40%',
                left: '48%',
              }}
            >
              <CircularProgress />
            </Stack>
          )
        }
      </Box>

      <DialogCommon
        kTitle={t(`global.${LocationErrorMsg.trim()}`)}
        kFullScreenMobile={false}
        kEnableBorderRadius
        kBorderRadius="10px"
        kMaxWidth="sm"
        kDisableActions
        kSlideTransition={isMobile}
        kMaxHeight="auto"
        kOpenDialogLists={openLocationError}
        kOnClose={() => setOpenLocationError(false)}
        kContent={(
          <Typography sx={{ textAlign: 'center', fontSize: '18px' }}>
            {t('global.location_blocked_error')}
          </Typography>
        )}
      />
    </>
  );
}

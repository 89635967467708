// import { AddRounded, CameraAltRounded } from '@mui/icons-material';
import { Stack, Box } from '@mui/material';
// import { FileUploader } from 'react-drag-drop-files';
// import { LmiTheme } from '../../../page/BackOffice/ProfilePrivate/Components';
import LmiColorPalette from '../../../themes/lmi-mui-theme/lmi-color-palette';
import { CoverImageModels } from './CoverImage.models';

function CoverImage(props: CoverImageModels) {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        sx={{
          aspectRatio: '5 / 1.5',
          // backgroundImage: `url("${props.kPhoto}")`,
          // backgroundSize: 'cover',
          // backgroundPosition: 'center',
          position: 'relative',
        }}
      >

        {/* {
          props.kEditing
          && (
            (
              !props.kPhoto
              && (
                <Stack
                  onClick={props.kOnClick}
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    width: 'calc(100% - 48px)',
                    height: 'calc(100% - 48px)',
                    bgcolor: LmiTheme.light.systemBackground.base,
                    border: `1px dashed ${LmiTheme.light.systemBackground.tertiary}`,
                    borderRadius: '7px',
                    m: 3,
                    position: 'absolute',
                    cursor: 'pointer',
                  }}
                >
                  <AddRounded onClick={props.kOnClick} sx={{ fontSize: 44, color: LmiTheme.light.accent }} />
                </Stack>
              )
            )
            || (
              props.kPhoto
              && (
                <>
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                      aspectRatio: '4 / 1.5',
                      backgroundImage: `url("${props.kPhoto}")`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      cursor: 'pointer',
                    }}
                  />
                  <Avatar
                    onClick={props.kOnClick}
                    sizes="large"
                    sx={{
                      bgcolor: LmiTheme.light.accent,
                      width: 44,
                      height: 44,
                      m: 3,
                      cursor: 'pointer',
                    }}
                  >
                    <CameraAltRounded />
                  </Avatar>
                </>
              )
            )
          )
        } */}
        {
          // !props.kEditing
          // && (
          //   (
          //     !props.kPhoto
          //     && (
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            sx={{
              width: '100%',
              height: '100%',
              maxHeight: '246px',
              bgcolor: LmiColorPalette.greenScheme[100],
              // borderRadius: '7px',
              position: 'absolute',
              backgroundSize: '170%',
              backgroundPosition: '70% center',
              // backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 xmlns:xlink=%22http://www.w3.org/1999/xlink%22 width=%221966%22 height=%22506%22 viewBox=%220 0 1966 506%22%3E%3Cdefs%3E%3CclipPath id=%22clip-path%22%3E%3Crect id=%22Screen_Shot_2022-03-01_at_6.39.30_PM%22 data-name=%22Screen Shot 2022-03-01 at 6.39.30 PM%22 width=%221966%22 height=%22506%22 transform=%22translate(-704 178)%22 fill=%22red%22/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id=%22Mask_Group_1%22 data-name=%22Mask Group 1%22 transform=%22translate(704 -178)%22 clip-path=%22url(%23clip-path)%22%3E%3Cpath id=%22Path_3%22 data-name=%22Path 3%22 d=%22M6.6 847.812C6.6 515.9 246.467 239.778 561.64 181.205V2.7L904.705 315.084 561.64 624.68V440.6C360.822 501.957 232.521 699.987 263.2 909.173s209.186 362.589 418.372 362.589c211.975 2.789 390.481-150.614 423.95-357.011s-89.253-407.215-290.071-471.366L957.7 315.084 812.663 181.205c315.174 58.572 555.04 334.7 555.04 666.606 0 373.746-304.017 677.763-680.552 677.763C310.617 1522.785 6.6 1221.557 6.6 847.812Z%22 transform=%22translate(114.13 230.494) rotate(-11)%22 fill=%22%23ffffff%22 opacity=%220.5%22/%3E%3Cpath id=%22Path_4%22 data-name=%22Path 4%22 d=%22M6.6 847.812C6.6 515.9 246.467 239.778 561.64 181.205V2.7L904.705 315.084 561.64 624.68V440.6C360.822 501.957 232.521 699.987 263.2 909.173s209.186 362.589 418.372 362.589c211.975 2.789 390.481-150.614 423.95-357.011s-89.253-407.215-290.071-471.366L957.7 315.084 812.663 181.205c315.174 58.572 555.04 334.7 555.04 666.606 0 373.746-304.017 677.763-680.552 677.763C310.617 1522.785 6.6 1221.557 6.6 847.812Z%22 transform=%22translate(-1524.331 -646.809) rotate(-11)%22 fill=%22%23ffffff%22 opacity=%220.5%22/%3E%3C/g%3E%3C/svg%3E")',
              backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%223265.136%22 height=%222631.908%22 viewBox=%220 0 3265.136 2631.908%22%3E%3Cg id=%22Group_3%22 data-name=%22Group 3%22 transform=%22translate(2144.337 -1583.872)%22%3E%3Cpath id=%22Path_5%22 data-name=%22Path 5%22 d=%22M6.6 847.812C6.6 515.9 246.467 239.778 561.64 181.205V2.7L904.705 315.084 561.64 624.68V440.6C360.822 501.957 232.521 699.987 263.2 909.173s209.186 362.589 418.372 362.589c211.975 2.789 390.481-150.614 423.95-357.011s-89.253-407.215-290.071-471.366L957.7 315.084 812.663 181.205c315.174 58.572 555.04 334.7 555.04 666.606 0 373.746-304.017 677.763-680.552 677.763C310.617 1522.785 6.6 1221.557 6.6 847.812Z%22 transform=%22translate(-512.87 2719.494) rotate(-11)%22 fill=%22%23ffffff%22 opacity=%220.5%22/%3E%3Cpath id=%22Path_6%22 data-name=%22Path 6%22 d=%22M6.6 847.812C6.6 515.9 246.467 239.778 561.64 181.205V2.7L904.705 315.084 561.64 624.68V440.6C360.822 501.957 232.521 699.987 263.2 909.173s209.186 362.589 418.372 362.589c211.975 2.789 390.481-150.614 423.95-357.011s-89.253-407.215-290.071-471.366L957.7 315.084 812.663 181.205c315.174 58.572 555.04 334.7 555.04 666.606 0 373.746-304.017 677.763-680.552 677.763C310.617 1522.785 6.6 1221.557 6.6 847.812Z%22 transform=%22translate(-2151.331 1842.191) rotate(-11)%22 fill=%22%23ffffff%22 opacity=%220.5%22/%3E%3C/g%3E%3C/svg%3E")',
            }}
          >
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              {props.kBtnPublicProfile}
            </Box>
          </Stack>
            //   )
            // )
            // || (
            //   props.kPhoto
            //   && (
            //     <Stack
            //       justifyContent="center"
            //       alignItems="center"
            //       sx={{
            //         width: '100%',
            //         height: '100%',
            //         bgcolor: LmiTheme.light.systemBackground.base,
            //         borderRadius: '7px',
            //         position: 'absolute',
            //         backgroundSize: '170%',
            //         backgroundPosition: '70% center',
            //         backgroundImage: `url("${props.kPhoto}")`,
            //       }}
            //     />
            //   )
            // )
          // )
        }
      </Stack>
    </>
  );
}

CoverImage.defaultProps = {
  kEditing: false,
};

export default CoverImage;

import { ClsModGetRentalRoadieDeliveryActivityRuns, ClsModGetShipmentDriver } from '@lendmeit/api_backend_lmi';
import { useReducer } from 'react';
import ApiLMI from '../../services/apilmi';
import DeliveryActivityContext from './deliveryActivityContext';
import DeliveryactivityReducer from './deliveryActivityReducer';
import { DELIVERY_ACTIVITY_SUCCES } from '../../types';
import { roadieShipmentFromJson, preOrderFromJson } from '../../models/delivery/roadie_shipment';
import { roadieDriverFromJson } from '../../models/delivery/roadie_driver';

const DeliveryActivityState = ({ children }: any) => {
  const initialState = {
    items: null,
  };

  const [state, dispatch] = useReducer(DeliveryactivityReducer, initialState);

  const deliveryActivityLoad = async (item: any) => {
    const _api = ApiLMI();

    if (!item.isLenderApproved) {
      const body: ClsModGetRentalRoadieDeliveryActivityRuns = {
        idCatLndRntItem: item.idCatLndRntItem,
      };
      const deliveriesResponse: any = await _api.activityGetRentalRoadieDeliveryActivityRuns(body);

      const deliveries = JSON.parse(deliveriesResponse.data).Response;

      if (item.isCancelled
          || item.isCancelledLender
          || item.isCancelledRenter) {
        deliveries[0].canceled = true;
        deliveries[1].canceled = true;
      } else {
        deliveries[0].canceled = false;
        deliveries[1].canceled = false;
      }
      // d

      const collectDelivery: any = preOrderFromJson(deliveries[0]);
      const returnDelivery: any = preOrderFromJson(deliveries[1]);
      dispatch({
        type: DELIVERY_ACTIVITY_SUCCES,
        payload: {
          collectShipment: collectDelivery,
          returnShipment: returnDelivery,
          collectLatLng: '',
          returnLatLng: '',
        },
      });
    } else {
      const bodyGetShipment: any = {
        shipmentId: item.roadieDeliveryIdCollect,
      };
      const bodyGetReturnShipment: any = {
        shipmentId: item.roadieDeliveryIdReturn,
      };
      const collectDeliveryResp: any = await _api.apiActivityDeliveryRoadieGetShipmentPost(bodyGetShipment);
      const returnDeliveryResp: any = await _api.apiActivityDeliveryRoadieGetShipmentPost(bodyGetReturnShipment);

      const collectDeliveryTwo: any = roadieShipmentFromJson(JSON.parse(collectDeliveryResp.data).Response);
      const returnDeliveryTwo: any = roadieShipmentFromJson(JSON.parse(returnDeliveryResp.data).Response);
      const bodyGetShipmentDriver: ClsModGetShipmentDriver = {
        idCatLndRntItem: item.idCatLndRntItem,
        toc: 'toc',
        roadieDeliveryId: collectDeliveryTwo?.id,
      };
      const bodyGetReturnShipmentDriver: ClsModGetShipmentDriver = {
        idCatLndRntItem: item.idCatLndRntItem,
        toc: 'toc',
        roadieDeliveryId: returnDeliveryTwo.id,
      };
      const roadieCollectDriver: any = await _api.itemGetShipmentDriver(bodyGetShipmentDriver);
      collectDeliveryTwo.driver = roadieDriverFromJson(JSON.parse(roadieCollectDriver.data).Response[0]);

      const roadieReturnDriver: any = await _api.itemGetShipmentDriver(bodyGetReturnShipmentDriver);
      returnDeliveryTwo.driver = roadieDriverFromJson(JSON.parse(roadieReturnDriver.data).Response[0]);

      if (item.isCancelled
          || item.isCancelledLender
          || item.isCancelledRenter) {
        collectDeliveryTwo.canceled = true;
        returnDeliveryTwo.canceled = true;
      } else {
        collectDeliveryTwo.canceled = false;
        returnDeliveryTwo.canceled = false;
      }
      dispatch({
        type: DELIVERY_ACTIVITY_SUCCES,
        payload: {
          collectShipment: collectDeliveryTwo,
          returnShipment: returnDeliveryTwo,
          collectLatLng: '',
          returnLatLng: '',
        },
      });
    }
  };

  console.log(dispatch);
  return (
    <DeliveryActivityContext.Provider
      value={{
        ...state,
        deliveryActivityLoad,
      }}
    >
      {children}

    </DeliveryActivityContext.Provider>
  );
};

export default DeliveryActivityState;

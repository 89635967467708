/* eslint-disable react/jsx-props-no-spreading */
import {
  PickersDay, CalendarPickerSkeleton, StaticDatePicker,
} from '@mui/lab';
import { format } from 'date-fns';
import {
  Badge,
  Box, Button, Divider, List, ListItem, ListItemIcon, ListItemText, Menu, Typography, useMediaQuery, useTheme, Stack,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enUS, es } from 'date-fns/locale';
import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@mui/icons-material/Info';
import moment from 'moment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  useContext, useState,
} from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { toast } from 'react-toastify';
import addEditItemContext from '../../../../../../../context/addEditItem/addEditItemContext';
import LmiTheme from '../../../../../../../themes/lmi-mui-theme/lmi-theme';

export default function BlockedCalendar() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const addEditContextLog = useContext(addEditItemContext);

  const {
    blockedIndDates,
    updateItemBlockedIndDates,
    updateBlockedIndDates,
    unavRentedDates,
  }: any = addEditContextLog;

  const [infoCalendar, setInfoCalendar] = useState(null);
  const open = Boolean(infoCalendar);
  const handleClickInfoCalendar = (event: any) => {
    setInfoCalendar(event.currentTarget);
  };
  const handleCloseInfoCalendar = () => {
    setInfoCalendar(null);
  };

  const initialValue = new Date();

  const [value, setValue] = useState<Date | null>(initialValue);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  let numClicks = 0;
  let singleClickTimer: ReturnType<typeof setTimeout>;
  const handleClick = (day: any) => {
    numClicks += 1;
    if (numClicks === 1) {
      singleClickTimer = setTimeout(() => {
        updateIdnBlockedDaysArray(day);
        numClicks = 0;
      }, 300);
    } else if (numClicks === 2) {
      clearTimeout(singleClickTimer);
      numClicks = 0;
      startRangeSelection(day);
    }
  };

  const removeAllBlockedDates = () => {
    updateItemBlockedIndDates([]);
    updateBlockedIndDates([]);
  };

  const updateIdnBlockedDaysArray = (day: any) => {
    const dayFormatted = moment(day).format('YYYY-MM-DD').toString();
    const indexBlockedDate = blockedIndDates.indexOf(dayFormatted);
    // If clicked date is currently on the blocked dates list ( indexBlockedDate > -1)
    // it gets removed from there, otherwise it is added

    if (indexBlockedDate > -1) {
      blockedIndDates.splice(indexBlockedDate, 1);
      updateItemBlockedIndDates(blockedIndDates);
      updateBlockedIndDates(blockedIndDates);
    } else {
      blockedIndDates.push(moment(day).format('YYYY-MM-DD').toString());
      updateItemBlockedIndDates(blockedIndDates);
      updateBlockedIndDates(blockedIndDates);
      setValue(day);
    }
  };

  const getDaysInBetween = (startDateBet: Date, endDateBet: Date) => {
    const daysArray = [];
    // eslint-disable-next-line no-plusplus
    for (let x = 0; x <= moment(endDateBet).diff(moment(startDateBet), 'days'); x++) {
      const myFutureDate = new Date(startDateBet);
      daysArray.push(myFutureDate.setDate(myFutureDate.getDate() + x + 1));
    }
    return daysArray;
  };

  const startRangeSelection = (day: any) => {
    const dayFormatted = moment(day).format('YYYY-MM-DD').toString();
    try {
      // if there is no start date it goes to else and adds it
      // other wise it adds end date
      if (startDate != '' && endDate == '' && !moment(day, 'YYYY-MM-DD').isBefore(moment(startDate, 'YYYY-MM-DD'))) {
        let hasUnavailableBetween = false;
        // Gets total days between start date and end date selection
        const daysList = getDaysInBetween(new Date(startDate), new Date(dayFormatted));
        if (daysList.length < 365) {
          daysList.forEach((element) => {
            const fD = format(element, 'yyyy-MM-dd');
            let indexElement = -1;

            // check unavailable dates for them to not be clickable
            if (unavRentedDates != null) {
              indexElement = unavRentedDates.indexOf(fD);
            }
            // index element will be greater than 1 if there is an
            // unavailable rented date between both range date selection
            if (indexElement > -1) {
              hasUnavailableBetween = true;
            }
          });
          // If there are no unavailable reserved days in between the selection
          // the new days get highlighted
          if (!hasUnavailableBetween) {
            setEndDate(dayFormatted);
            daysList.forEach((element) => {
              // Check if selected individual date is not one of the previous unavailable dates
              const formattedDate = format(element, 'yyyy-MM-dd');
              let indexElement = -1;

              // check unavailable dates for them to not be clickable

              if (unavRentedDates != null) {
                indexElement = unavRentedDates.indexOf(
                  formattedDate,
                );
              }

              if (indexElement < 0) {
                if (formattedDate != startDate) {
                  blockedIndDates.push(formattedDate);
                  updateItemBlockedIndDates(blockedIndDates);
                  updateBlockedIndDates(blockedIndDates);
                  setStartDate('');
                  setEndDate('');
                }
              }
            });
          } else {
            const indexBlockedDate = blockedIndDates.indexOf(startDate);
            blockedIndDates.splice(indexBlockedDate, 1);
            blockedIndDates.push(dayFormatted);
            updateItemBlockedIndDates(blockedIndDates);
            updateBlockedIndDates(blockedIndDates);
            setStartDate(dayFormatted);
            if (endDate != '') {
              setEndDate('');
            }
          }
        } else {
          toast.error(t('item_detail.block_period'));
        }
      } else if (blockedIndDates.indexOf(dayFormatted) === -1) {
        const stDate = dayFormatted;
        setStartDate(stDate);
        blockedIndDates.push(dayFormatted);
        updateItemBlockedIndDates(blockedIndDates);
        updateBlockedIndDates(blockedIndDates);
        if (endDate != '') {
          setEndDate('');
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const { t } = useTranslation('global');

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box sx={{
          width: '64px',
          display: { xs: 'none', sm: 'flex' },
        }}
        />
        <Typography
          p={2}
          textAlign="center"
          fontWeight={500}
          fontSize="body2.fontSize"
          color={LmiTheme.light.systemBackground.primary}
        >
          {t('item_detail.upcoming_blocked_days_txt')}
          <> </>
          {(blockedIndDates?.length + unavRentedDates?.length) || 0}
          <> </>
          {t('item_detail.upcoming_blocked_days_txt2')}
        </Typography>
        <Button onClick={handleClickInfoCalendar}>
          <InfoIcon fontSize="medium" />
        </Button>
      </Stack>
      <Divider />
      <Box className="lmi--date-picker" sx={{ textTransform: 'capitalize' }}>
        <>
          <LocalizationProvider locale={(cookies.get('i18next') === 'en') ? enUS : es} dateAdapter={AdapterDateFns}>
            <StaticDatePicker
              // displayStaticWrapperAs="desktop"
              toolbarTitle={t('item_detail.select_date')}
              displayStaticWrapperAs={isDesktop ? 'desktop' : 'mobile'}
              value={value}
              loading={false}
              onChange={() => {}}
              renderInput={() => <div />}
              renderLoading={() => <CalendarPickerSkeleton />}
              allowSameDateSelection
              renderDay={(day: any, _value: any, DayComponentProps: any) => {
                let isReservedBeforeToday = false;
                if (moment(day, 'YYYY-MM-DD').isBefore(moment(new Date(), 'YYYY-MM-DD'))) {
                  DayComponentProps.disabled = true;
                  isReservedBeforeToday = blockedIndDates.indexOf(moment(day).format('YYYY-MM-DD').toString()) > -1;
                }

                const isSelected = !DayComponentProps.outsideCurrentMonth
                  && blockedIndDates.indexOf(moment(day).format('YYYY-MM-DD').toString()) > -1
                  && unavRentedDates.indexOf(moment(day).format('YYYY-MM-DD').toString()) == -1;
                // if date is reserved through future rents
                const isReserved = !DayComponentProps.outsideCurrentMonth
                  && unavRentedDates.indexOf(moment(day).format('YYYY-MM-DD').toString()) > -1;
                DayComponentProps.selected = isSelected;
                return (
                  DayComponentProps.disabled
                    ? (
                      <Badge
                        key={day.toString()}
                        overlap="circular"
                        badgeContent={isReservedBeforeToday ? <FiberManualRecordIcon fontSize="small" sx={{ color: LmiTheme.light.systemBackground.tertiary }} /> : undefined}
                      >
                        <PickersDay onClick={() => { handleClick(day); }} {...DayComponentProps} />
                      </Badge>
                    )
                    : (
                      <Badge
                        key={day.toString()}
                        overlap="circular"
                        badgeContent={isReserved ? <FiberManualRecordIcon fontSize="small" color="warning" /> : undefined}
                      >
                        <PickersDay onClick={() => { handleClick(day); }} {...DayComponentProps} />
                      </Badge>
                    )
                );
              }}
            />
          </LocalizationProvider>
        </>
        <Divider />
        <Box my={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            disabled={blockedIndDates.length === 0 ?? true}
            onClick={removeAllBlockedDates}
            size="large"
            sx={{ textTransform: 'none', fontWeight: 700 }}
          >
            {t('item_detail.remove_dates')}
          </Button>
        </Box>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={infoCalendar}
        open={open}
        PaperProps={{
          elevation: 0,
          sx: {
            wordWrap: 'break-word',
            maxWidth: 360,
            minWidth: 200,
            width: 360,
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 26,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        onClose={handleCloseInfoCalendar}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <List
          sx={{
            width: 'inherit',
            bgcolor: 'background.paper',
            px: 1,
          }}
        >
          <ListItem dense disablePadding sx={{ alignItems: 'flex-start' }}>
            <ListItemIcon
              sx={{
                color: LmiTheme.light.accent,
                pt: '2px',
                px: 1,
                minWidth: 'inherit',
              }}
            >
              ●
            </ListItemIcon>
            <ListItemText
              secondary={t('item_detail.activate_deactivate_txt')}
              sx={{
                width: '80px',
              }}
            />
          </ListItem>
          <ListItem dense disablePadding sx={{ alignItems: 'flex-start' }}>
            <ListItemIcon
              sx={{
                color: LmiTheme.light.accent,
                pt: '2px',
                px: 1,
                minWidth: 'inherit',
              }}
            >
              ●
            </ListItemIcon>
            <ListItemText
              secondary={t('item_detail.select_range_txt')}
              sx={{
                width: '80px',
              }}
            />
          </ListItem>
        </List>
      </Menu>
    </>
  );
}

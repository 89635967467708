// import * as React from 'react';
// import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { List } from '@mui/material';
import DialogDelivery from './DialogDelivery';
import { DeliveryOptionsModels } from '../../../BookItemPage/Components/DeliveryOptions/DeliveryOptions.models';
import { NONE, PICKUPBYLENDER, ROADIE } from '../../../../../types';

function DialogDeliveryOptions(props: DeliveryOptionsModels) {
  const {
    roadie,
    changeDeliveryType,
    deliveryType,
    visiblePhoneCode,
  } = props;

  // const [selectedValue, setSelectedValue] = useState('2');
  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSelectedValue(event.target.value);
  // };

  const { t } = useTranslation('global');

  const deliveryList = [
    {
      kId: PICKUPBYLENDER,
      kTitle: `${t('delivery.options.pickup_lender')}`,
      kDescription: `${t('delivery.options.pickup_lender_description')}`,
      kShow: true,
    },
    {
      kId: ROADIE,
      kTitle: `${t('delivery.options.roadie')}`,
      kDescription: `${t('delivery.options.roadie_description')}`,
      kShow: roadie,
    },
    {
      kId: NONE,
      kTitle: `${t('delivery.options.none')}`,
      kDescription: `${t('delivery.options.none_description')}`,
      kShow: true,
    },
  ];

  return (
    <>
      <List sx={{ p: 0 }}>
        {
          /* eslint-disable react/jsx-props-no-spreading */
          deliveryList.map((d, i) => {
            if (d.kId == ROADIE && !visiblePhoneCode) {
              return null;
            }
            return (
              <DialogDelivery
                key={d.kId}
                kValue={i}
                kDivider={i < deliveryList.length - 1 ?? false}
                kChecked={d.kId == deliveryType}
                kOnChange={() => changeDeliveryType(d.kId)}
                kSelectedValue={deliveryType}
                kShowContent={d.kId == deliveryType ?? false}
                {...d}
              />
            );
          })
        }
      </List>
    </>
  );
}

DialogDeliveryOptions.defaultProps = {
  kTitle: 'Delivery',
  kContent: 'false',
};

export default DialogDeliveryOptions;

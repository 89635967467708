import {
  ClsModGetCircleInvitationInfo,
  ClsModGetItemDeleted,
  ClsModGetLenderProfileInfoNewFlow,
  ClsModGetReceivedReviewsAD,
  ClsModGetRentalActivityPaymentMethod, ClsModGetRentalSummary, ClsModGetRntItemStageEvidence,
  ClsModGetUserCircleEdit, ClsModGetUserProfileInfoNewFlowOwn,
  ClsModGetUserTermsAndConditions,
  ClsModSetOpenedUserNotification,
  ClsModValidatesRentalCancellationNewFlow,
} from '@lendmeit/api_backend_lmi/dist/models';
import { useContext, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
// import { t } from 'i18next';
import { MapData } from '../../components/Common/Map/Map.models';
import { UserTermsModel } from '../../page/BackOffice/Listing/Components/AddItem/AddItem.models';
import ApiLMI from '../../services/apilmi';
import {
  ACTIVITY_INFO_LOADED,
  ACTIVITY_HISTORY_INFO_LOADED,
  ACTIVITY_HISTORY_INFO_FILTERED,
  ACTIVITY_INFO_FILTERED,
  ACTIVITY_DETAIL_ITEM_SET,
  ACTIVITY_EXTRA_INFO_LOADED,
  RENTAL_PICKUP_IMAGES_LOADED,
  RENTAL_DROPOFF_IMAGES_LOADED,
  SET_NEW_ACTIVITY_LOCATION,
  ACTIVITY_CIRCLE_JOIN_EXTRA_INFO,
  UNSET_ACTIVITY_ITEM,
  ACTIVITY_CIRCLE_INVITATION_INFO,
} from '../../types';
import publicProfileContext from '../publicProfile/publicProfileContext';
import shuftiProContext from '../shuftiPro/shuftiProContext';
import ActivityContext from './activityContext';
import ActivityReducer from './activityReducer';

const ActivityState = (props: any) => {
  const { t } = useTranslation('global');
  const pProfileContext = useContext(publicProfileContext);
  const {
    privateProfileInfo,
  }: any = pProfileContext;
  const pShuftiProContext = useContext(shuftiProContext);

  const {
    initializeShufti,
    checkConfirmationStatus,
  }: any = pShuftiProContext;

  const initialState = {
    loading: true,
    rentalPaymentMethod: [],
    activityList: [],
  };

  const [state, dispatch] = useReducer(ActivityReducer, initialState);

  const updateActivityListElementRead = async (itemIdNotif: number) => {
    const notifIndex = state.activityList.findIndex((element: any) => element.idDetLndUserNotification === itemIdNotif);

    if (notifIndex > -1) {
      const newActivityList = state.activityList;
      newActivityList[notifIndex].readType = 'read';

      dispatch({
        type: ACTIVITY_INFO_LOADED,
        payload: {
          activityList: newActivityList,
          untouchedActivityList: newActivityList,
        },
      });
    }
  };

  const getActivityItems = async () => {
    const _api = ApiLMI();
    try {
      const { data } = await _api.apiActivityGetUserActivityNotificationsRentalStatusPost({ toc: '' });
      if (typeof data === 'string') {
        const parsedActItList = JSON.parse(data.toString());

        if (parsedActItList.Error == '') {
          let activityItemsList = [];
          if (parsedActItList.Response.length > 0) {
            activityItemsList = parsedActItList.Response;
          }

          dispatch({
            type: ACTIVITY_INFO_LOADED,
            payload: {
              activityList: activityItemsList,
              untouchedActivityList: activityItemsList,
            },
          });
          return activityItemsList;
        }
      }
      return [];
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const checkConfirmationStatusId = async (setTaskDone: any, email: string, userName: string, notifDetail: any) => {
    checkConfirmationStatus(setTaskDone, email, userName, notifDetail);
  };

  const getHistoryActivityItems = async () => {
    const _api = ApiLMI();
    try {
      const { data } = await _api.apiActivityGetUserActivityNotificationsAllReadRentalStatusPost({ toc: '' });
      if (typeof data === 'string') {
        const parsedActItList = JSON.parse(data.toString());

        if (parsedActItList.Error == '') {
          let activityItemsList = [];
          if (parsedActItList.Response.length > 0) {
            activityItemsList = parsedActItList.Response;
          }

          dispatch({
            type: ACTIVITY_HISTORY_INFO_LOADED,
            payload: {
              activityHistoryList: activityItemsList,
              untouchedActivityHistoryList: activityItemsList,
            },
          });
          return activityItemsList;
        }
      }
      return [];
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const filterHistoryNotifs = async (searchQuery: String) => {
    try {
      const historyNotifs = state.untouchedActivityHistoryList;

      let filterFunction;

      switch (searchQuery) {
        case '':
          dispatch({
            type: ACTIVITY_HISTORY_INFO_FILTERED,
            payload: {
              historyFilteredList: historyNotifs,
            },
          });
          return;
        case 'lend':
          filterFunction = (element: any) => element.notifType === 'LEND' && !element.isBuy;
          break;
        case 'rent':
          filterFunction = (element: any) => element.notifType === 'RENT';
          break;
        case 'circles':
          filterFunction = (element: any) => element.notifType === 'CIRCLES';
          break;
        case 'purchase':
          filterFunction = (element: any) => element.isBuy === true || element.notifType == 'Buy item';
          break;
        default:
          filterFunction = (element: any) => {
            const searchQueryLower = searchQuery ? searchQuery.toLowerCase() : '';
            return [
              'name', 'header', 'messageEn', 'notifDate', 'creationDate',
              'fromDate', 'toDate', 'renterName', 'lenderName', 'notifActionType',
            ].some((field) => element[field].toLowerCase().includes(searchQueryLower));
          };
      }

      const items = historyNotifs?.filter(filterFunction);

      dispatch({
        type: ACTIVITY_HISTORY_INFO_FILTERED,
        payload: {
          historyFilteredList: items,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const filterNotifs = async (searchQuery: String) => {
    try {
      const notifs = state.untouchedActivityList;
      let items = [];

      switch (searchQuery) {
        case '':
          items = notifs;
          break;
        case 'lend':
          items = notifs?.filter((element: any) => element.notifType == 'LEND' && !element.isBuy);
          break;
        case 'rent':
          items = notifs?.filter((element: any) => element.notifType == 'RENT');
          break;
        case 'circles':
          items = notifs?.filter((element: any) => element.notifType == 'CIRCLES');
          break;
        case 'purchase':
          items = notifs?.filter((element: any) => element.isBuy == true || element.notifType == 'Buy item');

          break;
        default:
          items = notifs?.filter((element: any) => {
            const searchStr = searchQuery ? searchQuery.toLowerCase() : '';
            return ['name', 'header', 'messageEn', 'notifDate', 'creationDate', 'fromDate', 'toDate', 'renterName', 'lenderName', 'notifActionType']
              .some((field) => element[field].toLowerCase().includes(searchStr));
          });
      }

      dispatch({
        type: ACTIVITY_INFO_FILTERED,
        payload: {
          activityList: state.activityList,
          activityFilteredList: items,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const setActionNotifOpened = async (item: any, makeUnclickable = false) => {
    const _api = ApiLMI();
    try {
      const body: ClsModSetOpenedUserNotification = {
        idCatLndUserNotification: item.idDetLndUserNotification,
      };
      await _api.activitySetOpenedUserNotification(body);
      if (makeUnclickable) {
        updateActivityListElementRead(item.idDetLndUserNotification);
        toast.info(t('activity_detail.activity_moved'));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setDetailActivityItem = async (item: any) => {
    const _api = ApiLMI();
    getActivityExtraInfo(item);

    const bodyGetRntItems: ClsModGetRntItemStageEvidence = {
      idCatLndRntItem: item?.idCatLndRntItem,
      isMine: true,
      type: 1,
    };
    const rentalImages: any = await _api.apiItemGetRntItemStageEvidencePost(bodyGetRntItems);
    const pickUpImages = JSON.parse(rentalImages.data).Response || [];

    const bodyGetRntItemsType2: ClsModGetRntItemStageEvidence = {
      idCatLndRntItem: item?.idCatLndRntItem,
      isMine: true,
      type: 2,
    };
    const rentalImagesType2: any = await _api.apiItemGetRntItemStageEvidencePost(bodyGetRntItemsType2);
    const dropOffImages = JSON.parse(rentalImagesType2.data).Response || [];

    const bodyCanCancelRental: ClsModValidatesRentalCancellationNewFlow = {
      idCatRntItem: item?.idCatLndRntItem,
    };

    const canCancelRental: any = await _api.itemValidatesRentalCancellationNewFlow(bodyCanCancelRental);

    const parsedCanCancel = JSON.parse(canCancelRental.data).Response[0].canCancel;

    const bodyItemDeleted: ClsModGetItemDeleted = {
      toc: '',
      idCatLndItem: item?.idCatLndItem,
    };

    const itemDeleted: any = await _api.apiItemGetItemDeletedPost(bodyItemDeleted);
    const parsedItemDeleted = JSON.parse(itemDeleted?.data).Response[0]?.itemDeleted;

    setActionNotifOpened(item);
    console.log(parsedCanCancel);
    if (item?.roadieDeliveryIdCollect || item?.roadieDeliveryIdReturn) {
      localStorage.setItem('roadieDeliveryIdCollect', item?.roadieDeliveryIdCollect);
      localStorage.setItem('roadieDeliveryIdReturn', item?.roadieDeliveryIdReturn);
    } else {
      localStorage.removeItem('roadieDeliveryIdCollect');
      localStorage.removeItem('roadieDeliveryIdReturn');
    }
    dispatch({
      type: ACTIVITY_DETAIL_ITEM_SET,
      payload: {
        openedActivityItem: item,
        pickUpImages,
        dropOffImages,
        canCancel: parsedCanCancel,
        itemDeleted: parsedItemDeleted,
      },
    });
  };

  const getActivityExtraInfo = async (activityItem: any) => {
    const _api = ApiLMI();
    try {
      if (activityItem) {
        const { idDetLndUserNotification } = activityItem;
        if (idDetLndUserNotification !== 0) {
          const idUserToSearch = activityItem.notifType == 'RENT'
            ? activityItem.idLender
            : activityItem.idRenter;

          const bodyRentalSummary: ClsModGetRentalSummary = {
            idCatLndRntItem: activityItem.idCatLndRntItem,
          };

          const bodyRenterLenderInfo: ClsModGetLenderProfileInfoNewFlow = {
            idCatLndUser: idUserToSearch,
          };

          const bodyReviewAD: ClsModGetReceivedReviewsAD = {
            idCatLender: idUserToSearch,
            idCatLndRntItem: activityItem.idCatLndRntItem,
          };

          const bodyGetPaymentMethod: ClsModGetRentalActivityPaymentMethod = {
            idCatLndRntItem: activityItem.idCatLndRntItem,
          };

          const bodyGetOwnInfo: ClsModGetUserProfileInfoNewFlowOwn = {
            toc: 'nonempty',
          };
          const rentalSummary: any = await _api.itemGetRentalSummary(bodyRentalSummary);

          const rentalAccessories: any = await _api.itemGetRentalAccessories(bodyRentalSummary);

          const renterLenderInfo: any = await _api.userGetLenderProfileInfoNewFlow(bodyRenterLenderInfo);

          const userOwnInfo: any = await _api.userGetUserProfileInfoNewFlowOwn(bodyGetOwnInfo);

          const reviewComment: any = await _api.apiUserGetReceivedReviewsADPost(bodyReviewAD);

          const rentPaymentMethod: any = await _api.apiActivityGetRentalActivityPaymentMethodPost(bodyGetPaymentMethod);

          const userTermsAndConditions: Array<UserTermsModel> = await userGetUserTermsAndConditions(activityItem.idCatLndItem);

          const { data } = await _api.itemGetItemDetailInfo({ idCatLndItem: activityItem.idCatLndItem, idCatLndCircle: 0, toc: 'qwerty' });

          const userOwnInfoResult = JSON.parse(userOwnInfo.data).Response[0] || [];
          const renterLenderInfoResult = JSON.parse(renterLenderInfo.data).Response[0] || [];
          if (!(privateProfileInfo?.lenderVerified
            || privateProfileInfo?.lenderVerifiedShufti
            || privateProfileInfo?.lenderVerifiedStripe
            || privateProfileInfo?.renterVerified
            || privateProfileInfo?.renterVerifiedShufti
            || privateProfileInfo?.renterVerifiedStripe
            || (!(activityItem.notifActionType != 'cancelled' && activityItem.notifActionType != 'rejected'))
          )) {
            await initializeShufti(privateProfileInfo?.email, privateProfileInfo?.userName, privateProfileInfo?.idCatLndUser);
          }
          const obj = JSON.parse(data.toString());
          const bookDetailsData = obj.Response[0];

          dispatch({
            type: ACTIVITY_EXTRA_INFO_LOADED,
            payload: {
              renterLenderInfo: renterLenderInfoResult,
              userOwnInfo: userOwnInfoResult,
              rentalSummary: JSON.parse(rentalSummary.data).Response[0] || [],
              rentalAccessories: JSON.parse(rentalAccessories.data).Response || [],
              rentalReviewComment: JSON.parse(reviewComment.data).Response[0] || [],
              rentalPaymentMethod: JSON.parse(rentPaymentMethod.data).Response[0] || [],
              termsAndConditions: userTermsAndConditions,
              requiresRenterIdVerification: bookDetailsData?.requiresRenterIdVerification,
            },
          });
        }
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const getActivityJoinCircleExtraInfo = async (activityItem: any) => {
    const _api = ApiLMI();
    try {
      const idUserToSearch = activityItem.notifType == 'RENT'
        ? activityItem.idLender
        : activityItem.idRenter;

      const bodyRenterLenderInfo: ClsModGetLenderProfileInfoNewFlow = {
        idCatLndUser: idUserToSearch,
      };

      const renterLenderInfo: any = await _api.userGetLenderProfileInfoNewFlow(bodyRenterLenderInfo);

      const renterLenderInfoResult = JSON.parse(renterLenderInfo.data).Response[0] || [];
      const modGetCircleDetail: ClsModGetUserCircleEdit = {
        idCatLndCircle: activityItem.idCatLndCircle,
      };
      const dataGetCircle: any = await _api.circleGetUserCircleEdit(modGetCircleDetail);

      dispatch({
        type: ACTIVITY_CIRCLE_JOIN_EXTRA_INFO,
        payload: {
          openedActivityItem: activityItem,
          renterLenderInfo: renterLenderInfoResult,
          circleInfo: JSON.parse(dataGetCircle.data).Response[0] || [],
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getActivityCircleInvitation = async (activityItem: any) => {
    const _api = ApiLMI();
    try {
      const modGetCircleInvitationInfo: ClsModGetCircleInvitationInfo = {
        idCatLndCircleInvitation: activityItem.idCatLndCircleInvitation,
      };
      const dataGetCircle: any = await _api.circleGetCircleInvitationInfo(modGetCircleInvitationInfo);
      dispatch({
        type: ACTIVITY_CIRCLE_INVITATION_INFO,
        payload: {
          openedActivityItem: activityItem,
          circleInvitationInfo: JSON.parse(dataGetCircle.data).Response || [],
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  async function userGetUserTermsAndConditions(idCatLndItem: number) {
    const api = ApiLMI();
    const body: ClsModGetUserTermsAndConditions = {
      idCatLndItem,
      ownTerms: 0,
    };
    try {
      const result = await api.userGetUserTermsAndConditions(body);
      const data = JSON.parse(result.data.toString());
      if (data.Error == '') {
        return data.Response;
      }
      return [];
    } catch (e) {
      console.log(e);
      return 0;
    }
  }

  const getRentalImages = async (rentalImageType: number, idCatLndRntItem: number) => {
    const _api = ApiLMI();
    try {
      const bodyGetRntItems: ClsModGetRntItemStageEvidence = {
        idCatLndRntItem,
        isMine: true,
        type: rentalImageType,
      };
      const rentalImages: any = await _api.apiItemGetRntItemStageEvidencePost(bodyGetRntItems);
      if (rentalImageType == 1) {
        dispatch({
          type: RENTAL_PICKUP_IMAGES_LOADED,
          payload: {
            pickUpImages: JSON.parse(rentalImages.data).Response || [],
          },
        });
      } else {
        dispatch({
          type: RENTAL_DROPOFF_IMAGES_LOADED,
          payload: {
            dropOffImages: JSON.parse(rentalImages.data).Response || [],
          },
        });
      }
    } catch (err) {
      console.log(err);
      console.log('error loading rental pictures');
    }
  };

  const updateLocationActivityDetail = async (mapData: MapData) => {
    dispatch({
      type: SET_NEW_ACTIVITY_LOCATION,
      payload: {
        openedActivityItem: {
          ...state.openedActivityItem,
          lat: mapData.lat,
          lon: mapData.long,
          exchangeAddress: mapData.address ?? '',
        },
      },
    });
  };

  const unsetOpenActivityItem = async () => {
    dispatch({
      type: UNSET_ACTIVITY_ITEM,
    });
  };

  return (
    <ActivityContext.Provider
      value={{
        ...state,
        activityList: state.activityList,
        activityHistoryList: state.activityHistoryList,
        historyFilteredList: state.historyFilteredList,
        untouchedActivityHistoryList: state.untouchedActivityHistoryList,
        untouchedActivityList: state.untouchedActivityList,
        openedActivityItem: state.openedActivityItem,
        renterLenderInfo: state.renterLenderInfo,
        rentalSummary: state.rentalSummary,
        rentalAccessories: state.rentalAccessories,
        pickUpImages: state.pickUpImages,
        dropOffImages: state.dropOffImages,
        rentalReviewComment: state.rentalReviewComment,
        rentalPaymentMethod: state.rentalPaymentMethod,
        termsAndConditions: state.termsAndConditions,
        loading: state.loading,
        userOwnInfo: state.userOwnInfo,
        circleInfo: state.circleInfo,
        requiresRenterIdVerification: state.requiresRenterIdVerification,
        getActivityItems,
        getHistoryActivityItems,
        filterHistoryNotifs,
        filterNotifs,
        setActionNotifOpened,
        setDetailActivityItem,
        getActivityExtraInfo,
        getRentalImages,
        updateLocationActivityDetail,
        checkConfirmationStatusId,
        getActivityJoinCircleExtraInfo,
        getActivityCircleInvitation,
        unsetOpenActivityItem,
      }}
    >
      {props.children}
    </ActivityContext.Provider>
  );
};

export default ActivityState;

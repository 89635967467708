/* eslint-disable no-restricted-globals */
import {
  Grid, Typography, FormControlLabel, Checkbox, Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@mui/material/InputAdornment';
import { useContext, useEffect, useState } from 'react';
import { LmiTheme } from '../../../../../../../components/Common';
import InputForm from '../../../../../../../components/Common/InputForm/InputForm';
import addEditItemContext from '../../../../../../../context/addEditItem/addEditItemContext';
import { getKeyValue } from '../../../../../../../services/common';
import publicProfileContext from '../../../../../../../context/publicProfile/publicProfileContext';

export default function AddAccessories(props: any) {
  const addEditContextLog = useContext(addEditItemContext);

  const pProfileContext = useContext(publicProfileContext);
  const {
    privateProfileInfo,
  }: any = pProfileContext;

  const [roadieVisibleFromPhoneCode, setRoadieVisibleFromPhoneCode] = useState(true);

  useEffect(() => {
  // Phone Code validation
    const renterPhoneNumb = privateProfileInfo?.phoneNumber;
    if (renterPhoneNumb?.length > 0) {
      const phoneCode = renterPhoneNumb.slice(0, -10);
      if (phoneCode != '+1') {
        setRoadieVisibleFromPhoneCode(false);
      }
    }
  }, [privateProfileInfo?.phoneNumber]);

  const {
    itemInfo,
    addEditAccessoryInfo,
    updateAccessoryInfo,
    checkAccValidate,
    updateAccValidationStatus,
  }: any = addEditContextLog;
  // Validations
  const [isNameValid, setIsNameValid] = useState(true);
  const [isDescriptionValid, setIsDescriptionValid] = useState(true);
  const [isRCValid, setIsRCValid] = useState(true);
  const [isLengthValid, setIsLengthValid] = useState(true);
  const [isWidthValid, setIsWidthValid] = useState(true);
  const [isHeightValid, setIsHeightValid] = useState(true);
  const [isWeightValid, setIsWeightValid] = useState(true);

  useEffect(() => {
    let isEverythingOk = true;
    if (checkAccValidate) {
      switch (true) {
        case addEditAccessoryInfo.name.length === 0:
          isEverythingOk = false;
          setIsNameValid(false);
          break;
        case addEditAccessoryInfo.dscription.length === 0:
          isEverythingOk = false;
          setIsDescriptionValid(false);
          break;
        case addEditAccessoryInfo.replacementCost === 0:
          isEverythingOk = false;
          setIsRCValid(false);
          break;
        case itemInfo.roadie && addEditAccessoryInfo.length === 0:
          isEverythingOk = false;
          setIsLengthValid(false);
          break;
        case itemInfo.roadie && addEditAccessoryInfo.width === 0:
          isEverythingOk = false;
          setIsWidthValid(false);
          break;
        case itemInfo.roadie && addEditAccessoryInfo.height === 0:
          isEverythingOk = false;
          setIsHeightValid(false);
          break;
        case itemInfo.roadie && addEditAccessoryInfo.weight === 0:
          isEverythingOk = false;
          setIsWeightValid(false);
          break;
        default:
          break;
      }
      updateAccValidationStatus(isEverythingOk);
    }
  }, [checkAccValidate]);

  function formatDeliveryPrice(value: any) {
    const newValue = `${value}`;
    const priceArray: any = newValue.split('.');
    if (parseInt(priceArray[1]) == 0) {
      return parseInt(priceArray[0]);
    }
    return value;
  }

  useEffect(() => {
    const {
      replacementCost, hourlyPrice, hourlyRentalPrice, dailyRentalPrice,
      weeklyPrice, weeklyRentalPrice, monthlyPrice, monthlyRentalPrice,
    } = addEditAccessoryInfo;

    updateAccessoryInfo({
      ...addEditAccessoryInfo,
      replacementCost: formatDeliveryPrice(replacementCost),
      hourlyRentalPrice: formatDeliveryPrice(hourlyPrice != null ? hourlyPrice : hourlyRentalPrice),
      dailyRentalPrice: formatDeliveryPrice(dailyRentalPrice),
      weeklyRentalPrice: formatDeliveryPrice(weeklyPrice != null ? weeklyPrice : weeklyRentalPrice),
      monthlyRentalPrice: formatDeliveryPrice(monthlyPrice != null ? monthlyPrice : monthlyRentalPrice),
    });
  }, [addEditAccessoryInfo.replacementCost]);

  const onChange = (e: any) => {
    const dataType = getKeyValue(addEditAccessoryInfo, e.target.name);

    const fieldValidations = {
      name: setIsNameValid,
      dscription: setIsDescriptionValid,
      replacementCost: setIsRCValid,
      length: setIsLengthValid,
      width: setIsWidthValid,
      height: setIsHeightValid,
      weight: setIsWeightValid,
    };

    const fieldValidation = fieldValidations[e.target.name];
    if (fieldValidation) {
      fieldValidation(e.target.value.length > 0);
      e.target.value = e.target.value.substring(0, 200);
    } else {
      setIsLengthValid(true);
      setIsWidthValid(true);
      setIsHeightValid(true);
      setIsWeightValid(true);
    }

    let value;
    switch (typeof dataType) {
      case 'number':
        value = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
        break;
      case 'boolean':
        value = e.target.checked;
        break;
      default:
        value = e.target.value;
        break;
    }

    updateAccessoryInfo({
      ...addEditAccessoryInfo,
      [e.target.name]: value,
    });
  };

  const updateAccActive = (e: any) => {
    const actve = e.target.checked;

    updateAccessoryInfo({
      ...addEditAccessoryInfo,
      actve,
    });
  };

  const { t } = useTranslation('global');

  return (
    <>
      <InputForm
        id="title"
        label={t('add_item_page.title')}
        helper_text={t('add_item_page.required_field')}
        max_lenght="45"
        kInputCurrentLength={addEditAccessoryInfo?.name?.length}
        kInputMaxLength={45}
        input_params={{
          required: true,
          placeholder: `${t('add_item_page.enter_title_accessory')}`,
          error: !isNameValid,
          name: 'name',
          value: addEditAccessoryInfo?.name != undefined ? addEditAccessoryInfo.name : '',
          onChange,
        }}
        form_control_params={{
          fullWidth: true,
        }}
      />
      <InputForm
        id="description"
        label={t('add_item_page.description')}
        helper_text={t('add_item_page.required_field')}
        max_lenght="200"
        kInputCurrentLength={addEditAccessoryInfo?.dscription?.length}
        kInputMaxLength={200}
        input_params={{
          multiline: true,
          required: true,
          error: !isDescriptionValid,
          rows: '4',
          placeholder: `${t('add_item_page.enter_description_accessory')}`,
          name: 'dscription',
          value: addEditAccessoryInfo?.dscription != undefined ? addEditAccessoryInfo.dscription : '',
          onChange,
        }}
        form_control_params={{
          fullWidth: true,
        }}
      />
      <InputForm
        id="value"
        label={t('add_item_page.accessory_value')}
        helper_text={t('add_item_page.required_field')}
        input_params={{
          required: true,
          placeholder: `${t('add_item_page.enter_value')}`,
          name: 'replacementCost',
          error: !isRCValid,
          value: addEditAccessoryInfo?.replacementCost != undefined ? addEditAccessoryInfo.replacementCost : 'Enter value',
          onChange,
          endAdornment: (<InputAdornment position="end"><Typography color={LmiTheme.light.systemBackground.secondary} fontSize="body2.fontSize">{props.userCurrency}</Typography></InputAdornment>),
        }}
        form_control_params={{
          fullWidth: true,
        }}
      />
      <Typography component="span" fontSize="caption.fontSize" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
      <Typography component="span" fontSize="caption.fontSize" fontWeight={500} color={LmiTheme.light.systemBackground.secondary}>
        {t('add_item_page.rental_price')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3} lg={2}>
          <InputForm
            id="hourly"
            label={t('add_item_page.hourly')}
            kColorLabel={LmiTheme.light.systemBackground.tertiary}
            input_params={{
              placeholder: '0',
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              name: 'hourlyRentalPrice',
              onChange,
              value: addEditAccessoryInfo?.hourlyRentalPrice != undefined ? addEditAccessoryInfo.hourlyRentalPrice : '',
            }}
            form_control_params={{
              fullWidth: true,
              sx: { alignItems: 'center' },
            }}
          />

          <Stack direction="row" justifyContent="center">
            <Typography color={LmiTheme.light.systemBackground.secondary} fontSize="body2.fontSize">{props.userCurrency}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={3} lg={2}>
          <InputForm
            id="daily"
            label={t('add_item_page.daily')}
            kColorLabel={LmiTheme.light.systemBackground.tertiary}
            input_params={{
              placeholder: '0',
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              name: 'dailyRentalPrice',
              value: addEditAccessoryInfo?.dailyRentalPrice != undefined ? addEditAccessoryInfo.dailyRentalPrice : '',
              onChange,
            }}
            form_control_params={{
              fullWidth: true,
              sx: { alignItems: 'center' },
            }}
          />
          <Stack direction="row" justifyContent="center">
            <Typography color={LmiTheme.light.systemBackground.secondary} fontSize="body2.fontSize">{props.userCurrency}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={3} lg={2}>
          <InputForm
            id="weekly"
            label={t('add_item_page.weekly')}
            kColorLabel={LmiTheme.light.systemBackground.tertiary}
            input_params={{
              placeholder: '0',
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              name: 'weeklyRentalPrice',
              value: addEditAccessoryInfo?.weeklyRentalPrice != undefined ? addEditAccessoryInfo.weeklyRentalPrice : '',
              onChange,
            }}
            form_control_params={{
              fullWidth: true,
              sx: { alignItems: 'center' },
            }}
          />
          <Stack direction="row" justifyContent="center">
            <Typography color={LmiTheme.light.systemBackground.secondary} fontSize="body2.fontSize">{props.userCurrency}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={3} lg={2}>
          <InputForm
            id="monthly"
            label={t('add_item_page.monthly')}
            kColorLabel={LmiTheme.light.systemBackground.tertiary}
            input_params={{
              placeholder: '0',
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              name: 'monthlyRentalPrice',
              value: addEditAccessoryInfo?.monthlyRentalPrice != undefined ? addEditAccessoryInfo.monthlyRentalPrice : '',
              onChange,
            }}
            form_control_params={{
              fullWidth: true,
              sx: { alignItems: 'center' },
            }}
          />
          <Stack direction="row" justifyContent="center">
            <Typography color={LmiTheme.light.systemBackground.secondary} fontSize="body2.fontSize">{props.userCurrency}</Typography>
          </Stack>
        </Grid>
      </Grid>
      {roadieVisibleFromPhoneCode && (
      <Typography fontSize="subtitle2.fontSize" pt={3} color={LmiTheme.light.systemBackground.tertiary}>
        {t('add_item_page.case_roadie')}
      </Typography>
      )}
      {roadieVisibleFromPhoneCode && (
      <Grid container spacing={2}>
        <Grid item xs={3} lg={2}>
          <InputForm
            id="lenght"
            label={t('add_item_page.length')}
            helper_text={t('add_item_page.required')}
            input_params={{
              placeholder: '0',
              name: 'length',
              error: itemInfo.roadie ? !isLengthValid : false,
              value: addEditAccessoryInfo?.length != undefined ? addEditAccessoryInfo.length : '',
              onChange,
            }}
            form_control_params={{
              fullWidth: true,
              sx: { alignItems: 'center' },
            }}
          />
          <Typography
            color={LmiTheme.light.systemBackground.tertiary}
            textAlign="center"
            fontSize="caption.fontSize"
            fontWeight={500}
          >
            {t('add_item_page.inches')}

          </Typography>
        </Grid>
        <Grid item xs={3} lg={2}>
          <InputForm
            id="width"
            label={t('add_item_page.width')}
            helper_text={t('add_item_page.required')}
            input_params={{
              placeholder: '0',
              name: 'width',
              error: itemInfo.roadie ? !isWidthValid : false,
              value: addEditAccessoryInfo?.width != undefined ? addEditAccessoryInfo.width : '',
              onChange,
            }}
            form_control_params={{
              fullWidth: true,
              sx: { alignItems: 'center' },
            }}
          />
          <Typography
            color={LmiTheme.light.systemBackground.tertiary}
            textAlign="center"
            fontSize="caption.fontSize"
            fontWeight={500}
          >
            {t('add_item_page.inches')}

          </Typography>
        </Grid>
        <Grid item xs={3} lg={2}>
          <InputForm
            id="height"
            label={t('add_item_page.height')}
            helper_text={t('add_item_page.required')}
            input_params={{
              placeholder: '0',
              name: 'height',
              error: itemInfo.roadie ? !isHeightValid : false,
              value: addEditAccessoryInfo?.height != undefined ? addEditAccessoryInfo.height : '',
              onChange,
            }}
            form_control_params={{
              fullWidth: true,
              sx: { alignItems: 'center' },
            }}
          />
          <Typography
            color={LmiTheme.light.systemBackground.tertiary}
            textAlign="center"
            fontSize="caption.fontSize"
            fontWeight={500}
          >
            {t('add_item_page.inches')}

          </Typography>
        </Grid>
        <Grid item xs={3} lg={2}>
          <InputForm
            id="weight"
            label={t('add_item_page.weight')}
            helper_text={t('add_item_page.required')}
            input_params={{
              placeholder: '0',
              name: 'weight',
              error: itemInfo.roadie ? !isWeightValid : false,
              value: addEditAccessoryInfo?.weight != undefined ? addEditAccessoryInfo.weight : '',
              onChange,
            }}
            form_control_params={{
              fullWidth: true,
              sx: { alignItems: 'center' },
            }}
          />
          <Typography color={LmiTheme.light.systemBackground.tertiary} textAlign="center" fontSize="caption.fontSize" fontWeight={500}>{t('add_item_page.pounds')}</Typography>
        </Grid>
      </Grid>
      )}
      <FormControlLabel
        control={(
          <Checkbox
            checked={addEditAccessoryInfo.actve}
            onChange={(e: any) => { updateAccActive(e); }}
          />
        )}
        label={t('global.active')}
      />
    </>
  );
}

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ListItem,
  ListItemText,
  Radio,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { LmiTheme } from '..';
import { PICKUPBYLENDER, ROADIE } from '../../../../../types';
import { DialogDeliveryModels } from './DialogDelivery.models';
import DialogDeliveryByLender from './DialogDeliveryByLender';
import DialogDeliveryByRoadie from './DialogDeliveryByRoadie';

function DialogDelivery(props: DialogDeliveryModels) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Box p={0} m={0} sx={{ background: props.kShowContent !== true ? LmiTheme.light.systemBackground.base : 'transparent' }}>
        <ListItem
          dense
          key={props.kId}
          disablePadding
          divider={props.kDivider}
          sx={{
            py: 1,
            pl: isMobile ? 2 : 4,
            pr: isMobile ? 1 : 3,
          }}
        >
          <ListItemText
            primaryTypographyProps={{ fontWeight: '500', fontSize: 'subtitle1.fontSize' }}
            secondaryTypographyProps={{ color: LmiTheme.light.systemBackground.tertiary, fontSize: 'subtitle2.fontSize' }}
            primary={props.kTitle}
            secondary={props.kDescription}
          />
          <Radio
            checked={props.kChecked}
            onChange={props.kOnChange}
            value={props.kValue}
            name="delivery-options"
          />
        </ListItem>
        <Box my={0}>
          <Accordion disableGutters elevation={0} expanded={props.kSelectedValue === PICKUPBYLENDER ?? false}>
            <AccordionSummary sx={{ display: 'none', p: 0 }} />
            <AccordionDetails sx={{ p: 0 }}>
              {
                props.kShowContent === true && (
                  props.kSelectedValue === PICKUPBYLENDER && <DialogDeliveryByLender />
                )
              }
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box my={0}>
          <Accordion disableGutters elevation={0} expanded={props.kSelectedValue === ROADIE ?? false}>
            <AccordionSummary sx={{ display: 'none', p: 0 }} />
            <AccordionDetails sx={{ p: 0 }}>
              {
                props.kShowContent === true && (
                  props.kSelectedValue === ROADIE
                   && <DialogDeliveryByRoadie />
                )
              }
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </>
  );
}

export default DialogDelivery;

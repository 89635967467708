import { MoreHorizRounded } from '@mui/icons-material';
import {
  // Box,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
// import { colors } from 'unique-names-generator';
import {
  CardAmex,
  CardCirrus,
  CardDiscover,
  CardJcb,
  CardMaestro,
  CardMastercard,
  CardPaypal,
  CardVisa,
  LmiTheme,
} from '..';
import { PaymentMethodSelectItemModels } from './PaymentMethodSelectItem.models';

function PaymentMethodSelectItem(props: PaymentMethodSelectItemModels) {
  const { t } = useTranslation('global');
  return (
    <>
      <Paper
        variant="outlined"
        sx={{
          mx: 1,
          my: 2,
          borderColor: props.kPaymentSelectedIndex
            ? LmiTheme.light.secondaryColor.orange
            : LmiTheme.light.systemBackground.tertiary,
          borderRadius: '10px',
        }}
      >
        <ListItem
          onClick={() => {
            if (props.kIsCheckout) {
              props.kOnClickCard();
            }
          }}
          disablePadding
          divider={props.kDivider}
          secondaryAction={
            (
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
              >
                {
                  props.brand !== undefined
                  && (
                    (props.brand.toLowerCase() === 'american express' && <CardAmex width={56} height={56} style={{ marginRight: 16 }} />)
                    || (props.brand.toLowerCase() === 'cirrus' && <CardCirrus width={56} height={56} style={{ marginRight: 16 }} />)
                    || (props.brand.toLowerCase() === 'discover' && <CardDiscover width={56} height={56} style={{ marginRight: 16 }} />)
                    || (props.brand.toLowerCase() === 'jcb' && <CardJcb width={56} height={56} style={{ marginRight: 16 }} />)
                    || (props.brand.toLowerCase() === 'maestro' && <CardMaestro width={56} height={56} style={{ marginRight: 16 }} />)
                    || (props.brand.toLowerCase() === 'mastercard' && <CardMastercard width={56} height={56} style={{ marginRight: 16 }} />)
                    || (props.brand.toLowerCase() === 'paypal' && <CardPaypal width={56} height={56} style={{ marginRight: 16 }} />)
                    || (props.brand.toLowerCase() === 'visa' && <CardVisa width={56} height={56} style={{ marginRight: 16 }} />)
                  )
                }
                {props.kIsCheckout
                  ?? (
                    <IconButton color="primary" onClick={props.kOnClick}>
                      <MoreHorizRounded />
                    </IconButton>
                  )}
              </Stack>
            )
          }
          sx={{
            py: 1,
            '&& .Mui-selected, && .Mui-selected:hover': {
              bgcolor: 'transparent',
              cursor: 'default',
            },
            '& .MuiListItemButton-root:hover': {
              bgcolor: 'transparent',
              cursor: 'default',
            },
            '&& .Mui-selected, && span': {
              bgcolor: 'transparent',
            },
          }}
        >
          <ListItemButton
            selected={props.kPaymentSelectedIndex}
            onClick={props.kHandlePaymentSelected}
            disabled={!props.valid}
          >
            {
              props.kListItemIcon !== undefined
              && (
                <ListItemIcon sx={{ color: props.kListItemIconColor }}>{props.kListItemIcon}</ListItemIcon>
              )
            }
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <ListItemText
                primaryTypographyProps={{ color: LmiTheme.light.systemBackground.primary, fontSize: 'subtitle1.fontSize' }}
                secondaryTypographyProps={{
                  color: props.valid === false ? LmiTheme.light.dialog.danger : LmiTheme.light.systemBackground.tertiary,
                  fontSize: 'subtitle2.fontSize',
                  fontStyle: 'italic',
                }}
                primary={`···· ···· ···· ${props.last4}`}
                secondary={!props.valid ? `${t('payment_method.expired')}` : `${t('payment_method.valid')}`}
              />
            </Stack>
          </ListItemButton>
        </ListItem>
      </Paper>
    </>
  );
}

PaymentMethodSelectItem.defaultProps = {
  kDivider: true,
  kListItemIcon: undefined,
  kListItemIconColor: undefined,
  kAvatarVariant: undefined,
  kAvatarAlt: 'Avatar',
  kAvatarImageSrc: undefined,
  kAvatarWidth: 44,
  kAvatarHeight: 44,
  kPrimaryText: '',
  kSecondaryText: '',
  kPrice: undefined,
  kPriceType: undefined,
};

export default PaymentMethodSelectItem;

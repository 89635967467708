import {
  Box, Typography,
} from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LmiTheme } from '../../../../../../components/Common';
import MyCirclesListingContext from '../../../../../../context/myCirclesListing/myCirclesListingContext';

export default function CircleSpecialFeeDetail() {
  const {
    circle,
  }: any = useContext(MyCirclesListingContext);

  const { t } = useTranslation('global');

  return (
    <Box>
      <Typography
        fontSize="16px"
        color={LmiTheme.light.systemBackground.tertiary}
        sx={{
          padding: '16px 30px',
        }}
      >
        {
          (circle?.circleFee > 0.00 && circle?.isCircleFeeActive) ? (
            <>
              {t('circle_detail.has_fee_text1')}
              {' '}
              {circle?.circleFee}
              {' '}
              {t('circle_detail.has_fee_text2')}
            </>
          ) : (
            <>
              {t('circle_detail.not_fee')}
            </>
          )
        }

      </Typography>
    </Box>
  );
}

import React, { useState } from 'react';
import {
  AddRounded,
  BackspaceRounded,
  Star,
  StarRounded,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import {
  List,
  Button,
  ListItemIcon,
  Divider,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import { LmiTheme } from '..';
import { PaymentMethodSelectModels } from './PaymentMethodSelect.models';
import PaymentMethodSelectItem from './PaymentMethodSelectItem';

function PaymentMethodSelect(props: PaymentMethodSelectModels) {
  const [menuItemIdClick, setMenuItemIdClick] = useState(0);

  const [menuOptnsAnchorEl, setmenuOptnsAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const menuOptnsOpenOnClick = (event: React.MouseEvent<HTMLButtonElement>, idCatLndUserPaymentMethod: number) => {
    setMenuItemIdClick(idCatLndUserPaymentMethod);
    setmenuOptnsAnchorEl(event.currentTarget);
  };
  const menuOptnsCloseOnClick = () => {
    setmenuOptnsAnchorEl(null);
  };

  const clickCard = (event: React.MouseEvent<HTMLButtonElement>, idCatLndUserPaymentMethod: number) => {
    console.log(event);
    setMenuItemIdClick(idCatLndUserPaymentMethod);
    props?.kUpdateDefault?.(idCatLndUserPaymentMethod);
  };

  const menuOptnsOpen = Boolean(menuOptnsAnchorEl);
  const menuOptnsId = menuOptnsOpen ? 'Payment ID' : undefined;
  const deliveryList = props.kPaymentMethods;
  const totalCards: any = props?.kTotalValidCards;
  const { t } = useTranslation('global');

  return (
    <>
      <List>
        {
          /* eslint-disable react/jsx-props-no-spreading */
          deliveryList?.filter((r: any) => (props.deletePaymenteTmp ? (props.deletePaymenteTmp
            .indexOf(r.idCatLndUserPaymentMethod) == -1) : true))
            .map((l: any) => (
              <PaymentMethodSelectItem
                key={l?.idCatLndUserPaymentMethod}
                kOnClick={(e: any) => (menuOptnsOpenOnClick(e, l?.idCatLndUserPaymentMethod))}
                kOnClickCard={(e: any) => (clickCard(e, l?.idCatLndUserPaymentMethod))}
                kPaymentSelectedIndex={props.defaulPaymenteTmp && props.defaulPaymenteTmp > 0 ? props.defaulPaymenteTmp == l?.idCatLndUserPaymentMethod : l?.defaultCard}
                kDivider={false}
                kIsCheckout={props.kIsCheckout}
                kListItemIcon={<Star />}
                kAvatarVariant="rounded"
                kListItemIconColor={
                  (props.defaulPaymenteTmp && props.defaulPaymenteTmp > 0 ? props.defaulPaymenteTmp == l?.idCatLndUserPaymentMethod : l.defaultCard)
                    ? LmiTheme.light.secondaryColor.orange
                    : LmiTheme.light.systemBackground.tertiary
                }
                {...l}
              />
            ))
        }
      </List>
      {(totalCards != undefined && totalCards < 5) ? (
        <Stack mb={1}>
          <Button
            disableElevation
            size="large"
            variant="outlined"
            onClick={props.kOnClick}
            sx={{
              mx: 'auto',
              textTransform: 'none',
              borderRadius: '10px',
            }}
          >
            <AddRounded />
            {t('payment_method.add_payment_method')}
          </Button>
        </Stack>
      ) : null}
      <Menu
        anchorEl={menuOptnsAnchorEl}
        id={menuOptnsId}
        open={menuOptnsOpen}
        onClose={menuOptnsCloseOnClick}
        onClick={menuOptnsCloseOnClick}
        PaperProps={{
          elevation: 0,
          sx: {
            minWidth: 200,
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => props?.kUpdateDefault?.(menuItemIdClick)}>
          <ListItemIcon>
            <StarRounded />
          </ListItemIcon>
          {t('payment_method.default')}
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => props?.kDeletePayment?.(menuItemIdClick)}>
          <ListItemIcon>
            <BackspaceRounded />
          </ListItemIcon>
          {t('payment_method.delete')}
        </MenuItem>
      </Menu>
    </>
  );
}

export default PaymentMethodSelect;

import { LmiTheme } from '../../..';

const BtnTranslationStyle = {
  boxShadow: 'none',
  backgroundColor: 'white',
  cursor: 'pointer',
  color: LmiTheme.light.accent,
  border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
  display: { sm: 'flex', xs: 'none' },
  height: '44px',
  width: '44px',
};
export default BtnTranslationStyle;

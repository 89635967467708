import { LmiTheme } from '../../../../../components/Common';

// eslint-disable-next-line import/prefer-default-export
export const OtherListingsCarouselStyle = {
  backgroundColor: 'white',
  overflow: 'hidden',
  width: '100%',

  '.lmiw-activity-swiper': {
    borderRadius: '10px',

    '.lmiw-swiper-image': {
      width: '100%',
      height: 160,
      cursor: 'pointer',
      borderRadius: '10px',

    },
    '.prev, .next': {
      display: { xs: 'none', sm: 'flex' },
      width: 45,
      height: 45,
      fontWeight: 400,
      fontSize: 20,
      boxShadow: 'none',
      backgroundColor: 'white',
      textTransform: 'capitalize',
      color: LmiTheme.light.accent,
      border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
      '&.MuiButton-outlined': {
        borderRadius: 2,
        px: { xs: 2, sm: 4 },
        height: { xs: 44, sm: 56 },
      },
    },
  },
};

import {
  Box,
  CardMedia,
  Stack,
} from '@mui/material';
import {
  LmiTheme,
} from '..';
import { EmptyStateModels } from './EmptyState.models';

export default function EmptyState(props: EmptyStateModels) {
  return (
    <Stack
      direction="column"
      justifyContent={props.kContentStart ? 'start' : 'center'}
      alignItems="center"
      height={{ xs: props.kHeightAuto ? 'auto' : 'calc(100vh - 80px)', md: '100%' }}
      spacing={props.kDisableSpacing ? 0 : 2}
    >
      <CardMedia
        component="img"
        alt="Lend"
        image={props.kImage}
        sx={{ maxWidth: '400px', width: { sm: '80%', xs: '100%' } }}
      />
      <Box
        sx={{
          fontSize: props.kTitleFontSize ? props.kTitleFontSize : 24,
          fontWeight: 'bold',
          pt: 2,
          maxWidth: { xs: '70%', md: '90%' },
          textAlign: 'center',
        }}
      >
        {props.kTitle}
      </Box>
      <Box
        sx={{
          fontSize: props.kDescriptionFontSize ? props.kDescriptionFontSize : 20,
          fontWeight: 400,
          color: LmiTheme.light.systemBackground.tertiary,
          maxWidth: { xs: '60%', md: '80%' },
          width: props.kDescriptionWidth ? props.kDescriptionWidth : 'inherit',
          textAlign: 'center',
        }}
      >
        {props.kDescription}
        <br />
        <br />
        {props.kDescription2}
      </Box>
      <Box pt={2}>
        {props.kAction}
      </Box>

    </Stack>
  );
}

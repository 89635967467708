import { useState, useEffect } from 'react';
// import { Dialog } from '@mui/material';
import {
  Box, CardMedia,
  useMediaQuery, useTheme,
} from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import SwiperCore, {
  Pagination, Navigation, Thumbs, Controller,
} from 'swiper';
import { LightBoxModels } from './Lightbox.models';
import { ActivitySwiperModalStyle } from '../../../page/BackOffice/Activity/ActivityDetail/Components/ActivitySwiper/ActivitySwiper.style';
import 'swiper/swiper-bundle.min.css';
// eslint-disable-next-line import/no-useless-path-segments
import { DialogCommon } from '../../../components/Common';

SwiperCore.use([Pagination, Navigation, Thumbs, Pagination]);
const Lightbox = (props: LightBoxModels) => {
  const {
    imageIndex,
    openImageScreen,
    handleOpenLightBox,
    images,
  } = props;
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [mainSwiper, setMainSwiper] = useState<any>(null);
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
  const [arrayImages, setArrayImages] = useState<any>(null);
  const { t } = useTranslation('global');

  const MainParams = {
    modules: [Navigation, Thumbs, Pagination, Controller],
    watchSlidesProgress: true,
    spaceBetween: 10,
    slidesPerView: 1,
    navigation: !isTablet && images.length > 1,
    pagination: isTablet,
    thumbs: {
      swiper: thumbsSwiper,
    },
    onSwiper: (data: any) => setMainSwiper(data),
  };
  mainSwiper?.slideTo(imageIndex, 0, false);
  thumbsSwiper?.slideTo(imageIndex, 0, false);

  const thumbnailsParams = {
    spaceBetween: 10,
    slidesPerView: 8,
    watchSlidesProgress: true,

    onSwiper: (data: any) => { setThumbsSwiper(data); },
    breakpoints: {

      1200: {
        slidesPerView: 8,
      },
      900: {
        slidesPerView: 6,
      },
      700: {
        slidesPerView: 8,
      },
      600: {
        slidesPerView: 6,
      },
      400: {
        slidesPerView: 4,
      },
      1: {
        slidesPerView: 3,
      },
    },
  };
  useEffect(() => {
    if (images) {
      setArrayImages(images);
      console.log(arrayImages);
    }
    console.log(arrayImages);
  }, [images]);

  return (
    <DialogCommon
      kTitle={`${t('global.images')}`}
      kMaxWidth="sm"
      kOpenDialogLists={openImageScreen}
      kOnClose={handleOpenLightBox}
      kDisableActions
      kContent={(
        <Box sx={ActivitySwiperModalStyle}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Swiper {...MainParams} className="lmiw-c-swiper lmiw-c-swiper--main">
            {images && images?.length > 0
              ? images.map((data: any) => (
                ((data.videoPath != '' && data.videoPath != undefined) || data?.base64?.toString().includes('data:video/mp4'))
                  ? (
                    <SwiperSlide
                      key={data.idDetLndItemImage}
                      className="__svideo"
                      style={{ aspectRatio: '5 / 4' }}
                    >
                      <CardMedia
                        component="video"
                        controls
                        height="500"
                        src={data.videoPath != undefined ? data.videoPath : data.base64}
                        sx={{
                          width: '100%',
                          height: '100%',
                        }}
                      />
                    </SwiperSlide>
                  )
                  : (
                    <SwiperSlide
                      key={data.idDetLndItemImage}
                      className="__image"
                      style={{
                        backgroundImage: `url(${data.imgPath != undefined ? data.imgPath : data})`,
                        // backgroundImage: `url(${preview})`,
                        aspectRatio: '5 / 4',
                        cursor: 'pointer',
                      }}
                    />
                  )

              )) : null}
            {!images.length
              ? (
                <SwiperSlide
                  key="0"
                  className="__none"
                  slot="wrapper-end"
                />
              ) : ''}
          </Swiper>
          {/* eslint-disable-next-line */}
          <Swiper {...thumbnailsParams} className="lmiw-c-swiper lmiw-c-swiper--thumbnail">
            {images && images?.length > 0
              ? images.map((data: any, index: number) => (
                ((data.videoPath != '' && data.videoPath != undefined) || data?.base64?.toString().includes('data:video/mp4'))
                  ? (
                    <SwiperSlide
                      key={data.idDetLndItemImage}
                      className={`__video ${index == 0 ? 'swiper-slide-thumb-active' : ''}`}
                      style={{ aspectRatio: '5 / 4', backgroundColor: 'black' }}
                    >
                      <CardMedia
                        component="video"
                        height="500"
                        src={data.videoPath != undefined ? data.videoPath : data.base64}
                        sx={{
                          width: '100%',
                          height: '100%',
                        }}
                      />
                    </SwiperSlide>
                  )
                  : (
                    <SwiperSlide
                      key={data.idDetLndItemImage}
                      className={`__image ${index == 0 ? 'swiper-slide-thumb-active' : ''}`}
                      style={{
                        backgroundImage: `url(${data.imgPath != undefined ? data.imgPath : data})`,
                        aspectRatio: '5 / 4',
                      }}
                    />
                  )
              )) : null}

          </Swiper>
        </Box>

      )}
    />
  );
};

export default Lightbox;

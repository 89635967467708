import LmiTheme from '../../../../../themes/lmi-mui-theme/lmi-theme';

const FindOrListAnythingStyle = {
  img: {
    borderRadius: '7px',
    border: `1px solid ${LmiTheme.light.systemBackground.solid}`,
    cursor: 'pointer',
  },
  h6: {
    fontSize: { xs: '12px', sm: '20px' },
  },
};

export default FindOrListAnythingStyle;

import LmiTheme from '../../../themes/lmi-mui-theme/lmi-theme';

const style = {
  backgroundColor: 'white',
  marginTop: { md: 6, xs: 15 },
  h1: {
    fontSize: '22px',
    fontWeight: '700',
    color: LmiTheme.light.systemBackground.primary,
    textTransform: 'uppercase',
    marginBottom: '1.5',
  },
  h3: {
    fontSize: '16px',
    fontWeight: '700',
    color: LmiTheme.light.systemBackground.primary,
    textTransform: 'uppercase',
    marginBottom: '1.5',
  },
  'ul, ol': {
    paddingInlineStart: ' 10px',
    li: {
      paddingInlineStart: ' 10px',
    },
  },
  'p, ul, ol': {
    fontSize: '16px',
    fontWeight: '400',
    color: LmiTheme.light.systemBackground.primary,
    textAlign: 'justify',
    lineHeight: '1.5',
  },

  a: {
    color: LmiTheme.light.accent,
  },

};

export default style;

import {
  ListItem,
  Avatar,
  Typography,
  Card,
  CardHeader,
  CardContent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ThumbUpRounded, ThumbDownRounded } from '@mui/icons-material';
import { ReviewsItemModels } from './ReviewsItem.models';
import { LmiTheme } from '..';

function ReviewsItem(props: ReviewsItemModels) {
  const splitDay = props.kReviewDate?.split(' ')[0];
  const splitMonth = props.kReviewDate?.split(' ')[1];
  const splitYear = props.kReviewDate?.split(' ')[2];

  const { t } = useTranslation('global');

  return (
    <>
      <ListItem
        disablePadding
        divider={props.kDivider}
        sx={{ px: 0, pt: 2 }}
      >
        <Card
          sx={{
            width: '100%',
          }}
          elevation={0}
        >
          <CardHeader
            sx={{ px: 0, py: 0 }}
            avatar={
              (
                <Avatar
                  alt={props.kLenderName}
                  src={props.kLenderPhoto !== '' || null || undefined ? props.kLenderPhoto : props.kLenderName}
                  sx={{ bgcolor: LmiTheme.light.systemBackground, width: 30, height: 30 }}
                />
              )
            }
            action={
              (
                <Avatar
                  onClick={undefined}
                  sx={{
                    mt: 1,
                    mr: 1,
                    bgcolor: props.kReviewLike == 1 ? LmiTheme.light.systemBackground : LmiTheme.light.secondaryColor.green,
                    width: 30,
                    height: 30,
                  }}
                >
                  {
                    props.kReviewLike == 1
                      ? <ThumbDownRounded sx={{ color: LmiTheme.light.systemBackground.primaryIverted, fontSize: 14 }} />
                      : <ThumbUpRounded sx={{ color: LmiTheme.light.systemBackground.primaryIverted, fontSize: 14 }} />
                  }
                </Avatar>
              )
            }
            title={
              (
                <>
                  <Typography component="span" variant="subtitle1" color={LmiTheme.light.systemBackground.primary}>
                    {props.kLenderName}
                  </Typography>
                  <Typography component="span" variant="subtitle2" color={LmiTheme.light.systemBackground.tertiary} pl={2}>
                    {splitDay}
                    {' '}
                    {t(`months.${splitMonth}`)}
                    {' '}
                    {splitYear}
                  </Typography>
                </>
              )
            }
          />
          <CardContent sx={{ px: 0, py: 1 }}>
            <Typography sx={{ lineBreak: 'anywhere' }} variant="body2" color={LmiTheme.light.systemBackground.secondary}>
              {props.kReviewDescription}
            </Typography>
          </CardContent>
        </Card>
      </ListItem>
    </>
  );
}

ReviewsItem.defaulktProps = {
  kDivider: true,
  kLenderPhoto: 'kLenderPhoto',
  kLenderName: 'kLenderName',
  kReviewDate: 'kReviewDate',
  kReviewLike: 5,
  kReviewDescription: 'kReviewDescription',
};

export default ReviewsItem;

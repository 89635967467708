import { Divider, useMediaQuery, useTheme } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import React, { useState } from 'react';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import DialogCommonHeader from './Components/DialogHeader/DialogHeader';
import DialogCommonActions from './Components/DialogActions/DialogActions';
import DialogCommonContent from './Components/DialogContent/DialogContent';
import { DialogCommonModels } from './DialogCommon.models';

const Transition = React.forwardRef((
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
// eslint-disable-next-line react/jsx-props-no-spreading
) => <Slide direction="up" ref={ref} {...props} />);
function DialogCommon(props: DialogCommonModels) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const maxHeight = (isMobile && window.matchMedia('(orientation: landscape)').matches)
    ? '100%' : props.kMaxHeight;
  const [scroll] = useState<DialogProps['scroll']>('paper');

  // eslint-disable-next-line no-unused-expressions
  const slideTransition = props.kSlideTransition;

  return (
    <>
      <Dialog
        PaperProps={{
          // eslint-disable-next-line no-nested-ternary
          style: { borderRadius: isMobile ? props.kEnableBorderRadius ? props.kBorderRadius : 0 : props.kBorderRadius },
        }}
        keepMounted
        TransitionComponent={slideTransition ? Transition : undefined}
        fullWidth
        fullScreen={isMobile ? props.kFullScreenMobile : props.kFullScreenDesktop}
        maxWidth={props.kMaxWidth}
        open={props.kOpenDialogLists}
        scroll={scroll}
        onClose={props.kOnClose}
        sx={{
          '.MuiDialog-paper': {
            overflow: 'visible',
            height: props.kHeight ? props.kHeight : maxHeight,
            transition: '0.25s',
            maxHeight: (isMobile && window.matchMedia('(orientation: landscape)').matches)
              ? props.kMaxHeight : '100%',
            position: isMobile ? props.kFixedDialogPosition : 'inherit',
            bottom: isMobile ? props.kFixedDialog : 'inherit',
            left: isMobile ? props.kFixedDialog : 'inherit',
            right: isMobile ? props.kFixedDialog : 'inherit',
          },
        }}
      >
        {
          (!props.kDisableTitle)
          && (
            <DialogCommonHeader
              kTitle={props.kTitle}
              kSubTitle={props.kSubTitle}
              kOnClose={props.kOnClose}
              kHeaderComponent={props.kHeaderComponent}
              kDisableContentPaddinginY={props.kDisableContentPaddinginY}
              kCenterTitle={props.kCenterTitle}
            />
          )
        }
        {
          props.kTopDivider ? (
            <Divider />
          ) : null
        }
        <DialogCommonContent
          kContent={props.kContent}
          KDisableScroll={props.KDisableScroll}
          kDividers={props.kFullScreenMobile ?? isMobile}
          kContentPadding={props.kDisableContentPadding}
          kDisableBottomDivider={props.kDisableBottomDivider}
          kDisableContentPaddingInY={props.kDisableContentPaddinginY}
        />
        {
          !props.kDisableActions
          && (
            <DialogCommonActions
              kSecondaryDialogAction={props.kOnSecondary}
              kPrimaryDialogAction={props.kOnPrimary}
              kPrimaryBtnTextAction={props.kPrimaryBtnText}
              kThirdDialogAction={props.kOnThird}
              kThirdBtnTextAction={props.kPrimaryBtnText}
              kSecondaryBtnTextAction={props.kSecondaryBtnText}
              kAlignBtnAction={props.kAlignBtn}
              kElementAction={props.kElementAction}
              kDisableBtnSave={props.kDisableBtnSave}
              kKeepBtnSize={props.kKeepBtnSize}
            />
          )
        }
      </Dialog>
    </>
  );
}

DialogCommon.defaultProps = {
  kFullScreenMobile: true,
  kFullScreenDesktop: false,
};
export default DialogCommon;

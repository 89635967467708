import {
  Typography, Chip, Table, TableBody, TableCell, TableContainer, TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ItemDetailEspecialInstructionsModels, ItemDetailEspecialInstructionModels } from './ItemDetaiModels';
import { LmiTheme } from '../../../../../components/Common';
import LmiColorPalette from '../../../../../themes/lmi-mui-theme/lmi-color-palette';

export default function ItemDetailEspecialInstructions(props: ItemDetailEspecialInstructionsModels) {
  const { t } = useTranslation('global');
  return (
    <>
      <TableContainer sx={{
        borderRadius: 2,
        border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
      }}
      >
        <Table
          aria-label={t('item_detail.special_instructions')}
        >
          <TableBody>

            { props.kEspecialInstructions.length > 0
              ? props.kEspecialInstructions.map((row: ItemDetailEspecialInstructionModels) => (
                <TableRow
                  key={row.idTerm}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Typography fontWeight="400" fontSize={14}>{row.termDetail}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Chip
                      label={row.relatedToItem ? `${t('item_detail.active')}` : `${t('item_detail.inactive')}`}
                      variant="filled"
                      sx={{
                        fontWeight: 'bold',
                        height: '25px',
                        borderRadius: '7px',
                        color: row.relatedToItem ? LmiColorPalette.greenScheme[900] : LmiTheme.light.systemBackground.primary,
                        background: row.relatedToItem ? LmiColorPalette.greenScheme[50] : LmiTheme.light.systemBackground.solid,
                      }}
                    />
                  </TableCell>

                </TableRow>
              ))
              : (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left">
                    {t('item_detail.no_special_instructions')}
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

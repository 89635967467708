import { useContext, useEffect } from 'react';
import {
  useParams,
} from 'react-router-dom';
import {
  Grid, Toolbar, Container, Box,
} from '@mui/material';
import Filters from './Components/Filters/Filters';
import ResultListing from './Components/ResultListing/ResultListing';
import ExploreContext from '../../../context/explore/exploreContext';
import homeContext from '../../../context/home/homeContext';

export default function ExplorePage() {
  const {
    categories,
    getCategories,
  }: any = useContext(homeContext);
  const {
    searchFn,
    items,
    loading,
    searched,
    changePage,
  }: any = useContext(ExploreContext);
  const { search: _search }: any = useParams();

  const newSearch = !_search && searched == '' ? false : _search != searched;

  useEffect(() => {
    if ((loading && !items) || newSearch) {
      (async function performSearch() {
        await changePage(1);
        searchFn(_search, 1);
        getHistorySearch();
      }());
    }
  }, [_search]);

  useEffect(() => {
    if (!categories) {
      getCategories();
    }
    getHistorySearch();
  }, []);

  const getHistorySearch = () => {
    const searchHistory = localStorage.getItem('searchHistory');
    const searchHistoryJson = searchHistory ? JSON.parse(searchHistory) : [];
    if (_search) {
      if (searchHistoryJson.length) {
        const index = searchHistoryJson.findIndex((s: any) => s === _search);
        if (index === -1) {
          if (searchHistoryJson.length >= 5) {
            searchHistoryJson.reverse();
            searchHistoryJson.pop();
            searchHistoryJson.reverse();
          }
          searchHistoryJson.push(_search);
        }
      } else {
        if (searchHistoryJson.length >= 5) {
          searchHistoryJson.reverse();
          searchHistoryJson.pop();
          searchHistoryJson.reverse();
        }
        searchHistoryJson.push(_search);
      }
      localStorage.setItem('searchHistory', JSON.stringify(searchHistoryJson));
    }
  };

  return (
    <Box sx={{ width: '100%', background: 'white' }}>
      <Container
        disableGutters
        maxWidth="lg"
        sx={{
          marginTop: { md: 0, xs: 8 },
          bgcolor: '#fff',
          minHeight: { xs: 'auto', md: '100vh' },
          borderBottom: 'none',
        }}
      >
        <Toolbar />
        <Grid
          container
          spacing={0}
          justifyContent="center"
        >
          <Grid component="section" xs="auto" sx={{ display: { xs: 'none', sm: 'inline-block' } }} item>
            <Filters />
          </Grid>
          <Grid component="section" xs={12} sm={7.8} md={8.9} item>
            <ResultListing />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

/* eslint-disable max-lines */
/* eslint import/no-unresolved: [2, { commonjs: true, amd: true }] */
import { useContext, useReducer } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { ClsModCreateUserPayOut } from '@lendmeit/api_backend_lmi/dist/models';
import ApiLMI from '../../services/apilmi';
import PayoutAccountContext from './payoutAccountContext';
import PayoutAccountReducer from './payoutAccountReducer';
import {
  GET_BY_ID,
  PAYMENT_ADD_EDIT,
  CHECK_NEW_PAYOUT_VALIDATIONS,
  UPDATE_PAYOUT_VALIDATION_STATUS,
  PAYOUT_EDIT_INFO,
  PAYOUT_UPDATED_CREATED,
} from '../../types';
import publicProfileContext from '../publicProfile/publicProfileContext';

const PayoutAccountState = (props: any) => {
  const pProfileContext = useContext(publicProfileContext);
  const {
    getPrivateProfileInfo,
  }: any = pProfileContext;
  const initialNewPayoutInfo = {
    isIndividual: true,
    isBusiness: false,
    firstName: '',
    lastName: '',
    dateOfBirth: '2000-01-01',
    ssn: '',
    email: '',
    companyName: '',
    taxId: '',

    isDebitCar: true,
    isBankAccount: false,

    cardNumber: '',
    expiration: '',
    cvv: '',

    accountNumber: '',
    routingNumber: '',

    expirationMonth: '',
    expirationYear: '',

    accountHolderType: '',
    accountHolderName: '',
    externalAccId: '',
    personId: '',

    accId: '',
    bussinedType: '',
    bussinesProfName: '',
    bussinesProfile: '',
    individual: '',
    externalAcc: '',

    rfc: '',
    country: 'us',
    state: '',
    city: '',
    address: '',
    zipCode: '',
    frontIne: '',
    backIne: '',
    extNumber: '',
    intNumber: '',
    bankAccountMx: '',

    companyEmail: '',
    companyRfc: '',
    ownerFirstName: '',
    ownerLastName: '',
    companyOwnerRfc: '',
    ownerPhoneNumber: '',
    companyCity: '',
    companyAddress: '',
    companyExtNumber: '',
    companyIntNumber: '',
    companyZipCode: '',
    accountHolderNameMx: '',
    accountHolderLastNameMx: '',
  };

  const initialState = {
    loading: true,
    paymentMethodsList: [],
    payoutAccountDetails: initialNewPayoutInfo,
    payoutAccDetEdit: initialNewPayoutInfo,
  };

  const [state, dispatch] = useReducer(PayoutAccountReducer, initialState);

  const {
    loading,
  } = state;

  const updateNewPayoutAccountInfo = async (values: any) => {
    dispatch({
      type: PAYOUT_EDIT_INFO,
      payload: {
        payoutAccountDetails: values,
      },
    });
  };

  const updateExistingPayoutAccountDetails = async (values: any) => {
    dispatch({
      type: PAYOUT_EDIT_INFO,
      payload: {
        payoutAccountDetails: values,
        checkPayoutValidate: false,
      },
    });
  };

  const updateNewPaymentInfo = async (values: any) => {
    dispatch({
      type: PAYMENT_ADD_EDIT,
      payload: {
        newPaymentMethodInfo: values,
        checkPaymentValidate: false,
      },
    });
  };

  const getUserPayoutAccount = async () => {
    const _api = ApiLMI();
    try {
      const payoutAccount = await _api.userpayoutGetUserPayOutAccount({ toc: 'string' });
      const payoutAccountParsed = JSON.parse(payoutAccount.data.toString()).Response;
      if (payoutAccountParsed?.id != null) {
        let emailVersion;
        let accoundHolderName;
        if (payoutAccountParsed?.business_type == 'individual') {
          emailVersion = payoutAccountParsed?.individual?.email;
        } else {
          emailVersion = payoutAccountParsed?.email;
        }
        if (payoutAccountParsed?.business_type == 'individual') {
          accoundHolderName = payoutAccountParsed?.external_accounts.data[0].account_holder_name;
        } else {
          accoundHolderName = payoutAccountParsed?.external_accounts.data[0].account_holder_name;
        }
        const PAinfo = {
          isIndividual: payoutAccountParsed?.business_type == 'individual',
          isBusiness: payoutAccountParsed?.business_type != 'individual',
          firstName: payoutAccountParsed?.individual?.first_name == null ? '' : payoutAccountParsed.individual.first_name,
          lastName: payoutAccountParsed?.individual?.last_name == null ? '' : payoutAccountParsed.individual.last_name,
          dateOfBirth: `${payoutAccountParsed?.individual?.dob?.year}-${payoutAccountParsed?.individual?.dob?.month}-${payoutAccountParsed?.individual?.dob?.day}`,
          ssn: payoutAccountParsed?.individual?.ssn == null ? '' : payoutAccountParsed?.individual?.ssn,
          email: emailVersion == null ? '' : emailVersion,
          companyName: payoutAccountParsed?.company.name != null ? payoutAccountParsed.company.name : '',
          taxId: payoutAccountParsed?.taxId == null ? '' : payoutAccountParsed?.taxId,

          isDebitCar: payoutAccountParsed?.external_accounts.data[0].object == 'card',
          isBankAccount: payoutAccountParsed?.external_accounts.data[0].object == 'bank_account',

          cardNumber: payoutAccountParsed?.cardNumber == null ? '' : payoutAccountParsed?.cardNumber,
          expiration:
            (payoutAccountParsed?.external_accounts.data[0].exp_month == null && payoutAccountParsed?.external_accounts.data[0].exp_year == null)
              ? '' : `${payoutAccountParsed?.external_accounts.data[0].exp_month.toString()}/${payoutAccountParsed?.external_accounts.data[0].exp_year.toString().slice(-2)}`,
          cvv: payoutAccountParsed?.cvv == null ? '' : payoutAccountParsed?.cvv,

          accountNumber: payoutAccountParsed?.accountNumber == null ? '' : payoutAccountParsed?.accountNumber,
          routingNumber: payoutAccountParsed?.routingNumber == null ? '' : payoutAccountParsed?.routingNumber,

          expirationMonth: payoutAccountParsed?.external_accounts.data[0].exp_month == null ? '' : payoutAccountParsed?.external_accounts.data[0].exp_month.toString(),
          expirationYear: payoutAccountParsed?.external_accounts.data[0].exp_year == null ? '' : payoutAccountParsed?.external_accounts.data[0].exp_year.toString().slice(-2),

          accountHolderType: payoutAccountParsed?.external_accounts.data[0].account_holder_type == null ? '' : payoutAccountParsed?.external_accounts.data[0].account_holder_type,
          accountHolderName: accoundHolderName == null ? '' : accoundHolderName,
          externalAccId: payoutAccountParsed?.external_accounts.data[0].id == null ? '' : payoutAccountParsed?.external_accounts.data[0].id,
          personId: payoutAccountParsed?.individual?.id == null ? '' : payoutAccountParsed?.individual?.id,

          accId: payoutAccountParsed?.id == null ? '' : payoutAccountParsed?.id,
          bussinedType: payoutAccountParsed?.bussinedType == null ? '' : payoutAccountParsed?.bussinedType,
          bussinesProfName: payoutAccountParsed?.bussinesProfName == null ? '' : payoutAccountParsed?.bussinesProfName,
          bussinesProfile: payoutAccountParsed?.business_profile == null ? '' : payoutAccountParsed?.business_profile,
          individual: payoutAccountParsed?.individual == null ? '' : payoutAccountParsed?.individual,
          externalAcc: payoutAccountParsed?.external_accounts == null ? '' : payoutAccountParsed?.external_accounts,
          rfc: payoutAccountParsed?.rfc || '',
          country: payoutAccountParsed?.country || '',
          state: payoutAccountParsed?.state || '',
          city: payoutAccountParsed?.city || '',
          address: payoutAccountParsed?.address || '',
          zipCode: payoutAccountParsed?.zipCode || '',
          frontIne: payoutAccountParsed?.frontIne || '',
          backIne: payoutAccountParsed?.backIne || '',
          extNumber: payoutAccountParsed?.extNumber || '',
          intNumber: payoutAccountParsed?.intNumber || '',
          bankAccountMx: payoutAccountParsed?.accountNumber || '',

        };

        dispatch({
          type: GET_BY_ID,
          payload: {
            payoutAccountDetails: PAinfo,
            payoutAccDetEdit: PAinfo,
            checkPayoutValidate: false,
            arePayoutFieldsValidated: false,
          },
        });
      } else {
        dispatch({
          type: GET_BY_ID,
          payload: {
            payoutAccountDetails: initialNewPayoutInfo,
            payoutAccDetEdit: initialNewPayoutInfo,
            arePayoutFieldsValidated: false,
          },
        });
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const createPayoutAccount = async (details: any, clickCloseDialogAddPayoutMethod?: Function) => {
    const api = ApiLMI();
    const payoutDetails = state.payoutAccountDetails;
    const idtoast = toast.loading(t('global.saving_info'));
    let body: ClsModCreateUserPayOut;
    if (payoutDetails.isBankAccount) {
      payoutDetails.cvv = '';
      payoutDetails.cardNumber = '';
      payoutDetails.expiration = '';
    } else {
      payoutDetails.accountNumber = '';
      payoutDetails.routingNumber = '';
    }
    if (state.payoutAccountDetails.isIndividual && state.payoutAccountDetails.country == 'mx') {
      let addressToAdd = '';
      let bankAcct = state.payoutAccountDetails.accountNumber;
      if (state.payoutAccountDetails.country == 'mx') {
        addressToAdd = `${state.payoutAccountDetails.address} ${state.payoutAccountDetails.intNumber} ${state.payoutAccountDetails.extNumber}`;
        bankAcct = state.payoutAccountDetails.bankAccountMx;
      }
      body = {
        isIndividual: state.payoutAccountDetails.isIndividual,
        isBaccount: state.payoutAccountDetails.country == 'mx' ? true : state.payoutAccountDetails.isBankAccount,
        stripeEmailAccount: state.payoutAccountDetails.email,
        legalName: state.payoutAccountDetails.firstName,
        dateOfBirth: state.payoutAccountDetails.dateOfBirth,
        ssn: state.payoutAccountDetails.country == 'mx' ? '' : state.payoutAccountDetails.ssn,
        country: state.payoutAccountDetails.country == '' ? 'us' : state.payoutAccountDetails.country,
        productDescriptionInd: 'lendable items',
        businessName: state.payoutAccountDetails.companyName,
        productDescriptionComp: 'lendable items',
        accountNumber: bankAcct,
        routingNumber: state.payoutAccountDetails.routingNumber != '' ? state.payoutAccountDetails.routingNumber : '',
        debitNumber: state.payoutAccountDetails.cardNumber != '' ? state.payoutAccountDetails.cardNumber.replace(/\s/g, '') : '',
        debitExpiryMonth: details.expirationMonth.toString(),
        debitExpiryYear: details.expirationYear.toString(),
        debitCvv: payoutDetails.cvv,
        legalLastName: state.payoutAccountDetails.lastName,
        taxId: state.payoutAccountDetails.taxId,
        rfc: state.payoutAccountDetails.rfc,
        state: state.payoutAccountDetails.state,
        city: state.payoutAccountDetails.city,
        address: addressToAdd,
        zipCode: state.payoutAccountDetails.zipCode,
        documentProofFront: state.payoutAccountDetails.frontIne.split(',')[1],
        documentProofBack: state.payoutAccountDetails.backIne.split(',')[1],
        toc: '',
      };
    } else if (!state.payoutAccountDetails.isIndividual && state.payoutAccountDetails.country == 'us') {
      body = {
        toc: 'string',
        isIndividual: state.payoutAccountDetails.isIndividual,
        isBaccount: state.payoutAccountDetails.isBankAccount,
        stripeEmailAccount: state.payoutAccountDetails.email,
        legalName: state.payoutAccountDetails.firstName,
        dateOfBirth: '20/08/1970',
        ssn: state.payoutAccountDetails.ssn,
        country: state.payoutAccountDetails.country == '' ? 'us' : state.payoutAccountDetails.country,
        productDescriptionInd: 'lendable items',
        businessName: state.payoutAccountDetails.companyName,
        productDescriptionComp: 'lendable items',
        accountNumber: state.payoutAccountDetails.accountNumber != '' ? state.payoutAccountDetails.accountNumber : '',
        routingNumber: state.payoutAccountDetails.routingNumber != '' ? state.payoutAccountDetails.routingNumber : '',
        debitNumber: state.payoutAccountDetails.cardNumber != '' ? state.payoutAccountDetails.cardNumber.replace(/\s/g, '') : '',
        debitExpiryMonth: details.expirationMonth.toString(),
        debitExpiryYear: details.expirationYear.toString(),
        debitCvv: payoutDetails.cvv,
        legalLastName: state.payoutAccountDetails.lastName,
        taxId: state.payoutAccountDetails.taxId,
        rfc: '',
        state: '',
        city: '',
        address: '',
        zipCode: '',
        documentProofFront: '',
        documentProofBack: '',
      };
    } else if (!state.payoutAccountDetails.isIndividual && state.payoutAccountDetails.country == 'mx') {
      body = {
        toc: 'string',
        isIndividual: state.payoutAccountDetails.isIndividual,
        isBaccount: true,
        stripeEmailAccount: state.payoutAccountDetails.companyEmail,
        legalName: state.payoutAccountDetails.accountHolderNameMx,
        dateOfBirth: state.payoutAccountDetails.dateOfBirth,
        // dateOfBirth: '08/08/1970',
        ssn: state.payoutAccountDetails.ssn,
        country: state.payoutAccountDetails.country == '' ? 'us' : state.payoutAccountDetails.country,
        productDescriptionInd: 'lendable items',
        businessName: state.payoutAccountDetails.companyName,
        productDescriptionComp: 'lendable items',
        accountNumber: state.payoutAccountDetails.bankAccountMx,
        // accountNumber: state.payoutAccountDetails.accountNumber != '' ? state.payoutAccountDetails.accountNumber : '',
        routingNumber: state.payoutAccountDetails.routingNumber != '' ? state.payoutAccountDetails.routingNumber : '',
        debitNumber: state.payoutAccountDetails.cardNumber != '' ? state.payoutAccountDetails.cardNumber.replace(/\s/g, '') : '',
        debitExpiryMonth: details.expirationMonth.toString(),
        debitExpiryYear: details.expirationYear.toString(),
        debitCvv: payoutDetails.cvv,
        legalLastName: state.payoutAccountDetails.accountHolderLastNameMx,
        taxId: state.payoutAccountDetails.taxId,
        rfc: state.payoutAccountDetails.companyRfc,
        state: state.payoutAccountDetails.state,
        city: state.payoutAccountDetails.companyCity,
        address: state.payoutAccountDetails.companyAddress,
        zipCode: state.payoutAccountDetails.companyZipCode,
        documentProofFront: state.payoutAccountDetails.frontIne.split(',')[1],
        documentProofBack: state.payoutAccountDetails.backIne.split(',')[1],
        ownerFirstName: state.payoutAccountDetails.ownerFirstName,
        ownerLastName: state.payoutAccountDetails.ownerLastName,
        ownerPhone: state.payoutAccountDetails.ownerPhoneNumber,
        ownerRFC: state.payoutAccountDetails.companyOwnerRfc,
        ownerDateOfBirth: state.payoutAccountDetails.dateOfBirth,
        // ownerDateOfBirth: '20/08/1992',
      };
    } else {
      body = {
        toc: 'string',
        isIndividual: state.payoutAccountDetails.isIndividual,
        isBaccount: state.payoutAccountDetails.isBankAccount,
        stripeEmailAccount: state.payoutAccountDetails.email,
        legalName: state.payoutAccountDetails.firstName,
        dateOfBirth: state.payoutAccountDetails.dateOfBirth,
        ssn: state.payoutAccountDetails.ssn,
        country: state.payoutAccountDetails.country == '' ? 'us' : state.payoutAccountDetails.country,
        productDescriptionInd: 'lendable items',
        businessName: state.payoutAccountDetails.companyName,
        productDescriptionComp: 'lendable items',
        accountNumber: state.payoutAccountDetails.accountNumber != '' ? state.payoutAccountDetails.accountNumber : '',
        routingNumber: state.payoutAccountDetails.routingNumber != '' ? state.payoutAccountDetails.routingNumber : '',
        debitNumber: state.payoutAccountDetails.cardNumber != '' ? state.payoutAccountDetails.cardNumber.replace(/\s/g, '') : '',
        debitExpiryMonth: details.expirationMonth.toString(),
        debitExpiryYear: details.expirationYear.toString(),
        debitCvv: payoutDetails.cvv,
        legalLastName: state.payoutAccountDetails.lastName,
        taxId: state.payoutAccountDetails.taxId,
        rfc: '',
        state: '',
        city: '',
        address: '',
        zipCode: '',
        documentProofFront: '',
        documentProofBack: '',
      };
    }

    const createPayout = await api.apiUserpayoutCreateUserPayOutPost(body);
    const createPaymentParsed = JSON.parse(createPayout.data.toString());
    if (createPaymentParsed.Error != '') {
      toast.error(createPaymentParsed.Error);

      toast.update(idtoast, {
        render: `${t('payout_info.state.error_occurred')}`,
        type: 'error',
        isLoading: false,
        autoClose: 1500,
      });
    } else {
      getUserPayoutAccount();
      getPrivateProfileInfo({ idCatLender: 0 });
      dispatch({
        type: PAYOUT_UPDATED_CREATED,
        payoutUpdated: true,
      });
      if (clickCloseDialogAddPayoutMethod) {
        updateExistingPayoutAccountDetails(initialNewPayoutInfo);
        clickCloseDialogAddPayoutMethod();
      }
      toast.update(idtoast, {
        render: `${t('payout_info.state.payout_added')}`, type: 'success', icon: '🟢', isLoading: false, autoClose: 1500,
      });
    }
  };

  const updatePayoutAccount = async (arrToSend: any) => {
    const api = ApiLMI();
    const payoutDetails = state.payoutAccountDetails;
    const idtoast = toast.loading(t('global.saving_info'));
    if (!payoutDetails.isBankAccount) {
      payoutDetails.cvv = '';
      payoutDetails.debitNumber = '';
      payoutDetails.expiration = '';
    } else {
      payoutDetails.accountNumber = '';
      payoutDetails.routingNumber = '';
    }
    const accHN = state.payoutAccountDetails.accountHolderName != '' ? state.payoutAccountDetails.accountHolderName : null;
    const body = {
      toc: 'string',
      isIndividual: state.payoutAccountDetails.isIndividual,
      isBaccount: state.payoutAccountDetails.isBankAccount,
      stripeEmailAccount: state.payoutAccountDetails.email,
      legalName: state.payoutAccountDetails.isIndividual ? state.payoutAccountDetails.firstName : accHN,
      dateOfBirth: state.payoutAccountDetails.isIndividual ? state.payoutAccountDetails.dateOfBirth : '1/1/2002',
      ssn: undefined,
      productDescriptionInd: 'lendable items',
      businessName: state.payoutAccountDetails.companyName != '' ? state.payoutAccountDetails.companyName : null,
      productDescriptionComp: 'lendable items',
      accountNumber: payoutDetails.accountNumber != '' ? payoutDetails.accountNumber : null,
      routingNumber: payoutDetails.routingNumber != '' ? payoutDetails.routingNumber : null,
      debitNumber: state.payoutAccountDetails.cardNumber,
      debitExpiryMonth: arrToSend.expirationMonth,
      debitExpiryYear: arrToSend.expirationYear,
      debitCvv: state.payoutAccountDetails.cvv,
      legalLastName: state.payoutAccountDetails.lastName,
      taxId: state.payoutAccountDetails.taxId,
      accountHolderType: null,
      accountHolderName: state.payoutAccountDetails.accountHolderName != '' ? state.payoutAccountDetails.accountHolderName : null,
      externalAccId: state.payoutAccountDetails.externalAcc.data[0].id,
      personId: state.payoutAccountDetails.personId,
    };
    try {
      const createPayout = await api.userpayoutUpdateUserPayOutAccountCustom(body);
      const createPaymentParsed = JSON.parse(createPayout.data.toString());
      if (createPaymentParsed.Error != '') {
        toast.error(createPaymentParsed.Error);
      } else {
        getUserPayoutAccount();
        dispatch({
          type: PAYOUT_UPDATED_CREATED,
          payoutUpdated: true,
        });
        toast.update(idtoast, {
          render: `${t('payout_info.state.payout_added')}`, type: 'success', icon: '🟢', isLoading: false, autoClose: 1500,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      toast.dismiss(idtoast);
    }
  };

  const checkNewPayoutValidations = async (isCheckNewPayoutValidations: boolean) => {
    dispatch({
      type: CHECK_NEW_PAYOUT_VALIDATIONS,
      checkPayoutValidate: isCheckNewPayoutValidations,
      triggerCheckValidate: !state.triggerCheckValidate,
    });
  };

  const updatePayoutValidationStatus = async (values: any) => {
    dispatch({
      type: UPDATE_PAYOUT_VALIDATION_STATUS,
      arePayoutFieldsValidated: values,
      changedPayoutFields: !state.changedPayoutFields,
    });
  };

  const { t } = useTranslation('global');

  return (
    <PayoutAccountContext.Provider
      value={{
        ...state,
        loading,
        payoutAccountDetails: state.payoutAccountDetails,
        payoutAccDetEdit: state.payoutAccDetEdit,
        newPayoutAccountInfo: state.newPayoutAccountInfo,
        checkPayoutValidate: state.checkPayoutValidate,
        changedPayoutFields: state.changedPayoutFields,
        arePayoutFieldsValidated: state.arePayoutFieldsValidated,
        triggerCheckValidate: state.triggerCheckValidate,
        getUserPayoutAccount,
        updateNewPaymentInfo,
        createPayoutAccount,
        checkNewPayoutValidations,
        updatePayoutValidationStatus,
        updateNewPayoutAccountInfo,
        updateExistingPayoutAccountDetails,
        updatePayoutAccount,
      }}
    >
      {props.children}

    </PayoutAccountContext.Provider>
  );
};

export default PayoutAccountState;

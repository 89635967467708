import {
  Box, Typography, FormControlLabel, Accordion, AccordionSummary, AccordionDetails, Stack, IconButton, Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { ExpandMoreRounded } from '@mui/icons-material';
import ServiceRoadie from '../ServiceRoadie/ServiceRoadie';
import PickUp from '../PickUp/PickUp';
import { ItemDeliveryModel } from './ItemDelivery.models';
import IOSSwitch from '../ItemBasicInformation/ItemBasicInformation.style';
import addEditItemContext from '../../../../../../../context/addEditItem/addEditItemContext';
import { getKeyValue } from '../../../../../../../services/common';
import { ReactComponent as DeliveryIcon } from '../../../../../../../assets/icons/lmi_delivery_icon.svg';
import { ReactComponent as RoadieIcon } from '../../../../../../../assets/icons/lmi_roadie_icon.svg';
import { LmiTheme } from '../../../../../../../components/Common';
import homeContext from '../../../../../../../context/home/homeContext';
import publicProfileContext from '../../../../../../../context/publicProfile/publicProfileContext';

export default function ItemDelivery(props: ItemDeliveryModel) {
  const [roadieVisibleFromPhoneCode, setRoadieVisibleFromPhoneCode] = useState(true);

  const pProfileContext = useContext(publicProfileContext);

  const {
    privateProfileInfo,
  }: any = pProfileContext;
  const addEditContextLog = useContext(addEditItemContext);

  const {
    itemInfo,
    updateItemInfo,
    canModify,
    updateDraftItemInfo,
  }: any = addEditContextLog;

  const { countryCode }: any = useContext(homeContext);

  const updateItemForDraft = (obj: {}) => {
    if (itemInfo.idCatLndItem == 0) {
      updateDraftItemInfo({
        ...obj,
      });
    }
  };

  const onChange = (e: any) => {
    const dataType = getKeyValue(itemInfo, e.target.name);

    let value;
    if (e.target.name === 'isLenderDelivery') {
      e.target.value = false;
    }

    switch (typeof (dataType)) {
      case 'number':
        value = parseFloat(e.target.value != '' ? e.target.value : 0);
        break;
      case 'boolean':
        value = e.target.checked;
        break;
      default:
        value = e.target.value;
        break;
    }
    const updatedItemInfo = { ...itemInfo, [e.target.name]: value };
    if (e.target.name === 'isLenderDelivery' && value) {
      updatedItemInfo.pickupOnly = false;
    }
    updateItemInfo(updatedItemInfo);
    updateItemForDraft(updatedItemInfo);
  };

  const { t } = useTranslation('global');

  useEffect(() => {
    // Phone Code validation
    const userPhoneNumber = privateProfileInfo?.phoneNumber;
    // let americanPhoneCode = true;
    if (userPhoneNumber?.length > 0) {
      const phoneCode = userPhoneNumber.slice(0, -10);
      if (phoneCode != '+1') {
        setRoadieVisibleFromPhoneCode(false);
      }
    }
  }, [privateProfileInfo?.phoneNumber]);

  return (
    <>
      <Box p={0}>
        <Typography p={2} fontSize="body2.fontSize" color={LmiTheme.light.systemBackground.tertiary}>
          {t('add_item_page.offer_delivery_service')}
        </Typography>
        <Divider />
        <Accordion disableGutters elevation={0}>
          <Box py={1}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" pl={2} pr={0}>
              <Stack direction="row" justifyContent="flex-start" alignItems="center">
                <DeliveryIcon width={24} height={24} style={{ marginRight: 16 }} />
                <Typography mr={2} fontSize="body2.fontSize" fontWeight={700} color={LmiTheme.light.systemBackground.primary}>{t('item_detail.offer_delivery')}</Typography>
              </Stack>
              <Stack direction="row" justifyContent="flex-end" alignItems="center">
                {
                  itemInfo == null && canModify ? null : (
                    <FormControlLabel
                      control={(
                        <IOSSwitch
                          disabled={!canModify}
                          name="isLenderDelivery"
                          checked={itemInfo.isLenderDelivery}
                          onChange={(e: any) => { onChange(e); }}
                          sx={{ mr: 0 }}
                        />
                      )}
                      label=""
                    />
                  )
                }
                <AccordionSummary
                  sx={{ mr: 2, p: 0 }}
                  expandIcon={(
                    <IconButton sx={{ m: 0, p: 0 }}>
                      <ExpandMoreRounded sx={{ m: 0, p: 0 }} fontSize="large" color="primary" />
                    </IconButton>
                  )}
                />
              </Stack>
            </Stack>
          </Box>
          <AccordionDetails sx={{ p: 0 }}>
            <Divider />
            <Box px={2}>
              {itemInfo == null ? null : <PickUp userCurrency={props.userCurrency} kIsReadOnly={props.kIsReadOnly} />}
            </Box>
          </AccordionDetails>
        </Accordion>
        {
          (countryCode === 'US' && roadieVisibleFromPhoneCode) ? (
            <>
              <Divider />
              <Accordion disableGutters elevation={0}>
                <Box py={1}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" pl={2} pr={0}>
                    <Stack direction="row" justifyContent="flex-start" alignItems="center">
                      <RoadieIcon width={24} height={24} style={{ marginRight: 16 }} />
                      <Typography mr={2} fontSize="body2.fontSize" fontWeight={700} color={LmiTheme.light.systemBackground.primary}>{t('item_detail.third_party_service')}</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="flex-end" alignItems="center">
                      {itemInfo == null && canModify ? null : (
                        <FormControlLabel
                          control={(
                            <IOSSwitch
                              disabled={!canModify}
                              name="roadie"
                              checked={itemInfo.roadie}
                              onChange={(e) => { onChange(e); }}
                            />
                          )}
                          label=""
                        />
                      )}
                      <AccordionSummary
                        sx={{ mr: 2, p: 0 }}
                        expandIcon={(
                          <IconButton sx={{ m: 0, p: 0 }}>
                            <ExpandMoreRounded sx={{ m: 0, p: 0 }} fontSize="large" color="primary" />
                          </IconButton>
                        )}
                      />
                    </Stack>
                  </Stack>
                </Box>
                <AccordionDetails sx={{ p: 0 }}>
                  <Divider />
                  <Box px={2}>
                    {itemInfo == null ? null : <ServiceRoadie kIsReadOnly={props.kIsReadOnly} kAvailable />}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </>
          ) : (<ServiceRoadie kIsReadOnly={props.kIsReadOnly} kAvailable={false} />)
        }
      </Box>
    </>
  );
}

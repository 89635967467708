import {
  useContext, useEffect, useState,
} from 'react';
import {
  Grid, Button, Divider, Fab, Typography, Box, useTheme, useMediaQuery, Stack, IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import IosShareIcon from '@mui/icons-material/IosShare';
import CreateIcon from '@mui/icons-material/Create';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import MyListing from './Components/MyListing/MyListing';
import ItemDetail from './Components/ItemDetail/ItemDetail';
import empty_lend from '../../../assets/empty_state/empty_lend.png';
import {
  ContentLayout,
  DialogCommon,
  EmptyState,
  SectionTitle,
  UserMenu,
} from '../../../components/Common';

import MyListingContext from '../../../context/myListing/myListingContext';
import { AddItemInfoModel, emptyItemModel } from './Components/AddItem/AddItem.models';
import CardLend from './Components/CardLend/CardLend';
import LmiTheme from '../../../themes/lmi-mui-theme/lmi-theme';
import GridListings from './Components/GridListings/GridListings';
import LoadingLayout from '../../../components/Common/LoadingLayout/LoadingLayout';
import AppBarSecondary from '../../../components/Layout/AppBarSecondary/AppBarSecondary';
import ShareItem from '../AddItem/Components/ShareItem';
import ShareListings from './Components/ShareListings/ShareListings';
import authContext from '../../../context/authentication/authContext';

export default function Listing() {
  const {
    getMyItems,
    items,
    details,
    loading,
    item,
    dynamicLink,
    images,
    getExtraInformation,
    getDynamicLink,
  }: any = useContext(MyListingContext);

  const {
    token,
  }: any = useContext(authContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const history = useHistory();
  const state = history.location.state as {
    kItemId: string,
    kItemIndex: number,
    updateSwiper: false,
    kShowDetailsMobile: false
  };

  const [openShare, setOpenShare] = useState(false);
  const [openShareListing, setOpenShareListing] = useState(false);

  const showItemDetailMobile = state?.kShowDetailsMobile ? state.kShowDetailsMobile : false;
  const idCatLndItem = item ? item.idCatLndItem : 0;

  useEffect(() => {
    (async function fetchMyItems() {
      await getMyItems();
    }());
  }, []);

  useEffect(() => {
    (async function fetchDynamicLink() {
      await getDynamicLink();
    }());
  }, []);

  useEffect(() => {
    if (item) {
      (async function fetchExtraInformation() {
        await getExtraInformation();
      }());
    }
  }, [item]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (items && !loading && !item) {
      (async function fetchDetails() {
        await details(items[state?.kItemIndex ? state.kItemIndex : 0], []);
      }());
    } else if (item) {
      (async function fetchDetails() {
        await details(items[state?.kItemIndex ? state.kItemIndex : 0], []);
      }());
    }
  }, [items]);

  const onChange = (itemSelected: AddItemInfoModel, index: number) => {
    if (item.idCatLndItem != itemSelected.idCatLndItem) {
      details(itemSelected, images);
      window.scrollTo(0, 0);
    }
    if (isMobile) {
      history.push({
        state: {
          ...state,
          kItemId: item.idCatLndItem,
          kItemIndex: index,
          updateSwiper: true,
          kShowDetailsMobile: true,
        },
      });
    } else {
      history.push({
        state: {
          ...state,
          kItemId: item.idCatLndItem,
          kItemIndex: index,
          updateSwiper: false,
          kShowDetailsMobile: false,
        },
      });
    }
  };

  const openShareModal = (id: number) => {
    const indexInArray = items.findIndex((element: any) => element.idCatLndItem === id);
    let isSold = false;
    if (indexInArray > -1) {
      isSold = items[indexInArray].sold;
      if (!isSold) {
        setOpenShare(true);
      }
    }
  };

  const openEditItemPage = (id: number) => {
    const indexInArray = items.findIndex((element: any) => element.idCatLndItem === id);
    let isSold = false;
    if (indexInArray > -1) {
      isSold = items[indexInArray].sold;
    }
    if (isSold) {
      toast.info(t('listing.item_sold'));
    } else {
      details(items[indexInArray], images);
      history.push({
        pathname: '/listing/edit',
        state: {
          ...state,
          kItemId: id,
          kItemIsNew: false,
          kItemIndex: state?.kItemIndex ? state.kItemIndex : 0,
          updateSwiper: state?.updateSwiper ? state.updateSwiper : 0,
          kShowDetailsMobile: state?.kShowDetailsMobile ? state.kShowDetailsMobile : false,
        },
      });
    }
  };

  const openAddItemPage = () => {
    window.scrollTo(0, 0);
    details(emptyItemModel(), []);
    history.push({
      pathname: '/listing/edit',
      state: {
        ...state,
        kItemId: 0,
        kItemIsNew: true,
        kItemIndex: state?.kItemIndex ? state.kItemIndex : 0,
        updateSwiper: false,
        kShowDetailsMobile: false,
      },
    });
  };

  const backToLendOnMobile = () => {
    window.scrollTo(0, 0);
    history.push({
      state: {
        ...state,
        kItemId: state.kItemId,
        kItemIndex: state.kItemIndex,
        updateSwiper: state.updateSwiper,
        kShowDetailsMobile: false,
      },
    });
  };

  const { t } = useTranslation('global');

  return (
    <>
      <AppBarSecondary
        kBackClick={() => backToLendOnMobile()}
        kBack={!!showItemDetailMobile}
        kTitle={
          (
            <SectionTitle
              kMarginTop={0}
              kMarginBottom={0}
              kTitle={showItemDetailMobile ? `${t('listing.item_detail')}` : `${t('listing.lend')}`}
            />
          )
        }
        kActions={showItemDetailMobile ? (
          <>
            <Stack flexDirection="row" alignItems="center" gap={1}>
              <Button
                onClick={() => openEditItemPage(idCatLndItem)}
                variant="outlined"
                sx={{
                  borderRadius: 3,
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                  border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
                }}
                startIcon={<CreateIcon />}
              >
                {t('listing.edit')}
              </Button>
              <Button
                onClick={() => openShareModal(idCatLndItem)}
                variant="outlined"
                sx={{
                  textTransform: 'capitalize',
                  borderRadius: '100%',
                  width: '44px',
                  height: '44px',
                  minWidth: '44px',
                  border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
                }}
              >
                <IosShareIcon sx={{ color: LmiTheme.light.accent }} />
              </Button>
            </Stack>
          </>
        ) : (
          <Stack flexDirection="row" alignItems="center" gap={1} justifyContent="center">
            <IconButton
              onClick={() => openShareModal(idCatLndItem)}
              sx={{
                border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
              }}
            >
              <IosShareIcon sx={{ color: LmiTheme.light.accent }} />
            </IconButton>
            <UserMenu />
          </Stack>
        )}
      />
      <ContentLayout kPaddingXs={0}>
        {!token || items
          ? (
            <Grid container>
              {items && items.length > 0 ? (
                <>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{
                      position: 'relative',
                      borderRight: isMobile ? 'none' : `1px solid ${LmiTheme.light.systemBackground.separator}`,
                      display: {
                        xs: showItemDetailMobile ? 'none' : 'inherit',
                        md: 'inherit',
                      },
                    }}
                  >
                    <CardLend
                      kTitle={t('listing.lend')}
                      kActions={(
                        <Button
                          onClick={() => setOpenShareListing(true)}
                          variant="outlined"
                          sx={{
                            borderRadius: 3,
                            fontWeight: 'bold',
                            textTransform: 'capitalize',
                            border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
                          }}
                          startIcon={<IosShareIcon />}
                        >
                          {t('listing.share')}
                        </Button>
                      )}
                      kTopBar={(
                        <>
                          <Typography component="span" fontSize={14} sx={{ textTransform: 'capitalize' }}>
                            {t('listing.listings')}
                            {' '}
                          </Typography>
                          <Typography component="span" fontWeight="bold" fontSize={14} sx={{ textTransform: 'capitalize' }}>
                            {items.length}
                          </Typography>
                        </>
                      )}
                      kContent={(
                        <>
                          <Box sx={{ display: { xs: 'none', md: 'inherit' } }}>
                            <MyListing
                              onChange={(itemSelected: AddItemInfoModel, index: number) => {
                                console.log(itemSelected);
                                onChange(itemSelected, index);
                              }}
                            />
                          </Box>
                          <Box sx={{ display: { xs: 'inherit', md: 'none' } }}>
                            <GridListings
                              onChange={(itemSelected: AddItemInfoModel, index: number) => {
                                onChange(itemSelected, index);
                              }}
                            />
                          </Box>
                        </>
                      )}
                    />
                    <Box sx={{
                      display: { xs: 'none', md: 'initial' },
                      background: 'white',
                      position: 'absolute',
                      bottom: '0',
                      width: '100%',
                    }}
                    >
                      <Divider />
                      <Box sx={{
                        background: 'white', p: 2,
                      }}
                      >
                        <Button
                          variant="contained"
                          onClick={() => openAddItemPage()}
                          sx={{
                            width: '100%',
                            height: 56,
                            borderRadius: 3,
                            fontWeight: 'bold',
                            textTransform: 'capitalize',
                            boxShadow: 'none',
                          }}
                          startIcon={<AddIcon />}
                        >
                          {t('listing.add_item')}
                        </Button>
                      </Box>
                    </Box>

                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={8}
                    sx={{
                      display: {
                        xs: showItemDetailMobile ? 'block' : 'none',
                        md: 'initial',
                      },
                    }}
                  >
                    <CardLend
                      kTitle={t('listing.item_detail')}
                      kActions={(

                        <Stack
                          flexDirection="row"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <Button
                            onClick={() => openEditItemPage(idCatLndItem)}
                            variant="outlined"
                            sx={{
                              mr: 2,
                              borderRadius: 3,
                              fontWeight: 'bold',
                              textTransform: 'capitalize',
                              border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
                            }}
                            startIcon={<CreateIcon />}
                          >
                            {t('listing.edit')}
                          </Button>
                          <Button
                            onClick={() => openShareModal(idCatLndItem)}
                            variant="outlined"
                            sx={{
                              borderRadius: 3,
                              fontWeight: 'bold',
                              textTransform: 'capitalize',
                              border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
                            }}
                            startIcon={<IosShareIcon />}
                          >
                            {t('listing.share')}
                          </Button>

                        </Stack>
                      )}
                      kContent={(<ItemDetail />)}
                    />
                  </Grid>
                  {!showItemDetailMobile ? (
                    <Box
                      sx={{
                        position: 'fixed',
                        zIndex: 1,
                        bottom: 30,
                        left: 0,
                        right: 0,
                        margin: '0 auto',
                        width: '100%',
                        textAlign: 'center',
                        justifyContent: 'center',
                        display: { xs: 'inherit', md: 'none' },
                      }}
                    >

                      <Fab
                        aria-label="add listing"
                        color="primary"
                        onClick={() => openAddItemPage()}
                      >
                        <AddIcon />
                      </Fab>
                    </Box>
                  ) : ''}
                </>
              ) : (
                <Grid item xs={12}>
                  <CardLend
                    kTitle={t('listing.lend')}
                    kContent={(
                      <EmptyState
                        kImage={empty_lend}
                        kTitle={t('listing.ready_to_jump')}
                        kDescription={t('listing.average_earn')}
                        kAction={(
                          <Button
                            onClick={() => openAddItemPage()}
                            // href="/listing/0"
                            disableElevation
                            variant="contained"
                            size="large"
                            sx={{
                              fontSize: 'subtitle1.fontSize',
                              textTransform: 'none',
                              fontWeight: 'bold',
                              height: 56,
                              borderRadius: 3,
                            }}
                          >
                            {t('listing.start_listing')}
                          </Button>
                        )}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          )
          : <CardLend kTitle={t('listing.lend')} kContent={(<LoadingLayout />)} />}
      </ContentLayout>
      <DialogCommon
        kTitle={t('listing.share_item')}
        kMaxWidth="sm"
        kEnableBorderRadius
        kMaxHeight={isMobile ? '450px' : 'auto'}
        kBorderRadius={isMobile ? 0 : '10px'}
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kDisableContentPadding
        kKeepBtnSize
        kCenterTitle
        kSlideTransition={isMobile}
        kTopDivider
        kOpenDialogLists={openShare}
        kOnClose={() => setOpenShare(false)}
        kDisableActions
        kContent={
          (
            <ShareItem
              deepLink={item?.itemDeepLink}
              itemName={item?.name}
              itemDescription={item?.dscription}
            />
          )
        }
      />
      <DialogCommon
        kTitle={t('listing.share_listing')}
        kMaxWidth="sm"
        kEnableBorderRadius
        kMaxHeight={isMobile ? '450px' : 'auto'}
        kBorderRadius={isMobile ? 0 : '10px'}
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kDisableContentPadding
        kKeepBtnSize
        kCenterTitle
        kSlideTransition={isMobile}
        kTopDivider
        kOpenDialogLists={openShareListing}
        kOnClose={() => setOpenShareListing(false)}
        kDisableActions
        kContent={
          (
            <ShareListings
              dynamicLink={dynamicLink}
            />
          )
        }
      />
    </>
  );
}

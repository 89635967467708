/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  GoogleMap, useJsApiLoader,
  //   Marker,
  //   Circle,
  DirectionsRenderer,
  Marker,
  // Circle,
} from '@react-google-maps/api';
// import { useTranslation } from 'react-i18next';
// import { toast } from 'react-toastify';
import {
  Box, useMediaQuery, useTheme,
} from '@mui/material';
import { MapModels } from './Map.models';
// import deliveryActivityContext from '../../../../../context/deliveryActivity/deliveryActivityContext';

import deliveryActivityContext from '../../../context/deliveryActivity/deliveryActivityContext';
import courierMarker from '../../../assets/map/map_marker_courier.svg';
import pickUpMarker from '../../../assets/map/map_marker_solid_gray.svg';
import dropOffMarker from '../../../assets/map/map_marker_solid_brandBlue.svg';
import socketContext from '../../../context/socket/socketContext';

const _mapStyleLight = [
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#7c93a3',
      },
      {
        lightness: '-10',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#c2d1d6',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#dde3e3',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#c2d1d6',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#a9b4b8',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#a3c7df',
      },
    ],
  },
];

export default function MapRoadie({
  state,
  funUpdateInfo,
  //   kDraggable,
  kOverMap,
  zoom,
  usrLat,
  usrLng,
  kChangeLocation,
  //   exactAddress = true,
  kShowSearchedCircles,
  kDisableBorder,
  kFullHeight,
}: MapModels) {
  const {
    lat, long,
  } = state ?? {
    height: null, lat: null, long: null,
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const deliveryActivityContextLog = useContext(deliveryActivityContext);
  const SocketContext = useContext(socketContext);
  const {
    collectLatLng, returnLatLng, deliveryId,
  }: any = SocketContext;
  const {
    collectShipment, returnShipment,
  }: any = deliveryActivityContextLog;

  const containerStyle = {
    width: '100%',
    height: kFullHeight && isMobile ? '100%' : '89%',
    minHeight: isMobile ? '70vh' : '45vh',
    borderRadius: isMobile || kDisableBorder ? '0px' : '10px',
  };

  // const { t } = useTranslation('global');

  const DEFAULT_LAT = 34.0536909;
  const DEFAULT_LNG = -118.242766;
  const userLat = usrLat ?? DEFAULT_LAT;
  const userLng = usrLng ?? DEFAULT_LNG;
  const usrLatM = lat ? parseFloat(lat) : userLat;
  const usrLngM = long ? parseFloat(long) : userLng;
  const [directions, setDirections]: any = useState(null);

  const [, setMap] = React.useState(null);
  const [center, setCenter] = React.useState({ lat: usrLatM, lng: usrLngM });

  // const [origin, setOrigin] = useState({ lat: collectShipment?.pickUp?.address.latitude! ?? 29.119700, lng: collectShipment?.pickUp?.address.longitude! ?? -110.978960 });
  // const [destination, setDestination] = useState({ lat: collectShipment?.dropOff?.address.latitude! ?? 29.109963, lng: collectShipment?.dropOff?.address.longitude! ?? -110.965920 });

  // const [updateLoc, setUpdateLoc]: any = React.useState(true);
  const [markerDriver, setDriverMarker]: any = useState();

  const [originMarker, setOriginMarker]: any = useState();
  const [destMarker, setDestMarker]: any = useState();
  const [libraries]: any = useState(['places']);
  const mapRef: any = useRef();
  const directionsService: any = new window.google.maps.DirectionsService();

  useEffect(() => {
    const timer = setTimeout(() => {
      setCenter({ lat: usrLatM, lng: usrLngM });
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (usrLatM != 0 && usrLngM != 0) {
      setCenter({
        lat: usrLatM,
        lng: usrLngM,
      });
    }
    if (mapRef.current != undefined) {
      mapRef.current.setZoom(16);
    }
  }, [usrLatM, usrLngM]);

  useEffect(() => {
    if (collectShipment?.dropOff?.address.latitude) {
      setCenter({
        lat: collectShipment?.dropOff?.address.latitude,
        lng: collectShipment?.dropOff?.address.longitude,
      });
      const newOrigin = { lat: collectShipment?.pickUp?.address.latitude! ?? 29.119700, lng: collectShipment?.pickUp?.address.longitude! ?? -110.978960 };
      const newDestintation = { lat: collectShipment?.dropOff?.address.latitude! ?? 29.109963, lng: collectShipment?.dropOff?.address.longitude! ?? -110.965920 };

      directionsService.route(
        {
          origin: newOrigin,
          destination: newDestintation,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result: any, status: any) => {
          if (status === google.maps.DirectionsStatus.OK) {
            setOrigAndDestMarker(newOrigin, newDestintation);
            setDirections(result);
          } else {
            console.error(`error fetching directions ${result}`);
          }
        },
      );
    }
    if (mapRef.current != undefined) {
      mapRef.current.setZoom(16);
    }
  }, [collectShipment, returnShipment]);

  useEffect(() => {
    if (collectShipment?.dropOff?.address.latitude) {
      // let driverLocation = {};
      if (deliveryId) {
        if (deliveryId == collectShipment.id) {
          const latAdd = collectLatLng?.lat ?? collectShipment?.pickUp?.address.latitude!;
          const lngAdd = collectLatLng?.lng ?? collectShipment?.pickUp?.address.longitude!;
          // driverLocation = { lat: latAdd, lng: lngAdd };
          setCenterAndDriver(latAdd, lngAdd);
        } else {
          const latAdd = returnLatLng?.lat ?? returnShipment?.pickUp?.address.latitude!;
          const lngAdd = returnLatLng?.lng ?? returnShipment?.pickUp?.address.longitude!;
          // driverLocation = { lat: latAdd, lng: lngAdd };
          setCenterAndDriver(latAdd, lngAdd);
        }
      }
      if (mapRef.current != undefined) {
        mapRef.current.setZoom(16);
      }
    }
  }, [collectLatLng, returnLatLng, deliveryId]);

  const setCenterAndDriver = (latR: any, lng: any) => {
    setCenter({
      lat: latR,
      lng,
    });
    setDriverMarker(<Marker
      clickable
      key={deliveryId} // Need to be unique
      onClick={() => { }}
      position={{
        lat: parseFloat(latR),
        lng: parseFloat(lng),
      }}
      icon={{
        url: courierMarker,
        scaledSize: new google.maps.Size(50, 50),
      }}
    />);
  };

  const setOrigAndDestMarker = (originCoords: any, destCoords: any) => {
    setOriginMarker(<Marker
      clickable
      key={deliveryId} // Need to be unique
      onClick={() => { }}
      position={{
        lat: originCoords?.lat,
        lng: originCoords?.lng,
      }}
      icon={{
        // path: google.maps.SymbolPath.CIRCLE,
        fillColor: 'green',
        fillOpacity: 0.2,
        strokeColor: 'white',
        strokeWeight: 0.5,
        url: pickUpMarker,
        scaledSize: new google.maps.Size(50, 50),
      }}
    />);

    setDestMarker(<Marker
      clickable
      key={deliveryId} // Need to be unique
      onClick={() => { }}
      position={{
        lat: destCoords?.lat,
        lng: destCoords?.lng,
      }}
      icon={{
        url: dropOffMarker,
        // url: Courier,
        fillColor: 'blue',
        fillOpacity: 0.2,
        strokeColor: 'white',
        scaledSize: new google.maps.Size(50, 50),
      }}
    />);
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
  });

  const onLoad = React.useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds();
    map.setZoom(zoom);
    map.fitBounds(bounds);

    mapRef.current = map;
    // directionsRenderer.setMap(mapRef.current);
    mapRef.current.fitBounds(bounds);
  }, []);

  const onUnmount = React.useCallback(() => {
    setMap(null);
    mapRef.current = null;
  }, []);

  useEffect(() => {
    geocodeLatLng();
  }, [kChangeLocation]);

  // useEffect(() => {
  //   geocodeLatLng();
  // }, [updateLoc]);

  const directionsRendererRef: any = useRef();

  useEffect(() => {
    if (directionsRendererRef.current) {
      directionsRendererRef.current.setOptions({ suppressMarkers: true });
    }
  }, []);

  async function geocodeLatLng() {
    if (usrLatM === 0 || usrLngM === 0) {
      return;
    }

    try {
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${usrLatM},${usrLngM}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}`);
      const data = await response.json();

      if (!funUpdateInfo) {
        return;
      }

      const addressComponents = data.results[0]?.address_components.reduce((acc: any, curr: any) => {
        const { short_name, long_name, types } = curr;

        if (types.includes('colloquial_area')) {
          acc.streetAddress = long_name;
        }
        if (types.includes('street_number')) {
          acc.streetAddress = `${acc.streetAddress} ${long_name}`.trim();
        }
        if (types.includes('route')) {
          acc.streetAddress = `${acc.streetAddress} ${long_name}`.trim();
        } else if (types.includes('sublocality')) {
          acc.extendedAddress = long_name;
        } else if (types.includes('locality')) {
          acc.locality = long_name;
        } else if (types.includes('administrative_area_level_1')) {
          acc.region = short_name;
        } else if (types.includes('postal_code')) {
          acc.postalCode = long_name;
        } else if (types.includes('country')) {
          acc.countryName = long_name;
        }
        return acc;
      }, {
        streetAddress: '',
        extendedAddress: '',
        locality: '',
        region: '',
        postalCode: '',
        countryName: '',
      });

      mapRef.current?.setZoom(16);
      funUpdateInfo({
        ...state,
        address: data.results[0].formatted_address,
        long: usrLngM ? usrLngM.toString() : state?.long,
        lat: usrLatM ? usrLatM.toString() : state?.lat,
        ...addressComponents,
      });

      setCenter({
        lat: usrLatM,
        lng: usrLngM,
      });
    } catch (error) {
      console.error(error);
    }
  }

  return isLoaded ? (
    kShowSearchedCircles ? (
      <Box sx={{
        'img[src$="#circular_marker_image"]': { borderRadius: '50%' },
        height: 'inherit',
        width: 'inherit',
      }}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          ref={mapRef}
          center={center}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={{ disableDefaultUI: true, styles: _mapStyleLight }}
          zoom={15}
        >

          {markerDriver}

          {directions && (
            <DirectionsRenderer directions={directions} />
          )}
          {originMarker}
          {destMarker}

        </GoogleMap>
      </Box>
    ) : (
      <GoogleMap
        mapContainerStyle={containerStyle}
        ref={mapRef}
        center={center}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{ disableDefaultUI: true, styles: _mapStyleLight }}
      >
        { /* Child components, such as markers, info windows, etc. */}
        <>

          {markerDriver}
          {/* <DirectionsRenderer
            directions={directions}
          /> */}

          {originMarker}
          {destMarker}
          <DirectionsRenderer
            directions={directions}
            options={{ suppressMarkers: true }}
          />
          <div style={{ opacity: 0.99, padding: 12 }}>
            {kOverMap}
          </div>
        </>
      </GoogleMap>
    )
  ) : <></>;
}

import {
  Box,
  Divider, Radio, Stack, Typography,
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';

export default function ReportDialog() {
  const handleChange = () => {};

  const { t } = useTranslation('global');

  const reportReasons = [
    {
      reason: t('circles.members.scam'),
    },
    {
      reason: t('circles.members.inappropriate_content'),
    },
    {
      reason: t('circles.members.spam'),
    },
    {
      reason: t('circles.members.other'),
    },
  ];
  return (
    <Box>
      <Typography color="#999da2" sx={{ mb: '16px', px: 2 }}>
        {t('circles.members.report_reason')}
      </Typography>
      <Divider />
      {
        reportReasons.map((report) => (
          <div key={uuid()}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              px={2}
              py={1}
            >
              <Typography>{report.reason}</Typography>
              <Radio
                onChange={handleChange}
                value={0}
                name="radio-buttons"
              />
            </Stack>
            <Divider />
          </div>
        ))
      }
    </Box>

  );
}

import { LmiTheme } from '../../../../../../components/Common';

const ActivityPaymentStyle = {
  width: '100%',
  '.lmiw-activity-payment': {
    borderRadius: '10px',
    color: LmiTheme.light.systemBackground.secondary,
    '.lmiw-payment-concept': {
      py: 1,
    },
  },

};

export default ActivityPaymentStyle;

import { DialogContent, DialogProps } from '@mui/material';
import { useState } from 'react';
import { DialogCommonContentModels } from './DialogContent.models';

function DialogCommonContent(props: DialogCommonContentModels) {
  const [scroll] = useState<DialogProps['scroll']>('paper');

  return (
    <>
      <DialogContent
        dividers={props.kDisableBottomDivider ? false : scroll === 'paper'}
        sx={{
        //   px: isMobile ? 1 : undefined,
          position: 'relative',
          px: props.kContentPadding ? 0 : { xs: 2, sm: 10, md: 3 },
          py: props.kDisableContentPaddingInY ? 0 : 3,
          overflow: props.KDisableScroll ? 'hidden' : undefined,
        }}
      >
        {props.kContent}
      </DialogContent>
    </>
  );
}

export default DialogCommonContent;

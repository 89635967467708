/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { useEffect, useContext } from 'react';
import {
  Grid,
  Button, Box, Divider, IconButton, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import AddIcon from '@mui/icons-material/Add';
import { ArrowBackIosRounded } from '@mui/icons-material';
import MyCirclesListingContext from '../../../../context/myCirclesListing/myCirclesListingContext';
import AddEditCirclesContext from '../../../../context/circles/addEditCirclesContext';
import { CircleDetailInfoModel } from '../AddCircle/AddCircle.models';
import LmiTheme from '../../../../themes/lmi-mui-theme/lmi-theme';
import CardLend from '../../Listing/Components/CardLend/CardLend';
import CircleTopBar from '../Components/CircleTopBar/CircleTopBar';
import UserCircles from '../UserCircles/UserCircles';
import SecondTopBar from '../Components/SecondTopBar/SecondTopBar';
import EmptyCircles from '../EmptyCircles/EmptyCircles';

interface PropsT {
  viewMap: boolean,
  isMobile: boolean,
  firstTitle: string,
  secondTitle: string,
  circlesFound: boolean,
  // openMapView: boolean,
  longAddress: string,
  showSearchedCircles: any,
  openCircleDetail: boolean,
  openSearchCircle: boolean,
  searchedCirclesInfo: boolean,
  areSuggestedCircles: boolean,
  areCirclesIBelong: boolean,
  hasCircles: boolean,
  onChange: (circleSelected: CircleDetailInfoModel, index: number) => void
  openDetails: (e: boolean) => void
  openAddCirclePage: () => void,
  handleOpenMapView: (e: boolean) => void
  handleOpenSearchCircle: (e: boolean) => void
  handleSearchedCirclesInfo: (e: boolean) => void
  handleCirclesFound: (e: boolean) => void
  handleAreSuggestedCircles: (e: boolean) => void
  handleAreCirclesIBelong: (e: boolean) => void
  handleHasCircles: (e: boolean) => void
  handleViewMap: (e: boolean) => void
  changeCircleListingToShow: (e: string) => void
  handleShowSearchedCircles: (e: any) => void
}

const SideBarCircles = (props: PropsT) => {
  const {
    isMobile,
    viewMap,
    firstTitle,
    // openMapView,
    secondTitle,
    longAddress,
    circlesFound,
    showSearchedCircles,
    openCircleDetail,
    openSearchCircle,
    searchedCirclesInfo,
    areSuggestedCircles,
    areCirclesIBelong,
    hasCircles,
    onChange,
    openDetails,
    openAddCirclePage,
    handleCirclesFound,
    handleOpenSearchCircle,
    handleAreSuggestedCircles,
    handleOpenMapView,
    handleSearchedCirclesInfo,
    changeCircleListingToShow,
    handleAreCirclesIBelong,
    handleHasCircles,
    handleViewMap,
    handleShowSearchedCircles,
  } = props;

  const circlesContextLog = useContext(AddEditCirclesContext);
  const { t } = useTranslation('global');

  const {
    circles,
    circlesTypeToShow,
  }: any = useContext(MyCirclesListingContext);
  const {
    cleanErrorsCircles,
  }: any = circlesContextLog;

  useEffect(() => {
    cleanErrorsCircles();
  }, []);

  return (
    <Grid
      item
      xs={12}
      md={4}
      sx={{
        position: 'relative',
        borderRight: isMobile ? 'none' : `1px solid ${LmiTheme.light.systemBackground.separator}`,
        display: {
          xs: openCircleDetail || viewMap ? 'none' : 'inherit',
          md: 'inherit',
        },
      }}
    >
      {
        isMobile && !openSearchCircle && circles?.length == 0 ? (
          <CardLend
            kTitle={t('circles.main.circles')}
            kOpenSearchCircle={openSearchCircle}
            // Button to open of input search
            kActions={(
              !openSearchCircle ? (
                <Button
                  onClick={() => {
                    handleOpenSearchCircle(true);
                    changeCircleListingToShow('suggested');
                  }}
                  variant="outlined"
                  sx={{
                    borderRadius: 3,
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                    border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
                  }}
                  startIcon={<SearchOutlinedIcon />}
                >
                  {t('global.search')}
                </Button>
              ) : showSearchedCircles?.length != 0 && showSearchedCircles != undefined ? (
                <Button
                  onClick={() => {
                    handleViewMap(!viewMap);
                  }}
                  variant="outlined"
                  sx={{
                    borderRadius: 3,
                    fontWeight: 'bold',
                    textTransform: 'none',
                    ml: 0,
                    border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
                  }}
                  startIcon={<PlaceOutlinedIcon />}
                >
                  {!viewMap ? t('circles.main.map_view') : t('circles.main.map_close')}
                </Button>
              ) : <></>
            )}
            // Show the content in the list circles
            kContent={(
              <>
                <EmptyCircles />
              </>
            )}
          />
        ) : (
          <>
            <CardLend
              kTitle={!openSearchCircle ? t('circles.main.circles') : t('circles.main.search_circles')}
              kHideDividers={!circlesFound}
              kHideSecondTopBarDivider={!areSuggestedCircles}
              kOpenSearchCircle={openSearchCircle}
              // Button to open of input search
              kActions={(
                !openSearchCircle ? (
                  <Button
                    onClick={() => {
                      handleOpenSearchCircle(true);
                      changeCircleListingToShow('suggested');
                    }}
                    variant="outlined"
                    sx={{
                      borderRadius: 3,
                      fontWeight: 'bold',
                      textTransform: 'capitalize',
                      border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
                    }}
                    startIcon={<SearchOutlinedIcon />}
                  >
                    {t('global.search')}
                  </Button>
                ) : showSearchedCircles?.length != 0 && showSearchedCircles != undefined ? (
                  <Button
                    onClick={() => {
                      handleViewMap(!viewMap);
                    }}
                    variant="outlined"
                    sx={{
                      borderRadius: 3,
                      fontWeight: 'bold',
                      textTransform: 'none',
                      ml: 0,
                      border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
                    }}
                    startIcon={<PlaceOutlinedIcon />}
                  >
                    {!viewMap ? t('circles.main.map_view') : t('circles.main.map_close')}
                  </Button>
                ) : <></>
              )}
              // Button to back the list circles
              kBackAction={(
                (openSearchCircle && (hasCircles || areCirclesIBelong)) ? (
                  <IconButton
                    aria-label="back"
                    onClick={() => {
                      handleOpenSearchCircle(false);
                      changeCircleListingToShow('myCircles');
                      handleSearchedCirclesInfo(false);
                      handleCirclesFound(true);
                      handleViewMap(false);
                    }}
                    sx={{
                      paddingLeft: 0,
                    }}
                  >
                    <ArrowBackIosRounded sx={{ color: LmiTheme.light.accent }} />
                  </IconButton>
                ) : <></>
              )}
              // Title of list circles
              kTopBar={(
                <>
                  <CircleTopBar
                    kFirstTitle={
                      openSearchCircle && showSearchedCircles?.length == 0
                        ? ''
                        : firstTitle
                    }
                    longAddress={longAddress}
                    kSearchedCirclesSection={openSearchCircle}
                    kSetCirclesFound={(e: boolean) => handleCirclesFound(e)}
                    kCirclesFound={circlesFound}
                    kSearchedCircles={searchedCirclesInfo}
                    kSetSearchedCircles={(e: boolean) => handleSearchedCirclesInfo(e)}
                    kHandleOpenMapView={handleOpenMapView}
                    kShowSearchedCircles={showSearchedCircles}
                  />
                </>
              )}
              // Show the content in the list circles
              kContent={(
                <>
                  <Box sx={{ display: { sm: 'inherit', md: 'none' } }}>
                    <Box mx={2} my={1}>
                      <CircleTopBar
                        kFirstTitle={
                          circlesFound && showSearchedCircles?.length != 0
                            ? firstTitle
                            : ''
                        }
                        longAddress={longAddress}
                        kSearchedCirclesSection={openSearchCircle}
                        kSetCirclesFound={(e: boolean) => handleCirclesFound(e)}
                        kCirclesFound={circlesFound}
                        kSearchedCircles={searchedCirclesInfo}
                        kSetSearchedCircles={(e: boolean) => handleSearchedCirclesInfo(e)}
                        kHandleOpenMapView={handleOpenMapView}
                        kShowSearchedCircles={showSearchedCircles}
                      />
                    </Box>
                  </Box>
                  <Divider sx={{ display: isMobile && circlesFound && showSearchedCircles?.length != 0 ? 'inherit' : 'none' }} />
                  <Box sx={{ display: { md: 'inherit' } }}>
                    <UserCircles
                      kUserCircles
                      kOpenCircleDetail={(e: any) => openDetails(e)}
                      onChange={(circleSelected: CircleDetailInfoModel, index: number) => {
                        onChange(circleSelected, index);
                      }}
                      kIsOwnerOfCircles={handleHasCircles}
                      kShowSearchedCircles={handleShowSearchedCircles}
                    />
                    <Typography
                      fontSize={14}
                      sx={{
                        textAlign: 'center',
                        color: '#999da2',
                        p: 3,
                        display: !openSearchCircle || !hasCircles ? 'inherit' : 'none',
                      }}
                    >
                      {!hasCircles && t('circles.main.not_owner')}
                    </Typography>
                  </Box>
                </>
              )}
              // Show the title in the under list
              kSecondTopBar={(
                <SecondTopBar
                  kCirclesUserBelong
                  kSecondTitle={secondTitle}
                  kOpenSearchCircle={openSearchCircle}
                />
              )}
              // Show the circles in the under list
              kSecondContent={(
                <>
                  {(circlesTypeToShow != 'searched') && (
                    <>
                      <Box sx={{ display: { sm: 'inherit', md: 'none' } }}>
                        <Box mx={2} my={1}>
                          <SecondTopBar
                            kCirclesUserBelong
                            kSecondTitle={secondTitle}
                            kOpenSearchCircle={openSearchCircle}
                          />
                        </Box>
                        <Divider />
                      </Box>
                      <Box sx={{ display: { md: 'inherit' } }}>
                        <UserCircles
                          kUserCircles={false}
                          kOpenCircleDetail={openDetails}
                          onChange={(circleSelected: CircleDetailInfoModel, index: number) => {
                            onChange(circleSelected, index);
                          }}
                          kAreSuggestedCircles={(e: any) => handleAreSuggestedCircles(e)}
                          kAreCirclesIBelong={handleAreCirclesIBelong}
                        />
                        {
                          circlesTypeToShow == 'suggested' ? (
                            <>
                              <Typography
                                fontSize={14}
                                sx={{
                                  textAlign: 'center',
                                  color: '#999da2',
                                  p: 3,
                                  display: !areSuggestedCircles ? 'inherit' : 'none',
                                }}
                              >
                                {t('circles.main.no_near_circles')}
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Typography
                                fontSize={14}
                                sx={{
                                  textAlign: 'center',
                                  color: '#999da2',
                                  p: 3,
                                  display: !areCirclesIBelong ? 'inherit' : 'none',
                                }}
                              >
                                {t('circles.main.dont_belong_circle')}
                              </Typography>
                            </>
                          )
                        }
                        <Box sx={{ pb: 8 }} />
                      </Box>
                    </>
                  )}
                </>
              )}
            />
            {
              // Show a button to create a new circle
              <Box sx={{
                display: { xs: 'none', md: 'initial' },
                background: 'white',
                position: 'absolute',
                bottom: '0',
                width: '100%',
              }}
              >
                <Box sx={{
                  background: 'white', p: 2,
                }}
                >
                  <Button
                    variant="contained"
                    onClick={() => openAddCirclePage()}
                    sx={{
                      width: '100%',
                      height: 56,
                      borderRadius: 3,
                      fontWeight: 'bold',
                      fontSize: '18px',
                      textTransform: 'none',
                      boxShadow: 'none',
                    }}
                    startIcon={<AddIcon />}
                  >
                    {t('circles.main.create_circle')}
                  </Button>
                </Box>
              </Box>
            }
          </>
        )
      }
    </Grid>
  );
};

export default SideBarCircles;

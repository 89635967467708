import { useContext, useEffect } from 'react';
import {
  Box, Link, Stack, Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';

import { isMobile, isMacOs } from 'react-device-detect';
import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import HeaderSwiper from './Components/HeaderSwiper/HeaderSwiper';
import CategoriesSwiper from './Components/CategoriesSwiper/CategoriesSwiper';
import FindOrListAnything from './Components/FindOrListAnything/FindOrListAnything';
import NewFeatures from './Components/NewFeatures/NewFeatures';
import WhyLendMeIt from './Components/WhyLendMeIt/WhyLendMeIt';
import SubscriptionForNews from './Components/SubscriptionForNews/SubscriptionForNews';
import BannerPromo from './Components/BannerPromo/BannerPromo';

import VideoClassroom from '../../../assets/video_play/video_classroom.jpg';
import saveMoney from '../../../assets/earn_save_money/save.webp';
import earnMoney from '../../../assets/earn_save_money/earn.webp';
import peopleCircle from '../../../assets/banner_circles/people.png';
import bgwallCircle from '../../../assets/banner_circles/bgwall.jpg';
import parallaxMap from '../../../assets/parallax/mapstyle.webp';
import parallaxApp from '../../../assets/parallax/app.png';
import parallaxAppEs from '../../../assets/parallax/app-es.png';
import googlePlay from '../../../assets/header_swiper/google-play-badge-en.png';
import googlePlay_es from '../../../assets/header_swiper/google-play-badge-es.png';
import appStore from '../../../assets/header_swiper/app-store-badge.svg';
import appStore_es from '../../../assets/header_swiper/app-store.png';
import bgPromo from '../../../assets/banner_promo/bg_promo.jpg';

import homeContext from '../../../context/home/homeContext';

import {
  ContentLayout,
  SectionTitle,
  ParallaxImage,
  TwoTabs,
  VideoPlay,
  LmiTheme,
  BannerCircles,
} from '../../../components/Common';

function SubTitle(props: any) {
  return (
    <Typography
      py={1}
      sx={{
        fontSize: {
          xs: '18px', sm: '22px', md: '22px', lg: '24px',
        },
        color: LmiTheme.light.systemBackground.secondary,
        textAlign: { md: 'left', xs: 'center' },
        fontWeight: '300',
      }}
    >
      {props.text}
    </Typography>
  );
}

export default function LandingPage() {
  const history = useHistory();
  const goToCircle = () => {
    history.push('/circle');
  };
  const {
    categories,
    loading,
    getCategories,
  }: any = useContext(homeContext);

  useEffect(() => {
    if (loading && !categories) {
      getCategories();
    }
  }, []);

  const { t } = useTranslation('global');

  const goToPromoCode = () => {
    if (isMobile) {
      window.open('https://lendmeit.app/share-code', '_blank', 'noopener,noreferrer');
    } else if (isMacOs) {
      window.open('https://apps.apple.com/us/app/lend-me-it-rent-everything/id1444352676?ppid=24ff0328-5d7d-40bd-9638-9d3905065f4b', '_blank', 'noopener,noreferrer');
    } else {
      window.open('https://play.google.com/store/apps/details?id=com.lendmeit.app&hl=en&gl=US', '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <>
      <HeaderSwiper />
      <CategoriesSwiper />
      <Box pt={5} pb={5} component="article">
        <ContentLayout>
          <>
            <SectionTitle kMarginTop={0} kMarginBottom={5} kComponent="h2" kVariant="h5" kTitle={t('find_list.find_list').toUpperCase()} />
            <FindOrListAnything />
          </>
        </ContentLayout>
      </Box>
      <Box pt={0} pb={5} component="article">
        <ContentLayout>
          <BannerPromo
            kBackground={bgPromo}
            // kImage={peopleCircle}
            kTitle={t('promo_banner.invite_friends')}
            kText={t('promo_banner.invite_friends_desc')}
            kBtnText={t('promo_banner.invite_friends_btn')}
            kColorBg="rgba(51, 54, 58, 0.6)"
            kBtnColor="black"
            kClick={() => { goToPromoCode(); }}
            // kClick={() => { window.location.replace('https://lendmeit.app/download-app-web'); }}
          />
        </ContentLayout>
      </Box>
      <Box pt={5} pb={5} component="article" sx={{ bgcolor: '#fff' }}>
        <ContentLayout>
          <>
            <SectionTitle kMarginTop={0} kMarginBottom={5} kComponent="h2" kVariant="h5" kTitle={t('new_features.new_features')} />
            <NewFeatures />
          </>
        </ContentLayout>
      </Box>
      <Box pt={10} pb={5} component="article">
        <ContentLayout>
          <BannerCircles
            kBackground={bgwallCircle}
            kImage={peopleCircle}
            kTitle={t('home_page.circles_earning')}
            kBtnText={t('home_page.learn_more')}
            kColorBg="rgba(51, 54, 58, 1)"
            kDark={false}
            kPath="/circle/"
            kClick={() => { goToCircle(); }}
          />
        </ContentLayout>
      </Box>
      <Box pt={5} pb={0} component="article">
        <VideoPlay kBackground={VideoClassroom} kIdYoutube="iwZQbNMyaHU" />
      </Box>
      <Box pt={5} pb={5} component="article" sx={{ bgcolor: '#fff' }}>
        <ContentLayout>
          <TwoTabs
            kTab1={t('home_page.earn_money')}
            kImg1={earnMoney}
            kTitle1={t('home_page.list_earn')}
            kTitleBlue1="Lend Me It!"
            kDesc1={(
              <>
                <SubTitle text={t('home_page.lending')} />
                <SubTitle text={t('home_page.list')} />
              </>
            )}
            kTab2={t('home_page.save_money')}
            kImg2={saveMoney}
            kTitle2={t('home_page.save')}
            kTitleBlue2="Lend Me It?"
            kDesc2={(
              <>
                <SubTitle text={t('home_page.enjoy')} />
                <SubTitle text={t('home_page.rent')} />
              </>
            )}
          />
        </ContentLayout>
      </Box>
      <Box component="article">
        <ParallaxImage
          kImageBg={parallaxMap}
          kHeight={700}
          kLeftElement={(
            <>
              <Typography
                variant="h6"
                color={LmiTheme.light.systemBackground.elevation}
                sx={{
                  textAlign: { xs: 'center', sm: 'left' },
                  letterSpacing: '-1.46px',
                  lineHeight: 1,
                  fontSize: { md: 42, sm: 36, xs: 30 },
                  maxWidth: { xs: 'auto', sm: 300 },
                  mb: { xs: 4, sm: 3 },
                  fontWeight: 'bold',
                }}
              >
                {t('home_page.download_app')}
              </Typography>
              <Stack
                direction={{ sm: 'row', xs: 'column' }}
                justifyContent="flex-start"
                sx={{ width: '100%' }}
                // alignItems="flex-start"
                alignItems={{ md: 'flex-start', xs: 'center' }}
                spacing={2}
              >
                <Link
                  target="_blank"
                  rel="noopener"
                  sx={{ height: { lg: '60', xs: '50' } }}
                  href="https://play.google.com/store/apps/details?id=com.lendmeit.app"
                >
                  {
                  cookies.get('i18next') === 'en' ? (
                    <img src={googlePlay} alt="Google Play" height="55" />
                  ) : (<img src={googlePlay_es} alt="Google Play" height="55" />)
                  }
                </Link>
                <Link
                  target="_blank"
                  rel="noopener"
                  sx={{ height: { lg: '60', xs: '50' } }}
                  href="https://itunes.apple.com/us/app/lend-me-it-lend-rent-easily/id1444352676"
                >
                  {
                  cookies.get('i18next') === 'en' ? (
                    <img src={appStore} alt="AppStore" height="55" />
                  ) : (<img src={appStore_es} alt="AppStore" height="55" />)
                  }
                </Link>
              </Stack>
            </>
          )}
          kRightElement={(
              cookies.get('i18next') === 'en' ? (
                <img
                  style={{
                    boxShadow: '5px 10px 10px 0 rgb(104 129 146 / 36%)',
                    borderRadius: '50px',
                  }}
                  height="680px"
                  alt="app"
          // eslint-disable-next-line import/no-unresolved
                  src={parallaxApp}
                />
              ) : (
                <img
                  style={{
                    boxShadow: '5px 10px 10px 0 rgb(104 129 146 / 36%)',
                    borderRadius: '50px',
                  }}
                  height="680px"
                  alt="app"
            // eslint-disable-next-line import/no-unresolved
                  src={parallaxAppEs}
                />
              )
        )}
        />
      </Box>
      <Box pt={5} pb={5} component="article" sx={{ bgcolor: '#fff' }}>
        <ContentLayout>
          <>
            <SectionTitle kMarginTop={0} kMarginBottom={5} kComponent="h2" kVariant="h5" kTitle={t('why_lmi.why_lmi')} />
            <WhyLendMeIt />
          </>
        </ContentLayout>
      </Box>
      <Box component="article">
        <SubscriptionForNews />
      </Box>

    </>
  );
}

import moment from 'moment';
import Compressor from 'compressorjs';
import cookies from 'js-cookie';
import {
  PRECING_DAILY, PRECING_HOURLY, PRECING_MONTHLY, PRECING_WEEKLY, RENT,
} from '../types';
import { PaymentMethodModel } from '../context/paymentMethods/models/paymentMethod_Model';

export const getKeyValue = function <T extends object, U extends keyof T> (obj: T, key: U) { return obj[key]; };

export function formatDate(date: Date) {
  const day = (`0${date.getDate()}`).slice(-2);
  const month = (`0${date.getMonth() + 1}`).slice(-2);
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

export const formatDate2 = (date: any) => {
  const d = new Date(date);
  let month = (d.getMonth() + 1).toString();
  let day = d.getDate().toString();
  const year = d.getFullYear().toString();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
};

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export function setDateToDateTimePicker(date: Date) {
  const isoString = date.toISOString();
  return isoString.substring(0, (isoString.indexOf('T') | 0) + 6 | 0);
}

export function formatYYYYMMDD(dateFormat: Date) {
  const day = (`0${dateFormat.getDate()}`).slice(-2);
  const _month = (`0${dateFormat.getMonth() + 1}`).slice(-2);
  const _year = dateFormat.getFullYear();

  return `${_year}-${_month}-${day}`;
}

export function getRandomName(name: string) {
  const numerChars = 5;
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let characters = '';
  let letResultName = name;
  for (let index = 0; index < numerChars; index += 1) {
    characters += chars.charAt(Math.floor(Math.random() * 62));
  }
  if (letResultName.indexOf(' ') != -1) {
    const arrName = letResultName.split(' ');
    let _name = arrName[0].charAt(0);
    _name += arrName[1].charAt(0);
    letResultName = _name;
  } else {
    letResultName = `${letResultName.charAt(0)}${letResultName.charAt(1)}`;
  }

  return `${letResultName}${characters}`;
}

export function setTimerAWS(userData: any, history: any) {
  const { state } = history.location;
  const token = userData.signInUserSession.idToken.jwtToken;
  localStorage.setItem('token', token);
  const { payload } = userData.signInUserSession.idToken;
  const { exp } = payload;
  const time = moment.unix(exp);

  history.location.state = { ...state, timeExp: time.toString() };
}

export default function emptyCache() {
  if ('caches' in window) {
    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    // Makes sure the page reloads. Changes are only visible after you refresh.
    // window.location.reload();
  }
}

export const formatDateLocal = (dateString: any) => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  };
  if (cookies.get('i18next') === 'es') {
    return new Date(dateString).toLocaleDateString('es-ES', options);
  } return new Date(dateString).toLocaleDateString([], options);
};

export const onImageDropped = (e: any, callback: any, error?: any) => {
  // eslint-disable-next-line no-new, no-undef
  new Compressor(e, {
    convertTypes: [
      'image/png',
      'image/webp',
      'image/bmp',
      'image/tiff',
    ],
    quality: 0.8,
    convertSize: 1000,
    maxWidth: 4096,
    maxHeight: 4096,
    minWidth: 1080,
    minHeight: 1920,
    success: (compressedResult) => {
      const reader = new FileReader();
      reader.onloadend = () => callback(reader.result);
      reader.readAsDataURL(compressedResult);
    },
    // eslint-disable-next-line no-unused-vars
    error,
  });
};

export const getAccesoryPrice = (accesory: any,
  bookType: string,
  precingType: string) => {
  let accessoriesAmmount = 0;
  if (bookType == RENT) {
    switch (precingType) {
      case PRECING_HOURLY:
        accessoriesAmmount += +accesory.hourlyRentalPrice;
        break;
      case PRECING_DAILY:
        accessoriesAmmount += +accesory.dailyRentalPrice;
        break;
      case PRECING_WEEKLY:
        accessoriesAmmount += +accesory.weeklyRentalPrice;
        break;
      case PRECING_MONTHLY:
        accessoriesAmmount += +accesory.monthlyRentalPrice;
        break;

      default:
        break;
    }
  } else {
    accessoriesAmmount += +accesory.replacementCost;
  }

  return accessoriesAmmount;
};

export function getDefaultPaymentMethod(paymentMethodsList: PaymentMethodModel[]) {
  let defaulPaymentMethod;
  if (paymentMethodsList && paymentMethodsList.length > 0) {
    const objIndex = paymentMethodsList
      .findIndex(((paymentMethod: any) => paymentMethod.defaultCard == true
      ));

    if (objIndex >= 0) {
      defaulPaymentMethod = paymentMethodsList[objIndex];
    }
  }

  return defaulPaymentMethod;
}

export const newDate = new Date();
export const date = newDate.getDate();
export const month = newDate.getMonth();
export const year = newDate.getFullYear();
export const currentDate = new Date(year, month, date);
export const currentDateMonth = new Date(year, month, (date + 1));
export const currentDateYear = new Date((year + 1), month, date);

import {
  Box, Typography, Paper, Stack, IconButton, CardActionArea, Card, useTheme, useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import { useContext, useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { useJsApiLoader } from '@react-google-maps/api';
import {
  DialogCommon, SectionTitle, StaticMap,
} from '../../../../../../components/Common';
import ActivityLocationStyle from './ActivityLocation.style';
import SuggestedMap from '../../../../../../components/Common/Map/MapPlaces';
import { MapData } from '../../../../../../components/Common/Map/Map.models';
import ActivityContext from '../../../../../../context/activity/activityContext';
import { NotifDetail } from '../../../Activity.models';

export default function ActivityLocation({ notifDetail }: NotifDetail) {
  const [openChangeAddressModal, setOpenChangeAddressModal] = useState(false);
  const [title, setTitle] = useState('');
  const [address, setAddress] = useState('');
  const activityContext = useContext(ActivityContext);
  const [firstOpenMap, setFirstOpenMap] = useState(true);
  const [libraries]: any = useState(['places']);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
  });

  const {
    updateLocationActivityDetail,
    openedActivityItem,
  }: any = activityContext;

  const { t } = useTranslation('global');

  useEffect(() => {
    if (notifDetail.notifType == 'LEND') {
      setTitle(`${t('activity.location.your_location')}`);
    } else if (notifDetail.exchangeLat.length > 0) {
      setTitle(`${t('activity.location.lender_location')}`);
    }
  }, []);

  useEffect(() => {
    if (openedActivityItem.exchangeAddress.length > 0) {
      setAddress(openedActivityItem.exchangeAddress);
    } else {
      setAddress(notifDetail?.addressComplete);
    }
  }, [openedActivityItem.exchangeAddress]);

  const updateMapa = (mapaData: MapData) => {
    if (!firstOpenMap) {
      updateLocationActivityDetail(
        mapaData,
      );
    } else {
      setFirstOpenMap(false);
    }
  };

  const openInNewTab = () => {
    const url = `https://www.google.com/maps/search/?api=1&query=${notifDetail.exchangeLat},${notifDetail.exchangeLon}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const uiTheme = useTheme();
  const isMobile = useMediaQuery(uiTheme.breakpoints.down('md'));

  return (
    <>
      {
        // eslint-disable-next-line no-nested-ternary
        (notifDetail.notifType == 'LEND')
          ? (

            <Box sx={ActivityLocationStyle}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                {(!notifDetail.isReviewed && !notifDetail.isLenderDelivery && !notifDetail.isRoadieDelivery) && (
                  <SectionTitle
                    kMarginTop={2}
                    kTitle={title}
                    kFontSize={18}
                  />
                )}
                <Stack
                  className="lmiw-activity-location"
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                >
                  <RoomOutlinedIcon />
                  <Typography className="lmiw-location-address">{address}</Typography>
                  {(notifDetail.notifType == 'LEND' && notifDetail.exchangeLat.length == 0) && (
                    <IconButton onClick={() => setOpenChangeAddressModal(true)} color="primary" aria-label={t('activity.location.edit_location')}>
                      <EditIcon />
                    </IconButton>
                  )}
                </Stack>
              </Stack>
              <Paper
                className="lmiw-activity-map"
                variant="outlined"
                elevation={0}
              >
                <Box m={2} sx={{ borderRadius: '10px', overflow: 'hidden' }}>
                  <StaticMap
                    kLat={parseFloat(notifDetail.exchangeLat != '' ? notifDetail.exchangeLat : notifDetail.lat)}
                    kLng={parseFloat(notifDetail.exchangeLon != '' ? notifDetail.exchangeLon : notifDetail.lon)}
                    kExactLocation
                  />
                </Box>
                {(notifDetail.exchangeLat.length > 0 && notifDetail.notifType == 'LEND') && (
                  <Typography className="lmiw-location-info">
                    {t('activity.location.location_cannot_change')}
                  </Typography>
                )}
              </Paper>
              {isLoaded ? (
                <DialogCommon
                  kTitle={t('activity.location.location')}
                  kSubTitle={t('activity.location.select_location')}
                  kMaxWidth="md"
                  kAlignBtn={isMobile ? 'center' : 'flex-end'}
                  kBorderRadius={!isMobile ? '10px' : 0}
                  kSlideTransition={isMobile}
                  kKeepBtnSize
                  kDisableContentPaddinginY
                  kDisableContentPadding
                  kOpenDialogLists={openChangeAddressModal}
                  kOnClose={() => { setOpenChangeAddressModal(false); }}
                  kOnPrimary={() => { setOpenChangeAddressModal(false); }}
                  kOnSecondary={() => { setOpenChangeAddressModal(false); }}
                  kContent={
                    (
                      <SuggestedMap
                        state={{
                          address: notifDetail?.exchangeAddress ?? notifDetail?.address,
                          lat: notifDetail?.lat,
                          long: notifDetail?.lon,
                          typeItem: 3,
                        }}
                        usrLat={parseFloat(notifDetail?.lat)}
                        usrLng={parseFloat(notifDetail?.lon)}
                        userLatLng={{ lat: notifDetail?.lat, lng: notifDetail?.lon }}
                        funUpdateInfo={updateMapa}
                        kFullHeight
                        kDisableInsideInput
                      />
                    )
                  }
                />
              ) : <></>}
            </Box>
          )
          : (
            (notifDetail?.exchangeLat.length > 0)
              ? (
                <Card
                  sx={{
                    width: '100%',
                  }}
                >
                  <CardActionArea onClick={() => openInNewTab()}>
                    <Box sx={ActivityLocationStyle}>
                      <Paper
                        className="lmiw-activity-map"
                        variant="outlined"
                        elevation={0}
                      >
                        <Box m={2} sx={{ borderRadius: '10px', overflow: 'hidden' }}>
                          <StaticMap
                            kLat={parseFloat(notifDetail.exchangeLat)}
                            kLng={parseFloat(notifDetail.exchangeLon)}
                            kExactLocation
                          />
                        </Box>
                        {(notifDetail.exchangeLat.length > 0 && notifDetail.notifType == 'LEND') && (
                          <Typography className="lmiw-location-info">
                            {t('activity.location.location_cannot_change')}
                          </Typography>
                        )}
                      </Paper>
                    </Box>
                  </CardActionArea>
                </Card>
              )
              : null
          )
      }
    </>
  );
}

import {
  Stack,
  Button,
  Grid,
  Divider,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LmiTheme } from '..';
import bookItemContext from '../../../../../context/bookItem/bookContext';
import ItemLocation from '../../../../../components/Common/ItemLocation/ItemLocation';
import { SummaryModel } from '../../../../../context/authentication/models/summary-Model';
import { MapData } from '../../../../../components/Common/Map/Map.models';
import { DialogCommon } from '../../../../../components/Common';
import ModalMap from '../../../../../components/Common/Map/MapPlaces';

function DialogDeliveryByLender() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation('global');

  const {
    // loading,
    bookDetails: { idCatTypeItem },
    pickupLenderTemp,
    updateLenderPikupInfo_Temp,
    summaryTemp,
  }: any = useContext(bookItemContext);

  const [openChangeAddress, setOpenChangeAddress] = useState(false);

  const bookSummary = summaryTemp as SummaryModel;
  let title = 'place';

  const {
    address, lat, long,
  } = pickupLenderTemp;

  switch (idCatTypeItem) {
    case 0:
      title = 'item';
      break;
    case 1:
      title = 'service';
      break;
    default:
      break;
  }

  const updateMapa = (mapaData: MapData) => {
    updateLenderPikupInfo_Temp(
      {
        ...pickupLenderTemp,
        address: mapaData.address ?? address,
        countryName: mapaData.countryName,
        extendedAddress: mapaData.extendedAddress,
        locality: mapaData.locality,
        postalCode: mapaData.postalCode,
        region: mapaData.region,
        streetAddress: mapaData.streetAddress,
        lat: mapaData.lat,
        long: mapaData.long,
      },
    );
  };

  return (
    <>
      <DialogCommon
        kTitle={`${t('delivery.by_lender.select_location')} ${title}`}
        kMaxWidth="md"
        kOpenDialogLists={openChangeAddress}
        kOnClose={() => { setOpenChangeAddress(!openChangeAddress); }}
        kContent={
        (
          <ModalMap
            state={{
              address,
              lat,
              long,
              typeItem: idCatTypeItem ?? 3,
            }}
            funUpdateInfo={updateMapa}
          />
        )
      }
      />
      <Box px={isMobile ? 2 : 4} pt={2} pb={1}>
        <Typography sx={{ color: LmiTheme.light.systemBackground.tertiary, fontSize: 'subtitle2.fontSize' }}>
          {t('delivery.by_lender.deliver_return')}
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          py={1}
        >
          <Grid item>
            <ItemLocation
              kTextColor={LmiTheme.light.systemBackground.secondary}
              kText={address}
            />
          </Grid>
          <Grid item>
            <Button
              variant="text"
              key="share"
              size="small"
              disableElevation
              disableRipple
              disableFocusRipple
              onClick={() => { setOpenChangeAddress(true); }}
              sx={{
                textTransform: 'none',
                color: LmiTheme.light.accent,
                fontWeight: 'bold',
                fontSize: 'body1.fontSize',
              }}
            >
              {t('delivery.by_lender.change')}
            </Button>
          </Grid>
        </Stack>
        <Divider />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          p={1}
        >
          <Grid item>
            <Typography sx={{
              color: LmiTheme.light.systemBackground.secondary,
              fontSize: 'subtitle1.fontSize',
              fontWeight: 'bold',
            }}
            >
              {t('delivery.roadie.cost')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ color: LmiTheme.light.systemBackground.secondary, fontSize: 'subtitle1.fontSize', fontWeight: 'bold' }}>
              {bookSummary?.self && bookSummary.self.fee > 0 ? `$${bookSummary?.self.fee}` : bookSummary?.self?.message }
            </Typography>
          </Grid>
        </Stack>
      </Box>
      <Divider />
    </>
  );
}

export default DialogDeliveryByLender;

/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Paper, Stack, Typography, Fab, Box, Modal,
  useMediaQuery, useTheme,
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CloseIcon from '@mui/icons-material/Close';
import { VideoPlayModels } from './VideoPlay.models';

export default function VideoPlay(props: VideoPlayModels) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLandscape = useMediaQuery('(orientation: landscape)');
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const handleModalToggle = () => {
    setOpen(!open);
  };

  const { t } = useTranslation('global');

  return (
    <>
      <Paper
        sx={{
          position: 'relative',
          height: '660px',
          backgroundSize: 'cover',
          backgroundImage: `url(${props.kBackground})`,
        }}
      >

        <Stack
          pt={20}
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          sx={{ zIndex: 2, position: 'relative' }}
        >
          <Typography
            color="white"
            variant="h6"
            sx={{
              fontWeight: 'bold', lineHeight: 1, margin: { xs: 0, sm: 0, md: 0 }, fontSize: { xs: '24px', sm: '28px', md: '30px' },
            }}
          >
            {t('video_section.first_string')}

          </Typography>
          <Typography
            color="white"
            variant="h1"
            align="center"
            sx={{
              fontWeight: 'bold', lineHeight: 1, margin: { xs: 0, sm: 0, md: 0 }, fontSize: { xs: '30px', sm: '45px', md: '60px' },
            }}
          >
            {t('video_section.second_string')}
            {' '}
            <br />
            {' '}
            {t('video_section.third_string')}

          </Typography>
          <Fab onClick={() => { handleModalToggle(); }} sx={{ width: '96px', height: '96px' }} color="primary" aria-label="play">
            <PlayArrowIcon sx={{ fontSize: '56px' }} />
          </Fab>
        </Stack>
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            background: 'rgb(0 0 0 / 20%)',
            zIndex: 1,
          }}
        />
      </Paper>
      <Modal
        open={open}
        onClose={handleModalToggle}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={0}
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: 900,
            width: '100%',
          }}
        >
          <iframe
            style={{
              width: 850,
              height: isLandscape && isMobile ? '100vh' : '60vh',
              minHeight: 380,
            }}
            src={`https://www.youtube.com/embed/${props.kIdYoutube}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
          <CloseIcon
            fontSize="large"
            onClick={handleModalToggle}
            sx={{
              color: 'white',
              cursor: 'pointer',
              position: 'absolute',
              top: '-50px',
              right: '0px',
            }}
            aria-label="close modal"
          />
        </Stack>
      </Modal>
    </>
  );
}

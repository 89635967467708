/* eslint-disable no-nested-ternary */
import { useMemo } from 'react';
import {
  Apple,
  Email,
  FacebookRounded,
  Google,
} from '@mui/icons-material';
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LmiTheme, SectionTitle } from '..';
import { VisibleOnlyToYouModels } from './VisibleOnlyToYou.models';

const SOCIAL_NETWORK = {
  facebook: 'Facebook',
  google: 'Google',
  Apple: 'Apple',
};

function VisibleOnlyToYou(props: VisibleOnlyToYouModels) {
  const {
    isCognito, isFb, isGoogle, isApple,
  } = props.kProfileInfo;
  const { t } = useTranslation('global');

  const isLoggedWithIcon = useMemo(() => {
    if (isCognito === 1 && isFb === 0 && isGoogle === 0 && isApple === 0) return <Email sx={{ mr: 1, color: LmiTheme.light.systemBackground.primary }} />;
    if (isCognito === 1) {
      if (isFb === 1) return <FacebookRounded sx={{ mr: 1, color: LmiTheme.light.social.facebook }} />;
      if (isGoogle === 1) return <Google sx={{ mr: 1, color: LmiTheme.light.social.google }} />;
      if (isApple === 1) return <Apple sx={{ mr: 1, color: LmiTheme.light.systemBackground.primary }} />;
    } else {
      if (isFb === 1) return <FacebookRounded sx={{ mr: 1, color: LmiTheme.light.social.facebook }} />;
      if (isGoogle === 1) return <Google sx={{ mr: 1, color: LmiTheme.light.social.google }} />;
      if (isApple === 1) return <Apple sx={{ mr: 1, color: LmiTheme.light.systemBackground.primary }} />;
    }
    return null;
  }, [isCognito, isFb, isGoogle, isApple]);

  const isLoggedWithIconName = useMemo(() => {
    if (isCognito === 1 && isFb === 0 && isGoogle === 0 && isApple === 0) return `${t('profile.email')}`;
    if (isCognito === 1) {
      if (isFb === 1) return SOCIAL_NETWORK.facebook;
      if (isGoogle === 1) return SOCIAL_NETWORK.google;
      if (isApple === 1) return SOCIAL_NETWORK.Apple;
    } else {
      if (isFb === 1) return SOCIAL_NETWORK.facebook;
      if (isGoogle === 1) return SOCIAL_NETWORK.google;
      if (isApple === 1) return SOCIAL_NETWORK.Apple;
    }
    return null;
  }, [isCognito, isFb, isGoogle, isApple]);

  return (
    <>
      <SectionTitle kTitle={props.kTitle} kMarginTop={3} kMarginBottom={1} />
      <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
        <List disablePadding>

          <ListItem>
            <ListItemText
              primaryTypographyProps={{ color: LmiTheme.light.systemBackground.tertiary, fontSize: 'subtitle2.fontSize' }}
              primary={t('profile.email')}
            />

            <ListItemSecondaryAction>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >

                {props.kProfileInfo?.email}
              </Stack>
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem>
            <ListItemText
              primaryTypographyProps={{ color: LmiTheme.light.systemBackground.tertiary, fontSize: 'subtitle2.fontSize' }}
              primary={t('profile.phone_number')}
            />

            <ListItemSecondaryAction>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                {props.kProfileInfo?.phoneNumber}
              </Stack>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primaryTypographyProps={{ color: LmiTheme.light.systemBackground.tertiary, fontSize: 'subtitle2.fontSize' }}
              primary={t('profile.connected_with')}
            />

            <ListItemSecondaryAction>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                {isLoggedWithIcon}
                {isLoggedWithIconName}
                {props.kProfileInfo?.connectedWith}
              </Stack>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Paper>
    </>
  );
}

export default VisibleOnlyToYou;

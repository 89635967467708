import {
  Box, Button, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SubmitButtonModels } from './SubmitButton.models';

function SubmitButton(props: SubmitButtonModels) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation('global');

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      mt: '30px',
      mb: '14px',
    }}
    >
      {
        isMobile ? (
          <Button
            color="primary"
            variant="contained"
            sx={{
              height: '56px',
              width: '94%',
              borderRadius: '10px',
              position: 'fixed',
              bottom: '10px',
              left: '10px',
              zIndex: 10,
            }}
            onClick={() => props.kSubmitCircle()}
          >
            <Typography sx={{
              fontSize: '18px',
              fontWeight: '600',
              color: 'white',
              textTransform: 'none',
            }}
            >
              {t('circles.add_circle_page.submit')}
            </Typography>
          </Button>
        ) : (
          <Button
            onClick={() => props.kSubmitCircle()}
            disableElevation
            variant="contained"
            size="large"
            disabled={props.disabled}
            sx={{
              fontSize: 'subtitle1.fontSize',
              textTransform: 'none',
              fontWeight: 'bold',
              height: '56px',
              borderRadius: 3,
              paddingLeft: '38px',
              paddingRight: '38px',
            }}
          >
            <Typography sx={{
              fontSize: '18px',
              fontWeight: '600',
              color: 'white',
              textTransform: 'none',
            }}
            >
              {t('circles.add_circle_page.submit')}
            </Typography>
          </Button>
        )
      }
    </Box>
  );
}

export default SubmitButton;

import { Box, Typography } from '@mui/material';
import { useRef } from 'react';
import cookies from 'js-cookie';

import { useTranslation } from 'react-i18next';
import {
  ContentLayout, TwoTabs, VideoPlay, BannerCircles, ParallaxImage, BannerCookies,
} from '../../../components/Common';
import LmiTheme from '../../../themes/lmi-mui-theme/lmi-theme';
import LmiColorPalette from '../../../themes/lmi-mui-theme/lmi-color-palette';

import HeaderImage from './Components/HeaderCircle/HeaderCircle';
import HowItsWorks from './Components/HowItsWorks/HowItsWorks';
import CreateCircleSwiper from './Components/CircleSwiper/CircleSwiper';
import videoCircle from '../../../assets/video_play/video_circle.webp';
import createCircle from '../../../assets/create_circle/create_circle1.jpg';
import joinCircle from '../../../assets/create_circle/create_circle2.jpg';
import studentsCircle from '../../../assets/banner_circles/students.png';
import bgschoolCircle from '../../../assets/banner_circles/bgschool.jpg';
import bgapp from '../../../assets/parallax/bgapp.png';
import bgappEs from '../../../assets/parallax/bgapp-es.png';
// import { CircleModels } from './CirclePage.model';

declare const window: any;

export default function CirclePage() {
  const childRef:any = useRef();
  const cookieStorage = {
    getItem: (item: any) => {
      const cookiesList = document.cookie
        .split(';')
        .map((cookie) => cookie.split('='))
        .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
      return cookiesList[item];
    },
    setItem: (item: any, value: any) => {
      const d = new Date();
      const exdays = 7;
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      const expires = `expires=${d.toUTCString()}`;
      document.cookie = `${item}=${value};${expires}`;
    },
  };

  const openChat = () => {
    if (cookieStorage.getItem('consentNecessaryCookies') || cookieStorage.getItem('consentNecessaryCookies') == false) {
      window.zE('webWidget', 'toggle');
    } else {
      childRef.current.refToggleMessagesModal();
    }
  };

  const { t } = useTranslation('global');

  return (
    <>
      <HeaderImage />
      <Box pt={5} pb={5} component="article" sx={{ bgcolor: '#fff' }} id="how-it-works">
        <ContentLayout>
          <>
            <Typography component="h2" variant="h5" mb={5} align="center" fontWeight="bold">
              {t('circles_page.how_works')}
            </Typography>
            <HowItsWorks />
          </>
        </ContentLayout>
      </Box>
      <CreateCircleSwiper />
      <Box pt={5} pb={5} component="article" sx={{ bgcolor: '#fff' }}>
        <ContentLayout>
          <TwoTabs
            kTab1={t('circles_page.create_circle')}
            kImg1={createCircle}
            kTitle1={t('circles_page.benefits')}
            kDesc1={(
              <Box sx={{
                li: {
                  fontWeight: 'bold',
                  fontSize: {
                    xs: '16px', sm: '20px', md: '20px', lg: '22px',
                  },
                  '& p': {
                    fontWeight: '200',
                    mt: 0,
                    mb: 2,
                  },
                },
              }}
              >
                <ul>
                  <li>
                    {t('circles_page.connect')}
                    <p>{t('circles_page.build_community')}</p>
                  </li>
                  <li>
                    {t('circles_page.earn_money')}
                    <p>{t('circles_page.transaction')}</p>
                  </li>
                  <li>
                    {t('circles_page.exclusive')}
                    <p>{t('circles_page.create_listings')}</p>

                  </li>
                </ul>
              </Box>
            )}
            kTab2={t('circles_page.join_circle')}
            kImg2={joinCircle}
            kTitle2={t('circles_page.join_circle_benefits')}
            kDesc2={(
              <Box sx={{
                li: {
                  fontWeight: 'bold',
                  fontSize: {
                    xs: '16px', sm: '20px', md: '20px', lg: '22px',
                  },
                  '& p': {
                    fontWeight: '200',
                    mt: 0,
                    mb: 2,
                  },
                },
              }}
              >
                <ul>
                  <li>
                    {t('circles_page.find_need')}
                    <p>{t('circles_page.find_variety_listings')}</p>
                  </li>
                  <li>
                    {t('circles_page.meet_people')}
                    <p>{t('circles_page.make_friends')}</p>
                  </li>
                  <li>
                    {t('circles_page.more_people')}
                    <p>{t('circles_page.interested_people')}</p>

                  </li>
                </ul>
              </Box>
            )}
          />
        </ContentLayout>
      </Box>
      <Box pb={0} component="article">
        <VideoPlay kBackground={videoCircle} kIdYoutube="fsizTUnqjwA" />
      </Box>

      <Box pt={10} pb={5} component="article">
        <ContentLayout>
          <BannerCircles
            kBackground={bgschoolCircle}
            kImage={studentsCircle}
            kTitle={t('circles_page.organization')}
            kText={t('circles_page.zero_cost')}
            kBtnText={t('circles_page.contact')}
            kBtnColor={LmiTheme.light.systemBackground.primary}
            kColorBg={LmiColorPalette.greenScheme[50]}
            kColorText={LmiTheme.light.systemBackground.primary}
            kClick={() => openChat()}
          />
        </ContentLayout>
      </Box>

      <Box component="article" sx={{ bgcolor: '#fff' }}>
        <Box component="article">
          <ParallaxImage
            kImageBg={cookies.get('i18next') === 'en' ? bgapp : bgappEs}
            kLeftElement={(
              <Typography
                variant="h6"
                color={LmiTheme.light.systemBackground.elevation}
                sx={{
                  textAlign: 'left',
                  letterSpacing: '-1.46px',
                  lineHeight: 1,
                  fontSize: { md: 42, sm: 36, xs: 30 },
                  maxWidth: 300,
                  mb: 2,
                  fontWeight: 'bold',
                }}
              >
                {t('circles_page.start_community')}
              </Typography>
)}
          />
        </Box>
      </Box>

      <BannerCookies
        ref={childRef}
        kShowBanner={false}
      />
    </>
  );
}

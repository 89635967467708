import { Button } from '@mui/material';
import { LocationOnRounded } from '@mui/icons-material';
import LmiTheme from '../../../themes/lmi-mui-theme/lmi-theme';
import { ItemLocationModels } from './ItemLocation.models';

function ItemLocation(props: ItemLocationModels) {
  return (
    <>
      <Button
        onClick={props.kOnClick}
        variant={props.kVariant}
        key={props.kKey}
        size={props.kButtonSize}
        startIcon={(
          <LocationOnRounded
            style={{
              color: props.kIconColor,
              fontSize: props.kIconSIze,
            }}
          />
        )}
        disableElevation
        disableRipple
        disableFocusRipple
        sx={{
          color: props.kTextColor,
          fontWeight: props.kFontWeight,
          fontSize: props.kFontSize,
          textAlign: props.KTextAlign,
          textTransform: props.kTextTransform,
        }}
      >
        {props.kText}
      </Button>
    </>
  );
}

ItemLocation.defaultProps = {
  kKey: 'location',
  kOnClick: undefined,
  kText: '',
  kTextColor: LmiTheme.light.systemBackground.tertiary,
  kFontWeight: 'bold',
  kFontSize: 'subtitle2.fontSize',
  KTextAlign: 'left',
  kTextTransform: 'none',
  kIconColor: LmiTheme.light.accent,
  kIconSize: 22,
  kButtonSize: 'large',
  kVariant: 'text',
};

export default ItemLocation;

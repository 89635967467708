import { useEffect, useReducer, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import RouterContext from './routerContext';
import RouterReducer from './routerReducer';
import {
  CHANGE,
} from '../../types';

const RouterProvider = ({ children }: any) => {
  const location = useLocation();
  const initialState = {
    to: location.pathname,
    from: location.pathname,
  };

  const [state, dispatch] = useReducer(RouterReducer, initialState);
  usePreviousURL(location);

  // useEffect(() => {
  //   const router = (prev:any) => ({ to: location.pathname, from: prev.to });

  //   const route = router(location.pathname);
  //   dispatch({
  //     type: CHANGE,
  //     payload: route,
  //   });
  // }, [location]);

  function usePreviousURL(value:any) {
    const ref:any = useRef();
    useEffect(() => {
      dispatch({
        type: CHANGE,
        payload: { to: value.pathname, from: ref.current?.pathname ?? '' },
      });
    }, [location]);
    ref.current = value;

    return ref.current;
  }

  return (
    <RouterContext.Provider
      value={
     {
       ...state,
       from: state.from,
       to: state.to,
     }
      }
    >
      {children}
    </RouterContext.Provider>
  );
};

export default RouterProvider;

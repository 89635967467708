import { getDefaultPaymentMethod } from '../../services/common';
import {
  GET_BY_ID, UPDATE_DEFAULT_CARD,
  PAYMENT_ADD_EDIT,
  CHECK_NEW_PAYMENT_VALIDATIONS,
  UPDATE_PAYMENT_VALIDATION_STATUS,
  PAYMENT_METHOD_ADDED_SUCCESS,
  UPDATE_BOOKING_CARD,
  DELETED_CARD_SUCCESS,
} from '../../types';

export default (state: any, action: any) => {
  switch (action.type) {
    case GET_BY_ID:
    {
      const { paymentMethodsList } = action.payload;
      const defaultPaymentMethodResp = getDefaultPaymentMethod(paymentMethodsList);

      let defaultPaymentMethod;
      if (defaultPaymentMethodResp) {
        defaultPaymentMethod = defaultPaymentMethodResp;
      }
      if (!defaultPaymentMethodResp && paymentMethodsList.length > 0) {
        [defaultPaymentMethod] = paymentMethodsList;
      }

      return {
        ...state,
        loading: false,
        paymentMethodsList,
        newPaymentMethodInfo: action.payload.newPaymentMethodInfo,
        totalValidCard: action.payload.totalValidCard,
        defaultPaymentMethod,
        bookingCard: defaultPaymentMethod,
      };
    }
    case UPDATE_DEFAULT_CARD:
    {
      const { paymentMethodsList } = action.payload;
      const defaulPaymentMethod = getDefaultPaymentMethod(paymentMethodsList);

      return {
        ...state,
        loading: false,
        paymentMethodsList,
        defaultPaymentMethod: defaulPaymentMethod,
      };
    }
    case UPDATE_BOOKING_CARD:
    {
      return {
        ...state,
        loading: false,
        bookingCard: action.payload.bookingCard,
      };
    }
    case PAYMENT_ADD_EDIT:
    {
      return {
        ...state,
        loading: false,
        newPaymentMethodInfo: action.payload.newPaymentMethodInfo,
        checkPaymentValidate: action.payload.checkPaymentValidate,
      };
    }
    case CHECK_NEW_PAYMENT_VALIDATIONS: {
      return {
        ...state,
        checkPaymentValidate: action.checkPaymentValidate,
        // itemAccessories: action.itemAccessories,
      };
    }
    case UPDATE_PAYMENT_VALIDATION_STATUS: {
      return {
        ...state,
        arePaymentFieldsValidated: action.arePaymentFieldsValidated,
        changedPaymentFields: !state.changedPaymentFields,
        // itemAccessories: action.itemAccessories,
      };
    }
    case PAYMENT_METHOD_ADDED_SUCCESS: {
      return {
        ...state,
        newPaymentMethodInfo: action.newPaymentMethodInfo,
      };
    }
    case DELETED_CARD_SUCCESS: {
      const { paymentMethodsList } = action.payload;
      const defaulPaymentMethod = getDefaultPaymentMethod(paymentMethodsList);
      return {
        ...state,
        paymentMethodsList,
        totalValidCard: action.payload.totalValidCard,
        defaultPaymentMethod: defaulPaymentMethod,
      };
    }
    default:
      return state;
  }
};

import {
  Box, Button, useMediaQuery, useTheme,
} from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import empty_circles from '../../../../../assets/empty_circles/empty_circles.png';
import { EmptyState } from '../../../../../components/Common';
import MyCirclesListingContext from '../../../../../context/myCirclesListing/myCirclesListingContext';
import { emptyCircleModel } from '../../AddCircle/AddCircle.models';

interface PropsT {
  // eslint-disable-next-line react/require-default-props
  hiddenButton?: boolean
}

export default function NoCirclesFound(props: PropsT) {
  const { hiddenButton } = props;
  const {
    details,
  }: any = useContext(MyCirclesListingContext);

  const { t } = useTranslation('global');

  const history = useHistory();

  const state = history.location.state as {
    kCircleId: string,
    kCircleIndex: number,
    updateSwiper: false,
    kShowDetailsMobile: false
  };

  const openAddCirclePage = () => {
    window.scrollTo(0, 0);
    details(emptyCircleModel(), []);
    history.push({
      pathname: '/circles/new',
      state: {
        ...state,
        kCircleId: 0,
        kCircleIsNew: true,
        kCircleIndex: state?.kCircleIndex ? state.kCircleIndex : 0,
        updateSwiper: false,
        kShowDetailsMobile: false,
      },
    });
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ pt: isMobile ? '100px' : '50px' }}>
      <EmptyState
        kImage={empty_circles}
        kTitle={t('circles.main.no_results')}
        kDescription={t('circles.empty_state.description2')}
        kTitleFontSize={18}
        kDescriptionFontSize={16}
        kDescriptionWidth="230px"
        kDisableSpacing
        kContentStart
        kHeightAuto
        kAction={(
          <>
            {
              !hiddenButton ? (
                <Button
                  onClick={() => openAddCirclePage()}
                  disableElevation
                  variant="contained"
                  size="large"
                  sx={{
                    fontSize: 'subtitle1.fontSize',
                    textTransform: 'none',
                    fontWeight: 'bold',
                    height: 56,
                    borderRadius: 3,
                    paddingLeft: '38px',
                    paddingRight: '38px',
                  }}
                >
                  {t('circles.empty_state.button')}
                </Button>

              ) : null
            }
          </>
        )}
      />
    </Box>
  );
}

const style = {
  '.lmi-swiper-slide': {
    height: '100%',
    display: 'flex',
    backgroundSize: 'cover',
    '.lmi-swiper-stack': {
      overflow: 'hidden',
      height: {
        xs: { xs: '450px', sm: '600px' },
      },
      position: 'relative',
      '.caption': {
        zIndex: 2,
        textAlign: 'center',
      },
      '.lmi-swiper-collapse': {
        my: { xs: 2, sm: 4 },
        overflow: 'hidden',
        transition: 'all 1s ease',
        p: {
          fontSize: '18px',
          lineHeight: ' 1.2',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 2,
        },
      },
      '.lmi-btn-accordion': {
        minWidth: '150px',
        backgroundColor: 'transparent',
        height: '44px',
        color: 'white',
        margin: 'auto',
        borderRadius: '50px',
        border: '1px solid white',
        textTransform: 'capitalize',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        svg: {
          transition: 'all 0.5s ease',
        },
      },
    },
  },
  '& .swiper-wrapper': {
    height: { xs: '450px', sm: '600px' },
    mb: 5,
  },

  '& .swiper-slide': {
    borderRadius: '10px',
    overflow: 'hidden',
  },

  '& .swiper-slide .caption': {
    opacity: '0',
    h2: {
      fontSize: { xs: '1.8rem', md: '2.125rem' },
    },
  },

  '& .swiper-slide-active': {
    borderRadius: '10px',
    '& .caption': {
      opacity: '1',
    },
    '& .bg': {
      height: '100%',
      '&.more': {
        height: '150%',
      },
    },
  },

  '& .bg': {
    left: 0,
    bottom: 0,
    width: '100%',
    height: '150%',
    zIndex: 1,
    borderRadius: '10px',
    transition: 'all 1s ease',
    background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);',
  },

  '& .swiper-button-next, .swiper-button-prev': {
    color: 'white',
    borderRadius: '50px',
    border: '2px solid white',
    height: '50px',
    width: '50px',
    padding: '10px',
    '&:after': {
      fontSize: '18px',
      fontWeight: 'bold',
    },
    display: { xs: 'none', sm: 'flex' },
  },
  '& .swiper-button-next': {
    right: {
      xs: '20px', sm: '40px', md: '100px', lg: '150px',
    },
  },
  '& .swiper-button-prev': {
    left: {
      xs: '20px', sm: '40px', md: '100px', lg: '150px',
    },
  },
};

export default style;

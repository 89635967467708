/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable react/jsx-props-no-spreading */
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectCoverflow, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import {
  Box, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import circleItemsSwiperSyle from './CircleItemsSwiper.style';
import { CircleItemsSwiperModels } from './CircleItemsSwiper.models';

SwiperCore.use([EffectCoverflow, Pagination]);

function SwiperContent(props: CircleItemsSwiperModels) {
  const [activeIndex, setActiveIndex] = useState(0);

  props.handleSelectedIndex(activeIndex);

  return (
    <Swiper
      effect="coverflow"
      initialSlide={0}
      preventClicks
      onActiveIndexChange={(element) => setActiveIndex(element.realIndex)}
      slidesPerView={2}
      centeredSlides
      speed={1000}
      loop
      grabCursor
      coverflowEffect={
        {
          rotate: 0,
          stretch: 0,
          depth: 600,
          modifier: 1,
          slideShadows: true,
        }
      }
      breakpoints={{

        1200: {
          slidesPerView: 4,
          coverflowEffect: {
            stretch: -50,
            depth: 100,
          },
        },

        992: {
          slidesPerView: 4,
          coverflowEffect: {
            stretch: -50,
            depth: 100,
          },
        },

        768: {
          slidesPerView: 4,
          coverflowEffect: {
            stretch: -50,
            depth: 100,
          },
        },

        600: {
          slidesPerView: 3,
          coverflowEffect: {
            stretch: -25,
            depth: 50,
          },
        },

        1: {
          slidesPerView: 1.5,
          coverflowEffect: {
            stretch: -20,
            depth: 200,
          },
        },

      }}
      navigation
    >
      {props.children}
    </Swiper>
  );
}

export default function CircleItemsSwiper(props: any) {
  const { t } = useTranslation('global');

  return (
    <Box sx={{ ...circleItemsSwiperSyle, position: 'absolute' }}>
      <SwiperContent
        handleSelectedIndex={(e: any) => props.handleSelectedIndex(e)}
        items={props.items}
      >
        {props.items?.map((item: any) => (
          <SwiperSlide
            key={item.idCatLndItem}
            className="lmi-swiper-slide"
            data-swiper
            style={{
              backgroundImage: `url(${item.itemPicture})`,
            }}
          >
            <Typography sx={{
              color: 'white',
              ml: '10px',
              fontWeight: '600',
              mt: '120px',
              zIndex: 2,
            }}
            >
              $
              {item.dailyRentalPrice}
              {' '}
              {item.currency}
              {' '}
              {t('item_detail.daily')}
            </Typography>
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: '100%',
                height: '100%',
                zIndex: 1,
                borderRadius: '10px',
                transition: 'all 1s ease',
                background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);',
              }}
              component="div"
            />
          </SwiperSlide>
        ))}
      </SwiperContent>
    </Box>
  );
}

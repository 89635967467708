import { useContext, useEffect, useState } from 'react';
import {
  Box, Grid, Stack, TextField, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LmiTheme } from '../../../page/BackOffice/ProfilePrivate/Components';
import publicProfileContext from '../../../context/publicProfile/publicProfileContext';

function DialogNameEdit(props: any) {
  const limitFullName = 45;
  const pProfileContext = useContext(publicProfileContext);

  const {
    newFullName,
    updateCompleteName,
  }: any = pProfileContext;

  const changeCompleteName = (e: any) => {
    validateFullName(e.target.value);
    updateCompleteName(e.target.value);
  };

  const [isFullNameValid, setIsFullNameValid] = useState(true);

  useEffect(() => {
    setIsFullNameValid(props.kIsValid);
  }, []);

  const validateFullName = (fullName: string) => {
    const isValid = (/[a-zA-Z]{1,}\s[a-zA-Z]{1,}‘?-?[a-zA-Z]{1,}\s?([a-zA-Z]{1,})?/).test(fullName);
    if (fullName.length == 0) {
      props.KSetValid(false);
      setIsFullNameValid(false);
    } else {
      props.KSetValid(isValid);
      setIsFullNameValid(isValid);
    }
  };

  const { t } = useTranslation('global');

  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            pb={1}
          >
            <Box>
              <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
              <Typography component="span" fontWeight={500}>{t('profile.full_name')}</Typography>
            </Box>
            <Typography component="span" color={LmiTheme.light.systemBackground.tertiary}>
              {`${newFullName?.length}/${limitFullName}`}
            </Typography>
          </Stack>
          <TextField
            fullWidth
            id="outlined-basic"
            variant="outlined"
            type="text"
            placeholder={t('profile.enter_name')}
            helperText={!isFullNameValid && t('profile.required_names')} // TODO traducción
            error={!isFullNameValid}
            value={newFullName}
            onChange={changeCompleteName}
            onKeyPress={(e) => props.clickSaveFullNameEditKey(e)}
            sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
            inputProps={{
              maxLength: limitFullName,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default DialogNameEdit;

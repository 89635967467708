import {
  CHANGE,
} from '../../types';

export default (state: any, action: any) => {
  switch (action.type) {
    case CHANGE:
      return {
        ...state,
        from: action.payload.from,
        to: action.payload.to,
      };

    default:
      return state;
  }
};

/* eslint-disable no-nested-ternary */
// import { Favorite, FavoriteBorder } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import publicProfileContext from '../../../../../../../context/publicProfile/publicProfileContext';
import bookContext from '../../../../../../../context/bookItem/bookContext';

function MemberListings() {
  const pProfileContext = useContext(publicProfileContext);
  const {
    publicUserItems,
  }: any = pProfileContext;

  const {
    clearData,
  }: any = useContext(bookContext);

  const history = useHistory();

  function handleClick(bookItem: any): void {
    clearData();
    history.push('/bookitem', {
      bookItem,
    });
  }

  const { t } = useTranslation('global');

  return (
    <>
      <Box px={1} py={3}>
        <Grid container rowSpacing={0} columnSpacing={2}>
          {
            publicUserItems.length !== 0 ? (
              publicUserItems?.map((item: any, i: number) => (
                i <= 9 && (

                  <Grid item xs={6} sm={4} md={3} key={item.idCatLndItem}>
                    <Card elevation={0} onClick={() => handleClick(item)} sx={{ cursor: 'pointer' }}>
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-starts"
                        sx={{
                          aspectRatio: '5 / 4',
                          backgroundImage: `url(${item.itemPicture})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          borderRadius: '10px',
                        }}
                      />
                      <CardContent sx={{ p: 0 }}>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          px={0}
                          pt={1}
                          pb={0.5}
                        >
                          <Typography
                            variant="body2"
                            color="text.primary"
                            fontWeight={500}
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 1,
                            }}
                          >
                            {item.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.primary"
                            fontWeight={500}
                            sx={{ whiteSpace: 'nowrap', marginLeft: '10px' }}
                          >
                            {item?.dailyRentalPrice > 0
                              ? `$${parseInt(item?.dailyRentalPrice, 10)} ${item?.currency} Daily`
                              : item?.hourlyRentalPrice > 0
                                ? `$${parseInt(item?.hourlyRentalPrice, 10)} ${item?.currency} Hourly`
                                : item?.weeklyRentalPrice > 0
                                  ? `$${parseInt(item?.weeklyRentalPrice, 10)} ${item?.currency} Weekly`
                                  : `$${parseInt(item?.monthlyRentalPrice, 10)} ${item?.currency} Monthly`}
                          </Typography>
                        </Stack>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          fontSize="12px"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                          }}
                        >
                          {item.dscription}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                )
              ))
            ) : (
              <>
                <Typography
                  fontSize={14}
                  sx={{
                    textAlign: 'center',
                    color: '#999da2',
                    p: 3,
                  }}
                >
                  {t('circles.members.no_listings')}
                </Typography>
              </>
            )

          }
        </Grid>
      </Box>
    </>
  );
}

export default MemberListings;

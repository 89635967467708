import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { Divider, Stack } from '@mui/material';
import { useContext } from 'react';
import addEditItemContext from '../../../../../../../context/addEditItem/addEditItemContext';
import { LmiTheme } from '../../../../../../../components/Common';

export default function ListAccessories(props: any) {
  const triggerFunction = props.editAccesory;
  const addEditContextLog = useContext(addEditItemContext);

  const {
    itemAccessories,
    updateAccessoriesList,
  }: any = addEditContextLog;
  const [checked, setChecked] = React.useState([0]);

  const editAccessory = (value: any) => () => {
    triggerFunction(value);
  };

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const updateAccActive = (index: any) => {
    const updatedItemAccessories = [...itemAccessories];
    updatedItemAccessories[index].actve = !updatedItemAccessories[index].actve;
    updateAccessoriesList(updatedItemAccessories);
  };

  return (
    <List sx={{ width: '100%' }}>
      {itemAccessories.length > 0 ? itemAccessories.map((value: any, index: any) => (

        <ListItem
          divider
          key={value.idRelItemAccesorie}
          secondaryAction={(
            <>
              <Stack direction="row" justifyContent="space-between" spacing={3}>
                <IconButton onClick={editAccessory(index)}>
                  <EditIcon fontSize="medium" sx={{ color: LmiTheme.light.accent }} />
                </IconButton>
              </Stack>
            </>
          )}
          disablePadding
        >
          <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
            <ListItemIcon>
              <Checkbox
                checked={value.actve}
                onChange={() => { updateAccActive(index); }}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
              }}
              primaryTypographyProps={{
                fontSize: 'body2.fontSize',
              }}
              primary={value.name}
              secondaryTypographyProps={{
                fontSize: 'body2.fontSize',
                color: LmiTheme.light.systemBackground.tertiary,
              }}
              secondary={value.dscription}
            />
          </ListItemButton>
          <Divider />
        </ListItem>

      )) : null}
    </List>
  );
}

import {
  Box, Typography, Paper, Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
// import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import { ThumbDownAltOutlined } from '@mui/icons-material';
import { SectionTitle } from '../../../../../../components/Common';
import ActivityReviewStyle from './ActivityReview.style';

export default function ActivityReview({ notifDetail, reviewComment }: any) {
  const comment: any = reviewComment.length == 0 ? 'Not specified' : reviewComment?.message;
  const { t } = useTranslation('global');
  return (
    notifDetail?.isReviewed
      ? (
        <Box sx={ActivityReviewStyle}>
          <SectionTitle
            kMarginTop={2}
            kTitle={t('activity.review.review')}
            kFontSize={18}
          />
          <Paper
            className="lmiw-activity-review"
            variant="outlined"
            elevation={0}
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              className="lmiw-review-stack"
              spacing={2}
              sx={{ px: 2 }}
            >
              { reviewComment?.idRateUserWhoReviewedYou == 5 ? <ThumbUpAltOutlinedIcon /> : <ThumbDownAltOutlined />}
              <Typography fontSize="14px">
                {comment}
              </Typography>
            </Stack>
          </Paper>

        </Box>
      )
      : null
  );
}

import {
  Avatar, Chip, ListItemText, Skeleton, Stack, useMediaQuery, useTheme,
} from '@mui/material';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useContext } from 'react';
import UserBtnChip from './BtnUser.style';
import { BtnUserModels } from '../../UserMenu.models';
import authContext from '../../../../../context/authentication/authContext';
import { LmiTheme } from '../../..';
import publicProfileContext from '../../../../../context/publicProfile/publicProfileContext';

export default function BtnUser(props: BtnUserModels) {
  const { token }: any = useContext(authContext);
  const UserName = props.kUserName ? props.kUserName : <Skeleton variant="rectangular" width={50} height={12} />;
  const UserAdress = props.kUserName ? props.kAddress : <Skeleton variant="rectangular" width={30} height={8} />;

  const { privateProfileInfo }: any = useContext(publicProfileContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      {
        !isMobile || props.kType == 'showFullButton' ? (
          <Chip
            variant="outlined"
            onClick={token ? props.kOpenMenu : props.kChangeLocation}
            sx={{
              width: { xs: !token ? '44px' : '180px', sm: '180px' },
              pl: isMobile ? '14px' : 0,
              ...UserBtnChip,
            }}
            label={(
              <Stack
                direction="row"
                justifyContent={{ xs: 'center', sm: 'space-between' }}
                alignItems="center"
                gap={{ xs: 0, sm: 1 }}
                px={{ xs: 0, sm: 0.5 }}
              >
                <ListItemText
                  className="lmiw-list-item"
                  sx={{
                    display: { xs: token ? 'block' : 'none', sm: 'block' },
                  }}
                  primary={token ? UserName : props.kAddress}
                  secondary={token ? UserAdress : ''}
                />
                {token
                  ? <KeyboardArrowDownIcon className="lmiw-list-icon" />
                  : <RoomOutlinedIcon className="lmiw-list-icon" width={20} height={20} />}
              </Stack>
            )}
            avatar={token
              ? <Avatar sx={{ my: 4 }} alt={props.kUserName} src={privateProfileInfo.profilePicture} />
              : <></>}
          />
        ) : (
          <Chip
            avatar={token && (
              <Avatar
                alt={props.kUserName}
                src={privateProfileInfo.profilePicture}
                sx={{
                  width: '32px',
                  height: '32px',
                }}
              />
            )}
            variant="filled"
            onClick={token ? props.kOpenMenu : props.kChangeLocation}
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
              height: '48px',
              width: '48px',
              borderRadius: '50%',
              backgroundColor: 'white',
              paddingLeft: '14px',
              '&.MuiChip-root:hover': { backgroundColor: '#fff' },
            }}
            icon={!token ? (
              <RoomOutlinedIcon
                style={{ color: LmiTheme.light.accent }}
                width={20}
                height={20}
              />
            ) : undefined}
          />
        )
      }
    </>
  );
}

import React from 'react';

import { ExpandLessRounded } from '@mui/icons-material';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Divider,
  List,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import LmiTheme from '../../../themes/lmi-mui-theme/lmi-theme';
import { SpecialInstructionsModels } from './SpecialInstructions.models';
import Instruction from './Instruction';

function SpecialInstructions(props: SpecialInstructionsModels) {
  const {
    condictions,
  }: any = props;
  const [expanded, setExpanded] = React.useState<string | false>('expanded');
  const handleChangeExpanded = (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  const { t } = useTranslation('global');
  return (
    <>
      <Box my={3}>
        <Accordion
          expanded={expanded === 'expanded'}
          onChange={handleChangeExpanded('expanded')}
          elevation={0}
          sx={{ border: `1px solid ${LmiTheme.light.systemBackground.separator}`, borderRadius: '4px' }}
        >
          <AccordionSummary
            expandIcon={<ExpandLessRounded sx={{ fontSize: '2em', color: LmiTheme.light.accent }} />}
          >
            <Typography sx={{ fontSize: 'h6.fontSize', fontWeight: 'bold' }}>
              {t(`${props.kTitle}`)}
            </Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails sx={{ padding: '0px 8px 0 0' }}>
            <List>
              {
                condictions && condictions.length > 0
                /* eslint-disable react/jsx-props-no-spreading */
                  ? condictions.map((s: any, i:any) => (
                    <Instruction
                      kkey={s.id}
                      kDivider={i < condictions.length - 1 ?? false}
                      {...s}
                    />
                  ))
                  : null
              }
            </List>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
}

SpecialInstructions.defaultProps = {
  kTitle: 'item_detail.special_instructions',
};

export default SpecialInstructions;

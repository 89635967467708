/* eslint-disable react/jsx-no-bind */
import React, {
  MutableRefObject, useContext, useEffect, useRef,
} from 'react';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper, Stack, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded';
import { useTranslation } from 'react-i18next';
import AddEditCirclesContext from '../../../../../../context/circles/addEditCirclesContext';
import LmiTheme from '../../../../../../themes/lmi-mui-theme/lmi-theme';
import InputTextField from '../../../../AddItem/Components/InputTextField/InputTextField';
import useCircleBasicInformationValidations from './hooks/useCircleBasicInformationValidations';
import useImageCircleBasicInformation from './hooks/useImageCircleBasicInformation';

function CirclesBasicInformation(props: any) {
  const addEditCirclesContextLog = useContext(AddEditCirclesContext);
  const { handleChange } = useCircleBasicInformationValidations();
  const { onImageDropped } = useImageCircleBasicInformation();

  const {
    circleInfoEdit,
    updateCircleInfo,
    updateDraftCircleInfo,
    checkValidate,
    errors,
  }: any = addEditCirclesContextLog;
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const inputTitleReference = useRef() as MutableRefObject<HTMLDivElement>;
  const inputDescriptionReference = useRef() as MutableRefObject<HTMLDivElement>;

  const changeCircleType = () => {
    updateCircleInfo({
      ...circleInfoEdit,
      isPublic: !circleInfoEdit.isPublic,
    });
    updateDraftCircleInfo({
      ...circleInfoEdit,
      isPublic: !circleInfoEdit.isPublic,
    });
  };

  const fileTypes = [
    'JPG', 'JPEG',
    'PNG', 'WEBP',
    'BMP', 'TIFF',
  ];

  const { t } = useTranslation('global');

  useEffect(() => {
    let isEverythingOk = true;
    if (circleInfoEdit) {
      if (circleInfoEdit.name.length == 0) {
        isEverythingOk = false;
        inputTitleReference?.current.scrollIntoView();
      } if (circleInfoEdit.dscription.length == 0) {
        if (isEverythingOk) {
          inputDescriptionReference?.current.scrollIntoView();
        }
        isEverythingOk = false;
      } if (circleInfoEdit.circlePicture.length == 0) {
        isEverythingOk = false;
      }
    }
  }, [checkValidate]);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Paper
        variant="outlined"
        sx={{
          p: { xs: 0, sm: 2 },
          borderWidth: { xs: 0, sm: 0 },
        }}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <FileUploader
            maxSize={256}
            multiple={false}
            handleChange={onImageDropped}
            name="file"
            id="file"
            types={fileTypes}
          >
            <Button
              sx={{
                aspectRatio: '5 / 5',
                borderRadius: '50%',
                borderColor: LmiTheme.light.systemBackground.separator,
                bgcolor: 'transparent',
              }}
            >
              <Box
                sx={{
                  borderRadius: '50%',
                  width: isTablet ? 170 : 130,
                  height: isTablet ? 170 : 130,
                  borderStyle: circleInfoEdit?.circlePicture === '' ? 'dashed' : 'none',
                  borderWidth: circleInfoEdit?.circlePicture === '' ? '1px' : 'none',
                  textAlign: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                  verticalAlign: 'middle',
                  display: 'flex',
                }}
              >
                {circleInfoEdit?.circlePicture != '' ? (
                  <>
                    <Avatar
                      src={circleInfoEdit?.circlePicture}
                      sx={{ width: isTablet ? 170 : 130, height: isTablet ? 170 : 130 }}
                    />
                    <IconButton sx={{
                      backgroundColor: '#038de2',
                      position: 'absolute',
                      bottom: '8px',
                      right: '8px',
                      ':hover': { backgroundColor: '#038de2' },
                    }}
                    >
                      <CameraAltRoundedIcon fontSize={isMobile ? 'large' : 'small'} style={{ color: 'white' }} />
                    </IconButton>
                  </>

                )
                  : <AddOutlinedIcon color="primary" sx={{ width: '24px', height: '24px' }} />}
              </Box>
            </Button>

          </FileUploader>
          <Typography component="p" pb={2} pt="11px" sx={{ textAlign: 'center', fontSize: '16px', color: LmiTheme.light.systemBackground.tertiary }}>
            {t('circles.add_circle_page.upload_picture')}
          </Typography>
          {(errors?.circlePicture) && (
            <Typography component="p" pb={2} pt="11px" sx={{ textAlign: 'center', fontSize: '12px', color: LmiTheme.light.dialog.error }}>
              {t('circles.add_circle_page.photoRequired')}
            </Typography>
          )}
        </Stack>
        <Divider />
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          sx={{ mt: '30px', mb: '30px' }}
        >
          <Grid
            container
            justifyContent="center"
            sx={{ maxWidth: '560px' }}
          >
            <ToggleButtonGroup
              color="primary"
              value={circleInfoEdit?.isPublic}
              exclusive
              onChange={changeCircleType}
              fullWidth
              disabled={props.isBlocked}
              sx={{ height: '40px' }}
            >
              <ToggleButton value={false} sx={{ fontSize: '14px' }}>{t('circles.add_circle_page.private')}</ToggleButton>
              <ToggleButton value sx={{ fontSize: '14px' }}>{t('circles.add_circle_page.public')}</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          {
            circleInfoEdit?.isPublic ? (
              <Typography component="p" pt="8px" sx={{ textAlign: 'center', color: LmiTheme.light.systemBackground.tertiary }}>
                {t('circles.add_circle_page.public_circle_message')}
              </Typography>
            ) : (
              <Typography component="p" pt="8px" sx={{ textAlign: 'center', color: LmiTheme.light.systemBackground.tertiary }}>
                {t('circles.add_circle_page.private_circle_message')}
              </Typography>
            )
          }

        </Stack>
        <Divider />
        <Box sx={{ pt: '16px' }}>
          <InputTextField
            kLabel={t('add_item_page.title')}
            kInputMaxLength={45}
            kInputId="title"
            kInputType="text"
            kInputPlaceholder={`${t('add_item_page.enter_title')}${t('add_item_page.item').toLowerCase()}`}
            kInputValue={circleInfoEdit?.name != undefined ? circleInfoEdit.name : ''}
            kDisabled={props.isBlocked}
            kInputMultiline={false}
            kRef={inputTitleReference}
            kInputRows={1}
            kInputMaxRows={1}
            kInputOnChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('name', e)}
            kInputRequired
            kInputError={errors?.name}
            kInputName="name"
            kInputCurrentLength={circleInfoEdit?.name.length || 0}
            kInputHelperText={errors?.name ? `${t('add_item_page.required_field')}` : ''}
          />
        </Box>

        <Box sx={{ pt: '16px' }}>
          <InputTextField
            kLabel={t('item_detail.description')}
            kInputMaxLength={420}
            kInputId="description"
            kInputType="text"
            kInputPlaceholder={`${t('add_item_page.enter_description')}${t('add_item_page.item').toLowerCase()}`}
            kInputValue={circleInfoEdit?.dscription != undefined ? circleInfoEdit.dscription : ''}
            kDisabled={props.isBlocked}
            kInputMultiline
            kInputRows={5}
            kRef={inputDescriptionReference}
            kInputOnChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('dscription', e)}
            kInputRequired
            kInputCurrentLength={circleInfoEdit?.dscription.length}
            kInputError={errors?.dscription}
            kInputName="dscription"
            kInputHelperText={errors?.dscription ? `${t('add_item_page.required_field')}` : ''}
          />
        </Box>
      </Paper>
    </>
  );
}

export default CirclesBasicInformation;

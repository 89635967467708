import React, { useContext, useEffect, useState } from 'react';
import {
  Box, Typography, Button, Stack, Divider, useTheme, useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuid } from 'uuid';
import AddAccessories from '../AddAccessories/AddAccessories';
import ListAccessories from '../ListAccessories/ListAccessories';
import addEditItemContext from '../../../../../../../context/addEditItem/addEditItemContext';
import { ItemAccessoriesModel } from './ItemAccessories.models';
import { DialogCommon, LmiTheme } from '../../../../../../../components/Common';

export default function ItemAccessories(props: any) {
  const [open, setOpen] = React.useState(false);
  const [flagAdd, setFlagAdd] = useState(false);

  const addEditContextLog = useContext(addEditItemContext);
  const initialAddEditAccessoryInfo: ItemAccessoriesModel = {
    idRelItemAccesorie: 0,
    idCatLndItem: 0,
    name: '',
    actve: true, // cambiado a true
    dscription: '',
    dailyRentalPrice: 0,
    hourlyRentalPrice: 0,
    weeklyRentalPrice: 0,
    monthlyRentalPrice: 0,
    length: 0,
    width: 0,
    height: 0,
    weight: 0,
    replacementCost: 0,
    generatedNewID: uuid(),
  };

  const {
    addEditAccessoryInfo,
    itemAccessories,
    updateAccessoriesList,
    updateAccessoryInfo,
    checkAccValidations,
    areAccFieldsValidated,
    changedAccFields,
    updateDraftAccessoriesInfo,
  }: any = addEditContextLog;

  const handleModalToggle = (value: any) => {
    if (value >= 0) {
      const selectedAccessory = itemAccessories[value];
      updateAccessoryInfo({
        ...selectedAccessory,
        actve: selectedAccessory.actve,
      });
    } else {
      updateAccessoryInfo(initialAddEditAccessoryInfo);
    }
    setOpen(!open);
  };

  const closeDialogModal = () => {
    setOpen(false);
  };

  const submitAccessory = () => {
    setFlagAdd(!flagAdd);
    checkAccValidations(true);
  };

  useEffect(() => {
    if (areAccFieldsValidated) {
      saveUpdateAccesory();
    }
  }, [changedAccFields]);

  const calculatePrice = (value: any, base: any, multiplier: any) => {
    if (value !== 0) return value;
    const calculated = Math.round(base * multiplier);
    return calculated > 1 ? calculated : 1;
  };

  const setHourlyDailyIfZero = () => {
    const {
      hourlyRentalPrice,
      dailyRentalPrice,
      weeklyRentalPrice,
      monthlyRentalPrice,
      replacementCost,
    } = addEditAccessoryInfo;

    return {
      ...addEditAccessoryInfo,
      hourlyRentalPrice: calculatePrice(hourlyRentalPrice, replacementCost, 0.5),
      dailyRentalPrice: calculatePrice(dailyRentalPrice, replacementCost, 0.01),
      weeklyRentalPrice: calculatePrice(weeklyRentalPrice, dailyRentalPrice, 4),
      monthlyRentalPrice: calculatePrice(monthlyRentalPrice, weeklyRentalPrice, 3),
    };
  };

  const saveUpdateAccesory = () => {
    let updateAccessory: any = [];
    let exist: any = false;
    let generatedNewID: any = '';
    const addEditAccessoryInfoNew = setHourlyDailyIfZero();

    if (addEditAccessoryInfoNew.name === '') {
      return;
    }
    itemAccessories.forEach((accesorie: any) => {
      if (addEditAccessoryInfoNew.idRelItemAccesorie === 0) {
        if (accesorie.generatedNewID === addEditAccessoryInfoNew.generatedNewID) {
          exist = true;
          generatedNewID = addEditAccessoryInfoNew.generatedNewID;
        }
      } else if (accesorie?.idRelItemAccesorie === addEditAccessoryInfoNew.idRelItemAccesorie) {
        exist = true;
      }
    });

    switch (exist) {
      case false:
        itemAccessories.push(addEditAccessoryInfoNew);
        break;
      case true:
        if (addEditAccessoryInfoNew.idRelItemAccesorie === 0 && addEditAccessoryInfoNew.generatedNewID != generatedNewID) {
          updateAccessory = [...itemAccessories, addEditAccessoryInfoNew];
        } else {
          updateAccessory = itemAccessories.map((item: any) => {
            if (item.idRelItemAccesorie === 0) {
              if (item.generatedNewID == addEditAccessoryInfoNew.generatedNewID) {
                return addEditAccessoryInfoNew;
              } return item;
            } if (item.idRelItemAccesorie === addEditAccessoryInfoNew.idRelItemAccesorie) {
              return addEditAccessoryInfoNew;
            } return item;
          });
        }
        break;
      default:
        break;
    }

    updateAccessoriesList(exist ? updateAccessory : itemAccessories);
    updateDraftAccessoriesInfo(itemAccessories);
    setOpen(!open);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation('global');

  return (
    <Box>
      <Stack p={2} direction="row" justifyContent="space-between" spacing={3}>
        <Typography fontSize="body2.fontSize" color={LmiTheme.light.systemBackground.tertiary}>
          {t('add_item_page.add_15_accessories')}
        </Typography>
        <Typography fontSize="body2.fontSize" fontWeight={500} color={LmiTheme.light.systemBackground.tertiary}>
          {itemAccessories.length}
          /15
        </Typography>
      </Stack>
      <Divider />
      <ListAccessories editAccesory={handleModalToggle} />
      <Box mb={2} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          disabled={itemAccessories.length == 15}
          variant="outlined"
          onClick={() => { handleModalToggle(-1); }}
          startIcon={<AddIcon fontSize="large" />}
          size="large"
          sx={{
            my: 2,
            textTransform: 'none',
            fontWeight: 700,
            borderRadius: '10px',
          }}
        >
          {t('add_item_page.add_accessory')}
        </Button>

        <DialogCommon
          kTitle={t('add_item_page.add_accessory')}
          kMaxWidth="sm"
          kSlideTransition={isMobile}
          kKeepBtnSize
          kAlignBtn="center"
          kBorderRadius={isMobile ? 0 : '10px'}
          kOpenDialogLists={open}
          kOnClose={closeDialogModal}
          kOnSecondary={closeDialogModal}
          kOnPrimary={submitAccessory}
          kContent={
            (
              <AddAccessories userCurrency={props.userCurrency} />
            )
          }
        />
      </Box>
    </Box>
  );
}

/* eslint-disable no-nested-ternary */
import {
  Box, Typography, Stack, Divider, IconButton, FormControlLabel, SwitchProps, styled, Switch, Radio, Button, useTheme, useMediaQuery,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DialogCommon } from '../../../../../../../components/Common';
import AddEditCirclesContext from '../../../../../../../context/circles/addEditCirclesContext';

export default function EditMember() {
  const history = useHistory();
  const [selectedValue, setSelectedValue] = React.useState<string>('');
  const [rentOnly, setRentOnly] = React.useState<boolean>(false);
  const [confirmPermModalTitle, setConfirmPermModalTitle] = React.useState<string>('Member');
  const [confirmPermModalSubtitle, setConfirmPermModalSubtitle] = React.useState<string>('Member');
  const [confirmPermBtnText, setConfirmPermBtnText] = React.useState<string>('Confirm');
  const [canUserEdit, setCanUserEdit] = React.useState<boolean>(false);

  const circlesContextLog = useContext(AddEditCirclesContext);
  const {
    circleInfoEdit,
    memberSelected,
    updateMemberCirclePermission,
    memberUpdated,
  }: any = circlesContextLog;

  useEffect(() => {
    console.log(confirmPermModalTitle, confirmPermModalSubtitle);
    const selectedRadio = memberSelected?.blocked
      ? 'isBlocked'
      : memberSelected?.isAdmin
        ? 'isAdmin'
        : memberSelected?.isOwner
          ? 'isOwner'
          : 'isMember';
    setRentOnly(memberSelected?.rentOnly);
    setCanUserEdit(memberSelected?.canEdit);
    setSelectedValue(selectedRadio);
    setInitialMembership(selectedRadio);
  }, [memberSelected]);

  useEffect(() => {
    setOpen(false);
    setIsBtnDisable(false);
    if (memberUpdated) {
      window.scrollTo(0, 0);
      history.push({
        pathname: '/circles',
      });
    }
  }, [memberUpdated]);

  const IOSSwitch = styled((props: SwitchProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#038DE2' : '#038DE2',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [isOwner, setIsOwner] = React.useState<boolean>(false);
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false);
  const [isBlocked, setIsBlocked] = React.useState<boolean>(false);

  const [isBtnDisabled, setIsBtnDisable] = React.useState<boolean>(false);

  const [initialMembership, setInitialMembership] = React.useState<string>('Member');

  const _changeConfirmBtnText = () => {
    if (memberSelected?.blocked) {
      setConfirmPermBtnText(t('circles.add_circle_page.unblock'));
    } else {
      setConfirmPermBtnText(t('circles.add_circle_page.transfer'));
    }
  };

  const onChange = (e: any) => {
    setRentOnly(e.target.checked);
  };

  const _updateMembership = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
    const val = event.target.value;
    if (val == 'isOwner') {
      setIsOwner(true);
      setIsAdmin(false);
      setIsBlocked(false);
      setConfirmPermModalTitle(t('circles.add_circle_page.transferOwnerTitle'));
      setConfirmPermModalSubtitle(t('circles.add_circle_page.transferOwnerSubtitle'));
      _changeConfirmBtnText();
    } else if (val == 'isAdmin') {
      setIsOwner(false);
      setIsAdmin(true);
      setIsBlocked(false);
      if (memberSelected?.blocked) {
        setConfirmPermModalTitle(t('circles.add_circle_page.unblockMemberTitle'));
        setConfirmPermModalSubtitle(t('circles.add_circle_page.unblockMemberSubtitle'));
      }
      _changeConfirmBtnText();
    } else if (val == 'isBlocked') {
      setIsOwner(false);
      setIsAdmin(false);
      setIsBlocked(true);
      setConfirmPermModalTitle(t('circles.add_circle_page.blockUserTitle'));
      setConfirmPermModalSubtitle(t('circles.add_circle_page.blockUserSubtitle'));
      setConfirmPermBtnText(t('circles.add_circle_page.block'));
    } else if (val == 'isMember') {
      setIsOwner(false);
      setIsAdmin(false);
      setIsBlocked(false);
      if (memberSelected?.blocked) {
        setConfirmPermModalTitle(t('circles.add_circle_page.unblockMemberTitle'));
        setConfirmPermModalSubtitle(t('circles.add_circle_page.unblockMemberSubtitle'));
      }
      _changeConfirmBtnText();
    } else if (val != 'isBlocked' && initialMembership == 'isBlocked') {
      setConfirmPermModalTitle(t('circles.add_circle_page.unblockMemberTitle'));
      setConfirmPermModalSubtitle(t('circles.add_circle_page.unblockMemberSubtitle'));
      _changeConfirmBtnText();
    }
    console.log(isOwner, isAdmin, isBlocked);
  };

  const [open, setOpen] = React.useState(false);

  const handleModalToggle = () => {
    setOpen(!false);
  };

  const closeDialogModal = () => {
    setOpen(false);
  };

  const saveChanges = () => {
    if (selectedValue == initialMembership) {
      if (rentOnly != memberSelected?.rentOnly) {
        confirmUpdatePermit();
      } else {
        handleModalToggle();
      }
    } else if (selectedValue == 'isBlocked') {
      handleModalToggle();
    } else if (selectedValue == 'isOwner') {
      handleModalToggle();
    } else if (selectedValue != 'isBlocked' && initialMembership == 'isBlocked') {
      handleModalToggle();
    } else {
      confirmUpdatePermit();
    }
  };

  const confirmUpdatePermit = () => {
    setIsBtnDisable(true);
    updateMemberCirclePermission(
      memberSelected?.idCatLndCircleMember,
      isAdmin,
      isOwner,
      isBlocked,
      rentOnly,
      circleInfoEdit?.idCatLndCircle,
    );
  };

  const { t } = useTranslation('global');

  const editOptions = [
    {
      text: t('circles.members.owner'),
      value: 'isOwner',
      ariaLabel: 'A',
    },
    {
      text: t('circles.members.member'),
      value: 'isMember',
      ariaLabel: 'B',
    },
    {
      text: t('circles.members.admin'),
      value: 'isAdmin',
      ariaLabel: 'C',
    },
    {
      text: t('circles.members.block_member'),
      value: 'isBlocked',
      ariaLabel: 'D',
    },
  ];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // const isAllowToList = true;

  return (
    <Box>
      <Stack
        height="53px"
        p={0}
        pl={3}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={0}
        sx={{ mt: '5px', mb: '5px' }}
      >
        <Typography
          fontSize="17px"
          fontWeight="bold"
          color="#121212"
        >
          {`${t('circles.members.permissions_circles')} ${circleInfoEdit.name}`}
        </Typography>
        {
          !canUserEdit ? (
            <IconButton disabled sx={{ mr: '20px' }}>
              <EditIcon color="disabled" />
            </IconButton>
          ) : (
            <IconButton sx={{ mr: '20px' }} onClick={() => setIsEdit(true)}>
              <EditIcon color="primary" />
            </IconButton>
          )
        }
      </Stack>
      <Divider />
      { memberSelected?.isAdmin || memberSelected?.isOwner || circleInfoEdit?.canUploadItem != 1
        ? null
        : (
          <Stack
            height="53px"
            p={0}
            pl={3}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={0}
          >
            <Typography fontSize="17px" color="#121212">
              {t('circles.members.allow_to_list')}
            </Typography>
            { isEdit ? (
              <FormControlLabel
                label=""
                control={(
                  <IOSSwitch
                    onChange={(e) => { onChange(e); }}
                    checked={rentOnly}
                    sx={{ mr: '13px' }}
                  />
              )}
              />
            ) : (
              <Box sx={{
                height: '25px',
                backgroundColor: '#e6f4e5',
                mr: '20px',
                borderRadius: '7px',
                width: '63px',
                textAlign: 'center',
              }}
              >
                <Typography sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: '#005a00',
                }}
                >
                  { !memberSelected?.isOwner ? (!memberSelected?.blocked && rentOnly) ? `${t('circles.members.active')}` : `${t('circles.members.inactive')}` : `${t('circles.members.active')}` }
                </Typography>
              </Box>
            )}
          </Stack>
        ) }
      <Divider />
      {
        isEdit ? (
          <>
            {
            editOptions.map((option) => (
              <>
                <Stack
                  height="53px"
                  p={0}
                  pl={3}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={0}
                >
                  <Typography fontSize="17px" color="#121212">
                    {option.text}
                  </Typography>
                  <Radio
                    size="medium"
                    checked={selectedValue === option.value}
                    onChange={_updateMembership}
                    value={option.value}
                    name="radio-buttons"
                    inputProps={{ 'aria-label': `${option.ariaLabel}` }}
                    sx={{ mr: 3 }}
                  />
                </Stack>
                <Divider />
              </>
            ))
          }
            <Stack
              height="89px"
              mr="30px"
              p={0}
              pl={3}
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing="30px"
            >
              <Button
                className="btn-textTransform-none"
                disableElevation
                variant="outlined"
                onClick={() => setIsEdit(false)}
                sx={{
                  padding: '13px 45px',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  borderRadius: '10px',
                }}
              >
                {t('circles.members.cancel')}
              </Button>
              <Button
                className="btn-textTransform-none"
                disableElevation
                variant="contained"
                onClick={() => {
                  saveChanges();
                  setIsEdit(false);
                }}
                sx={{
                  padding: '13px 45px',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  borderRadius: '10px',
                }}
              >
                {t('circles.members.save')}
              </Button>
            </Stack>
          </>

        ) : (
          <Stack
            height="53px"
            p={0}
            pl={3}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={0}
          >
            <Typography fontSize="17px" color="#121212">
              {selectedValue == 'isOwner'
                ? 'Owner'
                : selectedValue == 'isAdmin'
                  ? 'Admin'
                  : 'Member'}
            </Typography>
          </Stack>
        )
      }

      <DialogCommon
        kTitle={confirmPermModalTitle}
        kMaxWidth="sm"
        kOpenDialogLists={open}
        kMaxHeight="319px"
        kAlignBtn="center"
        kBorderRadius={isMobile ? 0 : '10px'}
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kKeepBtnSize
        kSlideTransition={isMobile}
        kPrimaryBtnText={confirmPermBtnText}
        kDisableBtnSave={isBtnDisabled}
        kOnClose={closeDialogModal}
        kOnSecondary={closeDialogModal}
        kOnThird={confirmUpdatePermit}
        kContent={
          (
            <>
              <Typography>
                {confirmPermModalSubtitle}
              </Typography>

            </>
          )
        }
      />

    </Box>
  );
}

import { ArrowForwardIosRounded, ThumbUpRounded } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconLinkButton, LmiTheme, SectionTitle } from '..';
import { ReviewsCardModels } from './ReviewsCard.models';

function ReviewsCard(props: ReviewsCardModels) {
  const { t } = useTranslation('global');
  return (
    <>
      <SectionTitle kTitle={t(`${props.kTitle}`)} kMarginTop={3} kMarginBottom={1} />
      <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
        <Box my={2} ml={2} mr={1}>
          {
            props.kReviews
              ? (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                >
                  <Stack
                    pt={0}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    <Avatar
                      onClick={undefined}
                      sx={{
                        bgcolor: LmiTheme.light.secondaryColor.green,
                        width: 24,
                        height: 24,
                      }}
                    >
                      <ThumbUpRounded sx={{ color: LmiTheme.light.systemBackground.primaryIverted, fontSize: 14 }} />
                    </Avatar>
                    <Typography sx={{ fontSize: 'body2.fontSize', color: LmiTheme.light.systemBackground.tertiary }}>
                      {props.kRecommended}
                      {t('profile.recommended')}
                    </Typography>
                  </Stack>
                  <IconLinkButton
                    kOnClick={props.kOnClickGoToReviewsTab || props.kOnClickOpenReviewsDialog}
                    kEndIcon={<ArrowForwardIosRounded />}
                    kText={`${props.kReviews} ${t('profile.reviews')}`}
                    kFontSize="body1.fontSize"
                    kFontWeight="bold"
                    kHideText={false}
                  />
                </Stack>
              )
              : (
                <Typography sx={{ fontSize: 'body2.fontSize', color: LmiTheme.light.systemBackground.tertiary }}>
                  {t('profile.no_reviews')}
                </Typography>
              )
          }
        </Box>
      </Paper>
    </>
  );
}

ReviewsCard.defaultProps = {
  kTitle: 'reviews',
  kRecommended: 0,
  kReviews: 0,
  kEditOnClick: undefined,
};

export default ReviewsCard;

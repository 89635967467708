/* eslint-disable global-require */

import {
  Button, Stack, Typography, Box, Container, Fab, useTheme, useMediaQuery,

} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import {
  ContentLayout, Logo, SearchModal, UserMenu,
} from '../../../../../components/Common';
import header_background from '../../../../../assets/header_circle/header_background.jpg';
import people from '../../../../../assets/header_circle/header_people.png';

import LmiTheme from '../../../../../themes/lmi-mui-theme/lmi-theme';
// import { ContentLayout } from '../../../../../components/Common';

export default function HeaderImage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.up('sm') && theme.breakpoints.down('md'));
  const [openSearch, setOpenSearch] = useState(false);

  const history = useHistory();
  const toggleModal = () => {
    setOpenSearch(!openSearch);
  };
  const goToHome = () => {
    history.push('/');
  };

  const moreInfo = () => {
    const topID = (document.getElementById('how-it-works')!.offsetTop);

    window.scroll({
      top: topID,
      behavior: 'smooth',
    });
  };

  // eslint-disable-next-line no-nested-ternary
  const sizeLogo = isMobile ? 50 : isTablet ? 180 : 210;

  const { t } = useTranslation('global');

  return (
    <>
      <Box
        className="navbar"
        sx={{
          position: 'absolute',
          // top: 100,
          right: 0,
          zIndex: 2,
          width: '100%',
          top: { xs: 0, md: 8 },
        }}
      >
        <ContentLayout>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Box sx={{ height: { xs: '50px', md: '85px' }, display: 'flex' }} onClick={() => goToHome()}>
              <Logo icon={isMobile} width={sizeLogo} iconColor="white" textColor="white" />
            </Box>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Fab
                onClick={() => { toggleModal(); }}
                size="medium"
                className="fabIconNavbarPrimary"
                color="primary"
              >
                <SearchIcon />
              </Fab>
              <UserMenu />
            </Stack>
          </Stack>
        </ContentLayout>
      </Box>
      <SearchModal
        kSearchModalOpen={openSearch}
        kSearchModalToggle={() => toggleModal()}
      />
      <Stack
        direction="column"
        justifyContent="flex-end"
        alignItems="center"
        sx={{

          height: { xs: '510px', sm: '700px' },
          minHeight: '400px',
          position: 'relative',
          backgroundSize: 'cover',
          backgroundImage: `url(${header_background})`,
        }}
      >
        {/* image */}
        <Stack
          justifyContent="flex-end"
          alignItems="center"
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            // background: 'rgb(0 0 0 / 20%)',
            zIndex: 0,
            overflow: 'hidden',
          }}
        >
          <Box sx={{
            maxWidth: { md: '908px', sm: '868px', xs: '600px' },
            minWidth: '660px',
            width: {
              xs: 'calc(100% + 200px)', md: '100%',
            },
            height: 'auto',
            display: 'flex',
          }}
          >
            <img
              alt=""
              src={people}
              srcSet={people}
              loading="lazy"
              width="100%"
            />
          </Box>
        </Stack>
        {/* caption */}
        <Box sx={{
          height: { xs: '45%', sm: '35%', md: '50%' },
          width: '100%',
          zIndex: 2,
          background: 'linear-gradient(to top, rgba(0,0,0,0.9), rgba(0,0,0,0))',
        }}
        >
          <Container maxWidth="md">
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              sx={{ zIndex: 2, height: '50%' }}
            >
              <Typography
                variant="h5"
                color={LmiTheme.light.systemBackground.elevation}
                sx={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  color: 'white',
                  fontSize: { xs: '24px', sm: '34px', lg: '44px' },
                }}
              >
                {t('circles_page.title')}
              </Typography>
              <Typography
                component="h6"
                color={LmiTheme.light.systemBackground.elevation}
                mt={10}
                sx={{
                  textAlign: 'center',
                  fontWeight: '400',
                  lineHeight: '1.2',
                  fontSize: { xs: '18px', sm: '24px', lg: '26px' },
                }}
              >
                {t('circles_page.text')}

              </Typography>
              <Button
                sx={{
                  color: 'white',
                  borderColor: 'white',
                  borderRadius: '50px',
                  mt: 2,
                  textTransform: 'inherit',
                  fontWeight: '400',
                  fontSize: { xs: '18px' },
                  minWidth: '120px',
                  minHeight: '40px',
                  px: 5,
                  py: 2,
                }}
                onClick={() => moreInfo()}
                variant="contained"
              >
                {t('circles_page.more_info')}
              </Button>
            </Stack>
          </Container>

        </Box>
      </Stack>
    </>

  );
}

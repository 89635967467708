import {
  Stack, Box, Paper, Typography, FormControlLabel,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Heading from '../Heading/Heading';
import IOSSwitch from '../../../Listing/Components/AddItem/Components/ItemBasicInformation/ItemBasicInformation.style';
import { IdVerificationSwitchModels } from './IdVerificationSwitch.models';

const IdVerificationSwitch = (props: IdVerificationSwitchModels) => {
  const { t } = useTranslation('global');

  return (
    <>
      <Box mt={3}>
        <Heading kTitle={t('activity_detail.id_verification')} />
      </Box>
      <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" py={3}>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" pl={2}>
            <Typography mr={2} fontSize="body2.fontSize" fontWeight={700}>{t('item_detail.renter_id_verification')}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <FormControlLabel
              control={(
                <IOSSwitch
                  name="requireIdVerify"
                  checked={props.kRequiresIdVerify}
                  onChange={(e) => { props.kOnChangeIdVerify(e); }}
                  sx={{ mr: 0 }}
                />
              )}
              label=""
            />
          </Stack>
        </Stack>
      </Paper>
    </>
  );
};

export default IdVerificationSwitch;

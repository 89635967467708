import {
  Box, Typography, Stack, Divider, Radio,
} from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LmiTheme } from '../../../../../../components/Common';
import AddEditCirclesContext from '../../../../../../context/circles/addEditCirclesContext';

export default function PermisionToUploadItems() {
  const circlesContextLog = useContext(AddEditCirclesContext);
  const {
    circleInfoEdit,
    updateCircleInfo,
    updateDraftCircleInfo,
  }: any = circlesContextLog;
  const { t } = useTranslation('global');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateCircleInfo({
      ...circleInfoEdit,
      canUploadItem: parseInt(event.target.value, 10),
    });
    updateDraftCircleInfo({
      ...circleInfoEdit,
      canUploadItem: parseInt(event.target.value, 10),
    });
  };

  return (
    <Box>
      <Stack height="53px" p={0} pl={3} direction="row" justifyContent="space-between" alignItems="center" spacing={0}>
        <Typography fontSize="17px" color={LmiTheme.light.systemBackground.tertiary}>
          {t('circle_detail.all')}
        </Typography>
        <Radio
          size="small"
          checked={circleInfoEdit?.canUploadItem === 0}
          onChange={handleChange}
          value={0}
          name="radio-buttons"
          sx={{ mr: 3 }}
        />
      </Stack>
      <Divider />
      <Stack
        height="53px"
        p={0}
        pl={3}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={0}
      >
        <Typography fontSize="17px" color={LmiTheme.light.systemBackground.tertiary}>
          {t('circle_detail.admins_only')}
        </Typography>
        <Radio
          size="small"
          checked={circleInfoEdit?.canUploadItem === 1}
          onChange={handleChange}
          value={1}
          name="radio-buttons"
          sx={{ mr: 3 }}
        />
      </Stack>
      <Divider />
      <Stack
        height="53px"
        p={0}
        pl={3}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={0}
      >
        <Typography fontSize="17px" color={LmiTheme.light.systemBackground.tertiary}>
          {t('circle_detail.owner_only')}
        </Typography>
        <Radio
          size="small"
          checked={circleInfoEdit?.canUploadItem === 2}
          onChange={handleChange}
          value={2}
          name="radio-buttons"
          sx={{ mr: 3 }}
        />
      </Stack>
    </Box>
  );
}

import { useContext, useEffect, useState } from 'react';
import {
  AddRounded,
  CameraAltRounded,
  Edit,
  EventRounded,
  PriorityHighRounded,
  VerifiedRounded,
  ArrowCircleRightOutlined,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
import { DialogCommon, IconLinkButton, LmiTheme } from '../../../page/BackOffice/ProfilePrivate/Components';
import { ProfileInfoModels } from './ProfileInfo.models';
import DialogNameEdit from './DialogNameEdit';
import LmiColorPalette from '../../../themes/lmi-mui-theme/lmi-color-palette';
import publicProfileContext from '../../../context/publicProfile/publicProfileContext';
import shuftiProContext from '../../../context/shuftiPro/shuftiProContext';
import SharePromoCode from '../../../page/BackOffice/ProfilePrivate/Components/SharePromoCode';
import LoadingLayout from '../LoadingLayout/LoadingLayout';

function ProfileInfo(props: ProfileInfoModels) {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));

  const triggerUpdatePicture = props.kUpdatePicture;
  const [isBlocked, setIsBlocked] = useState(false);
  const pProfileContext = useContext(publicProfileContext);
  const {
    newFullName,
    privateProfileInfo,
    publicProfileInfo,
    updateProfileInfo,
    updateUserNameAndDescription,
    getPrivateProfileInfo,
    pendingInfo,
  }: any = pProfileContext;

  const pShuftiProContext = useContext(shuftiProContext);
  const {
    shuftiUrl,
    checkConfirmationStatus,
  }: any = pShuftiProContext;
  const fileTypes = [
    'JPG', 'JPEG',
    'PNG', 'WEBP',
    'BMP', 'TIFF',
  ];

  const [openShuftiIframe, setOpenShuftiIframe] = useState(false);
  const [openShare, setOpenShare] = useState(false);

  const clickOpenShuftiIframe = () => openIdInitializeShufti();

  const clickCloseShuftiIframe = () => setOpenShuftiIframe(false);
  const [renderIframe, setRenderIframe] = useState(false);

  const openIdInitializeShufti = async () => {
    // setIsBlocked(true);
    setOpenShuftiIframe(true);
  };
  // this one is for validating the name after loading user info
  // so it triggers once to active the laidations when editing the username
  const [isProfileLoaded, setIsProfileLoaded] = useState(false);

  useEffect(() => {
    async function fetchPrivateProfileInfo() {
      if (Object.keys(privateProfileInfo).length === 0 || privateProfileInfo.length <= 3) {
        await getPrivateProfileInfo({ idCatLender: 0 });
      }
    }

    fetchPrivateProfileInfo();
  }, []);

  useEffect(() => {
    if (!isProfileLoaded && newFullName) {
      setIsProfileLoaded(true);
      validateFullName(newFullName);
    }
  }, [newFullName]);

  const validateFullName = (fullName: string) => {
    const isValid = (/[a-zA-Z]{1,}\s[a-zA-Z]{1,}‘?-?[a-zA-Z]{1,}\s?([a-zA-Z]{1,})?/).test(fullName);
    if (fullName.length == 0) {
      setIsFullNameValid(false);
    } else {
      setIsFullNameValid(isValid);
    }
  };

  const [openDialogNameEdit, setOpenDialogNameEdit] = useState(false);
  const clickOpenDialogNameEditEdit = () => setOpenDialogNameEdit(true);
  const clickCloseDialogNameEdit = () => setOpenDialogNameEdit(false);
  const [isFullNameValid, setIsFullNameValid] = useState(false);

  const clickSaveFullNameEdit = () => {
    updateUserNameAndDescription({
      description: privateProfileInfo?.dscription,
      userName: newFullName,
    });
    updateProfileInfo({
      ...privateProfileInfo,
      completeName: newFullName,
    });
    setOpenDialogNameEdit(false);
  };

  const clickSaveFullNameEditKey = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      updateUserNameAndDescription({
        description: privateProfileInfo?.dscription,
        userName: newFullName,
      });
      updateProfileInfo({
        ...privateProfileInfo,
        completeName: newFullName,
      });
      setOpenDialogNameEdit(false);
    }
  };

  const finishVerification = async () => {
    console.log(clickOpenShuftiIframe);
    setIsBlocked(true);
    setRenderIframe(false);
    checkConfirmationStatus(updateProfileIdv, privateProfileInfo?.email, privateProfileInfo?.userName);
    clickCloseShuftiIframe();
    setTimeout(() => {
      setIsBlocked(false);
    }, 2500);
  };

  const updateProfileIdv = async () => {
    updateProfileInfo({
      ...privateProfileInfo,
      isShuftiVerified: true,
    });
  };

  let nameToShow;
  if (!props.kEditing) {
    nameToShow = publicProfileInfo?.completeName != '' ? publicProfileInfo?.completeName : publicProfileInfo?.userName;
  } else {
    nameToShow = privateProfileInfo?.completeName != '' ? privateProfileInfo?.completeName : privateProfileInfo?.userName;
  }

  const splitDay = props.kMemberSince?.split(' ')[0];
  const splitMonth = props.kMemberSince?.split(' ')[1];
  const splitYear = props.kMemberSince?.split(' ')[2];

  const uiTheme = useTheme();
  const isMobile = useMediaQuery(uiTheme.breakpoints.down('md'));

  const { t } = useTranslation('global');

  return (
    <>
      {
            isBlocked ? (
              <Box
                sx={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: 9999,
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  display: 'block',
                }}
              >
                <LoadingLayout />
              </Box>
            ) : (
              <ListItem sx={{ ml: -3, pb: 2 }}>
                <ListItemAvatar>
                  {
            props.kEditing
            && (
              (
                props.kPhoto !== 'https://lendmt-images.s3.us-east-2.amazonaws.com/lnd_own_images/lmi-user-icon.svg'
                && (

                  <FileUploader
                    maxSize={256}
                    multiple={false}
                    handleChange={triggerUpdatePicture}
                    name="file"
                    types={fileTypes}
                  >
                    <Badge
                      onClick={props.kOnClickEditPhoto}
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      sx={{ cursor: 'pointer' }}
                      badgeContent={
                        (
                          props.kEditing
                          && (
                            <Avatar
                              onClick={props.kOnClickEditPhoto}
                              sx={{
                                ml: -3,
                                bgcolor: LmiTheme.light.accent,
                                width: 44,
                                height: 44,
                              }}
                            >
                              <CameraAltRounded />
                            </Avatar>
                          )
                        )
                      }
                    >
                      <Avatar
                        alt={props.kName}
                        src={props.kPhoto}
                        sx={{
                          width: isTablet ? 170 : 130,
                          height: isTablet ? 170 : 130,
                          border: `5px solid ${LmiTheme.light.systemBackground.elevation}`,
                          cursor: 'pointer',
                        }}
                      />
                    </Badge>
                  </FileUploader>
                )
              )
              || (
                props.kPhoto
                && (
                  <FileUploader
                    maxSize={256}
                    multiple={false}
                    handleChange={triggerUpdatePicture}
                    name="file"
                    types={fileTypes}
                  >
                    <Avatar
                      onClick={props.kOnClickEditPhoto}
                      sx={{
                        width: isTablet ? 170 : 130,
                        height: isTablet ? 170 : 130,
                        bgcolor: LmiTheme.light.systemBackground.base,
                        border: `1px dashed ${LmiTheme.light.systemBackground.tertiary}`,
                        cursor: 'pointer',
                      }}
                    >
                      <AddRounded sx={{ fontSize: 44, color: LmiTheme.light.accent }} />
                    </Avatar>
                  </FileUploader>
                )
              )
            )
          }
                  {
            !props.kEditing
            && (
              <Avatar
                alt={props.kName}
                src={props.kPhoto}
                sx={{
                  width: isTablet ? 170 : 130,
                  height: isTablet ? 170 : 130,
                  border: `5px solid ${LmiTheme.light.systemBackground.elevation}`,
                  color: LmiColorPalette.greenScheme[900],
                  fontSize: '56px',
                  fontWeight: 'bold',
                  bgcolor: props.kPhoto === '--' ? LmiColorPalette.greenScheme[100] : LmiTheme.light.systemBackground.base,
                }}
              />
            )
          }
                </ListItemAvatar>
                <ListItemText sx={{ ml: 3, mr: -6, mt: isTablet ? undefined : -1 }}>
                  <>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={1}
                    >
                      <Typography sx={{ overflowWrap: 'anywhere', fontSize: 'body1.fontSize', fontWeight: 'bold' }}>
                        {nameToShow}
                        {' '}
                      </Typography>
                      {
                props.kEditing
                && (
                  <IconLinkButton
                    kOnClick={clickOpenDialogNameEditEdit}
                    kStartIcon={<Edit />}
                    kText={t('profile.edit')}
                    kFontSize="body1.fontSize"
                    kFontWeight="bold"
                    kHideText={false}
                  />
                )
              }
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                      my={1}
                    >
                      <EventRounded sx={{ color: LmiTheme.light.systemBackground.primary, fontSize: 24 }} />
                      <> </>
                      <Typography sx={{ fontSize: 'body2.fontSize', color: LmiTheme.light.systemBackground.tertiary }}>
                        {t('profile.member_since')}
                        {' '}
                        {splitDay}
                        {' '}
                        {t(`months.${splitMonth}`)}
                        {' '}
                        {splitYear}
                      </Typography>
                    </Stack>
                    {
              props.kIdVerify
              && (
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                  my={1}
                >
                  <VerifiedRounded sx={{ color: LmiTheme.light.accent, fontSize: 24 }} />
                  <> </>
                  <Typography sx={{ fontSize: 'body2.fontSize', color: LmiTheme.light.systemBackground.tertiary }}>
                    {t('profile.id_verified')}
                  </Typography>
                </Stack>
              )
            }

                    {props.kBtnPublicProfile}
                  </>
                </ListItemText>
              </ListItem>
            )
}
      {
        props.kEditing
        && (
          <DialogCommon
            kTitle={t('profile.name')}
            kMaxWidth="sm"
            kMaxHeight="320px"
            kAlignBtn="center"
            kBorderRadius={isMobile ? 0 : '10px'}
            kFixedDialog={0}
            kFixedDialogPosition="fixed"
            kDisableBottomDivider
            kKeepBtnSize
            kTopDivider
            kSlideTransition={isMobile}
            kOpenDialogLists={openDialogNameEdit}
            kOnClose={clickCloseDialogNameEdit}
            kOnSecondary={clickCloseDialogNameEdit}
            kOnPrimary={clickSaveFullNameEdit}
            kDisableBtnSave={!isFullNameValid}
            kContent={
              (
                <DialogNameEdit kIsValid={isFullNameValid} KSetValid={setIsFullNameValid} clickSaveFullNameEditKey={clickSaveFullNameEditKey} />
              )
            }
          />
        )
      }
      {
        props.kIdVerify
        || (props.kEditing
          && (
            <Paper variant="outlined" sx={{ mb: 3, borderRadius: '10px' }}>
              <Box my={2} ml={2} mr={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                >
                  <Stack
                    pt={0}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    <Avatar
                      onClick={undefined}
                      sx={{
                        bgcolor: LmiTheme.light.secondaryColor.orange,
                        width: 24,
                        height: 24,
                      }}
                    >
                      <PriorityHighRounded sx={{ color: LmiTheme.light.systemBackground.primaryIverted, fontSize: 18 }} />
                    </Avatar>
                    <Typography sx={{ fontSize: 'body2.fontSize', color: LmiTheme.light.systemBackground.primary, fontWeight: 'bold' }}>
                      {t('profile.id_verification')}
                    </Typography>
                  </Stack>
                </Stack>
                <Typography pl={4} component="div" sx={{ fontSize: 'body2.fontSize', color: LmiTheme.light.systemBackground.primary }}>
                  {t('profile.verify_profile')}

                  {pendingInfo?.profilePicture.length == 0 && ` ${t('profile.needUploadPicture')}`}
                </Typography>
                {pendingInfo?.profilePicture.length > 0 && (
                  <Button onClick={() => {
                    setRenderIframe(true);
                    openIdInitializeShufti();
                  }}
                  >
                    {t('profile.verify')}
                  </Button>
                )}
              </Box>

              {renderIframe ? (
                <DialogCommon
                  kTitle={t('profile.id_verification')}
                  kMaxWidth="md"
                  kOpenDialogLists={openShuftiIframe}
                  kOnClose={() => { finishVerification(); }}
                  kOnSecondary={() => { finishVerification(); }}
                  kOnPrimary={() => { finishVerification(); }}
                  kDisableActions
                  kContent={
                    (

                      <Card sx={{ height: 600, width: 800 }}>
                        <iframe
                          title="shufti"
                          height="100%"
                          width="100%"
                          src={shuftiUrl}
                          id="shuftipro-iframe"
                          allow="camera"
                          frameBorder="0"
                        />
                      </Card>
                    )
                  }
                />
              ) : null}
            </Paper>
          )
        )
      }
      {(props.kEditing
        && (
          <Paper variant="outlined" sx={{ mb: 3, borderRadius: '10px', backgroundColor: '#FDF3E0' }}>
            <Box my={2} ml={2} mr={1}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
              >
                <Stack
                  pt={0}
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={0}
                >
                  <Typography sx={{ fontSize: 'h6', color: LmiTheme.light.systemBackground.primary, fontWeight: 'bold' }}>
                    {t('profile.getDiscount')}
                  </Typography>
                  <Typography component="div" sx={{ fontSize: 'body2.fontSize', color: LmiTheme.light.systemBackground.primary }}>
                    {t('profile.inviteFriendsDiscount')}
                  </Typography>
                </Stack>
                <IconButton sx={{ color: '#000000' }} onClick={() => setOpenShare(true)}>
                  <ArrowCircleRightOutlined fontSize="large" />
                </IconButton>
              </Stack>
            </Box>
            <DialogCommon
              kTitle={t('profile.modalMyPromoCodeTitle')}
              kMaxWidth="sm"
              kEnableBorderRadius
              kMaxHeight="auto"
              kBorderRadius={isMobile ? 0 : '10px'}
              kFixedDialog={0}
              kFixedDialogPosition="fixed"
              kDisableBottomDivider
              kDisableContentPadding
              kKeepBtnSize
              kCenterTitle
              kSlideTransition={isMobile}
              kTopDivider
              kOpenDialogLists={openShare}
              kOnClose={() => setOpenShare(false)}
              kDisableActions
              kContent={
                (
                  <SharePromoCode
                    myPromoCode={props.kPromoCode}
                    myPromoCodeLink={props.kPromoCodeLink}
                  />
                )
              }
            />
          </Paper>
        )
      )}

    </>
  );
}

ProfileInfo.defaultProps = {
  kPhoto: '--',
  kName: '--',
  kMemberSince: '--',
  kIdVerify: false,
  kEditing: false,
};

export default ProfileInfo;

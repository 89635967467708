import {
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SectionDescription } from '../../../../../components/Common';
import { ItemDetailPricingModels } from './ItemDetaiModels';

export default function ItemDetailPricing(props: ItemDetailPricingModels) {
  const { t } = useTranslation('global');
  return (

    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      justifyContent="space-between"
      alignItems={{ xs: 'flex-start', sm: 'center' }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
      >
        <SectionDescription
          kMarginBottom={props.kMarginBottom}
          kMarginTop={props.kMarginTop}
          kTitle={t('item_detail.item_value_2')}
          kTitleSize={props.kFontSize}
          kDescription={props.kValue ? props.kValue : '--'}
        />
        <SectionDescription
          kMarginBottom={props.kMarginBottom}
          kMarginTop={props.kMarginTop}
          kTitle={t('item_detail.price_for_sale')}
          kTitleSize={props.kFontSize}
          kDescription={props.kPriceForSale ? props.kPriceForSale : '--'}
        />

      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <SectionDescription
          kMarginBottom={props.kMarginBottom}
          kMarginTop={props.kMarginTop}
          kTitleSize={props.kFontSize}
          kTitle={t('item_detail.hourly')}
          kDescription={props.kHourlyPrice ? props.kHourlyPrice : '--'}
        />
        <SectionDescription
          kMarginBottom={props.kMarginBottom}
          kMarginTop={props.kMarginTop}
          kTitleSize={props.kFontSize}
          kTitle={t('item_detail.daily')}
          kDescription={props.kDailyPrice ? props.kDailyPrice : '--'}
        />
        <SectionDescription
          kMarginBottom={props.kMarginBottom}
          kMarginTop={props.kMarginTop}
          kTitleSize={props.kFontSize}
          kTitle={t('item_detail.weekly')}
          kDescription={props.kWeeklyPrice ? props.kWeeklyPrice : '--'}
        />
        <SectionDescription
          kMarginBottom={props.kMarginBottom}
          kMarginTop={props.kMarginTop}
          kTitleSize={props.kFontSize}
          kTitle={t('item_detail.monthly')}
          kDescription={props.kMonthlyPrice ? props.kMonthlyPrice : '--'}
        />
      </Stack>

    </Stack>
  );
}

import { LmiTheme } from '../../../../../../components/Common';

const ActivityDeliveryStyle = {
  width: '100%',
  '.lmiw-activity-delivery': {
    borderRadius: '10px',
    '.lmiw-delivery-title': {
      fontSize: '16px',
      fontWeight: 'bold',
      color: 'black',
      mb: 2,
    },
    '.lmiw-delivery-label': {
      fontSize: '14px',
      fontWeight: '400',
      color: LmiTheme.light.systemBackground.secondary,
      minWidth: '30%',
    },
    '.lmiw-delivery-date': {
      fontSize: '14px',
      fontWeight: 'bold',
      color: LmiTheme.light.systemBackground.secondary,
    },
    '.lmiw-delivery-desc': {
      fontSize: '14px',
      fontWeight: '400',
      color: LmiTheme.light.systemBackground.secondary,
    },
    '.lmiw-btn-tracking': {
      color: LmiTheme.light.accent,
      fontSize: '14px',
      textDecoration: 'underline',
      cursor: 'pointer',
      fontWeight: 'bold',
    },

  },

};

export default ActivityDeliveryStyle;

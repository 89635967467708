import {
  Stack, Box, TextField, FormControl, Button, useTheme, useMediaQuery, Container,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  DialogCommon, LmiTheme, Logo, SearchSuggestion,
} from '..';
import ExploreContext from '../../../context/explore/exploreContext';
import { SearchModalsModels } from './SearchModal.models';

export default function SearchModal(props: SearchModalsModels) {
  const {
    searched,
    clearData,
  }: any = useContext(ExploreContext);
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.up('sm') && theme.breakpoints.down('md'));
  const [isVisibleSuggestion, setVisibleSuggestion] = useState(false);
  const [textSearch, setTextSearch] = useState('');

  const fnTextSearch = (e: any) => {
    setTextSearch(e.target.value);
  };

  const gotoSearch = (value?: string) => {
    if (history.location.pathname == '/') {
      clearData();
    }
    if (value) {
      history.push(`/search/${value}`);
    } else {
      history.push(`/search/${textSearch}`);
    }
  };

  useEffect(() => {
    if (searched && searched != '') { setTextSearch(searched); }
  }, [searched]);

  // eslint-disable-next-line no-nested-ternary
  const sizeLogo = isMobile ? 50 : isTablet ? 180 : 210;

  const { t } = useTranslation('global');

  return (
    <DialogCommon
      kDisableActions
      kDisableTitle
      kMaxWidth="md"
      kFullScreenDesktop
      kOpenDialogLists={props.kSearchModalOpen}
      kOnClose={() => { props.kSearchModalToggle(); }}
      kContent={
        (
          <Container
            maxWidth="lg"
            sx={{
              height: '100%',
              position: 'relative',
              width: '100%',
              padding: '0 !important',
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                position: 'absolute',
                top: 0,
                width: '100%',
              }}
            >
              <Box
                sx={{
                  my: { xs: 5, md: 10 },
                  height: { xs: '50px', md: '85px' },
                  display: 'flex',
                }}
              >
                <Logo icon={isMobile} width={sizeLogo} iconColor="accent" textColor="accent" />
              </Box>
              <CloseRoundedIcon
                sx={{
                  fill: LmiTheme.light.accent,
                  fontSize: { xs: '2rem', md: '5rem' },
                  cursor: 'pointer',
                  right: 0,
                  top: 0,
                }}
                onClick={() => { props.kSearchModalToggle(); }}
                className="closeModal"
              />
            </Stack>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent={{ xs: 'center', sm: 'space-between' }}
              alignItems="center"
              sx={{ height: '100%' }}
              gap={4}
            >

              <FormControl sx={{ width: '100%' }}>
                <TextField
                  hiddenLabel
                  inputProps={{
                    maxLength: 50,
                  }}
                  value={textSearch}
                  onChange={(e: any) => fnTextSearch(e)}
                  onKeyDown={(e: any) => (e.keyCode == 13
                    ? gotoSearch()
                    : '')}
                  placeholder={t('search.what_looking')}
                  variant="standard"
                  fullWidth
                  size="medium"
                  onFocus={() => {
                    setVisibleSuggestion(true);
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      setVisibleSuggestion(false);
                    }, 500);
                  }}
                  sx={{
                    backgroundColor: 'transparent',
                    input: {
                      height: '56px',
                      fontSize: { xs: '26px', md: '40px' },
                      fontWeight: 300,
                    },
                    '& ::before': {
                      borderWidth: '3px !important',
                      borderColor: `${LmiTheme.light.accent} !important`,
                    },
                  }}
                />
                {(textSearch || textSearch == '') && isVisibleSuggestion
                  ? <SearchSuggestion kTop={80} kInputText={textSearch} kOnClick={(text: string) => gotoSearch(text)} />
                  : ''}
              </FormControl>
              <Button
                onClick={() => gotoSearch()}
                sx={{
                  minWidth: { xs: '140px', md: '190px' },
                  height: { xs: '56px', md: '80px' },
                  borderRadius: 2,
                  textTransform: 'capitalize',
                  fontSize: { xs: 22, md: 26 },
                  fontWeight: 400,
                  border: `3px solid${LmiTheme.light.accent}`,
                  '&:hover': {
                    color: 'white',
                    backgroundColor: LmiTheme.light.accent,
                  },
                }}
                variant="outlined"
                disableElevation
              >
                {t('search.search')}
              </Button>
            </Stack>
          </Container>
        )
      }
    />
  );
}

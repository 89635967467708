import {
  ACTIVITY_VALIDATES_APPROVED_STATE,
  ACTIVITY_START_APPROVAL_PROCESS,
  UPDATE_REJECT_REASON,
  ACTIVITY_START_TASK,
  REFRESH_ACTIVITY_TASK_DONE,
  ROADIE_IN_PAST,
  APPROVE_REJECT_FAILURE,
  UPDATE_REVIEW_RATE,
  UPDATE_REVIEW_COMMENT,
  UPDATE_REVIEW_THUMBS,
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
  ACCEPTED_CIRCLE_JOIN_REQUEST,
  UPLOAD_PICTURE_FAILURE,
  CLEAR_NEW_DELIVERY_FLAG,
  ACTIVITY_START_VALIDATES_APPROVED_STATE,
} from '../../../types';

export default (state: any, action: any) => {
  switch (action.type) {
    // Start approval process
    case ACTIVITY_START_APPROVAL_PROCESS: {
      return {
        ...state,
        // lenderLocation: action.payload.lenderLocation,
        // lenderLocationAddress: action.payload.lenderLocationAddress,
        // messageToRenter: action.payload.messageToRenter,
        setNewDelivery: false,
        // taskEnded: false,
        // loadingActAction: true,
      };
    }
    case CLEAR_NEW_DELIVERY_FLAG: {
      return {
        ...state,
        setNewDelivery: false,
      };
    }
    // WHEN theres is still something missing in order to approve the reservation
    case ACTIVITY_VALIDATES_APPROVED_STATE: {
      return {
        ...state,
        message: action.payload.message,
        taskEnded: false,
        setNewDelivery: false,
        loadingActAction: false,
      };
    }
    case UPDATE_REJECT_REASON: {
      return {
        ...state,
        rejectReason: action.payload.rejectReason,
        rejectFailure: false,
        taskEnded: false,
      };
    }
    case UPLOAD_PICTURE_FAILURE: {
      return {
        ...state,
        uploadPictureErrorMessage: action.payload.uploadPictureErrorMessage,
        uploadPictureError: action.payload.uploadPictureError,
        taskEnded: false,
      };
    }
    case ACTIVITY_START_TASK: {
      return {
        ...state,
        taskEnded: false,
      };
    }
    case REFRESH_ACTIVITY_TASK_DONE: {
      return {
        ...state,
        taskEnded: action.payload.taskEnded,
        reviewComment: '',
        rejectFailure: false,
        loadingActAction: false,
      };
    }
    case ROADIE_IN_PAST: {
      return {
        ...state,
        diffRoadieMessage: action.payload.diffRoadieMessage,
        newStartTime: action.payload.newStartTime,
        lenderLocation: action.payload.lenderLocation,
        lenderLocationAddress: action.payload.lenderLocationAddress,
        messageToRenter: action.payload.messageToRenter,
        setNewDelivery: true,
        rejectFailure: false,
      };
    }
    case APPROVE_REJECT_FAILURE: {
      return {
        ...state,
        rejectFailure: true,
        loadingActAction: false,
        taskEnded: false,
        rejectFailureMessage: action.payload.rejectFailureMessage,
      };
    }
    case UPDATE_REVIEW_RATE: {
      return {
        ...state,
        idCatLndReviewByRenter: action.payload.idCatLndReviewByRenter,
        idCatLndReviewByLender: action.payload.idCatLndReviewByLender,
      };
    }
    case UPDATE_REVIEW_COMMENT: {
      return {
        ...state,
        reviewComment: action.payload.reviewComment,
      };
    }
    case UPDATE_REVIEW_THUMBS: {
      return {
        ...state,
        isThumbsUpReview: action.payload.isThumbsUpReview,
      };
    }
    case SET_LOADING_TRUE: {
      return {
        ...state,
        loadingActAction: true,
      };
    }
    case SET_LOADING_FALSE: {
      return {
        ...state,
        loadingActAction: false,
      };
    }
    case ACCEPTED_CIRCLE_JOIN_REQUEST: {
      return {
        ...state,
        loadingActAction: false,
        taskEnded: action.payload.taskEnded,
        taskError: action.payload.taskError,
      };
    }
    case ACTIVITY_START_VALIDATES_APPROVED_STATE: {
      return {
        ...state,
        loadingActAction: true,
        message: '',
      };
    }
    default:
      return state;
  }
};

import {
  useContext, useEffect, useReducer,
} from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  ClsModCreateCirlceJoinRequest,
  ClsModGetCircleDetail, ClsModGetCircleDetailItems, ClsModGetCircleDetailMembers, ClsModGetCirclesMainSearchCountry, ClsModGetCirclesSuggestedTopFiveCountry, ClsModGetUserCircles,
} from '@lendmeit/api_backend_lmi/dist/models';
import MyCirclesListingContext from './myCirclesListingContext';
import authContext from '../authentication/authContext';

import MyCirclesListingReducer from './myCirclesListingReducer';
import {
  GET_ALL,
  GET_BY_ID,
  DETAILS,
  PRECING_TYPE,
  PRECING_CHANGE,
  PRECING_HOURLY,
  CLEAR,
  FILTER_CIRCLE_ITEMS,
  CIRCLES_TYPE,
  GET_SEARCHED_CIRCLES,
  UPDATE_SUGGESTED_CIRCLES,
  UPDATE_SEARCHED_CIRCLES,
  INFO_IS_LOADING,
  LOAD_CIRCLES,
  LOAD_CIRCLES_DETAILS,
  LOAD_SEARCH_CIRCLES,
} from '../../types';
import ApiLMI from '../../services/apilmi';
import BookItemContext from '../bookItem/bookContext';
import { getItemAccesories, getItemBlockedIndDates } from '../../data/listing';
import { CircleDetailInfoModel } from '../../page/BackOffice/Circles/AddCircle/AddCircle.models';
import homeContext from '../home/homeContext';

const initialStte = {
  circles: null,
  images: null,
  condictions: null,
  accesories: null,
  loading: false,
  circle: null,
  itemBlockedIndDates: null,
  totalCircles: null,
  precingType: PRECING_HOURLY,
};

const LOAD_INFO_TYPE = {
  LOADED: 'loaded',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
  FAILED: 'failed',
  EMPTY: 'empty',
};

const MyCirclesListingState = ({ children }: any) => {
  const {
    lat,
    long,
    countryName,
  }: any = useContext(homeContext);

  const { token, user }: any = useContext(authContext);
  const [state, dispatch] = useReducer(MyCirclesListingReducer, initialStte);

  const {
    getBookImages,
    userGetUserTermsAndConditions,
  }: any = useContext(BookItemContext);

  const getExtraInformation = async () => {
    try {
      if (state.item) {
        const { idCatLndItem } = state.item;
        if (idCatLndItem !== 0) {
          const images = await getBookImages(idCatLndItem);
          const condictions = await userGetUserTermsAndConditions(idCatLndItem);
          const accesories = await getItemAccesories(idCatLndItem);
          const itemBlockedIndDates = await getItemBlockedIndDates(idCatLndItem);

          dispatch({
            type: GET_BY_ID,
            payload: {
              itemBlockedIndDates,
              images,
              condictions,
              accesories,
            },
          });
        }
      }
    } catch (err: any) {
      toast.error(t('global.server_error'));
    }
  };

  const loadCircleInformation = async (status: string) => {
    await dispatch({
      type: LOAD_CIRCLES,
      payload: status,
    });
  };

  const loadCircleDetails = async (status: string) => {
    await dispatch({
      type: LOAD_CIRCLES_DETAILS,
      payload: status,
    });
  };
  const searchCircle = async (status: string) => {
    await dispatch({
      type: LOAD_SEARCH_CIRCLES,
      payload: status,
    });
  };

  const getMyCircles = async (idCatLndLender: number) => {
    const _api = ApiLMI();
    // const idtoast = toast.loading('Loading information, Please wait...');

    try {
      await loadCircleInformation(LOAD_INFO_TYPE.LOADED);
      const modelRequest: ClsModGetUserCircles = {
        idCatLndLender: idCatLndLender || 0,
        toc: 'qwerty',
      };
      const { data } = await _api.circleGetUserCircles(modelRequest);

      const bodyTopFive: ClsModGetCirclesSuggestedTopFiveCountry = {
        lat: lat.toString(),
        lng: long.toString(),
        toc: 'qwerty',
        country: countryName,
      };

      const respSuggestedCircles: any = await _api.apiCircleGetCirclesSuggestedTopFiveCountryPost(bodyTopFive);
      if (typeof data === 'string') {
        const obj = JSON.parse(data.toString());
        const circles = obj.Response;

        if (obj.Error == '') {
          await dispatch({
            type: GET_ALL,
            payload: circles,
            suggestedCircles: JSON.parse(respSuggestedCircles.data).Response ? JSON.parse(respSuggestedCircles.data).Response : [],
          });
          await loadCircleDetails(LOAD_INFO_TYPE.SUCCESS);
        } else {
          await dispatch({
            type: GET_ALL,
            payload: [],
            suggestedCircles: [],
          });

          await loadCircleDetails(LOAD_INFO_TYPE.EMPTY);
        }
      } else {
        toast.error(t('global.server_error'));
      }
    } catch (err: any) {
      toast.error(t('global.server_error'));
    }
  };

  const getCirclesSearch = async (query: string) => {
    const _api = ApiLMI();
    await searchCircle(LOAD_INFO_TYPE.LOADING);
    try {
      const bodySearchCircle: ClsModGetCirclesMainSearchCountry = {
        searchQuery: query,
        lat: lat.toString(),
        lng: long.toString(),
        toc: 'qwerty',
        page: 1,
        pageSize: 20,
        country: countryName,
      };
      const dataSearchCircles: any = await _api.apiCircleGetCirclesMainSearchCountryPost(bodySearchCircle);
      const parsedResult = JSON.parse(dataSearchCircles.data);

      if (parsedResult.Error == '') {
        dispatch({
          type: GET_SEARCHED_CIRCLES,
          payload: parsedResult.Response,
        });

        await searchCircle(parsedResult.Response.length === 0 ? LOAD_INFO_TYPE.EMPTY : LOAD_INFO_TYPE.SUCCESS);
      } else {
        toast.error(t('global.server_error_details'));
      }
    } catch (error: any) {
      console.log(error);
      toast.error(error);
      await searchCircle(LOAD_INFO_TYPE.ERROR);
    }
  };

  const details = async (circle: CircleDetailInfoModel) => {
    try {
      if (circle) {
        isLoading();
        const _api = ApiLMI();
        const modGetCircleDetail: ClsModGetCircleDetail = {
          toc: 'qwerty',
          idCatLndCircle: circle.idCatLndCircle,
        };
        const dataGetCircle: any = await _api.circleGetCircleDetail(modGetCircleDetail);

        const modGetCircleMembers: ClsModGetCircleDetailMembers = {
          toc: 'qwerty',
          idCatLndCircle: circle.idCatLndCircle,
          showAll: true,
        };
        const dataGetMembers: any = await _api.circleGetCircleDetailMembers(modGetCircleMembers);
        const modGetCircleItems: ClsModGetCircleDetailItems = {
          toc: 'qwerty',
          idCatLndCircle: circle.idCatLndCircle,
          showAll: true,
        };
        const dataGetItems: any = await _api.circleGetCircleDetailItems(modGetCircleItems);
        dispatch({
          type: DETAILS,
          payload: JSON.parse(dataGetCircle.data).Response[0] || [],
          members: JSON.parse(dataGetMembers.data).Response || [],
          items: JSON.parse(dataGetItems.data).Response || [],
        });
      }
    } catch (error) {
      toast.error(t('global.server_error_details'));
    }
  };

  const changePrcingType = async (type: PRECING_TYPE) => {
    try {
      dispatch({
        type: PRECING_CHANGE,
        payload: type,
      });
    } catch (e: any) {
      toast.error(e ?? t('global.server_error_price'));
    }
  };

  const filterCircleDetailItems = async (searchValue: any) => {
    const filteredArr = state.items.filter((element: any) => element.name.toLowerCase().includes(searchValue));
    dispatch({
      type: FILTER_CIRCLE_ITEMS,
      itemsFiltered: filteredArr,
    });
  };

  const filterCircles = async (searchValue: any) => {
    const filteredArr = state.circles.filter((element: any) => element.name.toLowerCase().includes(searchValue.toLowerCase()));
    dispatch({
      type: FILTER_CIRCLE_ITEMS,
      itemsFiltered: filteredArr,
    });
  };

  const initialState = async () => {
    try {
      dispatch({
        type: CLEAR,
        payload: initialStte,
      });
      // eslint-disable-next-line no-empty
    } catch (_) {
    }
  };

  const setCircleListingToShow = async (type: string) => {
    try {
      dispatch({
        type: CIRCLES_TYPE,
        payload: type,
      });
      // eslint-disable-next-line no-empty
    } catch (_) {
    }
  };

  const requestJoinCircle = async (idCircle: number) => {
    const _api = ApiLMI();
    try {
      const bodyJoin: ClsModCreateCirlceJoinRequest = {
        toc: 'qwert',
        idCatLndCircle: idCircle,
      };
      const respJoin: any = await _api.circleCreateCirlceJoinRequest(bodyJoin);
      const parsedResp = JSON.parse(respJoin.data);
      if (parsedResp.Error == '') {
        // Success join request circle
        const updatedSearchedCircleIndex = state.searchedCircles?.findIndex((element: any) => element.idCatLndCircle == idCircle);
        if (updatedSearchedCircleIndex > -1) {
          state.searchedCircles[updatedSearchedCircleIndex].pendingApproval = true;
          state.circle.pendingApproval = true;
          dispatch({
            type: UPDATE_SEARCHED_CIRCLES,
            payload: state.searchedCircles,
            circle: state.circle,
          });
        }
        const updatedSuggestedCircleIndex = state.suggestedCircles?.findIndex((element: any) => element.idCatLndCircle == idCircle);
        if (updatedSuggestedCircleIndex > -1) {
          state.suggestedCircles[updatedSuggestedCircleIndex].pendingApproval = true;
          state.circle.pendingApproval = true;
          dispatch({
            type: UPDATE_SUGGESTED_CIRCLES,
            payload: state.suggestedCircles,
            circle: state.circle,
          });
        }
        toast.success(t('global.join_circle'));
      } else {
        // Something wrong request join circle
        toast.error(t('global.server_error_price'));
      }
      console.log(respJoin);
    } catch (error: any) {
      toast.error(error ?? t('global.server_error_price'));
    }
  };

  const isLoading = async () => {
    dispatch({
      type: INFO_IS_LOADING,
    });
  };

  useEffect(() => {
    if (!token || !user) {
      initialState();
    }
  }, [token, user]);

  const { t } = useTranslation('global');

  return (
    <MyCirclesListingContext.Provider
      key={token}
      value={
        {
          ...state,
          circle: state.circle,
          circles: state.circles,
          suggestedCircles: state.suggestedCircles,
          members: state.members,
          condictions: state.condictions,
          itemBlockedIndDates: state.itemBlockedIndDates,
          items: state.items,
          precingType: state.precingType,
          totalCircles: state.totalCircles,
          loading: state.loading,
          circlesTypeToShow: state.circlesTypeToShow,
          searchedCircles: state.searchedCircles,
          initialState,
          getMyCircles,
          details,
          getExtraInformation,
          changePrcingType,
          filterCircleDetailItems,
          filterCircles,
          setCircleListingToShow,
          getCirclesSearch,
          requestJoinCircle,
          isLoading,
        }
      }
    >
      {children}
    </MyCirclesListingContext.Provider>
  );
};

export default MyCirclesListingState;

import {
  Box,
  Button,
  CardContent,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import CircleDetailHeader from './Components/CircleDetailHeader/CircleDetailHeader';
import AboutCircle from './Components/AboutCircle/AboutCircle';
import Heading from '../AddCircle/Components/Heading/Heading';
import ConditionsToJoinDetail from './Components/ConditionsToJoin/ConditionsToJoinDetail';
import { DialogCommon, LoadingLayout } from '../../../../components/Common';
import CircleTagsDetail from './Components/CircleTagsDetail.tsx/CircleTagsDetail';
import CircleMembersDetail from './Components/CircleMembersDetail.tsx/CircleMembersDetails';
import CircleItemsDetail from './Components/CircleItemsDetail/CircleItemsDetail';
import CircleSpecialFeeDetail from './Components/SpecialFeeDetail/SpecialFeeDetail';
import LeaveCircle from './Components/LeaveCircle/LeaveCircle';
import MyCirclesListingContext from '../../../../context/myCirclesListing/myCirclesListingContext';
import AddEditCirclesContext from '../../../../context/circles/addEditCirclesContext';
import UnavailableCircle from './Components/UnavailableCircle/UnavailableCircle';

export default function CircleDetail() {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);

  const [totalMembers, setTotalMembers] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  const circlesContextLog = useContext(AddEditCirclesContext);
  const {
    confirmLeaveCircle,
    leftCircle,
    getCircleItems,
  }: any = circlesContextLog;

  const {
    circle,
    getMyCircles,
    loading,
  }: any = useContext(MyCirclesListingContext);

  useEffect(() => {
    (async function fetchCircleItems() {
      if (circle) {
        await getCircleItems(circle?.idCatLndCircle);
      }
    }());
  }, [circle]);

  const handleModalToggle = () => {
    setOpen(!false);
  };

  const closeDialogModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (leftCircle) {
      getMyCircles();
    }
  }, [leftCircle]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation('global');

  return (
    <Box>
      {!loading ? (
        <>
          {!circle?.active && !circle?.isOwner && !circle?.isAdmin && <UnavailableCircle />}

          <CardContent sx={{
            pointerEvents: !circle?.active && !circle?.isOwner && !circle?.isAdmin ? 'none' : 'inherit',
            opacity: !circle?.active && !circle?.isOwner && !circle?.isAdmin ? 0.4 : 1,
            userSelect: !circle?.active && !circle?.isOwner && !circle?.isAdmin ? 'none' : 'auto',
          }}
          >

            <CircleDetailHeader />

            <Box mt={3}>
              <Heading kTitle={t('circles.detail.about_circle')} />
            </Box>
            <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
              <AboutCircle />
            </Paper>

            <Box mt={3}>
              <Heading kTitle={t('circles.detail.conditions_to_join')} />
            </Box>
            <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
              <ConditionsToJoinDetail />
            </Paper>

            <Box mt={3}>
              <Heading kTitle={t('circles.detail.special_fee')} />
            </Box>
            <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
              <CircleSpecialFeeDetail />
            </Paper>

            <Box mt={3}>
              <Heading kTitle={t('circles.detail.tags_circle')} />
            </Box>
            <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
              <CircleTagsDetail />
            </Paper>

            <Stack
              mt={3}
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Heading kTitle={t('circles.detail.members')} />
              <Typography sx={{
                pb: '16px',
                fontSize: '16px',
                color: '#999da2',
                ml: '10px',
              }}
              >
                {`${totalMembers} ${t('circles.detail.members')}`}
              </Typography>
            </Stack>
            <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
              <CircleMembersDetail kTotalMembers={(a: number) => setTotalMembers(a)} />
            </Paper>

            <Stack
              mt={3}
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Heading kTitle={t('circles.detail.items')} />
              <Typography sx={{
                pb: '16px',
                fontSize: '16px',
                color: '#999da2',
                ml: '10px',
              }}
              >
                {`${totalItems} ${t('circles.detail.items')}`}
              </Typography>
            </Stack>
            <Paper variant="outlined" sx={{ mb: '30px', borderRadius: '10px' }}>
              <CircleItemsDetail kTotalItems={(a: number) => setTotalItems(a)} />
            </Paper>

            {(circle?.isOwner || circle?.isAdmin || circle?.isMember) && (
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                mt: '30px',
                mb: isMobile ? '60px' : '14px',
              }}
              >
                <Button
                  variant="text"
                  onClick={() => handleModalToggle()}
                >
                  <Typography
                    sx={{
                      textTransform: 'none',
                      fontSize: '18px',
                      fontWeight: 'bold',
                    }}
                  >
                    {t('circles.detail.leave_circle')}
                  </Typography>
                </Button>
              </Box>
            )}
            <DialogCommon
              kTitle={t('circles.detail.leave_circle')}
              kMaxWidth="sm"
              kMaxHeight="280px"
              kAlignBtn="center"
              kBorderRadius={isMobile ? 0 : '10px'}
              kFixedDialog={0}
              kFixedDialogPosition="fixed"
              kDisableBottomDivider
              kKeepBtnSize
              kSlideTransition={isMobile}
              kTopDivider
              kOpenDialogLists={open}
              kOnClose={closeDialogModal}
              kOnSecondary={closeDialogModal}
              kOnThird={() => {
                if (circle?.isOwner) {
                  window.scrollTo(0, 0);
                  history.push({
                    pathname: '/circles/members',
                    state: {
                      isChoosingNewOwner: true,
                      circleId: circle?.idCatLndCircle,
                    },
                  });
                } else {
                  closeDialogModal();
                  confirmLeaveCircle(circle?.idCatLndCircle, false, 0);
                }
              }}
              kContent={
                (
                  <LeaveCircle />
                )
              }
            />
          </CardContent>
        </>

      ) : <LoadingLayout />}
    </Box>
  );
}

import {
  Box, Button, Card, IconButton, Paper, Stack, Tooltip, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useState } from 'react';
import promoCode from '../../../../assets/profile_private/png/lmi-my-promo-code-modal.jpg';
import { DialogCommon, LmiTheme } from '.';
import ShareSocialMedia from '../../Circles/AddCircle/Components/CircleCreated/ShareSocialMedia/ShareSocialMedia';

export default function SharePromoCode(props: any) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [openShareSocialMedia, setOpenShareSocialMedia] = useState(false);

  const { t } = useTranslation('global');

  return (
    <>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        maxWidth="686px"
        spacing={0}
        py={3}
      >
        <Paper
          variant="outlined"
          sx={{
            mb: 1.5,
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
            backgroundColor: '#F7CC84',
            width: { xs: '390px', sm: '390px', md: '400px' },
          }}
        >
          <Box my={2} ml={2} mr={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Stack
                pt={0}
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Card
                  component="section"
                  elevation={0}
                  sx={{
                    height: 280,
                    width: 280,
                    backgroundImage: `url(${promoCode})`,
                    overflow: 'visible',
                    backgroundPosition: 'absolute',
                    backgroundSize: 'cover',
                  }}
                />
                <Box>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    backgroundColor: '#f7f7f7',
                    borderRadius: '10px',
                    alignItems: 'center',
                    pl: 2,
                    pr: 1,
                    height: '48px',
                    width: { xs: '282px', sm: '282px', md: '135px' },
                    maxWidth: '395px',
                  }}
                  >
                    {props?.myPromoCode != null && (
                      <Typography sx={{ fontSize: '13px' }}>
                        {props?.myPromoCode.toUpperCase()}
                      </Typography>
                    )}
                    <Tooltip title={t('global.copy')}>
                      <IconButton onClick={() => { navigator.clipboard.writeText(props?.myPromoCode ? props.myPromoCode.toUpperCase() : ''); }}>
                        <ContentCopyIcon color="primary" fontSize="medium" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Paper>
        <Typography variant="h6" gutterBottom>
          {t('profile.modalPromoCodeInvite')}
        </Typography>

        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '98px',
          width: { xs: '340px', sm: '340px', md: '395px' },
        }}
        >
          <Typography
            align="center"
            sx={{
              fontSize: 'body2.fontSize',
              color: LmiTheme.light.systemBackground.tertiary,
            }}
          >
            {t('profile.modalPromoCodeDescOne')}
          </Typography>
          <Typography
            align="center"
            sx={{
              fontSize: 'body2.fontSize',
              color: LmiTheme.light.systemBackground.tertiary,
            }}
          >
            {t('profile.modalPromoCodeDescTwo')}
          </Typography>
        </Box>
        <Box height={15} />
        <Button
          onClick={() => { setOpenShareSocialMedia(true); }}
          disableElevation
          variant="contained"
          size="large"
          sx={{
            px: 5,
            py: 1,
            fontSize: '18px',
            textTransform: 'none',
            fontWeight: 'bold',
            borderRadius: '10px',
          }}
        >
          {t('circles.main.share')}
        </Button>
      </Stack>
      <DialogCommon
        kTitle={t('circles.main.share_in')}
        kMaxWidth="xs"
        kEnableBorderRadius
        kMaxHeight={isMobile ? '180px' : 'auto'}
        kBorderRadius={isMobile ? 0 : '10px'}
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kDisableContentPadding
        kKeepBtnSize
        kCenterTitle
        kSlideTransition={isMobile}
        kTopDivider
        kOpenDialogLists={openShareSocialMedia}
        kOnClose={() => setOpenShareSocialMedia(false)}
        kDisableActions
        kContent={
          (
            <ShareSocialMedia
              deepLink={props?.myPromoCodeLink}
              shareCode
              code={props.myPromoCode}
            />
          )
        }
      />
    </>
  );
}

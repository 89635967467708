/* eslint-disable react/jsx-props-no-spreading */
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { MapCarouselModels, ImageModel } from './Swiper.models';
import 'swiper/swiper-bundle.min.css';
import './Swiper.style.css';

SwiperCore.use([Navigation]);

export default function CustomSwiper(props: MapCarouselModels) {
  const itemImages = props.images ? props.images : [];
  const MainParams = {
    modules: [Navigation],
    spaceBetween: 10,
    slidesPerView: 1,
    navigation: itemImages.length != 1,

  };

  return (
    <Swiper {...MainParams} className="lmiw-c-swiper lmiw-c-swiper--main">
      {itemImages.map((data: ImageModel) => (
        <SwiperSlide
          key={data.idDetLndItemImage}
          className={data.videoPath != '' ? '__video' : '__image'}
          style={data.videoPath != '' ? { aspectRatio: '5 / 4' } : {
            backgroundImage: `url(${data.imgPath})`,
            aspectRatio: '5 / 4',
          }}
        >
          {data.videoPath != ''
            ? (
              <video
                width="100%"
                controls
              >
                <track kind="captions" />
                <source src={data.videoPath} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )
            : ''}
        </SwiperSlide>
      ))}
      {!itemImages.length
        ? (
          <SwiperSlide
            key="0"
            className="__image"
            style={{
              backgroundImage: 'url(https://www.subicicleta.com/assets/images/no-image.png)',
              aspectRatio: '5 / 4',
            }}
          />
        ) : ''}
    </Swiper>
  );
}

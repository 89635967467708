import {
  Box, Typography, Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LmiTheme } from '../../../../../../../components/Common';

export default function ManageMembersEmpty() {
  const { t } = useTranslation('global');

  return (
    <Box>
      <Stack
        p={0}
        direction="row"
        justifyContent="space-between"
        spacing={3}
      >
        <Typography
          fontSize="17px"
          color={LmiTheme.light.systemBackground.tertiary}
        >
          {t('circles.add_circle_page.empty_manage_members')}
        </Typography>
      </Stack>
    </Box>
  );
}

import {
  Accordion, AccordionSummary,
  Box, ListItemText,

} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { AccordionLink, LinkItems } from './AccordionLink.models';
import AccordionLinkStyles from './AccordionLink.style';

export default function AccordionLinksFooter(props: AccordionLink) {
  return (

    <Box sx={{ ...AccordionLinkStyles, width: '100%', minWidth: '180px' }}>
      <Accordion
        elevation={0}
        expanded={props.kExpanded}
        onChange={() => props.kAccordionClik()}
        className="lmiw-accordion-container"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ display: { xs: 'block', sm: 'none' } }} />}
          aria-controls="company-content"
          id={`${props.kTitle}-header`}
          sx={{ p: 0 }}
          className="lmiw-summary"
        >
          <Box className="lmiw-summary-title">{props.kTitle}</Box>
        </AccordionSummary>

        {props.kLinkItems.map((item: LinkItems) => (
          <ListItemText key={item.kLinkText}>
            <Box onClick={() => item.kLinkClik()} className="lmiw-summary-link">
              {item.kLinkText}
            </Box>
          </ListItemText>
        ))}

      </Accordion>
    </Box>

  );
}

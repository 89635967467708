import { ArrowBackIos } from '@mui/icons-material';
import {
  Box, FormControl, Typography, TextField, Container, Avatar, Button, Stack, IconButton,
} from '@mui/material';
import { useContext, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded';
import Compressor from 'compressorjs';
import { IconLinkButton, LmiTheme } from '../../ProfilePrivate/Components';
import publicProfileContext from '../../../../context/publicProfile/publicProfileContext';

export default function UserCompletion() {
  const [errors, setErrors] = useState({ name: '', description: '', picture: '' });
  const [droppedImage, setDroppedImage] = useState('');

  const [loading, setLoading] = useState(false);

  const pProfileContext = useContext(publicProfileContext);
  const {
    privateProfileInfo,
    updateProfileInfo,
    uploadUpdateProfilePicture,
    updateUserNameAndDescription,
  }: any = pProfileContext;

  const onImageDropped = (file: any) => {
    // eslint-disable-next-line no-new
    new Compressor(file, {
      convertTypes: [
        'image/png',
        'image/webp',
        'image/bmp',
        'image/tiff',
      ],
      quality: 0.8,
      convertSize: 1000,
      maxWidth: 4096,
      maxHeight: 4096,
      minWidth: 1080,
      minHeight: 1920,
      success: (compressedResult) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          setDroppedImage(reader.result as string);
          updatePicture(reader.result as string);
        };
        reader.readAsDataURL(compressedResult);
      },
    });
  };

  const updatePicture = (result: string) => {
    setLoading(true);
    uploadUpdateProfilePicture(result)
      .then(() => {
        updateProfileInfo({
          ...privateProfileInfo,
          profilePicture: result,
        });
        setLoading(false);
      });
  };

  const limitName = 70;
  const limitDescription = 500;

  const fileTypes = [
    'JPG', 'JPEG',
    'PNG', 'WEBP',
    'BMP', 'TIFF',
  ];

  const [newName, setNewName] = useState('');
  const [newDescription, setNewDescription] = useState('');

  const changeName = (e: any) => {
    setNewName(e.target.value);
  };

  const changeDescription = (e: any) => {
    setNewDescription(e.target.value);
  };

  const redirectState = JSON.parse(localStorage.getItem('redirectState') || '{}');

  const redirectPath = redirectState.kFromDraft
    ? {
      pathname: '/listing/edit',
      state: {
        ...redirectState,
        kItemId: 0,
        kItemIsNew: true,
        kItemIndex: redirectState.kItemIndex || 0,
        updateSwiper: false,
        kShowDetailsMobile: false,
      },
    }
    : '/';

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const isFullNameValid = /^[a-zA-Z]{2,40}( [a-zA-Z]{2,40})+$/;
    const newErrors = { name: '', description: '', picture: '' };
    let formIsValid = true;

    if (!isFullNameValid.test(newName)) {
      newErrors.name = t('sign_up.fullname_required');
      formIsValid = false;
    }

    if (!newDescription.trim()) {
      newErrors.description = t('sign_up.description_required');
      formIsValid = false;
    }

    if (!droppedImage) {
      newErrors.picture = t('sign_up.picture_required');
      formIsValid = false;
    }

    if (loading) {
      newErrors.picture = t('sign_up.picture_loading');
      formIsValid = false;
    }

    setErrors(newErrors);

    if (formIsValid) {
      updateProfileInfo({
        ...privateProfileInfo,
        dscription: newDescription,
        completeName: newName,
      });
      updateUserNameAndDescription({
        description: newDescription,
        userName: newName,
      });
      history.push(redirectPath);
      localStorage.removeItem('redirectState');
    }
  };

  const { t } = useTranslation('global');
  const history = useHistory();

  return (
    <>
      <Box mt={3} sx={{ height: '44px' }}>
        <IconLinkButton
          kStartIcon={<ArrowBackIos />}
          kText={t('sign_up.back_homepage')}
          kFontSize={isMobile ? 'body2.fontSize' : 'body1.fontSize'}
          kFontWeight={isMobile ? '400' : 'bold'}
          kOnClick={() => history.push('/login')}
        />
      </Box>
      <Container maxWidth="xs" component="form" noValidate onSubmit={handleSubmit} sx={{ mb: '50px' }}>
        <Box sx={{
          mx: 'auto', mb: '30px', maxWidth: '140px', borderRadius: '100px',
        }}
        >
          <FileUploader
            maxSize={256}
            multiple={false}
            handleChange={onImageDropped}
            name="file"
            id="file"
            types={fileTypes}
          >
            <Button
              sx={{
                aspectRatio: '5 / 5',
                borderRadius: '50%',
                borderColor: LmiTheme.light.systemBackground.separator,
                bgcolor: 'transparent',
              }}
            >
              <Box
                sx={{
                  borderRadius: '50%',
                  width: isTablet ? 170 : 130,
                  height: isTablet ? 170 : 130,
                  borderStyle: 'dashed',
                  borderWidth: '1px',
                  textAlign: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                  verticalAlign: 'middle',
                  display: 'flex',
                }}
              >
                {droppedImage !== '' ? (
                  <>
                    <Avatar
                      src={droppedImage}
                      sx={{ width: isTablet ? 170 : 130, height: isTablet ? 170 : 130 }}
                    />
                    <IconButton
                      sx={{
                        backgroundColor: '#038de2',
                        position: 'absolute',
                        bottom: '8px',
                        right: '8px',
                        ':hover': { backgroundColor: '#038de2' },
                      }}
                    >
                      <CameraAltRoundedIcon
                        fontSize={isMobile ? 'large' : 'small'}
                        style={{ color: 'white' }}
                      />
                    </IconButton>
                  </>
                ) : (
                  <AddOutlinedIcon color="primary" sx={{ width: '24px', height: '24px' }} />
                )}

                <IconButton sx={{
                  backgroundColor: '#038de2',
                  position: 'absolute',
                  bottom: '8px',
                  right: '8px',
                  ':hover': { backgroundColor: '#038de2' },
                }}
                >
                  <CameraAltRoundedIcon fontSize={isMobile ? 'large' : 'small'} style={{ color: 'white' }} />
                </IconButton>
              </Box>
            </Button>

          </FileUploader>
          {errors.picture && (
            <Typography variant="caption" color="error">
              {errors.picture}
            </Typography>
          )}

        </Box>

        <FormControl fullWidth>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            pb={1}
          >
            <Box>
              <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
              <Typography component="span" fontWeight={500}>{t('profile.name')}</Typography>
            </Box>
            <Typography component="span" color={LmiTheme.light.systemBackground.tertiary}>
              {`${(newName?.length)}/${limitName}`}
            </Typography>
          </Stack>
          <TextField
            hiddenLabel
            fullWidth
            id="full-name"
            placeholder={t('welcome.enter_full_name')}
            name="full-name"
            onChange={changeName}
            error={Boolean(errors.name)}
            helperText={errors.name}
            inputProps={{ maxLength: limitName }}
          />
        </FormControl>

        <FormControl sx={{ mt: 2 }} fullWidth>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            pb={1}
          >
            <Box>
              <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
              <Typography component="span" fontWeight={500}>{t('profile.description')}</Typography>
            </Box>
            <Typography component="span" color={LmiTheme.light.systemBackground.tertiary}>
              {`${(newDescription?.length)}/${limitDescription}`}
            </Typography>
          </Stack>
          <TextField
            hiddenLabel
            fullWidth
            id="description"
            placeholder={t('welcome.describe_yourself')}
            name="description"
            onChange={changeDescription}
            multiline
            rows={4}
            error={Boolean(errors.description)}
            helperText={errors.description}
            inputProps={{ maxLength: limitDescription }}
          />
        </FormControl>

        <Button
          disabled={loading || !newName.trim() || !newDescription.trim() || !droppedImage}
          type="submit"
          fullWidth
          variant="contained"
          disableElevation
          size="large"
          sx={{
            my: 1, borderRadius: '50px', height: '56px', textTransform: 'none', mt: '50px',
          }}
        >
          {`${t('sign_up.continue')}`}
        </Button>

        <Button
          fullWidth
          variant="outlined"
          sx={{
            my: 1, borderRadius: '50px', height: '56px',
          }}
          onClick={() => {
            history.push(redirectPath);
            localStorage.removeItem('redirectState');
            localStorage.removeItem('userExists');
          }}
        >
          {t('global.skip')}
        </Button>

      </Container>
      <Box />
    </>

  );
}

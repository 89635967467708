const getElement = (name: string) => {
  const element = document.getElementById(name);
  const coor = element?.getBoundingClientRect();
  element?.focus();
  return coor;
};

export default function elementPosition(errors: any) {
  let position = { x: 0, y: 0 };
  let type = '';
  if (Object.keys(errors).length) {
    const {
      circlePicture,
      dscription,
      name,
      addressCircle,
      tags,
      replacementCost,
      address,
      images,
      title,
      descrip,
    } = errors;
    if (circlePicture) {
      const coor = getElement('file');
      type = 'file';
      const x = coor?.x || 0;
      const y = coor?.y || 0;
      position = { x, y };
    } else if (name) {
      const coor = getElement('title');
      type = 'title';
      const x = coor?.x || 0;
      const y1 = coor?.y || 0;
      let y = 0;
      if (y1 < 100) {
        y = y1 + 300;
      }
      if (y1 > 100) {
        y = y1 - 100;
      }
      position = { x, y };
    } else if (dscription) {
      const coor = getElement('description');
      type = 'description';
      const x = coor?.x || 0;
      const y1 = coor?.y || 0;
      let y = 0;
      if (y1 < 300) {
        y = y1 + 400;
      }
      if (y1 > 300) {
        y = y1 - 100;
      }
      position = { x, y };
    } else if (addressCircle) {
      const coor = getElement('address');
      type = 'addressCircle';
      const x = coor?.x || 0;
      const y = 760;
      position = { x, y };
    } else if (tags) {
      const coor = getElement('tags');
      type = 'tags';
      const x = coor?.x || 0;
      const y = 1260;
      position = { x, y };
    } else if (images) {
      type = 'images';
      position = { x: 0, y: 200 };
    } else if (title) {
      const coor = getElement('title');
      type = 'title';
      const x = coor?.x || 0;
      const y = 764;
      position = { x, y };
    } else if (replacementCost) {
      const coor = getElement('itemvalue');
      type = 'replacementCost';
      const x = coor?.x || 0;
      const y = 764;
      position = { x, y };
    } else if (descrip) {
      const coor = getElement('description');
      type = 'descrip';
      const x = coor?.x || 0;
      const y = 764;
      position = { x, y };
    } else if (address) {
      const coor = getElement('location');
      type = 'address';
      const x = coor?.x || 0;
      const y = 1200;
      position = { x, y };
    }
  }
  return { position, type };
}

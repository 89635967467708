import {
  GET_ALL,
  DETAILS,
  GET_BY_ID,
  PRECING_CHANGE,
  CLEAR,
  GET_DYNAMIC_LINK,
} from '../../types';

export default (state: any, action: any) => {
  switch (action.type) {
    case GET_ALL:
      return {
        ...state,
        items: action.payload,
      };
    case GET_DYNAMIC_LINK:
      return {
        ...state,
        dynamicLink: action.payload,
      };
    case DETAILS:
      const
        item = action.payload;
      return {
        ...state,
        item,
        loading: true,
        // loadingDetails: true,
        itemImages: action.itemImages,
        totalItems: action.totalItems,
      };
    case GET_BY_ID:
      const {
        images,
        condictions,
        accesories,
        itemBlockedIndDates,
      } = action.payload;
      return {
        ...state,
        images,
        condictions,
        accesories,
        itemBlockedIndDates,
        loading: false,
        // loadingDetails: false,
      };
    case PRECING_CHANGE:
      return {
        ...state,
        precingType: action.payload,
      };
    case CLEAR:
      const initialState = action.payload;
      return { ...initialState };

    default:
      return state;
  }
};

import {
  Grid, Typography, Button, Stack, Card, Box, Fab,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BannerPromoStyle from './BannerPromo.style';
import { BannerCirlcesModels } from './BannerPromo.models';

export default function BannerPromo(props: BannerCirlcesModels) {
  return (
    <Box sx={{ ...BannerPromoStyle }}>
      <Card
        component="section"
        elevation={0}
        sx={{
          height: {
            lg: 350, md: 280, sm: 230, xs: 160,
          },
          backgroundImage: `url(${props.kBackground})`,
          backgroundColor: props.kColorBg,
          overflow: 'visible',
          backgroundPosition: 'top left',
          backgroundSize: { sm: '110%', md: 'cover' },
        }}
        className="lmiwBase lmiwRadiusBottom lmiwRadiusTop"
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          maxWidth="xl"
        >
          <Grid
            item
            xs={6}
            md={5}
            className="lmiwRadiusBottom"
            sx={{
              zIndex: '1',
            }}
          >
            <Stack
              direction="column"
              justifyContent={{ xs: 'end', sm: 'center' }}
              alignItems="flex-start"
              spacing={1}
              sx={{
                ml: { xs: 2, sm: 5 },
                height: {
                  lg: 350, md: 280, sm: 230, xs: 160,
                },
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: props.kColorText,
                    fontWeight: '900',
                    lineHeight: 1,
                    marginBottom: { xs: 1, md: 2 },
                    fontSize: {
                      xs: 18, sm: 32, md: 40, lg: 50,
                    },
                  }}
                  component="h3"
                >
                  {props.kTitle}
                </Typography>
                {props.kText ? (
                  <Typography
                    sx={{
                      color: props.kColorText,
                      fontWeight: '500',
                      lineHeight: { xs: 1.2, sm: 1.4 },
                      marginBottom: { xs: 2, sm: 1, md: 2 },
                      fontSize: {
                        xs: 12, sm: 16, md: 20, lg: 24,
                      },
                    }}
                    component="h5"
                  >
                    {props.kText}
                  </Typography>
                ) : '' }
              </Box>
              <Button
                onClick={() => props.kClick()}
                variant="contained"
                fullWidth={false}
                sx={{
                  minWidth: { xs: '60px', md: '160px' },
                  maxWidth: '250px',
                  borderRadius: '50px',
                  height: '44px',
                  textTransform: 'lowercase',
                  fontWeight: '500',
                  whiteSpace: 'nowrap',
                  padding: '0px 25px',
                  fontSize: { xs: 12, sm: 16 },
                  display: { xs: 'none', sm: 'block' },
                  backgroundColor: props.kBtnColor,
                  '&:hover': {
                    backgroundColor: props.kBtnColor,
                  },
                  '&:first-letter': {
                    textTransform: 'uppercase',
                  },
                }}
              >
                {props.kBtnText}
              </Button>

              <Fab
                size="small"
                aria-label="go"
                onClick={() => props.kClick()}
                sx={{
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  border: '3px solid white',
                  color: 'white',
                  position: 'absolute',
                  right: 16,
                  bottom: 16,
                  display: { xs: 'flex', sm: 'none' },
                }}
              >
                <ArrowForwardIcon />
              </Fab>
            </Stack>
          </Grid>
          <Box
            className="lmiwGradientRight"
            sx={{
              background: {
                sm: `linear-gradient(to left, rgba(0, 0, 0, 0), ${props.kColorBg} 100%)`,
                xs: `linear-gradient(to bottom, rgba(0, 0, 0, 0), ${props.kColorBg} 120%)`,
              },
              display: 'block',
            }}
          />
        </Grid>
      </Card>
    </Box>
  );
}

BannerPromo.defaultProps = {
  kColorText: 'white',

};

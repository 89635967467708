/* eslint-disable max-lines */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React, {
  useContext, useEffect, useState,
} from 'react';
import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import moment from 'moment';
// import { LocalizationProvider, TimePicker } from '@mui/lab';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { isMobile } from 'react-device-detect';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LmiTheme from '../../../../../themes/lmi-mui-theme/lmi-theme';
import { ItemPricing, SectionTitle } from '../../../../../components/Common/index';
import bookItemContext from '../../../../../context/bookItem/bookContext';
import { formatDateLocal } from '../../../../../services/common';
import CalendarHourly from './calendars/calendarHourly';
import CalendarRentals from './calendars/calendarRentals';

function Availability() {
  const {
    bookDetails,
    changePrcingType,
    precingType,
    setDateRange,
    setHours,
    availability: {
      dateRange,
      errorAvailability,
      hours,
    },
    bookItem: {
      unavailableDates,
    },
    updateFromHourRental,
    updateToHourRental,
    // fromHour,
    // toHour,
  }: any = useContext(bookItemContext);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const newDate = new Date();
  const date = newDate.getDate();
  const month = newDate.getMonth();
  const year = newDate.getFullYear();
  const currentDate = new Date(year, month, date);
  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newPriceSelected: string,
  ) => {
    if (newPriceSelected !== null) {
      setNullDefaultDates(false);
      setPrecingTypeIfDisabled(newPriceSelected);
      changePrcingType(newPriceSelected);
      if (newPriceSelected != 'HOURLY') {
        setIsEndTimePlaceHolderVisible(true);
        setIsStartTimePlaceHolderVisible(true);
      }
      setDateRange([null, null]);
    }
  };

  let endTimeChanged = false;

  // const nextYear = moment().year() + 1;
  const currentYear = moment().year();
  const currentDay = moment().format('DD');
  const currentMonth = moment().format('MM');
  const nextHour = moment().add(1, 'hours').format('HH');
  const nextTwoHours = moment().add(3, 'hours').format('HH');

  const kHourlyDisabled = !(bookDetails && bookDetails.hourlyRentalPrice && bookDetails.hourlyRentalPrice !== '0.00');
  const kDailyDisabled = !(bookDetails && bookDetails.dailyRentalPrice && bookDetails.dailyRentalPrice !== '0.00');
  const kWeeklyDisabled = !(bookDetails && bookDetails.weeklyRentalPrice && bookDetails.weeklyRentalPrice !== '0.00');
  const kMonthlyDisabled = !(bookDetails && bookDetails.monthlyRentalPrice && bookDetails.monthlyRentalPrice !== '0.00');

  const _precingTypeIfDisabled = !kDailyDisabled
    ? precingType : (!kWeeklyDisabled ? 'WEEKLY'
      : (!kMonthlyDisabled ? 'MONTHLY' : 'HOURLY'));
  const [precingTypeIfDisabled, setPrecingTypeIfDisabled] = useState(_precingTypeIfDisabled);
  const [isEndTimePlaceHolderVisible, setIsEndTimePlaceHolderVisible] = useState(true);
  const [openStartPicker, setOpenStartPicker] = useState(false);
  const [isStartTimePlaceHolderVisible, setIsStartTimePlaceHolderVisible] = useState(true);
  const [openEndPicker, setOpenEndPicker] = useState(false);

  const [hourlyDateRange, setHourlyDateRange] = useState(() => {
    const storedStartTime = localStorage.getItem('hourlyDateRange');
    if (storedStartTime) {
      setDateRange(JSON.parse(storedStartTime));
    }
    return storedStartTime ? JSON.parse(storedStartTime) : [null, null];
  });

  const setHourlyDateRangeFromCalendar = (value: any) => {
    setHourlyDateRange(value);
  };

  const calculateHours = (sT: any, eT: any) => {
    if (hourlyDateRange[0] && hourlyDateRange[0] == hourlyDateRange[1] && !isEndTimePlaceHolderVisible && !isStartTimePlaceHolderVisible) {
      const startDate = new Date(moment(sT != 0 ? sT : startTime).format('YYYY-MM-DDTHH:mm'));
      const endDate = new Date(moment(eT != 0 ? eT : endTime).format('YYYY-MM-DDTHH:mm'));
      const hoursStart = startDate.getHours();
      const hoursEnd = endDate.getHours();
      const diff = hoursEnd - hoursStart;
      // const startDate = new Date(moment(sT != 0 ? sT : startTime).format('HH:mm'));
      // const endDate = new Date(moment(eT != 0 ? eT : endTime).format('HH:mm'));
      // const diff = (endDate.getTime() - startDate.getTime()) / 3600000;

      if (diff < 1) {
        toast.error(t('add_item_page.time_period_error'));
      } else if (bookDetails.hasServiceMaxRate && bookDetails.serviceMaxRate > 0 && diff > parseInt(bookDetails.serviceMaxRate, 10)) {
        toast.error(`${t('add_item_page.service_max_rate_exceeded')} ${bookDetails.serviceMaxRate} ${diff > 1 ? t('activity.summary.hours') : t('activity.summary.hour')}`);
      } else {
        setHours(diff, [hourlyDateRange[0], hourlyDateRange[1]]);
      }
    } else if (precingType !== 'HOURLY') {
      setHours(null);
    }
  };

  const [nullDefaultDates, setNullDefaultDates] = useState(true);

  const [newCalendar, setNewCalendar] = useState(() => {
    switch (precingType) {
      case 'HOURLY':
        calculateHours(0, 0);
        setHourlyDateRange([null, null]);
        return (
          <CalendarHourly
            dateRange={dateRange}
            setDateRange={setHourlyDateRangeFromCalendar}
            unavailableDates={unavailableDates}
          />
        );
      case 'DAILY':
        return (
          <CalendarRentals
            componentKey="Key_Calendar_Daily"
            dateRange={dateRange}
            setDateRange={setDateRange}
            unavailableDates={unavailableDates}
          />
        );
      case 'WEEKLY':
        return (
          <CalendarRentals
            componentKey="Key_Calendar_Weekly"
            dateRange={dateRange}
            setDateRange={setDateRange}
            unavailableDates={unavailableDates}
          />
        );
      case 'MONTHLY':
        return (
          <CalendarRentals
            componentKey="Key_Calendar_Monthly"
            dateRange={dateRange}
            setDateRange={setDateRange}
            unavailableDates={unavailableDates}
          />
        );
      default:
        return null;
    }
  });
  useEffect(() => {
    if (nullDefaultDates) {
      if (hourlyDateRange[1]) {
        setDateRange([null, null]);
      }
    } else {
      setDateRange([null, null]);
      setCalendar(precingType, [null, null]);
    }
  }, [precingType, precingTypeIfDisabled, bookDetails.idCatLndItem]);

  useEffect(() => {
    if (unavailableDates?.length > 0) {
      setCalendar(precingType, dateRange);
    }
  }, [unavailableDates]);

  const setCalendar = (prcngType: any, range: any) => {
    switch (prcngType) {
      case 'HOURLY':
        setHourlyDateRange([null, null]);
        setNewCalendar(
          <CalendarHourly
            dateRange={range}
            setDateRange={setHourlyDateRangeFromCalendar}
            unavailableDates={unavailableDates}
          />,
        );
        break;
      case 'DAILY':
        setNewCalendar(
          <CalendarRentals
            componentKey="Key_Calendar_Daily"
            dateRange={range}
            setDateRange={setDateRange}
            unavailableDates={unavailableDates}
          />,
        );
        break;
      case 'WEEKLY':
        setNewCalendar(
          <CalendarRentals
            componentKey="Key_Calendar_Weekly"
            dateRange={range}
            setDateRange={setDateRange}
            unavailableDates={unavailableDates}
          />,
        );
        break;
      case 'MONTHLY':
        setNewCalendar(
          <CalendarRentals
            componentKey="Key_Calendar_Monthly"
            dateRange={range}
            setDateRange={setDateRange}
            unavailableDates={unavailableDates}
          />,
        );
        break;
      default:
        break;
    }
  };

  if (newCalendar == null) {
    setCalendar(precingType, dateRange);
  }

  const hasErrorRange = ((precingType !== 'HOURLY') && errorAvailability && errorAvailability != '') || !dateRange[1];
  const startString = hasErrorRange
    ? '--'
    : formatDateLocal(dateRange[0] ?? currentDate);

  const endString = hasErrorRange
    ? '--'
    : formatDateLocal(dateRange[1] ?? dateRange[0] ?? currentDate);

  // const initialStartTime: any = 'HH:mm';
  // const initialStartTime = new Date(`${nextYear}-02-07T10:00`);

  const initialStartTime = new Date(`${currentYear}-${currentMonth}-${currentDay}T${nextHour}:00`);

  const initialEndTime = new Date(`${currentYear}-${currentMonth}-${currentDay}T${nextTwoHours}:00`);

  const [isValidTimePeriod, setIsValidTimePeriod] = useState<boolean>(false);
  const [startTime, setStartTime] = useState(() => {
    const storedStartTime = localStorage.getItem('startTime');
    if (storedStartTime) {
      setIsStartTimePlaceHolderVisible(false);
    }
    return storedStartTime ? moment(storedStartTime).format('YYYY-MM-DDTHH:mm') : initialStartTime;
  });
  const [endTime, setEndTime] = useState(() => {
    const storedEndTime = localStorage.getItem('endTime');
    if (storedEndTime) {
      setIsEndTimePlaceHolderVisible(false);
    }
    return storedEndTime ? moment(storedEndTime).format('YYYY-MM-DDTHH:mm') : initialEndTime;
  });
  const storageIdItem = localStorage.getItem('storageIdItem');
  const storageDateRange: any = localStorage.getItem('storageDateRange');

  useEffect(() => {
    const precing = localStorage.getItem('precingType');
    if (precing) {
      changePrcingType(precing);
    }
    updateFromHourRental(startTime);
    updateToHourRental(endTime);
    calculateHours(startTime, endTime);
  }, []);

  const [minMinHour, setMinMinHour] = useState(9);
  const [minMaxHour, setMinMaxHour] = useState(12);

  useEffect(() => {
    localStorage.setItem('startTime', moment(startTime).toISOString());
  }, [startTime]);

  useEffect(() => {
    localStorage.setItem('endTime', moment(endTime).toISOString());
  }, [endTime]);

  useEffect(() => {
    if (bookDetails.idCatLndItem == storageIdItem) {
      if (storageDateRange != null) {
        const updateDateRange: any = storageDateRange.split(',');
        const resultDate: any = [new Date(updateDateRange[0]), new Date(updateDateRange[1])];
        setDateRange(resultDate);
        if (precingType === 'HOURLY') {
          setHourlyDateRange(resultDate);
        }
        setCalendar(precingType, resultDate);
      }
    }
  }, [bookDetails]);

  useEffect(() => {
    if (bookDetails.hourlyRentalFrom && bookDetails.hourlyRentalTo) {
      if (bookDetails.hourlyRentalFrom !== bookDetails.hourlyRentalTo && bookDetails.hourlyRentalFrom < bookDetails.hourlyRentalTo && precingType === 'HOURLY') {
        const formattedRentalFrom = moment(`${currentYear}-${currentMonth}-${currentDay}T${bookDetails.hourlyRentalFrom}`).format('YYYY-MM-DDTHH:mm');
        const formattedRentalTo = moment(`${currentYear}-${currentMonth}-${currentDay}T${bookDetails.hourlyRentalTo}`).format('YYYY-MM-DDTHH:mm');
        setIsValidTimePeriod(true);
        let startTimeInit = bookDetails.hourlyRentalFrom ? formattedRentalFrom : initialStartTime;
        if (moment(hourlyDateRange[0]).isSame(moment(), 'day')) {
          if (moment(hourlyDateRange[0]).isAfter(moment(), 'hour')) {
            setMinMinHour(parseInt(bookDetails?.hourlyRentalFrom?.split(':')[0], 10));
            setMinMaxHour(parseInt(bookDetails?.hourlyRentalTo?.split(':')[0], 10));
          } else {
            setMinMinHour(moment().hour() + 1);
            const hourAfter = moment().hour() + 1;
            const hourString = hourAfter <= 9 ? `0${hourAfter}` : hourAfter;
            startTimeInit = new Date(`${currentYear}-${currentMonth}-${currentDay}T${hourString}:00`);
            setMinMaxHour(parseInt(bookDetails?.hourlyRentalTo?.split(':')[0], 10));
          }
        } else {
          setMinMinHour(parseInt(bookDetails?.hourlyRentalFrom?.split(':')[0], 10));
          setMinMaxHour(parseInt(bookDetails?.hourlyRentalTo?.split(':')[0], 10));
        }

        // if there are values of start time and end item stored in the localStorage, this will be
        // displayed in the hourly ranges
        const storedStartTimePrev = localStorage.getItem('startTime');
        const storedStartTime = (storedStartTimePrev != null && storedStartTimePrev != 'null') ? moment(storedStartTimePrev).format('YYYY-MM-DDTHH:mm') : initialStartTime;
        const storedEndTimePrev = localStorage.getItem('endTime');
        const storedEndTime = storedEndTimePrev ? moment(storedEndTimePrev).format('YYYY-MM-DDTHH:mm') : initialEndTime;
        const startTimeToShow = (storedStartTime != null && storedStartTime != 'null') ? storedStartTime : startTimeInit;

        setStartTime(startTimeToShow);
        updateFromHourRental(startTimeToShow);
        const endTimeInit = bookDetails.hourlyRentalTo ? formattedRentalTo : initialEndTime;
        const endTimeToShow = (storedEndTime != null && storedEndTime != 'null') ? storedEndTime : endTimeInit;
        setEndTime(endTimeToShow);
        updateToHourRental(endTimeToShow);
        calculateHours(startTimeToShow, endTimeToShow);

        const rangeToDisplay = hourlyDateRange[1] ? hourlyDateRange : dateRange;
        localStorage.setItem('hourlyDateRange', JSON.stringify(rangeToDisplay));
        setHourlyDateRange(rangeToDisplay);
        setDateRange(rangeToDisplay);
      } else {
        calculateHours(0, 0);
        setIsValidTimePeriod(false);
      }
    }
  }, [bookDetails.hourlyRentalFrom, hourlyDateRange]);
  const changeStartTime = (newValue: any) => {
    setStartTime(newValue);
    updateFromHourRental(moment(newValue).format('YYYY-MM-DDTHH:mm'));
    calculateHours(newValue, 0);
  };
  const changeEndTime = (newValue: any) => {
    setEndTime(newValue);
    updateToHourRental(moment(newValue).format('YYYY-MM-DDTHH:mm'));
    calculateHours(0, newValue);
  };

  const { t } = useTranslation('global');

  return (
    <>
      <SectionTitle kTitle={t('listing_detail.pricing')} />

      {bookDetails ? (
        <ItemPricing
          kHourlyPrice={!kHourlyDisabled ? `$${parseInt(bookDetails.hourlyRentalPrice, 10)}` : '--'}
          kDailyPrice={!kDailyDisabled ? `$${parseInt(bookDetails.dailyRentalPrice, 10)}` : '--'}
          kWeeklyPrice={!kWeeklyDisabled ? `$${parseInt(bookDetails.weeklyRentalPrice, 10)}` : '--'}
          kMonthlyPrice={!kMonthlyDisabled ? `$${parseInt(bookDetails.monthlyRentalPrice, 10)}` : '--'}
          kHourlyDisabled={kHourlyDisabled}
          kDailyDisabled={kDailyDisabled}
          kWeeklyDisabled={kWeeklyDisabled}
          kMonthlyDisabled={kMonthlyDisabled}
          kCurrency={bookDetails.currency}
          kOnChange={handleChange}
          kValue={precingTypeIfDisabled}
        />
      ) : null}

      <SectionTitle kTitle={t('listing_detail.availability')} />
      <Box my={3}>
        <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
          <>
            <Grid
              spacing={3}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                pl={1}
                xs={5}
                sm="auto"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
              >
                <Typography
                  variant="caption"
                  sx={{
                    color: LmiTheme.light.systemBackground.tertiary,
                  }}
                >
                  {t('listing_detail.start_date')}
                </Typography>
                <Typography
                  component="div"
                  sx={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: isDesktop ? 'body1.fontSize' : 'body2.fontSize',
                    textTransform: 'capitalize',
                  }}
                >
                  {startString}
                </Typography>
              </Grid>

              {precingType !== 'HOURLY' ? (
                <>
                  <Grid
                    item
                    xs="auto"
                    sm={2}
                    textAlign="center"
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: LmiTheme.light.systemBackground.tertiary,
                        fontWeight: 'bold',
                      }}
                    >
                      {t('listing_detail.to')}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    pr={1}
                    xs={5}
                    sm="auto"
                    textAlign="center"
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: LmiTheme.light.systemBackground.tertiary,
                      }}
                    >
                      {t('listing_detail.end_date')}
                    </Typography>
                    <Typography
                      component="div"
                      sx={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        fontSize: isDesktop ? 'body1.fontSize' : 'body2.fontSize',
                        textTransform: 'capitalize',
                      }}
                    >
                      {endString}
                    </Typography>
                  </Grid>
                  {' '}
                </>
              ) : null}
            </Grid>
          </>
          <Divider />
          <Box className="lmi--date-picker">
            <>
              {newCalendar}
            </>
          </Box>
          {
          ((precingType === 'HOURLY' && hourlyDateRange[0])) && (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid
              container
              spacing={2}
              sx={{ mt: 0, mb: 3 }}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={isMobile ? 6 : 5}>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                  <Typography fontSize="body2.fontSize" fontWeight={700} color={LmiTheme.light.systemBackground.secondary}>
                    {t('add_item_page.from')}
                  </Typography>
                  <TimePicker
                    toolbarTitle={t('global.select_time')}
                    minTime={isValidTimePeriod ? new Date(0, 0, 0, minMinHour) : new Date(0, 0, 0, 9)}
                    maxTime={isValidTimePeriod ? new Date(0, 0, 0, minMaxHour) : new Date(0, 0, 0, 17)}
                    views={['hours']}
                    open={openStartPicker}
                    onOpen={() => {
                      setOpenStartPicker(true);
                      setTimeout(() => {
                        const el = document.activeElement;
                        if (el) {
                          (el as HTMLElement).blur();
                        }
                      });
                    }}
                    onClose={() => {
                      setOpenStartPicker(false);
                    }}
                    onAccept={(value: any) => {
                      setOpenStartPicker(false);
                      if (!hours || hours == 0) {
                        calculateHours(value, 0);
                      }
                      // triggerHourCalculation();
                    }}
                    renderInput={(params: any) => {
                      if (!isStartTimePlaceHolderVisible) {
                        return (
                          <TextField
                            variant="outlined"
                            sx={{
                              bgcolor: LmiTheme.light.systemBackground.base,
                              maxWidth: '170px',
                              textAlign: 'center',
                            }}
                            {...params}
                          />
                        );
                      }
                      return (
                        <TextField
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <AccessTimeIcon />
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            bgcolor: LmiTheme.light.systemBackground.base,
                            maxWidth: '170px',
                            textAlign: 'center',
                          }}
                          placeholder="-- --"
                          onClick={() => {
                            setIsStartTimePlaceHolderVisible(false);
                            setOpenStartPicker(true);
                          }}
                          variant="outlined"
                        />
                      );
                    }}
                    value={startTime || initialStartTime}
                    onChange={(newValue: any) => {
                      changeStartTime(newValue);
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={isMobile ? 6 : 5}>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                  <Typography fontSize="body2.fontSize" fontWeight={700} color={LmiTheme.light.systemBackground.secondary}>
                    {t('add_item_page.to')}
                  </Typography>
                  <TimePicker
                    minTime={isValidTimePeriod ? new Date(0, 0, 0, minMinHour) : new Date(0, 0, 0, 9)}
                    maxTime={isValidTimePeriod ? new Date(0, 0, 0, minMaxHour) : new Date(0, 0, 0, 17)}
                    views={['hours']}
                    open={openEndPicker}
                    onOpen={() => {
                      setOpenEndPicker(true);
                      setTimeout(() => {
                        const el = document.activeElement;
                        if (el) {
                          (el as HTMLElement).blur();
                        }
                      });
                    }}
                    onClose={() => {
                      setOpenEndPicker(false);
                      const endDate = new Date(moment(endTime).format('YYYY-MM-DDTHH:mm'));
                      const InitialEndDate = new Date(moment(initialEndTime).format('YYYY-MM-DDTHH:mm'));
                      const hourEnd = endDate.getHours();
                      const hourInitial = InitialEndDate.getHours();
                      if (hourEnd == hourInitial && !endTimeChanged) {
                        calculateHours(0, endTime);
                      }
                    }}
                    onAccept={(value: any) => {
                      setOpenEndPicker(false);
                      if (!hours || hours == 0) {
                        calculateHours(0, value);
                      }
                    }}
                    renderInput={(params: any) => {
                      if (!isEndTimePlaceHolderVisible) {
                        return (
                          <TextField
                            variant="outlined"
                            sx={{
                              bgcolor: LmiTheme.light.systemBackground.base,
                              maxWidth: '170px',
                              textAlign: 'center',
                              // display: !isPlaceHolderVisible,
                            }}
                            {...params}
                          />
                        );
                      }
                      return (
                        <TextField
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <AccessTimeIcon />
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            bgcolor: LmiTheme.light.systemBackground.base,
                            maxWidth: '170px',
                            textAlign: 'center',
                          }}
                          placeholder="-- --"
                          onClick={() => {
                            setIsEndTimePlaceHolderVisible(false);
                            setOpenEndPicker(true);
                          }}
                          variant="outlined"
                        />
                      );
                    }}
                    value={endTime || initialEndTime}
                    onChange={(newValue: any) => {
                      endTimeChanged = true;
                      changeEndTime(newValue);
                    }}
                  />

                </Stack>
              </Grid>
            </Grid>
          </LocalizationProvider>
          )
          }

        </Paper>
      </Box>
      {
        hasErrorRange ? (
          <Typography sx={{ color: 'red', textAlign: 'center', mt: -2 }}>
            {t(errorAvailability)}
          </Typography>
        ) : null
      }
    </>
  );
}

Availability.defaultProps = {
  kStartDate: 'Select Start Date',
  kEndDate: 'Select End Date',
};

export default Availability;

import { LmiTheme } from '../../../../components/Common';

const ActivityDetailStyle = {
  width: '100%',
  height: '100%',
  overflow: 'auto',
  // '.lmiw-activitydetail-title': {
  //   minHeight: '22px',
  //   fontSize: '18px',
  //   fontWeight: 'bold',
  //   lineHeight: '2.72rem',
  // },
  '.lmiw-empty-detail': {
    width: '100%',
    height: '100%',
    display: 'flex',
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'center',
    color: LmiTheme.light.systemBackground.secondary,
  },

  '.lmiw-detail-header': {
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    zIndex: 1,
    width: '100%',
  },
  '.lmiw-detail-content': {
    width: '100%',
    height: '100%',
  },
  '.lmiw-detail-btn': {
    borderRadius: '10px',
    textTransform: 'capitalize',
  },
};

export default ActivityDetailStyle;

import { useContext, useState } from 'react';
import {
  Stack,
  Button,
  Grid,
  Divider,
  Typography,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ItemLocation from '../../../../../components/Common/ItemLocation/ItemLocation';
import LmiTheme from '../../../../../themes/lmi-mui-theme/lmi-theme';
import { DialogCommon } from '../../../../../components/Common';
import ModalMap from '../../../../../components/Common/Map/MapPlaces';
import bookItemContext from '../../../../../context/bookItem/bookContext';
import { MapData } from '../../../../../components/Common/Map/Map.models';
import { SummaryModel } from '../../../../../context/authentication/models/summary-Model';
import { PICKUPBYLENDER } from '../../../../../types';
import homeContext from '../../../../../context/home/homeContext';

function DeliveryByLender() {
  const {
    bookDetails: {
      idCatTypeItem, currency,
    },
    pickupLenderTemp: pickupLender,
    updateLenderPikupInfo_Temp,
    summary,
    updateLenderPikupInfo,
    pickupLender: originPickupLender,
    deliveryType,
  }: any = useContext(bookItemContext);

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const bookSummary = summary as SummaryModel;
  const {
    address, lat, long,
  } = pickupLender;

  const {
    longAddress,
    lat: user_lat,
    long: user_long,
  }: any = useContext(homeContext);

  let title = 'place';

  switch (idCatTypeItem) {
    case 0:
      title = 'item';
      break;
    case 1:
      title = 'service';
      break;
    default:
      break;
  }

  const [openChangeAddress, setOpenChangeAddress] = useState(false);

  const updateMapa = (mapaData: MapData) => {
    if (isFirstLoad) setIsFirstLoad(false);
    if (deliveryType === PICKUPBYLENDER) {
      const isNewData = Object.keys(pickupLender).length == 0 && Object.keys(originPickupLender).length == 0;
      updateLenderPikupInfo_Temp(
        {
          ...pickupLender,
          address: (mapaData.address) ? mapaData.address : longAddress,
          countryName: mapaData.countryName,
          extendedAddress: mapaData.extendedAddress,
          locality: mapaData.locality,
          postalCode: mapaData.postalCode,
          region: mapaData.region,
          streetAddress: mapaData.streetAddress,
          lat: mapaData.lat,
          long: mapaData.long,
        },
      );

      if (isNewData) {
        updateLenderPikupInfo(
          {
            ...pickupLender,
            address: (mapaData.address) ? mapaData.address : longAddress,
            countryName: mapaData.countryName,
            extendedAddress: mapaData.extendedAddress,
            locality: mapaData.locality,
            postalCode: mapaData.postalCode,
            region: mapaData.region,
            streetAddress: mapaData.streetAddress,
            lat: mapaData.lat,
            long: mapaData.long,
          },
        );
      }
    }
  };

  const saveCloseMap = () => {
    setOpenChangeAddress(!openChangeAddress);

    updateLenderPikupInfo(
      {
        ...pickupLender,
      },
    );
  };

  const cancelCloseMap = () => {
    setOpenChangeAddress(!openChangeAddress);

    updateLenderPikupInfo_Temp(
      {
        ...originPickupLender,
      },
    );
  };

  const { t } = useTranslation('global');

  return (
    <>
      <DialogCommon
        kTitle={`${t('delivery.by_lender.select_location')} ${title}`}
        kMaxWidth="md"
        kOpenDialogLists={openChangeAddress}
        kOnClose={() => { cancelCloseMap(); }}
        kOnPrimary={() => { saveCloseMap(); }}
        kOnSecondary={() => { cancelCloseMap(); }}
        kContent={
          (
            <ModalMap
              state={{
                address,
                lat: (lat && lat.length > 0) ? parseFloat(lat) : user_lat,
                long: (long && long.length > 0) ? parseFloat(long) : user_long,
                typeItem: idCatTypeItem ?? 3,
              }}
              funUpdateInfo={(mapData: MapData) => { updateMapa(mapData); }}
            />
          )
        }
      />
      <Box px={2} pt={2} pb={1}>
        <Typography sx={{ color: LmiTheme.light.systemBackground.tertiary, fontSize: 'subtitle2.fontSize' }}>
          {t('delivery.by_lender.deliver_return')}
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          py={1}
        >
          <Grid item>
            <ItemLocation
              kTextColor={LmiTheme.light.systemBackground.secondary}
              // kText={originPickupLender.address }
              kText={isFirstLoad ? longAddress : originPickupLender?.address}
            />
          </Grid>
          <Grid item>
            <Button
              variant="text"
              key="share"
              size="small"
              disableElevation
              disableRipple
              disableFocusRipple
              onClick={() => { setOpenChangeAddress(true); }}
              sx={{
                textTransform: 'none',
                color: LmiTheme.light.accent,
                fontWeight: 'bold',
                fontSize: 'body1.fontSize',
              }}
            >
              {t('delivery.by_lender.change')}
            </Button>
          </Grid>
        </Stack>
        <Divider />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          p={1}
        >
          <Grid item>
            <Typography sx={{
              color: LmiTheme.light.systemBackground.secondary,
              fontSize: 'subtitle1.fontSize',
              fontWeight: 'bold',
            }}
            >
              {t('delivery.by_lender.take_out')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ color: LmiTheme.light.systemBackground.secondary, fontSize: 'subtitle1.fontSize', fontWeight: 'bold' }}>
              {bookSummary?.self && bookSummary.self.fee > 0 ? `$${bookSummary?.self.fee} ${currency}` : bookSummary?.self?.message}
            </Typography>
          </Grid>
        </Stack>
      </Box>
      <Divider />
    </>
  );
}

export default DeliveryByLender;

import { useState, useEffect } from 'react';

const initBeforeUnLoad = (showExitPrompt: boolean): void => {
  // eslint-disable-next-line consistent-return
  window.onbeforeunload = (event) => {
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    }

    return null;
  };
};

// Hook
export default function useExitPrompt(bool: any) {
  const [showExitPrompt, setShowExitPrompt] = useState(bool);

  window.onload = () => {
    initBeforeUnLoad(showExitPrompt);
  };

  useEffect(() => {
    initBeforeUnLoad(showExitPrompt);
  }, [showExitPrompt]);

  return [showExitPrompt, setShowExitPrompt];
}

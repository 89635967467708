import {
  DELIVERY_ACTIVITY_SUCCES,
} from '../../types/index';

export default (state: any, action: any) => {
  switch (action.type) {
    case DELIVERY_ACTIVITY_SUCCES:
      // const collectShipment = action.payload.collectShipment;
      return {
        ...state,
        collectShipment: action.payload.collectShipment,
        // collectShipment: collectDelivery,
        returnShipment: action.payload.returnShipment,
        collectLatLng: '',
        returnLatLng: '',
      };

    default:
      return state;
  }
};

import { ArrowBackIosRounded } from '@mui/icons-material';
import {
  AppBar, Stack, Toolbar, Box, IconButton,
} from '@mui/material/';
import { useHistory } from 'react-router-dom';
import { LmiTheme } from '../../Common';
import { AppBarSecondaryModels } from './AppBarSecondary.models';

export default function AppBarSecondary(props: AppBarSecondaryModels) {
  const history = useHistory();
  const backClick = () => {
    if (props.kBackClick) {
      props.kBackClick();
    } else {
      history.goBack();
    }
  };

  return (
    <>
      <AppBar
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          borderBottom: `1px solid ${LmiTheme.light.systemBackground.separator}`,
          display: { xs: 'initial', md: 'none' },

        }}
      >
        <Toolbar>
          {props.kBack ? (
            <IconButton
              aria-label="back"
              onClick={() => backClick()}
              sx={{
                paddingLeft: 0,
                marginLeft: '-5px',
              }}
            >
              <ArrowBackIosRounded sx={{ color: LmiTheme.light.accent }} />
            </IconButton>
          ) : <></>}

          <Box sx={{ flexGrow: 1 }}>
            {props.kTitle}
          </Box>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="flex-end"
            spacing={1}
          >
            {props.kActions}
          </Stack>
        </Toolbar>
      </AppBar>
    </>
  );
}

AppBarSecondary.defaultProps = {
  kBack: false,
};

import React, { useState, useContext } from 'react';
import AddEditCirclesContext from '../../../../../../../context/circles/addEditCirclesContext';

const useSpecialFeeValidations = () => {
  const [values, setValues] = useState<string>('');
  const circlesContextLog = useContext(AddEditCirclesContext);
  const {
    circleInfoEdit,
    updateCircleInfo,
    updateDraftCircleInfo,
  }: any = circlesContextLog;

  const onlyNumberWithDecimals = (event: React.ChangeEvent<HTMLInputElement>) => {
    const reOnlyNumber = /^[0-9]*\.?[0-9]*$/;
    const reTwoDecimals = /^[0-9]*\.[0-9]{3}$/g;

    const { value } = event.target;
    let newValue = '';

    if (!reOnlyNumber.test(value)) return;

    if (Number(value) > 12) return;

    if (reTwoDecimals.test(value)) return;

    if (value.charAt(0) === '.') {
      newValue = `0${value}`;
      updateCircleInfo({
        ...circleInfoEdit,
        circleFee: newValue,
      });
      updateDraftCircleInfo({
        ...circleInfoEdit,
        circleFee: newValue,
      });
      setValues(newValue);
      return;
    }

    updateCircleInfo({
      ...circleInfoEdit,
      circleFee: value == '' ? '0.00' : value,
    });
    updateDraftCircleInfo({
      ...circleInfoEdit,
      circleFee: value == '' ? '0.00' : value,
    });

    setValues(value);
  };

  return {
    values,
    onlyNumberWithDecimals,
  };
};

export default useSpecialFeeValidations;

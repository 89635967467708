/* eslint-disable no-unused-vars */
import { Box, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useContext } from 'react';
import CircleDetailCard from '../CircleDetailCard/CircleDetailCard';
import MyCirclesListingContext from '../../../../context/myCirclesListing/myCirclesListingContext';

interface PropsT {
  open: boolean,
  hasCircles: boolean,
  openCircleDetail: boolean,
  openSearchCircle: boolean,
  openReportCircleModal: boolean,
  closeDialogModal: () => void,
  openAddCirclePage: () => void,
  handleOpen: (e: boolean) => void,
  handleOpenReportCircleModal: (e: boolean) => void,

}

const SecondaryBarCircles = (props: PropsT) => {
  const {
    open,
    hasCircles,
    openCircleDetail,
    openSearchCircle,
    openReportCircleModal,
    handleOpen,
    closeDialogModal,
    openAddCirclePage,
    handleOpenReportCircleModal,
  } = props;

  const {
    circles,
  }: any = useContext(MyCirclesListingContext);

  return (
    <>
      <CircleDetailCard
        kCloseDialogModal={() => closeDialogModal()}
        kOpen={open}
        kHasCircles={hasCircles}
        kOpenReportCircleModal={openReportCircleModal}
        kSetOpen={(e: boolean) => handleOpen(e)}
        kSetOpenReportCircleModal={(e: boolean) => handleOpenReportCircleModal(e)}
        kOpenCircleDetail={openCircleDetail}
      />
      {
        !openSearchCircle && !openCircleDetail && (
          <Box
            sx={{
              position: 'fixed',
              zIndex: 1,
              bottom: 30,
              left: 0,
              right: 0,
              margin: '0 auto',
              width: '100%',
              textAlign: 'center',
              justifyContent: 'center',
              display: { xs: circles?.length != 0 ? 'inherit' : 'none', md: 'none' },
            }}
          >
            <Fab
              aria-label="add listing"
              color="primary"
              onClick={() => openAddCirclePage()}
            >
              <AddIcon />
            </Fab>
          </Box>
        )
      }
    </>

  );
};

export default SecondaryBarCircles;

import {
  Box, Stack, AvatarGroup, Avatar, Typography, useTheme, useMediaQuery,
} from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AddEditCirclesContext from '../../../../../../../context/circles/addEditCirclesContext';
import ManageMembersEmpty from './ManageMembersEmpty';

export default function ManageMembers() {
  const circlesContextLog = useContext(AddEditCirclesContext);
  const {
    circleMembersEdit,
  }: any = circlesContextLog;
  const { t } = useTranslation('global');

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box>
      <Stack p={2} direction="row" justifyContent="space-between" spacing={3}>
        {
          circleMembersEdit?.length ? (
            <>
              <AvatarGroup
                total={circleMembersEdit?.length}
                max={isMobile ? 3 : 5}
                sx={{
                  '& .MuiAvatar-root': {
                    width: isMobile ? '50px' : '60px',
                    height: isMobile ? '50px' : '60px',
                    backgroundColor: '#f3f3f3',
                    color: 'black',
                    fontSize: '16px',
                  },
                }}
              >
                { circleMembersEdit?.map((member: any) => (
                  <Avatar key={member.memberPicture} alt={member.name} src={member?.memberPicture} />
                ))}
              </AvatarGroup>

              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography sx={{
                  pt: 'auto', pb: 'auto', color: '#999da2', fontSize: '16px',
                }}
                >
                  {t('circles.add_circle_page.total_members')}
                  {' '}
                  {circleMembersEdit?.length}
                </Typography>
              </Box>
            </>
          ) : (<ManageMembersEmpty />)
        }

      </Stack>
    </Box>
  );
}

/* eslint-disable no-nested-ternary */
import {
  Avatar, Box, Button, Chip, Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { DialogCommon } from '../../../../../../components/Common';
import CircleAdminsChat from './AdminsChat/CircleAdminsChat';
import MyCirclesListingContext from '../../../../../../context/myCirclesListing/myCirclesListingContext';
import { CircleDetailInfoModel } from '../../../AddCircle/AddCircle.models';
import AddEditCirclesContext from '../../../../../../context/circles/addEditCirclesContext';
import ManageItemsModal from '../../../AddCircle/Components/ManageItemsSection/ManageItems/ManageItemsModal';

export default function CircleDetailHeader() {
  const circlesContextLog = useContext(AddEditCirclesContext);

  const {
    circle,
    circles,
    details,
    images,
    requestJoinCircle,
  }: any = useContext(MyCirclesListingContext);

  const {
    reasonToDisableItem,
    setModalItemEditPage,
    pageEditItem,
    selectedItemId,
    itemActive,
    circleManageUpdateItem,
  }: any = circlesContextLog;
  const [open, setOpen] = React.useState(false);
  const idCatLndCircle = circle ? circle.idCatLndCircle : 0;
  const [showManageItems, setShowManageItems] = React.useState(false);

  useEffect(() => {
    let canManageItems = false;

    if (circle?.canUploadItem === 0 && (circle?.isMember || circle?.isAdmin || circle?.isOwner)) {
      canManageItems = true;
    } else if (circle?.canUploadItem === 1 && (circle?.isAdmin || circle?.isOwner || circle?.rentOnly)) {
      canManageItems = true;
    } else if (circle?.canUploadItem === 2 && circle?.isOwner) {
      canManageItems = true;
    }

    setShowManageItems(canManageItems);
  }, [circle]);

  const closeDialogModal = () => {
    setOpen(false);
  };

  const [isManageItemsOpen, setIsManageItemsOpen] = React.useState(false);

  const updateItem = () => {
    if (pageEditItem == 2) {
      circleManageUpdateItem(selectedItemId, itemActive, true, reasonToDisableItem, idCatLndCircle);
      toast.success(t('circles.add_circle_page.block_item'));
      setModalItemEditPage(1);
      setIsManageItemsOpen(false);
    } else {
      setModalItemEditPage(1);
      setIsManageItemsOpen(false);
    }
  };

  const closeItemsModal = () => {
    setModalItemEditPage(1);
    setIsManageItemsOpen(false);
  };

  const history = useHistory();

  const state = history.location.state as {
    kCircleId: string,
    kCircleIndex: number,
    updateSwiper: false,
    kShowDetailsMobile: false
  };

  const openEditCirclePage = (circleElement: CircleDetailInfoModel) => {
    const indexInArray = circles.findIndex((element: any) => element.idCatLndCircle === circleElement.idCatLndCircle);
    if (indexInArray > -1) {
      details(circles[indexInArray], images);
    }
    history.push({
      pathname: '/circles/edit',
      state: {
        ...state,
        circleElement: circle,
      },
    });
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [isRequestedToJoin, setIsRequestedToJoin] = React.useState(false);

  const { t } = useTranslation('global');

  return (
    <>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        mt: isMobile ? '14px' : '19px',
        mb: '29px',
      }}
      >
        <Stack flexDirection="column" justifyContent="center" alignItems="center">
          <Avatar
            src={circle?.circlePicture}
            sx={{
              width: isMobile ? '150px' : '200px',
              height: isMobile ? '150px' : '200px',
              mb: (circle?.isOwner || circle?.isAdmin) ? 0 : '30px',
            }}
          />
          <Chip
            label={circle?.isOwner ? t('circles.detail.owner').toUpperCase() : circle?.isAdmin ? t('circles.detail.admin').toUpperCase() : null}
            variant="outlined"
            sx={{
              zIndex: '2',
              display: (circle?.isOwner || circle?.isAdmin) ? 'inherit' : 'none',
              backgroundColor: 'white',
              mb: '30px',
              mt: -2,
              height: isMobile ? '30px' : '35px',
              width: isMobile ? '93px' : '109px',
              fontSize: '16px',
              fontWeight: '500',
              borderWidth: '2px',
              borderColor: (circle?.isOwner) ? '#2daa24' : (circle?.isAdmin) ? '#922bfa' : '',
              color: (circle?.isOwner) ? '#2daa24' : (circle?.isAdmin) ? '#922bfa' : '',
            }}
          />
        </Stack>
        <Box>
          <Typography sx={{
            fontSize: '18px',
            fontWeight: '700',
            mb: '30px',
          }}
          >
            {circle?.name}
          </Typography>
        </Box>
        {
          (circle?.isOwner || circle?.isAdmin || circle?.isMember) ? (
            <Stack flexDirection="row" alignItems="center" justifyContent="center" gap={1}>
              {
                (circle?.isOwner || circle?.isAdmin) && (
                  <>
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{
                        height: '56px',
                        width: '94%',
                        borderRadius: '10px',
                        position: 'fixed',
                        bottom: '10px',
                        left: '10px',
                        zIndex: 10,
                        display: isMobile ? 'inherit' : 'none',
                      }}
                      onClick={() => openEditCirclePage(idCatLndCircle)}
                    >
                      <Typography sx={{
                        fontSize: '18px',
                        fontWeight: '600',
                        color: 'white',
                        textTransform: 'none',
                      }}
                      >
                        {t('circles.detail.edit')}
                      </Typography>
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      sx={{
                        height: '56px',
                        width: '150px',
                        mr: '16px',
                        borderRadius: '10px',
                        display: isMobile ? 'none' : 'inherit',
                      }}
                      onClick={() => openEditCirclePage(idCatLndCircle)}
                    >
                      <Typography sx={{
                        fontSize: '18px',
                        fontWeight: '600',
                        color: 'white',
                        textTransform: 'none',
                      }}
                      >
                        {t('circles.detail.edit')}
                      </Typography>
                    </Button>
                  </>
                )
              }

              {
                (showManageItems) && (
                  <Stack flexDirection="row" alignItems="center">
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{
                        height: '56px',
                        width: '150px',
                        mr: '16px',
                        borderRadius: '10px',
                      }}
                      onClick={() => setIsManageItemsOpen(true)}
                    >
                      <Typography sx={{
                        fontSize: '18px',
                        fontWeight: '600',
                        color: 'white',
                        textTransform: 'none',
                      }}
                      >
                        {t('circle_detail.manage_my_items')}
                      </Typography>
                    </Button>
                  </Stack>
                )
              }
            </Stack>
          ) : (
            !circle?.isMember
            && (
              <Stack flexDirection="column" justifyContent="center" alignItems="center">
                <Button
                  disabled={isRequestedToJoin || circle?.pendingApproval}
                  color="primary"
                  variant="contained"
                  sx={{
                    height: '56px',
                    width: '150px',
                    mr: '16px',
                    mb: '16px',
                    borderRadius: '10px',
                    display: isMobile ? 'none' : 'inherit',
                  }}
                  onClick={() => {
                    requestJoinCircle(idCatLndCircle);
                    setIsRequestedToJoin(true);
                  }}
                >
                  <Typography sx={{
                    fontSize: '18px',
                    fontWeight: '600',
                    color: 'white',
                    textTransform: 'none',
                  }}
                  >
                    {
                      !isRequestedToJoin && !circle?.pendingApproval ? (
                        t('circles.detail.join')
                      ) : (
                        t('circles.detail.requested')
                      )
                    }
                  </Typography>
                </Button>
                <Button
                  disabled={isRequestedToJoin || circle?.pendingApproval}
                  color="primary"
                  variant="contained"
                  sx={{
                    height: '56px',
                    width: '94%',
                    borderRadius: '10px',
                    position: 'fixed',
                    bottom: '10px',
                    left: '10px',
                    zIndex: 10,
                    display: isMobile ? 'inherit' : 'none',
                  }}
                  onClick={() => setIsRequestedToJoin(true)}
                >
                  <Typography sx={{
                    fontSize: '18px',
                    fontWeight: '600',
                    color: 'white',
                    textTransform: 'none',
                  }}
                  >
                    {
                      !isRequestedToJoin && !circle?.pendingApproval ? (
                        t('circles.detail.join')
                      ) : (
                        t('circles.detail.requested')
                      )
                    }
                  </Typography>
                </Button>
                <Typography sx={{
                  display: isMobile ? 'none' : 'inherit',
                  color: '#999da2',
                }}
                >
                  {t('circles.detail.join_circle')}
                </Typography>
              </Stack>
            )
          )
        }
      </Box>
      <DialogCommon
        kTitle={circle?.name}
        kMaxWidth="sm"
        kBorderRadius={isMobile ? 0 : '10px'}
        kSlideTransition={isMobile}
        kOpenDialogLists={open}
        kOnClose={closeDialogModal}
        kDisableActions
        kContent={(
          <CircleAdminsChat />
        )}
      />
      <DialogCommon
        kTitle={t('circles.add_circle_page.manage_items')}
        kMaxWidth="sm"
        kBorderRadius="10px"
        kDisableContentPadding
        kSlideTransition={isMobile}
        kOpenDialogLists={isManageItemsOpen}
        kOnClose={closeItemsModal}
        kOnPrimary={updateItem}
        kOnSecondary={closeItemsModal}
        kAlignBtn="center"
        kKeepBtnSize
        kDisableBtnSave={(pageEditItem == 2 && !!(reasonToDisableItem?.length < 5 || reasonToDisableItem == undefined))}
        kContent={
          (
            <ManageItemsModal />
          )
        }
      />
    </>
  );
}

import { Button, IconButton } from '@mui/material';
import LmiTheme from '../../../themes/lmi-mui-theme/lmi-theme';
import { IconLinkButtonModels } from './IconLinkButton.models';

function IconLinkButton(props: IconLinkButtonModels) {
  return (
    <>
      {!props.kHideText
        ? (
          <Button
            variant="text"
            key="share"
            size={props.kButtonSize}
            startIcon={props.kStartIcon}
            endIcon={props.kEndIcon}
            disableElevation
            disableRipple
            disableFocusRipple
            onClick={(e) => {
              e.preventDefault();
              props.kOnClick(e);
            }}
            sx={{
              textTransform: 'none',
              color: props.kTextColor,
              fontWeight: props.kFontWeight,
              fontSize: props.kFontSize,
              borderRadius: '10px',
            }}
          >
            {props.kText}
          </Button>
        )
        : (
          <IconButton
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              props.kOnClick(e);
            }}
            sx={{
              color: props.kTextColor,
              border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
              width: '42px',
            }}
          >
            {props.kStartIcon}
          </IconButton>
        )}
    </>
  );
}

IconLinkButton.defaultProps = {
  kTextColor: LmiTheme.light.accent,
  kButtonSize: 'large',
  kFontWeight: 'bold',
  kFontSize: 'body1.fontSize',
  kOnClick: undefined,
  kHideText: false,
};

export default IconLinkButton;

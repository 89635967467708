/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import {
  Stack, Avatar, Typography, ListItem, Box, ListItemButton, Badge,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CircleIcon from '@mui/icons-material/Circle';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { useEffect, useState, useContext } from 'react';
import { ActivityItemModel } from './ActivityItem.models';
import ActivityItemStyle from './ActivityItem.style';
import { LmiTheme } from '../../../../../../components/Common';
import SocketContext from '../../../../../../context/socket/socketContext';

export default function ListingItem(props: ActivityItemModel) {
  const [cardType, setCardType] = useState('');
  const [cardName, setCardName] = useState('');
  const [readNewMessage, setReadNewMessage] = useState<number[]>([]);
  const socketContext = useContext(SocketContext);
  const {
    ws,
    notifications,
    newNotification,
    newMessage,
  }: any = socketContext;

  useEffect(() => {
    generateCardName(props?.kActivityItem);
    generateCardType(props?.kActivityItem);
  }, []);

  useEffect(() => {
    if (props.kIsActiveChat) {
      if (notifications) {
        setReadNewMessage(JSON.stringify(notifications) != '{}' ? notifications : []);
      }
      const handleNewNotification = () => {
        if (newNotification != '' && newNotification != null) {
          if (readNewMessage.length > 0 && readNewMessage[0] != null) {
            setReadNewMessage((current: any) => [...current, newNotification]);
          } else setReadNewMessage([newNotification]);
        }
      };
      if (newMessage) handleNewNotification();
    }
  }, [props.kIsActiveChat, newMessage, newNotification, notifications]);

  const { t } = useTranslation('global');

  function generateCardType(item: any) {
    if (item) {
      switch (item.notifType) {
        case 'CIRCLES':
          switch (item.notifActionType) {
            case 'joincirclerequest':
              setCardType(t('activity.list.request'));
              break;
            case 'joincirclerequest_denied':
              setCardType(t('activity.list.denied'));
              break;
            case 'joincirclerequest_approved':
              setCardType(t('activity.list.approved'));
              break;
            case 'circle_promoted_owner':
            case 'circle_promoted_admin':
            case 'circle_promoted_user_admin':
              setCardType(t('activity.list.promoted'));
              break;
            case 'circle_member_blcked':
            case 'circle_member_admin_blcked':
              setCardType(t('activity.list.blocked'));
              break;
            case 'circle_downgrade_admin':
            case 'circle_downgrade_owner':
              setCardType(t('activity.list.demoted'));
              break;
            case 'circle_item_blocked':
              setCardType(t('activity.list.item_blocked'));
              break;
            case 'circle_item_unblocked':
              setCardType(t('activity.list.item_unblocked'));
              break;
            case 'circle_owner_earning':
              setCardType(item.name);
              break;
            case 'circleInvitation':
              setCardType(t('activity.list.circleInvitation'));
              break;
            default:
              break;
          }
          break;
        case 'RENT':
          setCardType(item.isBuy ? t('activity.list.buy_rent') : `${t('activity.list.rent_singular')}  ·`);
          break;
        case 'LEND':
          setCardType(item.isBuy ? t('activity.list.buy_lend') : `${t('activity.list.lend_singular')}  ·`);
          break;
        case 'IDV':
          setCardType(t('activity.list.id_verification'));
          break;
        default:
          break;
      }
    }
    return cardType;
  }

  function generateCardName(item: any) {
    let cardNamePrice;
    if (item) {
      switch (item.notifActionType) {
        case 'joincirclerequest':
        case 'circle_promoted_owner':
        case 'circle_promoted_admin':
        case 'circle_member_blcked':
        case 'circle_member_admin_blcked':
          cardNamePrice = visibleToOthersName(item.lenderName.trim());
          break;
        case 'joincirclerequest_denied':
        case 'joincirclerequest_approved':
          cardNamePrice = visibleToOthersName(item.userNameDeniedApproved.trim());
          break;
        default:
          if (item.isRent || item.isBuy) {
            cardNamePrice = item.notifType === 'RENT'
              ? visibleToOthersName(item.lenderName.trim())
              : visibleToOthersName(item.renterName.trim());
          } else if (item.notifType.trim() === 'IDV') {
            cardNamePrice = '';
          } else {
            cardNamePrice = item.notifType === 'RENT'
              ? visibleToOthersName(item.lenderName.trim())
              : visibleToOthersName(item.renterName.trim());
          }
          break;
      }
      setCardName(cardNamePrice.toString());
    }
    return cardNamePrice;
  }

  function visibleToOthersName(userFullname: String) {
    let _visibleToOthersName: String;
    let names: any = [];
    try {
      names = userFullname.toString().split(' ');

      _visibleToOthersName = `${capitalizeFirstLetter(names[0])} ${names[1].substring(0, 1).toUpperCase()}.`;
    } catch (err: any) {
      if (err.message == t('activity.list.value_not_range')) {
        try {
          _visibleToOthersName = `${capitalizeFirstLetter(names[0])} ${names[2].substring(0, 1).toUpperCase()}.`;
        } catch (error: any) {
          _visibleToOthersName = userFullname;
        }
      } else {
        _visibleToOthersName = userFullname;
      }
    }

    return _visibleToOthersName;
  }

  function capitalizeFirstLetter(word: String) {
    return `${word.substring(0, 1).toUpperCase()}${word.substring(1).toLowerCase()}`;
  }

  const splitDay = props?.kActivityItem?.fromDate.split(' ')[0];
  const splitMonth = props?.kActivityItem?.fromDate.split(' ')[1];
  const splitYear = props?.kActivityItem?.fromDate.split(' ')[2];

  const splitNotifDay = props?.kActivityItem?.notifDate.split(' ')[0];
  const splitNotifMonth = props?.kActivityItem?.notifDate.split(' ')[1];
  const splitNotifYear = props?.kActivityItem?.notifDate.split(' ')[2];

  const splitFromDay = props?.kActivityItem?.fromDate.split(' ')[0];
  const splitFromMonth = props?.kActivityItem?.fromDate.split(' ')[1];
  const splitFromYear = props?.kActivityItem?.fromDate.split(' ')[2];

  const splitToDay = props?.kActivityItem?.toDate.split(' ')[0];
  const splitToMonth = props?.kActivityItem?.toDate.split(' ')[1];
  const splitToYear = props?.kActivityItem?.toDate.split(' ')[2];

  return (
    <ListItem
      alignItems="flex-start"
      sx={ActivityItemStyle}
    >
      <ListItemButton
        onClick={() => props.kOpenDetail('id')}
        sx={{
          maxHeight: '150px',
          cursor: 'pointer',
          borderRadius: '10px',
          backgroundColor: props.kIsItemActive ? LmiTheme.light.accentActiveBackground : '',
        }}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          className="lmiw-listing-item"
        >
          <Avatar
            src={props?.kActivityItem?.notifAttachment}
            className="lmiw-item-image"
            variant={props.kItemType === 'circle' ? 'circular' : 'rounded'}
          />
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="flex-start"
            className="lmiw-item-content"
            pl={2}
            pr={2}
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <Box className="lmiw-content-info">
              <CardTitle
                item={props?.kActivityItem}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
              {props?.kActivityItem?.notifActionType == 'circle_owner_earning'
                ? (
                  <Typography component="span" className="lmiw-content-desc">
                    {`${splitFromDay} ${t(`months.${splitFromMonth}`)} ${splitFromYear}-${splitToDay} ${t(`months.${splitToMonth}`)} ${splitToYear}`}
                  </Typography>
                )
                : (
                  <Typography component="span" className="lmiw-content-desc">
                    {props.kDesc}
                  </Typography>
                )}
            </Box>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              width="100%"
            >
              <Box className="lmiw-content-status">
                <Typography component="span" className="lmiw-status-type">
                  {cardType}
                </Typography>
                <Typography component="span" className="lmiw-status-username">
                  {cardName}
                </Typography>
              </Box>
              {props?.kActivityItem?.isRent ? (
                <Typography component="span" className="lmiw-content-time">
                  {splitDay}
                  {' '}
                  {t(`months.${splitMonth}`)}
                  {' '}
                  {splitYear}
                </Typography>
              ) : (
                <Typography component="span" className="lmiw-content-time">
                  {splitNotifDay}
                  {' '}
                  {t(`months.${splitNotifMonth}`)}
                  {' '}
                  {splitNotifYear}
                </Typography>
              )}
            </Stack>

          </Stack>
          {props?.kItemType == 'RENT' || props?.kItemType == 'LEND' ? (
            <ChatIconOpen
              item={props}
              newMessage={readNewMessage}
              readNewMessage={(data: any) => {
                if (readNewMessage.includes(data)) {
                  ws.send(JSON.stringify({
                    action: 'remove-notification',
                    rentId: data,
                  }));
                  setReadNewMessage((id) => id.filter((read) => read !== data));
                }
              }}
            />
          ) : null}
        </Stack>
      </ListItemButton>
    </ListItem>
  );
}

function CardTitle({ item }: any) {
  const { t } = useTranslation('global');
  let cardStatusText;

  switch (item?.notifActionType) {
    case 'new_rental':
    case 'new_buy':
    case 'approve':
    case 'approved':
      if (!item?.isLenderApproved) {
        cardStatusText = item?.notifType == 'RENT'
          ? t('activity_item.pending_lender_approval')
          : t('activity_item.pending_your_approval');
      } else if (item?.exchangeLat.length > 0) {
        cardStatusText = item?.notifType == 'RENT'
          ? t('activity_item.approved_lender')
          : t('activity_item.approved_you');
      }
      break;
    case 'rejected':
      cardStatusText = item?.notifType == 'RENT' ? t('activity_item.rejected_lender') : t('activity_item.rejected_you');
      break;
    case 'pickup':
      cardStatusText = t('activity_item.scheduled_pickup');
      break;
    case 'dropoff':
      cardStatusText = t('activity_item.scheduled_dropoff');
      break;
    case 'payment_processed':
      cardStatusText = item?.notifType == 'RENT'
        ? t('activity_item.your_payment_processed')
        : t('activity_item.payment_processed');
      break;
    case 'review':
      cardStatusText = item?.isReviewed ? t('activity_item.completed') : t('activity_item.review_experience');
      break;
    case 'cancelled':
      if (item?.isCancelledRenter || item?.isCancelledLender) {
        cardStatusText = item?.notifType == 'RENT'
          ? item?.isCancelledRenter
            ? t('activity_item.canceled_you')
            : t('activity_item.canceled_lender')
          : item?.isCancelledLender
            ? t('activity_item.canceled_you')
            : t('activity_item.canceled_renter');
      } else {
        cardStatusText = t('activity_item.reservation_canceled');
      }
      break;
    case 'approve_buy':
      cardStatusText = item?.notifType == 'RENT'
        ? t('activity_item.pending_lender_approval')
        : t('activity_item.pending_your_approval');
      break;
    case 'verified_failed':
      cardStatusText = t('activity_item.id_failed');
      break;
    case 'verified':
    case 'verified_ok':
      cardStatusText = t('activity_item.id_success');
      break;
    case 'verifypending':
      cardStatusText = t('activity_item.id_being_verified');
      break;
    case 'joincirclerequest':
      if (item?.notifType == 'CIRCLES') {
        cardStatusText = t('activity_item.request_join_circle');
      }
      break;
    case 'circleInvitation':
      if (item?.notifType == 'CIRCLES') {
        cardStatusText = t('activity_item.circleInvitation');
      }
      break;
    case 'joincirclerequest_approved':
      if (item?.notifType == 'CIRCLES') {
        cardStatusText = t('activity_item.join_approved');
      }
      break;
    case 'joincirclerequest_denied':
      if (item?.notifType == 'CIRCLES') {
        cardStatusText = t('activity_item.join_denied');
      }
      break;
    case 'circle_promoted_owner':
      if (item?.notifType == 'CIRCLES') {
        cardStatusText = t('activity_item.promoted_owner');
      }
      break;
    case 'circle_promoted_admin':
      if (item?.notifType == 'CIRCLES') {
        cardStatusText = t('activity_item.promoted_admin');
      }
      break;
    case 'circle_member_blcked':
      if (item?.notifType == 'CIRCLES') {
        cardStatusText = t('activity_item.blocked_circle');
      }
      break;
    case 'circle_promoted_user_admin':
      if (item?.notifType == 'CIRCLES') {
        cardStatusText = t('activity_item.member_admin');
      }
      break;
    case 'circle_member_admin_blcked':
      if (item?.notifType == 'CIRCLES') {
        cardStatusText = t('activity_item.member_blocked_circle');
      }
      break;
    case 'circle_downgrade_admin':
      if (item?.notifType == 'CIRCLES') {
        cardStatusText = t('activity_item.no_longer_admin');
      }
      break;
    case 'circle_downgrade_owner':
      if (item?.notifType == 'CIRCLES') {
        cardStatusText = t('activity_item.no_longer_owner');
      }
      break;
    case 'circle_item_blocked':
      if (item?.notifType == 'CIRCLES') {
        cardStatusText = t('activity_item.item_blocked');
      }
      break;
    case 'circle_item_unblocked':
      if (item?.notifType == 'CIRCLES') {
        cardStatusText = t('activity.status.item_unblocked');
      }
      break;
    case 'circle_owner_earning':
      if (item?.notifType == 'CIRCLES') {
        cardStatusText = item?.dscription;
      }
      break;
    case 'driver_assigned':
    case 'assigned':
      cardStatusText = t('activity_item.driver_assigned');
      break;
    case 'en_route_to_pickup':
      cardStatusText = t('activity_item.driver_headed_pickup');
      break;
    case 'at_pickup':
      cardStatusText = t('activity_item.driver_at_pickup');
      break;
    case 'pickup_confirmed':
      cardStatusText = t('activity_item.driver_picked_shipment');
      break;
    case 'en_route_to_delivery':
      cardStatusText = t('activity_item.driver_headed_delivery');
      break;
    case 'at_delivery':
      cardStatusText = t('activity_item.driver_at_delivery');
      break;
    case 'delivery_confirmed':
    case 'delivery_details_added':
      cardStatusText = t('activity_item.driver_delivered');
      break;
    case 'delivery_attempted':
      cardStatusText = t('activity_item.driver_not_able');
      break;
    case 'returned':
      cardStatusText = t('activity_item.underiverable_shipment');
      break;
    case 'canceled':
      cardStatusText = t('activity_item.shipment_canceled');
      break;
    case 'driver_approaching_pickup':
      cardStatusText = t('activity_item.driver_near_pickup');
      break;
    case 'driver_approaching_delivery':
      cardStatusText = t('activity_item.driver_near_delivery');
      break;
    case 'scheduled':
      cardStatusText = t('activity_item.shipment_scheduled');
      break;
    case 'en_route':
      cardStatusText = t('activity_item.item_pickup');
      break;
    case 'delivered':
      cardStatusText = t('activity_item.item_delivered');
      break;
    case 'attempted':
      cardStatusText = t('activity_item.shipment_unsuccessful');
      break;
    case 'expired':
      cardStatusText = t('activity_item.delivery_passed');
      break;
    case 'reminder':
      cardStatusText = t('activity_item.reminder');
      break;
    case 'extend_reservation_pending':
      cardStatusText = t('activity_item.pendingExtendReservation');
      break;
    case 'extend_reservation_approved':
      cardStatusText = t('activity_item.approvedExtendReservation');
      break;
    case 'extend_reservation_denied':
      cardStatusText = t('activity_item.deniedExtendReservation');
      break;
    case 'extend_reservation_canceled':
      cardStatusText = t('activity_item.canceledExtendReservation');
      break;
    default:
      cardStatusText = 'Unknown';
  }

  return (
    <Typography component="span" className="lmiw-content-title">
      {cardStatusText}
    </Typography>
  );
}

function ChatIconOpen(props: {item: any, newMessage: any, readNewMessage(data: any): void}) {
  const { item, newMessage } = props;
  // let canOpenChat = true;

  // if (props?.kActivityItem?.hasStripeAccount
  //   && props?.kActivityItem?.renterVerified
  //   && props?.kActivityItem?.lenderVerified
  //   && !props?.kActivityItem?.isCancelledRenter
  //   && !props?.kActivityItem?.isCancelledLender
  //   && props?.kActivityItem?.isLenderApproved) {
  //   if (props?.kActivityItem?.notifActionType == 'cancelled') {
  //     canOpenChat = false;
  //   } else if (props?.kActivityItem?.notifActionType == 'review') {
  //     // if (hasMessages) { canOpenChat = true; } else { canOpenChat = false; }
  //   } else {
  //     canOpenChat = true;
  //   }
  // }

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="flex-end"
      minHeight="76px"
      className="lmiw-item-actions"
    >
      <CircleIcon sx={{
        width: '12px',
        height: '12px',
        color: item.kIsUnread ? LmiTheme.light.secondaryColor.orange : LmiTheme.light.systemBackground.separator,
      }}
      />
      <Badge
        overlap="circular"
        badgeContent={newMessage.includes(item?.kActivityItem?.idCatLndRntItem) ? ' ' : 0}
        variant="dot"
        onClick={() => {
          if (item.kIsActiveChat) {
            props.readNewMessage(item?.kActivityItem?.idCatLndRntItem);
            item.kOpenChat();
          }
        }}
        sx={{
          cursor: item?.kIsActiveChat ? 'pointer' : '',
          '.MuiBadge-badge': {
            backgroundColor: item.kIsActiveChat ? LmiTheme.light.secondaryColor.orange : LmiTheme.light.systemBackground.separator,
          },
        }}
      >
        <ChatBubbleOutlineIcon sx={{
          width: '16px',
          height: '16px',
          color: item?.kIsActiveChat ? LmiTheme.light.accent : LmiTheme.light.systemBackground.separator,
        }}
        />
      </Badge>

    </Stack>
  );
}

ListingItem.defaultProps = {
  kItemType: 'explore',
  kIsUnread: false,
  kIsActiveChat: false,
  kNewMessages: false,
  kUserName: 'userName',
  kIsActive: false,
};

import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CardStripe, LmiTheme } from '../../../page/BackOffice/ProfilePrivate/Components';
import paymentMethodsContext from '../../../context/paymentMethods/paymentMethodsContext';
import { TextCardNumber, TextCVCMask, TextExpireDate } from '../../../utils/inputsFormats';

function PaymentMethodAddDialog(props: any) {
  const pPaymentMethodsContext = useContext(paymentMethodsContext);
  const limitFullName = 45;

  const {
    updateNewPaymentInfo,
    newPaymentMethodInfo,
    checkPaymentValidate,
    updatePaymentValidationStatus,
    totalValidCard,
  }: any = pPaymentMethodsContext;

  const [isFullNameValid, setIsFullNameValid] = useState(true);
  const [isCardNumberValid, setIsCardNumberValid] = useState(true);
  const [isCVCValid, setIsCVCValid] = useState(true);
  const [isExpDateValid, setIsExpDateValid] = useState(true);

  useEffect(() => {
    if (totalValidCard < 5) {
      let isEverythingOk = true;
      if (checkPaymentValidate) {
        if (newPaymentMethodInfo.cardName.length == 0) {
          isEverythingOk = false;
          setIsFullNameValid(isEverythingOk);
        } else {
          setIsFullNameValid(true);
        }
        if (newPaymentMethodInfo.cardNumber.replace(/\s/g, '').length < 15) {
          isEverythingOk = false;
          setIsCardNumberValid(isEverythingOk);
        } else {
          setIsCardNumberValid(true);
        }
        if (newPaymentMethodInfo.cvc.length < 3) {
          isEverythingOk = false;
          setIsCVCValid(isEverythingOk);
        } else {
          setIsCVCValid(true);
        }
        if (newPaymentMethodInfo.expiryMonth.length < 5) {
          isEverythingOk = false;
          setIsExpDateValid(isEverythingOk);
        } else {
          setIsExpDateValid(true);
        }
        updatePaymentValidationStatus(isEverythingOk);
      }

      if (props.KSetValid) {
        props.KSetValid(isEverythingOk);
      }
    } else {
      toast.info(t('payment_method.max_methods_txt'));
      // toast.error('You cant have more than 3 cards');
    }
  }, [checkPaymentValidate]);

  const handleInputchange = ({ target }: any) => {
    if (target.name == 'cardNumber') {
      updateNewPaymentInfo({
        ...newPaymentMethodInfo,
        [target.name]: target.value,
        cardNumberFormated: target.value.replace(/\s/g, ''),
      });
    } else if (target.name == 'cardName') {
      validateFullName(target.value);
      updateNewPaymentInfo({
        ...newPaymentMethodInfo,
        [target.name]: target.value,
      });
    } else if (target.name == 'expiryMonth') {
      const splitExpire = newPaymentMethodInfo.expiryMonth.split('/');
      // const month = splitExpire[0];
      const year = splitExpire[1];
      updateNewPaymentInfo({
        ...newPaymentMethodInfo,
        expiryMonth: target.value,
        expiryYear: year,
      });
    } else {
      updateNewPaymentInfo({
        ...newPaymentMethodInfo,
        [target.name]: target.value,
      });
    }
  };

  const validateFullName = (fullName: string) => {
    const isValid = (/[a-zA-Z]{1,}\s[a-zA-Z]{1,}‘?-?[a-zA-Z]{1,}\s?([a-zA-Z]{1,})?/).test(fullName);
    if (fullName.length == 0) {
      if (props.KSetValid) { props.KSetValid(false); }

      setIsFullNameValid(false);
    } else {
      const haveNumber = fullName.match(/[0-9]/);
      const haveSpecial = fullName.match(/[^\w\\\sñ´óáíéú]/);
      if (haveNumber || haveSpecial) {
        if (props.KSetValid) { props.KSetValid(false); }
        setIsFullNameValid(false);
      } else {
        if (props.KSetValid) { props.KSetValid(isValid); }
        setIsFullNameValid(isValid);
      }
    }
  };

  const { t } = useTranslation('global');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Typography py={2} component="span" textAlign="center" color={LmiTheme.light.systemBackground.tertiary}>{t('payment_method.payment_secure')}</Typography>
        <CardStripe width={56} height={56} style={{ marginRight: 30 }} />
      </Stack>
      <Box sx={{ p: 1, color: LmiTheme.light.systemBackground.tertiary }}>
        {t('payment_method.add_info')}
      </Box>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            pb={1}
          >
            <Box>
              <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
              <Typography component="span" fontWeight={500}>{t('payment_method.full_name')}</Typography>
            </Box>
            <Typography component="span" color={LmiTheme.light.systemBackground.tertiary}>
              {`${newPaymentMethodInfo?.cardName.length}/${limitFullName}`}
            </Typography>
          </Stack>
          <TextField
            fullWidth
            id="outlined-basic"
            variant="outlined"
            type="text"
            name="cardName"
            placeholder={t('payment_method.full_name')}
            value={newPaymentMethodInfo?.cardName}
            error={!isFullNameValid}
            helperText={!isFullNameValid && `${t('payment_method.invalid_name')}`}
            onChange={handleInputchange}
            sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
            inputProps={{
              maxLength: limitFullName,
            }}
            FormHelperTextProps={{
              style: {
                backgroundColor: LmiTheme.light.systemBackground.elevation,
                margin: 0,
                paddingTop: '3px',
                paddingRight: '14px',
                paddingBottom: '0',
                paddingLeft: '14px',
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box pb={1}>
            <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
            <Typography component="span" fontWeight={500}>{t('payment_method.card_number')}</Typography>
          </Box>

          <TextField
            fullWidth
            id="outlined-basic"
            variant="outlined"
            type="text"
            name="cardNumber"
            placeholder={t('payment_method.card_number')}
            value={newPaymentMethodInfo?.cardNumber}
            error={!isCardNumberValid}
            helperText={!isCardNumberValid && `${t('payment_method.invalid_card')}`}
            onChange={handleInputchange}
            sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              inputComponent: TextCardNumber as any,
            }}
            FormHelperTextProps={{
              style: {
                backgroundColor: LmiTheme.light.systemBackground.elevation,
                margin: 0,
                paddingTop: '3px',
                paddingRight: '14px',
                paddingBottom: '0',
                paddingLeft: '14px',
              },
            }}
          />
        </Grid>
        <Grid item xs={isMobile ? 6 : 3}>
          <Box pb={1}>
            <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
            <Typography component="span" fontWeight={500}>{t('payment_method.expiration')}</Typography>
          </Box>
          <TextField
            fullWidth
            id="outlined-basic"
            variant="outlined"
            type="text"
            placeholder="MM/YY"
            name="expiryMonth"
            value={newPaymentMethodInfo?.expiryMonth}
            onChange={handleInputchange}
            sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
            error={!isExpDateValid}
            helperText={!isExpDateValid && `${t('payment_method.invalid_date')}`}
            // inputProps={{
            //   inputMode: 'numeric',
            //   maxLength: 4,
            //   style: { textAlign: 'center' },
            // }}
            inputProps={{
              maxLength: 5,
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              inputComponent: TextExpireDate as any,
            }}
            FormHelperTextProps={{
              style: {
                backgroundColor: LmiTheme.light.systemBackground.elevation,
                margin: 0,
                paddingTop: '3px',
                paddingRight: '14px',
                paddingBottom: '0',
                paddingLeft: '14px',
              },
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Box pb={1}>
            <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
            <Typography component="span" fontWeight={500}>CVV</Typography>
          </Box>
          <TextField
            fullWidth
            id="outlined-basic"
            variant="outlined"
            type="text"
            placeholder="•••"
            name="cvc"
            value={newPaymentMethodInfo?.cvc}
            onChange={handleInputchange}
            sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
            error={!isCVCValid}
            helperText={!isCVCValid && `${t('payment_method.invalid_cvv')}`}
            // inputProps={{
            //   inputMode: 'numeric',
            //   maxLength: 4,
            //   style: { textAlign: 'center' },
            // }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              inputComponent: TextCVCMask as any,
            }}
            FormHelperTextProps={{
              style: {
                backgroundColor: LmiTheme.light.systemBackground.elevation,
                margin: 0,
                paddingTop: '3px',
                paddingRight: '14px',
                paddingBottom: '0',
                paddingLeft: '14px',
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default PaymentMethodAddDialog;

import { useContext, useEffect, useState } from 'react';
import {
  Avatar,
  Box, Divider,
  FormControlLabel,
  Stack,
  Chip,
  Typography,
  useMediaQuery,
  useTheme,
  styled,
} from '@mui/material';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import AddEditCirclesContext from '../../../../../../context/circles/addEditCirclesContext';
import addEditItemContext from '../../../../../../context/addEditItem/addEditItemContext';
import DialogCommon from '../../../../../../components/Common/DialogCommon/DialogCommon';
import { UpdateLndItemStatuses } from '../../../../../../data/listing';

interface PropsT {
  circlesInfo: Array<{}>,
  // eslint-disable-next-line react/require-default-props
  isMember?: boolean,
}

function ManageCirclesSectionModal(props: PropsT) {
  const { circlesInfo, isMember } = props;
  const [itemsInCircles, setItemsInCircles] = useState<Array<{}>>(circlesInfo);
  const [isManageItemsOpen, setIsManageItemsOpen] = useState(false);
  const [idCircle, setIdCircle] = useState(0);
  const circlesContextLog = useContext(AddEditCirclesContext);
  const addEditContextLog = useContext(addEditItemContext);
  const {
    itemManageUpdateCircles,
    existsItemsInCircles,
    circleItemsEdit,
  }: any = circlesContextLog;
  const {
    itemInfo,
    updateItemInfo,
  }: any = addEditContextLog;

  const IOSSwitch = styled((propsSwitch: SwitchProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...propsSwitch} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#038DE2' : '#038DE2',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation('global');

  const isOwner = (item: any) => {
    if (item?.isOwner) return t('circles.detail.owner').toUpperCase();
    if (item?.isAdmin) return t('circles.detail.admin').toUpperCase();
    return null;
  };

  const handleIsOwnerColor = (item: any) => {
    if (item?.isOwner) return '#2daa24';
    if (item?.isAdmin) return '#922bfa';
    return null;
  };

  const getInfo = async () => {
    const res = await existsItemsInCircles(itemInfo?.idCatLndItem);
    const filterArray = circlesInfo;
    const newFilterArray: Array<{}> = [];

    filterArray.map((item: any) => newFilterArray.push({ ...item, isActiveCircles: res.some((element: any) => element?.idCatLndCircle === item?.idCatLndCircle) }));
    setItemsInCircles(newFilterArray);
  };

  const closeItemsModal = () => {
    setIsManageItemsOpen(false);
  };

  useEffect(() => {
    (async function fetchInfo() {
      await getInfo();
    }());
  }, [circleItemsEdit, circlesInfo]);

  const handleChange = (e: any, idCircleModal: number) => {
    const { checked } = e.target;
    if (checked && (itemInfo.itemStatus == 3 || itemInfo.itemStatus == 0)) {
      setIdCircle(idCircleModal);
      setIsManageItemsOpen(true);
    } else {
      itemManageUpdateCircles(itemInfo?.idCatLndItem, checked, true, '', idCircleModal);
    }
  };

  const updateStatusItems = () => {
    const updateItem = {
      ...itemInfo,
      circlesAndExplore: true,
      circlesOnly: false,
      exploreOnly: false,
      active: true,
      itemStatus: 1,
    };
    updateItemInfo(updateItem);
    UpdateLndItemStatuses(updateItem);
    itemManageUpdateCircles(itemInfo?.idCatLndItem, true, true, '', idCircle);
    closeItemsModal();
  };

  const nameStatus = () => {
    if (itemInfo.itemStatus == 3) return t('listing.listing_status_name');
    if (itemInfo.itemStatus == 0) return t('listing.listing_status_name_2');
    return '';
  };

  return (
    <>
      {itemsInCircles?.filter((element: any) => (!element.memberItem && isMember ? element.isMember : (element.isOwner || element.Admin))).map((item: any) => (
        <Stack key={uuid()}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              mx: isMobile ? '16px' : '30px',
            }}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '60%',
            }}
            >
              <Avatar
                variant="rounded"
                alt={item?.name}
                src={item?.circlePicture}
                sx={{
                  width: '80px', height: '80px', mr: '16px', borderRadius: '7px',
                }}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography sx={{
                    fontSize: '16px',
                    fontWeight: '600',
                    mb: '4px',
                  }}
                  >
                    {item?.name}
                  </Typography>
                </Box>
                <Typography sx={{
                  fontSize: '14px',
                  fontWeight: '500',
                  mb: '4px',
                }}
                >
                  {' '}
                  {item?.totalMembers}
                  {' '}
                  {t('circle_detail.members')}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '400',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                    lineBreak: 'anywhere',
                  }}
                >
                  {item?.dscription}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Chip
                label={isOwner(item)}
                variant="outlined"
                sx={{
                  zIndex: '2',
                  display: (item?.isOwner || item?.isAdmin) ? 'inherit' : 'none',
                  backgroundColor: 'white',
                  mb: '10px',
                  height: isMobile ? '20px' : '25px',
                  width: '75px',
                  fontSize: '12px',
                  fontWeight: '500',
                  borderWidth: '2px',
                  borderColor: (handleIsOwnerColor(item)),
                  color: (handleIsOwnerColor(item)),
                }}
              />
            </Box>
            <Box>
              <FormControlLabel
                label=""
                control={(
                  <IOSSwitch
                    checked={item?.isActiveCircles}
                    name="updateItemInCircle"
                    onChange={(e: any) => handleChange(e, item?.idCatLndCircle)}
                    sx={{ ml: 2, left: '20px' }}
                  />
                )}
              />
            </Box>
          </Box>
          <Divider sx={{ mb: '16px', mt: '16px' }} />
        </Stack>
      ))}
      <DialogCommon
        kTitle={t('listing.lend')}
        kMaxWidth="sm"
        kBorderRadius="10px"
        kDisableContentPadding
        KDisableScroll
        kDisableContentPaddinginY
        kSlideTransition={isMobile}
        kOpenDialogLists={isManageItemsOpen}
        kOnPrimary={updateStatusItems}
        kPrimaryBtnText="Yes"
        kOnClose={closeItemsModal}
        kContent={
          (
            <Box sx={{
              p: 2,
            }}
            >
              <Typography>
                {t('listing.listing_status')}
                {' '}
                {nameStatus()}
                {'. '}
              </Typography>
              <Typography>
                {t('listing.listing_status_question')}
              </Typography>
            </Box>
          )
        }
      />
    </>
  );
}

export default ManageCirclesSectionModal;

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  base: {
    boxShadow: 'none',
    width: '60px',
    height: '60px',
    padding: '15px',
    border: '1px solid #e8e8e8',
    borderRadius: '100%',
    cursor: 'pointer',

  },
  facebook: {
    backgroundColor: '#1877f2',
    '&:hover': {
      backgroundColor: '#1877f2',
    },
  },
  google: {
    backgroundColor: 'white',
    '& .abcRioButtonBlue, &:hover': {
      borderRadius: '100% !important',
      background: 'none !important',
      boxShadow: 'none !important',
    },
  },
  apple: {
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'black',
    },
  },
  // [theme.breakpoints.up('sm')]: {
  //   marginLeft: theme.spacing(3),
  //   width: 'auto',
  // },
});

export default useStyles;

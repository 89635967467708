import {
  Box, Button, IconButton, Stack, Tooltip, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import QRCode from 'react-qr-code';
import { useContext, useEffect, useState } from 'react';
import { DialogCommon } from '../../../../ProfilePrivate/Components';
import ShareSocialMedia from './ShareSocialMedia/ShareSocialMedia';
import addEditCirclesContext from '../../../../../../context/circles/addEditCirclesContext';

export default function ShareCircle(props: any) {
  const { t } = useTranslation('global');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [openShareSocialMedia, setOpenShareSocialMedia] = useState(false);

  const addEditContextLog = useContext(addEditCirclesContext);
  const {
    getCircleEditInfo,
    circleInfoEdit,
    circleUpdated,
  }: any = addEditContextLog;

  useEffect(() => {
    if (circleUpdated) {
      getCircleEditInfo(circleInfoEdit?.idCatLndCircle);
    }
  }, [circleUpdated]);

  return (
    <>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        maxWidth="686px"
        spacing={0}
        py={3}
      >
        <Box sx={{
          width: { xs: '121px', sm: '121px', md: '209px' },
          height: { xs: '121px', sm: '121px', md: '209px' },
          backgroundColor: '#e8e8e8',
          textAlign: 'center',
          alignItems: 'center',
          borderRadius: '7px',
          display: 'flex',
          justifyContent: 'center',
          mb: '30px',
        }}
        >
          <Box sx={{
            borderRadius: '7px',
            width: { xs: '105px', sm: '105px', md: '183px' },
            height: { xs: '105px', sm: '105px', md: '183px' },
          }}
          >
            <QRCode
              value={props.deepLink ? props.deepLink : circleInfoEdit?.deepLink || ''}
              style={{ width: '100%', height: '100%' }}
            />
          </Box>
        </Box>
        <Box>
          <Typography sx={{ fontSize: '14px' }}>
            {t('circles.add_circle_page.link_to_share')}
          </Typography>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            backgroundColor: '#f7f7f7',
            borderRadius: '10px',
            alignItems: 'center',
            pl: 2,
            pr: 1,
            mb: '30px',
            mt: '5px',
            height: '48px',
            width: { xs: '282px', sm: '282px', md: '395px' },
            maxWidth: '395px',
          }}
          >
            <Typography sx={{ fontSize: '13px' }}>
              {props.deepLink ? props.deepLink : circleInfoEdit?.deepLink}
            </Typography>
            <Tooltip title="Copy">
              <IconButton onClick={() => { navigator.clipboard.writeText(props.deepLink ? props.deepLink : circleInfoEdit?.deepLink); }}>
                <ContentCopyIcon color="primary" fontSize="medium" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Button
          onClick={() => { setOpenShareSocialMedia(true); }}
          disableElevation
          variant="contained"
          size="large"
          sx={{
            px: 5,
            py: 1,
            fontSize: '18px',
            textTransform: 'none',
            fontWeight: 'bold',
            borderRadius: '10px',
          }}
        >
          {t('circles.add_circle_page.share')}
        </Button>
      </Stack>
      <DialogCommon
        kTitle={t('circles.main.share_in')}
        kMaxWidth="xs"
        kEnableBorderRadius
        kMaxHeight={isMobile ? '180px' : 'auto'}
        kBorderRadius={isMobile ? 0 : '10px'}
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kDisableContentPadding
        kKeepBtnSize
        kCenterTitle
        kSlideTransition={isMobile}
        kTopDivider
        kOpenDialogLists={openShareSocialMedia}
        kOnClose={() => setOpenShareSocialMedia(false)}
        kDisableActions
        kContent={
          (
            <ShareSocialMedia deepLink={props.deepLink ? props.deepLink : circleInfoEdit?.deepLink} />
          )
        }
      />
    </>
  );
}

/* eslint-disable max-lines */
/* eslint import/no-unresolved: [2, { commonjs: true, amd: true }] */
import { useReducer } from 'react';
// import axios from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { ClsModSendNotificationGeneralNotification } from '@lendmeit/api_backend_lmi';
import ShuftiProContext from './shuftiProContext';
import ShuftiProReducer from './shuftiProReducer';
import {
  INITIALIZE_SHUFTI_PRO,
} from '../../types';
// import publicProfileContext from '../publicProfile/publicProfileContext';
import ApiLMI from '../../services/apilmi';

// const { sha256 } = require('js-sha256');

// declare global {
//   // eslint-disable-next-line no-unused-vars
//   interface Window {
//     gapi: any;
//     FB: any;
//   }
// }

const ShuftiProState = (props: any) => {
  const initialState = {
    loading: true,
    publicProfileInfo: {},
    shuftiUrl: '',
    referenceId: '',
  };

  // const pProfileContext = useContext(publicProfileContext);
  // const {
  //   publicProfileInfo,
  // }: any = pProfileContext;

  //   const initialChangePassWord = {
  //     oldPassword: '',
  //     newPass: '',
  //     newPassConfirm: '',
  //   };

  const [state, dispatch] = useReducer(ShuftiProReducer, initialState);

  const {
    loading,
    shuftiUrl,
    referenceId,
  } = state;

  const initializeShufti = async (email: String, userName: String, idCatLndUser: number, regenerate?: boolean) => {
    const clientId = process.env.REACT_APP_SHUFTI_PRO_CLIENT_ID;
    const clientSecret = process.env.REACT_APP_SHUFTI_PRO_CLIENT_SECRET;
    const token = btoa(`${clientId}:${clientSecret}`); // BASIC AUTH TOKEN
    const newReferenceId = `${userName ? userName.toLowerCase() : ''}_verification_shufti_${uuid()}_${idCatLndUser}_0`;
    if ((shuftiUrl == '' && referenceId == '') || regenerate) {
      const payload1 = {
        reference: newReferenceId,
        // reference: 'InitShufti_18548_0.21122434506168464',
        callback_url: `${process.env.REACT_APP_API_BACKEND}/api/callback/shuftipro`,
        // callback_url: 'https://9t0bxr2t4h.execute-api.us-west-2.amazonaws.com/gamez/api/callback/shuftipro',
        email,
        // country: 'US',
        language: 'EN',
        // redirect_url: 'http://www.example.com',
        show_feedback_form: '0',
        verification_mode: 'image_only',
        document: {
          proof: '',
          supported_types: ['id_card', 'driving_license', 'passport'],
          // name: '',
          // dob: '',
          // issue_date: '',
          // expiry_date: '',
          // document_number: '',
        },
        allow_retry: '1',
        face: {
          proof: '',
          allow_offline: '1',
          allow_online: '1',
        },

      };
      // var token = btoa('YOUR_CLIENT_ID:YOUR_SECRET_KEY');
      fetch('https://api.shuftipro.com/', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Basic ${token}`,
          // Authorization:'Basic RmZudHR3eE1iMDBLVUt2TTh5eVhkUXhKUTdHdFZ4RVFBNE9OcFJDTmQwQkRGdFdqZHMxNTc2MDE5OTE2OiQyeSQxMCRJWFJmYTJBYVJlT0MyVWJ3R3hhM0x1TmNBbmZVcDdERHFtNGJtRE1vdnVtTXJjR2ZpUlV5aQ==',
        },
        body: JSON.stringify(payload1),
      }).then((response: any) => response.json()).then((data) => {
        // console.log(data);
        dispatch({
          type: INITIALIZE_SHUFTI_PRO,
          verification_url: data?.verification_url,
          reference_id: newReferenceId,
        });
      }).catch((e) => {
        console.log(e);
      });
    }
  };

  const checkConfirmationStatus = async (updateVerified: any, email: string, userName: string, notifDetail: any) => {
    const clientId = process.env.REACT_APP_SHUFTI_PRO_CLIENT_ID;
    const clientSecret = process.env.REACT_APP_SHUFTI_PRO_CLIENT_SECRET;
    const token = btoa(`${clientId}:${clientSecret}`); // BASIC AUTH TOKEN

    const reqBody = {
      // reference: 'InitShufti_18548_0.7720990097241915', // Accepted reference
      // reference: 'InitShufti_18548_0.8311079623386501',
      reference: referenceId,
      // reference: 'InitShufti_18548_0.21122434506168464',
    };
    // updateVerified();
    fetch('https://api.shuftipro.com/status', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${token}`,
        // Authorization: 'Basic RmZudHR3eE1iMDBLVUt2TTh5eVhkUXhKUTdHdFZ4RVFBNE9OcFJDTmQwQkRGdFdqZHMxNTc2MDE5OTE2OiQyeSQxMCRJWFJmYTJBYVJlT0MyVWJ3R3hhM0x1TmNBbmZVcDdERHFtNGJtRE1vdnVtTXJjR2ZpUlV5aQ==',
      },
      body: JSON.stringify(reqBody),
    }).then((response: any) => response.json()).then(async (data) => {
      const api = ApiLMI();
      console.log(data);
      let body = {};
      if (data.event == 'verification.cancelled') {
        body = {
          eventResponse: data.event,
          reference_id: state.reference_id,
          declined_reason: data.declined_reason,
          declined_codes: data.declined_codes,
        };
        // api.apiUserUpdateShuftiStatusPost(body);
        state.referenceId = '';
        initializeShufti(
          email
            ?? Math.random().toString().split('.')[1],
          userName
            ?? Math.random().toString().split('.')[1],
          state.publicProfileData?.idCatLndUser ?? '0', true,
        );
      } else if (data.event == 'request.invalid') {
        // body = {

        // }
        // toast.error('Please continue with the verification process');
      } else if (data.event == 'request.pending') {
        // toast.error('Please continue with the verification process');
      } else if (data.event == 'verification.accepted') {
        body = {
          eventResponse: data.event,
          reference_id: state.reference_id,
          declined_reason: '',
          declined_codes: [],
        };
        const resp = await api.apiUserUpdateShuftiStatusPost(body);
        if (resp?.status == 200) {
          const bodyPushNotif: ClsModSendNotificationGeneralNotification = {
            idCatLndRntItem: notifDetail.idCatLndRntItem,
            status: '',
            notificationType: 2,
            sendSMS: true,
            sendPush: false,
            sendEmail: false,
          };
          const respNotif: any = await api.pushnotificationSendNotificationGeneralNotification(bodyPushNotif);
          const parsedRespNotif = JSON.parse(respNotif.data);
          if (parsedRespNotif.Error == '') updateVerified();
          toast.success(t('shufti.accepted'));
        } else {
          toast.error(t('shufti.error_verification'));
        }
      } else if (data.event == 'verification.declined') {
        body = {
          eventResponse: data.event,
          reference_id: state.reference_id,
          declined_reason: data.declined_reason,
          declined_codes: data.declined_codes,
        };
        // api.apiUserUpdateShuftiStatusPost(body);
        state.referenceId = '';
        initializeShufti(
          email
            ?? Math.random().toString().split('.')[1],
          userName
            ?? Math.random().toString().split('.')[1],
          state.publicProfileData?.idCatLndUser ?? '0', true,
        );
        toast.error(t('shufti.declined'));
      }
    }).catch((e) => {
      console.log(e);
    });
  };

  const { t } = useTranslation('global');

  return (
    <ShuftiProContext.Provider
      value={{
        ...state,
        loading,
        shuftiUrl,
        referenceId,
        initializeShufti,
        checkConfirmationStatus,
      }}
    >
      {props.children}

    </ShuftiProContext.Provider>
  );
};

export default ShuftiProState;

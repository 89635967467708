import { ReactElement } from 'react';

export interface MapData {
  address?: string;
  lat?: string;
  long?: string;
  height?: number;
  typeItem?: number;
  cost?: string;
  streetAddress?: string,
  extendedAddress?: string,
  locality?: string,
  region?: string,
  postalCode?: string,
  countryName?: string,
  countryCode?: string,
}

export interface MapModels {
  kFullHeight?: boolean;
  kDisableBorder?: boolean;
  kShowSearchedCircles?: any;
  kDisableInput?: boolean;
  kDisableInsideInput?: boolean;
  kOverMap?: ReactElement;
  kChangeLocation?: boolean;
  kDraggable?: boolean;
  state?: MapData;
  zoom?: number;
  userLatLng?: any;
  usrLat?: number,
  usrLng?: number,
  exactAddress?: boolean,
  kCircleItems?: boolean,
  kOnMarkerClick?: any,
  kAddress?: string,
  // eslint-disable-next-line no-unused-vars
  funUpdateInfo?(data: MapData): void;
}

const emptyMapModel = (): MapData => ({});

export const createMapEmpty = <T extends Partial<MapData>>(initialValues: T): MapData & T => Object.assign(emptyMapModel(), initialValues);

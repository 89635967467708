import {
  ITEMS_LOADED,
} from '../../types/index';

export default (state: any, action: any) => {
  switch (action.type) {
    case ITEMS_LOADED:

      return {
        ...state,
        favItems: action.payload.favItems,
      };
    default:
      return state;
  }
};

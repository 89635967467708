/* eslint-disable dot-notation */
import { useContext, useState } from 'react';
import {
  Stack,
  Button,
  Divider,
  Typography,
  Box,
  TextField,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider, MobileTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import moment from 'moment';
import { toast } from 'react-toastify';
import bookItemContext from '../../../../../context/bookItem/bookContext';
import ItemLocation from '../../../../../components/Common/ItemLocation/ItemLocation';
import { LmiTheme } from '..';
import { MapData } from '../../../../../components/Common/Map/Map.models';
import { DialogCommon } from '../../../../../components/Common';
import ModalMap from '../../../../../components/Common/Map/MapPlaces';
import { formatter } from '../../../../../services/common';

function DialogDeliveryByRoadie() {
  const {
    bookDetails: {
      startDeliveryTime,
      endDeliveryTime,
      idCatTypeItem,
      currency,
    },
    summaryTemp,
    roadieTemp,
    updateRoadieInfoTemp,
  }: any = useContext(bookItemContext);

  const {
    address: addressMap,
    lat,
    long,
  } = roadieTemp;

  const { roadie: roadieSummary } = summaryTemp ?? { roadie: null };

  const { price } = roadieSummary ?? { price: 0.0 };

  let title = 'place';

  switch (idCatTypeItem) {
    case 1:
      title = 'item';
      break;
    case 2:
      title = 'service';
      break;
    default:
      break;
  }
  const dtStart = moment(startDeliveryTime, ['h:mm A']).format('HH:mm');
  const dtEnd = moment(endDeliveryTime, ['h:mm A']).format('HH:mm');
  const startTime = dtStart.split(':');
  const endTime = dtEnd.split(':');
  const _startTime = new Date(0, 0, 0, parseInt(startTime[0], 10), parseInt(startTime[1], 10));
  const _endTime = new Date(0, 0, 0, parseInt(endTime[0], 10), parseInt(endTime[1], 10));
  const _maxTime = new Date(0, 0, 0, parseInt(endTime[0], 10) - 4, parseInt(endTime[1], 10));

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const receptionStartTime = roadieTemp?.receptionStartTime ?? _startTime;
  const receptionEndTime = moment(roadieTemp?.receptionStartTime ?? _startTime).add(4, 'hours');
  const returnStartTime = roadieTemp?.returnStartTime ?? _startTime;
  const returnEndTime = moment(roadieTemp?.returnStartTime ?? _startTime).add(4, 'hours');
  const [openChangeAddress, setOpenChangeAddress] = useState(false);

  const updateHours = (hours: Date | null, data: string) => {
    const dataObj = { ...roadieTemp };
    const hour = hours! > _maxTime ? _maxTime : hours;

    switch (data) {
      case 'receptionStartTime':
        dataObj.receptionStartTime = hour;
        dataObj.receptionEndTime = moment(hour).add(4, 'hours');
        break;
      case 'returnStartTime':
        dataObj.returnStartTime = hour;
        dataObj.returnEndTime = moment(hour).add(4, 'hours');
        break;
      default:
        break;
    }

    updateRoadieInfoTemp(dataObj);
  };

  const updateMapa = (mapaData: MapData) => {
    if (!roadieTemp.receptionStartTime) {
      roadieTemp['receptionStartTime'] = receptionStartTime;
      roadieTemp['receptionEndTime'] = receptionEndTime;
      roadieTemp['returnStartTime'] = returnStartTime;
      roadieTemp['returnEndTime'] = returnEndTime;
    }

    updateRoadieInfoTemp(
      {
        ...roadieTemp,
        address: mapaData.address ?? addressMap,
        countryName: mapaData.countryName,
        extendedAddress: mapaData.extendedAddress,
        locality: mapaData.locality,
        postalCode: mapaData.postalCode,
        region: mapaData.region,
        streetAddress: mapaData.streetAddress,
        lat: mapaData.lat,
        long: mapaData.long,
      },
    );

    if (mapaData.countryName == 'Mexico' || mapaData.countryName == 'Canada') {
      toast.warning(t('delivery.roadie.not_available'));
    }
  };

  const { t } = useTranslation('global');
  return (
    <>
      <DialogCommon
        kTitle={`${t('delivery.by_lender.select_location')} ${title}`}
        kMaxWidth="md"
        kOpenDialogLists={openChangeAddress}
        kOnClose={() => { setOpenChangeAddress(!openChangeAddress); }}
        kContent={
        (
          <ModalMap
            state={{
              address: addressMap,
              lat,
              long,
              typeItem: idCatTypeItem ?? 3,
            }}
            funUpdateInfo={updateMapa}
          />
        )
      }
      />
      <Box px={isMobile ? 2 : 4} pt={2} pb={1}>
        <Typography sx={{ color: LmiTheme.light.systemBackground.tertiary, fontSize: 'subtitle2.fontSize' }}>
          {t('delivery.by_lender.deliver_return')}
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          pt={1}
        >
          <Grid item>
            <ItemLocation
              kTextColor={LmiTheme.light.systemBackground.secondary}
              kText={addressMap}
            />
          </Grid>
          <Grid item>
            <Button
              variant="text"
              key="share"
              size="small"
              disableElevation
              disableRipple
              disableFocusRipple
              onClick={() => { setOpenChangeAddress(true); }}
              sx={{
                textTransform: 'none',
                color: LmiTheme.light.accent,
                fontWeight: 'bold',
                fontSize: 'body1.fontSize',
              }}
            >
              {t('delivery.by_lender.change')}
            </Button>
          </Grid>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          pt={2}
          px={1}
        >
          <Typography
            component="span"
            sx={{
              color: LmiTheme.light.systemBackground.secondary,
              fontSize: 'subtitle2.fontSize',
              fontWeight: '500',
            }}
          >
            {t('delivery.options.available_time_lender')}
            <> </>
          </Typography>
          <Typography
            component="span"
            sx={{
              color: LmiTheme.light.systemBackground.secondary,
              fontSize: 'subtitle2.fontSize',
              fontWeight: '700',
            }}
          >
            { `${startDeliveryTime} to ${endDeliveryTime}` }
          </Typography>
        </Stack>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            pt={2}
            pb={1}
            px={2}
          >
            <Grid item>
              <Typography sx={{ color: LmiTheme.light.systemBackground.tertiary, fontSize: 'subtitle2.fontSize', fontWeight: '500' }}>
                {t('delivery.roadie.reception_time_between')}
              </Typography>
            </Grid>
            <Grid item>
              <MobileTimePicker
                okText={t('delivery.roadie.set')}
                label={t('delivery.roadie.reception_start')}
                toolbarTitle={t('delivery.roadie.reception_start')}
                value={receptionStartTime}
                minTime={_startTime}
                maxTime={_maxTime}
                onChange={(newReceptionStartTime) => {
                  updateHours(newReceptionStartTime, 'receptionStartTime');
                }}
                /* eslint-disable react/jsx-props-no-spreading */
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    type="datetime"
                    variant="standard"
                    placeholder=""
                    size="small"
                    InputLabelProps={{ shrink: false }}
                    InputProps={{
                      disableUnderline: true,
                      readOnly: true,
                      style: {
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: 'subtitle1.fontSize',
                        color: LmiTheme.light.accent,
                        width: '70px',
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Typography sx={{
                color: LmiTheme.light.accent,
                fontSize: 'subtitle1.fontSize',
                fontWeight: '500',
              }}
              >
                —
              </Typography>
            </Grid>
            <Grid item>
              <MobileTimePicker
                disabled
                okText={t('delivery.roadie.set')}
                label={t('delivery.roadie.reception_end')}
                toolbarTitle={t('delivery.roadie.reception_end')}
                value={receptionEndTime! <= receptionStartTime! ? receptionStartTime : receptionEndTime}
                minTime={receptionStartTime}
                maxTime={_endTime}
                onChange={() => {}}
                /* eslint-disable react/jsx-props-no-spreading */
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    type="datetime"
                    variant="standard"
                    placeholder=""
                    size="small"
                    InputLabelProps={{ shrink: false }}
                    InputProps={{
                      disableUnderline: true,
                      readOnly: true,
                      style: {
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: 'subtitle1.fontSize',
                        color: LmiTheme.light.accent,
                        width: '70px',
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            pt={1}
            pb={2}
            px={2}
          >
            <Grid item>
              <Typography sx={{
                color: LmiTheme.light.systemBackground.tertiary,
                fontSize: 'subtitle2.fontSize',
                fontWeight: '500',
              }}
              >
                {t('delivery.roadie.reception_time_between')}
              </Typography>
            </Grid>
            <Grid item>
              <MobileTimePicker
                okText={t('delivery.roadie.set')}
                label={t('delivery.roadie.return_start')}
                toolbarTitle={t('delivery.roadie.return_start')}
                value={returnStartTime}
                minTime={_startTime}
                maxTime={_maxTime}
                onChange={(newReturnStartTime) => {
                  updateHours(newReturnStartTime, 'returnStartTime');
                }}
                /* eslint-disable react/jsx-props-no-spreading */
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    type="datetime"
                    variant="standard"
                    placeholder=""
                    size="small"
                    InputLabelProps={{ shrink: false }}
                    InputProps={{
                      disableUnderline: true,
                      readOnly: true,
                      style: {
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: 'subtitle1.fontSize',
                        color: LmiTheme.light.accent,
                        width: '70px',
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Typography sx={{
                color: LmiTheme.light.accent,
                fontSize: 'subtitle1.fontSize',
                fontWeight: '500',
              }}
              >
                —
              </Typography>
            </Grid>
            <Grid item>
              <MobileTimePicker
                disabled
                okText={t('delivery.roadie.set')}
                label={t('delivery.roadie.return_end')}
                toolbarTitle={t('delivery.roadie.return_end')}
                value={returnEndTime! <= returnStartTime! ? returnStartTime : returnEndTime}
                // minTime={returnStartTime}
                // maxTime={_endTime}
                onChange={() => {}}
                /* eslint-disable react/jsx-props-no-spreading */
                renderInput={(params) => (
                  <TextField
                    {...params}
                    disabled
                    label=""
                    type="datetime"
                    variant="standard"
                    placeholder=""
                    size="small"
                    InputLabelProps={{ shrink: false }}
                    InputProps={{
                      disableUnderline: true,
                      readOnly: true,
                      style: {
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: 'subtitle1.fontSize',
                        color: LmiTheme.light.accent,
                        width: '70px',
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </Stack>
        </LocalizationProvider>
        <Divider />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          p={1}
        >
          <Grid item>
            <Typography sx={{ color: LmiTheme.light.systemBackground.secondary, fontSize: 'subtitle1.fontSize', fontWeight: 'bold' }}>
              {t('delivery.roadie.cost')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ color: LmiTheme.light.systemBackground.secondary, fontSize: 'subtitle1.fontSize', fontWeight: 'bold' }}>
              {price > 0 ? `${formatter.format(price)} ${currency}` : `${t('delivery.roadie.not_available_address')}`}
            </Typography>
          </Grid>
        </Stack>
      </Box>
      <Divider />
    </>
  );
}

export default DialogDeliveryByRoadie;

import {
  Box, Button, IconButton, Paper, useMediaQuery, useTheme,
} from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import AddEditCirclesContext from '../../../../../context/circles/addEditCirclesContext';
import MyCirclesListingContext from '../../../../../context/myCirclesListing/myCirclesListingContext';
import Heading from '../../../Circles/AddCircle/Components/Heading/Heading';
import ManageCircles from './ManageCircles/ManageCircles';
import { DialogCommon } from '../../../../../components/Common';
import ManageCirclesModal from './ManageCircles/ManageCirclesModal';

function ManageCirclesSection() {
  const circlesContextLog = useContext(AddEditCirclesContext);
  const circlesContext = useContext(MyCirclesListingContext);
  const {
    circles,
    getMyCircles,
  }: any = circlesContext;

  const {
    setModalItemEditPage,
  }: any = circlesContextLog;

  useEffect(() => {
    (async function fetchCircles() {
      await getMyCircles();
    }());
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation('global');

  const [isManageItemsOpen, setIsManageItemsOpen] = React.useState(false);

  const closeItemsModal = () => {
    setModalItemEditPage(1);
    setIsManageItemsOpen(false);
  };

  const openManageOpenDialog = () => {
    setIsManageItemsOpen(true);
  };

  const showManageButton = () => (
    // eslint-disable-next-line no-nested-ternary
    isMobile ? (
      <IconButton
        onClick={() => openManageOpenDialog()}
      >
        <ArrowForwardIosRoundedIcon color="primary" />
      </IconButton>
    ) : (
      <Button
        variant="text"
        sx={{ textTransform: 'none', fontSize: '18px' }}
        onClick={() => openManageOpenDialog()}
      >
        {t('circles.add_circle_page.manage')}
      </Button>
    )
  );

  return (
    <>
      <Box
        mt={3}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: '10px',
        }}
      >
        <Heading kTitle={t('item_detail.manage_circles')} kPaddingBottom="0px" />
        {
          circles?.length > 0 ? showManageButton() : null
        }
      </Box>
      <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
        <ManageCircles circles={circles} />
      </Paper>

      <DialogCommon
        kTitle={t('item_detail.manage_circles')}
        kMaxWidth="sm"
        kBorderRadius="10px"
        kDisableContentPadding
        KDisableScroll
        kDisableContentPaddinginY
        kSlideTransition={isMobile}
        kOpenDialogLists={isManageItemsOpen}
        kOnClose={closeItemsModal}
        kContent={
          (
            <ManageCirclesModal />
          )
        }
      />
    </>
  );
}

export default ManageCirclesSection;

import {
  alpha,
  Box, Button, Card, CardContent, Grid, IconButton, Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import { LmiTheme } from '../../../../../../components/Common';
import MyCirclesListingContext from '../../../../../../context/myCirclesListing/myCirclesListingContext';
import { CircleItemsDetailModels } from './CircleItemsDetail.models';
import bookContext from '../../../../../../context/bookItem/bookContext';
import FavoritesContext from '../../../../../../context/favorites/favoritesContext';

export default function CircleItemsDetail(props: CircleItemsDetailModels) {
  const {
    items,
    circle,
  }: any = useContext(MyCirclesListingContext);

  const {
    clearData,
  }: any = useContext(bookContext);

  const {
    favItems,
    loadItems,
    updateFavorite,
  }: any = useContext(FavoritesContext);

  useEffect(() => {
    (async function loadItemsData() {
      await loadItems();
    }());
  }, []);

  const history = useHistory();
  const [isHeartClicked, setIsHeartClicked] = useState(false);

  const openCircleItemsPage = () => {
    window.scrollTo(0, 0);
    history.push({
      pathname: '/circles/items',
    });
  };

  function handleClick(bookItem: any): void {
    if (!isHeartClicked) {
      clearData();
      const idCatLndCircle = circle?.idCatLndCircle ?? 0;
      history.push('/bookitem', {
        bookItem,
        idCatLndCircle,
      });
    } else {
      setIsHeartClicked(false);
    }
  }

  const clickedHeart = async (item: any, isFavorite: boolean) => {
    setIsHeartClicked(true);
    await updateFavorite(item, isFavorite);
  };

  const totalItems = items?.length;

  props.kTotalItems(totalItems);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  // eslint-disable-next-line no-nested-ternary
  const shownItems = isMobile ? 3 : isTablet ? 5 : 7;

  const { t } = useTranslation('global');

  return (
    <>
      {
        totalItems > 0 ? (
          <Box px={isMobile ? '17px' : '30px'} pt={3} pb={totalItems > 8 ? 3 : 0}>
            <Grid container rowSpacing={0} columnSpacing={2}>
              {
                items?.map((item: any, i: number) => {
                  const isFavoriteIndex = favItems?.findIndex(
                    (element: any) => element.idCatLndItem == item.idCatLndItem,
                  );
                  item.isFavorite = false;
                  if (isFavoriteIndex > -1) {
                    item.isFavorite = true;
                  }
                  if (i <= shownItems) {
                    return (

                      <Grid key={item.itemPicture} item xs={6} sm={4} md={3}>
                        <Card
                          elevation={0}
                          sx={{ cursor: 'pointer', position: 'relative' }}
                        >
                          <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="flex-end"
                            onClick={() => handleClick(item)}
                            sx={{
                              aspectRatio: '5 / 4',
                              backgroundImage: `url(${item?.itemPicture})`,
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                              borderRadius: '7px',
                            }}
                          />
                          <IconButton
                            sx={{
                              position: 'absolute',
                              top: 5,
                              right: 5,
                              backgroundColor: alpha(LmiTheme.light.systemBackground.primary, 0.5),
                              '&:hover': {
                                backgroundColor: alpha(LmiTheme.light.systemBackground.primary, 0.8),
                              },
                            }}
                            onPointerEnter={() => {
                              setIsHeartClicked(true);
                            }}
                            onPointerLeave={() => {
                              setIsHeartClicked(false);
                            }}
                            onClick={() => {
                              clickedHeart(item, !item.isFavorite);
                            }}
                          >
                            {item.isFavorite ? (
                              <Favorite
                                sx={{ color: 'white' }}
                              />
                            ) : (
                              <FavoriteBorder
                                sx={{ color: 'white' }}
                              />
                            )}
                          </IconButton>
                          <CardContent sx={{ p: 0 }}>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              px={0}
                              pt={1}
                              pb={0.5}
                            >
                              <Typography
                                variant="body2"
                                color="text.primary"
                                fontWeight={500}
                                sx={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  display: '-webkit-box',
                                  WebkitBoxOrient: 'vertical',
                                  WebkitLineClamp: 1,
                                }}
                              >
                                {item?.name}
                              </Typography>
                            </Stack>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              fontSize="12px"
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 2,
                              }}
                            >
                              {item?.dscription}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  }
                  return null;
                })
              }
            </Grid>
            {
              totalItems > (isMobile ? 4 : 8) && (
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  mt: isMobile ? '16px' : '30px',
                  mb: isMobile ? 0 : '14px',
                }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => openCircleItemsPage()}
                    sx={{
                      height: '56px',
                      width: '150px',
                      borderRadius: '10px',
                    }}
                  >
                    <Typography sx={{
                      textTransform: 'none',
                      fontSize: '18px',
                      fontWeight: 'bold',
                    }}
                    >
                      {t('circles.detail.see_more')}
                    </Typography>
                  </Button>
                </Box>
              )
            }

          </Box>
        ) : (
          <Stack p={2} direction="row" justifyContent="space-between" spacing={3}>
            <Box>
              <Typography fontSize="16px" pl={1} color={LmiTheme.light.systemBackground.tertiary}>
                {t('circles.detail.no_items')}
              </Typography>
            </Box>
          </Stack>
        )
      }

    </>
  );
}

/* eslint-disable react/jsx-no-bind */
import {
  Box, Button, Paper, Stack, Typography, useMediaQuery, useTheme, FormHelperText,
  CardMedia, IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CancelOutlined, AddCircleRounded } from '@mui/icons-material';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import { FileUploader } from 'react-drag-drop-files';

import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from 'react-grid-drag';
import {
  useContext, useMemo, useState,
} from 'react';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import { ImagesGridListModels } from './ImagesGridList.models';
import LmiTheme from '../../../themes/lmi-mui-theme/lmi-theme';
import Heading from '../../../page/BackOffice/AddItem/Components/Heading/Heading';
import LmiColorPalette from '../../../themes/lmi-mui-theme/lmi-color-palette';
import PickUpDropOffPicturesContext from '../../../context/activity/pickUpDropOffPictures/pickUpdropOffPicturesContext';
import { DialogCommon, SwiperThumbnails } from '..';

function ImagesGridList(props: ImagesGridListModels) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const removePictureFromArrayF = props.removePictureFromArray;
  const removeVideoFromArrayF = props.removeVideoFromArray ? props.removeVideoFromArray : null;
  const images = props.itemImages;
  const videos = props.itemVideos ? props.itemVideos : [];
  const [isDragging, setIsDragging] = useState(false);

  const {
    pickUpPictures,
    stopSwiperVideos,
  }: any = useContext(PickUpDropOffPicturesContext);

  function picRemove(index: any) {
    const pics22 = images;
    pics22.splice(index, 1);
    removePictureFromArrayF(pics22);
  }

  function videoRemove(index: any) {
    const vids22 = videos;
    vids22.splice(index, 1);
    if (removeVideoFromArrayF) {
      removeVideoFromArrayF(vids22);
    }
  }

  const onVideoDroppedF = props.onVideoDropped;
  const swapVideoF = props.swapVideo;

  const onImageDroppedF = props.onImageDropped;
  const swapImageF = props.swapImage;
  const fileTypes = [
    'JPG', 'JPEG',
    'PNG', 'WEBP',
    'BMP', 'TIFF',
  ];

  const videoFileTypes = [
    'MP4',
    'MOV',
  ];

  function onChange(sourceId: any, sourceIndex: any, targetIndex: any) {
    console.log(sourceId);
    document.body.classList.remove('not-scroll');
    if (images.length <= 6 && images.length != sourceIndex) {
      const nextState = swap(images, sourceIndex, targetIndex);
      swapImageF(nextState);
    }
  }

  function onChangeVideo(sourceId: any, sourceIndex: any, targetIndex: any) {
    console.log(sourceId);
    document.body.classList.remove('not-scroll');
    if (videos.length <= 6 && videos.length != sourceIndex) {
      const nextState = swap(videos, sourceIndex, targetIndex);
      if (swapVideoF) {
        swapVideoF(nextState);
      }
    }
  }

  function onTouchStartDrag() {
    document.body.classList.add('not-scroll');
  }
  const { t } = useTranslation('global');

  const [openSwiper, setOpenSwiper] = useState(false);

  const imagesToHandle = useMemo(() => (props.isActivity ? pickUpPictures : props.itemImages), [props.isActivity, props.itemImages]);
  const videosToHandle = props.itemVideos ? props.itemVideos : [];

  const defaultImage: any = [];

  return (
    <>
      <Heading
        kTitle={t('add_item_page.pictures_videos')}
      />
      <Paper
        variant="outlined"
        sx={{
          p: { xs: 0, sm: 2 },
          borderWidth: { xs: 0, sm: 1 },
          borderRadius: '10px',
        }}
      >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Typography component="p" pb={2} sx={{ fontSize: 'body2.fontSize', color: LmiTheme.light.systemBackground.tertiary }}>
            {t('add_item_page.upload_pictures')}
          </Typography>
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Typography
            component="p"
            pb={2}
            sx={{
              fontSize: 'body2.fontSize',
              fontWeight: 'bold',
              color: LmiTheme.light.systemBackground.secondary,
            }}
          >
            {t('add_item_page.pictures')}
          </Typography>
          <Typography
            component="span"
            pb={2}
            sx={{
              fontSize: 'body2.fontSize',
              fontWeight: 'bold',
              color: LmiTheme.light.systemBackground.secondary,
            }}
          >
            {imagesToHandle?.length}
            /6
          </Typography>
        </Stack>

        <GridContextProvider onChange={onChange}>
          <GridDropZone
            id="items"
            boxesPerRow={!isMobile ? 3 : 2}
            rowHeight={!isMobile ? 200 : 137}
            style={{
              height: !isMobile ? 'calc(200px * 2 + 5px)' : 'calc(137px * 3 + 10px)',
              minHeight: !isMobile ? '420px' : '425px',
              paddingTop: '10px',
              overflow: 'hidden',
            }}
          >
            {imagesToHandle && imagesToHandle?.length > 0
              ? imagesToHandle?.map((item: any, index: number) => (
                <GridItem
                  onTouchStart={() => {
                    setIsDragging(true);
                    onTouchStartDrag();
                  }}
                  onTouchEnd={() => {
                    setIsDragging(false);
                  }}
                  onClick={() => document.body.classList.remove('not-scroll')}
                  key={item.idDetLndItemImage}
                  className="lmiw-img-grid"
                  style={{
                    boxShadow: 'none',
                    padding: '7.5px',
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    spacing={3}
                    className="__image"
                    sx={{
                      borderRadius: '7px',
                      backgroundImage: `url(${item.imgPath != undefined ? item.imgPath : item})`,
                      aspectRatio: '5 / 4',
                      width: '100%',
                      height: '100%',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
                    }}
                  >
                    <Box
                      onClick={() => {
                      }}
                      sx={{
                        background: 'linear-gradient(45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.7) 100%)',
                        p: 1,
                        width: '40%',
                        height: '40%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-start',
                        borderBottomLeftRadius: '50%',
                        borderTopRightRadius: '7px',
                      }}
                      aria-label="add"
                    >
                      <CancelOutlined
                        fontSize="medium"
                        sx={{ color: LmiTheme.light.systemBackground.primaryIverted, cursor: 'pointer' }}
                        onMouseDown={(e) => {
                          if (!isDragging) {
                            if (props.isActivity) {
                              picRemove(index);
                            } else {
                              removePictureFromArrayF(item.idDetLndItemImage, index);
                            }
                          }
                          e.stopPropagation();
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      />
                    </Box>
                  </Stack>
                </GridItem>
              )) : null}
            {(imagesToHandle?.length > 0 && imagesToHandle?.length < 6) ? (
              <GridItem
                draggable={false}
                onTouchStart={() => onTouchStartDrag()}
                onClick={() => document.body.classList.remove('not-scroll')}
                className="lmiw-img-grid"
                key={0}
                style={{
                  boxShadow: 'none',
                  padding: '7.5px',
                }}
              >
                <FileUploader
                  maxSize={256}
                  multiple
                  handleChange={onImageDroppedF}
                  name="file"
                  types={fileTypes}
                >
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    className="__add"
                    sx={{
                      borderRadius: '7px',
                      bgcolor: LmiColorPalette.blueScheme[50],
                      aspectRatio: '5 / 4',
                      backgroundSize: 'cover',
                      width: '100%',
                      height: '100%',
                      border: `1px dashed ${LmiTheme.light.accent}`,
                      cursor: 'pointer',
                    }}
                  >
                    <AddCircleRounded fontSize="large" sx={{ color: LmiTheme.light.accent }} />
                    <Typography sx={{ fontSize: 'body1.fontSize', fontWeight: 500, color: LmiTheme.light.accent }}>
                      {t('add_item_page.add_photo')}
                    </Typography>
                  </Stack>
                </FileUploader>
              </GridItem>
            ) : null}
            {!imagesToHandle?.length
              ? (
                <FileUploader
                  maxSize={256}
                  multiple
                  handleChange={onImageDroppedF}
                  name="file"
                  types={fileTypes}
                >
                  <Button
                    sx={{
                      aspectRatio: '5 / 4',
                      height: '100%',
                      width: '100%',
                      backgroundSize: 'cover',
                      border: 1,
                      borderColor: LmiTheme.light.systemBackground.separator,
                      bgcolor: LmiTheme.light.systemBackground.base,
                    }}
                  >
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={3}
                      sx={{ textTransform: 'none' }}
                    >
                      <IosShareOutlinedIcon sx={{ fontSize: 100, color: LmiTheme.light.systemBackground.tertiary }} />
                      <Box sx={{ textTransform: 'none' }}>
                        <Typography component="span" color={LmiTheme.light.accent} fontSize={20} fontWeight={500}>
                          {t('add_item_page.browse_file_txt')}
                        </Typography>
                        <> </>
                        <Typography component="span" color={LmiTheme.light.systemBackground.primary} fontSize={20} fontWeight={500}>
                          {t('add_item_page.browse_file_txt2')}
                        </Typography>
                        <Typography component="p" color={LmiTheme.light.systemBackground.primary} fontSize={20} fontWeight={500}>
                          {t('add_item_page.browse_file_txt3')}
                        </Typography>
                      </Box>
                      <Typography component="p" color={LmiTheme.light.systemBackground.tertiary} fontSize={16} fontWeight="normal">
                        {t('add_item_page.max_file_size')}
                      </Typography>
                    </Stack>
                  </Button>
                </FileUploader>
              )
              : null}
          </GridDropZone>
        </GridContextProvider>
        {imagesToHandle?.length == 0 && props.isSave ? <FormHelperText error>{t('add_item_page.required_photo')}</FormHelperText> : ''}

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          mt={3}
        >
          <Typography
            component="p"
            pb={2}
            sx={{
              fontSize: 'body2.fontSize',
              fontWeight: 'bold',
              color: LmiTheme.light.systemBackground.secondary,
            }}
          >
            Videos
          </Typography>
          <Typography
            component="span"
            pb={2}
            sx={{
              fontSize: 'body2.fontSize',
              fontWeight: 'bold',
              color: LmiTheme.light.systemBackground.secondary,
            }}
          >
            {videosToHandle?.length}
            /2
          </Typography>
        </Stack>

        <GridContextProvider onChange={onChangeVideo}>
          <GridDropZone
            id="items"
            boxesPerRow={!isMobile ? 3 : 2}
            rowHeight={!isMobile ? 200 : 137}
            style={{
              height: !isMobile ? 'calc(100px * 2 + 5px)' : 'calc(68.5px * 3 + 10px)',
              maxHeight: '210px',
              paddingTop: '10px',
              overflow: 'hidden',
            }}
          >

            {videosToHandle && videosToHandle?.length > 0
              ? videosToHandle?.map((item: any, index: number) => (
                <GridItem
                  onTouchStart={() => onTouchStartDrag()}
                  onClick={() => document.body.classList.remove('not-scroll')}
                  key={item.idDetLndItemVideo}
                  className="lmiw-img-grid"
                  style={{
                    boxShadow: 'none',
                    padding: '7.5px',
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    spacing={3}
                    className="__video"
                    sx={{
                      borderRadius: '7px',
                      backgroundColor: 'black',
                      aspectRatio: '5 / 4',
                      width: '100%',
                      height: '100%',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
                      position: 'relative',
                      overflow: 'hidden',
                    }}
                  >
                    <CardMedia
                      component="video"
                      height="500"
                      src={item.videoPath != undefined ? item.videoPath : item.base64}
                      sx={{
                        width: '100%',
                        height: '100%',
                      }}
                    />
                    <Box
                      onClick={() => {
                        if (props.isActivity) {
                          videoRemove(index);
                        } else if (removeVideoFromArrayF) {
                          removeVideoFromArrayF(item.idDetLndItemImage, index);
                        }
                      }}
                      sx={{
                        p: 1,
                        position: 'absolute',
                        zIndex: 10,
                        cursor: 'pointer',
                      }}
                      aria-label="add"
                    >
                      <CancelOutlined
                        fontSize="medium"
                        sx={{ color: LmiTheme.light.systemBackground.primaryIverted }}
                      />
                    </Box>
                    <Box
                      sx={{
                        background: 'linear-gradient(45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.7) 100%)',
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          setOpenSwiper(true);
                        }}
                      >
                        <PlayCircleOutlineOutlinedIcon style={{ color: 'white', width: '50px', height: '50px' }} />
                      </IconButton>
                    </Box>
                  </Stack>
                </GridItem>
              )) : null}
            {(videosToHandle?.length > 0 && videosToHandle?.length < 2) ? (
              <GridItem
                draggable={false}
                onTouchStart={() => onTouchStartDrag()}
                onClick={() => document.body.classList.remove('not-scroll')}
                className="lmiw-img-grid"
                key={0}
                style={{
                  boxShadow: 'none',
                  padding: '7.5px',
                }}
              >
                <FileUploader
                  maxSize={512}
                  multiple
                  handleChange={onVideoDroppedF}
                  name="file"
                  types={videoFileTypes}
                >
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    className="__add"
                    sx={{
                      borderRadius: '7px',
                      cursor: 'pointer',
                      bgcolor: LmiColorPalette.blueScheme[50],
                      aspectRatio: '5 / 4',
                      backgroundSize: 'cover',
                      width: '100%',
                      height: '100%',
                      border: `1px dashed ${LmiTheme.light.accent}`,
                    }}
                  >
                    <AddCircleRounded fontSize="large" sx={{ color: LmiTheme.light.accent }} />
                    <Typography sx={{ fontSize: 'body1.fontSize', fontWeight: 500, color: LmiTheme.light.accent }}>
                      {t('add_item_page.add_video')}
                    </Typography>
                  </Stack>
                </FileUploader>
              </GridItem>
            ) : null}
            {!videosToHandle?.length
              ? (
                <FileUploader
                  maxSize={512}
                  multiple
                  handleChange={onVideoDroppedF}
                  name="file"
                  types={videoFileTypes}
                >
                  <Button
                    sx={{
                      aspectRatio: '5 / 4',
                      height: '100%',
                      width: '100%',
                      backgroundSize: 'cover',
                      border: 1,
                      borderColor: LmiTheme.light.systemBackground.separator,
                      bgcolor: LmiTheme.light.systemBackground.base,
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={3}
                      sx={{ textTransform: 'none' }}
                    >
                      <IosShareOutlinedIcon sx={{ fontSize: 100, color: LmiTheme.light.systemBackground.tertiary }} />
                      <Box sx={{ textTransform: 'none' }}>
                        <Typography component="span" color={LmiTheme.light.accent} fontSize={20} fontWeight={500}>
                          {t('add_item_page.browse_file_txt')}
                        </Typography>
                        <> </>
                        <Typography component="span" color={LmiTheme.light.systemBackground.primary} fontSize={20} fontWeight={500}>
                          {t('add_item_page.browse_file_txt2')}
                        </Typography>
                        <Typography component="p" color={LmiTheme.light.systemBackground.primary} fontSize={20} fontWeight={500}>
                          {t('add_item_page.browse_file_txt3_videos')}
                        </Typography>
                        <Typography component="p" color={LmiTheme.light.systemBackground.tertiary} fontSize={16} fontWeight="normal" mt={2}>
                          {t('add_item_page.max_file_size_videos')}
                        </Typography>
                        <Typography component="p" color={LmiTheme.light.systemBackground.tertiary} fontSize={16} fontWeight="normal">
                          {t('add_item_page.accepted_formats')}
                          {' MP4, MOV'}
                        </Typography>
                      </Box>
                    </Stack>
                  </Button>
                </FileUploader>
              )
              : null}
          </GridDropZone>
        </GridContextProvider>

      </Paper>

      <DialogCommon
        kTitle="Videos"
        kMaxWidth="sm"
        kBorderRadius={isMobile ? 0 : '10px'}
        kSlideTransition={isMobile}
        kOpenDialogLists={openSwiper}
        kOnClose={() => {
          setOpenSwiper(false);
          stopSwiperVideos();
        }}
        kDisableActions
        kContent={(
          <SwiperThumbnails
            itemImages={!videos ? defaultImage : videos}
            height={460}
            thumbnailHeight={80}
          />
        )}
      />
    </>
  );
}

export default ImagesGridList;

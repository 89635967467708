import { LmiTheme } from '../../../../../components/Common';

const NavbarAncillaryStyle = {
  backgroundColor: 'white',
  px: 3,
  py: { xs: 3, md: 5 },
  'Button, a': {
    textTransform: 'inherit',
    fontWeight: '400',
  },
  // '.lmi-btn-back': {
  //   fontSize: { xs: 12, md: 14 },
  // },
  a: {
    color: LmiTheme.light.accent,
  },

  '.NavigationMenu a.MuiButton-root': {
    justifyContent: 'flex-start',
    pl: 2,
    color: LmiTheme.light.systemBackground.primary,
  },

  '.MuiFab-circular': {
    background: 'white',
    boxShadow: 'none',
    border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
  },

};

export default NavbarAncillaryStyle;

import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { Divider } from '@mui/material';
import { useContext } from 'react';
import addEditItemContext from '../../../../../../../context/addEditItem/addEditItemContext';
import { LmiTheme } from '../../../../../../../components/Common';

export default function ListRequirements(props: any) {
  const triggerFunction = props.editRequirement;
  const addEditContextLog = useContext(addEditItemContext);

  const {
    userTerms,
    updateRequirementsList,
    updateDraftRequirementList,
  }: any = addEditContextLog;
  const [checked, setChecked] = React.useState([0]);

  const editRequirement = (value: any) => () => {
    triggerFunction(value);
  };

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const updateReqActive = (index: any) => {
    userTerms[index].relatedToItem = !userTerms[index].relatedToItem;
    updateRequirementsList(userTerms);
    updateDraftRequirementList(userTerms);
  };

  return (
    <List sx={{ width: '100%' }}>
      {userTerms?.length > 0 ? userTerms?.map((value: any, index: any) => (

        <ListItem
          // eslint-disable-next-line react/no-array-index-key
          divider
          key={value.idTerm.toString() ? value.idTerm.toString() : value.generatedNewID}
          secondaryAction={(
            <IconButton onClick={editRequirement(index)}>
              <EditIcon fontSize="medium" sx={{ color: LmiTheme.light.accent }} />
            </IconButton>
          )}
          disablePadding
        >
          <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
            <ListItemIcon>
              <Checkbox
                // edge="start"
                checked={value.relatedToItem}
                onChange={() => { updateReqActive(index); }}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText
              // id={labelId}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                maxWidth: { xs: 'calc(100% - 130px)', sm: '100%' },
              }}
              primaryTypographyProps={{
                fontSize: 'body2.fontSize',
              }}
              primary={value.termDetail}
            />
          </ListItemButton>
          <Divider />
        </ListItem>
      )) : null}
    </List>
  );
}

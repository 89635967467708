/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/extensions */
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import * as React from 'react';
import {
  Box, Button, Container, useMediaQuery, useTheme,
} from '@mui/material';
import { toast } from 'react-toastify';
import { ArrowBackIos } from '@mui/icons-material';
import {
  Footer, SocialButton, Form, Picture, WelcomeText,
} from './Components';
import authContext from '../../../context/authentication/authContext';
import { IconLinkButton, Logo } from '../../../components/Common';
import { AuthType } from '../../../context/authentication/authState';
import LoadingLayout from '../../../components/Common/LoadingLayout/LoadingLayout';

export default function LogIn() {
  const history = useHistory();
  const authContextLog = useContext(authContext);

  const {
    confirmAccount,
    logIn,
    message,
    token,
    user,
    addUser,
    codeSend,
    ForgotPasswordVerificationCode,
    ForgotPassword,
    fnConfirmAccount,
    userExist,
    appType,
    updateProviderAttributes,
    ResendCode,
    isBlocked,
    userName,
    newUser,
    setNewUser,
    email: Email,
    sendWelcomeEmail,
  }: any = authContextLog;
  const [activeStep, setActiveStep] = React.useState(0);
  const [email, setEmail] = useState<any>(null);
  const [code, setCode] = useState<any>(null);
  const [userExistsLogin, setUserExistsLogin] = useState<any>(false);
  const [password, setPassword] = useState<any>(null);
  const [phone, setPhone] = useState<any>(null);
  const [newPassword, setNewPassword] = useState<any>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [country, setCountry] = React.useState('+52');

  // eslint-disable-next-line no-nested-ternary
  const sizeLogo = isMobile ? 180 : 210;
  const { state }: any = history.location;
  const tokens = localStorage.getItem('token');

  let fromTest = '';

  if (document.referrer != (`${document.location.origin}/`) && document.referrer.indexOf(document.location.origin) > -1) {
    fromTest = document.referrer.replace(document.location.origin, '');
    localStorage.setItem('referrer', JSON.stringify({ fromTest, state }));
  }
  useEffect(() => {
    const data = localStorage.getItem('referrer') ? JSON.parse(localStorage.getItem('referrer')!.toString()) : { from: '' };

    const redirectState = JSON.parse(localStorage.getItem('redirectState') || '{}');
    const redirectPath = redirectState.kFromDraft
      ? {
        pathname: '/listing/edit',
        state: {
          ...redirectState,
          kItemId: 0,
          kItemIsNew: true,
          kItemIndex: redirectState.kItemIndex || 0,
          updateSwiper: false,
          kShowDetailsMobile: false,
        },
      }
      : '/';

    fromTest = data.fromTest;
    if (user && confirmAccount) {
      if (tokens && tokens !== '') {
        if (fromTest && fromTest != '' && fromTest != '/login') {
          localStorage.removeItem('referrer');
          history.push(fromTest, data.state);
        } else if (newUser) {
          sendWelcomeEmail({ type: 'welcome', userEmail: Email });
          history.push('/user-completion');
        } else {
          history.push(redirectPath);
          localStorage.removeItem('redirectState');
        }
        localStorage.removeItem('userExists');
        setNewUser(false);
      }
    } else if (confirmAccount == false) {
      if (appType == AuthType.Amplify || ((!phone || phone == '') && !userExistsLogin)) {
        setActiveStep(2);
      } else {
        setActiveStep(3);
      }
    }
    if (codeSend) {
      setActiveStep(5);
    }

    if (message) {
      if (message.category === 'success') {
        toast.success(message);
      } else if (message.msg != '') {
        toast.error(message.msg);
      }
    }
  }, [
    message,
    user,
    codeSend,
    confirmAccount,
    token,
    appType,
  ]);

  const handleNext = async (data: any) => {
    if (userExistsLogin && activeStep == 1) {
      await logIn({ email, password: data });
    } else if (activeStep == 4) {
      await ForgotPassword(email);
    } else {
      setActiveStep(() => activeStep + 1);
    }
  };

  const stepBack = () => {
    if ((appType == AuthType.Amplify) && activeStep == 2) {
      setActiveStep(0);
    } else if (activeStep === 4) {
      setPassword('');
      setActiveStep(1);
    } else {
      setActiveStep(() => activeStep - 1);
    }
  };

  async function existUser(emailToValidate: any) {
    const exist = await userExist(emailToValidate);
    return exist;
  }

  async function emailValidation(emailV: any) {
    // eslint-disable-next-line
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!emailV || regex.test(emailV) === false) {
      return false;
    }
    return true;
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    // validation of the mail in the database and obtain the user
    switch (true) {
      case !!data.get('email'):
        if (data.get('email') === '') {
          toast.error(t('sign_up.email_empty_error'));
          break;
        }

        const emailIsValid = await emailValidation(data.get('email'));

        if (!emailIsValid) {
          toast.error(t('sign_up.valid_email_error'));
          break;
        }

        const userExists = await existUser(data.get('email'));
        setUserExistsLogin(userExists);
        localStorage.setItem('userExists', String(!!userExists));
        if (!userExists) { setNewUser(true); } else { setNewUser(false); }

        await handleNext('');
        break;

      case !!data.get('password'):
        if (data.get('password') === '') {
          toast.error(t('sign_up.password_empty_error'));
          break;
        }

        setPassword(data.get('password'));
        await handleNext(data.get('password'));
        break;

      case !!data.get('newPassword'):
        if (data.get('newPassword') === '') {
          toast.error(t('sign_up.password_empty_error'));
          break;
        }

        setNewPassword(data.get('newPassword'));
        await handleNext(data.get('newPassword'));
        break;

      case !!data.get('phone'):
        if (data.get('code') === '') {
          toast.error(t('sign_up.select_country'));
          break;
        }

        if (data.get('phone') === '') {
          toast.error(t('sign_up.phone_empty'));
          break;
        }

        setPhone(`${data.get('phone')}`);
        let codeInput = '+1';
        switch (data.get('code')) {
          case '1':
          case '2':
            codeInput = '+1';
            break;
          case '3':
            codeInput = '+52';
            break;
          default:
            break;
        }

        const _phone = `${codeInput}${data.get('phone')}`;
        const signUpObject = ({
          email,
          password,
          phone: _phone,
          code,
        });
        switch (appType) {
          case AuthType.Amplify:
            const attributes = { phone_number: _phone };
            updateProviderAttributes(attributes);
            break;
          case AuthType.CognitoUserExist:
            ResendCode();
            break;
          default:
            addUser(signUpObject);
            break;
        }

        await handleNext('');
        break;

      default:
        break;
    }

    if (data.get('code1') || data.get('code2') || data.get('code3') || data.get('code4') || data.get('code5') || data.get('code6')) {
      setCode(data.get('code6'));

      const codeFull = `${data.get('code1')}${data.get('code2')}${data.get('code3')}${data.get('code4')}${data.get('code5')}${data.get('code6')}`;

      const codeD = {
        email,
        code: codeFull,
      };

      fnConfirmAccount(codeD);
    }

    if (data.get('newCode6')) {
      setCode(data.get('newCode6'));

      const codeFull = `${data.get('newCode1')}${data.get('newCode2')}${data.get('newCode3')}${data.get('newCode4')}${data.get('newCode5')}${data.get('newCode6')}`;

      const codeD = {
        email,
        verificationCode: codeFull,
        newPassword,
      };

      const verifCode = await ForgotPasswordVerificationCode(codeD);
      if (verifCode) {
        logIn({ email, password: newPassword });
      } else {
        toast.error(t('global.invalid_confirmation_code'));
      }
    } else {
      switch (activeStep) {
        case 0:
          if (data.get('email') == '') {
            toast.error(t('sign_up.email_empty_error'));
          }
          break;
        case 1:
          if (data.get('password') == '') {
            toast.error(t('sign_up.password_empty_error'));
          }
          break;
        case 2:
          if (data.get('phone') == '') {
            toast.error(t('sign_up.phone_empty'));
          }
          break;
        case 4:
          if (data.get('newPassword') == '') {
            toast.error(t('sign_up.password_empty_error'));
          }
          break;
        default:
          break;
      }
    }
  };

  const updateEmail = async (e: string) => {
    setEmail(e);
  };

  const forgotPassword = async () => {
    setPassword('');
    setActiveStep(4);
  };

  useEffect(() => {
    if (activeStep > 0) {
      let container = '';
      switch (activeStep) {
        case 1:
          container = '#password';
          break;
        case 2:
          container = '#phone';
          break;
        case 3:
          container = '#code';
          setNewUser(true);
          break;
        case 4:
          container = '#forgotpassword';
          break;
        case 5:
          container = '#forgotpcode';
          break;
        default:
          // eslint-disable-next-line no-unused-expressions
          container;
          break;
      }
    }
  }, [activeStep]);

  const { t } = useTranslation('global');

  return (
    <>
      {tokens && tokens != ''
        ? (
          <noscript>
            <img
              height="1"
              width="1"
              style={{ display: 'none' }}
              src="https://flask.nextdoor.com/pixel?pid=5e620e54-13b7-446b-9999-957a0dcdf70a&ev=CONVERSION&noscript=1"
            />
          </noscript>
        ) : null}

      {activeStep == 0 && (
        <Box mt={3} sx={{ height: '44px' }}>
          <IconLinkButton
            kStartIcon={<ArrowBackIos />}
            kText={t('sign_up.back_homepage')}
            kFontSize={isMobile ? 'body2.fontSize' : 'body1.fontSize'}
            kFontWeight={isMobile ? '400' : 'bold'}
            kOnClick={() => history.push('/')}
          />
        </Box>
      )}
      <Box mt={3} sx={{ height: '44px' }}>
        {activeStep > 0
          && (
            <IconLinkButton
              kStartIcon={<ArrowBackIos />}
              kText={t('welcome.back')}
              kFontSize={isMobile ? 'body2.fontSize' : 'body1.fontSize'}
              kFontWeight={isMobile ? '400' : 'bold'}
              kOnClick={() => stepBack()}
            />
          )}
      </Box>
      {
        activeStep === 0
        && (
          <Container maxWidth="xs">
            <Box my={3} sx={{ width: '50%', maxWidth: '220px' }}>
              <Logo width={sizeLogo} iconColor="black" textColor="black" />
            </Box>
          </Container>
        )
      }

      <Container maxWidth="xs" component="form" noValidate onSubmit={handleSubmit}>
        {activeStep != 6 ? '' : <Picture />}
        <WelcomeText step={activeStep} user={userName} userExists={userExistsLogin} />
        <Box my={3}>
          <Form
            email={email}
            password={password}
            phone={phone}
            setPhone={setPhone}
            country={country}
            setCountry={setCountry}
            userExists={userExistsLogin}
            updateEmail={updateEmail}
            updatePassword={setPassword}
            forgotPassword={forgotPassword}
            step={activeStep}
          />
        </Box>
        <Box>
          <Button
            disabled={
              (activeStep == 0 && (!email || email?.length < 7))
              || ((activeStep == 1 || activeStep == 4) && (!password || password?.length < 8))
            }
            type="submit"
            fullWidth
            variant="contained"
            disableElevation
            size="large"
            sx={{
              my: activeStep === 3 ? 1 : 0, borderRadius: '50px', height: '56px', textTransform: 'none',
            }}
          >
            {activeStep === 3 ? `${t('welcome.verify')}` : `${t('sign_up.continue')}`}
          </Button>
          {activeStep === 6 && (
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              sx={{
                my: 1, borderRadius: '50px', height: '56px',
              }}
            >
              {t('global.skip')}
            </Button>
          )}
        </Box>
      </Container>
      {activeStep === 0 && <SocialButton />}
      {activeStep === 0 ? (<Footer />) : <Box my={5} />}
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 9999,
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          display: isBlocked ? 'block' : 'none',
        }}
      >
        <LoadingLayout />
      </Box>
    </>

  );
}

/* eslint-disable max-lines */
/* eslint import/no-unresolved: [2, { commonjs: true, amd: true }] */
import {
  useContext, useReducer,
} from 'react';
import AWS from 'aws-sdk';
import SocketContext from './socketContext';
import SocketReducer from './socketReducer';

import {
  CHAT_ALL_MESSAGES,
  CHAT_NEW_MESSAGE,
  CHAT_NOTIFICATIONS,
  CHAT_START_TASK,
  DELIVERY_ACTIVITY_SUCCESS_ROADIE,
  INIT_WS_CONNECTION,
} from '../../types';
import ActivityContext from '../activity/activityContext';

const v4 = require('@lendmeit/aws-signature-v4');

const SocketState = (props: any) => {
  const activityContext = useContext(ActivityContext);
  const {
    openedActivityItem,
  }: any = activityContext;
  const initialState = {
  };

  const [state, dispatch] = useReducer(SocketReducer, initialState);

  const initWebSocketConnection = async () => {
    const token = localStorage.getItem('token');
    const isOpenChat = localStorage.getItem('OpenChat');
    const { credentials }: any = AWS.config;
    const accessKeyId = credentials?.accessKeyId!;
    const secretAccessKey = credentials?.secretAccessKey!;
    const sessionToken = credentials?.sessionToken!;

    const url = v4.createPresignedURL(
      'GET',
      process!.env!.REACT_APP_SOCKET_DOMAIN!,
      process!.env!.REACT_APP_SOCKET_STAGE!,
      'execute-api',
      null,
      {
        key: accessKeyId,
        secret: secretAccessKey,
        sessionToken,
        protocol: 'wss',
        region: process!.env!.REACT_APP_REGION!,
        signSessionToken: true,
        doubleEscape: false,
        removeToken: false,
        query: `token=${token!}`,
      },
    );
    const ws = new WebSocket(
      url,
    );

    ws.onopen = (event: any) => {
      console.log(event);
      console.log('connected to socket');
      dispatch({
        type: INIT_WS_CONNECTION,
        payload: ws,
      });
    };

    ws.onmessage = (event: any) => {
      // alert(`[message] Data received from server: ${event.data}`);
      console.log(`[message] Data received from server: ${event.data}`);
      const response = JSON.parse(event.data);
      dispatch({
        type: CHAT_START_TASK,
      });
      switch (response.action) {
        case 'delivery_update':
          console.log('delivery update');
          break;
        case 'roadie_event':
          const deliveryId = response.roadie.data.id;

          const roadieDeliveryIdCollect = localStorage.getItem('roadieDeliveryIdCollect');
          const roadieDeliveryIdReturn = localStorage.getItem('roadieDeliveryIdReturn');

          if (deliveryId == roadieDeliveryIdCollect || deliveryId == roadieDeliveryIdReturn) {
            // if (deliveryId) {
          // roadie.event = 'shipment.driver_assigned' not
          // shipment.tracking_updated yes
            if (response?.roadie?.event != 'shipment.driver_assigned') {
              const lat = response?.roadie?.data?.latitude;
              const lng = response?.roadie?.data?.longitude;

              let collectLatLng = {};
              if (deliveryId == openedActivityItem?.roadieDeliveryIdCollect
              && lat != null && lng != null) {
                collectLatLng = {
                  lat,
                  lng,
                };
              }

              let returnLatLng = {};
              if (deliveryId == openedActivityItem?.roadieDeliveryIdReturn
              && lat != null && lng != null) {
                returnLatLng = {
                  lat,
                  lng,
                };
              }

              dispatch({
                type: DELIVERY_ACTIVITY_SUCCESS_ROADIE,
                payload: {
                  collectLatLng,
                  returnLatLng,
                  deliveryId,
                },
              });
            }
          }
          break;
        case 'notifications':
          dispatch({
            type: CHAT_NOTIFICATIONS,
            payload: response?.notifications,
          });
          break;
        case 'messages':
          dispatch({
            type: CHAT_ALL_MESSAGES,
            payload: response?.messages,
          });
          break;
        case 'message_received':
          const { message, rentId } = response;
          if (isOpenChat == 'true') {
            if (response?.rentId != openedActivityItem?.idCatLndRntItem) {
              dispatch({
                type: CHAT_NOTIFICATIONS,
                payload: { message, rentId },
              });
            } else {
              dispatch({
                type: CHAT_NEW_MESSAGE,
                payload: { message, rentId: '' },
              });
            }
          } else {
            dispatch({
              type: CHAT_NEW_MESSAGE,
              payload: { message, rentId },
            });
          }
          break;
        default:
          break;
      }
    };

    ws.onclose = (event: any) => {
      if (event.wasClean) {
        console.log(`[close] Socket Connection closed cleanly, code=${event.code} reason=${event.reason}`);
      } else {
        // e.g. server process killed or network down
        // event.code is usually 1006 in this case
        console.log('[close]Socket Connection died');
      }
    };

    ws.onerror = (error: any) => {
      console.log(`[error socket] ${error}`);
    };
  };

  return (
    <SocketContext.Provider
      value={{
        ...state,
        initWebSocketConnection,
      }}
    >
      {props.children}

    </SocketContext.Provider>
  );
};

export default SocketState;

import {
  Box, Stack, Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ActivityIdReservationStyle from './ActivityIdReservation.style';
// import { ReactComponent as DeliveryIcon } from '../../../../../../assets/icons/lmi_delivery_icon.svg';
import { LmiTheme, SectionTitle } from '../../../../../../components/Common';
import { ActivityIdReservationModels } from './ActivityIdReservation.models';

export default function ActivityIdReservation(props: ActivityIdReservationModels) {
  const { t } = useTranslation('global');
  return (
    <Box sx={ActivityIdReservationStyle}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" px={2} py={1}>
        <SectionTitle
          kMarginTop={0}
          kMarginBottom={0}
          kTitle={t('activity.id_reservation')}
          kFontSize={16}
        />
        <SectionTitle
          kMarginTop={0}
          kMarginBottom={0}
          kTitle={props.kId}
          kFontWeight="400"
          kFontSize={16}
          kColor={LmiTheme.light.systemBackground.secondary}
        />
      </Stack>
      <Divider />

    </Box>
  );
}

import * as React from 'react';
import {
  FormControlLabel,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Typography,
} from '@mui/material';
import { LmiTheme } from '..';
import { DialogAccessoriesItemModels } from './DialogAccessoriesItem.models';

function DialogAccessoriesItem(props: DialogAccessoriesItemModels) {
  const selectChange = props.kOnChange ? (e: React.ChangeEvent<HTMLInputElement>,
    { idRelItemAccesorie }: any) => props.kOnChange({ checked: e.target.checked, idRelItemAccesorie }) : null;

  return (
    <ListItem
      key={0}
      dense
      disablePadding
      divider={props.kDivider}
      sx={{
        px: 0,
        py: 1,
      }}
    >
      <ListItemText
        primaryTypographyProps={{ fontWeight: '500', fontSize: 'subtitle1.fontSize' }}
        secondaryTypographyProps={{ color: LmiTheme.light.systemBackground.tertiary, fontSize: 'subtitle2.fontSize' }}
        primary={props.kTitle}
        secondary={props.kDescription}
      />
      <ListItemSecondaryAction sx={{ top: 25 }}>
        <FormControlLabel
          value={props.kValue}
          control={(
            <Switch
              checked={props.kChecked}
              color="primary"
              onChange={(e: any) => {
                if (selectChange) { selectChange(e, props); }
              }}
            />
          )}
          label={
            (
              <Typography fontSize="subtitle1.fontSize" fontWeight={500}>
                $
                {props.kPrice}
                <> </>
                {' '}
                {props.kCurrency}
                {' '}
                {props.kPriceType}
              </Typography>
            )
          }
          labelPlacement="start"
          sx={{ fontWeight: '700' }}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default DialogAccessoriesItem;

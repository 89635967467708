import { LmiTheme } from '../../../../../../components/Common';

const ActivityReservationStyle = {
  width: '100%',
  height: '100%',
  borderRadius: '10px',
  // p: 2,
  '.MuiPaper-rounded': {
    borderRadius: '10px',
  },
  '.lmiw-reservation-image': {
    borderRadius: '10px',
    width: { sm: '100px', xs: '60px' },
    height: { sm: '100px', xs: '60px' },
  },
  '.lmiw-reservation-item': {
    fontSize: '16px',
    fontWeight: '500',
    color: LmiTheme.light.accent,
    lineBreak: 'anywhere',
  },
  '.lmiw-reservation-price': {
    fontSize: '16px',
    fontWeight: '500',
  },

  '.lmiw-reservation-desc': {
    fontSize: '14px',
    color: LmiTheme.light.systemBackground.secondary,
  },

  '.lmiw-reservation-accessories': {
    color: LmiTheme.light.systemBackground.secondary,
  },

};

export default ActivityReservationStyle;

import {
  Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';

function UnavailableCircle() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation('global');
  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      sx={{ p: '16px' }}
    >
      <InfoOutlinedIcon sx={{ color: '#1d2024' }} fontSize="medium" />
      <Typography sx={{ fontSize: isMobile ? '14px' : '16px', color: '#1d2024', pl: '12px' }}>{t('circles.detail.unavailable')}</Typography>
    </Stack>
  );
}

export default UnavailableCircle;

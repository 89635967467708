/* eslint-disable no-nested-ternary */
import { Stack } from '@mui/material';
import {
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';
import { useTranslation } from 'react-i18next';

function ShareSocialMedia(props: any) {
  const { t } = useTranslation('global');

  const shareCircleText = `${t('share.circle')}`;
  const shareItemText = `${t('share.item')} | ${props.itemName} | ${props.itemDescription}`;
  const shareCodeText = `${t('share.useMyCode1')} ${props.code} ${t('share.useMyCode2')}`;

  return (
    <>
      <Stack flexDirection="row" justifyContent="center" alignItems="center" gap={2}>
        <FacebookShareButton url={props.deepLink} quote={props.shareItem ? shareItemText : props.shareCode ? shareCodeText : shareCircleText}>
          <FacebookIcon size={50} round />
        </FacebookShareButton>
        <FacebookMessengerShareButton url={props.deepLink} appId="">
          <FacebookMessengerIcon size={50} round />
        </FacebookMessengerShareButton>
        <WhatsappShareButton url={props.deepLink} title={props.shareItem ? shareItemText : props.shareCode ? shareCodeText : shareCircleText}>
          <WhatsappIcon size={50} round />
        </WhatsappShareButton>
        <TwitterShareButton url={props.deepLink} title={props.shareItem ? shareItemText : props.shareCode ? shareCodeText : shareCircleText}>
          <TwitterIcon size={50} round />
        </TwitterShareButton>
      </Stack>
    </>
  );
}

export default ShareSocialMedia;

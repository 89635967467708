/* eslint-disable max-lines */
/* eslint-disable no-nested-ternary */
import {
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MyCirclesListingContext from '../../../context/myCirclesListing/myCirclesListingContext';
import {
  ContentLayout,
  LoadingLayout,
} from '../../../components/Common';
// import EmptyCircles from './EmptyCircles/EmptyCircles';
import SideBarCircles from './SideBarCircles/SideBarCircles';
import SecondaryBarCircles from './SecondaryBarCircles/SecondaryBarCircles';
import { CircleDetailInfoModel, emptyCircleModel } from './AddCircle/AddCircle.models';
import AddEditCirclesContext from '../../../context/circles/addEditCirclesContext';
import CirclesAppBarSecondary from './Components/CirclesAppBarSecondary/CirclesAppBarSecondary';
import CirclesMapView from './CirclesMapView/CirclesMapView';

export default function Circles() {
  const [openCircleDetail, setOpenCircleDetail] = useState(false);
  const [openReportCircleModal, setOpenReportCircleModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchedCirclesInfo, setSearchedCirclesInfo] = useState(false);
  const [circlesFound, setCirclesFound] = useState(true);
  const [areSuggestedCircles, setAreSuggestedCircles] = useState(false);
  const [areCirclesIBelong, setAreCirclesIBelong] = useState(false);
  const [hasCircles, setHasCircles] = useState(false);
  const [firstTitle, setFirstTitle] = useState('My Circles');
  const [secondTitle, setSecondTitle] = useState('Circles to which I belong');
  const [showSearchedCircles, setShowSearchedCircles] = useState([]);
  const [openMapView, setOpenMapView] = useState(false);

  const {
    circle,
    circles,
    images,
    details,
    isLoadedCircles,
    getMyCircles,
    setCircleListingToShow,
    circlesTypeToShow,
    suggestedCircles,
  }: any = useContext(MyCirclesListingContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [openSearchCircle, setOpenSearchCircle] = useState(isMobile ? false : circles?.length === 0);

  const circlesContextLog = useContext(AddEditCirclesContext);
  const {
    circleUpdated,
  }: any = circlesContextLog;

  const history = useHistory();
  const { t } = useTranslation('global');

  useEffect(() => {
    (async function fetchCircles() {
      await getMyCircles();
    }());
  }, []);

  useEffect(() => {
    if (circleUpdated) {
      (async function fetchCircles() {
        await getMyCircles();
      }());
    }
  }, [circleUpdated]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getId = localStorage.getItem('circleIdActivity');
    if (circles && getId) {
      const findIndex = circles.findIndex((c: any) => c.idCatLndCircle === parseInt(getId, 10));
      const findCircle = circles.find((c: any) => c.idCatLndCircle !== getId);
      if (findIndex !== -1) {
        if (findCircle) {
          (async function fetchCircleDetails() {
            await details(circles[findIndex], []);
          }());
        }
      }
    } else {
      // eslint-disable-next-line
      if (circles && !circle) {
        (async function fetchCircleDetails() {
          await details(circles[state?.kCircleIndex ? state.kCircleIndex : 0], []);
        }());
      } else if ((circles || suggestedCircles) && circle) {
        let circlesToIndex = [];
        let findIndex = -1;
        if (circles.length > 0) {
          circlesToIndex = circles;
          findIndex = circles.findIndex((c: any) => c.idCatLndCircle === parseInt(circle.idCatLndCircle, 10));
        }
        if (suggestedCircles.length > 0 && findIndex < 0) {
          circlesToIndex = suggestedCircles;
          findIndex = suggestedCircles.findIndex((c: any) => c.idCatLndCircle === parseInt(circle.idCatLndCircle, 10));
        }
        if (circle?.idCatLndCircle !== circlesToIndex[findIndex > -1 ? findIndex : 0]?.idCatLndCircle) {
          (async function fetchCircleDetails() {
            await details(circlesToIndex[findIndex > -1 ? findIndex : 0], []);
          }());
        }
      } else if (circle) {
        if (circle?.idCatLndCircle !== circles[state?.kCircleIndex ? state.kCircleIndex : 0]?.idCatLndCircle) {
          (async function fetchCircleDetails() {
            await details(circles[state?.kCircleIndex ? state.kCircleIndex : 0], []);
          }());
        }
      }
    }
  }, [circles]);

  useEffect(() => {
    if (circlesTypeToShow == 'suggested') {
      if (!isMobile) {
        setOpenSearchCircle(true);
      }
      setFirstTitle(t('circles.main.my_circles'));
      setSecondTitle(t('circles.main.suggested_circles'));
    } else if (circlesTypeToShow == 'searched') {
      setFirstTitle(t('circles.main.searched'));
      setSecondTitle('');
    } else {
      setOpenSearchCircle(false);
      setFirstTitle(t('circles.main.my_circles'));
      setSecondTitle(t('circles.main.i_belong'));
    }
  }, [circlesTypeToShow]);

  const state = history.location.state as {
    kOpenActivity?: boolean;
    kCircleId: string,
    kCircleIndex: number,
    updateSwiper: false,
    kShowDetailsMobile: false
  };

  const handleOpenMapView = (value: boolean) => setOpenMapView(value);
  const handleOpenCircleDetail = (value: boolean) => setOpenCircleDetail(value);
  const handleOpenReportCircleModal = (value: boolean) => setOpenReportCircleModal(value);
  const handleOpen = (value: boolean) => setOpen(value);
  const handleOpenSearchCircle = (value: boolean) => setOpenSearchCircle(value);
  const handleSearchedCirclesInfo = (value: boolean) => setSearchedCirclesInfo(value);
  const handleCirclesFound = (value: boolean) => setCirclesFound(value);
  const handleAreSuggestedCircles = (value: boolean) => setAreSuggestedCircles(value);
  const handleAreCirclesIBelong = (value: boolean) => setAreCirclesIBelong(value);
  const handleHasCircles = (value: boolean) => setHasCircles(value);
  const handleShowSearchedCircles = (value: any) => setShowSearchedCircles(value);

  const changeCircleListingToShow = (circleType: string) => {
    setCircleListingToShow(circleType);
  };

  const onChange = (circleSelected: CircleDetailInfoModel, index: number) => {
    if (circle?.idCatLndCircle != circleSelected.idCatLndCircle) {
      details(circleSelected, images);
      window.scrollTo(0, 0);
    }

    const historyMobileState = {
      kCircleId: circle?.idCatLndCircle,
      kItemIndex: index,
    };

    const historyState = {
      kItemId: circle?.idCatLndCircle,
      kCircleIndex: index,
    };

    const historyProps = isMobile ? historyMobileState : historyState;

    history.push({
      state: {
        ...state,
        ...historyProps,
        updateSwiper: true,
        kShowDetailsMobile: true,
        kOpenActivity: false,
      },
    });
    localStorage.removeItem('circleIdActivity');
    setOpenMapView(false);
  };

  const openAddCirclePage = () => {
    window.scrollTo(0, 0);
    details(emptyCircleModel(), []);
    history.push({
      pathname: '/circles/new',
      state: {
        ...state,
        kCircleId: 0,
        kCircleIsNew: true,
        kCircleIndex: state?.kCircleIndex ? state.kCircleIndex : 0,
        updateSwiper: false,
        kShowDetailsMobile: false,
      },
    });
  };

  const closeDialogModal = () => {
    setOpenReportCircleModal(false);
    setOpen(false);
  };

  const [usrLat, setUserLat] = useState('');
  const [usrLon, setUsrLon] = useState('');
  const [longAddress, setLongAddress] = useState('');

  useEffect(() => {
    const localLat = localStorage.getItem('lat');
    const localLon = localStorage.getItem('lng');
    const address = localStorage.getItem('longAddress');
    setUserLat(localLat || '');
    setUsrLon(localLon || '');
    setLongAddress(address || '');
  }, []);

  return (
    <>
      {isLoadedCircles !== 'loaded' && <LoadingLayout />}
      <ContentLayout kPaddingXs={0}>
        <Grid container>
          <>
            <CirclesAppBarSecondary
              openSearchCircle={openSearchCircle}
              openMapView={openMapView}
              openCircleDetail={openCircleDetail}
              openReportCircleModal={openReportCircleModal}
              open={open}
              searchedCirclesInfo={searchedCirclesInfo}
              handleOpen={handleOpen}
              handleOpenMapView={handleOpenMapView}
              openDetails={handleOpenCircleDetail}
              handleCirclesFound={handleCirclesFound}
              handleOpenReportCircleModal={handleOpenReportCircleModal}
              changeCircleListingToShow={changeCircleListingToShow}
              handleOpenSearchCircle={handleOpenSearchCircle}
              handleSearchedCirclesInfo={handleSearchedCirclesInfo}
            />
            <SideBarCircles
              isMobile={isMobile}
              viewMap={openMapView}
              firstTitle={firstTitle}
              secondTitle={secondTitle}
              showSearchedCircles={showSearchedCircles}
              circlesFound={circlesFound}
              longAddress={longAddress}
              openCircleDetail={openCircleDetail}
              openSearchCircle={openSearchCircle}
              searchedCirclesInfo={searchedCirclesInfo}
              areSuggestedCircles={areSuggestedCircles}
              areCirclesIBelong={areCirclesIBelong}
              hasCircles={hasCircles}
              onChange={onChange}
              openDetails={handleOpenCircleDetail}
              handleViewMap={handleOpenMapView}
              handleOpenMapView={handleOpenMapView}
              handleShowSearchedCircles={handleShowSearchedCircles}
              openAddCirclePage={openAddCirclePage}
              handleCirclesFound={handleCirclesFound}
              handleOpenSearchCircle={handleOpenSearchCircle}
              handleSearchedCirclesInfo={handleSearchedCirclesInfo}
              changeCircleListingToShow={changeCircleListingToShow}
              handleAreSuggestedCircles={handleAreSuggestedCircles}
              handleAreCirclesIBelong={handleAreCirclesIBelong}
              handleHasCircles={handleHasCircles}
            />
            {
              !openMapView ? (
                <SecondaryBarCircles
                  open={open}
                  hasCircles={hasCircles}
                  openCircleDetail={openCircleDetail}
                  openSearchCircle={openSearchCircle}
                  openReportCircleModal={openReportCircleModal}
                  handleOpen={handleOpen}
                  closeDialogModal={closeDialogModal}
                  openAddCirclePage={openAddCirclePage}
                  handleOpenReportCircleModal={handleOpenReportCircleModal}
                />
              ) : (
                <CirclesMapView
                  openMapView={openMapView}
                  handleViewMap={handleOpenMapView}
                  showSearchedCircles={showSearchedCircles}
                  usrLat={usrLat}
                  usrLon={usrLon}
                  kOnMarkerClick={onChange}
                />
              )
            }
          </>
        </Grid>
      </ContentLayout>
    </>
  );
}

import {
  ListItemButton, Avatar, ListItemAvatar, ListItem, List, Typography, Stack, Badge,
  // useTheme, useMediaQuery,
} from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { MyListingModels } from './MyListing.models';
import MyListingContext from '../../../../../context/myListing/myListingContext';
import { AddItemInfoModel } from '../AddItem/AddItem.models';
import LmiTheme from '../../../../../themes/lmi-mui-theme/lmi-theme';

export default function MyListing(props: MyListingModels) {
  const { t } = useTranslation('global');
  const {
    item,
    items,
    loading,
  }: any = useContext(MyListingContext);

  return (
    <>
      <List
        disablePadding
        sx={{
          width: '100%',
          bgcolor: 'background.paper',
          'li:last-child': {
            pb: 8,
          },
        }}
      >

        {
          items && items.length > 0
            ? items.map((listItem: AddItemInfoModel, index: number) => (
              <ListItem
                disabled={loading}
                alignItems="flex-start"
                disablePadding
                key={listItem.idCatLndItem}
              >
                <ListItemButton
                  divider
                  onClick={() => (!loading ? props.onChange(listItem, index) : null)}
                  sx={{
                    maxHeight: '90px',
                    backgroundColor: (item && item.idCatLndItem === listItem.idCatLndItem) ? LmiTheme.light.accentActiveBackground : '',
                  }}
                >
                  <ListItemAvatar sx={{ marginRight: '2px' }}>
                    <Avatar
                      sx={{
                        width: 74,
                        height: 74,
                        marginRight: 1,
                        borderRadius: 2,
                        border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
                      }}
                      variant="square"
                      alt={listItem.name}
                      src={listItem.itemPicture}
                    />
                  </ListItemAvatar>
                  <Stack
                    direction="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    sx={{ width: 'calc(100% - 50px)' }}
                  >
                    <Typography
                      component="label"
                      noWrap
                      fontSize={18}
                      fontWeight="500"
                      sx={{
                        textTransform: 'capitalize',
                        width: '100%',
                        maxWidth: { md: '150px', lg: '210px' },
                        display: 'block',
                      }}
                    >
                      {listItem.name ? listItem.name : 'No title'}
                    </Typography>
                    <Typography
                      component="p"
                      fontSize={14}
                      color={LmiTheme.light.systemBackground.secondary}
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                      }}
                    >
                      {listItem.dscription ? listItem.dscription : `${t('profile.no_description')}`}
                    </Typography>
                  </Stack>
                  {
                    listItem.blocked && !listItem.sold ? (
                      <Badge
                        overlap="circular"
                        variant="dot"
                        sx={{
                          '.MuiBadge-badge': {
                            backgroundColor: LmiTheme.light.secondaryColor.red,
                            height: '16px',
                            width: '16px',
                            borderRadius: '50%',
                            mr: '12px',
                            zIndex: 0,
                          },
                        }}
                      />
                    ) : (
                      <Avatar sx={{
                        width: 25,
                        height: 25,
                        bgcolor: listItem.salePrice == 0 ? LmiTheme.light.systemBackground.tertiary : LmiTheme.light.dialog.success,
                      }}
                      >

                        <AttachMoneyIcon sx={{ fontSize: 15 }} />
                      </Avatar>
                    )
                  }

                  {/* <ListItemText
                  primary={(
                    <Typography
                      component="label"
                      noWrap
                      fontSize={18}
                      fontWeight="500"
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {listItem.name ? listItem.name : 'No title'}
                    </Typography>
                        )}
                  secondary={(
                    <Typography
                      component="p"
                      fontSize={14}
                      color={LmiTheme.light.systemBackground.secondary}
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                      }}
                    >
                      {listItem.dscription ? listItem.dscription : 'No description'}
                    </Typography>
                        )}
                /> */}

                </ListItemButton>
              </ListItem>
            ))
            : <h1>Loading...</h1>
        }
      </List>
    </>
  );
}

import { RoadieVehicle } from './roadie_vehicle';

export interface RoadieDriver {

  name: string;
  phone: string;
  vehicleType: string;
  vehicleColor: string;
  vehicle: RoadieVehicle;
}

export const roadieDriverFromJson = (roadieDriver: any) => {
  const objModel: any = {
    name: roadieDriver.roadie_driver_name,
    phone: roadieDriver.roadie_driver_phone,
    vehicleType: roadieDriver.roadie_vehicle_type,
    vehicleColor: roadieDriver.roadie_vehicle_color,
  };
  return objModel;
};

import {
  Box, Typography, Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LmiTheme } from '../../../../../../components/Common';

export default function ManageCirclesEmpty() {
  const { t } = useTranslation('global');

  return (
    <Box>
      <Stack
        p={0}
        direction="row"
        justifyContent="space-between"
        spacing={3}
      >
        <Typography
          fontSize="17px"
          color={LmiTheme.light.systemBackground.tertiary}
        >
          {t('circles_card.no_circles')}
        </Typography>
      </Stack>
    </Box>
  );
}

import * as React from 'react';
import {
  // Radio, ,
  Box, Stack, Divider, FormControl, OutlinedInput, IconButton, CircularProgress,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
// import io from 'socket.io-client';
import * as uuid from 'uuid';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ActivityChatStyle from './ActivityChat.style';
// import { InputForm } from '../../../../components/Common';
import { ActivityChatModels } from './ActivityChat.models';
// import MessageLeft from './ActivityChatMessageLeft/ActivityChatMessageLeft';
import ChatMessage from './ActivityChatMessage/ActivityChatMessage';
import ActivityContext from '../../../../context/activity/activityContext';
import publicProfileContext from '../../../../context/publicProfile/publicProfileContext';
import SocketContext from '../../../../context/socket/socketContext';

export default function ActivityChat(props: ActivityChatModels) {
  // const [selectedValue, setSelectedValue] = React.useState('a');
  const [value, setValue] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [messages, setMessages] = React.useState<any>(null);
  const token = localStorage.getItem('token');
  const activityContext = React.useContext(ActivityContext);
  const { openedActivityItem }: any = activityContext;
  const pProfileContext = React.useContext(publicProfileContext);
  const { privateProfileInfo }: any = pProfileContext;
  const socketContext = React.useContext(SocketContext);
  const {
    ws,
    allMessages,
    newMessage,
    message_received,
  }: any = socketContext;

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSelectedValue(event.target.value);
  // };

  React.useEffect(() => {
    if (openedActivityItem?.tokenizedRecord != null && openedActivityItem?.tokenizedRecord != '') {
      console.log(props);
      ws.send(JSON.stringify({
        action: 'messages',
        ownToken: token,
        tokenizedRecord: openedActivityItem?.tokenizedRecord,
      }));
    }
  }, [openedActivityItem]);

  React.useEffect(() => {
    if (allMessages) {
      setMessages(allMessages);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
    const handleNewMessage = () => {
      const newArray = messages;
      setMessages(() => [...newArray, message_received]);
    };
    if (newMessage && messages != null) handleNewMessage();
  }, [allMessages, newMessage, message_received]);

  const { t } = useTranslation('global');

  const sendMessage = () => {
    if (value != '') {
      const messageId = uuid.v4();
      const sendNewMessage = {
        id: messageId,
        message: value,
        userId: privateProfileInfo.idCatLndUser,
        date: new Date().getTime(),
      };
      setValue('');
      setMessages((current: any) => [...current, sendNewMessage]);
      ws.send(JSON.stringify({
        action: 'send-message',
        ownToken: token,
        tokenizedRecord: openedActivityItem?.tokenizedRecord,
        messageId: sendNewMessage.id,
        message: sendNewMessage.message,
      }));
    }
  };

  function ActiveChat(data: any) {
    let isActive = false;
    if (data?.notifActionType == 'cancelled' || data?.notifActionType == 'rejected'
    || data?.notifActionType == 'review') {
      isActive = true;
    }
    return isActive;
  }

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={ActivityChatStyle}
    >
      <Box width="100%">
        {loading
          ? (
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <CircularProgress />
            </Stack>
          ) : (
            <>
              {messages?.length > 0 ? (
                messages?.map((data: any) => (
                  <ChatMessage
                    kMessage={data.message}
                    kTimestamp={moment(new Date(data.date)).format('hh:mm a')}
                    kAlign={data.userId == privateProfileInfo.idCatLndUser ? 'right' : 'left'}
                  />
                ))
              )
                : (
                  <>
                    {
                    openedActivityItem?.notifType == 'RENT'
                      ? t('activity.chat.any_message_renter')
                      : t('activity.chat.any_message_lender')
                    }
                  </>
                )}
            </>
          )}

      </Box>
      <Divider sx={{ width: '100%' }} />
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={{ lg: 2, xs: 1 }}
        sx={{ width: '100%' }}
      >
        <FormControl
          className="lmi-formcontrol"
          onChange={() => {}}
          onKeyDown={() => {}}
          sx={{ width: '100%' }}
        >
          <OutlinedInput
            sx={{ width: '100%' }}
            fullWidth
            value={value}
            disabled={ActiveChat(openedActivityItem)}
            onFocus={() => {}}
            onBlur={() => { }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                sendMessage();
              }
            }}
            onChange={(e: any) => {
              const iAmWriting = e.target.value != '';
              ws.send(JSON.stringify({
                action: 'write-message',
                ownToken: token,
                tokenizedRecord: openedActivityItem?.tokenizedRecord,
                write: iAmWriting,
              }));
              setValue(e.target.value);
            }}
          />

        </FormControl>
        <IconButton
          disabled={ActiveChat(openedActivityItem)}
          sx={{ display: 'flex' }}
          onClick={() => {
            sendMessage();
          }}
        >
          <SendIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
}

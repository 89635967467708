import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Box,
  Typography,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import LmiTheme from '../../../../../themes/lmi-mui-theme/lmi-theme';
import { ItemToLendModels } from './ItemToLend.models';

function ItemToLend(props: ItemToLendModels) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      <ListItem sx={{ pb: 3 }}>
        <ListItemAvatar>
          <Avatar variant="rounded" alt={props.kItemName} src={props.kItemPhoto} sx={{ width: isDesktop ? 150 : 74, height: isDesktop ? 150 : 74 }} />
        </ListItemAvatar>
        <ListItemText>
          <>
            <Stack
              direction={isDesktop ? 'row' : 'column'}
              justifyContent="space-between"
              alignItems={isDesktop ? 'center' : 'flex-start'}
              spacing={1}
              pl={3}
            >
              <Typography sx={{ fontSize: 'h6.fontSize', fontWeight: '500' }}>
                {props.kItemName}
              </Typography>
              <Typography sx={{ fontSize: 'h6.fontSize', fontWeight: 'bold' }}>
                $
                {props.kItemPrice}
                <> </>
                {' '}
                {props.kCurrency}
                {' '}
                {props.kItemPriceType}
              </Typography>
            </Stack>
            {
              isDesktop && (
                <Box px={3} py={1}>
                  <Typography sx={{ fontSize: 'body2.fontSize', color: LmiTheme.light.systemBackground.tertiary }}>
                    {props.kItemDescription}
                  </Typography>
                </Box>
              )
            }
            {
              isDesktop && (
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                  pl={3}
                >
                  <Avatar alt={props.kLenderName} src={props.kLenderPhoto} />
                  <> </>
                  <Typography sx={{ fontSize: 'body1.fontSize', fontWeight: 'bold', color: LmiTheme.light.systemBackground.secondary }}>
                    {props.kLenderName}
                  </Typography>
                </Stack>
              )
            }
          </>
        </ListItemText>
      </ListItem>
    </>
  );
}

ItemToLend.defaultProps = {
  kItemName: '--',
  kItemPrice: 0.00,
  kItemPriceType: '--',
  kItemPhoto: '--',
  kItemDescription: '--',
  kLenderPhoto: '--',
  kLenderName: '--',
};

export default ItemToLend;

import {
  Box, Typography, Paper, Stack, Avatar, ListItemAvatar, Button, Fab,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import ActivityUserStyle from './ActivityUser.style';
import { Verified, ThumbsUp } from '../../../../../../assets';
import { LmiTheme, SectionTitle } from '../../../../../../components/Common';
import { ActivityUserModels } from './ActivityUser.models';
import BtnStyle from '../../Circle/BtnStyle.style';
import SocketContext from '../../../../../../context/socket/socketContext';

export default function ActivityUser(props: ActivityUserModels) {
  const socketContext = useContext(SocketContext);
  const { ws }: any = socketContext;
  const history = useHistory();
  function visibleToOthersName(userFullname: String) {
    let _visibleToOthersName: String;
    let names: any = [];
    try {
      names = userFullname.toString().split(' ');

      _visibleToOthersName = `${capitalizeFirstLetter(names[0])} ${names[1].substring(0, 1).toUpperCase()}.`;
    } catch (err: any) {
      if (err.message == 'Value not in range') {
        try {
          _visibleToOthersName = `${capitalizeFirstLetter(names[0])} ${names[2].substring(0, 1).toUpperCase()}.`;
        } catch (error: any) {
          _visibleToOthersName = userFullname;
        }
      } else {
        _visibleToOthersName = userFullname;
      }
    }

    return _visibleToOthersName;
  }

  function capitalizeFirstLetter(word: String) {
    return `${word.substring(0, 1).toUpperCase()}${word.substring(1).toLowerCase()}`;
  }

  function ActiveChat(data: any) {
    let isActive = true;
    if (data.notifActionType == 'approve' || data.notifActionType == 'new_rental') {
      isActive = false;
    }
    return isActive;
  }

  const { t } = useTranslation('global');

  return (
    <Box sx={ActivityUserStyle}>
      {props.notifDetail?.notifActionType == 'joincirclerequest'
        && (
          <SectionTitle
            kMarginTop={2}
            kTitle="This user wants to join your circle"
            kFontSize={18}
          />
        )}
      {(props.notifDetail?.notifType == 'RENT' && props.notifDetail?.notifActionType != 'joincirclerequest') && (
        <SectionTitle
          kMarginTop={2}
          kTitle={t('activity.user.about_lender')}
          kFontSize={18}
        />
      )}

      {(props.notifDetail?.notifType != 'RENT' && props.notifDetail?.notifActionType != 'joincirclerequest') && (
        <SectionTitle
          kMarginTop={2}
          kTitle={t('activity.user.about_renter')}
          kFontSize={18}
        />
      )}
      <Paper className="lmiw-activity-user" variant="outlined" elevation={0}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <ListItemAvatar>
              <Avatar
                src={props.userInfo?.lenderPicture}
                alt="username"
                sx={{ width: { md: 100, xs: 65 }, height: { md: 100, xs: 65 } }}
              />
            </ListItemAvatar>
            <Box>
              {
                (props.userInfo?.nickname == ' ' || props.userInfo?.nickname == '')
                  ? (
                    <Typography className="lmiw-user-name" mb={0.5}>
                      {t('activity.user.user')}
                    </Typography>
                  )
                  : (
                    <Typography className="lmiw-user-name" mb={0.5}>
                      {visibleToOthersName(props.userInfo?.nickname)}
                    </Typography>
                  )

              }
              <Stack
                direction="row"
                className="lmiw-user-like"
              >
                <ThumbsUp />
                {
                  props.userInfo?.sumRatesReceived?.toFixed(2) > 0
                    ? (
                      <Typography fontSize={14}>
                        {props.userInfo?.sumRatesReceived.toFixed(2)}
                        {t('profile.recommended')}
                      </Typography>
                    )
                    : (
                      <Typography fontSize={14}>
                        100
                        {t('profile.recommended')}
                      </Typography>
                    )
                }
              </Stack>
              {
                (props.userInfo?.lenderVerified
                  || props.userInfo?.lenderVerifiedShufti
                  || props.userInfo?.lenderVerifiedStripe
                  || props.userInfo?.renterVerified
                  || props.userInfo?.renterVerifiedShufti
                  || props.userInfo?.renterVerifiedStripe)
                && (
                  <Stack
                    direction="row"
                    className="lmiw-user-verified"
                  >
                    <Verified />
                    <Typography>
                      {t('profile.id_verified')}
                    </Typography>
                  </Stack>
                )
              }
            </Box>
          </Stack>
          <Button
            sx={BtnStyle}
            variant="outlined"
            fullWidth={false}
            color="primary"
            className="lmiw-btn-login"
            onClick={() => {
              history.push('/public-profile', {
                publicProfileId: props.userInfo?.idCatLndUser,
              });
            }}
          >
            {t('profile.view_profile')}
          </Button>
          {props.notifDetail?.notifType == 'RENT' || props.notifDetail?.notifType == 'LEND' ? (
            <>
              <Fab
                aria-label="like"
                className={ActiveChat(props.notifDetail) ? 'lmiw-user-message-icon' : 'lmiw-user-disabled-message-icon'}
                sx={{ display: 'flex' }}
                onClick={() => {
                  const isActiveChat = ActiveChat(props.notifDetail);
                  if (isActiveChat) {
                    props.kOpenChat();
                    ws.send(JSON.stringify({
                      action: 'remove-notification',
                      rentId: props.notifDetail.idCatLndRntItem,
                    }));
                  }
                }}
              >
                <ChatBubbleOutlineIcon
                  sx={{ color: ActiveChat(props.notifDetail) ? LmiTheme.light.accent : LmiTheme.light.systemBackground.separator }}
                />
              </Fab>
            </>
          ) : null}
        </Stack>
      </Paper>
    </Box>
  );
}

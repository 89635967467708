/* eslint-disable no-nested-ternary */
import {
  Button, FormControl, IconButton, InputAdornment, List, ListItemButton, ListItemIcon, OutlinedInput, Paper, Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { LocationOnRounded } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import React, { useContext, useEffect, useState } from 'react';
import LmiTheme from '../../../../../themes/lmi-mui-theme/lmi-theme';
import MyCirclesListingContext from '../../../../../context/myCirclesListing/myCirclesListingContext';
import { CircleTopBarModels } from './CircleTopBar.models';
import NoCirclesFound from '../NoCirclesFound.tsx/NoCirclesFound';

function CircleTopBar(props: CircleTopBarModels) {
  const {
    getCirclesSearch,
    setCircleListingToShow,
  }: any = useContext(MyCirclesListingContext);

  const changeCircleListingToShow = (circleType: string) => {
    setCircleListingToShow(circleType);
  };

  const [search, setSearch] = React.useState('');

  const [searchedCircles, setSearchedCircles] = useState<string[]>([]);
  const [areSuggestionsVisible, setAreSuggestionsVisible] = useState(false);

  useEffect(() => {
    const savedLocations = localStorage.getItem('searchedCircles');
    if (savedLocations) {
      const parsedLocations = JSON.parse(savedLocations);
      if (Array.isArray(parsedLocations)) {
        setSearchedCircles(parsedLocations.slice(-3));
      }
    }
  }, []);

  const handleSearch = () => {
    console.log(search);
    getCirclesSearch(search);

    if (search.trim() === '') {
      return;
    }

    const newSearchedCircle = search;
    const isAlreadySearched = searchedCircles.includes(newSearchedCircle);
    if (!isAlreadySearched) {
      const latestSearchedCircles = searchedCircles.concat(newSearchedCircle).slice(-3);
      setSearchedCircles(latestSearchedCircles);
      localStorage.setItem('searchedCircles', JSON.stringify(latestSearchedCircles));
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearch(value);

    if (value === '') {
      setAreSuggestionsVisible(false);
    } else {
      setAreSuggestionsVisible(true);
    }
  };

  const renderSuggestions = () => (search === '' || searchedCircles.length > 0)
    && searchedCircles.map((suggestion) => (
      <ListItemButton
        component="li"
        key={suggestion}
        onClick={() => {
          setSearch(suggestion);
          getCirclesSearch(suggestion);
          changeCircleListingToShow('searched');
          props.kSetSearchedCircles(true);
        }}
      >
        <ListItemIcon sx={{ minWidth: '34px' }}>
          <SearchIcon />
        </ListItemIcon>
        <Typography component="label" variant="subtitle2" sx={{ fontWeight: '300' }}>
          {suggestion}
        </Typography>
      </ListItemButton>
    ));

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation('global');

  return (
    <>
      <Stack flexDirection="column" spacing="16px">
        {
          props.kSearchedCirclesSection ? (
            <>
              <Stack flexDirection="column">
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ mt: '12px' }}
                >
                  <FormControl
                    className="lmi-formcontrol"
                    onChange={handleInputChange}
                    sx={{ width: '100%', mr: '10px' }}
                  >
                    <OutlinedInput
                      sx={{ borderRadius: '10px', height: '48px' }}
                      inputProps={{
                        maxLength: 50,
                      }}
                      value={search}
                      onFocus={() => {
                        setTimeout(() => {
                          setAreSuggestionsVisible(true);
                        }, 100);
                      }}
                      onBlur={() => {
                        setTimeout(() => {
                          setAreSuggestionsVisible(false);
                        }, 100);
                      }}
                      fullWidth
                      className="lmi-input-search"
                      startAdornment={(
                        <InputAdornment position="start">
                          <SearchRoundedIcon />
                        </InputAdornment>
                      )}
                      placeholder={t('circles.main.search_circle')}
                      endAdornment={(
                        search?.length > 0 && (
                          <InputAdornment position="end">
                            <IconButton color="primary" onClick={() => { setSearch(''); }}>
                              <CancelIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      )}
                    />
                  </FormControl>
                  {
                    areSuggestionsVisible && searchedCircles.length > 0 && search == ''
                    && (
                      <Paper
                        variant="outlined"
                        sx={{
                          width: '80%',
                          bgcolor: 'background.paper',
                          mt: searchedCircles.length == 1 ? '120px' : searchedCircles.length == 2 ? '160px' : '200px',
                          position: 'absolute',
                          zIndex: 333,
                        }}
                      >
                        <List component="ul">
                          {renderSuggestions()}
                        </List>
                      </Paper>
                    )
                  }
                  <Button
                    onClick={() => {
                      // button search when input available
                      handleSearch();
                      changeCircleListingToShow('searched');
                      props.kSetSearchedCircles(true);
                    }}
                    variant="outlined"
                    sx={{
                      minWidth: '90px',
                      height: '48px',
                      borderRadius: 3,
                      fontWeight: 'bold',
                      textTransform: 'capitalize',
                      border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
                    }}
                  >
                    {t('global.search')}
                  </Button>
                </Stack>
              </Stack>
              {
                isMobile
                && props.kSearchedCircles
                && props.kShowSearchedCircles?.length != 0
                && props.kShowSearchedCircles != undefined
                && (
                  <Stack flexDirection="column">
                    <Stack
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Stack flexDirection="row" alignItems="center">
                        <LocationOnRounded
                          color="primary"
                          fontSize="medium"
                          sx={{ mr: '8px' }}
                        />
                        <Stack
                          flexDirection="column"
                          alignItems="start"
                        >
                          <Button
                            variant="text"
                            color="primary"
                            sx={{
                              height: '15px',
                              p: 0,
                              textTransform: 'none',
                            }}
                            onClick={() => { props.kHandleOpenMapView(true); }}
                          >
                            {t('circles.main.view_on_map')}
                          </Button>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                )
              }
              {
                (props.kShowSearchedCircles?.length == 0 && props.kSearchedCircles)
                && (
                  <NoCirclesFound />
                )
              }
            </>
          ) : <></>
        }
        <Typography component="span" fontSize={14} sx={{ textTransform: 'none', color: '#999da2' }}>
          {props.kFirstTitle}
        </Typography>
      </Stack>
    </>
  );
}

export default CircleTopBar;

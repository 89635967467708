import React, { useContext } from 'react';
import { v4 as uuid } from 'uuid';
import {
  Box, Typography, Button, Stack, Divider, useTheme, useMediaQuery, Chip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { DialogCommon, LmiTheme } from '../../../../../../components/Common';
import AddCircleTags from './AddCircleTags/AddCircleTags';
import AddEditCirclesContext from '../../../../../../context/circles/addEditCirclesContext';

export default function CircleTags(props: any) {
  const addEditCirclesContextLog = useContext(AddEditCirclesContext);
  const [open, setOpen] = React.useState(false);
  const [isSuggestionVisible, setIsSuggestionVisible] = React.useState(false);

  const {
    circleInfoEdit,
    removeTag,
    errors,
    addNewTag,
  }: any = addEditCirclesContextLog;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const splitTags = circleInfoEdit?.tags.length > 0 ? circleInfoEdit?.tags.split(',') : [];

  const [tagSearch, setTagSearch] = React.useState('');

  const handleModalToggle = () => {
    setTagSearch('');
    setOpen(!false);
  };

  const closeDialogModal = () => {
    setTagSearch('');
    setOpen(false);
  };

  const submitTags = () => {
    setOpen(false);
    addNewTag(tagSearch);
    setTagSearch('');
  };

  const { t } = useTranslation('global');

  return (
    <Box>
      <Stack p={2} direction="row" justifyContent="space-between" spacing={3}>
        <Typography fontSize="17px" color={LmiTheme.light.systemBackground.tertiary}>
          {t('circles.add_circle_page.enter_tags')}
        </Typography>
        <Typography id="tags" fontSize="17px" fontWeight={500} color={LmiTheme.light.systemBackground.tertiary}>
          {splitTags.length}
          /10
        </Typography>
      </Stack>

      {(errors?.tags) && (
        <Typography component="p" pb={2} pt="11px" sx={{ textAlign: 'center', fontSize: '12px', color: LmiTheme.light.dialog.error }}>
          {t('circles.add_circle_page.tagFieldRequired')}
        </Typography>
      )}
      <Divider />
      {splitTags.length > 0
        ? (
          <Box sx={{
            padding: isMobile ? '16px 16px' : '30px 100px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '20px',
          }}
          >
            {
              splitTags.map((d: any, i: number) => (
                i <= 10 && (
                  <Chip
                    key={uuid()}
                    label={d}
                    sx={{
                      padding: '7.5px 0px',
                      fontSize: '16px',
                    }}
                    onDelete={() => {
                      removeTag(i);
                    }}
                    deleteIcon={<CancelRoundedIcon style={{ color: '#038de1' }} />}
                  />
                )))
            }
          </Box>
        )
        : (
          <Box>
            <Typography
              fontSize="16px"
              color={LmiTheme.light.systemBackground.tertiary}
              sx={{
                padding: '16px 30px',
              }}
            >
              {t('circles.add_circle_page.no_tags')}
            </Typography>
          </Box>
        )}
      <Box mb={2} sx={{ display: 'flex', justifyContent: 'center' }}>
        {splitTags?.length < 10 && (
          <Button
            disabled={props.isBlocked}
            variant="outlined"
            onClick={() => { handleModalToggle(); }}
            startIcon={<AddIcon fontSize="large" />}
            size="large"
            sx={{
              my: 2,
              textTransform: 'none',
              fontWeight: 700,
              borderRadius: '10px',
            }}
          >
            {t('circles.add_circle_page.add_tags')}
          </Button>
        )}

        <DialogCommon
          kTitle={t('circles.add_circle_page.circle_tags')}
          kMaxWidth="sm"
          kMaxHeight={tagSearch && isSuggestionVisible ? '590px' : '319px'}
          kHeight={tagSearch && isSuggestionVisible ? '590px' : '300px'}
          kAlignBtn="center"
          kBorderRadius={isMobile ? 0 : '10px'}
          kFixedDialog={0}
          kFixedDialogPosition="fixed"
          kDisableBottomDivider
          kKeepBtnSize
          kSlideTransition={isMobile}
          kTopDivider
          kOpenDialogLists={open}
          kOnClose={closeDialogModal}
          kOnSecondary={closeDialogModal}
          kOnPrimary={submitTags}
          kContent={
            (
              <AddCircleTags
                saveTag={closeDialogModal}
                visibleSuggestion={(a: any) => { setIsSuggestionVisible(a); }}
                setTagSearch={(a: any) => { setTagSearch(a); }}
                tagSearch={tagSearch}
              />
            )
          }
        />
      </Box>

    </Box>
  );
}

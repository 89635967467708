import LmiTheme from '../../../../../../themes/lmi-mui-theme/lmi-theme';

export const ActivitySwiperStyle = {
  backgroundColor: 'white',
  overflow: 'hidden',
  width: '100%',

  '.lmiw-activity-swiper': {
    borderRadius: '10px',

    '.lmiw-swiper-image': {
      width: '100%',
      height: 160,
      cursor: 'pointer',
      borderRadius: '10px',
      img: {
        transition: 'transform .3s',
        '&:hover': {
          transform: 'scale(1.1)',
        },
      },

    },
    '.prev, .next': {
      display: { xs: 'none', sm: 'flex' },
      width: 56,
      height: 56,
      fontWeight: 400,
      boxShadow: 'none',
      backgroundColor: 'white',
      textTransform: 'capitalize',
      color: LmiTheme.light.accent,
      border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
      '&.MuiButton-outlined': {
        borderRadius: 2,
        px: { xs: 2, sm: 4 },
        height: { xs: 44, sm: 56 },
      },
    },
  },
};

export const ActivitySwiperModalStyle = {
  '.main-swiper': {
    '.swiper-button-next, .swiper-button-prev': {
      background: ' rgba(0, 0, 0, 0.4)',
      borderRadius: '50px',
      width: '60px',
      height: '60px',
      color: 'white',
      margin: '0 25px',
      '&::after': {
        fontSize: '24px',
      },
    },
  },

  '.thumbnail-swiper': {
    '.swiper-slide': {
      opacity: 0.4,
      '&.swiper-slide-thumb-active': {
        opacity: 1,
      },
    },

    '.lmiw-thumbnail-image': {
      width: '100%',
      cursor: 'pointer',
      height: '100px',
      img: {
        transition: 'transform .3s',
        '&:hover': {
          transform: 'scale(1.1)',
        },
      },
    },
  },

};

// export default { ActivitySwiperStyle, ActivitySwiperModalStyle };

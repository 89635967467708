import {
  Box, Button, IconButton, Paper, useMediaQuery, useTheme,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { toast } from 'react-toastify';
import AddEditCirclesContext from '../../../../../../context/circles/addEditCirclesContext';
import Heading from '../Heading/Heading';
import ManageItems from './ManageItems/ManageItems';
import { DialogCommon } from '../../../../../../components/Common';
import ManageItemsModal from './ManageItems/ManageItemsModal';
import { ManageItemsSectionModels } from './ManageItemsSection.models';
import MyCirclesListingContext from '../../../../../../context/myCirclesListing/myCirclesListingContext';

function ManageItemsSection(props: ManageItemsSectionModels) {
  const circlesContextLog = useContext(AddEditCirclesContext);

  const {
    setModalItemEditPage,
  }: any = circlesContextLog;

  const {
    circle,
  }: any = useContext(MyCirclesListingContext);

  const [canManageItems, setCanManageItems] = useState(false);

  useEffect(() => {
    const canManage = circle?.isOwner
      || (circle?.isAdmin && !circle?.rentOnly && (circle?.canUploadItem === 1 || circle?.canUploadItem === 0));

    setCanManageItems(canManage);
  }, [circle]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation('global');

  const [isManageItemsOpen, setIsManageItemsOpen] = React.useState(false);
  const [refreshItems, setRefreshItems] = React.useState(false);

  const updateItem = () => {
    props.kSaveNewItemStatus();
    toast.success(t('circles.add_circle_page.block_item'));
    setModalItemEditPage(1);
    setIsManageItemsOpen(false);
  };

  const closeItemsModal = () => {
    setModalItemEditPage(1);
    setIsManageItemsOpen(false);
    setRefreshItems(!refreshItems);
  };

  useEffect(() => {
    // refresh
  }, [refreshItems]);

  return (
    <>
      <Box
        mt={3}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: '10px',
        }}
      >
        <Heading kTitle={t('circles.add_circle_page.items')} kPaddingBottom="0px" />
        {
          // eslint-disable-next-line no-nested-ternary
          (!props.kCircleIsNew || canManageItems) && (
            isMobile ? (
              <IconButton
                onClick={() => setIsManageItemsOpen(true)}
              >
                <ArrowForwardIosRoundedIcon color="primary" />
              </IconButton>
            ) : (
              <Button
                variant="text"
                sx={{ textTransform: 'none', fontSize: '18px' }}
                onClick={() => setIsManageItemsOpen(true)}
              >
                {t('circles.add_circle_page.manage')}
              </Button>
            )
          )
        }
      </Box>
      <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
        <ManageItems kIdCatLndCircle={props.kIdCatLndCircle} refresh={refreshItems} />
      </Paper>
      <DialogCommon
        kTitle={t('circles.add_circle_page.manage_items')}
        kMaxWidth="sm"
        kBorderRadius="10px"
        kDisableContentPadding
        kSlideTransition={isMobile}
        kOpenDialogLists={isManageItemsOpen}
        kOnClose={closeItemsModal}
        kOnPrimary={updateItem}
        kOnSecondary={closeItemsModal}
        kAlignBtn="center"
        kKeepBtnSize
        kContent={
          (
            <ManageItemsModal />
          )
        }
      />
    </>
  );
}

export default ManageItemsSection;

import {
  SET_INITIAL_CIRCLE,
  CONDITION_ADD_EDIT,
  UPDATE_CONDITION_VALIDATION_STATUS,
  CHECK_CONDITIONS_VALIDATIONS,
  SET_CONDITION_EDIT_INDEX,
  REMOVE_CONDITION,
  CIRCLE_INFO_CHANGED,
  FILTER_CIRCLE_ITEMS,
  FILTER_CIRCLE_MEMBERS,
  SET,
  UPDATE_CIRCLES_LIST,
  UPDATE_REASON_DISABLE_ITEM,
  SET_ITEM_ACTIVE_TO_UPDATE,
  UPDATE_PAGE_ITEM_STATUS_UPDATE,
  CONDITIONS_UPDATE_LIST,
  MEMBER_DETAILS,
  UPDATED_MEMBER_PERMIT,
  CIRCLE_UPDATED_SUCCESSFULLY,
  UNSET_MEMBERS_EDIT,
  CHECK_VALIDATIONS,
  CIRCLE_UPDATE_ERROR,
  ACTIVE_CIRCLE_UPDATED,
  DISABLE_MEMBERS,
  RE_SET_MEMBERS,
  SUCCESSFULLY_LEFT_CIRCLE,
  SET_CIRCLE_ITEMS,
  INFO_IS_LOADING,
  VALID_ERRORS,
  CLEAN_ERRORS,
  CIRCLE_UPDATED_CLEAN,
} from '../../types';

export default (state: any, action: any) => {
  switch (action.type) {
    case SET_INITIAL_CIRCLE: {
      return {
        ...state,
        circleInfoEdit: action.circleInfoEdit,
      };
    }
    case INFO_IS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET: {
      return {
        ...state,
        circleInfoEdit: action.circleInfoEdit,
        conditionsList: action.conditionsList,
        circleItemsEdit: action.circleItemsEdit,
        circleMembersEdit: action.circleMembersEdit,
        circleItemsEditFilter: action.circleItemsEditFilter,
        circleMembersEditFilter: action.circleMembersEditFilter,
        reasonToDisableItem: action.reasonToDisableItem,
        pageEditItem: action.pageEditItem,
        circleAddress: action.circleAddress,
        originalCircleInfoEdit: action.originalCircleInfoEdit,
        circleUpdated: false,
        loading: false,
      };
    }
    case FILTER_CIRCLE_ITEMS: {
      return {
        ...state,
        circleItemsEditFilter: action.circleItemsEditFilter,
      };
    }
    case FILTER_CIRCLE_MEMBERS: {
      return {
        ...state,
        circleMembersEditFilter: action.circleMembersEditFilter,
      };
    }
    case CONDITION_ADD_EDIT: {
      return {
        ...state,
        addEditConditionInfo: action.addEditConditionInfo,
        checkConditionValidate: action.checkConditionValidate,
      };
    }
    case UPDATE_CONDITION_VALIDATION_STATUS: {
      return {
        ...state,
        areConditionFieldsValidated: action.areConditionFieldsValidated,
        changedConditionFields: !state.changedConditionFields,
        checkConditionValidate: !action.checkConditionValidate,
      };
    }
    case CHECK_CONDITIONS_VALIDATIONS: {
      return {
        ...state,
        checkConditionValidate: action.checkConditionValidate,
      };
    }
    case SET_CONDITION_EDIT_INDEX: {
      return {
        ...state,
        conditionIndex: action.conditionIndex,
      };
    }
    case CONDITIONS_UPDATE_LIST: {
      return {
        ...state,
        conditionsList: action.conditionsList,
        addEditConditionInfo: '',
      };
    }
    case REMOVE_CONDITION: {
      return {
        ...state,
        conditionsList: action.payload,
      };
    }
    case CIRCLE_INFO_CHANGED: {
      return {
        ...state,
        circleInfoEdit: action.circleInfo,
        checkConditionValidate: false,
        // itemAccessories: action.itemAccessories,
      };
    }
    case UPDATE_CIRCLES_LIST: {
      return {
        ...state,
        circleItemsEdit: action.circleItemsEdit,
        circleItemsEditFilter: action.circleItemsEdit,
        pageEditItem: action.pageEditItem,
        reasonToDisableItem: action.reasonToDisableItem,
      };
    }
    case UPDATE_REASON_DISABLE_ITEM: {
      return {
        ...state,
        reasonToDisableItem: action.reasonToDisableItem,
      };
    }
    case SET_ITEM_ACTIVE_TO_UPDATE: {
      return {
        ...state,
        selectedItemId: action.selectedItemId,
        itemActive: action.itemActive,
      };
    }
    case UPDATE_PAGE_ITEM_STATUS_UPDATE: {
      return {
        ...state,
        pageEditItem: action.pageEditItem,
      };
    }
    case MEMBER_DETAILS: {
      return {
        ...state,
        memberSelected: action.payload,
        memberUpdated: false,
      };
    }
    case UPDATED_MEMBER_PERMIT: {
      return {
        ...state,
        memberUpdated: action.memberUpdated,
      };
    }
    case CIRCLE_UPDATED_SUCCESSFULLY: {
      return {
        ...state,
        circleUpdated: true,
      };
    }
    case CIRCLE_UPDATED_CLEAN: {
      return {
        ...state,
        circleUpdated: false,
      };
    }
    case ACTIVE_CIRCLE_UPDATED: {
      return {
        ...state,
        circleUpdated: true,
        circleInfoEdit: action.circleInfo,
      };
    }
    case CIRCLE_UPDATE_ERROR: {
      return {
        ...state,
        circleUpdateError: action.circleUpdateError,
        // circleUpdated: action.circleUpdated,
      };
    }
    case UNSET_MEMBERS_EDIT: {
      return {
        ...state,
        circleMembersEdit: undefined,
        memberSelected: undefined,
      };
    }
    case CHECK_VALIDATIONS: {
      return {
        ...state,
        checkValidate: action.checkValidate,
        loading: false,
      };
    }
    case DISABLE_MEMBERS: {
      return {
        ...state,
        circleMembersEdit: undefined,
        memberSelected: undefined,
      };
    }
    case RE_SET_MEMBERS: {
      return {
        ...state,
        circleMembersEdit: action.payload,
      };
    }
    case SUCCESSFULLY_LEFT_CIRCLE: {
      return {
        ...state,
        leftCircle: true,
      };
    }
    case SET_CIRCLE_ITEMS: {
      return {
        ...state,
        circleItemsEdit: action.circleItemsEdit,
        circleItemsEditFilter: action.circleItemsEditFilter,
        pageEditItem: 1,
        loading: false,
      };
    }
    case VALID_ERRORS: {
      return {
        ...state,
        errors: action.errors,
      };
    }
    case CLEAN_ERRORS: {
      return {
        ...state,
        errors: {},
      };
    }
    default:
      return state;
  }
};

import {
  Grid, Button, Box, Divider, Typography, IconButton, FormControl, OutlinedInput, InputAdornment, Stack, useTheme, useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import React, { useContext, useEffect } from 'react';
import { ArrowBackIosRounded } from '@mui/icons-material';
import {
  ContentLayout,
  SectionTitle,
  LoadingLayout,
  DialogCommon,
} from '../../../../components/Common';
import LmiTheme from '../../../../themes/lmi-mui-theme/lmi-theme';
import AppBarSecondary from '../../../../components/Layout/AppBarSecondary/AppBarSecondary';
import CardLend from '../../Listing/Components/CardLend/CardLend';
import MemberDetail from './MemberDetail/MemberDetail';
import CircleMembers from './CircleMembers/CircleMembers';
import AddEditCirclesContext from '../../../../context/circles/addEditCirclesContext';
import { circleMemberElementModel } from '../AddCircle/AddCircle.models';
import publicProfileContext from '../../../../context/publicProfile/publicProfileContext';
import MyCirclesListingContext from '../../../../context/myCirclesListing/myCirclesListingContext';
import ReportDialog from '../CircleDetail/Components/ReportCircle/ReportDialog';

export default function ManageMembersDetail() {
  const history = useHistory();
  const state = history.location.state as {
    kCircleMemberId: string,
    kMemberIndex: number,
    updateSwiper: false,
    kShowDetailsMobile: false
    kOpensFromCircleDetail: boolean;
    kCircleId: number
  };

  const circlesContextLog = useContext(AddEditCirclesContext);
  const {
    circleMembersEdit,
    memberDetails,
    memberSelected,
    getCircleEditInfo,
    filterMemberManageElements,
  }: any = circlesContextLog;

  const pProfileContext = useContext(publicProfileContext);
  const {
    getPublicProfileInfo,
  }: any = pProfileContext;

  const {
    circle,
    getMyCircles,
  }: any = useContext(MyCirclesListingContext);

  const { t } = useTranslation('global');

  const onChange = (memberClicked: circleMemberElementModel) => {
    if (memberClicked?.idCatLndUser != memberSelected?.idCatLndUser) {
      memberDetails(memberClicked);
      getPublicProfileInfo({ idCatLender: memberClicked?.idCatLndUser });
      getMyCircles(memberClicked?.idCatLndUser);
      window.scrollTo(0, 0);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    const circleId = localStorage.getItem('idCircle') || undefined;

    async function fetchMemberDetails(user: any, index: any, getCircles = false) {
      if (user?.idCatLndUser) {
        await getPublicProfileInfo({ idCatLender: user?.idCatLndUser || 0 });
        console.log(index);
        await memberDetails(user);
        if (getCircles) {
          await getMyCircles(user?.idCatLndUser);
        }
      }
    }

    async function fetchCircleEditInfo(id: any) {
      await getCircleEditInfo(id);
      console.log(id);
    }

    if (circleMembersEdit) {
      if (circle?.idCatLndCircle !== undefined) {
        localStorage.setItem('idCircle', circle?.idCatLndCircle);
      }

      if (!memberSelected) {
        fetchMemberDetails(circleMembersEdit[0], state?.kMemberIndex || 0, true);
      } else {
        fetchMemberDetails(memberSelected, state?.kMemberIndex || 0);
      }

      if (memberSelected) {
        fetchMemberDetails(circleMembersEdit[state?.kMemberIndex || 0], []);
      } else {
        fetchMemberDetails(memberSelected, state?.kMemberIndex || 0);
      }
    } else {
      fetchCircleEditInfo(circle?.idCatLndCircle !== undefined ? Number(circle?.idCatLndCircle) : Number(circleId));
    }
  }, [circleMembersEdit]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const hasMembers = circleMembersEdit?.some((circleMember: any) => circleMember.isMember === true);

  const goToCircles = () => {
    window.scrollTo(0, 0);
    history.push({
      pathname: '/circles',
    });
  };

  const [search, setSearch] = React.useState('');

  const [showMemberDetailMobile, setShowMemberDetailMobile] = React.useState(false);

  const openDetails = (e: boolean) => {
    setShowMemberDetailMobile(e);
  };

  useEffect(() => {
    if (circleMembersEdit) {
      filterMemberManageElements(search);
    }
  }, [circleMembersEdit]);

  useEffect(() => {
    const newTimer = setTimeout(() => {
      if (circleMembersEdit) {
        filterMemberManageElements(search);
      }
    }, 500);
    return () => clearTimeout(newTimer);
  }, [search]);

  const [openReportCircleModal, setOpenReportCircleModal] = React.useState(false);

  const goToSearch = (value: string) => {
    setSearch(value);
  };

  return (
    <>
      {
        showMemberDetailMobile ? (
          <AppBarSecondary
            kBackClick={() => setShowMemberDetailMobile(false)}
            kBack
            kTitle={
              (
                <SectionTitle
                  kMarginTop={0}
                  kMarginBottom={0}
                  kTitle={t('circles.members.members_detail')}
                />
              )
            }
            kActions={<></>}
          />
        ) : (
          <AppBarSecondary
            kBackClick={() => goToCircles()}
            kBack
            kTitle={
              (
                <SectionTitle
                  kMarginTop={0}
                  kMarginBottom={0}
                  kTitle={t('circles.members.members')}
                />
              )
            }
            kActions={<></>}
          />
        )
      }

      <ContentLayout kPaddingXs={0}>
        {circleMembersEdit
          ? (
            <Grid container>
              {circleMembersEdit && circleMembersEdit.length > 0
                ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      sx={{
                        position: 'relative',
                        borderRight: isMobile ? 'none' : `1px solid ${LmiTheme.light.systemBackground.separator}`,
                        display: {
                          xs: showMemberDetailMobile ? 'none' : 'inherit',
                          md: 'inherit',
                        },
                      }}
                    >
                      <CardLend
                        kTitle={t('circles.members.members')}
                        kBackAction={
                          (
                            <IconButton color="primary" onClick={() => goToCircles()} sx={{ py: 1.4 }}>
                              <ArrowBackIosRounded />
                            </IconButton>
                          )
                        }
                        kTopBar={(
                          <Stack flexDirection="column" spacing="16px">
                            {/* Members in desktop */}
                            <FormControl
                              className="lmi-formcontrol"
                              onChange={(e: any) => setSearch(e.target.value)}
                              onKeyDown={(e: any) => (e.keyCode == 13
                                ? goToSearch(e.target.value)
                                : '')}
                            >
                              <OutlinedInput
                                sx={{ borderRadius: '10px', height: '48px', mt: '12px' }}
                                inputProps={{
                                  maxLength: 50,
                                }}
                                value={search}
                                fullWidth
                                className="lmi-input-search"
                                startAdornment={(
                                  <InputAdornment position="start">
                                    <SearchRoundedIcon />
                                  </InputAdornment>
                                )}
                                placeholder={t('circles.members.search')}
                              />
                            </FormControl>
                            <Typography
                              component="span"
                              fontSize={14}
                              sx={{ textTransform: 'none', color: '#999da2' }}
                            >
                              {t('circles.members.admin_members')}
                            </Typography>
                          </Stack>
                        )}
                        kContent={(
                          <>
                            {/* Members in mobile */}
                            <Box sx={{ display: { sm: 'inherit', md: 'none' } }}>
                              <Stack mx={2} my={1} flexDirection="column" spacing="16px">
                                <FormControl
                                  className="lmi-formcontrol"
                                  onChange={(e: any) => setSearch(e.target.value)}
                                >
                                  <OutlinedInput
                                    sx={{ borderRadius: '10px', height: '48px', mt: '12px' }}
                                    inputProps={{
                                      maxLength: 50,
                                    }}
                                    value={search}
                                    fullWidth
                                    className="lmi-input-search"
                                    startAdornment={(
                                      <InputAdornment position="start">
                                        <SearchRoundedIcon />
                                      </InputAdornment>
                                    )}
                                    placeholder={t('circles.members.search')}
                                  />
                                </FormControl>
                                <Typography
                                  component="span"
                                  fontSize={14}
                                  sx={{ textTransform: 'none', color: '#999da2' }}
                                >
                                  {t('circles.members.admin_members')}
                                </Typography>
                              </Stack>
                              <Divider />
                            </Box>
                            <Box sx={{ display: 'inherit' }}>
                              <CircleMembers
                                kAdminMembers
                                kOpenMemberDetail={(e: any) => openDetails(e)}
                                onChange={(memberClicked: circleMemberElementModel) => {
                                  onChange(memberClicked);
                                }}
                              />
                            </Box>
                          </>
                        )}
                        kSecondTopBar={(
                          <>
                            <Typography
                              component="span"
                              fontSize={14}
                              sx={{ textTransform: 'capitalize', color: '#999da2' }}
                            >
                              {t('circles.members.members')}
                            </Typography>
                          </>
                        )}
                        kSecondContent={(
                          <>
                            <Box sx={{ display: { sm: 'inherit', md: 'none' } }}>
                              <Box mx={2} my={1}>
                                <Typography
                                  component="span"
                                  fontSize={14}
                                  sx={{ textTransform: 'capitalize', color: '#999da2' }}
                                >
                                  {t('circles.members.members')}
                                </Typography>
                              </Box>
                              <Divider />
                            </Box>
                            <Box sx={{ display: hasMembers ? 'inherit' : 'none' }}>
                              <CircleMembers
                                kOpenMemberDetail={(e: any) => openDetails(e)}
                                onChange={(memberClicked: circleMemberElementModel) => {
                                  onChange(memberClicked);
                                }}
                              />
                            </Box>
                            <Typography
                              fontSize={14}
                              sx={{
                                textAlign: 'center',
                                color: '#999da2',
                                p: 3,
                                display: !hasMembers ? 'inherit' : 'none',
                              }}
                            >
                              {t('circles.members.no_members')}
                            </Typography>
                          </>
                        )}
                      />
                      <Box sx={{
                        display: { xs: 'none', md: 'initial' },
                        background: 'white',
                        position: 'absolute',
                        bottom: '0',
                        width: '100%',
                      }}
                      >
                        <Box sx={{
                          background: 'white',
                          p: 2,
                          display: hasMembers ? 'none' : 'inherit',
                        }}
                        />
                      </Box>

                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={8}
                      sx={{
                        display: {
                          xs: showMemberDetailMobile ? 'block' : 'none',
                          md: 'initial',
                        },
                      }}
                    >
                      <CardLend
                        kTitle={t('circles.members.member_detail')}
                        kActions={(
                          <Button
                            onClick={() => setOpenReportCircleModal(true)}
                            variant="outlined"
                            sx={
                              {
                                display: { xs: 'none', sm: 'inherit' },
                                borderRadius: 3,
                                fontWeight: 'bold',
                                textTransform: 'capitalize',
                                border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
                              }
                            }
                            startIcon={<ErrorOutlineOutlinedIcon />}
                          >
                            {t('circles.members.report')}
                          </Button>
                        )}
                        kContent={(memberSelected != undefined
                          ? (
                            <MemberDetail
                              kOpensFromCircleDetail={state.kOpensFromCircleDetail}
                            />
                          )
                          : <LoadingLayout />
                        )}
                      />
                      <DialogCommon
                        kTitle={t('circles.members.report_user')}
                        kMaxWidth="sm"
                        kMaxHeight={isMobile ? '480px' : 'auto'}
                        kAlignBtn="center"
                        kBorderRadius={isMobile ? 0 : '10px'}
                        kFixedDialog={0}
                        kFixedDialogPosition="fixed"
                        kDisableBottomDivider
                        kDisableContentPadding
                        kKeepBtnSize
                        kSlideTransition={isMobile}
                        kTopDivider
                        kOpenDialogLists={openReportCircleModal}
                        kOnClose={() => setOpenReportCircleModal(false)}
                        kOnPrimary={() => setOpenReportCircleModal(false)}
                        kOnSecondary={() => setOpenReportCircleModal(false)}
                        kPrimaryBtnText={t('circles.members.report')}
                        kContent={(
                          <ReportDialog />
                        )}
                      />
                    </Grid>
                  </>
                )
                : ('')}
            </Grid>
          )
          : <CardLend kTitle={t('circles.members.members')} kContent={(<LoadingLayout />)} />}
      </ContentLayout>

    </>
  );
}

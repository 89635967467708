import {
  Button, CardContent, ListItemIcon, Stack,
} from '@mui/material';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  AboutMe,
  // CirclesCard,
  // CoverImage,
  LmiTheme,
  MainInformationTabPanel,
  ProfileInfo,
  ReviewsCard,
  VisibleOnlyToYou,
} from '..';
import publicProfileContext from '../../../../../context/publicProfile/publicProfileContext';
import { TabPanelMainInformationModels } from './TabPanelMainInformation.models';
// import { Profile } from '../../../../../components/Common';
import { onImageDropped } from '../../../../../services/common';
import { Profile } from '../../../../../assets';

function TabPanelMainInformation(props: TabPanelMainInformationModels) {
  const history = useHistory();
  const pProfileContext = useContext(publicProfileContext);
  const {
    privateProfileInfo,
    updateProfileInfo,
    uploadUpdateProfilePicture,
    userReviews,
    getPublicProfileInfo,
  }: any = pProfileContext;

  const fileTypes = [
    'JPG', 'JPEG',
    'PNG', 'WEBP',
    'BMP', 'TIFF',
  ];

  const nameToShow = privateProfileInfo?.completeName != '' ? privateProfileInfo?.completeName : props.kProfileInfo?.userName;
  const gotoPublicProfile = () => {
    (async function fetchProfileInfo() {
      await getPublicProfileInfo({ idCatLender: 0 });
      history.push('/public-profile', { fromPage: 'private-profile', publicProfileId: 0 });
    }());
  };

  const updatePicture = (result: string) => {
    uploadUpdateProfilePicture(result);
    updateProfileInfo({
      ...privateProfileInfo,
      profilePicture: result,
    });
  };

  const { t } = useTranslation('global');

  return (
    <>
      <MainInformationTabPanel
        kTabValue={props.kTabValue}
        kTabIndex={props.kTabIndex}
        kContent={
          (
            <>
              <CardContent sx={{ px: 3 }}>
                <ProfileInfo
                  kUpdatePicture={(e: any) => onImageDropped(e, updatePicture)}
                  kPictureTypes={fileTypes}
                  kPhoto={props.kProfileInfo?.profilePicture}
                  kName={nameToShow}
                  kMemberSince={props.kProfileInfo?.memberSince}
                  kIdVerify={props.kProfileInfo?.isShuftiVerified || props.kProfileInfo?.isStripeVerified}
                  kEditing
                  kOnClickEditPhoto={undefined}
                  kOnClickEditName={undefined}
                  kPromoCode={props.kProfileInfo?.userCode}
                  kPromoCodeLink={props.kProfileInfo?.referralLink}
                  kBtnPublicProfile={(
                    <Stack
                      direction="row"
                      sx={{ display: { xs: 'flex', md: 'none' } }}
                    >

                      <Button
                        size="small"
                        onClick={gotoPublicProfile}
                        variant="outlined"
                        endIcon={(
                          <ListItemIcon sx={{
                            minWidth: '10px',
                            marginRight: '5px',
                            svg: {
                              fill: LmiTheme.light.accent,
                              width: 12,
                              height: 12,
                            },
                          }}
                          >
                            <Profile />
                          </ListItemIcon>
                        )}
                        sx={{
                          borderRadius: '20px',
                          color: LmiTheme.light.systemBackground.primary,
                          backgroundColor: LmiTheme.light.accentActiveBackground,
                          border: 'none',
                          textTransform: 'capitalize',
                          mb: 2,
                          fontSize: '12px',
                          fontWeight: '400',
                        }}
                      >
                        {t('profile.public')}
                      </Button>
                    </Stack>
                  )}
                />
                <AboutMe
                  kTitle={t('profile.about_me')}
                  kDescription={props.kProfileInfo?.dscription}
                  kEditing
                />
                <VisibleOnlyToYou
                  kTitle={t('profile.visible_only')}
                  kProfileInfo={props.kProfileInfo}
                />
                <ReviewsCard
                  kTitle={t('profile.reviews')}
                  kRecommended={privateProfileInfo?.sumRatesReceived?.toFixed(2) ?? 100}
                  kReviews={privateProfileInfo?.totalReceivedReviews}
                  kReviewsDetails={userReviews}
                  kOnClickGoToReviewsTab={props.kOnClickGoToReviewsTab}
                />
              </CardContent>
            </>
          )
        }
      />
    </>
  );
}

export default TabPanelMainInformation;

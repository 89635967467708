import {
  Box, Typography, Stack,
} from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import MyCirclesListingContext from '../../../../../../context/myCirclesListing/myCirclesListingContext';
import LmiTheme from '../../../../../../themes/lmi-mui-theme/lmi-theme';

export default function ConditionsToJoinDetail() {
  const {
    circle,
  }: any = useContext(MyCirclesListingContext);

  const splitConditions = circle?.conditions ? circle.conditions.split(',') : [];

  const { t } = useTranslation('global');
  return (

    // eslint-disable-next-line no-nested-ternary
    circle?.conditions

      ? (
        splitConditions.length > 0
       && splitConditions.map((condition: any) => (
         <ul key={condition}>
           <li style={{ color: '#038de1' }}>
             <Typography fontSize="16px" color={LmiTheme.light.systemBackground.tertiary}>
               {condition}
             </Typography>
           </li>
         </ul>
       ))
      )
      : (
        <Stack p={2} direction="row" justifyContent="space-between" spacing={3}>
          <Box>
            <Typography fontSize="16px" pl={1} color={LmiTheme.light.systemBackground.tertiary}>
              {t('circles.detail.no_conditions')}
            </Typography>
          </Box>
        </Stack>
      )

  );
}

import { LmiTheme } from '../../../../../../components/Common';

const ActivityUserStyle = {
  width: '100%',
  '.lmiw-activity-user': {
    borderRadius: '10px',
    p: 2,
    '.lmiw-user-name': {
      // pt: 1,
      color: LmiTheme.light.systemBackground.primary,
      fontSize: 18,
      fontWeight: 'bold',
    },

    '.lmiw-user-like, .lmiw-user-verified': {
      // pt: 1,
      color: LmiTheme.light.systemBackground.secondary,
      svg: {
        width: '14px',
        mr: 1,
        fill: LmiTheme.light.secondaryColor.green,
      },
    },
    '.lmiw-user-organization': {
      // pt: 1,
      color: LmiTheme.light.systemBackground.secondary,
      svg: {
        width: '14px',
        mr: 1,
        fill: LmiTheme.light.accent,
      },
    },

    'button.lmiw-user-message': {
      borderRadius: '10px',
      padding: 2,
      textTransform: 'capitalize',
      fontSize: '16px',
      fontWeight: '500',
      width: 'auto',
      color: LmiTheme.light.systemBackground.secondary,
      border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
      p: {
        whiteSpace: 'nowrap',
      },
    },
    '.lmiw-user-disabled-message-icon': {
      borderRadius: '100%',
      p: 2,
      width: '40px',
      height: '40px',
      fontSize: '14px',
      color: LmiTheme.light.systemBackground.secondary,
      border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
      background: 'white',
      boxShadow: 'none',
      svg: {
        fontSize: '1rem',
      },
    },
    '.lmiw-user-message-icon': {
      borderRadius: '100%',
      p: 2,
      width: '40px',
      height: '40px',
      fontSize: '14px',
      color: LmiTheme.light.systemBackground.secondary,
      border: `1px solid ${LmiTheme.light.accent}`,
      background: 'white',
      boxShadow: 'none',
      svg: {
        fontSize: '1rem',
      },
    },
  },

};

export default ActivityUserStyle;

import {
  Grid, Stack,
} from '@mui/material';
import { TopNavModels } from './TopNav.models';

function TopNav(props: TopNavModels) {
  return (
    <>
      <Grid
        container
        py={1}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" spacing={1} onClick={props.kLeftClick}>
          {props.kContentLeft}
        </Stack>
        <Stack direction="row" spacing={1}>
          {props.kContentRight}
        </Stack>
      </Grid>
    </>
  );
}
TopNav.defaultProps = {
  kContentLeft: <Stack mt={5.5} />,
  kContentRight: <Stack mt={5.5} />,
};

export default TopNav;

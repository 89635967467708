/* eslint-disable max-lines */
import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CardStripe, LmiTheme } from '../..';
import payoutAccountContext from '../../../../../../context/payoutAccount/payoutAccountContext';
import { TextExpireDate } from '../../../../../../utils/inputsFormats';

function BusinessPayout(props: any) {
  const pPayoutContext = useContext(payoutAccountContext);
  const {
    // newPayoutAccountInfo,
    // payoutAccDetEdit,
    payoutAccountDetails,
    updateExistingPayoutAccountDetails,
    checkPayoutValidate,
    updatePayoutValidationStatus,
  }: any = pPayoutContext;

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isExpDateValid, setIsExpDateValid] = useState(true);
  const [isCompanyNameValid, setIsCompanyNameValid] = useState(true);

  useEffect(() => {
    if (checkPayoutValidate) {
      const validations = {
        email: {
          validator: (value: any) => value.length > 0,
          setter: setIsEmailValid,
        },
        expiration: {
          validator: (value: any) => value.length >= 5,
          setter: setIsExpDateValid,
        },
        companyName: {
          validator: (value: any) => value.length > 0,
          setter: setIsCompanyNameValid,
        },
      };

      let isEverythingOk = true;

      Object.entries(validations).forEach(([key, field]) => {
        const isValid = field.validator(payoutAccountDetails[key]);
        field.setter(isValid);
        if (!isValid) {
          isEverythingOk = false;
        }
      });

      updatePayoutValidationStatus(isEverythingOk);
    }
  }, [checkPayoutValidate]);

  const [selectedIndividualOrBussines, setSelectedIndividualOrBussines] = useState('individual');
  const changeIndividualOrBussines = (
    _event: React.MouseEvent<HTMLElement>,
    newSelectedIndividualOrBussines: string,
  ) => {
    if (newSelectedIndividualOrBussines !== null) {
      setSelectedIndividualOrBussines(newSelectedIndividualOrBussines);
      if (newSelectedIndividualOrBussines == 'individual') {
        updateExistingPayoutAccountDetails({
          ...payoutAccountDetails,
          isIndividual: true,
          isBusiness: false,
        });
      } else {
        updateExistingPayoutAccountDetails({
          ...payoutAccountDetails,
          isBusiness: true,
          isIndividual: false,
        });
      }
    }
  };

  const handleInputchange = ({ target }: any) => {
    updateExistingPayoutAccountDetails({
      ...payoutAccountDetails,
      [target.name]: target.value,
    });
  };

  const { t } = useTranslation('global');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const expiration = payoutAccountDetails?.expiration;
  const formattedExpiration = expiration.length === 4 && !expiration.startsWith('1') ? `0${expiration}` : expiration;

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Typography py={2} component="span" textAlign="center" color={LmiTheme.light.systemBackground.tertiary}>{t('payout_info.payment_secure')}</Typography>
        <CardStripe width={56} height={56} style={{ marginRight: 30 }} />
      </Stack>
      <Box sx={{ p: 1, fontWeight: 'bold' }}>
        {t('payout_info.payout_information')}
      </Box>
      <Paper variant="outlined" sx={{ p: 2, borderRadius: '10px' }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={1}
          component="form"
          sx={{
            '& > :not(style)': { p: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        >
          {payoutAccountDetails.accId == '' ? (
            <Grid item xs={12}>
              <ToggleButtonGroup
                onChange={changeIndividualOrBussines}
                value={selectedIndividualOrBussines}
                color="primary"
                fullWidth
                exclusive
              >
                <ToggleButton
                  disableFocusRipple
                  disableRipple
                  value="individual"
                >
                  {t('payout_info.individual')}
                </ToggleButton>
                <ToggleButton
                  disableFocusRipple
                  disableRipple
                  value="business"
                >
                  {t('payout_info.business')}
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          ) : (
            <Box>
              <Typography component="span" fontWeight={500}>{t('payment_method.business_account')}</Typography>
            </Box>
          )}
          <Grid item p={0} m={0} xs={12}>
            <Box my={0}>
              <Grid
                container
                rowSpacing={2}
                columnSpacing={0}
                component="form"
                sx={{
                  '& > :not(style)': { width: '25ch' },
                }}
                noValidate
                autoComplete="off"
              >
                {/* {(payoutAccountDetails.isBusiness) && ( */}
                <Grid item xs={12}>
                  <Box pb={1}>
                    <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                    <Typography component="span" fontWeight={500}>{t('payout_info.company_name')}</Typography>
                  </Box>
                  <TextField
                    fullWidth
                    // id="outlined-basic"
                    variant="outlined"
                    type="text"
                    error={!isCompanyNameValid}
                    helperText={!isCompanyNameValid && t('payout_info.invalid_company_name')}
                    placeholder={t('payout_info.your_company_name')}
                    name="companyName"
                    onChange={handleInputchange}
                    value={payoutAccountDetails?.companyName}
                    disabled={payoutAccountDetails?.accId != ''}
                    // value={valuesFullName.name}
                    sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
                    FormHelperTextProps={{
                      style: {
                        backgroundColor: LmiTheme.light.systemBackground.elevation,
                        margin: 0,
                        paddingTop: '3px',
                        paddingRight: '14px',
                        paddingBottom: '0',
                        paddingLeft: '14px',
                      },
                    }}
                  />
                </Grid>
                {/* )} */}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box pb={1}>
              <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
              <Typography component="span" fontWeight={500}>{t('payout_info.email')}</Typography>
            </Box>
            <TextField
              fullWidth
              // id="outlined-basic"
              variant="outlined"
              // type="email"
              name="email"
              value={payoutAccountDetails?.email}
              error={!isEmailValid}
              helperText={!isEmailValid && `${t('payout_info.invalid_email')}`}
              onChange={handleInputchange}
              disabled={props.kReadOnly}
              placeholder={t('payout_info.enter_email')}
              sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
              FormHelperTextProps={{
                style: {
                  backgroundColor: LmiTheme.light.systemBackground.elevation,
                  margin: 0,
                  paddingTop: '3px',
                  paddingRight: '14px',
                  paddingBottom: '0',
                  paddingLeft: '14px',
                },
              }}
            />
          </Grid>
        </Grid>
        {payoutAccountDetails?.externalAcc != '' && (
          <Grid item xs={12}>
            <Box>
              <Typography component="span" fontSize={14} fontWeight={500}>
                {`${t('payout_info.card_ending')}`}
                {payoutAccountDetails?.externalAcc.data[0].last4}

              </Typography>
            </Box>
          </Grid>
        )}

        {payoutAccountDetails.accId != '' && (
          <Grid item xs={12}>
            <Box pb={1}>
              <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
              <Typography component="span" fontWeight={500}>{t('payout_info.account_name')}</Typography>
            </Box>
            <TextField
              fullWidth
              // id="outlined-basic"
              variant="outlined"
              // type="email"
              name="accountHolderName"
              value={payoutAccountDetails?.accountHolderName}
              // error={!isEmailValid}
              // helperText={!isEmailValid && 'Invalid Account Holder Name.'}
              onChange={handleInputchange}
              disabled={props.kReadOnly}
              // placeholder={t('payout_info.enter_account_number')}
              sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
              FormHelperTextProps={{
                style: {
                  backgroundColor: LmiTheme.light.systemBackground.elevation,
                  margin: 0,
                  paddingTop: '3px',
                  paddingRight: '14px',
                  paddingBottom: '0',
                  paddingLeft: '14px',
                },
              }}
            />
          </Grid>
        )}
        {!payoutAccountDetails.isBankAccount && (
          <Grid item xs={isMobile ? 6 : 3}>
            <Box pb={1}>
              <Typography component="span" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
              <Typography component="span" fontWeight={500}>{t('payout_info.expiration')}</Typography>
            </Box>
            <TextField
              fullWidth
              // id="outlined-basic"
              variant="outlined"
              type="text"
              name="expiration"
              placeholder="MM/YY"
              value={formattedExpiration}
              error={!isExpDateValid}
              helperText={!isExpDateValid && `${t('payout_info.invalid_exp_date')}`}
              onChange={handleInputchange}
              sx={{ backgroundColor: LmiTheme.light.systemBackground.base }}
              InputProps={{
                inputComponent: TextExpireDate as any,
              }}
              FormHelperTextProps={{
                style: {
                  backgroundColor: LmiTheme.light.systemBackground.elevation,
                  margin: 0,
                  paddingTop: '3px',
                  paddingRight: '14px',
                  paddingBottom: '0',
                  paddingLeft: '14px',
                },
              }}
            />
          </Grid>
        )}
      </Paper>
    </>
  );
}

export default BusinessPayout;

/* eslint-disable max-lines */
/* eslint import/no-unresolved: [2, { commonjs: true, amd: true }] */
import { useEffect, useReducer, useState } from 'react';
import * as AWS from 'aws-sdk';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import cookies from 'js-cookie';
import { uniqueNamesGenerator, NumberDictionary } from 'unique-names-generator';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ClsModCreateUserAndProfile,
  ClsModCreateUserNewFlowWithApple,
  ClsModValidatesExistingEmailAccountNickName,
  ClsModValidatesPhoneNumber,
  ClsModValidatesPhoneNumberCancel,
  ClsModValidatesPhoneNumberCode,
  ClsModValidatesUserExitsNewFlowWithApple,
} from '@lendmeit/api_backend_lmi/dist/models';
import AuthContext from './authContext';
import AuthReducer from './authReducer';
import {
  userLogin, userRegistry,
} from './models/userCognitoModel';
import { confirmAccount, confirmRecoveryPassword } from './models/validationsCognitoModel';
import {
  REGISTRATION_ERROR,
  GET_USER,
  LOGIN_SUCCESSFUL,
  LOGIN_ERROR,
  CLOSE_SESION,
  CONFIRM_ACCOUNT,
  CONFIRM_ERROR,
  FORGOT_VERIFICATION,
  REQUEST_ID,
  USER_LMI,
  BLOCKED,
  NOTAUTHORIZED,
  EMAIL_PASS,
  CHANGE_PASS_ERROR,
  CHANGE_PASS_SUCCESS,
  TOKEN,
  CLEAN_ALERT,
  SET_NEW_USER,
} from '../../types';
import ApiLMI from '../../services/apilmi';
import useExitPrompt from '../../utils/useExitPrompt';
import API from '../../services/axiosConfig';
import { getRandomName, setTimerAWS } from '../../services/common';

const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    gapi: any;
    FB: any;
    ndp: any;
  }
}
export const AuthType = {
  NotSelect: 0,
  CognitoUser: 1,
  Google: 2,
  FB: 3,
  Apple: 4,
  Amplify: 5,
  CognitoUserExist: 6,
};

const AuthState = (props: any) => {
  const history = useHistory();
  const numberDictionary = NumberDictionary.generate({ min: 1, max: 999 });

  const initialState = {
    token: localStorage.getItem('token'),
    authenticated: null,
    confirmAccount: null,
    user: null,
    email: null,
    message: null,
    userLMI: null,
    isBlocked: false,
    codeSend: false,
    userName: localStorage.getItem('name'),
    appType: localStorage
      .getItem('logType') != null
      ? parseInt(localStorage.getItem('logType')!, 10)
      : AuthType.NotSelect,
    requestId: null,
    phoneNumber: null,
    comesForgot: false,
    password: null,
    newUser: false,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const userInit: any = {};

  const [cogUser, saveCognitoUsr] = useState(userInit);
  // const [, updateData] = useState(false);
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
  // const [isFormIncomplete, setIsFormIncomplete] = useState(true);

  AWS.config.region = process.env.REACT_APP_REGION;

  const userPool = new AmazonCognitoIdentity.CognitoUserPool({
    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    ClientId: process.env.REACT_APP_CLIENDID,
  });

  let tockenId = '';

  let appTypeb = state.appType;

  useEffect(() => () => {
    setShowExitPrompt(false);
  }, []);

  const registrybyCognito = async ({
    email,
    password,
    phone: phone_number,
  }: userRegistry) => {
    const randomName = uniqueNamesGenerator({
      dictionaries: [['google'], numberDictionary],
      length: 2,
      separator: ' ',
    });

    const name = getRandomName(randomName);
    let newCognitoUser: any;
    await Auth.signUp({
      username: email!,
      password,
      attributes: {
        email,
        name,
        phone_number,
      },
    }).then((data) => {
      const { user: cognitoUser } = data;
      newCognitoUser = cognitoUser;
    }).catch((error) => {
      // The user has already registered in cognito
      if (error.code === 'UsernameExistsException') {
        newCognitoUser = 'UsernameExists';
      } else {
        console.log(error);
      }
    });
    return newCognitoUser;
  };

  const ForgotPasswordVerificationCode = async ({ email, verificationCode, newPassword }: confirmRecoveryPassword) => {
    try {
      setBlocked(true);
      await Auth.forgotPasswordSubmit(email, verificationCode, newPassword);
      setBlocked(false);
      return true;
    } catch (error) {
      const alerta = {
        msg: (error) || `${t('auth_state.server_error')}`,
        category: 'alerta-error',
      };
      dispatch({
        type: CONFIRM_ERROR,
        payload: alerta,
      });
    }

    return false;
  };

  const ForgotPassword = async (email: string) => {
    try {
      setBlocked(true);
      await Auth.forgotPassword(email);

      dispatch({
        type: CLEAN_ALERT,
      });
      dispatch({
        type: FORGOT_VERIFICATION,
        payload: { codeSend: true, email },
      });
    } catch (error: any) {
      const alerta = {
        msg: (error && error.message != undefined) ? error.message : `${t('auth_state.server_error')}`,
        category: 'alerta-error',
      };

      dispatch({
        type: CONFIRM_ERROR,
        payload: alerta,
      });
    }
  };

  const setBlocked = (active: boolean = false) => {
    dispatch({
      type: BLOCKED,
      payload: active,
    });
  };

  const ResendCode = async () => {
    try {
      setBlocked(true);
      if (state.appType == AuthType.Amplify) { // || state.appType == AuthType.CognitoUserExist) {
        await Auth.currentAuthenticatedUser();

        // await getGuestCredentials();

        const _api = ApiLMI();
        if (state.requestId) {
          const bodyPhone: ClsModValidatesPhoneNumberCancel = {
            toc: 'qwerty',
            requestId: state.requestId,
          };

          await _api.userValidatesPhoneNumberCancel(bodyPhone);
        }

        updateProviderAttributes({
          phone_number: state.phoneNumber,
        });
      } else {
        await Auth.resendSignUp(state.email);
      }

      setBlocked(false);
    } catch (error: any) {
      const alerta = {
        msg: (error) ? error.message : `${t('auth_state.server_error')}`,
        category: 'alerta-error',
      };

      dispatch({
        type: CONFIRM_ERROR,
        payload: alerta,
      });
    }
  };

  const fnConfirmAccount = async ({ email, code }: confirmAccount) => {
    try {
      setBlocked(true);
      let isSuccess = false;
      let name = '';
      let lmiUser: any = null;
      const _api = ApiLMI();

      if (state.appType == AuthType.Amplify) { // || state.appType == AuthType.CognitoUserExist) {
        await Auth.currentAuthenticatedUser();

        // await getGuestCredentials();

        const bodyPhone: ClsModValidatesPhoneNumberCode = {
          toc: 'qwerty',
          requestId: state.requestId,
          code,
        };

        const { data } = await _api.userValidatesPhoneNumberCode(bodyPhone);
        // const { data } = await _api.userSendWelcomeEmail(bodyPhone);

        if (typeof data === 'string') {
          const result = JSON.parse(data.toString());
          if (result.Error != '') {
            toast.error(t('auth_state.not_work_error'));
          }

          isSuccess = result.Error == '' && result.Response[0].verified;
        }
      } else {
        const register = await Auth.confirmSignUp(email, code);
        isSuccess = register == 'SUCCESS';

        if (isSuccess) {
          const bodyEmail: any = {
            type: 'string',
            userEmail: email,
            language: cookies.get('i18next'),
            toc: 'string',
          };
          await Auth.signIn(email, state.password);
          await _api.apiEmailSendemailPost(bodyEmail);
          lmiUser = await getInfoUserLMI(false);
        }
      }

      if (isSuccess) {
        if (await creteUserLMI(lmiUser)) {
          const user = await Auth.currentAuthenticatedUser();
          updateTokenState(user);
          email = user;
          name = user.attributes.name ?? user.attributes.email;
          const confirmAccountModel = {
            user: email,
            confirm: true,
            message: null,
            userName: name,
            appType: appTypeb,
          };

          dispatch({
            type: CONFIRM_ACCOUNT,
            payload: confirmAccountModel,
          });
        }
        // signOff();
      } else {
        const alerta = {
          msg: `${t('auth_state.not_work_error')}`,
          category: 'alerta-error',
        };
        dispatch({
          type: CONFIRM_ERROR,
          payload: alerta,
        });
      }
    } catch (error: any) {
      const alerta = {
        msg: (error && error.message != undefined) ? error.message : `${t('auth_state.server_error')}`,
        category: 'alerta-error',
      };

      dispatch({
        type: CONFIRM_ERROR,
        payload: alerta,
      });
    }
  };

  function setNewUser(value: boolean) {
    dispatch({ type: SET_NEW_USER, payload: value });
  }

  const addUser = async (user: userRegistry) => {
    try {
      setBlocked(true);
      appTypeb = AuthType.CognitoUser;
      const newUser: any = await registrybyCognito(user);

      const confirmAccountModel = {
        user,
        confirm: true,
        appType: appTypeb,
        userName: newUser.username,
        message: {
          msg: '',
          category: 'alerta-error',
        },
        phoneNumber: user.phone,
        email: user.email,
        password: user.password,
      };

      if (newUser != 'UsernameExists') {
        saveCognitoUsr(newUser);
      } else ResendCode();

      dispatch({
        type: CONFIRM_ACCOUNT,
        payload: confirmAccountModel,
      });
    } catch (error) {
      const alerta = {
        msg: error,
        category: 'alerta-error',
      };

      dispatch({
        type: REGISTRATION_ERROR,
        payload: alerta,
      });
    }
  };

  const getUser = async (publicPage: boolean = true) => {
    setBlocked(true);
    const token = localStorage.getItem('token');

    tockenId = token!;
    try {
      if ((!publicPage && token && token != '') || publicPage) {
        await getUserSession(publicPage);

        const dataResult = {
          token,
          user: cogUser,
          publicPage,
        };

        dispatch({
          type: GET_USER,
          payload: dataResult,
        });
      }
    } catch (error) {
      dispatch({
        type: LOGIN_ERROR,
      });
    }
    return tockenId;
  };

  const googleAuthorize = async (googleUser: any) => {
    try {
      appTypeb = AuthType.Google;
      tockenId = googleUser.getAuthResponse().id_token;
      await cognitoCredentials(googleUser, googleUser.getBasicProfile().getName());
      // eslint-disable-next-line no-empty
    } catch (e) {
      toast.error(t('auth_state.not_authorized'));
    }
  };

  const facebookAuthorize = async (fbUser: any) => {
    try {
      appTypeb = AuthType.FB;
      tockenId = fbUser.accessToken;

      await cognitoCredentials(fbUser, fbUser.name);
      // eslint-disable-next-line no-empty
    } catch (e) {
      toast.error(t('auth_state.not_authorized'));
    }
  };

  const amplifyAuthorize = async (providerUser: any) => {
    try {
      setBlocked(true);

      appTypeb = AuthType.Amplify;

      await ReviewUser(providerUser);
      // eslint-disable-next-line no-empty
    } catch (e) {
      const alert = {
        message: {
          msg: `${t('auth_state.authentication_issues')}`,
          category: 'alerta-error',

        },
      };

      dispatch({
        type: NOTAUTHORIZED,
        payload: alert,
      });
    }
  };

  const changePassword = async ({ oldPassword, newPassConfirm }: any) => {
    const user = await Auth.currentAuthenticatedUser();

    try {
      setBlocked(true);
      await Auth.changePassword(user, oldPassword, newPassConfirm);
      setBlocked(false);
      dispatch({
        type: CHANGE_PASS_SUCCESS,
        closeModal: true,
      });
      return true;
    } catch (error) {
      const alerta = {
        msg: (error) || `${t('auth_state.server_error')}`,
        category: 'alerta-error',
      };
      dispatch({
        type: CHANGE_PASS_ERROR,
        payload: alerta,
      });
    }

    return false;
  };

  async function ReviewUser(providerUser: any) {
    await getUserSession();

    const matchUser: ClsModCreateUserNewFlowWithApple = await getInfoUserLMI();

    if (matchUser && matchUser.idCatLndUser == 0 && !matchUser.active) {
      saveCognitoUsr(providerUser);

      if (appTypeb == AuthType.CognitoUserExist && state.comesForgot) {
        const isSuccess = await creteUserLMI(matchUser);
        if (isSuccess) { updateTokenState(providerUser); }
      } else {
        const confirmAccountModel = {
          user: providerUser,
          confirm: false,
          message: {
            msg: `${t('auth_state.confirm_phone')}`,
            category: 'alerta-error',
          },
          appType: appTypeb,
          userLMI: matchUser,
        };

        dispatch({
          type: CONFIRM_ACCOUNT,
          payload: confirmAccountModel,
        });
      }
    } else {
      if (matchUser.idCatLndUser! > 0) {
        await updateProviderAttributes({
          'custom:lmi-id': `${matchUser.idCatLndUser}`,
          'custom:isValid': `${matchUser.active ? 1 : 0}`,
          'custom:lmi-phone': matchUser.phoneNumber,
          name: matchUser.userName,
        });
      }

      if (matchUser.active) {
        await cognitoCredentials(providerUser, matchUser.userName!);
      } else {
        toast.warning(t('auth_state.blocked_user'));

        setTimeout(() => {
          Auth?.signOut();
        }, 1300);
      }
    }
  }

  const updateTokenState = async (userProvider: any) => {
    setTimerAWS(userProvider, history);

    dispatch({
      type: TOKEN,
      payload: userProvider.signInUserSession.idToken.jwtToken,
    });
  };

  async function cognitoCredentials(userProvider: any, name: string) {
    await getUserSession();

    saveCognitoUsr(userProvider);
    await updateTokenState(userProvider);

    const dataResult = {
      token: userProvider.signInUserSession.idToken.jwtToken,
      user: userProvider,
      userName: name,
      appType: appTypeb,
    };

    dispatch({
      type: LOGIN_SUCCESSFUL,
      payload: dataResult,
    });
  }

  const getUserSession = async (publicPage: boolean = true) => {
    AWS.config.region = process.env.REACT_APP_REGION;
    // const token = localStorage.getItem('token');
    if (window.location.pathname !== '/login') {
      appTypeb = publicPage ? AuthType.NotSelect : appTypeb;
    }

    switch (appTypeb) {
      case AuthType.Google:
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID!,
          Logins: {
            'accounts.google.com': tockenId,
          },
        });
        break;

      case AuthType.FB:
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID!,
          Logins: {
            'graph.facebook.com': tockenId,
          },
        });
        break;

      case AuthType.CognitoUser:
        const cognitoUser = userPool.getCurrentUser();
        if (cognitoUser != null) {
          cognitoUser.getSession((err: any, result: any) => {
            if (err) {
              signOff();
            }

            if (result) {
              if (!result.isValid()) {
                signOff();
              }

              const Logins: any = {};
              const endPoint = `cognito-idp.${(process.env.REACT_APP_REGION)}.amazonaws.com/${(process.env.REACT_APP_USER_POOL_ID)}`;
              Logins[endPoint] = result.getIdToken()?.getJwtToken();
              AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID!,
                Logins,
              });
            }
          });
        }
        break;

      case AuthType.Amplify:
      case AuthType.CognitoUserExist:

        AWS.config.region = process.env.REACT_APP_REGION;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID!,
        });
        break;
      default:
        AWS.config.region = process.env.REACT_APP_REGION;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID!,
        });
        break;
    }

    await new Promise((resolve, reject) => {
      (AWS.config.credentials as AWS.CognitoIdentityCredentials)
        .get((err) => {
          if (err) {
            reject(err);
          }

          resolve(AWS.config.credentials);
        });
    });
  };

  const userExist = async (email: any) => {
    setBlocked(true);
    await getUserSession();
    const model = {
      email,
      message: null,
      appType: AuthType.CognitoUser,
      userName: '',

    };
    try {
      const emailBody: ClsModValidatesExistingEmailAccountNickName = {
        email,
      };
      // await getGuestCredentials();
      const _api = ApiLMI();
      const { data } = await _api.userValidatesExistingEmailAccountNickName(emailBody);

      if (typeof data === 'string') {
        const objUser = JSON.parse(data.toString());
        const { Response: userExistResponse, Error: error } = objUser;

        if (error == '' && userExistResponse.length > 0) {
          email = userExistResponse[0].email ?? email;
          model.userName = userExistResponse[0].username ?? '';
        }
      }

      dispatch({
        type: NOTAUTHORIZED,
        payload: model,
      });

      return model.userName !== '';
      // await Auth.signIn(email, '**s');
    } catch (error: any) {
      dispatch({
        type: NOTAUTHORIZED,
        payload: model,
      });
      const { code } = error;

      switch (code) {
        case 'UserNotFoundException':

          return false;
        case 'NotAuthorizedException':

          return true;
        case 'PasswordResetRequiredException':

          return true;
        case 'InvlalidParameterException':

          return true;
        case 'UserNotConfirmedException':

          return true;
        default:

          const alerta = {
            msg: (error && error.message != undefined) ? error.message : `${t('auth_state.server_error')}`,
            category: 'alerta-error',
          };

          dispatch({
            type: LOGIN_ERROR,
            payload: alerta,
          });
      }
    }
    return false;
  };

  const logIn = async ({ email, password }: userLogin) => {
    let user;

    try {
      setBlocked(true);
      appTypeb = AuthType.CognitoUserExist;
      user = await Auth.signIn(email, password);

      dispatch({
        type: EMAIL_PASS,
        payload: { email, password },
      });

      if (user) {
        await ReviewUser(user);
      } else {
        const alerta = {
          msg: `${t('auth_state.server_error')}`,
          category: 'alerta-error',
        };
        dispatch({
          type: LOGIN_ERROR,
          payload: alerta,
        });
      }
    } catch (error: any) {
      const { code } = error;

      switch (code) {
        case 'UserNotFoundException':

          const alert = {
            message: {
              msg: (error && error.message != undefined) ? error.message : `${t('auth_state.user_not_found')}`,
              category: 'alerta-error',

            },
            email,
            password,
          };

          dispatch({
            type: NOTAUTHORIZED,
            payload: alert,
          });

          break;
        case 'NotAuthorizedException':

          const alertaMsg = {
            message: {
              msg: (error && error.message != undefined) ? error.message : `${t('auth_state.server_error')}`,
              category: 'alerta-error',

            },
            email,
            password,
          };

          dispatch({
            type: NOTAUTHORIZED,
            payload: alertaMsg,
          });
          break;
        case 'PasswordResetRequiredException':
          const alerts = {
            message: {
              msg: (error && error.message != undefined) ? error.message : `${t('auth_state.password_reset')}`,
              category: 'alerta-error',

            },
            email,
            password,
          };

          dispatch({
            type: NOTAUTHORIZED,
            payload: alerts,
          });

          break;
        case 'UserNotConfirmedException':

          await Auth.resendSignUp(email);

          const confirmAccountModel = {
            email,
            confirm: false,
            message: {
              msg: (error && error.message != undefined)
                ? error.message : `${t('auth_state.validate_user')}`,
              category: 'alerta-error',
            },
            appType: appTypeb,
            password,
          };

          dispatch({
            type: CONFIRM_ACCOUNT,
            payload: confirmAccountModel,
          });
          break;
        default:

          const alerta = {
            msg: (error && error.message != undefined) ? error.message : `${t('auth_state.server_error')}`,
            category: 'alerta-error',
          };
          dispatch({
            type: LOGIN_ERROR,
            payload: alerta,
          });
      }
    }
  };

  const signOff = async () => {
    try {
      dispatch({
        type: CLOSE_SESION,
        payload: null,
      });
      switch (state.appType) {
        case AuthType.FB:

          window.FB.logout();
          break;
        case AuthType.Google:
          const auth2 = window.gapi.auth2.getAuthInstance();
          if (auth2) {
            await new Promise((resolve) => {
              auth2.signOut().then(() => {
                resolve({});
              });
            });
          }
          break;
        case AuthType.Amplify:
        case AuthType.CognitoUserExist:

          await Auth.signOut();
          break;
        case AuthType.CognitoUser:

          await Auth.signOut();
          break;

        default:

          await Auth.signOut();
          break;
      }

      localStorage.removeItem('token');
      await clearCacheAws();

      await getUser();
    } catch (_) {
      //
    }

    saveCognitoUsr(null);
  };

  async function clearCacheAws() {
    if (cogUser && Object.keys(cogUser).length > 0) {
      // await cogUser.disconnect();
      await cogUser.signOut();
    } else if (state.user && Object.keys(cogUser).length > 0) {
      // await state.user.disconnect();
      await state.user.signOut();
    }

    if (userPool) {
      const currentUser = userPool.getCurrentUser();
      if (currentUser) {
        await currentUser.signOut();
      }
    }

    if (AWS.config.credentials) {
      // AWS.config.credentials.refresh();
      const identity = await (AWS.config.credentials as AWS.CognitoIdentityCredentials);
      identity.clearCachedId();
      await (AWS.config.credentials as AWS.CognitoIdentityCredentials).clearCachedId();
    }
    AWS.config.credentials = null;
  }

  const preSignUp = async (mail: string, password: string) => {
    const result = await Auth.signUp({
      username: mail,
      password,
    });

    return result;
  };

  async function updateProviderAttributes(attributes: any) {
    try {
      setBlocked(true);
      if (Object.keys(attributes).find((a: any) => a == 'phone_number')) {
        // await getGuestCredentials();
        const _api = ApiLMI();
        const bodyPhone: ClsModValidatesPhoneNumber = {
          toc: 'qwerty',
          phoneNumber: attributes.phone_number,
        };

        const { data } = await _api
          .userValidatesPhoneNumber(bodyPhone);

        if (typeof data === 'string') {
          setShowExitPrompt(!showExitPrompt);
          const obj = JSON.parse(data.toString());
          const request = obj.Response[0];

          dispatch({
            type: REQUEST_ID,
            payload: {
              requestId: request?.requestId,
              phoneNumber: attributes.phone_number,
            },
          });
        }
      } else {
        const user = await Auth.currentAuthenticatedUser();

        await Auth.updateUserAttributes(user, attributes);
      }

      // setBlocked(true);
      setBlocked(false);

      return true;
      // eslint-disable-next-line no-empty
    } catch (error: any) {
      const model = {
        message: {
          msg: (error && error.message != undefined) ? error.message : `${t('auth_state.server_error')}`,
          category: 'alerta-error',
        },
        appType: AuthType.CognitoUser,
      };
      dispatch({
        type: NOTAUTHORIZED,
        payload: model,
      });
    }

    return false;
  }

  async function getGuestCredentials() {
    AWS.config.region = process.env.REACT_APP_REGION;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID!,
    });

    await new Promise((resolve, reject) => {
      // Make the call to obtain credentials
      (AWS.config.credentials as AWS.CognitoIdentityCredentials)
        .get(async (e: any) => {
          if (e) {
            reject(e);
          }

          resolve(AWS.config.credentials);
        });
    });
  }

  const sendEmail = async ({ type, userEmail }: any) => {
    try {
      const body = JSON.stringify({ type, userEmail });
      await API.post('email/sendemail', body, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      // eslint-disable-next-line no-empty
    } catch (_) {
    }
  };

  const sendWelcomeEmail = async ({ userEmail }: any) => {
    const _api = ApiLMI();
    try {
      const bodyEmail: any = {
        type: 'string',
        userEmail,
        language: cookies.get('i18next'),
        toc: 'string',
      };
      await _api.apiEmailSendemailPost(bodyEmail);
      // eslint-disable-next-line no-empty
    } catch (_) {
    }
  };

  const getInfoUserLMI = async (verify = true) => {
    let userLMI: ClsModCreateUserAndProfile = {
      idCatLndUser: 0,
      active: false,
      isWebUser: true,
    };

    try {
      const user = await Auth.currentAuthenticatedUser();

      const _api = ApiLMI();

      const randomName = uniqueNamesGenerator({
        dictionaries: [['apd'], numberDictionary],
        length: 2,
        separator: ' ',
      });

      const _cgnitoData = { ...user };
      let internalUserId = 0;
      let fbUserId = '';
      let googleUserId = '';
      let cognitoUserId = '';
      let appleUserId = '';
      let googleUserImage = '';
      let fbUserImage = '';
      let appleUserImage = '';
      let userName = '';
      let firstName = '';
      let lastName = '';
      let completeName = '';
      let phoneNumber = '';
      let email = '';
      let isFb = false;
      let isGoogle = false;
      let isApple = false;
      let isActive = 0;

      const userData = (appTypeb != AuthType.CognitoUser && appTypeb != AuthType.CognitoUserExist)
        ? JSON.parse(_cgnitoData.attributes.identities)[0] : {
          providerType: 'Cognito',
          userId: _cgnitoData.attributes.sub,
        };

      const {
        providerType,
        userId,
      } = userData;

      phoneNumber = state.phoneNumber ?? '';
      email = _cgnitoData.attributes.email;
      firstName = _cgnitoData.attributes.given_name ?? '';
      lastName = _cgnitoData.attributes.family_name ?? '';
      completeName = '';

      switch (providerType) {
        case 'Google':
          googleUserId = userId;
          isGoogle = true;
          googleUserImage = _cgnitoData.attributes.picture;
          userName = getRandomName(_cgnitoData.attributes.name ?? randomName);
          break;
        case 'Facebook':

          fbUserId = userId;
          isFb = true;
          fbUserImage = _cgnitoData.attributes.picture;
          userName = getRandomName(_cgnitoData.attributes.name ?? randomName);
          break;
        case 'SignInWithApple':
          appleUserId = userId;
          isApple = true;
          appleUserImage = _cgnitoData.attributes.picture ?? '';
          userName = getRandomName(randomName);
          firstName = _cgnitoData.attributes.given_name ?? '';
          break;
        case 'Cognito':

          appleUserImage = '';
          userName = _cgnitoData.attributes.name ?? getRandomName(randomName);
          firstName = '';
          phoneNumber = _cgnitoData.attributes.phone_number;
          break;

        default:
          break;
      }

      const userExistModel: ClsModValidatesUserExitsNewFlowWithApple = {
        userId,
        isFb,
        isGoogle,
        isApple,
      };

      if (verify) {
        const { data: dataUserExist } = await _api
          .userValidatesUserExitsNewFlowWithApple(userExistModel);

        if (typeof dataUserExist === 'string') {
          const objUser = JSON.parse(dataUserExist.toString());
          const { Response: userExistResponse, Error: error } = objUser;

          if (error == '' && userExistResponse.length > 0) {
            internalUserId = userExistResponse[0].internalUserId ?? 0;
            isActive = userExistResponse[0].isActive ?? 0;
            userName = userExistResponse[0].name ?? userName;
            phoneNumber = userExistResponse[0].phoneNumber ?? phoneNumber;
          }
        }
      }

      cognitoUserId = _cgnitoData.attributes.sub;
      userLMI = {
        toc: 'qwerty',
        idCatLndUser: internalUserId,
        cognitoUserId,
        userName,
        firstName,
        lastName,
        completeName,
        phoneNumber,
        email,
        isFb,
        fbUserId,
        fbUserImage,
        isGoogle,
        googleUserId,
        googleUserImage,
        isApple,
        appleUserId,
        appleUserImage,
        country: 0,
        state: 0,
        city: 0,
        zipCode: '',
        userAddress: '',
        deviceOs: 'Web_LMI',
        active: isActive == 1,
      };

      dispatch({
        type: USER_LMI,
        payload: userLMI,
      });

      // eslint-disable-next-line no-empty
    } catch (error: any) {
      const model = {
        message: {
          msg: (error && error.message != undefined)
            ? error.message : `${t('auth_state.server_error')}`,
          category: 'alerta-error',
        },
        appType: AuthType.CognitoUser,
      };

      dispatch({
        type: NOTAUTHORIZED,
        payload: model,
      });
    }

    return userLMI;
  };

  const creteUserLMI = async (lmiUser?: any) => {
    setBlocked(true);
    const userLMI = lmiUser ?? state.userLMI;
    let internalUserId = userLMI?.idCatLndUser ?? 0;
    const { phoneNumber } = state;
    let isActive = 0;

    try {
      setShowExitPrompt(false);

      if (internalUserId == 0) {
        // await getGuestCredentials();
        const _api = ApiLMI();
        userLMI.phoneNumber = phoneNumber ?? (lmiUser ? lmiUser.phoneNumber : '');
        let currency = 'USD';

        if (userLMI.phoneNumber.startsWith('+52')) {
          currency = 'MXN';
        }

        userLMI.active = true;
        userLMI.isWebUser = true;
        userLMI.currency = currency;
        const { data } = await _api
          .apiUserCreateUserCurrencyPost(userLMI);

        if (typeof data === 'string' && data !== '') {
          const obj = JSON.parse(data);
          const { Response } = obj;

          if (obj.Error === '') {
            if (userLMI.email) {
              sendEmail(
                { type: 'welcome', userEmail: userLMI.email },
              );
            }
            internalUserId = Response[0].IdCatLndUsuario;
            isActive = 1;
          }
        }
      }
      // state.phoneNumber ??

      if (internalUserId > 0 && isActive == 1) {
        if (window.ndp) {
          window.ndp('track', 'CONVERSION');
        }

        return await updateProviderAttributes({
          'custom:lmi-id': `${internalUserId}`,
          'custom:isValid': `${isActive}`,
          'custom:lmi-phone': userLMI.phoneNumber,
          name: userLMI.completeName != null && userLMI.completeName != '' ? userLMI.completeName : userLMI.userName,
        });
      }
    } catch (error: any) {
      const model = {
        message: {
          msg: (error && error.message != undefined) ? error.message : `${t('auth_state.server_error')}`,
          category: 'alerta-error',
        },
        appType: AuthType.CognitoUser,
      };

      dispatch({
        type: NOTAUTHORIZED,
        payload: model,
      });
    }

    return false;
  };

  useEffect(() => {
    setBlocked(false);
  }, []);

  window.onbeforeunload = function () {
    // localStorage.clear();
  };

  const { t } = useTranslation('global');

  return (
    <AuthContext.Provider
      value={{
        ...state,
        token: state.token,
        authenticated: state.authenticated,
        user: state.user,
        message: state.message,
        isBlocked: state.isBlocked,
        confirmAccount: state.confirmAccount,
        codeSend: state.codeSend,
        userName: state.userName,
        email: state.email,
        appType: state.appType,
        closeModal: state.closeModal,
        newUser: state.newUser,
        addUser,
        logIn,
        userAuthenticated: getUser,
        signOff,
        fnConfirmAccount,
        googleAuthorize,
        facebookAuthorize,
        amplifyAuthorize,
        ResendCode,
        ForgotPasswordVerificationCode,
        ForgotPassword,
        userExist,
        preSignUp,
        updateProviderAttributes,
        getGuestCredentials,
        sendEmail,
        sendWelcomeEmail,
        setBlocked,
        changePassword,
        setNewUser,
      }}
    >
      {props.children}

    </AuthContext.Provider>
  );
};

export default AuthState;

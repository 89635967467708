import {
  Box,
} from '@mui/material';
import { StaticMapModels } from './StaticMap.models';

function GMapCircle(lat: number, lng: number, rad: any, detail = 8) {
  const uri = 'https://maps.googleapis.com/maps/api/staticmap?';
  let staticMapSrc = `center=${lat.toString()},${lng.toString()}`;
  staticMapSrc += '&size=100x100';
  staticMapSrc += '&path=';
  staticMapSrc += 'color:0x00000060|';
  staticMapSrc += 'fillcolor:0x00000050|';
  staticMapSrc += 'weight:2';

  const r = 6371;

  const pi = Math.PI;

  const _lat = (lat * pi) / 180;
  const _lng = (lng * pi) / 180;
  const d = (rad / 1000) / r;

  let i = 0;

  for (i = 0; i <= 360; i += detail) {
    // eslint-disable-next-line no-mixed-operators
    const brng = i * pi / 180;

    let pLat = Math.asin(Math.sin(_lat) * Math.cos(d) + Math.cos(_lat) * Math.sin(d) * Math.cos(brng));
    const pLng = ((_lng + Math.atan2(Math.sin(brng) * Math.sin(d) * Math.cos(_lat), Math.cos(d) - Math.sin(_lat) * Math.sin(pLat))) * 180) / pi;
    pLat = (pLat * 180) / pi;

    staticMapSrc += `|${pLat.toString()},${pLng.toString()}`;
  }
  return uri + encodeURI(staticMapSrc);
}

export default function StaticMap(props: StaticMapModels) {
  const mapCentLat = props.kLat;
  const mapCentLng = props.kLng;
  const mapW = 810;
  const mapH = 200;
  const zoom = 16;
  const icon = `${window.location.origin}/assets/map_marker_32.png`;
  // const icon2 = 'https://goo.gl/5y3S82%7CCanberra+ACT';

  const circRadius = 100;
  // const circRadiusThick = 1;
  // const circFill = '00BFFF';
  // const circFillOpacity = '60';
  // const circBorder = '00BFFF';

  const encString = GMapCircle(props.kLat, props.kLng, circRadius);

  let src = 'https://maps.googleapis.com/maps/api/staticmap?';
  src += `center=${mapCentLat.toString()},${mapCentLng.toString()}&`;
  src += `zoom=${zoom}&`;
  src += `size=${mapW}x${mapH}&`;

  if (props.kExactLocation) {
    src += `markers=icon:${icon}|color:black|${mapCentLat.toString()},${mapCentLng.toString()}`;
  } else {
    src += `enc:${encString}`;
  }
  src += '&key=AIzaSyA-LGSemqcsQbBOIzdBR6aXSsmX24rw5Vs';
  return (
    <>
      <Box
        sx={{
          height: '200px',
          backgroundImage: `url("${src}")`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: '10px',
        }}
      />
    </>
  );
}

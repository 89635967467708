import {
  Box, Stack, AvatarGroup, Avatar, useTheme, useMediaQuery,
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import ManageCirclesEmpty from './ManageCirclesEmpty';

interface PropsT {
  circles: Array<null>,
}

export default function ManageCircles(props: PropsT) {
  const { circles } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box>
      <Stack p={2} direction="row" justifyContent="space-between" spacing={3}>
        {
          circles?.length ? (
            <>
              <AvatarGroup
                total={circles?.length}
                sx={{
                  '& .MuiAvatar-root': {
                    width: isMobile ? '50px' : '60px',
                    height: isMobile ? '50px' : '60px',
                    backgroundColor: '#f3f3f3',
                    color: 'black',
                    fontSize: '16px',
                  },
                }}
                variant="rounded"
                max={isMobile ? 3 : 5}
              >

                { circles?.map((item: any) => (
                  <Avatar key={uuid()} alt={item.name} src={item?.circlePicture} />
                ))}
              </AvatarGroup>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
            </>
          ) : (
            <ManageCirclesEmpty />
          )
        }
      </Stack>
    </Box>
  );
}
